import { useMemo } from "react";

import { hexToRGB, rgbToHsl, useTheme } from "@swiggy-private/rn-dls";

/**
 * Converts a hex color value to HSL. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
 * @param colorValue hex string
 * @returns hue, saturation and lightness values in array
 */
export const useGetHslFromRgb: () => number[] = () => {
    const { value: theme } = useTheme();

    const themeColorHslValue = useMemo(() => {
        const rgbValue = hexToRGB(theme["color-primary"].toString());
        const hslValue = rgbToHsl(rgbValue);

        return hslValue;
    }, [theme]);

    return themeColorHslValue;
};
