import { DEFAULT_STORE_VERSION, MINIS_DOMAIN, MINIS_SITE_DOMAIN } from "@minis-consumer/constants";

import { getMediaUrl, GetMediaUrlOpts } from "./media";
import type { Store } from "../interfaces/store";

const DEFAULT_IMAGE_SIZE = 100;

export const getStoreBrandImageUrl = (
    store: Store | string,
    opts: Partial<GetMediaUrlOpts> = {
        height: DEFAULT_IMAGE_SIZE,
        width: DEFAULT_IMAGE_SIZE,
    },
    isImageKitEnabled?: boolean,
): string | null => {
    if (typeof store === "string") {
        return getMediaUrl(store, opts, isImageKitEnabled);
    }

    return store.settings.brandImageId
        ? getMediaUrl(store.settings.brandImageId, opts, isImageKitEnabled)
        : null;
};

export const getStoreBackgroundImageUrl = (
    store: Store | string,
    opts: Partial<GetMediaUrlOpts> = {
        height: DEFAULT_IMAGE_SIZE,
        width: DEFAULT_IMAGE_SIZE,
    },
    isImageKitEnabled?: boolean,
): string | null => {
    if (typeof store === "string") {
        return getMediaUrl(store, opts, isImageKitEnabled);
    }

    return store.settings.backgroundImageId
        ? getMediaUrl(store.settings.backgroundImageId, opts, isImageKitEnabled)
        : null;
};

export const getStoreShortAddress = (store: Store): string | null => {
    if (!store.address) {
        return null;
    }

    const address = store.address;
    if (!address.locality && address.city) {
        return address.city;
    }

    return `${address.locality}, ${address.city ?? address.state ?? address.country ?? ""}`;
};

export const getStoreUrl = (slug: string, storeDomain: string): string => {
    return "https://" + slug + `.${storeDomain}`;
};

export const getStoreDomain = (storeVersion?: string): string => {
    if (!storeVersion) {
        return MINIS_SITE_DOMAIN;
    }

    return storeVersion === DEFAULT_STORE_VERSION ? MINIS_DOMAIN : MINIS_SITE_DOMAIN;
};
