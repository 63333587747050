/* eslint-disable react-native/no-inline-styles */
import React from "react";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { Pressable, StyleSheet, Platform, ViewStyle } from "react-native";

import { Box } from "@swiggy-private/rn-adaptive-layout";
import { RNStory, StoryProps } from "@swiggy-private/react-native-stories";
import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";
import { format } from "@swiggy-private/common-helpers";

import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { useShareCheckForInApp } from "@minis-consumer/helpers/user-agent-app-name";
import { IHightlight } from "@minis-consumer/interfaces/store";
import { useGetProducts } from "@minis-consumer/hooks/use-get-products";
import type { RouteList } from "@minis-consumer/interfaces/route";
import { useRenderClientOnly } from "@minis-consumer/hooks/use-render-client-only";
import { RENDER_ON_SERVER_ERROR_MSG } from "@minis-consumer/constants/error";
import { Product } from "@minis-consumer/interfaces/catalog";
import { getStoreDomain } from "@minis-consumer/helpers/store";

import { Header } from "./components/header";
import { Footer } from "./components/footer";
import { StoryCover } from "./components/story-cover";
import { SHARE_TEXT } from "./constants";
import { storiesAnalytics } from "./analytics";

import { transformStories, getSelectedStoryIndex, timeAgo } from "./helpers";

interface IHighlightProps {
    highlights: IHightlight[];
    storeId: string;
    slug: string;

    storyId?: string;
    storiesProps?: Partial<StoryProps>;
}

const StoreStoriesComponent: React.FC<IHighlightProps> = ({
    highlights,
    storeId,
    slug,
    storyId,
    storiesProps,
}) => {
    useRenderClientOnly(RENDER_ON_SERVER_ERROR_MSG);
    const executeShare = useShareCheckForInApp();
    const { value: theme } = useTheme();

    const stories = React.useMemo(() => transformStories(highlights), [highlights]);
    const products = useGetProducts();
    const navigation = useNavigation<NavigationProp<RouteList>>();
    const storeInfo = useStoreInfo();

    const brandColor = storeInfo.settings.brandColor ?? theme["color-primary"];
    const storeDomain = getStoreDomain(storeInfo.storeVersion);

    const [pauseAnimation, setPauseAnimation] = React.useState(false);
    const [showSelectedStory, setShowSelectedStory] = React.useState(storyId !== undefined);
    const analytics = React.useRef(storiesAnalytics(storeId, highlights)).current;

    const toggleAnimation = (value: boolean): void => {
        setPauseAnimation(value);
    };

    const renderCloseComponent = (): React.ReactNode => {
        return (
            <SvgIcon
                icon="Close"
                height={36}
                width={24}
                color={"color-basic-0"}
                accessible
                accessibilityLabel="Cancel"
                style={{ marginLeft: "auto" }}
            />
        );
    };

    const onSharePress = React.useCallback(() => {
        const firstStory = highlights[0].stories[0].id;
        const link = `https://${slug}.${storeDomain}?story_id=${firstStory}`;
        const shareText = format(SHARE_TEXT, highlights[0].title, storeInfo.name, link);
        executeShare({
            message: shareText,
        });
        analytics.sendShareClickEvent();
    }, [analytics, executeShare, highlights, slug, storeInfo.name]);

    const renderStoryActions = (): React.ReactNode => {
        return (
            <Pressable
                onPress={onSharePress}
                hitSlop={{ top: 16, bottom: 16, left: 16, right: 16 }}>
                <Box
                    direction="column"
                    ml={SpacingValue["space-x-small"]}
                    style={[
                        styles.share,
                        // to make it work for web
                        { filter: "drop-shadow(1px 4px 2px rgb(0, 0, 0,0.7) )" } as ViewStyle,
                    ]}>
                    <SvgIcon icon="Share" color={"color-basic-0"} width={24} height={24} />
                </Box>
            </Pressable>
        );
    };

    const goToChat = (): void => {
        navigation.navigate("ChatConversation", { storeId });
    };

    const goToPDP = (productId: string): void => {
        navigation.navigate("Product", {
            id: productId,
            slug: slug,
        });
    };

    const renderFooter = (
        highlightIndex: number,
        storyIndex: number,
        onClose: VoidFunction,
    ): React.ReactNode => {
        const currentHighlight = highlights[highlightIndex];
        const story = stories[highlightIndex].stories[storyIndex];
        const productIds = currentHighlight.stories[storyIndex].products ?? [];
        const listData = productIds
            .map((productId) => {
                return products.find((product) => product.id === productId);
            })
            .filter((product) => product !== undefined) as Product[];

        return (
            <Footer
                storeId={storeId}
                highlightName={currentHighlight.title ?? ""}
                story={story}
                listData={listData}
                slug={slug}
                goToChat={goToChat}
                goToPDP={goToPDP}
                onClose={onClose}
                brandColor={brandColor}
                toggleAnimation={toggleAnimation}
                analytics={analytics}
            />
        );
    };
    const renderStoryEndCover = ({ onClose }: { onClose: VoidFunction }): React.ReactNode => {
        const firstStory = highlights[0].stories[0].id;
        return (
            <StoryCover
                link={`https://${slug}.${storeDomain}?story_id=${firstStory}`}
                storeId={storeId}
                renderCloseComponent={renderCloseComponent}
                onClose={onClose}
                goToChat={goToChat}
                analytics={analytics}
            />
        );
    };

    const renderHeaderComponent = (highlightIndex: number, storyIndex: number): React.ReactNode => {
        const { brandImageId } = storeInfo.settings;

        const currentHighlight = highlights[highlightIndex];
        const timeAgoText = timeAgo(currentHighlight.stories[storyIndex].createdAt);
        return (
            <Header
                title={storeInfo.name}
                storeId={storeId}
                timestamp={timeAgoText}
                image={brandImageId ?? ""}
                analytics={analytics}
            />
        );
    };

    const { highlightIndex, storyIndex } = React.useMemo(
        () => getSelectedStoryIndex(stories, storyId),
        [stories, storyId],
    );

    if (stories.length === 0) {
        return null;
    }

    return (
        <RNStory
            data={stories}
            duration={8}
            renderHeaderComponent={renderHeaderComponent}
            renderCloseComponent={renderCloseComponent}
            renderStoryActions={renderStoryActions}
            renderFooter={renderFooter}
            renderStoryEndCover={renderStoryEndCover}
            selectedHighlightIndex={showSelectedStory ? highlightIndex : undefined}
            selectedStoryIndex={showSelectedStory ? storyIndex : undefined}
            pauseAnimation={pauseAnimation}
            analytics={analytics}
            onClose={() => setShowSelectedStory(false)}
            {...storiesProps}
        />
    );
};

export const StoreStories = React.memo(StoreStoriesComponent);

const styles = StyleSheet.create({
    share: {
        textShadowOffset: { width: -100, height: -100 },
        textShadowColor: "#000",
        shadowColor: "#000",
        shadowOpacity: 0.7,
        textShadowRadius: 4,
        shadowOffset: Platform.OS !== "web" ? { width: 1, height: 2 } : undefined,
        elevation: 4,
        backgroundColor: "#0000",
    },
});
