import React from "react";
import { Animated, useWindowDimensions, Platform, InteractionManager, Easing } from "react-native";
import { useSuspense } from "@rest-hooks/react";

import { useAnimatedValue } from "@swiggy-private/rn-dls";
import { Box } from "@swiggy-private/rn-adaptive-layout";

import { AsyncComponent } from "@minis-consumer/components/async-component";
import { FetchClearCartFeedbackOptions } from "@minis-consumer/resources/cart";

import { GenericFeedbackContent, GenericFeedbackProps } from "./components/feedback-content";
import { DEFAULTS } from "./constants";
import { Loader } from "./components/loader";

const GenericFeedbackCardComponent: React.FC<GenericFeedbackProps> = React.memo((props) => {
    const feedbackOptions = useSuspense(FetchClearCartFeedbackOptions);
    const animatedValue = useAnimatedValue(0);

    const { width: screenWidth } = useWindowDimensions();

    const taskRef = React.useRef<ReturnType<typeof InteractionManager.runAfterInteractions> | null>(
        null,
    );

    const [hasAnimationCompleted, setAnimationCompleted] = React.useState(false);

    const onAnimate = React.useCallback(
        (cb?: VoidFunction) => {
            taskRef.current?.cancel();

            taskRef.current = InteractionManager.runAfterInteractions(() => {
                Animated.timing(animatedValue, {
                    toValue: 1,
                    duration: DEFAULTS.TRANSLATE_ANIMATION_DURATION,
                    easing: Easing.bezier(0.32, 0, 0.67, 0),
                    useNativeDriver: Platform.OS !== "web",
                }).start((finished) => {
                    if (finished) {
                        setAnimationCompleted(true);
                        cb?.();
                    }
                });
            });
        },
        [animatedValue],
    );

    const onCta = React.useCallback(() => {
        onAnimate(props.onPressCta);
    }, [onAnimate, props.onPressCta]);

    const onClose = React.useCallback(() => {
        onAnimate(props.onPressClose);
    }, [onAnimate, props]);

    const animatedStyle = {
        transform: [
            {
                translateX: animatedValue.interpolate({
                    inputRange: [0, 1],
                    outputRange: [0, screenWidth],
                }),
            },
        ],
    };

    const content = React.useMemo(() => {
        const options = props?.isBulk
            ? feedbackOptions?.bulkFeedbackOptions
            : feedbackOptions?.individualFeedbackOptions;

        return { ...props, ...options };
    }, [feedbackOptions?.bulkFeedbackOptions, feedbackOptions?.individualFeedbackOptions, props]);

    if (hasAnimationCompleted) {
        return null;
    }

    return (
        <Box style={props.containerStyle}>
            <Animated.View style={animatedStyle}>
                <GenericFeedbackContent {...content} onPressCta={onCta} onPressClose={onClose} />
            </Animated.View>
        </Box>
    );
});

if (__DEV__) {
    GenericFeedbackCardComponent.displayName = "GenericFeedbackCardComponent";
}

const GenericFeedbackCartMain: React.FC<GenericFeedbackProps> = (props) => {
    return (
        <AsyncComponent fallback={<Loader containerStyle={props.containerStyle} />}>
            <GenericFeedbackCardComponent {...props} />
        </AsyncComponent>
    );
};

export const GenericFeedbackCard = React.memo(GenericFeedbackCartMain);
