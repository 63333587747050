export const TEST_IDS = {
    SNACKBAR_TEXT: "snackbar-text",
    CLOSE: "close-icon-cta",
};

export const DISPLAY_STRINGS = {
    BLOCKED_BY_CUSTOMER: "You can't send a message as you've blocked this seller",
    BLOCKED_BY_SELLER: "You can't send a message to this seller",
};

export const ANALYTICS_EVENTS = {
    MOUNT: "chat-blocked-toast",
};

export const SNACKBAR_TIMEOUT = 5000;
