import React, { useRef } from "react";
import { StyleSheet, TextInput, Platform, ViewStyle } from "react-native";

import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";
import { FormInput } from "@swiggy-private/react-native-ui";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { Stack, useScreenSize, ScreenSize, Box } from "@swiggy-private/rn-adaptive-layout";
import { useMount } from "@swiggy-private/react-hooks";

import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { useCartViewData } from "@minis-consumer/hooks/use-cart";
import { Analytics } from "@minis-consumer/analytics";
import { ExpansionPanel } from "@minis-consumer/components/expansion-panel";

import { AutoCompleteAddress, AutoCompleteAddressProps } from "../autocomplete-address";

interface Props {
    completeAddress: string;
    landmark: string;
    pincode: string;
    city: string;
    stateValue: string;
    handleAddress: (value: string) => void;
    handleLandmark: (value: string) => void;
    handleCity: (value: string) => void;
    handleState: (value: string) => void;
    handlePincode: (value: string) => void;
    handleAutoCompleteAddress: AutoCompleteAddressProps["handleAddress"];
    errors: Record<string, string | null>;
    isAddressFilled: boolean;
    canCollapse?: boolean;
    hintStyles?: ViewStyle;
}

const DELIVERY_DETAILS_FORM_LABELS = {
    COMPLETE_ADDRESS: "Complete Address",
    SEARCH_FOR_ADDRESS: "Search for address",
    LANDMARK: "Apartment, floor, etc (optional)",
    PINCODE: "Pincode",
    CITY: "City",
    STATE: "State",
};

const DISPLAY_COPIES = {
    DELIVERY_DETAILS_FORM_HEADER: "Where should we deliver?",
};

const ICON_SiZE = 20;

export const DeliveryDetails: React.FC<Props> = ({
    completeAddress,
    landmark,
    pincode,
    city,
    stateValue,
    handleAddress,
    handleLandmark,
    handleCity,
    handleState,
    handlePincode,
    errors,
    canCollapse,
    isAddressFilled,
    handleAutoCompleteAddress,
    hintStyles,
}) => {
    const { value: theme } = useTheme();
    const screenSize = useScreenSize();

    const store = useStoreInfo();
    const cart = useCartViewData(store.storeId);

    const addressRef = useRef<TextInput>(null);
    const landmarkRef = useRef<TextInput>(null);
    const pincodeRef = useRef<TextInput>(null);
    const cityRef = useRef<TextInput>(null);
    const stateRef = useRef<TextInput>(null);

    const isLargeScreen = React.useMemo(() => screenSize === ScreenSize.Large, [screenSize]);

    const triggerImpressionEvent = React.useCallback(
        (category: string) => {
            Analytics.impressionEvent({
                category,
                label: cart?.id || "-",
            });
        },
        [cart?.id],
    );

    const triggerClicKEvent = React.useCallback(
        (category: string) => {
            Analytics.clickEvent({
                category,
                label: cart?.id || "-",
            });
        },
        [cart?.id],
    );

    useMount(() => {
        triggerImpressionEvent("shipment-address-line-2");
        triggerImpressionEvent("shipment-address-pincode");
        triggerImpressionEvent("shipment-address-search");
    });

    const textInputStyle = {
        fontFamily: theme["text-subheader-2-font-family"],
        letterSpacing: theme["text-subheader-2-letter-spacing"],
        color: theme["color-basic-100"],
    };

    const containerStyles = {
        backgroundColor: theme["color-basic-0"],
        paddingHorizontal: isLargeScreen ? 0 : SpacingValue["space-medium"],
        paddingVertical: isLargeScreen ? 0 : SpacingValue["space-large"],
        borderRadius: SpacingValue["space-medium"],
        borderWidth: isLargeScreen ? 0 : 1,
        borderColor: theme["color-basic-15"],
    };

    const onSubmitAddress = (): void => {
        triggerClicKEvent("shipment-address-search");
        landmarkRef.current?.focus();
    };

    const onSubmitLandmark = (): void => {
        triggerClicKEvent("shipment-address-line-2");
        pincodeRef.current?.focus();
    };

    const onSubmitPinCode = (): void => {
        triggerClicKEvent("shipment-address-pincode");
        cityRef.current?.focus();
    };

    const customInputStyles = {
        ...hintStyles,
        marginBottom: isLargeScreen ? -SpacingValue["space-large"] : 0,
    };

    return (
        <Stack style={containerStyles}>
            <ExpansionPanel
                canCollapse={canCollapse}
                collapsed={false}
                title={DISPLAY_COPIES.DELIVERY_DETAILS_FORM_HEADER}>
                <Stack spacing={SpacingValue["space-x-large"]}>
                    <Box style={styles.addressInputContainer}>
                        <FormInput
                            name="address"
                            label={
                                isAddressFilled
                                    ? DELIVERY_DETAILS_FORM_LABELS.COMPLETE_ADDRESS
                                    : DELIVERY_DETAILS_FORM_LABELS.SEARCH_FOR_ADDRESS
                            }
                            style={styles.firstInputBox}
                            onChangeText={handleAddress}
                            placeholderTextColor={theme["color-basic-60"]}
                            textStyle={[textInputStyle, styles.addressInput]}
                            testID="address"
                            textContentType="fullStreetAddress"
                            blurOnSubmit={false}
                            value={completeAddress}
                            returnKeyType="next"
                            accessoryRight={() => (
                                <SvgIcon
                                    icon="Magnifier"
                                    color={theme["color-basic-60"]}
                                    width={ICON_SiZE}
                                    height={ICON_SiZE}
                                />
                            )}
                            onSubmitEditing={onSubmitAddress}
                            hintContainerStyle={hintStyles}
                            autoComplete="street-address"
                            error={errors.address != null}
                            hint={errors.address || ""}
                            ref={addressRef}
                        />
                        <AutoCompleteAddress
                            address={completeAddress}
                            handleAddress={handleAutoCompleteAddress}
                            addressInputRef={addressRef}
                        />
                    </Box>
                    <FormInput
                        ref={landmarkRef}
                        name="landmark"
                        label={DELIVERY_DETAILS_FORM_LABELS.LANDMARK}
                        onChangeText={handleLandmark}
                        placeholderTextColor={theme["color-basic-60"]}
                        textStyle={textInputStyle}
                        testID="landmark"
                        textContentType="streetAddressLine2"
                        blurOnSubmit={false}
                        returnKeyType="next"
                        value={landmark}
                        onSubmitEditing={onSubmitLandmark}
                        hintContainerStyle={hintStyles}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        autoComplete={Platform.OS === "web" ? "address-level3" : undefined}
                        error={errors.landmark != null}
                        hint={errors.landmark || ""}
                    />
                    <Stack
                        direction={isLargeScreen ? "row" : "column"}
                        justifyContent={isLargeScreen ? "space-between" : "center"}
                        spacing={
                            isLargeScreen
                                ? SpacingValue["space-small"]
                                : SpacingValue["space-x-large"]
                        }>
                        <FormInput
                            ref={pincodeRef}
                            name="postal-code"
                            label={DELIVERY_DETAILS_FORM_LABELS.PINCODE}
                            onChangeText={handlePincode}
                            placeholderTextColor={theme["color-basic-60"]}
                            textStyle={textInputStyle}
                            testID="pincode"
                            textContentType="postalCode"
                            keyboardType="number-pad"
                            maxLength={6}
                            blurOnSubmit={false}
                            value={pincode}
                            onSubmitEditing={onSubmitPinCode}
                            style={isLargeScreen ? styles.largeScreenInputStyles : {}}
                            autoComplete="postal-code"
                            inputMode="numeric"
                            hintContainerStyle={customInputStyles}
                            error={errors.pincode != null}
                            hint={errors.pincode || ""}
                        />
                        <FormInput
                            ref={cityRef}
                            name="city"
                            label={DELIVERY_DETAILS_FORM_LABELS.CITY}
                            onChangeText={handleCity}
                            placeholderTextColor={theme["color-basic-60"]}
                            textStyle={textInputStyle}
                            testID="city"
                            textContentType="addressCity"
                            spellCheck={false}
                            blurOnSubmit={false}
                            returnKeyType="next"
                            value={city}
                            onSubmitEditing={() => stateRef.current?.focus()}
                            style={isLargeScreen ? styles.largeScreenInputStyles : {}}
                            hintContainerStyle={customInputStyles}
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            autoComplete={Platform.OS === "web" ? "address-level2" : undefined}
                            error={errors.city != null}
                            hint={errors.city || ""}
                        />
                        <FormInput
                            ref={stateRef}
                            name="state"
                            label={DELIVERY_DETAILS_FORM_LABELS.STATE}
                            onChangeText={handleState}
                            placeholderTextColor={theme["color-basic-60"]}
                            textStyle={textInputStyle}
                            testID="state"
                            textContentType="addressState"
                            spellCheck={false}
                            blurOnSubmit={false}
                            returnKeyType="next"
                            value={stateValue}
                            style={isLargeScreen ? styles.largeScreenInputStyles : {}}
                            hintContainerStyle={customInputStyles}
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            autoComplete={Platform.OS === "web" ? "address-level1" : undefined}
                            error={errors.state != null}
                            hint={errors.state || ""}
                        />
                    </Stack>
                </Stack>
            </ExpansionPanel>
        </Stack>
    );
};

const styles = StyleSheet.create({
    firstInputBox: {
        marginTop: SpacingValue["space-x-large"],
    },
    largeScreenInputStyles: {
        width: "30%",
    },
    addressInputContainer: {
        zIndex: 1,
    },
    addressInput: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    addressHint: {
        paddingBottom: SpacingValue["space-xx-small"],
    },
});
