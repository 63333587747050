import React, { useCallback, useState } from "react";
import {
    GenericErrorView,
    getErrorDescription,
    getErrorTitle,
} from "@swiggy-private/react-native-ui";
import { ErrorBoundary } from "react-error-boundary";

import { Logger } from "@minis-consumer/includes/logger";

interface ResetErrorBoundaryProps {
    onReset?: () => void;
    onError?: (err: unknown) => void;
}

export const ResetErrorBoundary: React.FC<React.PropsWithChildren<ResetErrorBoundaryProps>> = ({
    children,
    onReset,
    onError,
}) => {
    const [resetKey, setResetKey] = useState(0);
    const onErrorReset = useCallback(() => {
        setResetKey((state) => state + 1);
        onReset?.();
    }, [onReset]);

    const onErrorLog = useCallback(
        (e: Error) => {
            onError?.(e);
            Logger.recordError(e);
        },
        [onError],
    );

    return (
        <ErrorBoundary
            fallbackRender={({ error, resetErrorBoundary }) => (
                <GenericErrorView
                    title={error ? getErrorTitle(error) : null}
                    message={error ? getErrorDescription(error) : null}
                    onRetry={resetErrorBoundary}
                />
            )}
            resetKeys={[resetKey]}
            onReset={onErrorReset}
            onError={onErrorLog}>
            {children}
        </ErrorBoundary>
    );
};

export const withResetErrorBoundary =
    <P extends object>(Component: React.ComponentType<P>, onReset?: () => void): React.FC<P> =>
    // eslint-disable-next-line react/display-name
    (props) => {
        return (
            <ResetErrorBoundary onReset={onReset}>
                <Component {...props} />
            </ResetErrorBoundary>
        );
    };
