import { Platform } from "react-native";

export const DISPLAY_STRINGS = {
    CLEAR_CART: "Clear and Proceed",
    CANCEL: "Cancel",
    TITLE: "Clear cart to add this item?",
};

export const ANALYTICS_COPIES = {
    MODAL_MOUNT: "clear-current-cart-pop-up",
    CANCEL_CTA: "clear-current-cart-cancel",
    CLEAR_CART_CTA: "clear-current-cart",
};

export const IS_WEB = Platform.OS === "web";
export const MAX_MODAL_WIDTH_WEB = 300;
