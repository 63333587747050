import React from "react";
import { Pressable, StyleProp, StyleSheet, ViewStyle } from "react-native";

import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";

import { WidgetPresser } from "@minis-consumer/components/widget-presser";

import { DEFAULTS } from "../../constants";

interface MultiSelectOptionProps {
    optionValue: string;
    onPress: (s: string) => void;

    canAnimateOnSelection?: boolean;
    style?: StyleProp<ViewStyle>;
}

const MultiSelectOptionComponent: React.FC<MultiSelectOptionProps> = ({
    optionValue,
    canAnimateOnSelection = false,
    style,
    onPress,
}) => {
    const [isSelected, setSelected] = React.useState(false);
    const [shouldAnimate, setAnimate] = React.useState(false);

    const { value: theme } = useTheme();

    const extraStyles = {
        borderColor: isSelected ? theme["color-positive-50"] : theme["color-basic-15"],
        backgroundColor: isSelected ? theme["color-positive-25"] : theme["color-basic-0"],
    };

    const commonCallback = React.useCallback(() => {
        setAnimate(false);
        setSelected(!isSelected);
        onPress(optionValue);
    }, [isSelected, onPress, optionValue]);

    const onOptionPress = React.useCallback(() => {
        if (canAnimateOnSelection) {
            setAnimate(true);
            return;
        }

        commonCallback();
    }, [canAnimateOnSelection, commonCallback]);

    return (
        <WidgetPresser
            animationDuration={DEFAULTS.OPTION_SELECTION_ANIMATION_DURATION}
            shouldTriggerAnimation={shouldAnimate}
            shouldReverseAnimation={isSelected}
            callback={commonCallback}>
            <Pressable style={[extraStyles, styles.box, style]} onPress={onOptionPress}>
                <Text category="b3" color="color-basic-60" weight="medium">
                    {optionValue}
                </Text>
            </Pressable>
        </WidgetPresser>
    );
};

export const styles = StyleSheet.create({
    box: {
        borderWidth: 1,
        paddingHorizontal: SpacingValue["space-small"],
        paddingVertical: 5 * SpacingValue["space-xxx-small"],
        borderRadius: 12,
    },
});

export const MultiSelectOption = React.memo(MultiSelectOptionComponent);
