import { ParamListBase, StackNavigationState, useNavigation } from "@react-navigation/core";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

export const useGetPreviousRoute = <RouteList extends ParamListBase>():
    | StackNavigationState<RouteList>["routes"][0]
    | null => {
    const navigation = useNavigation<NativeStackNavigationProp<RouteList>>();
    const state = navigation.getState();
    const routes = state.routes;

    if (routes.length >= 2) {
        return routes[routes.length - 2];
    }

    return null;
};
