import React, { ReactElement } from "react";
import { ScrollView } from "react-native";

export const DraggableScrollView: React.FC<{ children: ReactElement }> = ({ children }) => {
    const sliderRef = React.useRef<HTMLElement>();
    const isDown = React.useRef(false);
    const startX = React.useRef(0);
    const scrollLeft = React.useRef(0);
    const animationFrameRef = React.useRef(0);

    const onMousedown = React.useCallback((e: MouseEvent) => {
        isDown.current = true;
        if (sliderRef.current) {
            sliderRef.current.style.cursor = "grabbing";
        }
        startX.current = e.pageX - (sliderRef.current?.offsetLeft || 0);
        scrollLeft.current = sliderRef.current?.scrollLeft || 0;
    }, []);

    const onMouseleave = React.useCallback(() => {
        isDown.current = false;
        if (sliderRef.current) {
            sliderRef.current.style.cursor = "grabbing";
            window.cancelAnimationFrame(animationFrameRef.current);
        }
    }, []);

    const onMouseup = React.useCallback(() => {
        isDown.current = false;
        if (sliderRef.current) {
            sliderRef.current.style.cursor = "default";
            window.cancelAnimationFrame(animationFrameRef.current);
        }
    }, []);

    const onMousemove = React.useCallback(
        (e: MouseEvent) => {
            if (!isDown.current) {
                return;
            }
            e.preventDefault();

            animationFrameRef.current = window.requestAnimationFrame(() => {
                const x = e.pageX - (sliderRef.current?.offsetLeft || 0);
                const walk = (x - startX.current) * 3; //scroll-fast
                if (sliderRef.current) {
                    sliderRef.current.scrollLeft = scrollLeft.current - walk;
                }
            });
        },
        [isDown, scrollLeft, startX],
    );

    React.useEffect(() => {
        const sliderRefInstance = sliderRef.current;
        sliderRef.current?.addEventListener("mousemove", onMousemove);
        sliderRef.current?.addEventListener("mousedown", onMousedown);
        sliderRef.current?.addEventListener("mouseleave", onMouseleave);
        sliderRef.current?.addEventListener("mouseup", onMouseup);

        return () => {
            if (sliderRefInstance) {
                window.cancelAnimationFrame(animationFrameRef.current);
                sliderRefInstance.removeEventListener("mousemove", onMousemove);
                sliderRefInstance.removeEventListener("mousedown", onMousedown);
                sliderRefInstance.removeEventListener("mouseleave", onMouseleave);
                sliderRefInstance.removeEventListener("mouseup", onMouseup);
            }
        };
    }, [onMousemove, onMousedown, onMouseleave, onMouseup]);

    return (
        <ScrollView horizontal ref={sliderRef as unknown as React.Ref<ScrollView> | undefined}>
            {children}
        </ScrollView>
    );
};
