import React from "react";
import { ImageResizeMode, StyleSheet } from "react-native";

import { CdnImage } from "@swiggy-private/react-native-ui";
import { Surface, SurfaceProps, useTheme } from "@swiggy-private/rn-dls";

import { TEST_IDS_COMMON } from "@minis-consumer/constants/test-ids/common";

interface ShopLogoProps extends Omit<SurfaceProps, "children"> {
    url?: string | null;
    size?: number;
    borderRadius?: number;
    resizeMode?: ImageResizeMode;
    isImageKitEnabled?: boolean;
}

const SHOP_LOGO_IMG_FALLBACK = "minis/shop_logo_fallback";

export const ShopLogo: React.FC<ShopLogoProps> = ({
    url,
    size = 60,
    borderRadius = 16,
    style,
    resizeMode = "cover",
    isImageKitEnabled,
    ...props
}) => {
    const { value: theme } = useTheme();

    const containerStyle = {
        width: size,
        height: size,
        backgroundColor: theme["color-basic-0"],
        borderColor: theme["color-basic-30"],
        borderRadius,
    };

    const imageStyle = {
        borderRadius,
    };

    return (
        <Surface style={[styles.container, containerStyle, style]} {...props}>
            <CdnImage
                isImageKitEnabled={isImageKitEnabled}
                id={url ?? SHOP_LOGO_IMG_FALLBACK}
                width={size - StyleSheet.hairlineWidth}
                height={size - StyleSheet.hairlineWidth}
                style={imageStyle}
                resizeMode={resizeMode}
                testID={TEST_IDS_COMMON.SHOP_LOGO}
            />
        </Surface>
    );
};

const styles = StyleSheet.create({
    container: {
        alignItems: "center",
        justifyContent: "center",
        elevation: 1,
        borderWidth: StyleSheet.hairlineWidth,
    },
});
