import { AccountRouteList } from "@minis-consumer/interfaces/route";

export interface AccountDropDown {
    name: string;
    key: string;
    routeName?: keyof AccountRouteList;
}

export const DROPDOWN_ITEMS: AccountDropDown[] = [
    { name: "Saved addresses", key: "saved-addresses", routeName: "SavedAddresses" },
    { name: "My account", key: "account" },
    { name: "Settings", key: "settings", routeName: "ProfileSettings" },
    { name: "Logout", key: "logout" },
];
