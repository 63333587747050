import { ApiError, API_SUCCESS_STATUS_CODE } from "@swiggy-private/connect-api-core";
import { Endpoint } from "@swiggy-private/http-client";

import { getApiClient } from "@minis-consumer/includes/api";
import {
    PaymentGroup,
    PaymentServiceStatus,
    PaymentTransaction,
} from "@minis-consumer/interfaces/payment";

import { OrderEndpoint, PaymentEndpoint } from "../endpoint";

export interface FetchPaymentMethodsParams {
    storeId: string;
    addressId?: string;
    getSavedCards?: boolean;
    orderId?: string;
    lat?: string;
    lng?: string;
}

export interface FetchPaymentMethodsResponse {
    paymentAmount: string;
    paymentMethods: {
        paymentGroup: PaymentGroup[];
    };
    payments: PaymentServiceStatus;
}

export const fetchPaymentMethodsApi = async ({
    storeId,
    addressId,
    getSavedCards = true,
    orderId,
    lat,
    lng,
}: FetchPaymentMethodsParams): Promise<FetchPaymentMethodsResponse> => {
    const endpoint = Endpoint.from(`${PaymentEndpoint}/get-methods`);
    const response = await getApiClient().get<FetchPaymentMethodsResponse>({
        endpoint,
        queryParams: {
            storeId,
            addressId: addressId ?? "",
            getSavedCards: getSavedCards ? "1" : "0",
            orderId: orderId ?? "",
            lat: lat ?? "",
            lng: lng ?? "",
        },
    });

    if (response.statusCode === API_SUCCESS_STATUS_CODE && response.data) {
        return response.data;
    }

    return Promise.reject(
        new ApiError(response.statusMessage || "Could not fetch payment methods", response),
    );
};

export type MakePaymentUpiMode = "CUSTOM" | "GPAY" | "BHIM" | "PAYTM" | "PHONEPE" | "OTHERS";

export interface MakePaymentApiParams {
    storeId: string;
    orderId: string;
    savePaymentDetails: boolean;
    redirectUrl: string;
    paymentDetails:
        | {
              paymentMethod: "UPI";
              upiMode: MakePaymentUpiMode;
              upiVpa?: string;
          }
        | {
              paymentMethod: "CARD";
              cardBin?: string;
              cardId?: string;
          }
        | {
              paymentMethod: "NETBANKING";
              bankName: string;
              bankCode: string;
          };
}

export type MakePaymentApiResponse = PaymentTransaction;

export const makePaymentApi = async (params: MakePaymentApiParams): Promise<PaymentTransaction> => {
    const endpoint = Endpoint.from(`${OrderEndpoint}/{%%orderId%%}/payment`, {
        orderId: params.orderId,
    });

    const response = await getApiClient().post<MakePaymentApiResponse>({
        endpoint,
        body: {
            savePaymentDetails: params.savePaymentDetails,
            redirectUrl: params.redirectUrl,
            paymentDetails: params.paymentDetails,
        },
    });

    if (response.statusCode === API_SUCCESS_STATUS_CODE && response.data) {
        return response.data;
    }

    return Promise.reject(
        new ApiError(response.statusMessage || "Failed to create an order", response),
    );
};

export interface ConfirmPaymentTransactionParams {
    transactionId: string;
    paymentId?: string;
}

export const confirmPaymentTransactionApi = async (
    params: ConfirmPaymentTransactionParams,
): Promise<PaymentTransaction> => {
    const endpoint = Endpoint.from(`${PaymentEndpoint}/confirm-transaction`);
    const response = await getApiClient().post<PaymentTransaction>({
        endpoint,
        body: {},
        queryParams: {
            ...params,
        },
    });

    if (response.statusCode === API_SUCCESS_STATUS_CODE && response.data) {
        return response.data;
    }

    return Promise.reject(
        new ApiError(
            response.statusMessage || "Failed to confirm the payment transaction",
            response,
        ),
    );
};

export const verifyUPI = async (params: { vpa: string }): Promise<boolean> => {
    const endpoint = Endpoint.from(`${PaymentEndpoint}/validate/upi`);
    const response = await getApiClient().get<boolean>({
        endpoint,
        queryParams: {
            ...params,
        },
    });

    if (response.statusCode === API_SUCCESS_STATUS_CODE) {
        return !!response.data;
    }

    return Promise.reject(
        new ApiError(response.statusMessage || "Could not verify UPI ID", response),
    );
};
