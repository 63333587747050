import * as React from "react";
import { StyleSheet, TouchableWithoutFeedback, useWindowDimensions } from "react-native";
import { SpacingValue, Surface, SurfaceProps } from "@swiggy-private/rn-dls";
import { Box } from "@swiggy-private/rn-adaptive-layout";

import { AuthContext } from "../../contexts/auth-context";

type AuthScreenContainerProps = SurfaceProps;

export const AuthScreenContainer: React.FC<AuthScreenContainerProps> = ({
    children,
    style,
    ...props
}) => {
    const authContext = React.useContext(AuthContext);
    const { width: windowWidth } = useWindowDimensions();
    const width = Math.min(536, windowWidth - SpacingValue["space-x-large"] * 2);

    if (authContext?.fullscreen) {
        return <Box flex={1}>{children}</Box>;
    }

    return (
        <Box flex={1} justifyContent="center" alignItems="center">
            <TouchableWithoutFeedback>
                <Surface style={[styles.surface, { width }, style]} {...props}>
                    {children}
                </Surface>
            </TouchableWithoutFeedback>
        </Box>
    );
};

const styles = StyleSheet.create({
    surface: {
        paddingHorizontal: SpacingValue["space-x-large"],
        paddingVertical: SpacingValue["space-x-large"],
        elevation: 1,
        borderRadius: 16,
    },
});
