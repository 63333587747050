import React from "react";
import { Pressable, StyleSheet } from "react-native";

import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text } from "@swiggy-private/rn-dls";

import { ProductVariantValuesLabel } from "@minis-consumer/components/variants";
import { useGetProductCtaProps } from "@minis-consumer/components/product-cta/hooks/use-get-cta-actions";
import { ProductCta } from "@minis-consumer/components/product-cta";
import { Product } from "@minis-consumer/interfaces/catalog";

interface VariantListItemProps {
    price: number;
    type: Product["productType"];
    itemId: string;
    storeId: string;
    quantity: number;
    values: string[];
    available: boolean;
    onAdd: (variantId?: string) => void;
    onRemove: (variantId?: string) => void;

    discountedPrice?: number;
    variantId?: string;
}

const VariantListItem: React.FC<VariantListItemProps> = ({
    price,
    type,
    itemId,
    values,
    storeId,
    variantId,
    available,
    discountedPrice,
    onAdd,
    onRemove,
}) => {
    const showDiscount = discountedPrice && price > discountedPrice;

    const productCtaActionsAndStyles = useGetProductCtaProps({
        productId: itemId,
        storeId,
        buttonProps: {
            style: available ? styles.addBtn : styles.unavailableBtn,
            elevation: 0,
        },
        selectedVariant: variantId,
        isVariantList: true,
        productActionCtaName: "product-action-cta-variant",
        onAdd: () => onAdd(variantId),
        onRemoveOrDecrement: () => onRemove(variantId),
    });

    return (
        <Pressable>
            <Stack key={variantId} direction="row" justifyContent="space-between">
                <Stack spacing={SpacingValue["space-x-small"]} flex={1}>
                    <ProductVariantValuesLabel
                        values={values}
                        textProps={{
                            category: "b2",
                            weight: "medium",
                            color: "color-basic-75",
                        }}
                    />
                    <Stack direction="row" spacing={SpacingValue["space-x-small"]}>
                        <Text category="b2" weight="bold" color="high">
                            ₹{showDiscount ? discountedPrice : price}
                        </Text>
                        {showDiscount ? (
                            <Text category="b2" color="color-basic-45" style={styles.striked}>
                                ₹{price}
                            </Text>
                        ) : null}
                    </Stack>
                </Stack>

                <ProductCta
                    {...productCtaActionsAndStyles}
                    productId={itemId}
                    storeId={storeId}
                    price={price}
                    type={type}
                />
            </Stack>
        </Pressable>
    );
};

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: SpacingValue["space-medium"],
    },
    closeIcon: {
        borderRadius: 100,
    },
    addBtn: {
        width: 96,
        maxWidth: 96,
        minHeight: 40,
        height: 40,
    },
    unavailableBtn: {
        width: 125,
        maxWidth: 125,
    },
    headerDivider: {
        marginVertical: SpacingValue["space-large"],
    },
    striked: {
        textDecorationLine: "line-through",
    },
});

if (__DEV__) {
    VariantListItem.displayName = "VariantListItem";
}

export const VariantsCustomisationsListItem = React.memo(VariantListItem);
