import { useCallback } from "react";
import { ButtonProps } from "@swiggy-private/rn-dls";

import {
    useCartViewData,
    useLocalCartItem,
    useLocalCartVariantQuantity,
} from "@minis-consumer/hooks/use-cart";
import { useGetProduct } from "@minis-consumer/routes/product/hooks/use-get-product";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { useEnquiryModalHandler } from "@minis-consumer/hooks/use-enquiry-modal-handler";
import { Analytics } from "@minis-consumer/analytics";
import { Product } from "@minis-consumer/interfaces/catalog";
import { CartStatuses } from "@minis-consumer/interfaces/cart";
import { CART_V2_ERRORS_STATUS } from "@minis-consumer/routes/cart/components/item-list/constants";

import { useCustomNavigation } from "./use-get-minis-navigation";
import { useGetCtaProps } from "./use-get-cta-props";

import { ProductCtaProps } from "..";
import { ARD_EVENT_NAME, COPIES } from "../constants";

export type CounterActionType = "INCREMENT" | "DECREMENT";

interface Params {
    storeId: string;
    productId: string;

    buttonProps?: ButtonProps;
    isPdp?: boolean;
    selectedVariant?: string;
    isVariantList?: boolean;
    isCustomCartItem?: boolean;
    customProductDetails?: {
        productType: Product["productType"];
    };
    isEditForm?: boolean;
    variantIdForEdit?: string;
    sfWidget?: string;
    itemStatus?: CartStatuses;
    productActionCtaName?: string;

    onAdd?: VoidFunction;
    onRemoveOrDecrement?: VoidFunction;
    onEnquire?: VoidFunction;
    callBeforeNavigation?: VoidFunction;
}

// eslint-disable-next-line no-void
export const EmptyFunction = (): void => void 0;

export const useGetProductCtaProps = (params: Params): ProductCtaProps => {
    const {
        storeId,
        productId,
        buttonProps,
        isPdp,
        isVariantList,
        selectedVariant,
        isCustomCartItem,
        isEditForm,
        sfWidget,
        itemStatus,
        productActionCtaName,
        customProductDetails,
    } = params;

    const product = useGetProduct(params.productId);

    const [quantity, , , isItemInCart] = useLocalCartItem({
        storeId: storeId ?? "0",
        itemId: productId,
        productType: product?.productType ?? "PHYSICAL",
    });

    const { getVariantQuantityInCart } = useLocalCartVariantQuantity({ storeId });
    const cartViewData = useCartViewData(storeId);

    const isItemAddedInCart = selectedVariant ? isItemInCart(selectedVariant) : !!quantity;

    const variantDetails = product?.variantDetails?.variants.filter(
        (item) => item.id === selectedVariant,
    );

    const productDetails = {
        productType: customProductDetails?.productType ?? product?.productType,
        price: selectedVariant ? variantDetails?.[0]?.price : product?.price,
        inStock: product?.inStock,
        isItemInCart: isItemAddedInCart,
        isPdp,
        isVariantList,
        isCustomCartItem,
    };

    const productWithVariantsOnSF = sfWidget && product?.variantDetails?.variants?.length;

    const styleProps = useGetCtaProps(productDetails);

    const redirect = useRedirectToPdpHandler(params);
    const enquire = useEnquireActionHandler(params);
    const remove = useRemoveCta(params);
    const add = useAddOrBookOrIncrementProductCta(params);
    const editForm = useEditCta({
        ...params,
        productType: productDetails.productType,
        isCustomCartItem,
    });
    const getAnalyticsData = useConstructAnalyticsData();

    let ctaState: ProductCtaProps["ctaState"] = "UNAVAIALBLE";

    let singleActionHandler = EmptyFunction;

    if (isEditForm) {
        ctaState = "EDIT";
        singleActionHandler = editForm;
    } else if (isCustomCartItem) {
        ctaState = "REMOVE";
        singleActionHandler = remove;
    } else {
        if (productDetails.inStock) {
            if (!productDetails.price && !isItemAddedInCart) {
                ctaState = productWithVariantsOnSF ? "SHOW_VARIANTS" : "ENQUIRE";
                singleActionHandler = productWithVariantsOnSF ? add : enquire;
            } else {
                if (!isItemAddedInCart) {
                    if (
                        (productDetails.productType === "APPOINTMENT" ||
                            productDetails.productType === "PLAN") &&
                        !isPdp
                    ) {
                        ctaState = "REDIRECT";
                        singleActionHandler = redirect;
                    } else {
                        ctaState = "ADD";
                        singleActionHandler = add;
                    }
                } else {
                    if (!productDetails.productType || productDetails.productType === "PHYSICAL") {
                        ctaState = "COUNTER";
                    } else {
                        ctaState = "REMOVE";
                        singleActionHandler = remove;
                    }
                }
            }
        }
    }

    const doesItemHasFormOrSlotError = itemStatus
        ? CART_V2_ERRORS_STATUS.indexOf(itemStatus) > -1
        : false;

    const editCtaText = doesItemHasFormOrSlotError ? "Fill form" : "Edit";
    const finalCtaCtaText = isEditForm
        ? editCtaText
        : productWithVariantsOnSF
        ? COPIES.ADD_TO_CART
        : styleProps.ctaText;

    const finalCtaIcon = !productDetails.price ? undefined : styleProps.ctaIcon;

    const impressionEvent = useCallback(() => {
        const analyticsData = getAnalyticsData({
            quantity,
            ctaText: finalCtaCtaText,
            product,
            selectedVariant,
            ctaState,
            isVariantList,
            sfWidget,
            cartId: cartViewData?.id,
            billAmount: cartViewData?.bill?.billTotal,
            cartItemStatus: itemStatus,
            productActionCtaName,
        });

        Analytics.impressionEvent(analyticsData);
    }, [
        productId,
        selectedVariant,
        isVariantList,
        product,
        sfWidget,
        cartViewData,
        itemStatus,
        productActionCtaName,
        styleProps,
        ctaState,
        getAnalyticsData,
    ]);

    const incrementActionHandler = add;
    const decrementActionHandler = remove;

    return {
        type: productDetails.productType,
        isEditForm,
        productId,
        storeId,
        ctaState,
        ctaText: finalCtaCtaText,
        ctaIcon: finalCtaIcon,
        ctaIconColor: styleProps.ctaIconColor,
        ctaTextColor: styleProps.ctaTextColor,
        ctaColor: buttonProps?.color ?? styleProps.buttonProps.color,
        ctaStyle: [buttonProps?.style, styleProps.buttonProps.style],
        ctaTextStyle: buttonProps?.textStyle ?? styleProps.buttonProps.textStyle,
        ctaElevation: buttonProps?.elevation ?? styleProps.buttonProps.elevation,
        productActionCtaName,

        quantity: selectedVariant ? getVariantQuantityInCart(selectedVariant) : quantity,
        singleActionHandler,
        incrementActionHandler,
        decrementActionHandler,
        impressionEvent,
    };
};

export const useEnquireActionHandler = ({
    productId,
    storeId,
    onEnquire,
    selectedVariant,
    callBeforeNavigation,
}: Params): VoidFunction => {
    const product = useGetProduct(productId);

    const [, , , enquiryModalHandler] = useEnquiryModalHandler();
    const storeInfo = useStoreInfo();

    const navigation = useCustomNavigation();

    const handler = useCallback(() => {
        onEnquire?.();

        callBeforeNavigation?.();

        if (product?.productType) {
            navigation?.navigate("ProductForms", {
                id: productId,
                slug: storeInfo.slug,
                storeId,
                isEnquireMode: true,
                variantId: selectedVariant,
            });
        } else {
            /** open form with extra fields if valid - open form screen with param to send enquiry    */
            return enquiryModalHandler({
                open: true,
                id: productId,
                storeId,
            });
        }
    }, [
        onEnquire,
        product,
        navigation,
        storeInfo.slug,
        enquiryModalHandler,
        productId,
        storeId,
        selectedVariant,
        callBeforeNavigation,
    ]);

    return handler;
};

const useRedirectToPdpHandler = ({ productId, callBeforeNavigation }: Params): VoidFunction => {
    const navigation = useCustomNavigation();
    const storeInfo = useStoreInfo();

    const redirect = useCallback(() => {
        callBeforeNavigation?.();

        navigation?.navigate("Product", {
            id: productId,
            slug: storeInfo?.slug,
        });
    }, [productId, navigation, storeInfo, callBeforeNavigation]);

    return redirect;
};

const useAddOrBookOrIncrementProductCta = ({ onAdd }: Params): VoidFunction => {
    const handler = useCallback(() => {
        onAdd?.();
    }, [onAdd]);

    return handler;
};

const useRemoveCta = ({ onRemoveOrDecrement }: Params): VoidFunction => {
    const remove = useCallback(() => {
        onRemoveOrDecrement?.();
    }, [onRemoveOrDecrement]);

    return remove;
};

const useEditCta = ({
    productId,
    productType,
    variantIdForEdit,
    isCustomCartItem,
}: Params & {
    productType: Product["productType"];
}): VoidFunction => {
    const storeInfo = useStoreInfo();
    const navigation = useCustomNavigation();

    const navigateToSlotSelection = useCallback(() => {
        navigation?.navigate("SlotSelection", {
            id: productId,
            slug: storeInfo.slug,
            isEditMode: true,
            variantId: variantIdForEdit,
            isCustom: isCustomCartItem,
        });
    }, [storeInfo, productId, variantIdForEdit, navigation]);

    const navigateToForm = useCallback(() => {
        navigation?.navigate("ProductForms", {
            id: productId,
            slug: storeInfo.slug,
            storeId: storeInfo.storeId,
            isEditMode: true,
            variantId: variantIdForEdit,
        });
    }, [storeInfo, productId, variantIdForEdit, navigation]);

    return productType === "APPOINTMENT" ? navigateToSlotSelection : navigateToForm;
};

interface AnalyticsParams {
    ctaState: ProductCtaProps["ctaState"];

    quantity?: number;
    ctaText?: string;
    product?: Product | null;
    selectedVariant?: string;
    isVariantList?: boolean;
    sfWidget?: string;
    cartId?: string;
    cartItemStatus?: CartStatuses;
    billAmount?: number;
    counterActionType?: CounterActionType;
    productActionCtaName?: string;
}

const useConstructAnalyticsData = (): ((data: AnalyticsParams) => {
    category: string;
    context: string;
}) => {
    const getAnalyticsData = useCallback(
        ({
            ctaText,
            product,
            selectedVariant,
            ctaState,
            counterActionType,
            sfWidget,
            cartId,
            billAmount,
            cartItemStatus,
            productActionCtaName,
        }: AnalyticsParams) => {
            return {
                category: productActionCtaName ?? ARD_EVENT_NAME,
                context: JSON.stringify({
                    ctaText,
                    productId: product?.id,
                    variantId: selectedVariant,
                    ctaState,
                    counterActionType,
                    price: product?.price,
                    discountedPrice: product?.discountedPrice,
                    productType: product?.productType,
                    isRecommended: product?.badges.includes("Recommended"),
                    isItemAvailable: product?.inStock,
                    isVariantsPresent: !!product?.variantDetails?.variants?.length,
                    isFormPresent: !!product?.form?.length,
                    isEnquiryFormPresent: !!product?.enquiryForm?.length,
                    sfWidget,
                    cartId,
                    billAmount,
                    cartItemStatus,
                }),
            };
        },
        [],
    );

    return getAnalyticsData;
};
