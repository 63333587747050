import React from "react";

import { TextColor } from "@swiggy-private/rn-dls";

import { DELIVERY_ACROSS_INDIA } from "@minis-consumer/components/widgets/components/delivery-info";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { IDeliveryInfo } from "@minis-consumer/interfaces/delivery";

const COPIES = {
    UNSERVICEABLE_DELIVERY_ETA: "Apologies, we do not deliver to your location (yet!)",
    ORDERED_DELIVERED: "Order delivered by",
    FREE_DELIVERY_ABOVE: "Free delivery on orders above ₹",
    FREE_DELIVERY: "Free delivery on all orders!",
    DELIVERY_CHARGES_APPLICABLE: "Delivery charge will apply",
    DELIVER_IN: "Delivery only in",
};

export const useGetDeliveryInfoEta = (
    dropPincodeIsAvailable?: boolean,
    deliveryInfo?: IDeliveryInfo,
): {
    text: string;
    color: TextColor;
} | null => {
    const { name: storeName } = useStoreInfo();

    if (!dropPincodeIsAvailable) {
        return null;
    }

    if (deliveryInfo?.serviceabilityType === "UNSERVICEABLE") {
        return {
            text: COPIES.UNSERVICEABLE_DELIVERY_ETA,
            color: "color-critical",
        };
    }

    if (deliveryInfo?.deliveryEta?.max) {
        const etaText = `${deliveryInfo.deliveryEta.min}-${deliveryInfo.deliveryEta.max} ${deliveryInfo.deliveryEta.unit}`;

        return {
            text: etaText,
            color: "color-basic-60",
        };
    }

    return {
        text: `${COPIES.ORDERED_DELIVERED} ${storeName}`,
        color: "color-basic-60",
    };
};

export const useGetDeliveryCharges = (
    dropPincodeIsAvailable?: boolean,
    deliveryInfo?: IDeliveryInfo,
): {
    text: string | null;
    color: TextColor;
} | null => {
    const { address } = useStoreInfo();
    const { interDeliveryConfig, intraDeliveryConfig } = useStoreInfo();
    const deliveryNote = useGetDeliveryChargesText();

    if (!dropPincodeIsAvailable) {
        return {
            text: deliveryNote,
            color: "color-basic-60",
        };
    }

    if (deliveryInfo?.serviceabilityType === "UNSERVICEABLE") {
        return {
            text: interDeliveryConfig?.enabled
                ? DELIVERY_ACROSS_INDIA
                : address?.city
                ? `${COPIES.DELIVER_IN} ${address?.city}`
                : "",
            color: "color-basic-60",
        };
    }

    const config =
        deliveryInfo?.serviceabilityType === "INTER_CITY_SERVICEABLE"
            ? interDeliveryConfig
            : deliveryInfo?.serviceabilityType === "INTRA_CITY_SERVICEABLE"
            ? intraDeliveryConfig
            : null;

    if (config) {
        switch (config?.deliveryFeeType) {
            case "FREE_DELIVERY":
                return {
                    text: COPIES.FREE_DELIVERY,
                    color: "color-positive",
                };
                break;
            case "FREE_DELIVERY_ABOVE_BASE_AMOUNT":
                return {
                    text: `${COPIES.FREE_DELIVERY_ABOVE}${config?.baseAmount}`,
                    color: "color-basic-60",
                };
                break;
            case "FLAT_RATE_DELIVERY":
            case "PER_KM_RATE_DELIVERY":
                return {
                    text: COPIES.DELIVERY_CHARGES_APPLICABLE,
                    color: "color-basic-60",
                };
                break;
        }
    }

    return null;
};

export const useGetDeliveryChargesText = (): string | null => {
    const { interDeliveryConfig, intraDeliveryConfig, address } = useStoreInfo();

    let deliveryNote = "";
    const INTRA_ENABLED_ONLY = intraDeliveryConfig?.enabled && !interDeliveryConfig?.enabled;

    if (INTRA_ENABLED_ONLY) {
        switch (intraDeliveryConfig.deliveryFeeType) {
            case "FREE_DELIVERY":
                deliveryNote = "Free delivery for all orders";
                break;
            case "FLAT_RATE_DELIVERY":
            case "FREE_DELIVERY_ABOVE_BASE_AMOUNT":
            case "PER_KM_RATE_DELIVERY":
                deliveryNote = "Delivery fee will apply";
                break;
        }
        return deliveryNote;
    }

    const INTRA_INTER_ENABLED = interDeliveryConfig?.enabled && intraDeliveryConfig?.enabled;

    if (INTRA_INTER_ENABLED) {
        const interCityChargeable = interDeliveryConfig?.deliveryFeeType !== "FREE_DELIVERY";
        const intraCityChargeable = intraDeliveryConfig?.deliveryFeeType !== "FREE_DELIVERY";

        const INTRA_CHARGEABLE_ONLY = !interCityChargeable && intraCityChargeable;
        const INTER_CHARGEABLE_ONLY = interCityChargeable && !intraCityChargeable;
        const INTER_INTRA_FREE = !interCityChargeable && !intraCityChargeable;

        if (INTER_CHARGEABLE_ONLY) {
            deliveryNote = address?.city
                ? `Free delivery on all orders within ${address?.city}`
                : "";
        } else if (INTRA_CHARGEABLE_ONLY) {
            deliveryNote = address?.city
                ? `Free delivery on all orders outside ${address?.city}`
                : "";
        } else if (INTER_INTRA_FREE) {
            deliveryNote = "Free delivery on all orders";
        } else {
            deliveryNote = "Delivery fee will apply";
        }
    }

    return deliveryNote;
};

export const useShopDispatchTime = (): string | null => {
    const { settings } = useStoreInfo();

    const dispatchTimeNote = React.useMemo(() => {
        const dispatchTime = settings?.dispatchTime;

        if (dispatchTime) {
            if (dispatchTime.min === dispatchTime.max) {
                return `Usually dispatches orders ${
                    dispatchTime.min === 1 ? "on the same day" : `in ${dispatchTime.min} days`
                }`;
            }

            return `Usually dispatches orders in ${dispatchTime.min} - ${dispatchTime.max} days`;
        }

        return null;
    }, [settings?.dispatchTime]);

    return dispatchTimeNote;
};
