import { ApiError, API_SUCCESS_STATUS_CODE } from "@swiggy-private/connect-api-core";
import { Endpoint } from "@swiggy-private/http-client";

import { getApiClient } from "../../includes/api";
import { CouponEndpoint } from "../endpoint";
import type { CartCoupons } from "../../interfaces/coupon";

export interface FetchCouponParams {
    storeId: string;
    addressId?: string;
}

export const fetchCoupons = async ({
    storeId,
    addressId,
}: FetchCouponParams): Promise<CartCoupons> => {
    const endpoint = Endpoint.from(CouponEndpoint, { storeId });
    const response = await getApiClient().get<CartCoupons>({
        endpoint,
        queryParams: {
            userAddressId: addressId ?? "",
        },
    });

    if (response.statusCode === API_SUCCESS_STATUS_CODE && response.data) {
        return response.data;
    }

    return Promise.reject(
        new ApiError(response.statusMessage || "Could not fetch the coupons", response),
    );
};
