import { Dispatch, Reducer, ReducerAction, ReducerState, useReducer } from "react";

import { LocalCart } from "@minis-consumer/interfaces/cart";
import { CartAction } from "./actions";

export interface CartState {
    carts: Record<string, LocalCart>;
    instructions?: Record<string, string>;
    isCelebrationModalActive?: Record<string, boolean>;
}

function cartReducer(
    state: Readonly<CartState>,
    action: Readonly<CartAction>,
): Readonly<CartState> {
    const updatedAt = Math.round(Date.now() / 1000);

    const removeCartInstruction = (storeId: string): Record<string, string> => {
        const { instructions } = state;

        if (!instructions) {
            return {};
        }

        const filteredInstructions = Object.fromEntries(
            Object.entries(instructions).filter(([key]) => key !== storeId),
        );

        return filteredInstructions;
    };

    switch (action.type) {
        case "UPDATE_CART_ACTION":
            const cart = {
                addressId: action.payload.cart.addressId,
                items: action.payload.cart.items.filter((i) => i.quantity > 0),
                appliedCoupon: action.payload.cart.appliedCoupon,
                syncing: false,
                isSyncedWithServer: action.payload.isSyncedWithServer ?? false,
                updatedAt,
                guestDetails: action.payload.cart.guestDetails,
            };

            return {
                ...state,
                carts: {
                    ...state.carts,
                    [action.payload.storeId]: cart,
                },
            };

        case "UPDATE_CART_GUEST_DETAILS":
            if (!state.carts[action.payload.storeId]) {
                return state;
            }

            return {
                ...state,
                carts: {
                    ...state.carts,
                    [action.payload.storeId]: {
                        ...state.carts[action.payload.storeId],
                        guestDetails: action.payload.guestDetails,
                    },
                },
            };

        case "UPDATE_CART_ITEM_ACTION":
            const localCart = state.carts[action.payload.storeId] || {
                items: [],
                syncing: false,
            };

            const params = action.payload.item;

            const items: LocalCart["items"] = localCart.items.reduce((acc, item) => {
                // ignore custom item...
                if (item.id === params.itemId && params.custom) {
                    return acc;
                }

                // variantId should be undefined in both scenario's if it doesnot exist.
                const modifiedItem =
                    item.id === params.itemId && item.variantId === params.variantId;

                const newQuantity = params.update
                    ? item.quantity
                    : params.quantity != null
                    ? params.quantity
                    : modifiedItem
                    ? params.increment
                        ? item.quantity + 1
                        : item.quantity - 1
                    : item.quantity;

                if (modifiedItem) {
                    newQuantity > 0 &&
                        acc.push({
                            ...item,
                            quantity: Math.max(newQuantity, 0),
                            itemForm: params.itemForm,
                            slot: params.slot,
                        });
                } else {
                    acc.push(item);
                }

                return acc;
            }, [] as LocalCart["items"]);

            const addItem = params.increment || Number(params.quantity || 0) > 0;

            if (
                items.find(
                    (item) => item.id === params.itemId && item.variantId === params.variantId,
                ) == null &&
                addItem
            ) {
                items.push({
                    id: params.itemId,
                    quantity: Number(params.quantity || 0) || 1,
                    custom: params.custom,
                    variantId: params.variantId,
                    slot: params.slot,
                    itemForm: params.itemForm,
                    productType: params.productType,
                });
            }

            const updatedInstructions = !items.length
                ? removeCartInstruction(action.payload.storeId)
                : state.instructions;

            return {
                ...state,
                carts: {
                    ...state.carts,
                    [action.payload.storeId]: {
                        ...localCart,
                        isSyncedWithServer: false,
                        items,
                        updatedAt,
                    },
                },
                instructions: updatedInstructions,
            };

        case "CLEAR_CART_ACTION":
            const instructions = removeCartInstruction(action.payload.storeId);

            return {
                ...state,
                carts: {
                    ...state.carts,
                    [action.payload.storeId]: {
                        syncing: false,
                        items: [],
                        isSyncedWithServer: true,
                        updatedAt,
                    },
                },
                instructions,
            };

        case "BULK_CLEAR_CART_ACTION":
            return {
                ...state,
                carts: {},
                instructions: {},
            };

        case "SYNC_CART_ACTION":
            if (state.carts[action.payload.storeId] == null) {
                return state;
            }

            return {
                ...state,
                carts: {
                    ...state.carts,
                    [action.payload.storeId]: {
                        ...state.carts[action.payload.storeId],
                        syncing: action.payload.syncing,
                        updatedAt,
                    },
                },
            };

        case "UPDATE_CART_INSTRUCTION":
            return {
                ...state,
                instructions: {
                    ...(state.instructions || {}),
                    [action.payload.storeId]: action.payload.instruction,
                },
            };

        case "UPDATE_IS_CELEBRATION_MODAL_ACTIVE":
            return {
                ...state,
                isCelebrationModalActive: {
                    [action.payload.storeId]: action.payload.isCelebrationModalActive,
                },
            };
    }
}

export function useCartReducer(
    initialState: () => CartState,
): [
    ReducerState<Reducer<CartState, CartAction>>,
    Dispatch<ReducerAction<Reducer<CartState, CartAction>>>,
] {
    return useReducer(cartReducer, undefined, initialState);
}
