import { useIsFocused } from "@react-navigation/core";
import {
    useChatDispatch,
    useChatSdk,
    useChatService,
    useChatState,
    useConversationMessageUnreadCount,
    useFetchConversationUnreadCount,
} from "@swiggy-private/connect-chat-ui";
import { useMountedRef } from "@swiggy-private/react-hooks";
import { useEffect, useMemo, useState } from "react";
import { AppState } from "react-native";

import { Logger } from "@minis-consumer/includes/logger";
import { useCreateChatConversation } from "./use-start-chat-conversation";

export const useChatStoreUnreadCount = (storeId: string): number => {
    const { conversationId } = useCreateChatConversation({ storeId });
    useFetchConversationUnreadCount(conversationId ? [conversationId] : []);
    return useConversationMessageUnreadCount(conversationId ?? "") || 0;
};

export const useChatUnreadCount = (): number => {
    const [count, setCount] = useState(0);
    const sdk = useChatSdk();
    const service = useChatService();
    const isFocused = useIsFocused();
    const mounted = useMountedRef();
    const { conversationsMeta, conversations } = useChatState();
    const chatDispatch = useChatDispatch();

    const stateUnreadCount = useMemo(
        () =>
            Object.values(conversationsMeta).reduce((acc, conversation) => {
                const c: number = conversation?.unreadCount || 0;
                return acc + (c > 0 ? 1 : 0);
            }, 0),
        [conversationsMeta],
    );

    useEffect(() => {
        const fn = async (): Promise<void> => {
            if (!sdk || !service || !isFocused || AppState.currentState !== "active") {
                return;
            }

            const curTime = Date.now();

            // fetch latest conversations
            const response = await sdk.listConversations({ limit: 5 }).catch((_) => {
                return null;
            });

            const subscribedConversations = sdk.getSubscribedConversations();
            const latestConversations =
                response?.conversations.filter(
                    (c) => c.lastMessageTimestamp && subscribedConversations.indexOf(c.id) === -1,
                ) || [];

            if (!latestConversations.length || !response) {
                return;
            }

            // fetch last read time for these conversations
            const lastReadTimeTokens = await service
                .getConversationLastReadTime(latestConversations.map((c) => c.id))
                .catch((_) => ({} as Record<string, number>));

            // save conversations in local db
            for await (const conversation of latestConversations) {
                await service.putConversation(conversation).catch(Logger.recordError);
            }

            // filter conversations by read time
            const tokensRecord: Record<string, string> = {};

            latestConversations.forEach(({ id, lastMessageTimestamp }) => {
                let lastReadTime = lastReadTimeTokens[id];
                if (!lastReadTime) {
                    lastReadTime = (lastMessageTimestamp || curTime) - 10_000;
                }

                if (lastReadTime < (lastMessageTimestamp || 0)) {
                    tokensRecord[id] = String(lastReadTime * 10_000);
                }
            });

            const conversationIds = Object.keys(tokensRecord);
            if (!conversationIds.length) {
                return;
            }

            // fetch unread for conversations
            const unreadCount = await sdk
                .fetchUnreadMessagesCount(conversationIds, tokensRecord)
                .catch((_) => null);

            if (!unreadCount) {
                return;
            }

            // fetch unread messages
            for await (const conversationId of Object.keys(unreadCount)) {
                if (!unreadCount[conversationId]) {
                    continue;
                }

                const messagesResponse = await sdk
                    .fetchMessages({
                        conversationIds: [conversationId],
                        count: unreadCount[conversationId],
                        endTimetoken: tokensRecord[conversationId],
                    })
                    .catch((_) => null);

                if (messagesResponse?.conversations[conversationId]?.length) {
                    const messages = messagesResponse.conversations[conversationId].filter(
                        (message) => message.publisher !== sdk.getUUID(),
                    );

                    // update unread count
                    unreadCount[conversationId] = messages.filter(
                        (message) => message.actions?.read == null,
                    ).length;

                    messages.forEach((message) => {
                        if (message.actions?.ack == null) {
                            sdk.addMessageAction({
                                conversationId,
                                messageTimetoken: String(message.timetoken),
                                action: {
                                    type: "ack",
                                    value: String(Date.now()),
                                },
                            });
                        }
                    });
                }
            }

            if (!mounted.current || AppState.currentState !== "active") {
                return;
            }

            if (!subscribedConversations.length) {
                // update state with unread count...
                setCount(Object.values(unreadCount).filter((c) => c > 0).length);
            }

            // update unread count...
            chatDispatch({
                type: "MULTI_DISPATCH",
                payload: Object.keys(unreadCount).map((id) => ({
                    type: "SET_UNREAD_COUNT_ACTION",
                    payload: {
                        conversationId: id,
                        count: unreadCount[id] || 0,
                    },
                })),
            });
        };

        fn();

        const timer = setInterval(fn, 30_000);

        return () => {
            clearInterval(timer);
        };
    }, [chatDispatch, isFocused, mounted, sdk, service]);

    return Object.keys(conversations).length > 0 ? stateUnreadCount : count;
};
