import * as React from "react";
import { Platform, StyleSheet } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { Box } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue } from "@swiggy-private/rn-dls";
import { UploadedMediaFile } from "@swiggy-private/rn-image-picker";

import { MediaUploadWrapperModal } from "@minis-consumer/components/media-upload-wrapper-modal";
import { Analytics } from "@minis-consumer/analytics";
import { useIsDesktopWeb } from "@minis-consumer/hooks/use-dweb";

import { ANALYTICS_COPIES, DEFAULT_VALUES } from "../../constants";
import { ImageUploadLargeScreen } from "./components/large-screen";
import { ImageUploadSmallScreen } from "./components/small-screen";

interface Props {
    uploadedMedia: UploadedMediaFile[];
    setUploadedMedia: (urls: UploadedMediaFile[]) => void;

    maxImageCount?: number;
    handleImageUpload?: VoidFunction;
}

const isPlatformAndroid = Platform.OS === "android";

const mediaUploadOptions = isPlatformAndroid
    ? ANALYTICS_COPIES.GALLERY
    : ANALYTICS_COPIES.GALLERY_AND_CAMERA;

const ImageUploadComponent: React.FC<Props> = ({
    uploadedMedia,
    setUploadedMedia,
    maxImageCount = DEFAULT_VALUES.MAX_IMAGE_COUNT,
    handleImageUpload,
}) => {
    const inset = useSafeAreaInsets();
    const isDWeb = useIsDesktopWeb();

    const [showMediaUploadModal, setShowMediaUploadModal] = React.useState(false);

    const bottomOffsetForModal = React.useMemo(
        () => inset.bottom + DEFAULT_VALUES.UPLOAD_BUTTON_HEIGHT + 2 * SpacingValue["space-medium"],
        [inset.bottom],
    );

    const onUploadMedia = React.useCallback(
        (media: UploadedMediaFile[]) => {
            setShowMediaUploadModal(false);

            if (media.length > maxImageCount) {
                return;
            }

            setUploadedMedia([...uploadedMedia, ...media]);
        },
        [maxImageCount, setUploadedMedia, uploadedMedia],
    );

    const onImageUploadPress = React.useCallback(() => {
        if (uploadedMedia.length >= maxImageCount) {
            setShowMediaUploadModal(false);
            return;
        }

        setShowMediaUploadModal(true);
        handleImageUpload?.();
    }, [uploadedMedia.length, maxImageCount, handleImageUpload]);

    const analyticsData = {
        category: ANALYTICS_COPIES.CHAT_UPLOAD_IMAGE_HALF_CARD,
        context: mediaUploadOptions,
    };

    const handleUploadOptionSelect = (selectedOption: string): void => {
        Analytics.clickEvent({
            category: ANALYTICS_COPIES.CHAT_UPLOAD_IMAGE_HALF_CARD,
            context: JSON.stringify({
                selectedOption: selectedOption,
                option: mediaUploadOptions,
            }),
        });
    };
    const ComponentToLoad = isDWeb ? ImageUploadLargeScreen : ImageUploadSmallScreen;

    return (
        <>
            <Box style={styles.imageUploadContainer}>
                <ComponentToLoad
                    handleUploadImage={onImageUploadPress}
                    uploadedMedia={uploadedMedia}
                    setUploadedMedia={setUploadedMedia}
                    maxImageCount={maxImageCount}
                />
            </Box>

            {showMediaUploadModal ? (
                <MediaUploadWrapperModal
                    onUpload={onUploadMedia}
                    bottomOffset={bottomOffsetForModal}
                    analytics={analyticsData}
                    handleUploadOptionSelect={handleUploadOptionSelect}
                />
            ) : null}
        </>
    );
};

const styles = StyleSheet.create({
    imageUploadContainer: {
        marginBottom: SpacingValue["space-large"],
    },
});

export const ImageUpload = React.memo(ImageUploadComponent);
