import { OrderItem } from "./order";

export type ReturnOrderStatuses = "PENDING" | "IN_PROGRESS" | "CLOSED";

export type ReturnOrderClosedSubStatuses =
    | "REJECTED"
    | "REFUNDED"
    | "EXCHANGED"
    | "OTHER"
    | "CLOSED";

export type ReturnOrderSubStatuses = ReturnOrderClosedSubStatuses | ReturnOrderStatuses;

export enum ReturnOrderSubStatusesEnum {
    REJECTED = "REJECTED",
    REFUNDED = "REFUNDED",
    EXCHANGED = "EXCHANGED",
    OTHER = "OTHER",
    PENDING = "PENDING",
    IN_PROGRESS = "IN_PROGRESS",
    CLOSED = "CLOSED",
}

export enum ReturnResolutionTypeEnum {
    REFUND = "REFUND",
    EXCHANGE = "EXCHANGE",
    OTHER = "OTHER",
}

export type ReturnResolutionType = keyof typeof ReturnResolutionTypeEnum;

export interface ReturnDetails {
    settings: Settings;
    order: ReturnOrder;
}

interface Settings {
    allowed: boolean;
    deadline: number;
}

export interface ReturnOrder {
    reason: string;
    comments: string;
    images: string[];
    mobile: string;
    status: ReturnOrderSubStatuses;
    statusUpdatedAt: number;
    bill: Bill;
    items: OrderItem[];
    createdAt: number;
    updatedAt: number;

    resolutionDetails?: ReturnResolutionDetails;
}

interface Bill {
    itemsBill: ItemsBill[];
    itemTotal: number;
    finalItemTotal: number;
    couponDiscount: number;
}

interface ItemsBill {
    id: string;
    quantity: number;
    totalPrice: number;
    totalDiscountedPrice: number;
}

export interface Metadata {
    custom: boolean;
}

export interface ReturnResolutionDetails {
    resolutionType: ReturnResolutionType;

    details?: string;
    partnerName?: string;
    tracking?: string;
    reason?: string;
    images?: string[];
}
