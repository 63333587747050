import { useEffect, useState, useCallback } from "react";
import { useCache } from "@rest-hooks/react";

import { Product, Variant, VariantOption } from "@minis-consumer/interfaces/catalog";
import { CatalogView } from "@minis-consumer/resources/catalog";

import { useGetProducts } from "./use-get-products";

interface useVariantsListReturnValue {
    variantDetails: Variant[];
    variantOptions: VariantOption[];
}

interface useSelectedVariantReturnValue {
    selectedVariant: Variant | null;
    selectedVariantOptions: Variant["optionValueMap"] | null;
    onVariantPress: (item: string, variant: VariantOption) => void;
}

/**
 *
 * @param productId - unique product id.
 * @returns - variantOptions - All available variant options list and their values.
 * Example - Size (S,M,L,XL) , Color (Red,Blue)
 *
 * variantDetails - Individual details of each variant combination.
 */
export const useVariantsList = (productId: string): useVariantsListReturnValue => {
    const products = useGetProducts();
    const product = products?.find((p) => p.id === productId);
    return {
        variantDetails: product?.variantDetails?.variants || [],
        variantOptions: product?.variantDetails?.options || [],
    };
};

/**
 *
 * @param productId - unique product id.
 * @returns - variantOptions - All available variant options list and their values.
 * Example - Size (S,M,L,XL) , Color (Red,Blue)
 *
 * variantDetails - Individual details of each variant combination.
 */
export const useSelectedVariant = (
    productId: string,
    variantId?: string,
): useSelectedVariantReturnValue => {
    const { variantDetails = [] } = useVariantsList(productId);
    const [selectedVariant, setSelectedVariant] = useState<Variant | null>(null);
    const [selectedVariantOptions, setSelectedVariantOptions] = useState<
        Variant["optionValueMap"] | null
    >(null);

    useEffect(() => {
        if (!variantDetails.length) {
            return;
        }

        const productVariant = variantId
            ? variantDetails.find((variant) => variant.id === variantId)
            : variantDetails.find((variant) => variant.defaultVariant);

        productVariant && setSelectedVariantOptions(productVariant.optionValueMap);
    }, [variantDetails, variantId]);

    useEffect(() => {
        if (!variantDetails.length || !selectedVariantOptions) {
            return;
        }

        const selectedVariantValues = Object.values(selectedVariantOptions).join("_");
        const variant = variantDetails.find((_variant) => {
            const _variantValues = _variant.optionValueMap
                ? Object.values(_variant.optionValueMap).join("_")
                : "";
            return _variantValues === selectedVariantValues;
        });
        variant && setSelectedVariant(variant);
    }, [selectedVariantOptions, variantDetails]);

    const onVariantPress = useCallback(
        (item: string, variant: VariantOption) => {
            setSelectedVariantOptions(
                (prevState: Variant["optionValueMap"] | null): Variant["optionValueMap"] | null => {
                    if (!prevState) {
                        return {
                            [variant.name]: item,
                        };
                    }

                    return {
                        ...prevState,
                        [variant.name]: item,
                    };
                },
            );
        },
        [setSelectedVariantOptions],
    );

    return {
        selectedVariant,
        onVariantPress,
        selectedVariantOptions,
    };
};

/**
 *
 * @param productId - unique product id.
 * @param slug - required for PORTAL components
 * @returns - variantOptions - All available variant options list and their values.
 * Example - Size (S,M,L,XL) , Color (Red,Blue)
 *
 * variantDetails - Individual details of each variant combination.
 */
export const useSelectedVariantWithSlug = (
    productId: string,
    slug: string,
    variantId?: string,
): useSelectedVariantReturnValue => {
    const { products } = useCache(CatalogView, { storeSlug: slug });
    const pr = products as Product[];
    const product = pr?.find((p) => p.id === productId);

    const variantDetails: Variant[] = product?.variantDetails?.variants || [];
    const [selectedVariant, setSelectedVariant] = useState<Variant | null>(null);
    const [selectedVariantOptions, setSelectedVariantOptions] = useState<
        Variant["optionValueMap"] | null
    >(null);

    useEffect(() => {
        if (!variantDetails.length) {
            return;
        }

        const productVariant = variantId
            ? variantDetails.find((variant) => variant.id === variantId)
            : variantDetails.find((variant) => variant.defaultVariant);

        productVariant && setSelectedVariantOptions(productVariant.optionValueMap);
    }, [variantDetails, variantId]);

    useEffect(() => {
        if (!variantDetails.length || !selectedVariantOptions) {
            return;
        }

        const selectedVariantValues = Object.values(selectedVariantOptions).join("_");
        const variant = variantDetails.find((_variant) => {
            const _variantValues = _variant.optionValueMap
                ? Object.values(_variant.optionValueMap).join("_")
                : "";
            return _variantValues === selectedVariantValues;
        });
        variant && setSelectedVariant(variant);
    }, [selectedVariantOptions, variantDetails]);

    const onVariantPress = useCallback(
        (item: string, variant: VariantOption) => {
            setSelectedVariantOptions(
                (prevState: Variant["optionValueMap"] | null): Variant["optionValueMap"] | null => {
                    if (!prevState) {
                        return {
                            [variant.name]: item,
                        };
                    }

                    return {
                        ...prevState,
                        [variant.name]: item,
                    };
                },
            );
        },
        [setSelectedVariantOptions],
    );

    return {
        selectedVariant,
        onVariantPress,
        selectedVariantOptions,
    };
};
