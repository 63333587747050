import * as React from "react";
import { Pressable, StyleSheet } from "react-native";

import { SpacingValue, Surface } from "@swiggy-private/rn-dls";

import { useIsDesktop } from "@minis-consumer/hooks/use-desktop";

type WrapperProps = {
    children: React.ReactNode;

    onPress?: () => void;
    isDisabled?: boolean;
};

const WrapperComponent: React.FC<WrapperProps> = ({ children, onPress, isDisabled = false }) => {
    const isDesktop = useIsDesktop();

    if (isDesktop) {
        return <Surface style={styles.surface}>{children}</Surface>;
    }

    return (
        <Pressable onPress={() => onPress?.()} style={styles.pressable} disabled={isDisabled}>
            {children}
        </Pressable>
    );
};

const styles = StyleSheet.create({
    surface: {
        borderTopStartRadius: 16,
        borderTopEndRadius: 16,
        elevation: 1,
        zIndex: 10,
        height: 104,
        overflow: "hidden",
        padding: SpacingValue["space-x-large"],
        flexDirection: "row",
    },
    pressable: {
        flexDirection: "row",
    },
});

export const Wrapper = React.memo(WrapperComponent);
