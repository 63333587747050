import * as React from "react";
import {
    Dimensions,
    Platform,
    SectionList,
    SectionListProps,
    StyleSheet,
    View,
} from "react-native";

import { useNavigation } from "@react-navigation/core";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { useMount } from "@swiggy-private/react-hooks";
import { InView } from "@swiggy-private/react-native-ui";
import { Box, getScreenSize, ScreenSize } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, useLayout, Text } from "@swiggy-private/rn-dls";

import { Analytics } from "@minis-consumer/analytics";
import { unique } from "@minis-consumer/helpers/array";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import {
    CatalogLayout,
    ICatalogSectionsPos,
    Product,
    ProductBadge,
    ProductCategory,
} from "@minis-consumer/interfaces/catalog";
import { RouteList } from "@minis-consumer/interfaces/route";
import { SF_ANALYTICS_EVENT_NAMES } from "@minis-consumer/routes/shop/constants";

import { Divider } from "../../divider";
import { organiseProducts } from "./helpers";
import { ProductSectionHeader } from "./section-header";
import { ProductSectionListItem } from "./section-list-item";
import type { Section, SectionItem } from "./types";

interface ProductSectionListProps extends Omit<SectionListProps<SectionItem, Section>, "sections"> {
    categories: ProductCategory[];
    products: Record<string, Product>;
    badges: ProductBadge[];
    catalogMeasureRef: React.RefObject<ICatalogSectionsPos>;
    layout?: CatalogLayout;
    showAddToCart?: boolean;
}

export type ProductSectionListHandler = {
    scrollToCategory: (id: string) => void;
    getListRef: () => SectionList<Product, Section> | null;
};

const KeyExtractor = (p: Product): string => p.id;

const NUM_COLS = 2;
export const ProductSectionList = React.forwardRef<
    ProductSectionListHandler,
    ProductSectionListProps
>((props, ref) => {
    const {
        categories,
        products,
        badges,
        keyExtractor = KeyExtractor,
        stickySectionHeadersEnabled = false,
        catalogMeasureRef,
        layout,
        showAddToCart,
        ...restProps
    } = props;

    const store = useStoreInfo();

    const navigation = useNavigation<NativeStackNavigationProp<RouteList>>();
    const sectionListRef = React.useRef<SectionList<SectionItem, Section>>(null);
    const viewRef = React.useRef<View>(null);
    const [parentLayout, onLayout] = useLayout();

    const isLargeScreen = React.useMemo(
        () => Platform.OS === "web" && getScreenSize() === ScreenSize.Large,
        [],
    );

    const [expandedCategories, setExpandedCategories] = React.useState<string[]>(() => {
        const categoryIds = (
            Platform.OS !== "web" ? categories.slice(0, 1) : categories.slice(0, 3)
        ).map((c) => c.id);
        const systemCategoryIds = categories.filter((c) => c.systemCategory).map((c) => c.id);
        return unique([...categoryIds, ...systemCategoryIds]);
    });

    const toggleCategoryExpandedState = React.useCallback((id: string) => {
        setExpandedCategories((state) =>
            state.includes(id) ? state.filter((i) => i !== id) : [...state, id],
        );
    }, []);

    const openCategory = React.useCallback((id: string) => {
        setExpandedCategories((state) => (state.includes(id) ? state : [...state, id]));
    }, []);

    const renderSectionHeader: NonNullable<
        SectionListProps<SectionItem, Section>["renderSectionHeader"]
    > = React.useCallback(
        ({ section }) => {
            if (categories.length === 1 && section.category.systemCategory) {
                return <Text />;
            }
            const isExpanded = expandedCategories.includes(section.category.id);

            const onImpression = (isVisible: boolean): void => {
                if (!isVisible) {
                    return;
                }

                Analytics.impressionEvent({
                    category: SF_ANALYTICS_EVENT_NAMES.CATALOG_WIDGET_ACCORDION,
                    context: `categoryTitle: ${section.category.name}, isExpanded: ${isExpanded}`,
                });
            };

            const onPress = (id: string): void => {
                Analytics.clickEvent({
                    category: SF_ANALYTICS_EVENT_NAMES.CATALOG_WIDGET_ACCORDION,
                    context: `categoryTitle: ${section.category.name}, isExpanded: ${isExpanded}`,
                });

                toggleCategoryExpandedState(id);
            };

            return (
                <InView onChange={onImpression} triggerOnce>
                    <ProductSectionHeader
                        section={section}
                        onToggle={onPress}
                        catalogMeasureRef={catalogMeasureRef}
                        expanded={isExpanded}
                    />
                </InView>
            );
        },
        [categories.length, expandedCategories, catalogMeasureRef, toggleCategoryExpandedState],
    );

    useMount(() => {
        if (!catalogMeasureRef.current) {
            return;
        }
        catalogMeasureRef.current.openCategory = openCategory;
    });

    const onProductPress = React.useCallback(
        (productId: string, index: number, productType?: Product["productType"]) => {
            Analytics.clickEvent({
                category: "product-card",
                label: productId,
                value: index,
                context: `productType: ${productType}`,
            });

            navigation.navigate("Product", {
                id: productId,
                slug: store.slug,
            });
        },
        [navigation, store.slug],
    );

    const screenWidth = React.useMemo(() => {
        if (isLargeScreen) {
            return parentLayout?.width ?? 400;
        }
        return Dimensions.get("window").width;
    }, [isLargeScreen, parentLayout?.width]);

    const widgetWidth = React.useMemo(() => {
        const gutterWidth = isLargeScreen ? 32 : 56;
        return (screenWidth - gutterWidth) / NUM_COLS;
    }, [screenWidth, isLargeScreen]);

    const renderItem: NonNullable<SectionListProps<SectionItem, Section>["renderItem"]> =
        React.useCallback(
            ({ section, item, index }) => {
                if (layout === "LIST") {
                    return (
                        <ProductSectionListItem
                            item={item}
                            index={index}
                            badges={badges}
                            onProductPress={() => onProductPress(item.id, index, item.productType)}
                            layout={layout}
                            showAddToCart={showAddToCart}
                        />
                    );
                }

                if (isLargeScreen && !screenWidth) {
                    return null;
                }
                if (index % NUM_COLS !== 0) {
                    return null;
                }

                const rowItems = section.data.slice(index, index + NUM_COLS);

                return (
                    <Box
                        direction="row"
                        mb={SpacingValue["space-xx-large"]}
                        justifyContent={"space-between"}>
                        {rowItems.map((colItem, i) => {
                            return (
                                <ProductSectionListItem
                                    item={colItem}
                                    key={i}
                                    index={i}
                                    badges={badges}
                                    onProductPress={() => onProductPress(colItem.id, index)}
                                    width={widgetWidth}
                                    showAddToCart={showAddToCart}
                                    layout={layout}
                                />
                            );
                        })}
                    </Box>
                );
            },
            [
                badges,
                onProductPress,
                showAddToCart,
                screenWidth,
                widgetWidth,
                isLargeScreen,
                layout,
            ],
        );

    const sections: Section[] = React.useMemo(() => {
        return categories.map((category) => {
            const productMappedCategories = {
                category,
                data:
                    /** Temporary fix. Added category check to ignore cached data for OTHER category */
                    /** TODO: fix the issue at the root */
                    expandedCategories.includes(category.id) && category.storeId === store.storeId
                        ? category.products.map((pId) => products[pId])
                        : [],
            };

            return organiseProducts(productMappedCategories);
        });
    }, [categories, expandedCategories, products, store.storeId]);

    const totalItems = React.useMemo(() => {
        return categories.reduce((acc, category) => {
            return (acc = acc + category.products.length);
        }, 0);
    }, [categories]);

    const renderSectionFooter: NonNullable<
        SectionListProps<SectionItem, Section>["renderSectionFooter"]
    > = React.useCallback(
        ({ section: sectionItem }) => {
            if (sectionItem.category.id !== sections[sections.length - 1].category.id) {
                return <Divider />;
            }
            return null;
        },
        [sections],
    );

    React.useImperativeHandle(
        ref,
        () => ({
            scrollToCategory: (id) => {
                const section = sections.find((s) => s.category.id === id);
                if (!section) {
                    return;
                }

                sectionListRef?.current?.scrollToLocation({
                    animated: true,
                    itemIndex: 0,
                    sectionIndex: sections.indexOf(section),
                });
            },
            getListRef: () => sectionListRef?.current,
        }),
        [sections],
    );

    return (
        <Box ref={viewRef} onLayout={onLayout}>
            <SectionList
                ref={sectionListRef}
                stickySectionHeadersEnabled={stickySectionHeadersEnabled}
                sections={sections}
                keyExtractor={keyExtractor}
                renderItem={renderItem}
                renderSectionFooter={renderSectionFooter}
                renderSectionHeader={renderSectionHeader}
                initialNumToRender={totalItems > 1 ? totalItems : undefined}
                disableVirtualization={Platform.OS === "web"}
                maxToRenderPerBatch={10}
                windowSize={10}
                style={styles.list}
                {...restProps}
            />
        </Box>
    );
});

if (__DEV__) {
    ProductSectionList.displayName = "ProductSectionList";
}

const styles = StyleSheet.create({
    list: {
        overflow: "visible",
    },
    listHeader: {
        marginBottom: SpacingValue["space-x-small"],
    },
});
