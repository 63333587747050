import { StyleProp, ViewStyle } from "react-native";

import { TextProps, Theme } from "@swiggy-private/rn-dls";

import { VariantOption, Variant } from "@minis-consumer/interfaces/catalog";

export const isVariantAvailable = ({
    variantName,
    optionName,
    options,
    variantDetails,
    selectedVariants,
}: {
    variantName: string;
    optionName: VariantOption["name"];
    options: VariantOption[];
    variantDetails: Variant[];
    selectedVariants: Variant["optionValueMap"] | null;
}): boolean => {
    // if only a single variant option exists
    // check if it is available or not.
    if (options.length === 1) {
        const _variant = (variantDetails || []).find(
            (item) => item.optionValueMap[optionName] === variantName,
        );

        return !!_variant?.available;
    }
    // if selected variant is not available
    // return true.
    if (!selectedVariants) {
        return true;
    }

    const isDefaultOption = optionName === options[0].name;

    if (isDefaultOption) {
        /**
         * there can  be 2 scenarios -
         * 1. If only single variant exists, check if it is available or not.
         * 2. If 2 variants exists, the default optoin variant will only be disabled
         *    if all the variants 2 for variant 1 are disabled.
         */
        return (variantDetails || []).some((item) => {
            if (item.optionValueMap[optionName] === variantName) {
                return item.available;
            }
            return false;
        });
    }

    // check if variant 2 is available for selected variant 1.
    const _variant = (variantDetails || []).find(
        (item) =>
            item.optionValueMap[optionName] === variantName &&
            item.optionValueMap[options[0].name] === selectedVariants[options[0].name],
    );

    return !!_variant?.available;
};

export const getTextProps = ({
    selected,
    unavailable,
}: {
    selected: boolean;
    unavailable: boolean;
}): TextProps => {
    if (selected && unavailable) {
        return {
            category: "b2",
            weight: "regular",
            color: "color-basic-45",
        };
    }

    if (selected) {
        return {
            category: "btn4",
            weight: "bold",
            color: "color-primary",
        };
    }

    if (unavailable) {
        return {
            category: "b2",
            weight: "regular",
            color: "color-basic-45",
        };
    }

    return {
        category: "b2",
        weight: "regular",
        color: "color-basic-75",
    };
};

export const getPillStyles = ({
    selected,
    unavailable,
    theme,
}: {
    selected: boolean;
    unavailable: boolean;
    theme: Theme;
}): StyleProp<ViewStyle> => {
    if (selected && unavailable) {
        return {
            borderWidth: 2,
            borderColor: theme["color-primary"],
            backgroundColor: theme["color-basic-15"],
        };
    }

    if (selected) {
        return {
            borderWidth: 2,
            borderColor: theme["color-primary"],
        };
    }

    if (unavailable) {
        return {
            borderWidth: 1,
            borderColor: theme["color-basic-15"],
            backgroundColor: theme["color-basic-15"],
        };
    }

    return {
        borderWidth: 1,
        borderColor: theme["color-basic-15"],
    };
};
