import * as React from "react";
import { SafeAreaView, StatusBar, StyleSheet } from "react-native";

import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { Box, ScreenSize, Stack, useScreenSize } from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { CdnImageBackground } from "@swiggy-private/react-native-ui";

interface AuthHeaderProps {
    title: string;
    description: string;
    onBack?: () => void;
}

export const AuthHeader: React.FC<AuthHeaderProps> = ({ title, description, onBack }) => {
    const { value: theme } = useTheme();
    const screenSize = useScreenSize();

    return (
        <SafeAreaView style={{ backgroundColor: theme["color-primary-light"] }}>
            <CdnImageBackground isImageKitEnabled id="minis/consumer_signup" resizeMode="cover">
                <Stack
                    direction="row"
                    spacing={SpacingValue["space-medium"]}
                    style={[
                        styles.container,
                        {
                            paddingTop:
                                (StatusBar.currentHeight ?? 0) + SpacingValue["space-large"],
                        },
                        {
                            paddingHorizontal:
                                screenSize !== ScreenSize.Large
                                    ? SpacingValue["space-medium"]
                                    : SpacingValue["space-x-large"],
                        },
                    ]}>
                    <Box flex={1} direction="row">
                        <Box flex={1} style={styles.content}>
                            {onBack ? (
                                <SvgIcon
                                    icon="ArrowLeft"
                                    color="color-basic-75"
                                    width={24}
                                    height={24}
                                    onPress={onBack}
                                />
                            ) : null}
                            <Box style={styles.textContainer}>
                                <Text numberOfLines={1} category="h3" weight="bold" color="highest">
                                    {title}
                                </Text>
                                <Text numberOfLines={2} category="b2" color="medium">
                                    {description}
                                </Text>
                            </Box>
                        </Box>
                    </Box>
                </Stack>
            </CdnImageBackground>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({
    container: {
        padding: SpacingValue["space-x-large"],
    },
    content: {
        minHeight: 120,
        overflow: "hidden",
    },
    textContainer: {
        flex: 1,
        justifyContent: "flex-end",
    },
});
