import { ColorValue, Platform, ViewStyle } from "react-native";

export enum SearchStyleType {
    ROOT_CONTAINER = "ROOT_CONTAINER",
    INPUT = "INPUT",
}

export interface IGetStyle {
    canShowNudge?: boolean;
    type?: SearchStyleType;
    color?: ColorValue;
}

const getSearchBarShadowStyle = (color: ColorValue): ViewStyle => {
    if (Platform.OS === "ios") {
        return {
            shadowColor: color,
            shadowOpacity: 0.1,
            shadowRadius: 12,
            borderRadius: 12,
        };
    }

    return {
        shadowColor: color,
        elevation: 6,
    };
};

export const getInputStyles = (params: IGetStyle): ViewStyle | undefined => {
    const { canShowNudge, type, color } = params;

    if (!canShowNudge || !type || !color) {
        return;
    }

    const shadowStyle = getSearchBarShadowStyle(color);

    if (type === SearchStyleType.ROOT_CONTAINER) {
        return shadowStyle;
    }

    return {
        ...shadowStyle,
        borderColor: color,
    };
};
