import React, { memo } from "react";
import { StyleSheet } from "react-native";

import { Text, SpacingValue } from "@swiggy-private/rn-dls";

import { Product } from "@minis-consumer/interfaces/catalog";

const DigitalAssetInfoComponent: React.FC<NonNullable<Product["digitalAssetInfo"]>> = ({
    type,
    extension,
}) => {
    if (!type || !extension) {
        return null;
    }

    return (
        <Text
            category="b3"
            color="low"
            weight="regular"
            numberOfLines={1}
            ellipsizeMode="tail"
            style={styles.asset}>
            {extension}
        </Text>
    );
};

export const DigitalAssetInfo = memo(DigitalAssetInfoComponent);

const styles = StyleSheet.create({
    asset: {
        textTransform: "uppercase",
        marginTop: SpacingValue["space-xxx-small"],
    },
});
