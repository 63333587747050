import * as React from "react";
import { Pressable, StyleSheet, View } from "react-native";

import { InView, StoreMedia } from "@swiggy-private/react-native-ui";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Surface, Text, useTheme } from "@swiggy-private/rn-dls";
import { getTextualDescription } from "@swiggy-private/connect-business-commons";

import { Analytics } from "@minis-consumer/analytics";
import { useLocalCartItem } from "@minis-consumer/hooks/use-cart";
import { getProductMainImageUrl } from "@minis-consumer/helpers/catalog";
import type { ProductBadge } from "@minis-consumer/interfaces/catalog";
import {
    META_PIXEL_CONTENT_CATEGORY,
    META_PIXEL_EVENT_NAMES,
} from "@minis-consumer/constants/meta-pixel-analytics";
import { ProductVariantsSizeLabel } from "@minis-consumer/components/variants";
import { fireMetaPixelEvents } from "@minis-consumer/helpers/meta-pixel-events";
import { SF_ANALYTICS_EVENT_NAMES } from "@minis-consumer/routes/shop/constants";

import { ProductPrice } from "../product-price";

import type { SectionItem } from "./types";
import { useGetProductCtaProps } from "@minis-consumer/components/product-cta/hooks/use-get-cta-actions";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { ProductCta } from "@minis-consumer/components/product-cta";

interface ProductSectionItemProps {
    item: SectionItem;
    badges: ProductBadge[];
    onProductPress: () => void;
    index: number;

    callBeforeCtaNavigation?: VoidFunction;
}

const SF_WIDGET = "search";

export const ProductSectionItem: React.FC<ProductSectionItemProps> = React.memo(
    ({ item: product, badges, onProductPress, index, callBeforeCtaNavigation }) => {
        const { value: theme } = useTheme();

        const productBadges =
            product.badges.length > 0 ? badges.filter((b) => product.badges.includes(b.id)) : [];

        const productDescription = getTextualDescription(product.description ?? "");

        const [, increment, decrement] = useLocalCartItem({
            storeId: product.storeId,
            itemId: product.id,
            productType: product.productType ?? "PHYSICAL",
        });

        const productMainImageUrl = getProductMainImageUrl(
            product,
            {
                width: PRODUCT_IMAGE_SIZE,
                height: PRODUCT_IMAGE_SIZE,
            },
            true,
        );

        const imageStyle = React.useMemo(
            () =>
                StyleSheet.flatten([
                    styles.image,
                    {
                        backgroundColor: productMainImageUrl
                            ? theme["color-basic-0"]
                            : theme["color-secondary-light"],
                    },
                ]),
            [productMainImageUrl, theme],
        );

        const addBtnStyle = React.useMemo(
            () => StyleSheet.flatten([styles.addBtn, styles.addBtnAbsolute]),
            [],
        );

        const productCardAnalyticsContext = React.useMemo(() => {
            return JSON.stringify({
                itemId: product.id,
                sfWidget: SF_WIDGET,
                hasRecommendedTag: product.badges.includes("Recommended"),
                isAvailable: product.inStock,
                index,
                productType: product.productType,
            });
        }, [product, index]);

        const onItemAdd = React.useCallback((): void => {
            Analytics.clickEvent({
                category: SF_ANALYTICS_EVENT_NAMES.PRODUCT_CARD_ADD,
                context: productCardAnalyticsContext,
            });
            product.price && increment();

            fireMetaPixelEvents(META_PIXEL_EVENT_NAMES.ADD_TO_CART, {
                content_name: product?.name,
                value: String(product?.discountedPrice ?? product?.price),
                content_category: META_PIXEL_CONTENT_CATEGORY.SF_HOME,
            });
        }, [
            product.price,
            product?.name,
            product?.discountedPrice,
            increment,
            productCardAnalyticsContext,
        ]);

        const onImpression = React.useCallback(
            (isVisible: boolean) => {
                if (!isVisible) {
                    return;
                }

                Analytics.impressionEvent({
                    category: SF_ANALYTICS_EVENT_NAMES.PRODUCT_CARD,
                    context: productCardAnalyticsContext,
                });
            },
            [productCardAnalyticsContext],
        );

        const storeInfo = useStoreInfo();

        const productCtaActionsAndStyles = useGetProductCtaProps({
            productId: product.id,
            storeId: storeInfo.storeId,
            buttonProps: {
                style: productMainImageUrl ? addBtnStyle : styles.addBtn,
            },
            sfWidget: SF_WIDGET,
            productActionCtaName: `${SF_WIDGET.toLowerCase()}-product-action-cta`,
            onAdd: onItemAdd,
            onRemoveOrDecrement: decrement,
            callBeforeNavigation: callBeforeCtaNavigation,
        });

        return (
            <InView onChange={onImpression}>
                <Pressable onPress={onProductPress} accessible accessibilityLabel="Open product">
                    <Box direction="row" mv={SpacingValue["space-medium"]} alignItems="center">
                        <Stack flex={1} direction="column" spacing={SpacingValue["space-small"]}>
                            {productBadges.length > 0 ? (
                                <Stack direction="row" spacing={SpacingValue["space-x-small"]}>
                                    {productBadges.map((badge) => (
                                        <Surface
                                            style={styles.badge}
                                            color="color-critical-300"
                                            key={badge.id}>
                                            <Text
                                                category="b2"
                                                color="color-basic-0"
                                                style={styles.badgeText}
                                                weight="bold">
                                                {badge.name}
                                            </Text>
                                        </Surface>
                                    ))}
                                </Stack>
                            ) : null}

                            <Stack>
                                <Text category="b1" color="high" weight="bold" numberOfLines={2}>
                                    {product.name}
                                </Text>
                                <ProductVariantsSizeLabel
                                    options={product.variantDetails?.options || []}
                                />
                            </Stack>

                            <ProductPrice
                                price={product.price}
                                discountedPrice={product.discountedPrice}
                                discountPercent={product.discountPercent}
                            />

                            {productDescription ? (
                                <Text category="b2" numberOfLines={2}>
                                    {productDescription}
                                </Text>
                            ) : null}
                        </Stack>

                        <Box
                            ml={SpacingValue["space-medium"]}
                            mb={productMainImageUrl ? ADD_BUTTON_HEIGHT / 2 : 0}>
                            {productMainImageUrl ? (
                                <View>
                                    <View
                                        style={[
                                            styles.imageContainer,
                                            { borderColor: theme["color-basic-15"] },
                                        ]}>
                                        <StoreMedia
                                            onPress={onProductPress}
                                            disableVideoPlay
                                            isImageKitEnabled
                                            mediaId={productMainImageUrl}
                                            height={PRODUCT_IMAGE_SIZE}
                                            width={PRODUCT_IMAGE_SIZE}
                                            style={[styles.image, imageStyle]}
                                            resizeMode="cover"
                                            showLoader
                                        />
                                    </View>

                                    <ProductCta
                                        {...productCtaActionsAndStyles}
                                        productId={product.id}
                                        storeId={storeInfo.storeId}
                                        showVariants
                                        price={product.price}
                                        type={product.productType}
                                    />
                                </View>
                            ) : (
                                <ProductCta
                                    {...productCtaActionsAndStyles}
                                    productId={product.id}
                                    storeId={storeInfo.storeId}
                                    showVariants
                                    price={product.price}
                                    type={product.productType}
                                />
                            )}
                        </Box>
                    </Box>
                </Pressable>
            </InView>
        );
    },
);

if (__DEV__) {
    ProductSectionItem.displayName = "ProductSectionItem";
}

const PRODUCT_IMAGE_SIZE = 120;
const ADD_BUTTON_HEIGHT = 40;

const styles = StyleSheet.create({
    addBtn: {
        width: PRODUCT_IMAGE_SIZE,
        height: ADD_BUTTON_HEIGHT,
        minHeight: ADD_BUTTON_HEIGHT,
    },
    addBtnAbsolute: {
        marginTop: SpacingValue["space-small"],
    },
    badge: {
        paddingVertical: SpacingValue["space-xx-small"],
        paddingHorizontal: SpacingValue["space-x-small"],
        borderBottomRightRadius: 2,
        borderTopLeftRadius: 2,
        borderBottomLeftRadius: 8,
        borderTopRightRadius: 8,
        elevation: 0,
        alignSelf: "flex-start",
    },
    badgeText: {
        fontSize: 10,
        textTransform: "uppercase",
        letterSpacing: 0.3,
    },
    image: {
        height: PRODUCT_IMAGE_SIZE,
        width: PRODUCT_IMAGE_SIZE,
    },
    imageContainer: {
        borderRadius: 12,
        borderWidth: 1,
        overflow: "hidden",
    },
});
