/**
 * Remove duplicate values from an array.
 * @param arr the array
 */
export const unique = <T>(arr: T[]): T[] => {
    const set = new Set(arr);
    return Array.from(set);
};

/**
 * Checks if two arrays are equal
 * @param arr1 first array
 * @param arr2 second array
 */
export const ifArraysEqual = <T>(arr1: T[], arr2: T[]): boolean => {
    if (arr1.length !== arr2.length) {
        return false;
    }

    return arr1.every((a: T) => arr2.includes(a));
};
