import * as React from "react";

import { SwiggyHeaders } from "@minis-consumer/interfaces/swiggy";

export interface SelectedAddressInfo {
    lng?: string;
    lat?: string;
    postalCode?: number;
    addressId?: string;
    addressLabel?: string;
    deliveryEta?: string | null;
}

interface IContext {
    selectedAddressInfo?: SelectedAddressInfo;
    updateSelectedAddressInfo?: (info: SelectedAddressInfo) => void;
}

export interface ISelectedAddressInfoContextProps {
    headers?: SwiggyHeaders | null;
}

export const SelectedAddressInfoContext = React.createContext<IContext | null>(null);

export const SelectedAddressInfoProvider: React.FC<
    React.PropsWithChildren<ISelectedAddressInfoContextProps>
> = (props) => {
    const { headers, children } = props;
    const [selectedAddressInfo, setSelectedAddressInfo] = React.useState<SelectedAddressInfo>({
        lng: headers?.lng,
        lat: headers?.lat,
        postalCode: headers?.postalCode,
        addressId: headers?.addressId,
        addressLabel: headers?.addressLabel,
        deliveryEta: null,
    });

    const updateSelectedAddressInfo = React.useCallback((info: SelectedAddressInfo): void => {
        setSelectedAddressInfo(info);
    }, []);

    const value = React.useMemo<IContext>(
        () => ({ selectedAddressInfo, updateSelectedAddressInfo }),
        [selectedAddressInfo, updateSelectedAddressInfo],
    );

    return (
        <SelectedAddressInfoContext.Provider value={value}>
            {children}
        </SelectedAddressInfoContext.Provider>
    );
};
