import { isUrlVideo } from "@swiggy-private/react-native-ui";

import { DURATION_STRING_MAP } from "@minis-consumer/constants/product";

import { getMediaUrl, GetMediaUrlOpts } from "./media";
import type { Product, ProductDuration } from "../interfaces/catalog";

export function getProductMainImageUrl(
    product: Product,
    opts: Partial<GetMediaUrlOpts> = {},
    isImageKitEnabled?: boolean,
): string | null {
    return getProductImages(product, opts, isImageKitEnabled)[0] ?? null;
}

export function getProductImages(
    product: Product,
    opts: Partial<GetMediaUrlOpts> = {},
    isImageKitEnabled?: boolean,
): string[] {
    return getMediaAssets(product).map((id) => {
        opts.height = isUrlVideo(id) ? "100%" : opts.height;
        return getMediaUrl(id, opts, isImageKitEnabled);
    });
}

export const getBadgeCategoryNativeId = (id: string): string => {
    return "badge_category_" + id;
};

export const getProductCategoryNativeId = (id: string): string => {
    return "product_category_" + id;
};

/*
 * helps in retrieving images for products from old and new attribute
 */
export const getMediaAssets = <T extends Partial<Product>>(products: T): string[] => {
    if (!products) {
        return [];
    }
    const totalImagesList = (products?.mediaIds || []).concat(
        (products?.images || (products as Partial<{ imageIds: string[] }>)?.imageIds || []).filter(
            (item) => !(products?.mediaIds || []).includes(item),
        ),
    );
    return totalImagesList;
};

export const getProductDurationUnitText = ({ value, unit }: ProductDuration): string => {
    if (!value || !unit) {
        return "";
    }

    return value > 1
        ? DURATION_STRING_MAP[unit ?? ""].pluralText
        : DURATION_STRING_MAP[unit ?? ""].singularText;
};

export const getProductDurationText = ({ value, unit }: ProductDuration): string => {
    if (!value || !unit) {
        return "";
    }

    const text = getProductDurationUnitText({ value, unit });
    return `${value} ${text}`;
};
