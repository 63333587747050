import { Platform } from "react-native";
import React, { useContext } from "react";
import { useController } from "@rest-hooks/react";

import { StoreContext } from "@minis-consumer/contexts/store-context";
import { assertIsDefined } from "@minis-consumer/helpers/assert";
import { StoreDetail } from "@minis-consumer/resources/store";

import type { Store } from "../interfaces/store";

type Value<T extends boolean> = T extends true ? Store : Store | null;

type TCreatePageTitle = (page?: string) => string;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export function useStoreInfo<T extends boolean = true>(required: T = true): Value<T> {
    const ctx = useContext(StoreContext);

    if (required) {
        assertIsDefined(ctx.store);
        return ctx.store;
    }

    return (ctx.store ?? null) as Value<T>;
}

export const useStoreRefetch = (slug: string): (() => void) => {
    const { invalidate } = useController();

    const refetch = React.useCallback(() => {
        invalidate(StoreDetail, { slug });
    }, [invalidate, slug]);

    return refetch;
};

/**
 * Helps create better Page title for web, by appending storename
 * @params : string , createTitle receives page name
 * @returns : string , StoreName appended to Page title for web
 */
export const useStorePageTitle = (): TCreatePageTitle => {
    const storeInfo = useStoreInfo();

    const createTitle = React.useCallback(
        (page = "") => {
            if (storeInfo?.name && Platform.OS === "web") {
                return `${storeInfo?.name} | ${page === "Shop" ? "Home" : page}`;
            }

            return page;
        },
        [storeInfo.name],
    );

    return createTitle;
};
