import * as React from "react";

type IRatingAndReviewModalContext = {
    isOpen: boolean;
    orderId: string;
    storeId: string;
    ratingCount: number;
    orderKey: string;
    open: VoidFunction;
    close: VoidFunction;
    setOrderId: (e: string) => void;
    setStoreId: (e: string) => void;
    setRatingCount: (n: number) => void;
    setOrderKey: (orderKey: string) => void;
};

export const RatingAndReviewModalContext = React.createContext<IRatingAndReviewModalContext | null>(
    null,
);

export const RatingAndReviewModalContextProvider: React.FC<React.PropsWithChildren> = ({
    children,
}) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [orderId, setOrderId] = React.useState("");
    const [storeId, setStoreId] = React.useState("");
    const [orderKey, setOrderKey] = React.useState("");

    const [ratingCount, setRatingCount] = React.useState(0);

    const close = React.useCallback(() => setIsOpen(false), []);
    const open = React.useCallback(() => setIsOpen(true), []);

    const value = React.useMemo(
        () => ({
            isOpen,
            close,
            open,
            orderId,
            setOrderId,
            ratingCount,
            setRatingCount,
            setStoreId,
            storeId,
            orderKey,
            setOrderKey,
        }),
        [isOpen, close, open, orderId, ratingCount, storeId, orderKey],
    );

    return (
        <RatingAndReviewModalContext.Provider value={value}>
            {children}
        </RatingAndReviewModalContext.Provider>
    );
};
