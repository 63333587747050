import * as React from "react";

import { format } from "@swiggy-private/common-helpers";
import { isUri, useIsomorphicEffect } from "@swiggy-private/react-native-ui";

import { ORDER_TRACKING_COPY } from "@minis-consumer/constants/order";
import { SHIPPING_PARTNER_NAME } from "@minis-consumer/constants/shipping";
import { convertToReadableDateAndTime } from "@minis-consumer/helpers/date";
import { convertSecondsToMinutesAndSeconds } from "@minis-consumer/helpers/time";
import {
    CancellationDetails,
    CancelOrderReason,
    OrderStatus,
    OrderStore,
    ShippingDetails,
} from "@minis-consumer/interfaces/order";
import { PRODUCT_TYPE } from "@minis-consumer/interfaces/catalog";

import { useDeliveryETAContextStatus } from "./use-delivery-eta";

const getTextsByProductType = (props: Props): Record<PRODUCT_TYPE, any> => {
    const { statusUpdatedAt, storeDetails } = props;
    const storeName = storeDetails?.name ?? "Store";

    return {
        APPOINTMENT: {
            PENDING: {
                header: {
                    title: "Waiting for Confirmation",
                },
                body: {
                    description: `${storeName} will review your preferred time and confirm your order shortly. Feel free to contact us via chat if you have any queries.`,
                },
                ctaType: "Cancel",
            },
            ACCEPTED: {
                header: {
                    title: "Order Confirmed",
                },
                body: {
                    description: `Your order has been confirmed by ${storeName}`,
                },
                ctaType: "None",
            },
            DELIVERED: {
                header: {
                    title: "Order Completed",
                },
                body: {
                    description: statusUpdatedAt
                        ? `Your order has been marked as completed by ${storeName} on ${convertToReadableDateAndTime(
                              statusUpdatedAt,
                          )}. Hope you enjoyed the experience.`
                        : "",
                },
                ctaType: "None",
            },
            PAYMENT_FAILED: {
                header: {
                    title: "Payment failed",
                },
                body: {
                    description: `Any amount deducted will be refunded back to your account within 2-4 days.`,
                },
                ctaType: "RetryPayment",
            },
            TRANSACTION_FAILED: {
                header: {
                    title: "Payment failed",
                },
                body: {
                    description: `Any amount deducted will be refunded back to your account within 2-4 days.`,
                },
                ctaType: "RetryPayment",
            },
            CANCELLED_USER: {
                header: {
                    title: "Order cancelled",
                },
                body: {
                    description: `You’ve cancelled this order. Feel free to let us know in the chat what went wrong and how we can improve it.`,
                },
                ctaType: "None",
            },
        },
        DIGITAL: {
            DELIVERED: {
                header: {
                    title: "Order Completed",
                },
                body: {
                    description: statusUpdatedAt
                        ? `Your order has been marked as completed by ${storeName} on ${convertToReadableDateAndTime(
                              statusUpdatedAt,
                          )}. Hope you enjoyed the experience.`
                        : "",
                },
                ctaType: "None",
            },
            PAYMENT_FAILED: {
                header: {
                    title: "Payment failed",
                },
                body: {
                    description: `Any amount deducted will be refunded back to your account within 2-4 days.`,
                },
                ctaType: "RetryPayment",
            },
            TRANSACTION_FAILED: {
                header: {
                    title: "Payment failed",
                },
                body: {
                    description: `Any amount deducted will be refunded back to your account within 2-4 days.`,
                },
                ctaType: "RetryPayment",
            },
            CANCELLED_USER: {
                header: {
                    title: "Order cancelled",
                },
                body: {
                    description: `You’ve cancelled this order. Feel free to let us know in the chat what went wrong and how we can improve it.`,
                },
                ctaType: "None",
            },
        },
        PLAN: {
            PENDING: {
                header: {
                    title: "Waiting for Confirmation",
                },
                body: {
                    description: `Please allow ${storeName} some time to confirm your order. Feel free to contact us via chat if you have any queries.`,
                },
                ctaType: "Cancel",
            },
            ACCEPTED: {
                header: {
                    title: "Order Confirmed",
                },
                body: {
                    description: statusUpdatedAt
                        ? `Your order has been marked as completed by ${storeName} on ${convertToReadableDateAndTime(
                              statusUpdatedAt,
                          )}. Hope you enjoyed the experience.`
                        : "",
                },
                ctaType: "None",
            },
            DELIVERED: {
                header: {
                    title: "Order Confirmed",
                },
                body: {
                    description: statusUpdatedAt
                        ? `Your order has been marked as completed by ${storeName} on ${convertToReadableDateAndTime(
                              statusUpdatedAt,
                          )}. Hope you enjoyed the experience.`
                        : "",
                },
                ctaType: "None",
            },
            PAYMENT_FAILED: {
                header: {
                    title: "Payment failed",
                },
                body: {
                    description: `Any amount deducted will be refunded back to your account within 2-4 days.`,
                },
                ctaType: "RetryPayment",
            },
            TRANSACTION_FAILED: {
                header: {
                    title: "Payment failed",
                },
                body: {
                    description: `Any amount deducted will be refunded back to your account within 2-4 days.`,
                },
                ctaType: "RetryPayment",
            },
            CANCELLED_USER: {
                header: {
                    title: "Order cancelled",
                },
                body: {
                    description: `You’ve cancelled this order. Feel free to let us know in the chat what went wrong and how we can improve it.`,
                },
                ctaType: "None",
            },
        },
        PHYSICAL: undefined,
    };
};

const getText = (
    props: Props,
    isTrackingScreen: boolean,
    status: OrderStatus,
): TrackingMeta | null => {
    const { orderType } = props;

    const textsByProductType = getTextsByProductType(props);

    const productType = orderType ?? "PHYSICAL";

    const texts = textsByProductType[productType]?.[status];

    return texts;
};

type Props = {
    orderStatus: OrderStatus;
    shippingDetails?: ShippingDetails;
    cancellationDetails?: CancellationDetails;
    consumer?: "OrderDetailsScreen" | "TrackingScreen";
    createdAt?: number;
    updatedAt?: number;
    orderTimeout?: number;
    orderType?: PRODUCT_TYPE;
    statusUpdatedAt?: number;
    storeDetails?: OrderStore;
    txnId?: string;
    onTimeout?: VoidFunction;
    orderDeliveryETA?: string;
};

export type TrackingMeta = {
    header: {
        title: string;
        subTitle?: string;
    };
    body?: {
        description: string;
        mobile?: string;
        agentName?: string;
        awb?: string;
        trackUrl?: string;
        sellerCancellationReason?: string;
    };
    ctaType: "Track" | "RetryPayment" | "Reorder" | "Cancel" | "None" | "Refund";
};

export const useGuestCheckoutOrderTracking = (props: Props): TrackingMeta | null => {
    const {
        orderStatus,
        shippingDetails,
        cancellationDetails,
        consumer,
        storeDetails,
        createdAt,
        statusUpdatedAt,
        txnId,
        orderDeliveryETA,
    } = props;

    const storeName = storeDetails?.name || "";
    const PAYMENT_RETRY_TIME_IN_MINUTES = 5;
    const MAX_PAYMENT_TIME_ALLOWED_IN_SECONDS = PAYMENT_RETRY_TIME_IN_MINUTES * 60;

    const [timer] = React.useState<number>();
    const [returnValue, setReturnValue] = React.useState<TrackingMeta | null>(null);

    const orderTrackStatus = React.useMemo(() => orderStatus, [orderStatus]);

    const displayTimer = React.useMemo(
        () => (timer ? convertSecondsToMinutesAndSeconds(timer) : ""),
        [timer],
    );

    const { isDeliveryETAAvailable } = useDeliveryETAContextStatus();

    const cancellationReason = React.useMemo(
        () => cancellationDetails?.reasons[0] || "",
        [cancellationDetails?.reasons],
    );

    const orderAcceptedETANote = React.useMemo(() => {
        if (isDeliveryETAAvailable || !!orderDeliveryETA) {
            return format(ORDER_TRACKING_COPY.WILL_DELIVERY_ORDER, storeName, orderDeliveryETA);
        } else {
            return format(ORDER_TRACKING_COPY.CHAT_NOW_WITH_SELLER, storeName);
        }
    }, [isDeliveryETAAvailable, orderDeliveryETA, storeName]);

    const orderPendingETANote = React.useMemo(() => {
        if (isDeliveryETAAvailable || !!orderDeliveryETA) {
            return format(ORDER_TRACKING_COPY.DELIVER_TO_ADDRESS, orderDeliveryETA);
        } else {
            return format(ORDER_TRACKING_COPY.CHAT_NOW_WITH_SELLER, storeName);
        }
    }, [isDeliveryETAAvailable, orderDeliveryETA, storeName]);

    const timeRemainingInSeconds = React.useCallback(() => {
        const now = Date.now() / 1000;
        const secondsLeft =
            createdAt && MAX_PAYMENT_TIME_ALLOWED_IN_SECONDS > 0
                ? createdAt + MAX_PAYMENT_TIME_ALLOWED_IN_SECONDS - now
                : 0;

        return secondsLeft;
    }, [MAX_PAYMENT_TIME_ALLOWED_IN_SECONDS, createdAt]);

    const isTrackingScreen = consumer === "TrackingScreen";

    const textGenerator = React.useCallback(
        (status: OrderStatus) => {
            const texts = getText(props, isTrackingScreen, status);

            if (texts) {
                setReturnValue(texts);
                return;
            }

            switch (status) {
                case "PENDING":
                    setReturnValue({
                        header: {
                            title: "Order confirmation pending",
                        },
                        body: {
                            description: `Your order has been placed successfully. ${storeName} will confirm the order soon${orderPendingETANote}`,
                        },
                        ctaType: consumer === "TrackingScreen" ? "Cancel" : "Track",
                    });
                    break;

                case "ACCEPTED":
                    setReturnValue({
                        header: {
                            title: "Order getting processed",
                        },
                        body: {
                            description: `Your items are being prepared for packaging and dispatch${orderAcceptedETANote}`,
                        },
                        ctaType: consumer === "TrackingScreen" ? "None" : "Track",
                    });
                    break;

                case "READY_FOR_PICKUP":
                    setReturnValue({
                        header: {
                            title: "Order ready to dispatch",
                        },
                        body: {
                            description: "We will send a link shortly for you to track the order",
                        },
                        ctaType: consumer === "TrackingScreen" ? "None" : "Track",
                    });
                    break;

                case "IN_TRANSIT":
                    const header = {
                        title: "Order in transit",
                    };

                    const ctaType = consumer === "TrackingScreen" ? "None" : "Track";

                    const body = getShippingInfo({ shipping: shippingDetails, storeName });

                    setReturnValue({ header, body, ctaType });
                    break;

                case "DELIVERED":
                    setReturnValue({
                        header: {
                            title: "Order delivered",
                        },
                        body: {
                            description: statusUpdatedAt
                                ? `We have successfully delivered the order to you On ${convertToReadableDateAndTime(
                                      statusUpdatedAt,
                                  )}`
                                : "",
                        },
                        ctaType: consumer === "TrackingScreen" ? "None" : "Reorder",
                    });
                    break;

                case "CANCELLED_AUTO":
                    setReturnValue({
                        header: {
                            title: "Order cancelled",
                        },
                        body: {
                            description: `This order was cancelled because ${storeName} did not confirm it on time`,
                        },
                        ctaType: "None",
                    });
                    break;

                case "CANCELLED_SELLER":
                    const description = getSellerCancellationInfo({
                        storeName,
                        cancellationReason,
                    });

                    setReturnValue({
                        header: {
                            title: "Order cancelled",
                        },
                        body: {
                            description,
                            sellerCancellationReason: cancellationDetails?.description.length
                                ? `${cancellationDetails?.description}`
                                : undefined,
                        },
                        ctaType: "None",
                    });
                    break;

                case "CANCELLED_USER":
                    setReturnValue({
                        header: {
                            title: "Order cancelled",
                        },
                        body: {
                            description: statusUpdatedAt
                                ? `You cancelled this order on ${convertToReadableDateAndTime(
                                      statusUpdatedAt,
                                  )}`
                                : "You cancelled this order",
                        },
                        ctaType: "None",
                    });
                    break;

                case "CANCELLED_CC":
                    setReturnValue({
                        header: {
                            title: "Order cancelled",
                        },
                        body: {
                            description: cancellationDetails?.reasons?.length
                                ? cancellationDetails.reasons[0]
                                : "This order was cancelled",
                        },
                        ctaType: "None",
                    });
                    break;

                case "TRANSACTION_IN_PROGRESS":
                    setReturnValue({
                        header: {
                            title: "Awaiting payment status",
                        },
                        body: {
                            description: "We are checking with the bank to confirm payment status",
                        },
                        ctaType: txnId ? "RetryPayment" : "RetryPayment",
                    });
                    break;

                case "TRANSACTION_FAILED":
                    setReturnValue({
                        header: {
                            title: "Payment failed",
                        },
                        body: {
                            description: `If any amount was deducted from your account, it would be refunded in 5 - 7 business days${
                                timer && timer > 0
                                    ? `. To proceed with this order, please retry within ${displayTimer} min(s)`
                                    : ""
                            }`,
                        },
                        ctaType: "RetryPayment",
                    });
                    break;

                case "PAYMENT_FAILED":
                    setReturnValue({
                        header: {
                            title: "Payment failed",
                        },
                        body: {
                            description:
                                "Your payment for this order could not be completed. If any amount was deducted from your account, it would be refunded in 5 - 7 business days",
                        },
                        ctaType: "Reorder",
                    });
                    break;

                case "CANCELLED_RTO":
                case "CANCELLED_RTO_DELIVERED":
                case "CANCELLED_DISPATCH":
                    setReturnValue({
                        header: {
                            title: "Returning to seller",
                            subTitle: "Order has been cancelled",
                        },
                        body: {
                            description:
                                "This order wasn’t delivered and has been returned to the seller. You may reach out to the seller for any clarifications.\n\nYour payment for this order will be refunded back to your original payment method.",
                            sellerCancellationReason: cancellationDetails?.reasons?.length
                                ? cancellationDetails.reasons[0]
                                : undefined,
                        },
                        ctaType: "Reorder",
                    });
                    break;
            }
        },
        [
            storeName,
            orderPendingETANote,
            consumer,
            orderAcceptedETANote,
            shippingDetails,
            statusUpdatedAt,
            cancellationReason,
            cancellationDetails?.description,
            cancellationDetails?.reasons,
            txnId,
            timer,
            displayTimer,
        ],
    );

    useIsomorphicEffect(() => {
        const secondsLeft = timeRemainingInSeconds();
        const status: OrderStatus =
            orderTrackStatus === "INITIATED"
                ? secondsLeft > 0
                    ? "TRANSACTION_FAILED"
                    : "PAYMENT_FAILED"
                : orderTrackStatus;

        textGenerator(status);
    }, [orderTrackStatus, textGenerator, timeRemainingInSeconds]);

    return returnValue;
};

export type ShippingInfoParams = {
    storeName: string;

    shipping?: ShippingDetails;
};

export const getShippingInfo = (params: ShippingInfoParams): TrackingMeta["body"] => {
    const { shipping, storeName } = params;

    const isUrl = shipping?.trackInfo && isUri(shipping.trackInfo);
    const addTwoNewLines = addNewLines(2);

    let body: TrackingMeta["body"] = { description: "" };

    switch (shipping?.partner) {
        case "MERCHANT_THIRD_PARTY":
            body = {
                description: `Delivery via ${shipping.courierPartner}${addTwoNewLines}${
                    isUrl ? "Track your order:" : "Tracking info:"
                }`,
                trackUrl: isUrl ? shipping.trackInfo : undefined,
                awb: !isUrl ? shipping.trackInfo : undefined,
            };

            break;

        case "MERCHANT_FLEET":
            body = {
                description: `${storeName} will deliver your order directly${addTwoNewLines}Contact Number:`,
                mobile: shipping.deliveryAgent?.contactInfo,
                agentName: shipping.deliveryAgent?.name,
            };

            break;

        default:
            const descriptionPrefix = "We have dispatched your order via";

            const deliveryPartnerName =
                SHIPPING_PARTNER_NAME[shipping?.partner || ""] ?? shipping?.partner;

            const partnerName = shipping?.courierPartner ?? deliveryPartnerName;

            body = {
                description: `${descriptionPrefix} ${partnerName}${addTwoNewLines}Track your order :`,
                trackUrl: isUrl ? shipping.trackInfo : "https://" + shipping?.trackInfo,
            };
    }

    return body;
};

type SellerCancellationInfoParams = {
    storeName: string;
    cancellationReason: CancelOrderReason | "";
};
const getSellerCancellationInfo = (params: SellerCancellationInfoParams): string => {
    const { storeName, cancellationReason } = params;

    switch (cancellationReason) {
        case "ITEMS_OUT_OF_STOCK":
            return `${storeName} cancelled the order as the item(s) in the order is out of stock`;

        case "STORE_OFFLINE":
            return `${storeName} cancelled the order as the store is not accepting orders at the moment`;

        case "CUSTOMER_NOT_RESPONDING":
            return `The order is cancelled as ${storeName} couldn’t contact you regarding the order`;

        case "CUSTOMER_ADDRESS_NOT_SERVICEABLE":
            return `${storeName} cancelled the order as the address is not serviceable by the store`;
    }

    return "";
};

const addNewLines = (numberOfLines: number): string => "\n".repeat(numberOfLines);
