import * as React from "react";

import { Product } from "@minis-consumer/interfaces/catalog";

export interface IClearCartModalContext {
    isOpen: boolean;
    close: VoidFunction;
    open: VoidFunction;

    // to show messages accordingly
    descriptionPrefix: string;
    storeId: string;

    // to set data for messages
    setStoreId: (e: string) => void;
    setDescriptionPrefix: (e: string) => void;

    type?: Product["productType"];
    onClearCartCallback?: VoidFunction;
    setClearCartCallback?: (callback: VoidFunction) => void;
}

export const ClearCartModalContext = React.createContext<IClearCartModalContext | null>(null);

export const ClearCartModalContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [descriptionPrefix, setDescriptionPrefix] = React.useState("");
    const [storeId, setStoreId] = React.useState("");

    const close = React.useCallback(() => setIsOpen(false), []);
    const open = React.useCallback(() => setIsOpen(true), []);
    const callback = React.useRef<VoidFunction>(() => null);
    const setCallback = (onClearCart: VoidFunction): void => {
        callback.current = onClearCart;
    };

    const value = React.useMemo(
        () => ({
            isOpen,
            close,
            open,
            descriptionPrefix,
            storeId,
            setStoreId,
            setDescriptionPrefix,
            setClearCartCallback: setCallback,
            onClearCartCallback: callback.current,
        }),
        [isOpen, close, open, descriptionPrefix, setDescriptionPrefix, storeId, setStoreId],
    );

    return (
        <ClearCartModalContext.Provider value={value}>{children}</ClearCartModalContext.Provider>
    );
};
