import React, { FC, memo, useCallback, useMemo } from "react";

import { KebabMenu, MenuItem, MENU_ITEM_IDS } from "@swiggy-private/connect-chat-ui";

import { useChatBlockyModalHandler } from "@minis-consumer/hooks/use-chat-block-modal-handler";
import { Analytics } from "@minis-consumer/analytics";
import { getMenuItems } from "@minis-consumer/components/chat/helpers/header";

const MenuComponent: FC<{
    conversationId?: string;
    isBlocked?: boolean;
}> = ({ isBlocked, conversationId }) => {
    const { modalViewHandler } = useChatBlockyModalHandler();

    const analyticsContext = React.useMemo(
        () =>
            JSON.stringify({
                conversationId,
            }),
        [conversationId],
    );

    const menuItems: MenuItem[] = useMemo(() => getMenuItems(isBlocked), [isBlocked]);

    const handleMenuItemPress = useCallback(
        (item: MenuItem) => {
            Analytics.clickEvent({
                category: item.analyticsEventName,
                context: analyticsContext,
            });

            switch (item.id) {
                case MENU_ITEM_IDS.BLOCK: {
                    modalViewHandler(true);
                }
            }
        },
        [analyticsContext, modalViewHandler],
    );

    const onViewCallback = React.useCallback(() => {
        menuItems.forEach((item) => {
            Analytics.impressionEvent({
                category: item.analyticsEventName,
                context: analyticsContext,
            });
        });
    }, [menuItems, analyticsContext]);

    if (!conversationId) {
        return null;
    }

    return (
        <KebabMenu
            menuItems={menuItems}
            onMenuItemPress={handleMenuItemPress}
            onViewCallback={onViewCallback}
        />
    );
};

export const Menu = memo(MenuComponent);
