import React from "react";
import { Platform, ViewStyle, ScrollView, View } from "react-native";

import { SpacingValue, Button, useLayout } from "@swiggy-private/rn-dls";
import { Box } from "@swiggy-private/rn-adaptive-layout";
import { useKeyboardHeight } from "@swiggy-private/react-native-ui";

import { useEnquiryChat } from "@minis-consumer/hooks/use-product-enquiry-chat";
import { useToast } from "@minis-consumer/hooks/use-toast";
import { Store } from "@minis-consumer/interfaces/store";
import { useChatBlockedErrorSnackbarHandler } from "@minis-consumer/hooks/use-chat-blocked-snack-handler";

import { CTA_TEXT } from "../../constants";
import { ModalBodyHeader } from "../header";
import { ModalBodyContent } from "../content";

interface Props {
    storeId: Store["storeId"];
    closeModal: VoidFunction;
    onSendMessage: (enquiryMessage: string) => void;

    imageUrl?: string;
    queryText?: string;
    name?: string;
    showToastMsg?: boolean;
    variantText?: string;
}

const ModalBodyComponent: React.FC<Props> = ({
    imageUrl,
    queryText,
    name,
    variantText,
    showToastMsg,
    closeModal,
    onSendMessage,
    storeId,
}) => {
    const [enquiryMessage, setEnquiryMessage] = React.useState("");
    const [sending] = React.useState(false);

    const [showToast] = useToast();

    const { blockedInfo } = useEnquiryChat(storeId);

    const { dataHandler } = useChatBlockedErrorSnackbarHandler();

    const isBtnDisabled = React.useMemo(
        () => !enquiryMessage.trim().length || sending,
        [enquiryMessage, sending],
    );

    const actionBtn: ViewStyle = {
        width: "100%",
        height: 52,
        marginTop: SpacingValue["space-medium"],
    };

    const [layout, onLayout] = useLayout();
    const keyboardHeight = useKeyboardHeight();

    const onPressSend = React.useCallback(() => {
        if (!enquiryMessage) {
            return;
        }

        if (blockedInfo?.blockedBy?.length) {
            dataHandler(true, blockedInfo);
            closeModal();
            return;
        }
        onSendMessage(enquiryMessage);
        closeModal();

        showToastMsg && showToast("Query sent to seller over chat");
    }, [
        blockedInfo,
        onSendMessage,
        enquiryMessage,
        closeModal,
        showToastMsg,
        showToast,
        dataHandler,
    ]);

    const style = {
        opacity: layout.measured ? 1 : 0,
    };

    return (
        <ScrollView keyboardShouldPersistTaps="handled" bounces={false}>
            <Box collapsable={false} onLayout={onLayout} style={style}>
                <ModalBodyHeader
                    closeModal={closeModal}
                    imageUrl={imageUrl}
                    queryText={queryText}
                    name={name}
                    variantText={variantText}
                />
                <ModalBodyContent onChange={setEnquiryMessage} />

                <Button
                    color="primary"
                    size="medium"
                    elevation={2}
                    style={actionBtn}
                    onPress={onPressSend}
                    disabled={isBtnDisabled}>
                    {CTA_TEXT}
                </Button>
            </Box>

            {Platform.OS === "ios" ? <View style={[{ height: keyboardHeight }]} /> : null}
        </ScrollView>
    );
};

export const ModalBody = React.memo(ModalBodyComponent);
