import { ADDRESS_LAT_LNG_ID } from "@minis-consumer/constants";
import { useCartView } from "@minis-consumer/hooks/use-cart";
import { UserAddress } from "@minis-consumer/interfaces/cart";

import { useAddressList } from "./use-addresses";

export const useAddressListData = (
    storeId: string,
    fetchAddressesFromAddressApi: boolean,
): UserAddress[] => {
    const cart = useCartView(storeId);
    const addressListData = useAddressList(storeId)?.addresses;

    if (fetchAddressesFromAddressApi) {
        return addressListData || [];
    }

    return (
        cart?.cartViewData?.addresses.filter((address) => address.id !== ADDRESS_LAT_LNG_ID) || []
    );
};
