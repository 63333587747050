import { Platform } from "react-native";

export const DEFAULTS = {
    CLOSE_ICON_SIZE: 14,
    OPTION_SELECTION_ANIMATION_DURATION: 100,
    CTA_ICON_SIZE: Platform.OS === "android" ? 28 : 24,
    CTA_ANIMATION_DURATION: 200,
    TRANSLATE_ANIMATION_DURATION: 250,
    FEEDBACK_TOAST_PREFIX: "Thanks for sharing your feedback with us!",
    BULK_FEEDBACK_TOAST_SUFFIX: "It’ll help us improve our services",
    STORE_FEEDBACK_TOAST_PREFIX: "It’ll help",
    STORE_FEEDBACK_TOAST_SUFFIX: "get even better",
    INPUT_FEEDBACK_CHARACTER_THRESHOLD: 5,
};
