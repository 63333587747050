import React from "react";
import { StyleSheet, Platform } from "react-native";

import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";

import type { Product } from "@minis-consumer/interfaces/catalog";

interface RecommendedBadgeProps {
    badges: Product["badges"];
}
const RECOMMENDED = "Recommended";
const isWeb = Platform.OS === "web";

const RecommendedBadgeComponent: React.FC<RecommendedBadgeProps> = ({ badges }) => {
    const { value: theme } = useTheme();

    const showRecommendedBadge: boolean = React.useMemo(() => {
        return badges.includes(RECOMMENDED);
    }, [badges]);

    if (!showRecommendedBadge) {
        return null;
    }

    const badgeStyle = {
        ...styles.badgeStyle,
        backgroundColor: theme["background_Button_Primary"],
    };

    const triangleBottomStyle = {
        ...styles.triangleBottom,
        borderBottomColor: theme["background_Button_Primary"],
    };

    return (
        <Stack direction="row" style={styles.badgeContainer}>
            {!isWeb && <Box style={[triangleBottomStyle, styles.triangle]} />}
            <Box style={badgeStyle}>
                <Text category="b3" weight="bold" color="color-basic-0">
                    {RECOMMENDED}
                </Text>
            </Box>
            {!isWeb && <Box style={triangleBottomStyle} />}
        </Stack>
    );
};

const styles = StyleSheet.create({
    badgeStyle: {
        paddingVertical: SpacingValue["space-x-small"],
        paddingHorizontal: SpacingValue["space-medium"],
        borderBottomLeftRadius: 2,
        borderTopRightRadius: 2,
        borderBottomRightRadius: 24,
        borderTopLeftRadius: 24,
        ...Platform.select({
            web: {
                borderTopLeftRadius: 12,
            },
        }),
    },
    badgeContainer: {
        zIndex: 1,
        position: "absolute",
        top: -5,
        left: -5,
        ...Platform.select({
            web: {
                top: 0,
                left: 0,
            },
        }),
    },
    triangle: {
        right: -4,
        left: "auto",
        top: 1,
        borderBottomWidth: 4,
        transform: [{ rotate: "90deg" }],
    },
    triangleBottom: {
        position: "absolute",
        transform: [{ rotate: "-90deg" }],
        bottom: -5,
        left: 1,
        width: 0,
        height: 0,
        backgroundColor: "transparent",
        borderStyle: "solid",
        borderTopWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 5,
        borderLeftWidth: 5,
        borderTopColor: "transparent",
        borderRightColor: "transparent",
        borderLeftColor: "transparent",
    },
});

export const RecommendedBadge = React.memo(RecommendedBadgeComponent);
