import { setValue, getObjectValue } from "@swiggy-private/common-helpers";

export class Counter {
    private static _instance: Counter | null = null;
    private counters: { [key: string]: number } = {};

    private savingCounter = false;
    private timeout: number | null = null;

    private static _storageKey = "@minis_counters";

    private constructor() {
        //
    }

    static async load(): Promise<Counter> {
        const counters = await getObjectValue<{ [key: string]: number }>(Counter._storageKey);
        const counterInstance = Counter.getInstance();

        if (typeof counters === "object" && counters != null) {
            counterInstance.counters = counters;
        }

        return counterInstance;
    }

    static getInstance(): Counter {
        if (Counter._instance == null) {
            Counter._instance = new Counter();
            return Counter._instance;
        }

        return Counter._instance;
    }

    get(key: string): number {
        return this.counters[key] ?? 0;
    }

    set(key: string, count = 1): void {
        if (this.counters[key] == null) {
            this.counters[key] = 0;
        }

        this.counters[key] += count;
        this.save();
    }

    private async save(): Promise<void> {
        if (this.savingCounter) {
            this.timeout && clearTimeout(this.timeout);
            this.timeout = setTimeout(this.save, 0) as unknown as number;
            return;
        }

        this.savingCounter = true;
        await setValue(Counter._storageKey, this.counters);
        this.savingCounter = false;
    }
}
