import React, { useCallback } from "react";
import { InteractionManager, Platform, Pressable, View, ViewStyle } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useFocusEffect, useNavigation } from "@react-navigation/core";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { AnimatedView } from "@swiggy-private/react-native-ui";
import { Text, SpacingValue, useTheme, Surface } from "@swiggy-private/rn-dls";
import { useScreenSize, ScreenSize, Box } from "@swiggy-private/rn-adaptive-layout";

import { useCartViewData, useLocalCart } from "@minis-consumer/hooks/use-cart";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { MinisRouteList, RouteList } from "@minis-consumer/interfaces/route";
import { Analytics } from "@minis-consumer/analytics";
import { useIsDesktopWeb } from "@minis-consumer/hooks/use-dweb";
import { TEST_IDS_COMMON } from "@minis-consumer/constants/test-ids/common";
import { SF_ANALYTICS_EVENT_NAMES } from "@minis-consumer/routes/shop/constants";

/** This component will be reused for proceed CTA,
 * remaining refactoring will be done with that
 * */
export const TAB_MIN_HEIGHT = 66;
const YOUR_CART = "Your cart";

const CartTabFloatingComponent: React.FC = () => {
    const screenSize = useScreenSize();
    const store = useStoreInfo();
    const isDWeb = useIsDesktopWeb();

    const insets = useSafeAreaInsets();
    const { value: theme } = useTheme();

    const cartView = useLocalCart(store.storeId);
    const cartItemsTotal = cartView.items.reduce((sum, item) => sum + item.quantity, 0) || 0;
    const cart = useCartViewData(store.storeId);

    const [cartItemCount, setCartItemCount] = React.useState(0);
    const [willshow, setWillShow] = React.useState(false);

    const navigation = useNavigation<NativeStackNavigationProp<RouteList>>();
    const minisNavigation = useNavigation<NativeStackNavigationProp<MinisRouteList>>();

    const analyticsContext = React.useMemo(() => {
        return {
            category: SF_ANALYTICS_EVENT_NAMES.CART_FOOTER,
            context: JSON.stringify({
                items: cartView.items.map((item) => item.id),
                cartId: cart?.id,
            }),
        };
    }, [cartView, cart]);

    const navigateToCart = useCallback(() => {
        Analytics.clickEvent(analyticsContext);

        const miniRoutes = minisNavigation?.getState().routeNames;

        if (miniRoutes?.indexOf("MiniStore") > -1) {
            minisNavigation?.navigate("MiniStore", { slug: store.slug, initialPath: "cart" });
        } else {
            navigation.navigate("Home", {
                screen: "Cart",
                params: {},
            });
        }
    }, [navigation, minisNavigation, store.slug, analyticsContext]);

    useFocusEffect(
        useCallback(() => {
            cartItemsTotal > 0 && Analytics.impressionEvent(analyticsContext);

            const task = InteractionManager.runAfterInteractions(() => {
                if (cartItemsTotal > 0) {
                    setCartItemCount(cartItemsTotal);
                    setWillShow(true);
                } else {
                    Platform.OS === "web" && setCartItemCount(0);
                    setWillShow(false);
                }
            });

            return () => {
                task.cancel();
                setWillShow(false);
            };
        }, [cartItemsTotal, analyticsContext]),
    );

    const containerStyle: ViewStyle = React.useMemo(() => {
        return {
            position: "absolute",
            right: 0,
            left: 0,
            bottom: 0,
            minHeight: cartItemCount ? TAB_MIN_HEIGHT : 0,
        };
    }, [cartItemCount]);

    if (screenSize === ScreenSize.Large || !cartItemCount) {
        return null;
    }

    const pressableStyle: ViewStyle = {
        backgroundColor: theme["color-primary"],
        padding: SpacingValue["space-medium"],
        borderRadius: 12,
    };

    const cardStyle: ViewStyle = {
        backgroundColor: theme["color-basic-0"],
        paddingTop: SpacingValue["space-medium"],
        paddingBottom: Math.max(insets.bottom, SpacingValue["space-medium"]),
        paddingHorizontal: SpacingValue["space-medium"],
        elevation: Platform.OS !== "ios" ? 1 : 4,
        borderColor: theme["color-basic-5"],
        borderTopWidth: 1,
    };

    const cartTextStyle: ViewStyle = {
        marginBottom: Platform.select({
            android: -SpacingValue["space-xxx-small"],
            ios: -((SpacingValue["space-xx-small"] + SpacingValue["space-xxx-small"]) / 2),
        }),
    };

    const renderCart = (): React.ReactElement => {
        return (
            <Surface style={cardStyle} testID={TEST_IDS_COMMON.CART_FLOATING_CTA}>
                <Pressable onPress={navigateToCart} style={pressableStyle}>
                    <Box alignItems="center" justifyContent="center">
                        <Text
                            category="btn4"
                            weight="bold"
                            color="color-basic-0"
                            style={cartTextStyle}>
                            {YOUR_CART} ({cartItemCount || 0})
                        </Text>
                    </Box>
                </Pressable>
            </Surface>
        );
    };

    return (
        <View style={containerStyle} pointerEvents="box-none">
            {isDWeb ? (
                renderCart()
            ) : (
                <AnimatedView
                    duration={350}
                    animationType="slide_vertical"
                    reverse={!willshow}
                    onFinish={() => setCartItemCount(cartItemsTotal)}>
                    {renderCart()}
                </AnimatedView>
            )}
        </View>
    );
};

export const CartTabFloating = React.memo(CartTabFloatingComponent);
