const isWindowSessionStorageAvailable = (): boolean => {
    try {
        return typeof window !== "undefined" && typeof window.sessionStorage !== "undefined";
    } catch (e) {
        return false;
    }
};

export const closeNudgeForSession = (): void => {
    if (isWindowSessionStorageAvailable()) {
        window.sessionStorage.setItem("app_nudge_closed", "true");
    }
};

export const appNudgeClosedForSession = (): boolean => {
    if (isWindowSessionStorageAvailable()) {
        const isSessionClosed = window.sessionStorage.getItem("app_nudge_closed");

        return isSessionClosed === "true";
    }

    return false;
};
