import { useMount } from "@swiggy-private/react-hooks";

import { useShowCounter } from "@minis-consumer/hooks/use-show-counter";

/**
 * There are multiple type of user onboarding -
 * - Bottom sheet if user directly lands on storefront
 * - Instagram like stories
 *
 * This keeps a check if the user has been on-boarded via any mean or not
 */
export const useAutoplayOnboarding = (): boolean => {
    const [autoplayOnboarding, incrementCounter] = useShowCounter("autoplay_user_onboarding", 1);

    useMount(() => {
        if (autoplayOnboarding) {
            incrementCounter();
        }
    });

    return autoplayOnboarding;
};
