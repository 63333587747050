import { useCallback } from "react";
import { MediaType } from "react-native-image-picker";

import { useMountedRef } from "@swiggy-private/react-hooks";
import { useToast } from "@minis-consumer/hooks/use-toast";

import {
    MediaFile,
    pickMediaFromGallery,
    pickMediaUsingCamera,
    pickMediaUsingFiles,
} from "@swiggy-private/rn-image-picker";

interface UseSelectMediaFromDeviceParams {
    mediaType: MediaType;
    onSelect: (media: MediaFile[]) => void;
    limit: number;
    videoDurationLimit?: number;
}

export const useSelectMediaFromDevice = (
    params: UseSelectMediaFromDeviceParams,
): [() => void, () => void, () => void] => {
    const { mediaType, onSelect, limit, videoDurationLimit } = params;

    const [showToast] = useToast();
    const mounted = useMountedRef();

    const errorHandler = useCallback(
        (err: unknown): void => {
            if (mounted.current) {
                err instanceof Error && showToast(err.message);
                onSelect([]);
            }
        },
        [mounted, onSelect, showToast],
    );

    const successHandler = useCallback(
        (files: MediaFile[]): void => {
            if (mounted.current) {
                onSelect(files);
            }
        },
        [mounted, onSelect],
    );

    const uploadFromGallery = useCallback((): void => {
        pickMediaFromGallery({
            mediaType,
            selectionLimit: limit <= 0 ? 1 : limit,
        })
            .then(successHandler)
            .catch(errorHandler);
    }, [errorHandler, limit, mediaType, successHandler]);

    const uploadFromCamera = useCallback((): void => {
        pickMediaUsingCamera({
            mediaType,
            durationLimit: videoDurationLimit,
        })
            .then(successHandler)
            .catch(errorHandler);
    }, [errorHandler, mediaType, successHandler, videoDurationLimit]);

    const uploadFromFiles = useCallback((): void => {
        pickMediaUsingFiles().then(successHandler).catch(errorHandler);
    }, [errorHandler, successHandler]);

    return [uploadFromGallery, uploadFromCamera, uploadFromFiles];
};
