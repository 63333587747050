import { useMemo } from "react";
import { useCache } from "@rest-hooks/react";

import { CatalogView } from "@minis-consumer/resources/catalog";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import type { Product } from "@minis-consumer/interfaces/catalog";

export const useGetProducts = (): Product[] => {
    const storeInfo = useStoreInfo();
    const { products } = useCache(CatalogView, { storeSlug: storeInfo.slug });
    return useMemo(() => (products ? Object.values(products) : []), [products]);
};

export const useHasPhysicalProducts = (): boolean => {
    const storeInfo = useStoreInfo();
    const { products } = useCache(CatalogView, { storeSlug: storeInfo.slug });

    return !!products?.filter((item) => item.productType === "PHYSICAL")?.length;
};
