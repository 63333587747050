import React from "react";

import { isWindowSessionStorageAvailable } from "@swiggy-private/common-helpers";

import { Logger } from "@minis-consumer/includes/logger";
import { ICartGuestDetails } from "@minis-consumer/interfaces/cart";

export const GUEST_ADDRESS = "guest_address";

export const useGuestCheckOutAddress = (): ICartGuestDetails | undefined => {
    const [guestDetails, setGuestDetails] = React.useState<ICartGuestDetails>();
    const fetchGuestData = React.useCallback(async (): Promise<void> => {
        try {
            const data = sessionStorage.getItem(GUEST_ADDRESS);
            if (data) {
                const address = (await JSON.parse(data)) as ICartGuestDetails;
                setGuestDetails(address);
            }
        } catch (e) {
            Logger.recordError(e);
        }
    }, []);

    React.useEffect(() => {
        if (isWindowSessionStorageAvailable()) {
            fetchGuestData();
        }
    }, [fetchGuestData]);

    return guestDetails;
};
