import { TEventName } from "@swiggy-private/meta-sdk";

/**
 * All meta pixel constants used in app.
 */
export const META_PIXEL_EVENT_NAMES: { [key: string]: TEventName } = {
    ADD_TO_CART: "AddToCart",
    PURCHASE: "Purchase",
    ADD_PAYMENT_INFO: "AddPaymentInfo",
    COMPLETE_REGISTRATION: "CompleteRegistration",
    CONTACT: "Contact",
    ADD_TO_WISHLIST: "AddToWishlist",
    CUSTOMIZE_PRODUCT: "CustomizeProduct",
    INITIATE_CHECKOUT: "InitiateCheckout",
    LEAD: "Lead",
    SEARCH: "Search",
    VIEW_CONTENT: "ViewContent",
    SUBMIT_APPLICATION: "SubmitApplication",
};

export const META_PIXEL_CONTENT_CATEGORY: { [key: string]: string } = {
    CHAT: "chat",
    CART: "cart",
    MOBILE: "mobile",
    EMAIL: "email",
    SF_HOME: "sf_home",
    SF_RECOMMENDED: "sf_recommended",
    PRODUCT_DETAILS: "product_details",
};

export const META_PIXEL_CONTENT_NAME: { [key: string]: string } = {
    BANK: "bank",
    CARD: "card",
    UPI: "upi",
    CART_VIEW: "cart-view",
    STORE_VIEW: "store-view",
    PAYMENT_VIEW: "payment-view",
    PRODUCT_VIEW: "product-view",
};
