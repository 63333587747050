import React from "react";
import { useNavigation, NavigationProp } from "@react-navigation/core";

import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { RouteList, MinisRouteList } from "@minis-consumer/interfaces/route";

import { useMinisNavigation } from "./use-minis-navigation";

export const useShopNavigation = (): ((slug?: string) => void) => {
    const store = useStoreInfo(false);
    const navigation = useNavigation<NavigationProp<RouteList>>();
    const minisNavigation = useNavigation<NavigationProp<MinisRouteList>>();
    const storeContextNavigation = useMinisNavigation();

    return React.useCallback(
        (slug?: string) => {
            if (store && storeContextNavigation) {
                navigation?.navigate("Home", { screen: "Shop" });
                return;
            }

            slug && minisNavigation?.navigate("MiniStore", { slug });
        },
        [minisNavigation, navigation, store, storeContextNavigation],
    );
};
