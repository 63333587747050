import * as React from "react";
import { ActivityIndicator } from "@swiggy-private/rn-dls";

interface AsyncComponentProps {
    children?: React.ReactNode | undefined;
    fallback?: React.FC | NonNullable<React.ReactNode> | null;
}

export const AsyncComponent: React.FC<AsyncComponentProps> = (props) => {
    const { fallback = <ActivityIndicator />, children } = props;

    const fallbackElement = React.useMemo(() => {
        if (!React.isValidElement(fallback)) {
            const FallbackComponent = fallback as React.FC;
            return <FallbackComponent />;
        }

        return fallback;
    }, [fallback]);

    return <React.Suspense fallback={fallbackElement}>{children}</React.Suspense>;
};
