import React from "react";

import { Box } from "@swiggy-private/rn-adaptive-layout";
import { Text, SpacingValue } from "@swiggy-private/rn-dls";
import { CdnImage } from "@swiggy-private/react-native-ui";
import { StoriesAnalytics } from "@swiggy-private/react-native-stories";

import { FollowUnfollowCTA } from "@minis-consumer/components/widgets/widget-store-header-with-cta/follow-unfollow-cta";

const DEFAULT_LOGO_SIZE = 32;
const CONSTANT_STRINGS = { LOVE_THIS: "Love this" };

interface IHeaderProps {
    title: string;
    timestamp: string;
    image: string;
    storeId: string;
    analytics: StoriesAnalytics;
}

const HeaderComponent: React.FC<IHeaderProps> = ({
    image,
    timestamp,
    title,
    storeId,
    analytics,
}) => {
    return (
        <Box direction="row" flex={1} alignItems="center" style={{ maxWidth: "75%" }}>
            <CdnImage id={image} width={32} height={32} style={{ borderRadius: 8 }} />

            <Box direction="column" ml={8} style={{ maxWidth: "60%" }}>
                <Text category="b1" weight="bold" color={"color-basic-0"} numberOfLines={1}>
                    {title}
                </Text>
                <Text category="b3" weight="regular" color="color-basic-30">
                    {timestamp}
                </Text>
            </Box>
            <Box ml={12}>
                <FollowUnfollowCTA
                    storeId={storeId}
                    logoSize={DEFAULT_LOGO_SIZE}
                    hideIcon
                    renderCTA={() => {
                        return (
                            <Text category="btn5" color="color-basic-75" weight="bold">
                                {CONSTANT_STRINGS.LOVE_THIS}
                            </Text>
                        );
                    }}
                    style={{
                        paddingHorizontal: SpacingValue["space-x-small"],
                        paddingVertical: SpacingValue["space-xx-small"],
                    }}
                    onPressFollow={analytics.sendLoveClickEvent}
                />
            </Box>
        </Box>
    );
};

export const Header = React.memo(HeaderComponent);
