import { SwiggyRNModule, IUPIAppsList } from "@minis-consumer/includes/swiggy-rn-module";

export const getAllUpiAppsInstalledOnDevice = (): Promise<Array<Partial<IUPIAppsList>>> => {
    const swiggyRNModule = SwiggyRNModule.getInstance();
    return swiggyRNModule.getAllUpiIntentAppsInstalledOnDevice();
};

export const openUPIIntent = (deeplinkUri: string, packageName: string): Promise<string> => {
    const swiggyRNModule = SwiggyRNModule.getInstance();
    return swiggyRNModule.launchIntent(deeplinkUri, packageName);
};
