import React, { FC, memo, useCallback, useMemo } from "react";
import { Animated, Platform, Pressable, StyleSheet } from "react-native";

import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { useMount } from "@swiggy-private/react-hooks";
import { SpacingValue, useAnimatedValue, Text, Button, ButtonProps } from "@swiggy-private/rn-dls";
import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { InView } from "@swiggy-private/react-native-ui";

import { ProductCtaProps } from ".";
import { withVariantFormCalendarSelector } from "./hoc/with-variant-form-calendar";
import { QUANTITY_CTA_HITSLOP } from "./constants";

const MultipleQuantityProductCtaComponent: FC<Omit<ProductCtaProps, "ctaText" | "ctaState">> = ({
    quantity,
    incrementActionHandler: onIncrement,
    decrementActionHandler: onDecrement,
    ctaIconColor,
    ctaTextColor,
    isDisabled,
    ctaStyle,
    ctaTextStyle,
    ctaColor,
    impressionEvent,
}) => {
    const animatedValue = useAnimatedValue(0);

    const removeCounterStyle = useMemo(
        () => ({
            transform: [
                {
                    translateX: animatedValue.interpolate({
                        inputRange: [0, 1],
                        outputRange: [-SpacingValue["space-medium"], 0],
                    }),
                },
            ],
        }),
        [animatedValue],
    );

    const addCounterStyle = useMemo(
        () => ({
            transform: [
                {
                    translateX: animatedValue.interpolate({
                        inputRange: [0, 1],
                        outputRange: [SpacingValue["space-medium"], 0],
                    }),
                },
            ],
        }),
        [animatedValue],
    );

    const counterStyle = useMemo(
        () => ({
            transform: [
                {
                    translateY: animatedValue.interpolate({
                        inputRange: [0, 1],
                        outputRange: [-SpacingValue["space-medium"], 0],
                    }),
                },
            ],
        }),
        [animatedValue],
    );

    useMount(() => {
        Animated.timing(animatedValue, {
            toValue: 1,
            duration: 100,
            useNativeDriver: Platform.OS !== "web",
        }).start();
    });

    const onImpression = useCallback(
        (isVisible: boolean) => {
            isVisible && impressionEvent?.();
        },
        [impressionEvent],
    );

    if (!quantity) {
        return null;
    }

    const ctaProps: ButtonProps = {
        color: ctaColor ?? "primary",
        textStyle: ctaTextStyle,
        style: [styles.button, ctaStyle],
        elevation: 0,
    };

    return (
        <InView onChange={onImpression}>
            <Button textContainerStyle={styles.textContainer} {...ctaProps}>
                <Stack
                    flex={1}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    style={styles.stack}>
                    <Pressable
                        onPress={onDecrement}
                        accessibilityLabel="Remove"
                        disabled={isDisabled}
                        hitSlop={QUANTITY_CTA_HITSLOP}>
                        <Animated.View style={[styles.removeCounter, removeCounterStyle]}>
                            <SvgIcon
                                icon="Minus"
                                color={ctaIconColor ?? "color-primary"}
                                width={12}
                                height={12}
                                strokeWidth={2}
                            />
                        </Animated.View>
                    </Pressable>

                    <Animated.View style={counterStyle}>
                        <Text category="b3" weight="bold" color={ctaTextColor ?? "color-primary"}>
                            {quantity}
                        </Text>
                    </Animated.View>

                    <Pressable
                        onPress={onIncrement}
                        accessibilityLabel="Add"
                        disabled={isDisabled}
                        hitSlop={QUANTITY_CTA_HITSLOP}>
                        <Animated.View style={[styles.addCounter, addCounterStyle]}>
                            <SvgIcon
                                icon="Plus"
                                color={ctaIconColor ?? "color-primary"}
                                width={12}
                                height={12}
                                strokeWidth={2}
                            />
                        </Animated.View>
                    </Pressable>
                </Stack>
            </Button>
        </InView>
    );
};

export const MultipleQuantityProductCta = withVariantFormCalendarSelector(
    memo(MultipleQuantityProductCtaComponent),
);

MultipleQuantityProductCtaComponent.displayName = "MultipleQuantityProductCta";

const styles = StyleSheet.create({
    stack: {
        width: "100%",
        overflow: "hidden",
    },
    addCounter: {
        marginTop: SpacingValue["space-xxx-small"],
    },
    removeCounter: {
        marginTop: SpacingValue["space-xxx-small"],
    },
    button: {
        borderRadius: 8,
    },
    textContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        flex: 1,
    },
});
