import * as React from "react";
import { Platform, StyleProp, TextStyle, ViewProps, ViewStyle, StyleSheet } from "react-native";

import { NavigationProp, useNavigation } from "@react-navigation/native";

import { useMount } from "@swiggy-private/react-hooks";
import { Emoji, InView, CdnImage } from "@swiggy-private/react-native-ui";
import {
    Box,
    getScreenSize,
    ScreenSize,
    ScreenSizeProp,
    Stack,
    useSelectScreen,
} from "@swiggy-private/rn-adaptive-layout";
import { Button, SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";

import { Analytics } from "@minis-consumer/analytics";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { RouteList } from "@minis-consumer/interfaces/route";
import {
    META_PIXEL_CONTENT_CATEGORY,
    META_PIXEL_EVENT_NAMES,
} from "@minis-consumer/constants/meta-pixel-analytics";
import { fireMetaPixelEvents } from "@minis-consumer/helpers/meta-pixel-events";
import { SF_ANALYTICS_EVENT_NAMES } from "@minis-consumer/routes/shop/constants";
import { useGetConfigInfo } from "@minis-consumer/hooks/use-get-config-info";

export const ShopFooter: React.FC<ViewProps> = ({ style: propStyle, ...props }) => {
    const { value: theme } = useTheme();

    const footerWidth = useSelectScreen({
        lg: 632 as ViewStyle["width"],
        default: "100%",
    });

    const footerPadding = useSelectScreen({
        lg: SpacingValue["space-x-large"],
        default: SpacingValue["space-medium"],
    });

    const footerStyle = {
        width: footerWidth,
        borderTopLeftRadius: SpacingValue["space-large"],
        borderTopRightRadius: SpacingValue["space-large"],
        backgroundColor: theme["color-basic-5"],
        paddingVertical: footerPadding,
        paddingHorizontal: footerPadding,
        marginTop:
            Platform.OS === "web"
                ? -SpacingValue["space-xx-large"]
                : -SpacingValue["space-medium"] - 2,
    };
    const isNewServicesEnabled = useGetConfigInfo()?.isNewServicesEnabled;

    useMount(() => {
        Analytics.impressionEvent({ category: "talk-to-us-widget" });
    });

    return (
        <Box {...props} style={footerStyle} pb={SpacingValue["space-xx-large"] * 4}>
            <Stack
                spacing={{
                    default: SpacingValue["space-x-small"],
                }}>
                {!isNewServicesEnabled ? (
                    <Stack
                        direction="row"
                        spacing={SpacingValue["space-xx-small"]}
                        alignItems="center">
                        <Emoji id="chat_emoji" height={20} width={20} />
                        <Text category="b1" weight="bold" color="highest">
                            Talk to us
                        </Text>
                    </Stack>
                ) : null}
                <ChatWithUs />
            </Stack>
        </Box>
    );
};

const ChatWithUs: React.FC = () => {
    const { value: theme } = useTheme();
    const navigation = useNavigation<NavigationProp<RouteList>>();
    const storeInfo = useStoreInfo();

    const isNewServicesEnabled = useGetConfigInfo()?.isNewServicesEnabled;

    const stackDirection = useSelectScreen({
        lg: "row",
        md: "row",
        default: "column",
    }) as ScreenSizeProp<ViewStyle["flexDirection"]> | ViewStyle["flexDirection"];

    const containerPadding = useSelectScreen({
        lg: SpacingValue["space-x-large"],
        default: SpacingValue["space-large"],
    });

    const containerStyle = {
        padding: containerPadding,
        backgroundColor: theme["color-basic-0"],
        borderRadius: SpacingValue["space-large"],
        borderWidth: SpacingValue["space-xxx-small"],
        borderColor: theme["color-basic-15"],
        marginTop: isNewServicesEnabled ? 8 : undefined,
    };

    const chatContentAlignment = useSelectScreen({
        lg: "space-between",
        md: "flex-start",
        default: "center",
    }) as ViewStyle["justifyContent"];

    const chatTextAlignment = useSelectScreen({
        lg: {
            textAlign: "left",
        },
        md: {
            textAlign: "left",
        },
        default: {
            textAlign: "center",
        },
    }) as StyleProp<TextStyle>;

    const buttonStyle = useSelectScreen({
        lg: {
            width: "auto",
        },
        md: {
            width: "auto",
        },
        default: {
            width: 294,
        },
    });

    const onChatWithUs = (): void => {
        if (Platform.OS === "web" && getScreenSize() === ScreenSize.Large) {
            return;
        }
        Analytics.clickEvent({ category: SF_ANALYTICS_EVENT_NAMES.START_A_CHAT_CTA });

        fireMetaPixelEvents(META_PIXEL_EVENT_NAMES.CONTACT, {
            content_category: META_PIXEL_CONTENT_CATEGORY.CHAT,
        });
        navigation.getParent()?.navigate("ChatConversation", { storeId: storeInfo.storeId });
    };

    const onImpression = React.useCallback((isVisible: boolean) => {
        if (!isVisible) {
            return;
        }

        Analytics.impressionEvent({
            category: SF_ANALYTICS_EVENT_NAMES.START_A_CHAT_CTA,
        });
    }, []);

    const madeWithMinisLogoDirection = useSelectScreen({
        lg: "row",
        md: "column",
        default: "column",
    }) as ViewStyle["flexDirection"];

    return (
        <InView onChange={onImpression}>
            <Box style={containerStyle} flex={1}>
                <Stack
                    direction={stackDirection}
                    justifyContent={chatContentAlignment}
                    alignItems="center"
                    spacing={{
                        default: SpacingValue["space-small"],
                    }}>
                    <Text category="b1" color="color-basic-75" style={chatTextAlignment}>
                        Got any questions? Let&apos;s talk!
                    </Text>
                    <Button color="rn_dls_primary" style={buttonStyle} onPress={onChatWithUs}>
                        Contact Us
                    </Button>
                </Stack>
            </Box>
            <Stack
                style={styles.madeWithStack}
                justifyContent="center"
                direction={madeWithMinisLogoDirection}
                alignItems="center"
                spacing={SpacingValue["space-xx-small"]}>
                <Text category="b1" color="text_Button_Disabled" weight="medium">
                    Made With
                </Text>
                <CdnImage
                    id={"static-assets/swiggy_minis_orange"}
                    height={50}
                    width={200}
                    style={styles.logo}
                    resizeMode="contain"
                />
            </Stack>
        </InView>
    );
};

const styles = StyleSheet.create({
    logo: {
        height: 50,
        width: 200,
    },
    madeWithStack: {
        paddingTop: SpacingValue["space-xx-large"] + 8,
    },
});
