import React from "react";
import { ImageProps, Pressable, StyleProp, StyleSheet, ViewStyle } from "react-native";

import { CdnImage } from "@swiggy-private/react-native-ui";
import { SpacingValue, Surface, Text, TextProps, useTheme } from "@swiggy-private/rn-dls";
import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { useMount } from "@swiggy-private/react-hooks";

import { Analytics } from "@minis-consumer/analytics";
import { ANALYTICS_COPIES } from "@minis-consumer/routes/account/routes/abandoned-cart/constants";

import { DEFAULT } from "./constant";

interface EmptyContentProps {
    title: string;
    onPressCta: VoidFunction;

    ctaText?: string;
    description?: string;
    imageHeight?: number;
    imageWidth?: number;
    imageId?: string;
    imageProps?: ImageProps;
    titleProps?: TextProps;
    descriptionProps?: TextProps;
    ctaTextProps?: TextProps;
    style?: StyleProp<ViewStyle>;
}

const EmptyContentComponent: React.FC<EmptyContentProps> = ({
    imageId,
    imageHeight = DEFAULT.IMAGE_HEIGHT,
    imageWidth = DEFAULT.IMAGE_WIDTH,
    title,
    description,
    ctaText,
    titleProps,
    descriptionProps,
    ctaTextProps,
    imageProps,
    style,
    onPressCta,
}) => {
    const { value: theme } = useTheme();
    const ctaStyles = {
        backgroundColor: theme["color-primary-400"],
        borderColor: theme["color-primary-400"],
    };

    const onCtaPress = React.useCallback(() => {
        Analytics.clickEvent({
            category: ANALYTICS_COPIES.EXPLORE_MINIS_BTN,
        });

        onPressCta();
    }, [onPressCta]);

    const sendAnalyticsEvent = React.useCallback(() => {
        Analytics.screenViewEvent({
            category: ANALYTICS_COPIES.CART_EMPTY_STATE,
        });

        Analytics.impressionEvent({
            category: ANALYTICS_COPIES.EXPLORE_MINIS_BTN,
        });
    }, []);

    useMount(() => sendAnalyticsEvent());

    return (
        <Stack direction="column" alignItems="center" style={[styles.wrapper, style]}>
            {imageId ? (
                <CdnImage
                    isImageKitEnabled
                    id={imageId}
                    width={imageWidth}
                    height={imageHeight}
                    {...imageProps}
                />
            ) : null}

            <Text category="h3" color="color-basic-75" style={styles.title} {...titleProps}>
                {title}
            </Text>
            {description ? (
                <Text
                    category="b2"
                    weight="medium"
                    color="color-basic-60"
                    style={styles.description}
                    {...descriptionProps}>
                    {description}
                </Text>
            ) : null}

            {ctaText ? (
                <Surface style={styles.surface}>
                    <Pressable style={[styles.cta, ctaStyles]} onPress={onCtaPress}>
                        <Text category="btn2" weight="bold" color="color-basic-0" {...ctaTextProps}>
                            {ctaText}
                        </Text>
                    </Pressable>
                </Surface>
            ) : null}
        </Stack>
    );
};

const styles = StyleSheet.create({
    surface: { borderRadius: 12, elevation: 2 },
    wrapper: {
        paddingTop: "20%",
    },
    title: {
        marginTop: "18%",
    },
    description: {
        marginTop: SpacingValue["space-small"],
        marginBottom: SpacingValue["space-xx-large"],
    },
    cta: {
        borderRadius: 12,
        height: 52,
        width: 180,
        alignItems: "center",
        justifyContent: "center",
    },
});

export const EmptyContent = React.memo(EmptyContentComponent);
