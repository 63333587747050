import * as React from "react";
import { StyleSheet, ViewStyle } from "react-native";

import { ColorPalette, useTheme } from "@swiggy-private/rn-dls";
import { Box } from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";

import { OrderStatus } from "@minis-consumer/interfaces/order";
import { getSvgIcon } from "@minis-consumer/helpers/order";

type TrackingIndicatorComponentProps = {
    trackStatus: OrderStatus | "DORMANT";
    outerBoxStyle?: ViewStyle;
    innerBoxStyle?: ViewStyle;
    screen?: "Details" | "Tracking";
};

const TrackingIndicatorComponent: React.FC<TrackingIndicatorComponentProps> = ({
    trackStatus,
    outerBoxStyle,
    innerBoxStyle,
    screen,
}) => {
    const { value: theme } = useTheme();

    const outerBoxBackgroundColor = React.useMemo((): keyof ColorPalette => {
        if (
            trackStatus.includes("CANCELLED") ||
            trackStatus.includes("RTO") ||
            trackStatus === "DELIVERED" ||
            trackStatus === "DORMANT"
        ) {
            return "color-basic-0";
        }

        if (trackStatus.includes("FAILED")) {
            return screen === "Details" ? "color-critical-50" : "color-basic-0";
        }

        return "color-primary-50";
    }, [screen, trackStatus]);

    const trackSvgIcon = React.useMemo(() => getSvgIcon(trackStatus), [trackStatus]);

    return (
        <Box
            alignItems="center"
            justifyContent="center"
            style={[
                styles.outerBox,
                { backgroundColor: theme[outerBoxBackgroundColor] },
                outerBoxStyle,
            ]}>
            {trackStatus === "DELIVERED" ? (
                <SvgIcon icon="TickFilled" width={20} height={20} color="color-positive-400" />
            ) : trackStatus.includes("CANCELLED") ||
              trackStatus.includes("FAILED") ||
              trackStatus.includes("RTO") ? (
                <SvgIcon icon={trackSvgIcon} width={20} height={20} color="color-critical-300" />
            ) : (
                <Box
                    style={[
                        styles.innerBox,
                        {
                            backgroundColor:
                                trackStatus === "DORMANT"
                                    ? theme["color-basic-15"]
                                    : theme["color-primary-400"],
                        },
                        innerBoxStyle,
                    ]}
                />
            )}
        </Box>
    );
};

const styles = StyleSheet.create({
    outerBox: {
        height: 22,
        width: 22,
        borderRadius: 50,
    },
    innerBox: {
        height: 16,
        width: 16,
        borderRadius: 50,
    },
});

export const TrackingIndicator = React.memo(TrackingIndicatorComponent);
