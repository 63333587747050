import * as React from "react";

import {
    ChatBlockedErrorSnackbarContext,
    IChatBlockedErrorSnackContext,
} from "@minis-consumer/contexts/chat-blocked-snackbar-context";

type DataHandler = (
    b: boolean,
    bI?: IChatBlockedErrorSnackContext["blockedInfo"],
    eS?: IChatBlockedErrorSnackContext["extraStyle"],
) => void;

export const useChatBlockedErrorSnackbarHandler = (): {
    isOpen: boolean;
    blockedInfo: IChatBlockedErrorSnackContext["blockedInfo"];
    extraStyle: IChatBlockedErrorSnackContext["extraStyle"];
    dataHandler: DataHandler;
} => {
    const ctx = React.useContext(ChatBlockedErrorSnackbarContext);

    const dataHandler: DataHandler = React.useCallback(
        (open, bInfo, eStyle) => {
            ctx?.setBlockedInfo(bInfo);
            ctx?.setExtraStyle(eStyle);
            if (open) {
                ctx?.open();
            } else {
                ctx?.close();
            }
        },
        [ctx],
    );

    return {
        isOpen: !!ctx?.isOpen,
        blockedInfo: ctx?.blockedInfo,
        extraStyle: ctx?.extraStyle,
        dataHandler,
    };
};
