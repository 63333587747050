import { Clipboard, Platform } from "react-native";

import { ToastProps } from "@swiggy-private/rn-dls";

import { useToast } from "@minis-consumer/hooks/use-toast";
import { Logger } from "@minis-consumer/includes/logger";

type Params = {
    message: string;
    toastProps?: ToastProps;
    toastDuration?: number;
    toastText?: string;
};

const DEFAULT_VALUES = {
    TOAST_TEXT: "Link copied!",
    TOAST_DURATION: 2_500,
};

export const useCopyToClipboard = (): ((params: Params) => void) => {
    const [toast] = useToast();

    const copyToClipboard = async (params: Params): Promise<void> => {
        const {
            message,
            toastText = DEFAULT_VALUES.TOAST_TEXT,
            toastDuration = DEFAULT_VALUES.TOAST_DURATION,
            toastProps,
        } = params;

        try {
            if (!message) {
                return;
            }
            if (Platform.OS !== "web") {
                Clipboard.setString(message);
                toast(toastText, toastDuration, toastProps);
                return;
            }
            if (message) {
                await navigator.clipboard.writeText(message).then(function () {
                    toast(toastText, toastDuration, toastProps);
                });
            }
        } catch (e) {
            typeof e === "string" && toast(e);
            Logger.error("Could not copy text", e);
        }
    };
    return copyToClipboard;
};
