import React from "react";
import { StyleSheet } from "react-native";

import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";

import type { Product } from "@minis-consumer/interfaces/catalog";

interface RecommendedBadgeProps {
    badges: Product["badges"];
}
const RECOMMENDED = "Recommended";

const RecommendedBadgeComponent: React.FC<RecommendedBadgeProps> = ({ badges }) => {
    const { value: theme } = useTheme();
    const badgeStyle = [styles.badgeStyle, { backgroundColor: theme.background_Button_Primary }];

    const showRecommendedBadge: boolean = React.useMemo(() => {
        return badges.includes(RECOMMENDED);
    }, [badges]);

    if (!showRecommendedBadge) {
        return null;
    }

    return (
        <Stack direction="row" style={styles.badgeContainer}>
            <Box style={badgeStyle}>
                <Text category="b1" weight="bold" color="color-basic-0">
                    {RECOMMENDED}
                </Text>
            </Box>
        </Stack>
    );
};

const styles = StyleSheet.create({
    badgeStyle: {
        paddingVertical: SpacingValue["space-x-small"],
        paddingHorizontal: SpacingValue["space-large"],
        borderBottomLeftRadius: 2,
        borderTopRightRadius: 2,
        borderBottomRightRadius: 16,
        borderTopLeftRadius: 16,
        position: "absolute",
        top: 0,
        left: 0,
        // @Todo add #16A47F color to Design System
        backgroundColor: "#16A47F",
    },
    badgeContainer: {
        zIndex: 1,
    },
});

export const RecommendedBadge = React.memo(RecommendedBadgeComponent);
