import React from "react";
import { StyleSheet } from "react-native";

import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { DashBorderLine } from "@swiggy-private/react-native-ui";
import { Dialog, SpacingValue, useTheme } from "@swiggy-private/rn-dls";

import { useCartView } from "@minis-consumer/hooks/use-cart";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";

import { BillDetails } from "./bill-details";
import { BillItems } from "./bill-items";

interface BillSummaryModalProps extends ModalBodyProps {
    showModal: boolean;
    setShowModal: (value: boolean) => void;
    headerHeight: number;
}

const BillSummaryModalComponent: React.FC<BillSummaryModalProps> = ({
    showModal,
    setShowModal,
    headerHeight,
    ...props
}) => {
    const { value: theme } = useTheme();
    const closeModal = React.useCallback(() => setShowModal(false), [setShowModal]);

    const dialogStyles = {
        marginTop: headerHeight,
        width: "100%",
    };

    const overlayStyle = {
        top: headerHeight,
        backgroundColor: theme["color-basic-100"],
        opacity: 0.3,
    };

    return (
        <Dialog
            open={showModal}
            verticalAlignDialog="top"
            style={dialogStyles}
            fullWidth
            overlayStyle={overlayStyle}
            onClose={closeModal}>
            <Dialog.Content flex={1}>
                <ModalBody {...props} closeModal={closeModal} />
            </Dialog.Content>
        </Dialog>
    );
};

interface ModalBodyProps {
    closeModal?: () => void;
}

export const ModalBody: React.FC<ModalBodyProps> = () => {
    const { value: theme } = useTheme();
    const store = useStoreInfo();
    const cartView = useCartView(store.storeId);

    return (
        <Stack spacing={SpacingValue["space-medium"]}>
            <BillItems cartItems={cartView?.cartViewData?.cartItems} />
            <DashBorderLine borderColor={theme["color-basic-5"]} style={styles.mt0} type="solid" />
            <BillDetails />
        </Stack>
    );
};

const styles = StyleSheet.create({
    mt0: {
        marginTop: 0,
    },
});

export const BillSummaryModal = React.memo(BillSummaryModalComponent);
