import React, { createContext, PropsWithChildren, useContext } from "react";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { RouteList } from "@minis-consumer/interfaces/route";

const NavigationContext = createContext<NativeStackNavigationProp<RouteList> | null>(null);

export const useNavigationContext = (): NativeStackNavigationProp<RouteList> | null =>
    useContext(NavigationContext);

type Props = { navigation: NativeStackNavigationProp<RouteList> | null };

export const NavigationProvider: React.FC<PropsWithChildren<Props>> = ({
    children,
    navigation,
}) => {
    return <NavigationContext.Provider value={navigation}>{children}</NavigationContext.Provider>;
};
