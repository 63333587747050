import * as React from "react";
import { Pressable, StyleSheet } from "react-native";

import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { UploadedMediaFile } from "@swiggy-private/rn-image-picker";

import {
    COPIES,
    DEFAULT_VALUES,
    TEST_IDS,
} from "@minis-consumer/components/rating-and-review-form/constants";

import { UploadedImages } from "../uploaded-images";

interface ImageUploadProps {
    handleUploadImage: VoidFunction;
    uploadedMedia: UploadedMediaFile[];
    setUploadedMedia: (urls: UploadedMediaFile[]) => void;

    maxImageCount?: number;
}

export const ImageUploadLargeScreen: React.FC<ImageUploadProps> = React.memo(
    ({
        uploadedMedia,
        setUploadedMedia,
        handleUploadImage,
        maxImageCount = DEFAULT_VALUES.MAX_IMAGE_COUNT,
    }) => {
        const { value: theme } = useTheme();

        const uploadedMediaLength = uploadedMedia?.length;
        const isUploadedMediaCountValid = uploadedMediaLength < maxImageCount;

        const imageUploadContainerStyle = {
            backgroundColor: theme["color-basic-5"],
            borderColor: theme["color-basic-15"],
        };

        const imageAddContainerStyle = {
            backgroundColor: theme["color-basic-30"],
            borderColor: theme["color-basic-15"],
            height: DEFAULT_VALUES.LARGE_SCREEN_UPLOADED_IMAGE_SIZE,
            width: DEFAULT_VALUES.LARGE_SCREEN_UPLOADED_IMAGE_SIZE,
        };

        return (
            <Stack flex={1} justifyContent="center" testID={TEST_IDS.IMAGE_UPLOAD}>
                {uploadedMediaLength ? (
                    <Stack direction="row">
                        <Pressable
                            style={[imageAddContainerStyle, styles.addImageIconContainer]}
                            testID={TEST_IDS.LARGE_SCREEN_ADD_ICON}
                            onPress={handleUploadImage}
                            disabled={!isUploadedMediaCountValid}>
                            <SvgIcon
                                icon="Plus"
                                width={DEFAULT_VALUES.PLUS_ICON_SIZE}
                                height={DEFAULT_VALUES.PLUS_ICON_SIZE}
                                color="color-basic-45"
                            />
                        </Pressable>
                        <UploadedImages
                            uploadedMedia={uploadedMedia}
                            setUploadedMedia={setUploadedMedia}
                            height={DEFAULT_VALUES.LARGE_SCREEN_UPLOADED_IMAGE_SIZE}
                            width={DEFAULT_VALUES.LARGE_SCREEN_UPLOADED_IMAGE_SIZE}
                        />
                    </Stack>
                ) : (
                    <Stack direction="row" style={[imageUploadContainerStyle, styles.container]}>
                        <Pressable
                            testID={TEST_IDS.LARGE_SCREEN_ADD_TEXT}
                            onPress={handleUploadImage}
                            style={styles.imageUpload}
                            disabled={!isUploadedMediaCountValid}>
                            <Stack
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                                spacing={SpacingValue["space-small"]}>
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="center"
                                    spacing={SpacingValue["space-x-small"]}>
                                    <SvgIcon
                                        icon="CameraPlus"
                                        width={DEFAULT_VALUES.CAMERA_ICON_SIZE}
                                        height={DEFAULT_VALUES.CAMERA_ICON_SIZE}
                                        color={
                                            !uploadedMedia?.length
                                                ? "color-primary"
                                                : "color-basic-60"
                                        }
                                    />
                                    <Text category="b2" weight="bold" color="high">
                                        {COPIES.ADD_PHOTO_PREFIX}
                                    </Text>
                                </Stack>
                                <Text category="b2" weight="regular" color="color-basic-45">
                                    {COPIES.ADD_PHOTO_SUFIX}
                                </Text>
                            </Stack>
                        </Pressable>
                    </Stack>
                )}
            </Stack>
        );
    },
);

if (__DEV__) {
    ImageUploadLargeScreen.displayName = "ImageUploadLargeScreen";
}

const styles = StyleSheet.create({
    container: {
        borderRadius: 12,
        borderStyle: "dashed",
        borderWidth: 2,
        paddingVertical: SpacingValue["space-x-large"],
        paddingHorizontal: SpacingValue["space-medium"],
    },
    iconContainer: {
        elevation: 1,
        borderRadius: 8,
        padding: SpacingValue["space-large"],
        marginRight: SpacingValue["space-medium"],
    },
    imageUpload: { flex: 1 },
    addImageIconContainer: {
        borderRadius: 8,
        borderStyle: "dashed",
        marginRight: SpacingValue["space-medium"],
        borderWidth: 2,
        alignItems: "center",
        justifyContent: "center",
    },
});
