import React from "react";
import {
    StyleProp,
    ViewStyle,
    StyleSheet,
    Pressable,
    TextInput,
    TextStyle,
    ColorValue,
} from "react-native";

import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { Input, SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { useMount } from "@swiggy-private/react-hooks";

import { IFeedbackOptions } from "@minis-consumer/interfaces/cart";
import { Card } from "@minis-consumer/components/card";
import { Logger } from "@minis-consumer/includes/logger";
import { useToast } from "@minis-consumer/hooks/use-toast";
import { Analytics } from "@minis-consumer/analytics";
import { useWidgetAnalytics } from "@minis-consumer/hooks/use-widget-analytics";
import { ANALYTICS_COPIES } from "@minis-consumer/routes/account/routes/abandoned-cart/constants";
import { SAVE_FEEDBACK_ERROR_MSG, feedbackSubmitApi } from "@minis-consumer/api/user";

import { FeedbackCta } from "../feedback-cta";
import { DEFAULTS } from "../../constants";
import { MultiSelectOption } from "../multi-select-option";

export interface GenericFeedbackProps {
    canEnableFeedbackCta?: boolean;
    title?: string;
    onPressCta?: VoidFunction;
    multiSelectOptions?: string[];
    inputTextOptionConfig?: IFeedbackOptions["inputTextOptionConfig"];
    closeIconSize?: number;
    onPressClose?: VoidFunction;
    rootStyle?: StyleProp<ViewStyle>;
    multiSelectOptionStyle?: StyleProp<ViewStyle>;
    closeIconStyle?: StyleProp<ViewStyle>;
    containerStyle?: StyleProp<ViewStyle>;
    isBulk?: boolean;
    storeId?: string;
    cartId?: string;
    commonCb?: VoidFunction;
    onScrollToIndex?: VoidFunction;
}

const GenericFeedbackContentComponent: React.FC<GenericFeedbackProps> = (props) => {
    const analyticsHandler = useWidgetAnalytics();

    const {
        title,
        closeIconSize = DEFAULTS.CLOSE_ICON_SIZE,
        closeIconStyle,
        onPressClose,
        rootStyle,
        multiSelectOptionStyle,
        multiSelectOptions,
        inputTextOptionConfig,
        onPressCta,
        isBulk = false,
        storeId,
        cartId,
        canEnableFeedbackCta = false,
        commonCb,
        onScrollToIndex,
    } = props;

    const { value: theme } = useTheme();
    const [showToast] = useToast();

    const [borderColor, setBorderColor] = React.useState<ColorValue>(theme["color-basic-15"]);

    const cartClearAnalyticsData = React.useMemo(() => {
        return {
            category: ANALYTICS_COPIES.CART_CLEARING_REASON,
            context: isBulk ? ANALYTICS_COPIES.ALL_CARTS : cartId,
        };
    }, [isBulk, cartId]);

    const [input, setInput] = React.useState("");
    const [optionsSelected, setOptionsSelected] = React.useState<string[]>([]);

    const inputRef = React.useRef<TextInput>(null);

    const isCtaActive = React.useMemo(
        () =>
            canEnableFeedbackCta &&
            (optionsSelected.length >= 1 ||
                input.trim().length > DEFAULTS.INPUT_FEEDBACK_CHARACTER_THRESHOLD),
        [canEnableFeedbackCta, input, optionsSelected.length],
    );

    const onOptionPress = React.useCallback(
        (optionValue: string) => {
            const index = optionsSelected.indexOf(optionValue);

            if (optionsSelected.length === 0 || index < 0) {
                setOptionsSelected([...optionsSelected, optionValue]);
            } else {
                setOptionsSelected(optionsSelected.filter((val) => val !== optionValue));
            }

            commonCb?.();
        },
        [commonCb, optionsSelected],
    );

    const onChangeText = React.useCallback(
        (text: string) => {
            setInput(text);
            commonCb?.();
        },
        [commonCb],
    );

    const onSubmitFeedback = React.useCallback(async () => {
        const feedbacks = [];

        if (optionsSelected.length >= 1) {
            feedbacks.push(...optionsSelected);
        }

        const inputText = input.trim();

        if (inputText.length > DEFAULTS.INPUT_FEEDBACK_CHARACTER_THRESHOLD) {
            feedbacks.push(inputText);
        }

        Analytics.clickEvent({
            ...cartClearAnalyticsData,
            label: JSON.stringify(feedbacks),
        });

        feedbackSubmitApi({
            body: {
                feedbacks,
                storeId,
                cartId,
                bulk: isBulk,
            },
        })
            .then(onPressCta)
            .catch((err) => {
                Logger.recordError(err);
                showToast(SAVE_FEEDBACK_ERROR_MSG);
            });
    }, [
        cartId,
        input,
        isBulk,
        onPressCta,
        optionsSelected,
        showToast,
        storeId,
        cartClearAnalyticsData,
    ]);

    const inputStyle = {
        borderColor,
        borderRadius: 12,
    };

    const hitSlop = {
        top: 16,
        right: 16,
        bottom: 16,
        left: 16,
    };

    const onFocus = React.useCallback(() => {
        setBorderColor(theme["color-positive-500"]);
        onScrollToIndex?.();
    }, [onScrollToIndex, theme]);

    const onBlur = React.useCallback(() => setBorderColor(theme["color-basic-15"]), [theme]);

    const inputTextStyle = {
        textAlignVertical: "center",
        fontSize: theme["text-body-2-regular-font-size"],
        lineHeight: undefined,
        fontFamily: theme["text-body-2-regular-font-family"],
        letterSpacing: theme["text-body-2-regular-letter-spacing"],
    } as StyleProp<TextStyle>;

    useMount(() => {
        analyticsHandler.onMountAnalytics(cartClearAnalyticsData);
    });

    return (
        <Card style={styles.card}>
            <Stack style={rootStyle}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Text category="b1" weight="bold" color="high" numberOfLines={1}>
                        {title}
                    </Text>
                    <Pressable
                        onPress={onPressClose}
                        hitSlop={hitSlop}
                        disabled={!canEnableFeedbackCta}>
                        <SvgIcon
                            icon="Close"
                            color="color-basic-60"
                            height={closeIconSize}
                            width={closeIconSize}
                            style={closeIconStyle}
                        />
                    </Pressable>
                </Stack>

                {multiSelectOptions ? (
                    <Stack
                        alignItems="flex-start"
                        spacing={SpacingValue["space-x-small"]}
                        direction="row"
                        style={styles.optionsContainer}>
                        {multiSelectOptions.map((val, idx) => (
                            <MultiSelectOption
                                key={`${val}_${idx}`}
                                optionValue={val}
                                canAnimateOnSelection
                                onPress={onOptionPress}
                                style={[styles.optionStyle, multiSelectOptionStyle]}
                            />
                        ))}
                    </Stack>
                ) : null}

                <Stack direction="row" alignItems="flex-end">
                    {inputTextOptionConfig ? (
                        <Box mr={SpacingValue["space-x-small"]} flex={1}>
                            <Input
                                placeholder={inputTextOptionConfig.placeholder}
                                style={inputStyle}
                                textStyle={inputTextStyle}
                                multiline
                                ref={inputRef}
                                keyboardType="default"
                                returnKeyType="done"
                                returnKeyLabel="done"
                                autoCorrect={false}
                                autoCapitalize="none"
                                spellCheck={false}
                                value={input}
                                onChangeText={onChangeText}
                                allowFontScaling={false}
                                placeholderTextColor={theme["color-basic-45"]}
                                maxLength={inputTextOptionConfig.maxCharacterLimit}
                                multilineStyle={styles.multiline}
                                onFocus={onFocus}
                                onBlur={onBlur}
                                blurOnSubmit
                            />
                        </Box>
                    ) : null}

                    <FeedbackCta
                        isCtaActive={isCtaActive}
                        onPress={onSubmitFeedback}
                        iconSize={DEFAULTS.CTA_ICON_SIZE}
                    />
                </Stack>
            </Stack>
        </Card>
    );
};

const styles = StyleSheet.create({
    card: {
        borderRadius: 20,
    },
    optionsContainer: {
        flexWrap: "wrap",
        marginTop: SpacingValue["space-small"],
        marginBottom: SpacingValue["space-medium"],
    },
    optionStyle: {
        marginTop: SpacingValue["space-x-small"],
    },
    multiline: {
        paddingTop: 0,
        paddingBottom: 0,
        minHeight: 18,
        maxHeight: 118,
    },
});

export const GenericFeedbackContent = React.memo(GenericFeedbackContentComponent);
