import { useCallback } from "react";
import { useController } from "@rest-hooks/react";

import { Order } from "@minis-consumer/interfaces/order";
import { RateOrder } from "@minis-consumer/resources/order";

type OrderRating = {
    orderId: string;
    value: number;
    description: string;
    imageIds: string[];
};

export const useOrderRating = (): ((data: OrderRating) => Promise<Order>) => {
    const { fetch } = useController();

    return useCallback(
        async ({ orderId, value, description, imageIds }) => {
            return await fetch(RateOrder, {
                orderId,
                value,
                description,
                imageIds,
            });
        },
        [fetch],
    );
};
