import * as React from "react";

import { Portal } from "@swiggy-private/rn-dls";

import { useChatBlockedErrorSnackbarHandler } from "@minis-consumer/hooks/use-chat-blocked-snack-handler";
import { ChatBlockedSnackbar } from "@minis-consumer/components/chat/components/chat-blocked-snackbar";

export const ChatBlockedErrorSnackbarProvider: React.FC = () => {
    const { isOpen, blockedInfo, extraStyle, dataHandler } = useChatBlockedErrorSnackbarHandler();

    const onPressClose = React.useCallback(() => {
        dataHandler(false);
    }, [dataHandler]);

    return (
        <Portal>
            <ChatBlockedSnackbar
                canShowSnackbar={isOpen}
                onPressClose={onPressClose}
                blockedInfo={blockedInfo}
                extraStyle={extraStyle}
            />
        </Portal>
    );
};
