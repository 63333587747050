import React from "react";
import {
    Pressable,
    StyleProp,
    TextProps,
    TextStyle,
    ViewStyle,
    StyleSheet,
    Platform,
} from "react-native";

import { SvgIcon, SvgIconType } from "@swiggy-private/connect-svg-icons";
import {
    Box,
    ScreenSize,
    Stack,
    useScreenSize,
    useSelectScreen,
} from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, useTheme, Text } from "@swiggy-private/rn-dls";

import { WidgetShaker } from "@minis-consumer/components/widget-shaker";

type PaymentDowntimeNudgeProps = {
    message?: string;
    svgIcon?: SvgIconType["icon"];
    showNudge?: boolean;

    style?: StyleProp<ViewStyle>;
    iconStyle?: StyleProp<ViewStyle>;
    overlayStyle?: StyleProp<ViewStyle>;
    textStyle?: StyleProp<TextStyle>;

    textProps?: TextProps;

    children?: React.ReactNode;
};

const MESSAGE_FALLBACK_PREFIX =
    "Due to low success rates, all payment options are temporarily disabled.";

const MESSAGE_FALLBACK_SUFFIX = "Please retry after some time.";

const PaymentDowntimeNudgeComponent: React.FC<PaymentDowntimeNudgeProps> = (props) => {
    const { value: theme } = useTheme();
    const screenSize = useScreenSize();

    const [shake, setShake] = React.useState(false);

    const isLargeScreen = React.useMemo(() => screenSize === ScreenSize.Large, [screenSize]);

    const messageFallback = React.useMemo(
        () =>
            isLargeScreen && Platform.OS === "web"
                ? `${MESSAGE_FALLBACK_PREFIX}\n${MESSAGE_FALLBACK_SUFFIX}`
                : `${MESSAGE_FALLBACK_PREFIX} ${MESSAGE_FALLBACK_SUFFIX}`,
        [isLargeScreen],
    );

    const rootContainerStyle = useSelectScreen({
        lg: {
            paddingVertical: SpacingValue["space-small"],
            paddingHorizontal: SpacingValue["space-medium"],
        },
        default: {
            paddingHorizontal: SpacingValue["space-medium"],
            paddingVertical: SpacingValue["space-x-small"],
        },
    });

    const iconSize = useSelectScreen({
        lg: 24,
        default: 20,
    });

    return (
        <>
            {props.showNudge ? (
                <WidgetShaker shake={shake} setShake={setShake}>
                    <Stack
                        flex={1}
                        direction="row"
                        alignItems="center"
                        style={[
                            { backgroundColor: theme["color-basic-75"] },
                            rootContainerStyle,
                            props.style,
                        ]}
                        spacing={
                            isLargeScreen
                                ? SpacingValue["space-x-small"]
                                : SpacingValue["space-x-small"]
                        }>
                        <SvgIcon
                            icon={props.svgIcon ?? "Info"}
                            height={iconSize}
                            width={iconSize}
                            color="color-critical-300"
                            style={props.iconStyle}
                        />
                        <Box flex={1}>
                            <Text
                                category={isLargeScreen ? "b2" : "b3"}
                                weight={isLargeScreen ? "medium" : "regular"}
                                color="color-basic-0"
                                {...props.textProps}
                                style={props.textStyle}>
                                {props.message ?? messageFallback}
                            </Text>
                        </Box>
                    </Stack>
                </WidgetShaker>
            ) : null}

            {props.showNudge && props.children ? (
                <Pressable
                    onPress={() => setShake(true)}
                    style={[styles.overlay, props.overlayStyle]}>
                    <Box pointerEvents="none">{props.children}</Box>
                </Pressable>
            ) : props.children ? (
                <>{props.children}</>
            ) : null}
        </>
    );
};

export const PaymentDowntimeNudge = React.memo(PaymentDowntimeNudgeComponent);

const styles = StyleSheet.create({
    overlay: {
        opacity: Platform.OS === "android" ? 0.95 : 0.7,
    },
});
