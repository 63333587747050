import React from "react";
import { ViewStyle, StyleSheet } from "react-native";

import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Surface, Text, useTheme } from "@swiggy-private/rn-dls";

import { Analytics } from "@minis-consumer/analytics";

export const COPIES = {
    PRIMARY_TEXT: "Closing your browser will erase this chat.",
    CTA_TEXT: "Log in",
    SECONDARY_TEXT_PREFIX: "to get notified whenever",
    SECONDARY_TEXT_SUFFIX: "responds",
    ANALYTICS_CATEGORY: "guest-login-cta",
};

interface IChatConversationNudgeContent {
    isGuestUser: boolean;
    storeName: string;
    storeId: string;
    onPressCta: VoidFunction;
}

const ChatConversationNudgeContentComponent: React.FC<IChatConversationNudgeContent> = ({
    isGuestUser,
    storeId,
    storeName,
    onPressCta,
}) => {
    const { value: theme } = useTheme();

    const analyticsPayload = React.useMemo(() => {
        return {
            category: COPIES.ANALYTICS_CATEGORY,
            context: JSON.stringify({
                store_id: storeId, // analytics team wants the key to be like this
            }),
        };
    }, [storeId]);

    React.useEffect(() => {
        if (!isGuestUser) {
            return;
        }

        Analytics.impressionEvent(analyticsPayload);
    }, [analyticsPayload, isGuestUser]);

    const onPress = React.useCallback(() => {
        Analytics.clickEvent(analyticsPayload);
        onPressCta();
    }, [analyticsPayload, onPressCta]);

    if (!isGuestUser) {
        return null;
    }

    const containerStyle = {
        backgroundColor: theme["color-warning-25"],
        borderColor: theme["color-warning-50"],
    } as ViewStyle;

    return (
        <Surface style={styles.surface}>
            <Stack
                style={[containerStyle, styles.root]}
                alignItems="center"
                justifyContent="center">
                <Text category="b3" color="color-basic-60">
                    {COPIES.PRIMARY_TEXT}
                </Text>

                <Text style={styles.text}>
                    <Text
                        category="btn5"
                        weight="bold"
                        color="color-positive-500"
                        suppressHighlighting
                        onPress={onPress}>
                        {COPIES.CTA_TEXT}
                    </Text>
                    <Text
                        category="b3"
                        color="color-basic-60">{` ${COPIES.SECONDARY_TEXT_PREFIX} ${storeName} ${COPIES.SECONDARY_TEXT_SUFFIX}`}</Text>
                </Text>
            </Stack>
        </Surface>
    );
};

const styles = StyleSheet.create({
    surface: {
        elevation: 2,
        zIndex: 1,
    },
    root: {
        borderBottomWidth: 1,
        paddingVertical: SpacingValue["space-medium"],
        paddingHorizontal: SpacingValue["space-large"],
    },
    text: {
        textAlign: "center",
    },
});

export const ChatConversationNudgeContent = React.memo(ChatConversationNudgeContentComponent);
