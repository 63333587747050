import React from "react";
import { Box } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text } from "@swiggy-private/rn-dls";

import { SvgIcon } from "@swiggy-private/connect-svg-icons";

export const DELIVERY_ACROSS_INDIA = "Delivering pan India";
const ICON_SIZE = 12;

interface IProps {
    interDeliveryConfigEnabled: boolean;
    city?: string;
}

const StoreDeliveryInfoComponent: React.FC<IProps> = ({ interDeliveryConfigEnabled, city }) => {
    if (!interDeliveryConfigEnabled && !city) {
        return null;
    }

    return (
        <Box mt={SpacingValue["space-x-small"]} direction="row" alignItems="center">
            <Box>
                <SvgIcon
                    icon="PackageFilled"
                    color="color-basic-30"
                    width={ICON_SIZE}
                    height={ICON_SIZE}
                />
            </Box>
            <Box ml={SpacingValue["space-xx-small"]}>
                <Text color="color-basic-60" category="b3" numberOfLines={1}>
                    {interDeliveryConfigEnabled
                        ? `${DELIVERY_ACROSS_INDIA}`
                        : `Delivery only in ${city}`}
                </Text>
            </Box>
        </Box>
    );
};

export const StoreDeliveryInfo = React.memo(StoreDeliveryInfoComponent);
