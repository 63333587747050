import { useTheme, ColorPalette } from "@swiggy-private/rn-dls";
import * as React from "react";
import { StyleSheet, View, ViewProps } from "react-native";

export interface DividerProps extends ViewProps {
    color?: keyof ColorPalette;
    horizontal?: boolean;
}

export const Divider: React.FC<DividerProps> = ({
    style: propStyle,
    color,
    horizontal = true,
    ...props
}) => {
    const { value: theme } = useTheme();

    const style = {
        height: horizontal ? StyleSheet.hairlineWidth : "100%",
        width: horizontal ? "100%" : StyleSheet.hairlineWidth,
        backgroundColor: theme[color ?? "color-basic-15"],
    };

    return <View style={[style, propStyle]} {...props} />;
};
