/**
 * There is some  duplicate code in cart hooks which will be refactored
 * after Abandoned Cart M2 initiative
 */

import React from "react";
import { useController } from "@rest-hooks/react";

import { useMountedRef, useMount } from "@swiggy-private/react-hooks";

import { CartDispatchContext } from "@minis-consumer/contexts/cart-context";
import { Logger } from "@minis-consumer/includes/logger";
import { SoftClearCart, UpdateCart } from "@minis-consumer/resources/cart";
import {
    buildLocalCartFromCartView,
    compareLocalCart,
    getUpdateCartParams,
    useCartClearWithoutHardDelete,
    useFetchCartFromServer,
    useLocalCart,
} from "@minis-consumer/hooks/use-cart";
import { LocalCart } from "@minis-consumer/interfaces/cart";
import { UpdateCartParams } from "@minis-consumer/api/cart";
import { TrackAsyncTasksContext } from "@minis-consumer/contexts/async-tasks-context";
import { useSignedIn } from "@minis-consumer/hooks/use-user";

interface StoreCartGetterProps {
    storeId: string;
    children: React.ReactElement;
}

const INTERVAL_IN_MS = 5_000;
export const CART_UPDATION = "cart-updation";

const StoreCartContainerComponent: React.FC<StoreCartGetterProps> = ({ storeId, children }) => {
    const { fetch } = useController();
    const mountedRef = useMountedRef();
    const localCart = useLocalCart(storeId);
    const onClearCart = useCartClearWithoutHardDelete(storeId);
    const fetchCart = useFetchCartFromServer(storeId);
    const isGuestUser = useSignedIn() === false;

    const localCartRef = React.useRef(localCart);
    const t = React.useRef<NodeJS.Timer>();
    const cartParamsRef = React.useRef<UpdateCartParams>();
    const diffLocalCartRef = React.useRef<boolean>();
    const isCartEmptyRef = React.useRef<boolean>();

    const dispatch = React.useContext(CartDispatchContext);
    const { trackAsyncTasksPromises } = React.useContext(TrackAsyncTasksContext);

    const [canStartPolling, setCanPoll] = React.useState(false);

    const onStartPolling = React.useCallback(() => setCanPoll(true), []);

    const syncLocalCartWithServer = React.useCallback(
        async (newCart: LocalCart, prevCart: LocalCart, cb?: VoidFunction) => {
            try {
                if (newCart.isSyncedWithServer || compareLocalCart(newCart, prevCart)) {
                    return;
                }

                const isCartEmpty = newCart.items.length === 0;

                if (isCartEmpty) {
                    await onClearCart();
                }

                const cartUpdateParams = getUpdateCartParams(storeId, newCart);
                const cartEntity = await fetch(UpdateCart, cartUpdateParams);

                if (!mountedRef.current) {
                    return;
                }

                const updatedLocalCart = buildLocalCartFromCartView(
                    cartEntity,
                    cartUpdateParams.addressId,
                    cartUpdateParams.cart.guestUserDetails,
                );

                localCartRef.current = { ...updatedLocalCart, isSyncedWithServer: true };

                dispatch({
                    type: "UPDATE_CART_ACTION",
                    payload: {
                        storeId: cartEntity.storeId,
                        cart: updatedLocalCart,
                        isSyncedWithServer: true,
                    },
                });

                cb?.();
            } catch (err) {
                if (!mountedRef.current) {
                    return;
                }

                Logger.recordError(err);

                // reset the cart
                if (prevCart) {
                    localCartRef.current = prevCart;
                    dispatch({
                        type: "UPDATE_CART_ACTION",
                        payload: {
                            storeId,
                            cart: prevCart,
                        },
                    });
                }
            }
        },
        [dispatch, fetch, mountedRef, onClearCart, storeId],
    );

    const fetchCartFromServer = React.useCallback(
        async (shouldDoUpdationCheck: boolean) => {
            try {
                const isSyncReq = await fetchCart(shouldDoUpdationCheck);
                if (!mountedRef.current) {
                    return;
                }

                if (isSyncReq) {
                    syncLocalCartWithServer(
                        { ...localCart, isSyncedWithServer: false },
                        { ...localCart, addressId: "null" },
                        onStartPolling,
                    );

                    return;
                }

                setCanPoll(true);
            } catch (err) {
                if (mountedRef.current) {
                    Logger.recordError(err);
                }
            }
        },
        [fetchCart, localCart, mountedRef, onStartPolling, syncLocalCartWithServer],
    );

    React.useEffect(() => {
        cartParamsRef.current = getUpdateCartParams(storeId, localCart); // tracks cart update params
        diffLocalCartRef.current = !compareLocalCart(localCart, localCartRef.current); //tracks if the local cart and it's ref have differed
        isCartEmptyRef.current = localCart.items.length === 0; //tracks if the local cart is empty

        if (localCartRef.current !== localCart && canStartPolling) {
            t.current = setInterval(
                () => syncLocalCartWithServer(localCart, localCartRef.current),
                INTERVAL_IN_MS,
            );
        }

        return () => clearInterval(t.current as unknown as number);
    }, [canStartPolling, localCart, storeId, syncLocalCartWithServer]);

    useMount(() => {
        fetchCartFromServer(Boolean(localCart.items?.length));

        return () => {
            // on unmount if the local cart is empty then make clear cart api or if any other updation is there then sync cart to server

            if (isCartEmptyRef.current && !isGuestUser) {
                const clearCartPromise = fetch(SoftClearCart, { storeId }).catch((err) => {
                    Logger.recordError(err);
                });

                trackAsyncTasksPromises([clearCartPromise], CART_UPDATION);
                return;
            }

            if (!cartParamsRef.current || !diffLocalCartRef.current) {
                return;
            }

            const updateCartpromise = fetch(UpdateCart, cartParamsRef.current).catch((err) => {
                Logger.recordError(err);
            });
            trackAsyncTasksPromises([updateCartpromise], CART_UPDATION);
        };
    });

    return children;
};

export const StoreCartContainer = React.memo(StoreCartContainerComponent);
