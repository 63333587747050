import * as React from "react";

import { useAppReducer } from "../reducers/app-reducer";

type AppReducerType = ReturnType<typeof useAppReducer>;
type State = AppReducerType[0];
type Dispatch = AppReducerType[1];

export const AppStateContext = React.createContext<State | null>(null);

// eslint-disable-next-line no-void
export const AppDispatchContext = React.createContext<Dispatch>(() => void 0);

interface AppStateProviderProps {
    initialState: State;
}

export const AppStateProvider: React.FC<React.PropsWithChildren<AppStateProviderProps>> = ({
    initialState,
    children,
}) => {
    const [state, dispatch] = useAppReducer(initialState);

    return (
        <AppStateContext.Provider value={state}>
            <AppDispatchContext.Provider value={dispatch}>{children}</AppDispatchContext.Provider>
        </AppStateContext.Provider>
    );
};
