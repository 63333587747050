import React, { useMemo } from "react";
import { FlatList, StyleSheet } from "react-native";
import LinearGradient from "react-native-linear-gradient";

import { StoreMedia } from "@swiggy-private/react-native-ui";
import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";

import { ProductVariantValuesLabel } from "@minis-consumer/components/variants";
import { PRODUCT_IMG_FALLBACK } from "@minis-consumer/constants/images";
import { getMediaUrl } from "@minis-consumer/helpers/media";
import { CartItem } from "@minis-consumer/interfaces/cart";

import { DEFAULTS } from "./constants";

interface Props {
    cartItems?: CartItem[];
}

export const BillItemsComponent: React.FC<Props> = ({ cartItems }) => {
    return (
        <FlatList
            data={cartItems}
            renderItem={({ item }) => <BillItemComponent data={item} />}
            testID="cart-items"
        />
    );
};

export const BillItemComponent: React.FC<{ data: CartItem }> = ({ data }) => {
    const { value: theme } = useTheme();

    const noOfItemsStyle = {
        borderColor: theme["color-basic-0"],
        backgroundColor: theme["color-basic-5"],
    };

    const imageStyles = {
        borderColor: theme["color-basic-5"],
    };

    const transformedQtyItems = React.useMemo(
        () => (data.quantity > 99 ? "99+" : data.quantity),
        [data.quantity],
    );

    const cartItemImageId = useMemo(() => {
        const imageId = data?.item.imageIds?.[0];
        return imageId
            ? getMediaUrl(
                  imageId,
                  {
                      height: DEFAULTS.IMAGE_CONTAINER_SIZE,
                      width: DEFAULTS.IMAGE_CONTAINER_SIZE,
                  },
                  true,
              )
            : PRODUCT_IMG_FALLBACK;
    }, [data]);

    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={styles.container}>
            <Stack style={styles.imageContainer}>
                <StoreMedia
                    isImageKitEnabled
                    disableVideoPlay
                    width={DEFAULTS.IMAGE_CONTAINER_SIZE}
                    height={DEFAULTS.IMAGE_CONTAINER_SIZE}
                    mediaId={cartItemImageId}
                    style={[styles.image, imageStyles]}
                    resizeMode="contain"
                />

                <LinearGradient
                    colors={DEFAULTS.QUANTITY_GRADIENT_COLORS}
                    start={DEFAULTS.QUANTITY_GRADIENT_COLORS_START_POINT}
                    locations={DEFAULTS.QUANTITY_GRADIENT_COLORS_LOCATIONS}
                    style={[noOfItemsStyle, styles.quantityWrapper]}>
                    <Text weight="medium" color="color-basic-60" style={styles.quantityText}>
                        {transformedQtyItems}
                    </Text>
                </LinearGradient>
            </Stack>

            <Stack spacing={SpacingValue["space-xx-small"]}>
                <Text
                    category="b2"
                    weight="medium"
                    color="medium"
                    style={styles.itemDescription}
                    numberOfLines={2}>
                    {data.item.name}
                </Text>

                <ProductVariantValuesLabel values={data.item.variantOptionValues} />
            </Stack>

            <Text category="b2" weight="medium" color="high">
                ₹{data.item.price}
            </Text>
        </Stack>
    );
};

const styles = StyleSheet.create({
    divider: {
        borderTopWidth: 1,
    },
    itemSeparator: {
        marginTop: SpacingValue["space-medium"],
        marginBottom: SpacingValue["space-medium"],
    },
    container: {
        marginTop: SpacingValue["space-xx-small"],
    },
    imageContainer: {
        position: "relative",
        width: DEFAULTS.IMAGE_CONTAINER_SIZE,
        height: DEFAULTS.IMAGE_CONTAINER_SIZE,
    },
    image: {
        position: "absolute",
        borderWidth: 1,
        borderRadius: SpacingValue["space-x-small"],
        top: 0,
        left: 0,
    },
    noOfItems: {
        position: "absolute",
        zIndex: 1,
        left: 60,
        top: 0,
        height: DEFAULTS.ITEMS_SIZE,
        width: DEFAULTS.ITEMS_SIZE,
        borderRadius: DEFAULTS.ITEMS_SIZE / 2,
        borderWidth: 1,
        textAlign: "center",
    },
    itemDescription: {
        width: "80%",
        paddingHorizontal: SpacingValue["space-medium"],
        whiteSpace: "normal",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    mt0: {
        marginTop: 0,
    },
    quantityWrapper: {
        position: "absolute",
        right: -10,
        top: -10,
        minHeight: DEFAULTS.QUANTITY_WRAPPER_SIZE,
        minWidth: DEFAULTS.QUANTITY_WRAPPER_SIZE,
        borderRadius: DEFAULTS.QUANTITY_WRAPPER_SIZE / 2,
        borderWidth: 2,
        justifyContent: "center",
        alignItems: "center",
    },
    quantityText: {
        lineHeight: 14,
        fontSize: 11,
    },
});

export const BillItems = React.memo(BillItemsComponent);
