import * as React from "react";
import { Pressable } from "react-native";

import { Box } from "@swiggy-private/rn-adaptive-layout";
import { InView } from "@swiggy-private/react-native-ui";

import { Analytics } from "@minis-consumer/analytics";
import {
    META_PIXEL_CONTENT_CATEGORY,
    META_PIXEL_EVENT_NAMES,
} from "@minis-consumer/constants/meta-pixel-analytics";
import { fireMetaPixelEvents } from "@minis-consumer/helpers/meta-pixel-events";
import type { ProductBadge, CatalogLayout } from "@minis-consumer/interfaces/catalog";
import { SF_ANALYTICS_EVENT_NAMES } from "@minis-consumer/routes/shop/constants";

import type { SectionItem } from "./types";
import { ProductSmallCard } from "../product-variants/small-card";
import { ProductLargeCard } from "../product-variants/large-card";

interface ProductSectionItemProps {
    item: SectionItem;
    badges: ProductBadge[];
    onProductPress: VoidFunction;
    index: number;
    width?: number;
    layout?: CatalogLayout;
    showAddToCart?: boolean;
}

const SF_WIDGET = "catalog";

const ProductSectionListItemComponent: React.FC<ProductSectionItemProps> = React.memo(
    ({ item: product, onProductPress, index, width, badges, layout, showAddToCart }) => {
        const productCardAnalyticsContext = JSON.stringify({
            itemId: product.id,
            sfWidget: SF_WIDGET,
            hasRecommendedTag: product.badges.includes("Recommended"),
            isAvailable: product.inStock,
            index,
            productType: product.productType,
        });

        const onItemAdd = (): void => {
            Analytics.clickEvent({
                category: SF_ANALYTICS_EVENT_NAMES.PRODUCT_CARD_ADD,
                context: productCardAnalyticsContext,
            });

            fireMetaPixelEvents(META_PIXEL_EVENT_NAMES.ADD_TO_CART, {
                content_name: product?.name,
                value: String(product?.discountedPrice ?? product?.price),
                content_category: META_PIXEL_CONTENT_CATEGORY.SF_HOME,
            });
        };

        const onImpression = (isVisible: boolean): void => {
            if (!isVisible) {
                return;
            }

            Analytics.impressionEvent({
                category: SF_ANALYTICS_EVENT_NAMES.PRODUCT_CARD,
                context: productCardAnalyticsContext,
            });
        };
        return (
            <InView onChange={onImpression}>
                <Pressable
                    onPress={onProductPress}
                    accessible
                    accessibilityLabel="Open product"
                    style={{ flex: 1 }}>
                    <Box direction="row" flex={1}>
                        {layout === "GRID" ? (
                            <ProductSmallCard
                                product={product}
                                index={index}
                                imageSize={width}
                                onProductPress={onProductPress}
                                onItemAddCallback={onItemAdd}
                                showAddToCart={showAddToCart}
                                sfWidget={SF_WIDGET}
                            />
                        ) : (
                            <ProductLargeCard
                                item={product}
                                index={index}
                                onProductPress={onProductPress}
                                onItemAddCallback={onItemAdd}
                                badges={badges}
                                showAddToCart
                                sfWidget={SF_WIDGET}
                            />
                        )}
                    </Box>
                </Pressable>
            </InView>
        );
    },
);

if (__DEV__) {
    ProductSectionListItemComponent.displayName = "ProductSectionListItemComponent";
}

export const ProductSectionListItem = React.memo(ProductSectionListItemComponent);
