import { SdkConversation } from "@swiggy-private/connect-chat-sdk";

import { checkIsBlockedByCustomer } from "../../helpers/chat-blocked";
import { DISPLAY_STRINGS } from "./constants";

export const getSnackbarText = (blockedInfo: SdkConversation["blockedInfo"]): string => {
    if (blockedInfo?.blockedBy?.length) {
        const isBlockedByCustomer = checkIsBlockedByCustomer(blockedInfo);

        return isBlockedByCustomer
            ? DISPLAY_STRINGS.BLOCKED_BY_CUSTOMER
            : DISPLAY_STRINGS.BLOCKED_BY_SELLER;
    }

    return "";
};
