import { useCallback } from "react";
import { NavigationProp, useNavigation } from "@react-navigation/native";

import { RouteList } from "@minis-consumer/interfaces/route";
import { useIsDesktopWeb } from "@minis-consumer/hooks/use-dweb";

import { useReviewAndRatingHandler } from "./use-rating-and-review-modal-handler";

export interface UseRatingAndReviewHookProps {
    orderId: string;
    storeId: string;
    orderKey?: string;
}

export const useRatingAndReviewHook = ({
    orderId,
    storeId,
    orderKey,
}: UseRatingAndReviewHookProps): {
    ratingAndReviewNavigtaionHandler: (ratingCount: number) => void;
} => {
    const navigation = useNavigation<NavigationProp<RouteList>>();
    const { handler: ratingAndReviewModalHandler } = useReviewAndRatingHandler();

    const isDWeb = useIsDesktopWeb();

    const ratingAndReviewNavigtaionHandler = useCallback(
        (ratingCount: number) => {
            if (!isDWeb) {
                navigation.navigate("RatingAndReview", {
                    orderId,
                    defaultRating: ratingCount,
                    storeId,
                    orderKey,
                });
            } else {
                ratingAndReviewModalHandler({
                    canOpen: true,
                    orderId,
                    ratingCount,
                    storeId,
                    orderKey,
                });
            }
        },
        [isDWeb, navigation, orderId, ratingAndReviewModalHandler, storeId, orderKey],
    );

    return {
        ratingAndReviewNavigtaionHandler,
    };
};
