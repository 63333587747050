import React from "react";
import { Pressable, StyleSheet } from "react-native";

import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import {
    SpacingValue,
    Text,
    TextProps,
    Tooltip,
    TooltipPlacement,
    TooltipTriggerMode,
    useTheme,
} from "@swiggy-private/rn-dls";

import { formatNumberWithIndianSystem } from "@minis-consumer/helpers/number";

const FREE = "FREE";

interface Props {
    title: string;
    finalAmount: number;

    titleColor?: TextProps["color"];
    amount?: number;
    helper?: string;
    tooltip?: React.ReactNode;
    tooltipPlacement?: TooltipPlacement;
    // exception for when delivery fee is missing when address is not selected
    isDeliveryFeeMissing?: boolean;
}

const BillItemComponent: React.FC<Props> = ({
    title,
    finalAmount,
    amount,
    helper,
    tooltip,
    tooltipPlacement,
    isDeliveryFeeMissing,
    titleColor,
}) => {
    const { value: theme } = useTheme();

    const [showTooltip, setShowTooltip] = React.useState(false);

    const openTooltip = React.useCallback(() => setShowTooltip(true), []);
    const closeTooltip = React.useCallback(() => setShowTooltip(false), []);

    const getFormattedNumber = React.useCallback(
        (num: number) => formatNumberWithIndianSystem(num, { showRupeeSymbol: true }),
        [],
    );

    const formattedOldAmount = React.useMemo(
        () => getFormattedNumber(amount || 0),
        [getFormattedNumber, amount],
    );

    const formattedFinalAmount = React.useMemo(
        () => getFormattedNumber(finalAmount),
        [finalAmount, getFormattedNumber],
    );

    const showTitleTooltipandHelper = title || helper || tooltip;

    return (
        <Stack direction="row" spacing={SpacingValue["space-x-large"]}>
            {showTitleTooltipandHelper ? (
                <Stack flex={1} spacing={SpacingValue["space-xx-small"]}>
                    <Stack
                        direction="row"
                        spacing={SpacingValue["space-x-small"]}
                        alignItems="center">
                        {title ? (
                            <Text category="b2" color={titleColor ?? "medium"} weight="medium">
                                {title}
                            </Text>
                        ) : null}

                        {tooltip ? (
                            <Tooltip
                                message={tooltip}
                                showDuration={3_000}
                                showOverlay
                                backgroundColor={"transparent"}
                                style={styles.resetTooltipContent}
                                onOpen={openTooltip}
                                onClose={closeTooltip}
                                visible={showTooltip}
                                triggerMode={TooltipTriggerMode.TAP}
                                placement={tooltipPlacement}>
                                <Pressable hitSlop={{ top: 16, bottom: 16, left: 16, right: 16 }}>
                                    <SvgIcon
                                        icon="Info"
                                        height={16}
                                        width={16}
                                        color="color-basic-60"
                                    />
                                </Pressable>
                            </Tooltip>
                        ) : null}
                    </Stack>

                    {helper ? (
                        <Text category="b2" color="color-primary-400">
                            {helper}
                        </Text>
                    ) : null}
                </Stack>
            ) : null}

            <Box>
                <Stack direction="row" alignItems="center" spacing={SpacingValue["space-xx-small"]}>
                    {amount && amount !== finalAmount ? (
                        <Text category="b2" color="color-basic-45" style={styles.strikeThrough}>
                            {formattedOldAmount}
                        </Text>
                    ) : null}

                    {finalAmount ? (
                        <Text category="b2" color="high" weight="medium">
                            {formattedFinalAmount}
                        </Text>
                    ) : isDeliveryFeeMissing ? (
                        <Text category="b2" color="high" weight="medium">
                            --
                        </Text>
                    ) : (
                        <Box
                            ph={SpacingValue["space-x-small"]}
                            pv={SpacingValue["space-xxx-small"]}
                            style={[
                                styles.freeLabel,
                                {
                                    backgroundColor: theme["color-primary-50"],
                                },
                            ]}>
                            <Text category="b3" color="color-primary-800">
                                {FREE}
                            </Text>
                        </Box>
                    )}
                </Stack>
            </Box>
        </Stack>
    );
};

export const BillItem = React.memo(BillItemComponent);

const styles = StyleSheet.create({
    resetTooltipContent: {
        paddingHorizontal: 0,
        paddingVertical: 0,
        borderRadius: 0,
        backgroundColor: "transparent",
    },
    strikeThrough: {
        textDecorationLine: "line-through",
    },
    freeLabel: {
        borderRadius: 8,
    },
});
