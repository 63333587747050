import { getValue, removeValue, setValue } from "@swiggy-private/common-helpers";

export class PaymentProgress {
    private static _storageKey = "@minis_isPaymentInProgress";

    static async load(): Promise<boolean> {
        const isPaymentInPrgress = await getValue(PaymentProgress._storageKey);
        return !!isPaymentInPrgress && isPaymentInPrgress === "true";
    }

    static async save(): Promise<void> {
        await setValue(PaymentProgress._storageKey, "true");
    }

    static async clear(): Promise<void> {
        await removeValue(PaymentProgress._storageKey);
    }
}
