import React from "react";
import { SpacingValue, Text, TouchableComponent, useTheme } from "@swiggy-private/rn-dls";
import { StyleSheet } from "react-native";

import { Box } from "@swiggy-private/rn-adaptive-layout/src";

interface Props {
    name: string;
    onPress: () => void;
}

export const AccountDropDownTileItem: React.FC<Props> = ({ name, onPress }) => {
    const { value: theme } = useTheme();
    const bgColor = theme["color-background-primary"];

    const [hovered, setHovered] = React.useState(false);

    const bgStyle = hovered
        ? name === "Logout"
            ? theme["color-critical-25"]
            : theme["color-basic-alpha-5"]
        : bgColor;

    return (
        <TouchableComponent
            style={styles.option}
            accessibilityRole="menuitem"
            accessible
            accessibilityLabel={name}
            onHoverIn={() => {
                setHovered(true);
            }}
            onHoverOut={() => {
                setHovered(false);
            }}
            onPress={onPress}>
            <Box
                justifyContent="center"
                style={{
                    backgroundColor: bgStyle,
                    paddingLeft: SpacingValue["space-x-large"],
                }}
                flex={1}>
                <Text
                    category="b1"
                    weight="medium"
                    color={name === "Logout" ? "color-critical-300" : "color-basic-75"}>
                    {name}
                </Text>
            </Box>
        </TouchableComponent>
    );
};

const styles = StyleSheet.create({
    option: {
        minWidth: 160,
        minHeight: 36,
    },
});
