export const APPOINTMENT_ERROR_SNACKBAR_CONSTANTS = {
    ERROR_FALLBACK_MSSG: "Apologies. This coupon is no longer valid.",
    CTA_TEXT_FALLBACK: "Okay",
    MAX_SNACKBAR_WIDTH: 740,
};

export const ANALYTICS_COPIES = {
    CATEGORY: "book-slot-error-tooltip",
    SCREEN_NAME: "checkout-home",
};
