import { Entity } from "@rest-hooks/rest";
import { Endpoint } from "@rest-hooks/endpoint";

import { unique } from "@minis-consumer/helpers/array";

import {
    clearCartApi,
    fetchAllCartsApi,
    fetchCart,
    FetchCartParams,
    fetchClearCartFeedbackOptionsApi,
    updateCart,
    UpdateCartParams,
} from "../api/cart";
import type {
    Cart as ICart,
    CartStatuses,
    ICartsListItem,
    ICartsListStore,
    ICartsListProduct,
    ICartsList,
} from "../interfaces/cart";

export class CartEntity extends Entity implements ICart {
    readonly storeId: string = "0";
    readonly cartStatus: CartStatuses = "EMPTY_CART";
    readonly cartViewData: Readonly<ICart["cartViewData"]> = null;
    readonly payments: Readonly<ICart["payments"]> = { active: true };

    pk(): string {
        return this.storeId;
    }

    static override get key(): string {
        return "Cart";
    }
}

export const FetchCart = new Endpoint(
    async (params: FetchCartParams) => {
        if (!params.storeId) {
            return null;
        }

        const data = await fetchCart(params);

        if (!data?.cartViewData) {
            return {
                storeId: params.storeId,
                cartStatus: "EMPTY_CART",
                cartViewData: null,
                payments: { active: true },
            } as CartEntity;
        }

        return {
            ...data,
            storeId: params.storeId,
        };
    },
    {
        schema: CartEntity,
        sideEffect: true,
        key: ({ storeId }) => `cart/${storeId}`,
    },
);

export const UpdateCart = new Endpoint(
    async (params: UpdateCartParams) => {
        if (
            !Array.isArray(params.cart.cartItems) ||
            (params.cart.cartItems.length === 0 && !params.cartKey)
        ) {
            return {
                storeId: params.storeId,
                cartStatus: "EMPTY_CART",
                cartViewData: null,
                payments: { active: true },
            } as CartEntity;
        }

        const data = await updateCart(params);
        return {
            ...data,
            storeId: params.storeId,
        };
    },
    {
        schema: CartEntity,
        sideEffect: true,
        key: ({ storeId }) => `cart/${storeId}`,
    },
);

export const FetchClearCartFeedbackOptions = new Endpoint(fetchClearCartFeedbackOptionsApi, {
    key: () => "clear-cart-feedback-options",
});

export const SoftClearCart = new Endpoint(clearCartApi, {
    sideEffect: true,
    key: ({ storeId, bulk }) => `soft-clear-cart/${storeId ?? bulk}`,
});

export class CartListItemEntity extends Entity implements ICartsListItem {
    readonly store!: ICartsListStore;
    readonly products!: ICartsListProduct[];
    readonly cartId!: string;
    readonly cartCreationEpoch!: number;
    readonly updatedAt!: number;

    pk(): string {
        return this.cartId;
    }

    static override get key(): string {
        return "CartListItem";
    }
}

export const CartList = new Endpoint(fetchAllCartsApi, {
    schema: { carts: [CartListItemEntity], nextPageCursor: "" },

    update: (newResponse: ICartsList, params: { cursor?: string; type?: string }) => {
        return {
            [CartList.key({ type: params.type })]: (
                oldResponse: ICartsList = { nextPageCursor: "", carts: [] },
            ) => {
                return params.cursor
                    ? {
                          ...newResponse,
                          carts: unique([...oldResponse.carts, ...newResponse.carts]),
                      }
                    : newResponse;
            },
        };
    },
});
