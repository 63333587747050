import {
    PRODUCT_CTA_TEXT,
    TRACK_ORDER_STATUSES,
    TRACK_ORDER_TEXT,
    TRACK_REFUND_STATUSES,
    TRACK_REFUND_TEXT,
} from "../constants";

export const getOrderCtaText = ({
    orderType = "PHYSICAL",
    orderStatus,
}: {
    orderType: string;
    orderStatus: string;
}): string => {
    if (TRACK_ORDER_STATUSES.indexOf(orderStatus) > -1) {
        return PRODUCT_CTA_TEXT[orderType]?.TRACK_ORDER ?? TRACK_ORDER_TEXT;
    }
    if (TRACK_REFUND_STATUSES.indexOf(orderStatus) > -1) {
        return PRODUCT_CTA_TEXT[orderType]?.TRACK_REFUND ?? TRACK_REFUND_TEXT;
    }

    return TRACK_ORDER_TEXT;
};
