import React, { useMemo } from "react";
import { StyleSheet, Pressable, ViewStyle, StyleProp } from "react-native";
import { NavigationProp, useNavigation } from "@react-navigation/core";

import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { useMount } from "@swiggy-private/react-hooks";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";

import { RouteList } from "@minis-consumer/interfaces/route";
import { Analytics } from "@minis-consumer/analytics";

import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { useCartViewData } from "@minis-consumer/hooks/use-cart";
import { usePaymentSelector } from "@minis-consumer/routes/payment/hooks/use-selector";

interface Props {
    email: string;
    phone: string;
    address: string;
    name?: string;
    style?: StyleProp<ViewStyle>;
}

const USER_IMAGE_CONTAINER_SIZE = 32;
const EDIT_CTA = "Edit details";
const DETAILS_HEADER = "Order will be sent to";
const ANALYTICS_EVENT = "edit-details";

const GuestDetailsComponent: React.FC<Props> = ({ name, email, phone, address, style }) => {
    const store = useStoreInfo();
    const { value: theme } = useTheme();

    const { storeId } = store;
    const cart = useCartViewData(storeId);
    const order = usePaymentSelector((state) => state.order);
    const { id: orderId, txnId: transactionId, bill: orderBill, orderType } = order ?? {};

    const navigation = useNavigation<NavigationProp<RouteList, keyof RouteList>>();

    const userImageContainerStyles = {
        width: USER_IMAGE_CONTAINER_SIZE,
        height: USER_IMAGE_CONTAINER_SIZE,
        borderRadius: USER_IMAGE_CONTAINER_SIZE / 2,
        backgroundColor: theme["color-basic-60"],
        marginRight: SpacingValue["space-small"],
    };

    const containerStyles = {
        backgroundColor: theme["color-basic-0"],
        borderRadius: SpacingValue["space-medium"],
        padding: SpacingValue["space-medium"],
    };

    const analyticsData = useMemo(() => {
        return {
            category: ANALYTICS_EVENT,
            context: JSON.stringify({
                cartId: cart?.id || "-",
                productType: cart?.cartType || orderType,
                paymentAmount: cart?.bill?.billTotal || orderBill?.billTotal,
                orderId: orderId || "-",
                transactionId,
            }),
        };
    }, [cart, orderBill, orderId, orderType, transactionId]);

    useMount(() => {
        Analytics.impressionEvent(analyticsData);
    });

    const onChangeAddress = (): void => {
        Analytics.clickEvent(analyticsData);

        if (navigation.canGoBack()) {
            navigation.goBack();
        }
    };

    return (
        <Stack style={[containerStyles, style]}>
            <Stack direction="row" justifyContent="space-between" flex={1}>
                <Stack direction="row" flex={2}>
                    <Box
                        justifyContent="center"
                        alignItems="center"
                        style={userImageContainerStyles}>
                        <SvgIcon
                            icon="AccountFilled"
                            height={16}
                            width={16}
                            color="color-basic-0"
                        />
                    </Box>
                    <Stack spacing={SpacingValue["space-small"]}>
                        <Stack>
                            <Text category="b3" weight="regular" color="low">
                                {DETAILS_HEADER}
                            </Text>
                            <Text category="b1" weight="regular" color="highest">
                                {name}
                            </Text>
                        </Stack>

                        <Stack>
                            <Text category="b1" color="highest">
                                <Text category="b1" color="low">
                                    {`Contact: `}
                                </Text>
                                {phone}
                            </Text>
                            <Text category="b1" color="highest">
                                <Text category="b1" color="low">
                                    {`Email: `}
                                </Text>
                                {email}
                            </Text>
                        </Stack>
                    </Stack>
                </Stack>

                <Pressable onPress={onChangeAddress}>
                    <Text category="b3" weight="bold" color="color-positive-500">
                        {EDIT_CTA}
                    </Text>
                </Pressable>
            </Stack>

            <Text category="b3" color="low" style={styles.address}>
                {address}
            </Text>
        </Stack>
    );
};

export const GuestDetails = React.memo(GuestDetailsComponent);

const styles = StyleSheet.create({
    userDetails: {
        opacity: 0.6,
    },
    address: {
        marginTop: SpacingValue["space-medium"],
    },
});
