import { useCallback, useContext, useState } from "react";

import { DeliveryInfoParams, fetchDeliveryInfo } from "@minis-consumer/api/delivery";
import {
    SelectedAddressInfo,
    SelectedAddressInfoContext,
} from "@minis-consumer/contexts/selected-address-context";
import { Logger } from "@minis-consumer/includes/logger";
import { IDeliveryInfo } from "@minis-consumer/interfaces/delivery";
import { Order } from "@minis-consumer/interfaces/order";
import { getDeliveryEtaApiParams } from "@minis-consumer/routes/cart/helpers";

interface IDeliveryETAProps {
    fetchDeliveryEta: (params: DeliveryInfoParams, updateContext?: boolean) => void;
    isLoading: boolean;
    deliveryInfo?: IDeliveryInfo;
}

interface IOrderDeliverETAProps extends Omit<IDeliveryETAProps, "fetchDeliveryEta"> {
    fetchOrderETA?: (order?: Order) => void;
    deliveryETAinDays?: string;
    isOrderDeliveryETAvailable?: boolean;
}

export const useDeliveryEta = (): IDeliveryETAProps => {
    const selectedAddressCtx = useContext(SelectedAddressInfoContext);
    const [loading, setLoading] = useState(false);
    const [deliveryInfo, setDeliveryInfo] = useState<IDeliveryInfo>();

    const updateSelectedAddressInfo = useCallback(
        (params: DeliveryInfoParams, response: IDeliveryInfo): void => {
            const deliveryEtaMaxValue = response?.deliveryEta?.max;
            const deliveryEtaMinValue = response?.deliveryEta?.min;

            selectedAddressCtx?.updateSelectedAddressInfo?.({
                ...selectedAddressCtx.selectedAddressInfo,
                lng: params?.dropLng?.toString(),
                lat: params?.dropLat?.toString(),
                postalCode: Number(params.dropPincode),
                addressId: params?.dropLng
                    ? selectedAddressCtx.selectedAddressInfo?.addressId
                    : undefined,
                addressLabel: params?.dropLng
                    ? selectedAddressCtx.selectedAddressInfo?.addressLabel
                    : undefined,
                deliveryEta: deliveryEtaMaxValue
                    ? `${deliveryEtaMinValue}-${deliveryEtaMaxValue} ${response?.deliveryEta?.unit}`
                    : null,
            });
        },
        [selectedAddressCtx],
    );

    const fetchDeliveryEta = useCallback(
        async (params: DeliveryInfoParams, updateContext = true) => {
            try {
                setLoading(true);

                const response = await fetchDeliveryInfo(params);

                // * avoid saving ETAs in context for temp. passed order details
                if (updateContext) {
                    updateSelectedAddressInfo(params, response);
                }

                setDeliveryInfo(response);
                setLoading(false);
            } catch (err) {
                Logger.recordError(err);
            }
        },
        [updateSelectedAddressInfo],
    );

    return { fetchDeliveryEta, deliveryInfo, isLoading: loading };
};

/*
 * Fetches ETA based on passed Order Details
 */
export const useOrderBasedDeliveryETA = (): IOrderDeliverETAProps => {
    const { fetchDeliveryEta, deliveryInfo, isLoading } = useDeliveryEta();
    const selectedAddressCtx = useContext(SelectedAddressInfoContext);

    const fetchOrderETA = useCallback(
        async (orderData?: Order) => {
            // * return if not lat or data already  exists in context
            if (
                !orderData?.dropAddress?.lat ||
                selectedAddressCtx?.selectedAddressInfo?.lat === orderData?.dropAddress?.lat
            ) {
                return;
            }
            const dropAddressDetail = orderData?.dropAddress;
            const { storeDetails, pickupAddress } = orderData;

            const params = getDeliveryEtaApiParams({
                storeId: storeDetails?.id,
                selectedAddress: dropAddressDetail as SelectedAddressInfo,
                pickupAddress: pickupAddress,
            });

            fetchDeliveryEta(params, false);
        },
        [fetchDeliveryEta, selectedAddressCtx?.selectedAddressInfo?.lat],
    );

    const contextDeliveryETA = selectedAddressCtx?.selectedAddressInfo?.deliveryEta;

    const deliveryETAinDays = deliveryInfo?.deliveryEta?.min
        ? `${deliveryInfo?.deliveryEta?.min} - ${deliveryInfo?.deliveryEta?.max} ${deliveryInfo?.deliveryEta?.unit}`
        : contextDeliveryETA ?? undefined;

    const isOrderDeliveryETAvailable = !!deliveryETAinDays || !!contextDeliveryETA;

    return {
        fetchOrderETA,
        deliveryInfo,
        isLoading,
        deliveryETAinDays,
        isOrderDeliveryETAvailable,
    };
};

/*
 * Returns Status of the DeliveryETAs based on Pincode Selected
 */
export const useDeliveryETAContextStatus = (): {
    isUserPincodeAvailable: boolean;
    isDeliveryETAHidden: boolean;
    isDeliveryETAAvailable: boolean;
    selectedAddress: SelectedAddressInfo;
} => {
    const selectedAddressCtx = useContext(SelectedAddressInfoContext);
    const isUserPincodeAvailable = !!selectedAddressCtx?.selectedAddressInfo?.postalCode;
    const deliveryETAinDays = selectedAddressCtx?.selectedAddressInfo?.deliveryEta;

    const isDeliveryETAHidden = !!(isUserPincodeAvailable && !deliveryETAinDays);

    const isDeliveryETAAvailable = !!deliveryETAinDays;

    const selectedAddress = (selectedAddressCtx?.selectedAddressInfo ?? {}) as SelectedAddressInfo;

    return { isUserPincodeAvailable, isDeliveryETAHidden, isDeliveryETAAvailable, selectedAddress };
};
