export const COPIES = {
    UNAVAILABLE: "Unavailable",
    ADD_TO_CART: "Add to cart",
    ENQUIRE: "Enquire",
    GET_QUOTE: "Get quote",
    REMOVE: "Remove",
    BOOK_NOW: "Book now",
    EDIT: "Edit",
};

export const CTA_TEXT_PRODUCT_TYPE_MAP = {
    ADD_PDP: {
        PHYSICAL: "Add to cart",
        PLAN: "Buy now",
        DIGITAL: "Add to cart",
        APPOINTMENT: "Book now",
    },
    ADD: {
        PHYSICAL: "Add to cart",
        PLAN: "Buy now",
        DIGITAL: "Add to cart",
        APPOINTMENT: "Book now",
    },
};

export const ARD_EVENT_NAME = "product-cta";

export const QUANTITY_CTA_HITSLOP = { left: 16, right: 16, bottom: 16, top: 16 };
