/* eslint-disable @typescript-eslint/no-var-requires */
import * as React from "react";
import { Overlay, Portal } from "@swiggy-private/rn-dls";
import { NavigationContainer, Theme as NavTheme } from "@react-navigation/native";

import type { AuthRouteList } from "./types";
import { useNavigationTheme } from "../../hooks/use-navigation-theme";
import { AuthContext, IAuthContext } from "./contexts/auth-context";

import { useScreenOptions } from "@minis-consumer/hooks/use-screen-options";
import { createNavigator } from "@minis-consumer/helpers/navigation";

export interface AuthModalProps {
    open?: boolean;
    onClose?: () => void;
    fullscreen?: boolean;
}

const Stack = createNavigator<AuthRouteList>();

export const AuthModal: React.FC<AuthModalProps> = ({ open, onClose, fullscreen = false }) => {
    const defaultNavTheme = useNavigationTheme();
    const navTheme = React.useMemo<NavTheme>(
        () => ({
            ...defaultNavTheme,
            colors: {
                ...defaultNavTheme.colors,
                background: "transparent",
            },
        }),
        [defaultNavTheme],
    );

    const authContext: IAuthContext = React.useMemo(
        () => ({
            exit: () => onClose?.(),
            fullscreen: fullscreen ?? false,
        }),
        [fullscreen, onClose],
    );

    const screenOptions = useScreenOptions<AuthRouteList>(
        React.useMemo(
            () => ({
                headerShown: false,
                animationEnabled: true,
            }),
            [],
        ),
    );

    if (!open && !fullscreen) {
        return null;
    }

    const navigationStack = (
        <Stack.Navigator
            initialRouteName="Login"
            screenOptions={
                fullscreen
                    ? screenOptions
                    : {
                          headerShown: false,
                      }
            }>
            <Stack.Screen
                name="Login"
                getComponent={() => require("./routes/login").Login}
                options={{
                    title: "Login",
                }}
            />
            <Stack.Screen
                name="Signup"
                getComponent={() => require("./routes/signup").Signup}
                options={{
                    title: "Signup",
                }}
            />
            <Stack.Screen
                name="VerifyOtp"
                getComponent={() => require("./routes/verify-otp").VerifyOtp}
                options={{
                    title: "Verify OTP",
                }}
            />
        </Stack.Navigator>
    );

    if (fullscreen) {
        return <AuthContext.Provider value={authContext}>{navigationStack}</AuthContext.Provider>;
    }

    return (
        <Portal>
            <AuthContext.Provider value={authContext}>
                <NavigationContainer independent theme={navTheme}>
                    <Overlay backgroundColor="primary" onPress={onClose}>
                        {navigationStack}
                    </Overlay>
                </NavigationContainer>
            </AuthContext.Provider>
        </Portal>
    );
};
