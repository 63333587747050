import { Platform } from "react-native";

import { ScreenSize, useScreenSize } from "@swiggy-private/rn-adaptive-layout";

export const useIsMobileWeb = (): boolean => {
    const screenSize = useScreenSize();

    const isMWeb = Platform.OS === "web" && screenSize !== ScreenSize.Large;

    return isMWeb;
};
