import * as React from "react";

export interface IChatBlockModalContext {
    isOpen: boolean;
    close: VoidFunction;
    open: VoidFunction;

    // for API call
    conversationId: string;
    // to show messages accordingly
    isBlocked: boolean;
    personName: string;

    // to set data for messages
    setIsBlocked: (e: boolean) => void;
    setPersonName: (e: string) => void;
    setConversationId: (e: string) => void;
}

export const ChatBlockModalContext = React.createContext<IChatBlockModalContext | null>(null);

export const ChatBlockModalContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [conversationId, setConversationId] = React.useState("");
    const [isBlocked, setIsBlocked] = React.useState(false);
    const [personName, setPersonName] = React.useState("");

    const close = React.useCallback(() => setIsOpen(false), []);
    const open = React.useCallback(() => setIsOpen(true), []);

    const value = React.useMemo(
        () => ({
            isOpen,
            close,
            open,
            conversationId,
            isBlocked,
            personName,
            setIsBlocked,
            setPersonName,
            setConversationId,
        }),
        [
            isOpen,
            close,
            open,
            conversationId,
            isBlocked,
            personName,
            setIsBlocked,
            setPersonName,
            setConversationId,
        ],
    );

    return (
        <ChatBlockModalContext.Provider value={value}>{children}</ChatBlockModalContext.Provider>
    );
};
