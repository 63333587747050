import { Platform } from "react-native";

import { MINIS_DOMAIN_REGEX } from "@minis-consumer/constants";
import { useIsMobileWeb } from "@minis-consumer/hooks/use-mweb";

const ROOT_PATH = "/";

const isWeb = (): boolean => Platform.OS === "web";

const isWindowDefined = (): boolean => typeof window !== "undefined" && !!window.location?.href;

type TReturn = {
    onReload: (slug?: string) => void;
};

export const useOnReloadWeb = (): TReturn => {
    const isMWeb = useIsMobileWeb();

    const reload = (slug?: string): void => {
        if (!isWeb()) {
            return;
        }

        if (!isWindowDefined()) {
            return;
        }

        const { hostname, pathname, search, href } = window.location;
        const isProd = MINIS_DOMAIN_REGEX.test(hostname);
        const redirectPathFromQuery = new URLSearchParams(search ?? "").get("redirectPath");
        const isDevEnvAndHasSlug = !isProd && Boolean(slug?.length);

        const homePath = isDevEnvAndHasSlug ? `?storeSlug=${slug}` : "";

        let path = ROOT_PATH + homePath;

        if (redirectPathFromQuery) {
            path = redirectPathFromQuery + homePath;
        } else if (!isMWeb || !pathname.includes("/auth")) {
            path = href;
        }

        window.location.href = path;
    };

    return { onReload: reload };
};
