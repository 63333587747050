import * as React from "react";
import { StyleProp, StyleSheet, ViewStyle } from "react-native";

import { Box, Stack, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import {
    Button,
    Dialog,
    DialogContent,
    SpacingValue,
    Text,
    TextProps,
    useTheme,
} from "@swiggy-private/rn-dls";
import { ThreeDotsLoader } from "@swiggy-private/react-native-ui";
import { useMount } from "@swiggy-private/react-hooks";

interface ConfirmDialogProps {
    title?: string;
    description?: string;

    onPressCancel: () => void;
    onPressContinue: () => void;

    open?: boolean;
    onClose?: () => void;
    disableBackdropClose?: boolean;

    style?: StyleProp<ViewStyle>;
    btnTertiaryExtraStyle?: StyleProp<ViewStyle>;
    btnCriticalExtraStyle?: StyleProp<ViewStyle>;
    overlayStyle?: StyleProp<ViewStyle>;

    titleTextProps?: TextProps;

    cancelBtnText?: string;
    continueBtnText?: string;

    loading?: boolean;
    onMount?: () => void;
}

export type TextStyleProps = Pick<TextProps, "category" | "weight">;

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
    open = true,
    disableBackdropClose = true,
    overlayStyle,
    style,
    ...props
}) => {
    const modalMaxWidth = useSelectScreen({
        lg: 340 as ViewStyle["width"],
        default: "90%",
    });

    if (!open) {
        return null;
    }

    return (
        <Dialog
            overlayStyle={overlayStyle}
            disableBackdropClose={disableBackdropClose}
            open={open}
            onClose={props.onClose}
            style={[styles.dialog, { width: modalMaxWidth }, style]}>
            <DialogContent>
                <ConfirmDialogContent {...props} />
            </DialogContent>
        </Dialog>
    );
};

const ConfirmDialogContent: React.FC<Exclude<ConfirmDialogProps, "open">> = ({
    onPressCancel,
    title,
    description,
    onPressContinue,
    cancelBtnText,
    continueBtnText,
    loading,
    onMount,
    btnCriticalExtraStyle,
    btnTertiaryExtraStyle,
    titleTextProps,
}) => {
    const { value: theme } = useTheme();

    const btnTertiary: ViewStyle = {
        flex: 1,
    };

    const btnCritical: ViewStyle = {
        flex: 1,
    };

    const btnTertiaryText = {
        color: theme.text_Highest_Emphasis,
    };

    const titleText = useSelectScreen<TextStyleProps>({
        default: { category: "h5" },
        lg: { category: "h4" },
    });

    const subtitleText = useSelectScreen<TextStyleProps>({
        default: { category: "b2" },
        lg: { category: "b1" },
    });

    const marginStyle = useSelectScreen({
        default: {
            marginTop: SpacingValue["space-medium"],
        },
        md: {
            marginTop: SpacingValue["space-x-large"],
        },
        lg: {
            marginTop: SpacingValue["space-x-large"],
        },
    });

    useMount(() => {
        onMount?.();
    });

    return (
        <Box>
            {title ? (
                <Text category={titleText.category} weight="regular" {...titleTextProps}>
                    {title}
                </Text>
            ) : null}
            {description ? (
                <Text category={subtitleText.category} color="medium" style={styles.mt8}>
                    {description}
                </Text>
            ) : null}
            <Stack direction="row" spacing={SpacingValue["space-small"]} style={marginStyle}>
                <Button
                    color="rn_dls_tertiary"
                    elevation={1}
                    style={[btnTertiary, btnTertiaryExtraStyle]}
                    textStyle={btnTertiaryText}
                    onPress={onPressCancel}>
                    {cancelBtnText || "No, continue"}
                </Button>
                <Button
                    color="rn_dls_primary"
                    elevation={1}
                    style={[btnCritical, btnCriticalExtraStyle]}
                    onPress={onPressContinue}>
                    {loading ? <ThreeDotsLoader /> : continueBtnText || "Yes, Cancel"}
                </Button>
            </Stack>
        </Box>
    );
};

const styles = StyleSheet.create({
    dialog: {
        borderRadius: 20,
    },
    mt8: {
        marginTop: SpacingValue["space-x-small"],
    },
});
