import * as React from "react";
import { StyleProp, ViewStyle } from "react-native";

import { CdnImage } from "@swiggy-private/react-native-ui";
import { Box } from "@swiggy-private/rn-adaptive-layout";

import { useIsDesktopWeb } from "@minis-consumer/hooks/use-dweb";

import { DEFAULT_VALUES, TEST_IDS } from "../../constants";
import { getBannerImageId } from "../../helper";

interface BannerProps {
    ratingCount: number;

    styles?: StyleProp<ViewStyle>;
}

const BannerComponent: React.FC<BannerProps> = ({ ratingCount, styles }) => {
    const bannerId = getBannerImageId(ratingCount);
    const isDWeb = useIsDesktopWeb();

    const bannerHeight = isDWeb
        ? DEFAULT_VALUES.LARGE_SCREEN_BANNER_HEIGHT
        : DEFAULT_VALUES.SMALL_SCREEN_BANNER_HEIGHT;

    const bannerWidth = isDWeb
        ? DEFAULT_VALUES.LARGE_SCREEN_BANNER_WIDTH
        : DEFAULT_VALUES.SMALL_SCREEN_BANNER_WIDTH;

    return (
        <Box alignItems="center" style={styles} testID={TEST_IDS.BANNER}>
            <CdnImage
                id={bannerId}
                width={bannerWidth}
                height={bannerHeight}
                testID={TEST_IDS.BANNER_IMAGE}
            />
        </Box>
    );
};

export const Banner = React.memo(BannerComponent);
