import * as React from "react";

export interface IStoreDeletedErrorSnackContext {
    isOpen: boolean;
    close: () => void;
    open: () => void;
}

export const StoreDeletedErrorSnackbarContext =
    React.createContext<IStoreDeletedErrorSnackContext | null>(null);

export const StoreDeletedErrorSnackbarContextProvider: React.FC<React.PropsWithChildren> = ({
    children,
}) => {
    const [isOpen, setIsOpen] = React.useState(false);

    const close = React.useCallback(() => setIsOpen(false), []);
    const open = React.useCallback(() => setIsOpen(true), []);

    const value = React.useMemo(() => ({ isOpen, close, open }), [isOpen, close, open]);

    return (
        <StoreDeletedErrorSnackbarContext.Provider value={value}>
            {children}
        </StoreDeletedErrorSnackbarContext.Provider>
    );
};
