import React from "react";
import { StyleSheet } from "react-native";

import { Text, useTheme, SpacingValue } from "@swiggy-private/rn-dls";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";

import { CartBill } from "@minis-consumer/interfaces/cart";
import { Coupon } from "@minis-consumer/interfaces/coupon";
import { Card } from "@minis-consumer/components/card";
import { formatNumberWithIndianSystem } from "@minis-consumer/helpers/number";

import { DISPLAY_STRINGS } from "../../constants";

interface TooltipProps {
    itemDiscount: CartBill["itemTotal"];
    couponDiscount: CartBill["couponDiscount"];

    appliedCoupon?: Coupon["code"];
}

const DiscountTooltipComponent: React.FC<TooltipProps> = ({
    itemDiscount,
    couponDiscount,
    appliedCoupon,
}) => {
    const { value: theme } = useTheme();
    const formattedItemDiscount = React.useMemo(() => {
        return formatNumberWithIndianSystem(itemDiscount, {
            showRupeeSymbol: true,
        });
    }, [itemDiscount]);

    const formattedCouponDiscount = React.useMemo(() => {
        return formatNumberWithIndianSystem(couponDiscount, {
            showRupeeSymbol: true,
        });
    }, [couponDiscount]);

    return (
        <Card style={[styles.tooltip, { borderColor: theme["color-basic-5"] }]}>
            <Text category="b2" weight="medium" color="high">
                {DISPLAY_STRINGS.DISCOUNT_BREAKUP_TEXT}
            </Text>

            <Box pt={SpacingValue["space-small"]}>
                <Stack spacing={SpacingValue["space-x-small"]}>
                    {itemDiscount ? (
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            spacing={SpacingValue["space-medium"]}>
                            <Text category="b3" color="medium">
                                {DISPLAY_STRINGS.PRODUCT_DISCOUNT_HEADER}
                            </Text>
                            <Text category="b3" color="medium" weight="medium">
                                -{formattedItemDiscount}
                            </Text>
                        </Stack>
                    ) : null}

                    {couponDiscount && appliedCoupon ? (
                        <>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                spacing={SpacingValue["space-medium"]}>
                                <Box>
                                    <Text category="b3" color="medium">
                                        {DISPLAY_STRINGS.COUPON_DISCOUNT_HEADER}
                                    </Text>
                                </Box>
                                <Box>
                                    <Text category="b3" color="color-basic-60" weight="medium">
                                        -{formattedCouponDiscount}
                                    </Text>
                                </Box>
                            </Stack>
                            <Text style={styles.couponCodeContainer}>
                                <Text
                                    category="b3"
                                    color="medium"
                                    weight="regular"
                                    style={styles.couponCode}>
                                    {DISPLAY_STRINGS.COUPON_CODE_PREFIX}
                                </Text>
                                <Text
                                    category="b3"
                                    weight="bold"
                                    color="high"
                                    style={styles.couponCode}>
                                    {appliedCoupon}
                                </Text>
                            </Text>
                        </>
                    ) : null}
                </Stack>
            </Box>
        </Card>
    );
};

export const DiscountTooltip = React.memo(DiscountTooltipComponent);

const styles = StyleSheet.create({
    tooltip: {
        borderWidth: 1,
        padding: SpacingValue["space-medium"],
    },
    couponCodeContainer: {
        maxWidth: "90%",
    },
    couponCode: {
        fontSize: 12,
    },
});
