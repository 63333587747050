/**
 * Converts time in seconds to mm:ss format
 *
 * @param seconds time in seconds
 * @returns time in mm:ss format
 */
export const convertTimeInSecsToMinSecs = (seconds: number): string => {
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);
    return `${mins < 10 ? "0" : ""}${mins}:${secs < 10 ? "0" : ""}${secs}`;
};

/**
 * Function for removing spaces from string.
 *
 * @param str - The string
 * @returns the string with no spaces.
 */
export const removeSpaces = (str: string): string => str.replace(/\s/g, "");
