import { APP_ENV } from "@env";
import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

if (typeof window !== "undefined" && typeof window.SERVICE_CONFIG === "object") {
    const releaseTag = `${APP_ENV}_${window.SERVICE_CONFIG.BUILD_NUMBER}`;

    Sentry.init({
        dsn: "https://2243ec2cd699425d933421fd98378a86@o331401.ingest.sentry.io/6467469",
        release: releaseTag,
        integrations: [new BrowserTracing()],
        tracesSampleRate: 0.1,
        enabled: __DEV__ === false,
        environment: APP_ENV,
        ignoreErrors: ["HttpError", "ApiError"],
    });
}

export default Sentry;
