import { useCallback } from "react";

import { downloadAssetApi } from "@minis-consumer/api/media";
import { DigitalAssetInfo } from "@minis-consumer/interfaces/catalog";
import { useLinking } from "@minis-consumer/hooks/use-linking";
import { Platform } from "react-native";

export const useDownloadFile = (): ((asset?: DigitalAssetInfo) => Promise<void>) => {
    const openUrl = useLinking();
    return useCallback(
        async (asset?: DigitalAssetInfo) => {
            if (!asset || !asset.value) {
                return;
            }
            if (asset.type === "LINK") {
                openUrl(asset.value);
                return;
            }

            const downloadFileData = await downloadAssetApi({
                mediaId: asset.value,
                source: "PRIVATE_ASSET",
            });

            if (downloadFileData.url) {
                if (Platform.OS === "web") {
                    const link = document.createElement("a");
                    link.href = downloadFileData.url;
                    link.download = "file";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    return;
                }
                openUrl(downloadFileData.url);
            }
        },
        [openUrl],
    );
};
