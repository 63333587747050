import * as React from "react";
import { ViewProps, StyleSheet } from "react-native";

import { InitiateReviewMessage as IInitiateReviewMessage } from "@swiggy-private/connect-chat-commons";
import { StarRating } from "@swiggy-private/connect-business-commons";
import { SpacingValue } from "@swiggy-private/rn-dls";

import { useRatingAndReviewHook } from "@minis-consumer/hooks/use-rating-and-review";
import { Analytics } from "@minis-consumer/analytics";

import { ANALYTICS_COPIES } from "../../constants";

interface InitiatedReviewMessageProps {
    message: IInitiateReviewMessage;
    conversationId: string;

    style?: ViewProps["style"];
}

const InitiatedReviewMessageFooterCtaComponent: React.FC<InitiatedReviewMessageProps> = ({
    message: { payload, type },
    conversationId,
}) => {
    const { orderId, storeId } = payload;
    const { ratingAndReviewNavigtaionHandler } = useRatingAndReviewHook({
        orderId,
        storeId,
    });

    const trackInitiatedReviewMsgFooterCtaEvent = React.useCallback(
        (ratingCount: number) => {
            Analytics.clickEvent({
                category: ANALYTICS_COPIES.CHAT_MSGS,
                context: JSON.stringify({
                    storeId,
                    orderId,
                    messageType: type,
                    conversationId,
                    ratingStars: ratingCount,
                }),
            });
        },
        [orderId, storeId, type, conversationId],
    );

    const onPress = React.useCallback(
        (index: number) => {
            trackInitiatedReviewMsgFooterCtaEvent(index);
            ratingAndReviewNavigtaionHandler(index);
        },
        [ratingAndReviewNavigtaionHandler, trackInitiatedReviewMsgFooterCtaEvent],
    );

    return (
        <StarRating
            onPress={onPress}
            style={styles.container}
            iconProps={{
                color: "color-primary",
            }}
        />
    );
};

if (__DEV__) {
    InitiatedReviewMessageFooterCtaComponent.displayName = "InitiatedReviewMessageFooterCta";
}

/** We will be using some props in upcoming PRS */
export const InitiatedReviewMessageFooterCta = React.memo(InitiatedReviewMessageFooterCtaComponent);

const styles = StyleSheet.create({
    container: {
        marginTop: SpacingValue["space-small"],
    },
});
