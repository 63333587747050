import * as React from "react";
import { Pressable, StyleSheet } from "react-native";

import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { Text, SpacingValue } from "@swiggy-private/rn-dls";
import { CdnImage } from "@swiggy-private/react-native-ui";
import { useMount } from "@swiggy-private/react-hooks";

import { Analytics } from "@minis-consumer/analytics";
import { useCartViewData } from "@minis-consumer/hooks/use-cart";
import { formatNumberWithIndianSystem } from "@minis-consumer/helpers/number";

const CTA_TEXT = "Awesome!";
const DESCRIPTION_TEXT = "Supporting small businesses has never been so rewarding!";

type DialogBodyProps = {
    storeId: string;
    closeDialog: () => void;
};

const IMAGE_SIZE = 70;

const DialogBodyComponent: React.FC<DialogBodyProps> = ({ storeId, closeDialog }) => {
    const cart = useCartViewData(storeId);

    const formattedDiscount = React.useMemo(() => {
        return formatNumberWithIndianSystem(cart?.appliedCoupon?.discountAmount ?? 0, {
            showRupeeSymbol: true,
        });
    }, [cart?.appliedCoupon?.discountAmount]);

    useMount(() => {
        Analytics.impressionEvent({
            category: "coupon-confirmation-popup",
            label: `cartId: ${cart?.id}`,
            context: `couponCode: ${cart?.appliedCoupon?.code}`,
        });
    });

    return (
        <Stack spacing={SpacingValue["space-x-large"]} alignItems="center">
            <CdnImage
                isImageKitEnabled
                id="minis/celebration_modal"
                width={IMAGE_SIZE}
                height={IMAGE_SIZE}
            />

            <Text style={styles.txt} category="h4" weight="bold">
                {`Yay! You've saved ${formattedDiscount} with this coupon`}
            </Text>

            <Text category="b2" color="high" style={styles.txt}>
                {DESCRIPTION_TEXT}
            </Text>

            <Pressable onPress={closeDialog}>
                <Text category="btn3" color="color-primary" style={styles.txt}>
                    {CTA_TEXT}
                </Text>
            </Pressable>
        </Stack>
    );
};

const styles = StyleSheet.create({
    txt: {
        textAlign: "center",
    },
});

export const DialogBody = React.memo(DialogBodyComponent);
