import { nanoid } from "nanoid/non-secure";
import { Platform } from "react-native";
import { Analytics as RNAnalytics } from "@swiggy-private/rn-analytics";

import { AppConfig } from "@minis-consumer/config";
import { Session } from "@minis-consumer/includes/session";
import { Device } from "@minis-consumer/includes/device";
import { getCurrentLocation } from "@minis-consumer/contexts/location-context";
import { BaseWidgetAction } from "@minis-consumer/interfaces/widget";

import { RouteToAnalyticsScreenMap } from "./screen";
import { getUserAgentAndApp } from "../helpers/user-agent-app-name";

const isTestEnv = process.env.NODE_ENV === "test";

export type Event = {
    category: string;
    screen?: string;
    label?: string;
    value?: number;
    context?: string;
    referralScreen?: string;
    storeId?: string;
};

type SendEvent = Event & {
    action: string;
};

type EventNullProps = string | null | undefined;

const ClientEventInfo = {
    eventName: AppConfig.ANALYTICS_EVENT_NAME,
    sessionId: nanoid(),
    eventId: 0,
    schemaVersion: AppConfig.ANALYTICS_SCHEMA_VERSION,
};

class SendEventModule {
    currentScreen = "-";
    referralScreen = "-";
    storeId = "-";

    setStoreId = (storeId: EventNullProps): void => {
        this.storeId = storeId ?? "-";
    };

    updateScreenNameOnly = (screen: EventNullProps): void => {
        this.currentScreen = screen ?? "-";
    };

    setReferalScreen = (screen: EventNullProps): void => {
        this.referralScreen = screen ?? "-";
    };

    customEvent = (event: SendEvent): void => {
        if (isTestEnv || !AppConfig.ANALYTICS_ENABLED) {
            return;
        }

        const sessionInfo = Session.getInstance();
        const deviceInfo = Device.getInstance();
        //It is relevant to mWeb only
        const [userAgent, identifierApp] = getUserAgentAndApp();

        const screen = this.currentScreen;
        const referralScreenVal = this.referralScreen;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const _screen: string = screen ? RouteToAnalyticsScreenMap[screen] || screen : "-";

        const location = getCurrentLocation();

        const _referralScreen: string = referralScreenVal
            ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              RouteToAnalyticsScreenMap[referralScreenVal] || referralScreenVal
            : "-";

        RNAnalytics.trackEvent({
            eventId: ClientEventInfo.eventId++,
            sessionId: ClientEventInfo.sessionId,
            schemaVersion: ClientEventInfo.schemaVersion,
            eventName: ClientEventInfo.eventName,
            event: {
                ...event,
                screen: _screen,
                sessionInfo: {
                    sid: sessionInfo?.sid ?? "-",
                    lat: String(location?.lat || "-"),
                    lng: String(location?.lng || "-"),
                    userId: String(sessionInfo?.userId ?? "0"),
                },
                deviceInfo: {
                    id: deviceInfo.deviceId,
                    os: "-",
                    platform:
                        Platform.OS === "android"
                            ? "android"
                            : Platform.OS === "ios"
                            ? "ios"
                            : "web",
                    appVersion: deviceInfo.appVersion ?? "-",
                    osVersion: deviceInfo.osVersion ?? "-",
                    userAgent: userAgent,
                    identifierApp: identifierApp,
                },
                eventTimestamp: Date.now(),
                storeId: event.storeId ?? this.storeId,
                context: event.context ?? "-",
                referralScreen: _referralScreen,
            },
        });
    };
}

class AnalyticsModule extends SendEventModule {
    constructor() {
        super();
    }
    init = (): void => {
        RNAnalytics.init({
            appName: AppConfig.ANALYTICS_APP_NAME,
            endpoint: AppConfig.ANALYTICS_URL,
        });
    };

    clickEvent = (event: Event): void => {
        this.customEvent({
            action: "click",
            label: event.label ?? "",
            ...event,
        });
    };

    impressionEvent = (event: Event): void => {
        setTimeout(() => {
            this.customEvent({
                action: "impression",
                label: event.label ?? "",
                ...event,
            });
        }, 0);
    };

    screenViewEvent = (event: Event): void => {
        this.customEvent({
            action: "screen-view",
            label: event.label ?? "",
            ...event,
        });
    };

    launchEvent = (event: Event): void => {
        this.customEvent({
            action: "launch",
            label: "-",
            ...event,
        });
    };

    destroy = (): void => {
        RNAnalytics.destroy();
    };

    setCurrentScreen = (event: Omit<Event, "category">): void => {
        this.setReferalScreen(this.currentScreen);
        this.updateScreenNameOnly(event.screen);

        this.screenViewEvent({
            category: "-",
            context: event.context,
            label: event.label,
            value: event.value,
        });
    };

    // * used only when moving from MinisHP to Store
    preNavigateToStore = (action: BaseWidgetAction, screen: string): void => {
        const { storeId, ...otherProps } = action;

        if (storeId) {
            this.setStoreId(storeId);
        }

        this.setCurrentScreen({ screen, context: JSON.stringify(otherProps) });
    };
}

/**
 * ## Usage
 *
 * ```js
 * import { Analytics } from "analytics";
 * Analytics.clickEvent({
 *      category: "home",
 *      label: "create_cart",
 *      value: 1
 * });
 * ```
 */
export const Analytics = new AnalyticsModule();
