export const PINCODE_FORM_CONSTS = {
    ADDRESS_INPUT_LABEL: "Pincode",
    APPLY_BTN: "Apply",
    HEADER_TTTLE: "Enter pincode",
    HEADER_DESCRIPTION: "to view delivery information",
    SMALL_SCREEN_TITLE: "Enter your pincode to check delivery information",
    INVALID_PINCODE_ERROR: "Please enter a valid 6 digit pincode",
};

export const ANALYTICS_COPIES = {
    PINCODE_APPLY: "pincode-apply",
    ENTER_PINCODE: "enter-pincode",
};

export const VALID_PINCODE_LENGTH = 6;
