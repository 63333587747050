import React, { useRef } from "react";
import { StyleSheet, TextInput, ViewStyle } from "react-native";

import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";
import { FormInput } from "@swiggy-private/react-native-ui";
import { Stack, useScreenSize, ScreenSize } from "@swiggy-private/rn-adaptive-layout";

import { ExpansionPanel } from "@minis-consumer/components/expansion-panel";

interface Props {
    fullName: string;
    email: string;
    phone: string;
    handleFullName: (value: string) => void;
    handleEmail: (value: string) => void;
    handlePhone: (value: string) => void;
    errors: Record<string, string | null>;
    canCollapse?: boolean;
    hintStyles?: ViewStyle;
}

const DISPLAY_COPIES = {
    CONTACT_FORM_HEADER: "How can we contact you",
};

const CONTACT_FORM_LABELS = {
    FULL_NAME: "Full Name",
    EMAIL_ADDRESS: "Email Address",
    PHONE_NUMBER: "Phone Number",
};

const CONTACT_FORM_HINTS = {
    EMAIL_HINT: "to share updates on this order",
    PHONE_HINT: "may be used to assist delivery",
};

export const ContactDetails: React.FC<Props> = ({
    fullName,
    email,
    phone,
    handleFullName,
    handleEmail,
    handlePhone,
    errors,
    canCollapse,
    hintStyles,
}) => {
    const { value: theme } = useTheme();

    const screenSize = useScreenSize();

    const emailRef = useRef<TextInput>(null);
    const phoneRef = useRef<TextInput>(null);

    const isLargeScreen = React.useMemo(() => screenSize === ScreenSize.Large, [screenSize]);

    const inputStyle = {
        fontFamily: theme["text-subheader-2-font-family"],
        letterSpacing: theme["text-subheader-2-letter-spacing"],
        color: theme["color-basic-100"],
    };

    const containerStyles = {
        backgroundColor: theme["color-basic-0"],
        paddingHorizontal: isLargeScreen ? 0 : SpacingValue["space-medium"],
        paddingVertical: isLargeScreen ? 0 : SpacingValue["space-large"],
        borderRadius: SpacingValue["space-medium"],
        borderWidth: isLargeScreen ? 0 : 1,
        borderColor: theme["color-basic-15"],
        zIndex: -1,
    };

    const customHintStyles = {
        backgroundColor: theme["color-basic-5"],
        borderColor: theme["color-basic-5"],
        paddingBottom: SpacingValue["space-xx-small"],
    };

    return (
        <Stack style={containerStyles}>
            <ExpansionPanel
                canCollapse={canCollapse}
                collapsed={false}
                title={DISPLAY_COPIES.CONTACT_FORM_HEADER}>
                <Stack spacing={24}>
                    <FormInput
                        name="name"
                        label={CONTACT_FORM_LABELS.FULL_NAME}
                        style={styles.firstInputBox}
                        onChangeText={handleFullName}
                        placeholderTextColor={theme["color-basic-60"]}
                        textStyle={inputStyle}
                        testID="full-name"
                        textContentType="name"
                        blurOnSubmit={false}
                        value={fullName}
                        returnKeyType="next"
                        onSubmitEditing={() => emailRef.current?.focus()}
                        autoComplete="name"
                        hintContainerStyle={hintStyles}
                        error={errors.fullName != null}
                        hint={errors.fullName || ""}
                    />
                    <FormInput
                        ref={emailRef}
                        name="email"
                        label={CONTACT_FORM_LABELS.EMAIL_ADDRESS}
                        onChangeText={handleEmail}
                        placeholderTextColor={theme["color-basic-60"]}
                        textStyle={inputStyle}
                        testID="email-address"
                        textContentType="emailAddress"
                        blurOnSubmit={false}
                        returnKeyType="next"
                        value={email}
                        hint={errors.emailAddress || CONTACT_FORM_HINTS.EMAIL_HINT}
                        onSubmitEditing={() => phoneRef.current?.focus()}
                        hintContainerStyle={
                            hintStyles && errors.emailAddress ? hintStyles : customHintStyles
                        }
                        autoComplete="email"
                        autoCapitalize="none"
                        inputMode="email"
                        error={errors.emailAddress != null}
                    />
                    <FormInput
                        ref={phoneRef}
                        name="phone"
                        label={CONTACT_FORM_LABELS.PHONE_NUMBER}
                        onChangeText={handlePhone}
                        placeholderTextColor={theme["color-basic-60"]}
                        textStyle={inputStyle}
                        testID="phone-number"
                        textContentType="telephoneNumber"
                        maxLength={10}
                        blurOnSubmit={false}
                        hint={errors.phoneNumber || CONTACT_FORM_HINTS.PHONE_HINT}
                        hintContainerStyle={
                            hintStyles && errors.phoneNumber ? hintStyles : customHintStyles
                        }
                        value={phone}
                        autoComplete="tel"
                        inputMode="tel"
                        error={errors.phoneNumber != null}
                    />
                </Stack>
            </ExpansionPanel>
        </Stack>
    );
};

const styles = StyleSheet.create({
    firstInputBox: {
        marginTop: SpacingValue["space-x-large"],
    },
});
