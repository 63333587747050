import { Box } from "@swiggy-private/rn-adaptive-layout";
import { Overlay, ActivityIndicator, Portal } from "@swiggy-private/rn-dls";
import * as React from "react";
import { ViewStyle } from "react-native";

export interface OverlayLoaderProps {
    visible: boolean;
    style?: ViewStyle;
}

export const OverlayLoader: React.FC<OverlayLoaderProps> = ({ visible, style }) => {
    if (!visible) {
        return null;
    }

    const overlayStyle = {
        backgroundColor: "rgba(255, 255, 255, .5)",
    };

    return (
        <Portal>
            <Overlay backgroundColor="secondary" style={[overlayStyle, style]}>
                <Box flex={1} justifyContent="center" alignItems="center">
                    <ActivityIndicator size="large" />
                </Box>
            </Overlay>
        </Portal>
    );
};
