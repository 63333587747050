import React from "react";
import { Linking } from "react-native";

import { useMountedRef } from "@swiggy-private/react-hooks";
import { useToast } from "@minis-consumer/hooks/use-toast";

export const useLinking = (): ((link: string) => void) => {
    const [showToast] = useToast();
    const mountedRef = useMountedRef();

    return React.useCallback(
        (link: string) => {
            Linking.openURL(link).catch(() => {
                mountedRef.current && showToast("Could not be opened");
            });
        },
        [mountedRef, showToast],
    );
};
