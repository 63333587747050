import * as React from "react";

import { IAppUser } from "@minis-consumer/reducers/app-reducer/types";
import { AppStateProvider } from "../contexts/app-context";

interface AppStateContainerProps {
    initialUser?: IAppUser | null;
}

export const AppStateContainer: React.FC<React.PropsWithChildren<AppStateContainerProps>> = ({
    children,
    initialUser,
}) => {
    const initialState = {
        user: initialUser ?? null,
    };

    return <AppStateProvider initialState={initialState}>{children}</AppStateProvider>;
};
