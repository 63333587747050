import React from "react";
import { Animated, Easing, InteractionManager, Platform } from "react-native";

import { useAnimatedValue } from "@swiggy-private/rn-dls";

interface IWidgetPresserProps {
    children: React.ReactNode;
    animationDuration: number;
    shouldTriggerAnimation: boolean;

    shouldReverseAnimation?: boolean;
    callback?: VoidFunction;
}

// This component will show presseable effect i.e animation on children
const WidgetPresserComponent: React.FC<IWidgetPresserProps> = ({
    children,
    animationDuration,
    callback,
    shouldTriggerAnimation,
    shouldReverseAnimation = false,
}) => {
    const animatedValue = useAnimatedValue(shouldReverseAnimation ? 1 : 0);

    const taskRef = React.useRef<ReturnType<typeof InteractionManager.runAfterInteractions> | null>(
        null,
    );

    const animatedStyle = animatedValue.interpolate({
        inputRange: [0, 0.5, 1],
        outputRange: [1, 0.95, 1],
    });

    const onAnimate = React.useCallback(() => {
        taskRef.current?.cancel();

        taskRef.current = InteractionManager.runAfterInteractions(() => {
            Animated.timing(animatedValue, {
                toValue: shouldReverseAnimation ? 0 : 1,
                duration: animationDuration,
                easing: Easing.bezier(0.65, 0, 0.35, 1),
                useNativeDriver: Platform.OS !== "web",
            }).start((finished) => {
                if (finished) {
                    callback?.();
                }
            });
        });
    }, [animatedValue, animationDuration, callback, shouldReverseAnimation]);

    React.useEffect(() => {
        if (shouldTriggerAnimation) {
            onAnimate();
        }
    }, [shouldTriggerAnimation, onAnimate]);

    return (
        <Animated.View
            style={{
                transform: [
                    {
                        scaleX: animatedStyle,
                    },
                    { scaleY: animatedStyle },
                ],
            }}>
            {children}
        </Animated.View>
    );
};

export const WidgetPresser = React.memo(WidgetPresserComponent);
