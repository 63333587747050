import { App as MinisApp } from "@swiggy-private/mini-consumer-native";
import * as React from "react";

import { WithStylesContext } from "../../includes/style-context";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import styles from "../../global.css";

interface AppProps {
    storeSlug: string;
    headers?: Record<string, string>;
    url?: string;
}

export const App: React.FC<AppProps> = ({ storeSlug, headers, url }: AppProps) => {
    const context = React.useContext(WithStylesContext);
    if (typeof context?.addStyles === "function") {
        context.addStyles(styles);
    }

    return <MinisApp storeSlug={storeSlug} swiggyHeaders={headers} url={url} />;
};
