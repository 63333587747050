export const MINIS_SHARE_TEXT = `
Hey, have you heard about Swiggy Minis? 🎉

You can set up your mini-site in just minutes and start selling anything from appointments 📅 to PDFs 📄, cookies 🍪 to t-shirts 👕—all right from your website!

Plus, you can re-engage with your customers seamlessly using the Swiggy app.

Check it out here: https://mini.site

Let's elevate your online selling game! 🚀
`;

export const MINIS_SITE = "https://mini.site";

export const PRODUCT_SHARE_TEXT = `Discover {0} from {1} here`;
