import React, { useCallback } from "react";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { useMount } from "@swiggy-private/react-hooks";
import { UploadedMediaFile } from "@swiggy-private/rn-image-picker";

import { Analytics, Event } from "@minis-consumer/analytics";
import { useToast } from "@minis-consumer/hooks/use-toast";
import { MediaUploadModal } from "@minis-consumer/components/media-upload-modal";

interface ChatUploadMediaModalProps {
    bottomOffset: number;
    onUpload: (media: UploadedMediaFile[]) => void;

    analytics?: Event;
    handleUploadOptionSelect?: (s: string) => void;
}

export const MediaUploadWrapperModal: React.FC<ChatUploadMediaModalProps> = ({
    bottomOffset,
    onUpload,
    analytics,
    handleUploadOptionSelect,
}) => {
    const [showToast] = useToast();

    const uploadCallback = useCallback(
        (media?: UploadedMediaFile[]) => onUpload(media ?? []),
        [onUpload],
    );

    const onError = useCallback(
        (err?: Error) => {
            if (err) {
                onUpload([]);
                showToast(err.message, 5_000, {
                    accessoryLeft: <SvgIcon icon="Info" color="color-basic-0" />,
                });
            }
        },
        [onUpload, showToast],
    );

    useMount(() => {
        if (analytics) {
            Analytics.impressionEvent(analytics);
        }
    });

    const containerStyle = {
        backgroundColor: "transparent",
    };

    const contentStyle = {
        bottom: bottomOffset,
    };

    return (
        <MediaUploadModal
            onUpload={uploadCallback}
            sourceModalContentStyle={contentStyle}
            sourceModalStyle={containerStyle}
            uploadBtnText="Send"
            supportedMediaTypes={["photo"]}
            limit={1}
            maxVideoSize={30 * 1000 * 1000} // 30mb
            maxImageSize={4 * 1000 * 1000} // 4mb
            onError={onError}
            handleUploadOptionSelect={handleUploadOptionSelect}
        />
    );
};
