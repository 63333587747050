import React from "react";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { RouteList } from "@minis-consumer/interfaces/route";
import { Cart } from "@minis-consumer/interfaces/cart";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { useCartStatus } from "@minis-consumer/hooks/use-cart";

type Props = {
    checkoutErrorMsg?: string;
    cart?: Cart["cartViewData"];
};

type ReturnValue = {
    errorText: string;
    canShowSnackbar: boolean;
    dismissSnackbar: VoidFunction;
    triggerError: (msg: string) => void;
};

export const useInvalidAppointmentHandler = (props: Props | null): ReturnValue => {
    const navigation = useNavigation<NativeStackNavigationProp<RouteList>>();
    const storeInfo = useStoreInfo();
    const cartStatus = useCartStatus(storeInfo.storeId);

    const [errorText, setErrorText] = React.useState("");
    const [canShowSnackbar, setSnackbar] = React.useState(false);

    const { id: productId, customItem: isCustom } = props?.cart?.cartItems[0]?.item || {};

    const triggerError = React.useCallback((msg: string) => {
        setSnackbar(true);
        setErrorText(msg);
    }, []);

    const dismissSnackbar = React.useCallback(() => {
        setSnackbar(false);
        setErrorText("");

        if (productId) {
            navigation.navigate("SlotSelection", {
                id: productId,
                slug: storeInfo.slug,
                isCustom: isCustom,
                isEditMode: true,
            });
        }
    }, [isCustom, navigation, productId, storeInfo.slug]);

    React.useEffect(() => {
        if (cartStatus === "VALID_CART") {
            setSnackbar(false);
            setErrorText("");
        }

        if (cartStatus === "ITEM_SLOT_UNAVAILABLE" || cartStatus === "ITEM_SLOT_EXPIRED") {
            triggerError("Time slot taken by someone else. Please choose another");
        }
    }, [cartStatus, triggerError]);

    return { errorText, canShowSnackbar, dismissSnackbar, triggerError };
};
