import * as React from "react";

import { CustomMessageTypes } from "@swiggy-private/connect-chat-commons";
import { CustomFooterCtaProps } from "@swiggy-private/connect-chat-ui";
import { InitiateReviewMessage as IInitiateReviewMessage } from "@swiggy-private/connect-chat-commons";

import { InitiatedReviewMessageFooterCta } from "./initiated-review";

export const MessageFooterCta: React.FC<CustomFooterCtaProps> = ({ message, ...props }) => {
    switch (message.type) {
        case CustomMessageTypes.INITIATE_REVIEW:
            return (
                <InitiatedReviewMessageFooterCta
                    {...props}
                    message={message as unknown as IInitiateReviewMessage}
                />
            );

        default:
            return null;
    }
};
