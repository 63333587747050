import React from "react";
import { StyleProp, StyleSheet, useWindowDimensions, ViewStyle } from "react-native";

import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue } from "@swiggy-private/rn-dls";

import { Card } from "@minis-consumer/components/card";
import { Shimmer } from "@minis-consumer/components/shimmer";

const HEIGHTS = {
    STORE_ROW: 20,
    PRODUCT_ROW: 36,
    CTA_ROW: 52,
};

const WIDTHS = {
    STORE_ROW: 150,
    CTA_ROW: 50,
};

const LoaderComponent: React.FC<{ containerStyle?: StyleProp<ViewStyle> }> = ({
    containerStyle,
}) => {
    const { width: screenWidth } = useWindowDimensions();

    const widthAfterRemovingGutters = screenWidth - SpacingValue["space-xx-large"] * 2;

    const middleElementWidth = widthAfterRemovingGutters - SpacingValue["space-x-small"];

    const inputLoaderWidth =
        widthAfterRemovingGutters - SpacingValue["space-small"] - WIDTHS.CTA_ROW;

    const middleElements = React.useMemo(() => {
        return Array(2)
            .fill(1)
            .map((_, index) => (
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    key={index}
                    alignItems="center">
                    <Shimmer
                        width={middleElementWidth / 2}
                        height={HEIGHTS.PRODUCT_ROW}
                        style={styles.br8}
                    />
                    <Shimmer
                        width={middleElementWidth / 2}
                        height={HEIGHTS.PRODUCT_ROW}
                        style={styles.br8}
                    />
                </Stack>
            ));
    }, [middleElementWidth]);

    return (
        <Box style={containerStyle}>
            <Card style={styles.card}>
                <Stack spacing={SpacingValue["space-medium"]}>
                    <Shimmer
                        width={WIDTHS.STORE_ROW}
                        height={HEIGHTS.STORE_ROW}
                        style={styles.br8}
                    />

                    <Stack spacing={SpacingValue["space-x-small"]} style={styles.mt4}>
                        {middleElements}
                    </Stack>

                    <Stack spacing={SpacingValue["space-small"]} direction="row">
                        <Shimmer
                            width={inputLoaderWidth}
                            height={HEIGHTS.CTA_ROW}
                            style={styles.br8}
                        />
                        <Shimmer
                            width={WIDTHS.CTA_ROW}
                            height={HEIGHTS.CTA_ROW}
                            style={styles.br8}
                        />
                    </Stack>
                </Stack>
            </Card>
        </Box>
    );
};

const styles = StyleSheet.create({
    card: {
        borderRadius: 20,
    },
    br8: {
        borderRadius: 8,
    },
    mt4: {
        marginTop: SpacingValue["space-xx-small"],
    },
});

export const Loader = React.memo(LoaderComponent);
