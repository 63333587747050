/**
 * A utility to get all the url search params using given url.
 *
 * @param url the url to parse
 * @returns key value pairs
 */
export const getUrlSearchParams = (
    url: string,
    decode = true,
): Record<string, string | null | undefined> => {
    const search = url.split("?")[1] ?? "";
    return search.split("&").reduce((acc, param) => {
        if (param === "") {
            return acc;
        }

        const [key, value] = decode ? param.replace(/\+/g, " ").split("=") : param.split("=");
        if (!key) {
            return acc;
        }

        acc[decode ? decodeURIComponent(key) : key] =
            value == null ? null : decode ? decodeURIComponent(value) : value;
        return acc;
    }, {} as Record<string, string | null>);
};
