import { API_SUCCESS_STATUS_CODE, ApiError } from "@swiggy-private/connect-api-core";
import { Endpoint } from "@swiggy-private/http-client";

import { InstagramMedia, InstagramUser } from "@minis-consumer/interfaces/store";

import { getApiClient } from "../../includes/api";
import { InstagramEndpoint } from "../endpoint";

export interface FetchInstagramParams {
    storeId: string;
}

export interface InstagramFetchAllMediaResponse {
    user?: InstagramUser;
    media?: {
        data?: InstagramMedia[];
    };
}

/**
 * The API to fetch instagram media of a store
 *
 * @returns {@link Promise} with value as {@link InstagramFetchAllMediaResponse}
 */
export const getStoreInstagramMediaApi = async ({
    storeId,
}: FetchInstagramParams): Promise<InstagramFetchAllMediaResponse> => {
    const endpoint = Endpoint.from(InstagramEndpoint, { storeId });
    const response = await getApiClient().get<InstagramFetchAllMediaResponse>({ endpoint });

    if (response.statusCode === API_SUCCESS_STATUS_CODE && response.data) {
        return response.data;
    }

    return Promise.reject(
        new ApiError(
            response.statusMessage ||
                "Sorry, unable to fetch the details. Please try again in a few minutes",
            response,
        ),
    );
};
