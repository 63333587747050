import * as React from "react";

import { StoreDeletedErrorSnackbarContext } from "@minis-consumer/contexts/store-deleted-error-snackbar-context";

export const useStoreDeletedErrorSnackbarHandler = (): [
    boolean,
    (b: boolean, p?: string, i?: string) => void,
] => {
    const ctx = React.useContext(StoreDeletedErrorSnackbarContext);

    const handler = React.useCallback(
        (open: boolean) => {
            if (open) {
                ctx?.open();
            } else {
                ctx?.close();
            }
        },
        [ctx],
    );

    return [ctx?.isOpen ?? false, handler];
};
