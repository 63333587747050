import React from "react";
import { InteractionManager, Platform, Animated, ViewStyle, StyleProp, Easing } from "react-native";

import { useMount } from "@swiggy-private/react-hooks";
import { useAnimatedValue } from "@swiggy-private/rn-dls";

type NumberArray = number[];

interface AnimatedTranslateWrapperProps {
    duration: number;
    delay: number;
    children: React.ReactNode;

    yDirectionOutput?: NumberArray;
    yDirectionInput?: NumberArray;
    opacityInputRange?: NumberArray;
    opacityOutputRange?: NumberArray;
    style?: StyleProp<ViewStyle>;
    canAnimate?: boolean;
}

const AnimatedTranslateWrapperComponent: React.FC<AnimatedTranslateWrapperProps> = ({
    delay,
    duration,
    children,
    style,
    yDirectionInput,
    yDirectionOutput,
    opacityInputRange,
    opacityOutputRange,
    canAnimate = true,
}) => {
    const animatedValue = useAnimatedValue(0);

    useMount(() => {
        if (!canAnimate) {
            return;
        }

        const task = InteractionManager.runAfterInteractions(() => {
            Animated.timing(animatedValue, {
                toValue: 1,
                duration,
                delay,
                easing: Easing.bezier(0.65, 0, 0.35, 1),
                useNativeDriver: Platform.OS !== "web",
            }).start();
        });

        return () => {
            task.cancel();
        };
    });

    return (
        <Animated.View
            style={[
                {
                    transform: [
                        {
                            translateY: animatedValue.interpolate({
                                inputRange: yDirectionInput ?? [0, 1],
                                outputRange: yDirectionOutput ?? [40, 0],
                            }),
                        },
                    ],
                    opacity: animatedValue.interpolate({
                        inputRange: opacityInputRange ?? [0, 1],
                        outputRange: opacityOutputRange ?? [0, 1],
                    }),
                },
                style,
            ]}>
            {children}
        </Animated.View>
    );
};

export const AnimatedTranslateWrapper = React.memo(AnimatedTranslateWrapperComponent);
