import * as React from "react";
import { Platform } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { Snackbar, SpacingValue, Text } from "@swiggy-private/rn-dls";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { useSelectScreen } from "@swiggy-private/rn-adaptive-layout";

import { useStoreDeletedErrorSnackbarHandler } from "@minis-consumer/hooks/use-store-deleted-snack-handler";
import { useStoreReset } from "@minis-consumer/hooks/use-store-reset";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";

const CTA_TEXT = "OK, GOT IT";
const DESCRIPTION_TEXT =
    "Whoops! This store isn't available anymore. It may have been deleted by the Seller";

export const StoreDeleteErrorSnackbarProvider: React.FC<{
    addBottomMargin?: boolean;
    addBottomPadding?: boolean;
}> = ({ addBottomMargin, addBottomPadding }) => {
    const [isOpen, handler] = useStoreDeletedErrorSnackbarHandler();

    const insets = useSafeAreaInsets();

    const store = useStoreInfo(false);
    const reset = useStoreReset(store?.storeId ?? "");

    const onPress = React.useCallback(() => {
        handler(false);
        reset();
    }, [handler, reset]);

    const [maxWidth, marginBottom, paddingBottom] = useSelectScreen({
        lg: [600, 40],
        md: [600, addBottomMargin ? 40 : 0],
        default: [
            "100%",
            0,
            Platform.OS !== "web" && addBottomPadding
                ? insets.bottom
                : SpacingValue["space-medium"],
        ],
    });

    const snackbarStyle = {
        zIndex: 2,
        maxWidth,
        marginBottom,
        paddingBottom,
        marginHorizontal: "auto",
    };

    if (!isOpen) {
        return null;
    }

    return (
        <Snackbar
            style={snackbarStyle}
            isAutoDismiss={false}
            isActive={isOpen}
            accessoryLeft={<SvgIcon icon="Warning" color="color-critical" />}
            touchableRight={<Text onPress={onPress}>{CTA_TEXT}</Text>}>
            {DESCRIPTION_TEXT}
        </Snackbar>
    );
};
