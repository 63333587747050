import { ApiError, API_SUCCESS_STATUS_CODE } from "@swiggy-private/connect-api-core";
import { Endpoint } from "@swiggy-private/http-client";

import { getApiClient } from "../../includes/api";
import { ConfigInfoEndpoint } from "../endpoint";
import type { ConfigInfo } from "../../interfaces/config-info";

export interface FetchCouponParams {
    storeId: string;
    addressId?: string;
}

export const fetchConfigInfoApi = async (): Promise<ConfigInfo> => {
    const endpoint = Endpoint.from(ConfigInfoEndpoint);
    const response = await getApiClient().get<ConfigInfo>({ endpoint });

    if (response.statusCode === API_SUCCESS_STATUS_CODE && response.data) {
        return { ...response.data };
    }

    return Promise.reject(
        new ApiError(response.statusMessage || "Could not fetch config", response),
    );
};
