import { ApiError, API_SUCCESS_STATUS_CODE } from "@swiggy-private/connect-api-core";
import { Endpoint } from "@swiggy-private/http-client";

import { getApiClient } from "@minis-consumer/includes/api";
import { ProductSlotsData } from "@minis-consumer/interfaces/catalog";

import { ScheduledMeetingSlotsEndpoint, VacationDaysEndpoint } from "../endpoint";

export interface FetchVacationDaysResponse {
    unavailableDays: number[];
}

export const fetchVacationDays = async ({
    storeId,
}: {
    storeId: string;
}): Promise<FetchVacationDaysResponse> => {
    const endpoint = Endpoint.from(VacationDaysEndpoint, { storeId });
    const response = await getApiClient().get<FetchVacationDaysResponse>({
        endpoint,
    });

    if (response.statusCode === API_SUCCESS_STATUS_CODE && response.data) {
        return response.data;
    }

    return Promise.reject(
        new ApiError(response.statusMessage || "Could not fetch the vacation days", response),
    );
};

export interface FetchScheduledMeetingSlotsParams {
    storeId: string;
    productId: string;
    date: number; // epoch of day at 12 am
    custom?: boolean;
}

export interface DateSlot {
    date: number;
    slots: {
        start: number;
        end: number;
    }[];
}

export interface FetchScheduledMeetingSlotsResponse extends ProductSlotsData {
    dateSlots: DateSlot[]; //null for old & empty array for booked
}

export const fetchScheduledMeetingSlots = async ({
    storeId,
    productId,
    date,
    custom,
}: FetchScheduledMeetingSlotsParams): Promise<FetchScheduledMeetingSlotsResponse> => {
    const endpoint = Endpoint.from(ScheduledMeetingSlotsEndpoint, { storeId });
    const response = await getApiClient().get<FetchScheduledMeetingSlotsResponse>({
        endpoint,
        queryParams: {
            productId,
            date: date.toString(),
            custom: (custom ?? "").toString(),
        },
    });

    if (response.statusCode === API_SUCCESS_STATUS_CODE && response.data) {
        return response.data;
    }

    return Promise.reject(
        new ApiError(response.statusMessage || "Could not fetch the meeting slots", response),
    );
};
