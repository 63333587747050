import * as React from "react";
import { StyleSheet, ViewProps } from "react-native";

import { SpacingValue, Text } from "@swiggy-private/rn-dls";
import { Box } from "@swiggy-private/rn-adaptive-layout";
import { IEnquireConfirmMessage } from "@swiggy-private/connect-chat-commons";
import { format } from "@swiggy-private/common-helpers";

import { TEMPLATE_MSG } from "../../constants";

interface EnquireConfirmMessageProps {
    message: IEnquireConfirmMessage;
    isPublisher: boolean;
    style?: ViewProps["style"];
}

const EnquireConfirmMessageComponent: React.FC<EnquireConfirmMessageProps> = ({ message }) => {
    const storeSlug = message.payload.storeSlug;

    if (!storeSlug) {
        return null;
    }
    const confirmMessage = format(TEMPLATE_MSG, storeSlug);

    return (
        <Box style={styles.container}>
            <Box mt={SpacingValue["space-x-small"]}>
                <Text category="b2" weight="regular" color="high">
                    {confirmMessage}
                </Text>
            </Box>
        </Box>
    );
};

export const EnquireConfirmMessage = React.memo(EnquireConfirmMessageComponent);

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        width: "100%",
        minWidth: 100,
        maxWidth: "100%",
        overflow: "hidden",
    },
});
