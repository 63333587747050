import * as React from "react";
import { Pressable, StyleSheet, View } from "react-native";

import { IK_QUALITY_FORMAT, StoreMedia } from "@swiggy-private/react-native-ui";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";

import { RecommendedBadge } from "@minis-consumer/components/catalog/product-badge";
import { ProductPrice } from "@minis-consumer/components/catalog/product-price";
import { ProductVariantsSizeLabel } from "@minis-consumer/components/variants";
import { getProductMainImageUrl } from "@minis-consumer/helpers/catalog";
import { getProductFallbackImages } from "@minis-consumer/helpers/product-fallback-images";

import { useLocalCartItem } from "@minis-consumer/hooks/use-cart";
import type { ProductBadge } from "@minis-consumer/interfaces/catalog";
import { useGetProductCtaProps } from "@minis-consumer/components/product-cta/hooks/use-get-cta-actions";
import { ProductCta } from "@minis-consumer/components/product-cta";

import type { SectionItem } from "../../product-section-list/types";
import { DigitalAssetInfo } from "../../digital-asset.info";
import { CommunicationChannel } from "../../communication-channel";
import { ProductDuration } from "../../product-duration";

interface ProductSectionItemProps {
    item: SectionItem;
    onProductPress: VoidFunction;
    onItemAddCallback: VoidFunction;
    index: number;
    badges?: ProductBadge[];
    showAddToCart?: boolean;
    imageSize?: number;
    hideOriginalPrice?: boolean;
    hideBadge?: boolean;
    hideVariants?: boolean;
    renderCustomCTA?: () => React.ReactNode;
    sfWidget?: string;
}

const ProductLargeCardComponent: React.FC<ProductSectionItemProps> = React.memo(
    ({
        item: product,
        showAddToCart,
        imageSize = PRODUCT_IMAGE_SIZE,
        hideOriginalPrice,
        hideBadge,
        hideVariants,
        onProductPress,
        onItemAddCallback,
        renderCustomCTA,
        sfWidget,
    }) => {
        const { value: theme } = useTheme();

        const {
            id,
            name,
            price,
            badges,
            storeId,
            discountPercent,
            discountedPrice,
            variantDetails,
        } = product;

        const [, increment, decrement] = useLocalCartItem({
            storeId: storeId,
            itemId: id,
            productType: product.productType ?? "PHYSICAL",
        });

        const productMainImageUrl = getProductMainImageUrl(
            product,
            {
                width: PRODUCT_IMAGE_SIZE,
                height: PRODUCT_IMAGE_SIZE,
                qualityAuto: IK_QUALITY_FORMAT.GOOD,
            },
            true,
        );

        const imageStyle = React.useMemo(
            () =>
                StyleSheet.flatten([
                    styles.image,
                    {
                        backgroundColor: productMainImageUrl
                            ? theme["color-basic-0"]
                            : theme["color-secondary-light"],
                    },
                    {
                        height: imageSize,
                        width: imageSize,
                    },
                ]),
            [productMainImageUrl, theme, imageSize],
        );

        const onItemAdd = React.useCallback((): void => {
            price && increment();

            onItemAddCallback();
        }, [price, increment, onItemAddCallback]);

        const separatorStyle = {
            ...styles.separator,
            borderColor: theme["color-basic-45"],
        };

        const productCtaActionsAndStyles = useGetProductCtaProps({
            productId: product.id,
            storeId,
            buttonProps: {
                style: styles.addBtn,
                elevation: 0,
            },
            sfWidget,
            productActionCtaName: `${sfWidget?.toLowerCase()}-product-action-cta`,
            onAdd: onItemAdd,
            onRemoveOrDecrement: decrement,
        });

        const productImgFallback = getProductFallbackImages(product.productType);

        return (
            <Pressable
                testID="product-large-card"
                onPress={onProductPress}
                style={styles.contanier}
                accessible
                accessibilityLabel="Open product">
                <Box direction="row" flex={1} mv={SpacingValue["space-medium"]}>
                    <Box>
                        <View
                            style={[
                                styles.imageContainer,
                                { borderColor: theme["color-basic-15"] },
                            ]}>
                            <StoreMedia
                                onPress={onProductPress}
                                isImageKitEnabled
                                disableVideoPlay
                                mediaId={productMainImageUrl ?? productImgFallback}
                                height={imageSize}
                                width={imageSize}
                                style={[styles.image, imageStyle]}
                                resizeMode="cover"
                                showLoader
                                testID="product-large-card-image"
                            />
                        </View>

                        {!hideBadge ? <RecommendedBadge badges={badges} /> : null}
                    </Box>
                    <Box ml={SpacingValue["space-medium"]} flex={1}>
                        <Stack
                            flex={1}
                            direction="column"
                            spacing={SpacingValue["space-small"]}
                            justifyContent="space-between">
                            <Stack spacing={SpacingValue["space-xxx-small"]}>
                                <Text category="b1" color="high" weight="bold" numberOfLines={2}>
                                    {name}
                                </Text>
                                {!hideVariants && (
                                    <ProductVariantsSizeLabel
                                        options={variantDetails?.options || []}
                                    />
                                )}

                                {product.digitalAssetInfo && product.productType === "DIGITAL" ? (
                                    <DigitalAssetInfo {...product.digitalAssetInfo} />
                                ) : null}

                                {product.productType === "APPOINTMENT" ||
                                product.productType === "PLAN" ? (
                                    <Stack direction="row">
                                        <CommunicationChannel
                                            channels={product.serviceDeliveryChannels}
                                            showFirst
                                        />
                                        {product.serviceDeliveryChannels?.length ?? 0 > 0 ? (
                                            <Box
                                                mh={SpacingValue["space-xx-small"]}
                                                style={separatorStyle}
                                            />
                                        ) : null}

                                        {product.productDuration ? (
                                            <ProductDuration {...product.productDuration} />
                                        ) : null}
                                    </Stack>
                                ) : null}
                            </Stack>

                            <Stack spacing={SpacingValue["space-small"]}>
                                <ProductPrice
                                    price={price}
                                    discountedPrice={discountedPrice}
                                    discountPercent={discountPercent}
                                    hideOriginalPrice={hideOriginalPrice}
                                />

                                {showAddToCart ? (
                                    <ProductCta
                                        {...productCtaActionsAndStyles}
                                        productId={product.id}
                                        storeId={storeId}
                                        showVariants
                                        price={product.price}
                                        type={product.productType}
                                    />
                                ) : null}
                                {renderCustomCTA?.()}
                            </Stack>
                        </Stack>
                    </Box>
                </Box>
            </Pressable>
        );
    },
);

if (__DEV__) {
    ProductLargeCardComponent.displayName = "ProductLargeCardComponent";
}

export const ProductLargeCard = React.memo(ProductLargeCardComponent);

const PRODUCT_IMAGE_SIZE = 165;
const ADD_BUTTON_HEIGHT = 44;

const styles = StyleSheet.create({
    contanier: {
        flex: 1,
    },
    addBtn: {
        height: ADD_BUTTON_HEIGHT,
        minHeight: ADD_BUTTON_HEIGHT,
        marginTop: "auto",
    },
    image: {
        borderRadius: 0,
        height: PRODUCT_IMAGE_SIZE,
        width: PRODUCT_IMAGE_SIZE,
    },
    imageContainer: {
        borderRadius: 12,
        borderWidth: 1,
        overflow: "hidden",
        justifyContent: "center",
    },
    separator: {
        borderWidth: 0.5,
    },
});
