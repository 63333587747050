import { DimensionValue, PixelRatio } from "react-native";

import {
    CloudinaryTransformation,
    getCloudinaryImageUrl,
    getCloudinaryTransformation,
    getCloudinaryVideoBaseUrl,
    getImageFormatForPlatform,
    isCloudinaryUrl,
    getImageKitImageUrl,
    ImageKitTransformation,
    isImageKitUrl,
    getIKImageFormatForPlatform,
    getImageKitVideoBaseUrl,
    getImageKitTransformation,
    formatImageId,
    getImageKitImageBaseUrl,
    getImagePixelRatio,
    checkVideoExtension,
    isUrlVideo,
    IK_QUALITY_FORMAT,
} from "@swiggy-private/react-native-ui";

import {
    SWIGGY_CLOUDINARY_IMAGE_BASE_URL,
    SwiggyCloudinary,
    MinisCloudinary,
} from "@minis-consumer/constants/cloudinary";

import { AppConfig } from "../config";

export interface GetMediaUrlOpts {
    width?: DimensionValue | string | number;
    height?: DimensionValue | string | number;
    crop?: CloudinaryTransformation["crop"];
    format?: CloudinaryTransformation["format"];
    quality?: CloudinaryTransformation["quality"];
    qualityAuto?: CloudinaryTransformation["qualityAuto"];
    color?: CloudinaryTransformation["color"];
}

// Compute power of two greater than or equal to `n`
function findNextPowerOf2(n: number): number {
    // decrement `n` (to handle the case when `n` itself
    // is a power of 2)
    n = n - 1;

    // initialize result by 2
    let k = 2;

    // double `k` and divide `n` in half till it becomes 0
    // eslint-disable-next-line no-bitwise
    while ((n >>= 1)) {
        // eslint-disable-next-line no-bitwise
        k = k << 1; // double `k`
    }

    return k;
}

const bucketMediaSize = (width?: number, height?: number): { width?: number; height?: number } => {
    if (!width && !height) {
        return {};
    }

    if (height && !width) {
        height = findNextPowerOf2(height);
    } else if (width && !height) {
        width = findNextPowerOf2(width);
    } else if (width && height && width === height) {
        width = height = findNextPowerOf2(width);
    }

    return {
        width,
        height,
    };
};

export const getMediaUrl = (
    mediaId: string,
    opts: Partial<GetMediaUrlOpts> = {},
    isImageKitEnabled?: boolean,
): string => {
    if (!mediaId) {
        return mediaId;
    }
    if (isImageKitEnabled) {
        return isUrlVideo(mediaId)
            ? getImageKitVideoUrl(mediaId, opts as Partial<GetVideoUrlOpts>)
            : getImageKitMediaUrl(mediaId, opts);
    }

    if ((mediaId || "").startsWith("https://")) {
        return mediaId;
    }

    // media can come from s3 as well, hence we need to check it.
    const isMinisMedia = (mediaId || "").startsWith("IMAGE/");
    const baseUrl = isMinisMedia ? AppConfig.MEDIA_BASE_URL : SWIGGY_CLOUDINARY_IMAGE_BASE_URL;

    if (isCloudinaryUrl(baseUrl)) {
        const size = bucketMediaSize(
            opts.width ? PixelRatio.getPixelSizeForLayoutSize(Number(opts.width)) : undefined,
            opts.height ? PixelRatio.getPixelSizeForLayoutSize(Number(opts.height)) : undefined,
        );

        const transformations: Partial<CloudinaryTransformation> = {
            ...size,
            format:
                // without size params, we can't specify format.
                size.height || size.width ? opts.format ?? getImageFormatForPlatform() : undefined,
            crop: opts.crop ?? "fit",
            qualityAuto: opts.qualityAuto ?? true,
            quality: opts.quality,
            color: opts.color,
        };

        const cloudinary = isMinisMedia ? MinisCloudinary : SwiggyCloudinary;

        const url = getCloudinaryImageUrl({
            transformations,
            cloudName: cloudinary.cloudName,
            folderName: cloudinary.folderName,
            id: mediaId,
        });

        return url;
    }

    return `${baseUrl}/${mediaId}`;
};

export interface GetVideoUrlOpts {
    width?: number;
    height?: number;
    crop?: CloudinaryTransformation["crop"];
    preview?: CloudinaryTransformation["preview"];
    quality?: CloudinaryTransformation["quality"];
    qualityAuto?: CloudinaryTransformation["qualityAuto"];
}

export const getVideoUrl = (
    mediaId: string,
    opts: Partial<GetVideoUrlOpts> = {},
    isImageKitEnabled?: boolean,
): string => {
    if (isImageKitEnabled) {
        return getImageKitVideoUrl(mediaId, opts);
    }

    const baseUrl = getCloudinaryVideoBaseUrl({
        cloudName: SwiggyCloudinary.cloudName,
        baseUrl: SwiggyCloudinary.baseUrl,
    });

    let transformationsArr: Partial<CloudinaryTransformation>[] = [
        {
            width: opts.width ? Math.ceil(Number(opts.width)) : undefined,
            height: opts.height ? Math.ceil(Number(opts.height)) : undefined,
            crop: opts.crop,
            quality: opts.quality,
            dpr: PixelRatio.get(),
            qualityAuto: opts.qualityAuto ?? "good",
        },
    ];

    if (opts.preview) {
        transformationsArr = [{ preview: opts.preview }, ...transformationsArr];
    }

    const transformations = getCloudinaryTransformation(transformationsArr);
    const url = `${baseUrl}/${transformations}/${mediaId}`;

    return url;
};

export const isCloudinaryAvailable = (): boolean => {
    const baseUrl = AppConfig.MEDIA_BASE_URL;
    if (baseUrl == null) {
        return false;
    }

    return isCloudinaryUrl(baseUrl);
};

export const getImgVersion = (): string => {
    const date = new Date();
    return date.getDate().toString() + date.getMonth().toString();
};

export const getImageKitMediaUrl = (
    mediaId: string,
    opts: Partial<GetMediaUrlOpts> = {},
): string => {
    const formattedMediaId = formatImageId(mediaId);

    if ((formattedMediaId || "").startsWith("https://")) {
        return formattedMediaId;
    }

    const baseUrl = getImageKitImageBaseUrl({
        baseUrl: AppConfig.IMAGEKIT_MEDIA_BASE_URL,
        cloudName: AppConfig.IMAGEKIT_CLOUDNAME,
    });

    const dpr = getImagePixelRatio(true);
    if (isImageKitUrl(baseUrl)) {
        const size = bucketMediaSize(
            opts.width ? Math.floor(Number(opts.width) * dpr) : undefined,
            opts.height ? Math.floor(Number(opts.height) * dpr) : undefined,
        );

        const transformations: Partial<ImageKitTransformation> = {
            ...size,
            format:
                // without size params, we can't specify format.
                size.height || size.width
                    ? opts.format ?? getIKImageFormatForPlatform()
                    : undefined,
            crop: opts.crop ?? "fit",
            qualityAuto: opts.qualityAuto ?? true,
            quality: opts.quality,
            color: opts.color,
        };

        const url = getImageKitImageUrl({
            transformations,
            cloudBaseUrl: AppConfig.IMAGEKIT_MEDIA_BASE_URL,
            cloudName: AppConfig.IMAGEKIT_CLOUDNAME,
            id: formattedMediaId,
        });

        return url;
    }

    return `${baseUrl}/${formattedMediaId}`;
};

export const getImageKitVideoUrl = (
    mediaId: string,
    opts: Partial<GetVideoUrlOpts> = {},
): string => {
    const formattedMediaId = formatImageId(mediaId);
    const finalMediaId = checkVideoExtension(formattedMediaId);

    const baseUrl = getImageKitVideoBaseUrl({
        baseUrl: AppConfig.IMAGEKIT_MEDIA_BASE_URL,
        cloudName: AppConfig.IMAGEKIT_CLOUDNAME,
    });

    const size = bucketMediaSize(
        opts.width ? Math.floor(Number(opts.width)) : undefined,
        opts.height ? Math.floor(Number(opts.height)) : undefined,
    );

    let transformationsArr: Partial<ImageKitTransformation>[] = [
        {
            ...size,
            // * maintain video aspect-ratio based on its width
            height: undefined,
            crop: opts.crop,
            quality: opts.quality,
            qualityAuto: opts.qualityAuto ?? IK_QUALITY_FORMAT.GOOD,
        },
    ];

    if (opts.preview) {
        transformationsArr = [{ preview: opts.preview }, ...transformationsArr];
    }

    const transformations = getImageKitTransformation(transformationsArr);
    const url = `${baseUrl}/${transformations}/${finalMediaId}`;

    return url;
};
