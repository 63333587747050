import { AppConfig } from "@minis-consumer/config";

export const API_STORE_PATH = "/api/v1/stores";
export const API_STORE_PATH_V2 = "/api/v2/stores";

export const API_ORDERS_PATH = "/api/v1/orders";
export const API_ORDERS_PATH_V2 = "/api/v2/orders";

export const getStoreHost = (): string => AppConfig.CONSUMER_SERVICE_HOST;
export const getAuthHost = (): string => AppConfig.CONSUMER_SERVICE_HOST;
export const getUserHost = (): string => AppConfig.CONSUMER_SERVICE_HOST;
export const getHomeHost = (): string => AppConfig.CONSUMER_SERVICE_HOST;
export const getMediaHost = (): string => AppConfig.IMAGEKIT_MEDIA_BASE_URL;
export const getMediaFolder = (): string => AppConfig.IMAGEKIT_CLOUDNAME;

export const HomeEndpoint = getHomeHost() + "/api/v1/home";
export const StoreEndpoint = getStoreHost() + API_STORE_PATH;
export const StoreEndpointV2 = getStoreHost() + API_STORE_PATH_V2;
export const WidgetsPageEndpoint =
    `${getUserHost()}/api/v1/home/widgets/data/{%%widgetId%%}` as const;

export const UserDetailsEndpoint = `${getUserHost()}/api/v1/user/details`;

export const RecentMinisEndpoint = HomeEndpoint + "/stores/visited";
export const BulkFollowedStoresEndpoint = HomeEndpoint + "/stores/followed";
export const InstagramEndpoint = `${StoreEndpoint}/{%%storeId%%}/social/instagram` as const;

export const CartEndpoint = `${StoreEndpoint}/{%%storeId%%}/cart` as const;
export const CatalogViewEndpoint = `${StoreEndpoint}/{%%storeSlug%%}/catalog` as const;
export const ProductViewEndpoint =
    `${StoreEndpoint}/{%%storeSlug%%}/catalog/products/{%%productId%%}` as const;
export const CollectionEndpoint = `${HomeEndpoint}/collection/{%%collectionId%%}` as const;

export const StoryLinksEndpoint = `${StoreEndpoint}/{%%storeSlug%%}/links` as const;

export const AddressEndpoint = `${getStoreHost()}/api/v1/users/addresses`;
export const AddressListEndpoint = `${StoreEndpoint}/{%%storeId%%}/user-addresses` as const;

export const DeliveryEtaEndpoint = `${StoreEndpoint}/{%%storeId%%}/serviceability` as const;

export const PaymentEndpoint = `${getStoreHost()}/api/v1/payments`;
export const OrderEndpoint = `${getStoreHost()}${API_ORDERS_PATH}`;
export const OrderV2Endpoint = `${getStoreHost()}${API_ORDERS_PATH_V2}`;

export const CouponEndpoint = `${StoreEndpoint}/{%%storeId%%}/cart/coupons` as const;

export const PresearchEndpoint = `${getStoreHost()}/api/v1/presearch`;
export const SearchEndpoint = `${getStoreHost()}/api/v1/search`;
export const SearchSuggestionsEndpoint = `${SearchEndpoint}/suggest`;

export const ScreenWidgetsEndpoint = `${getStoreHost()}/api/v1/page`;

export const ClearCartFeedbackOptionsEndpoint = `${getMediaHost()}/${getMediaFolder()}/v${Date.now()}/static-assets/clear-cart-feedback-options.json`;

export const CartListEndpoint = `${getStoreHost()}/api/v1/carts`;
export const CartClearEndpoint = `${CartListEndpoint}/clear`;

export const FeedbackSubmitEndpoint = `${getStoreHost()}/api/v1/user/feedback`;

/** TODO - update when BE is finalised */
export const ConfigInfoEndpoint = `${getStoreHost()}/api/v1/config`;

export const SlotApiEndpoint =
    `${StoreEndpoint}/{%%storeId%%}/appointments/available-slots` as const;

export const VacationDaysEndpoint = `${StoreEndpoint}/{%%storeId%%}/settings/appointments` as const;
export const ScheduledMeetingSlotsEndpoint = SlotApiEndpoint; // this will change later
