import { useMemo } from "react";

import { ThemeRegistry } from "@swiggy-private/rn-dls";

import { addTheme } from "@minis-consumer/includes/theme";
import { checkColor } from "@minis-consumer/helpers/color";

let currentBrandColor: string | null;

/*
 * Apply theme to Store or a paricular independant module
 */
export const useApplyStoreTheme = (slug: string, brandColor?: string): void => {
    const pColor = useMemo(() => {
        return brandColor ? checkColor(brandColor) : undefined;
    }, [brandColor]);

    if (!slug) {
        return;
    }
    const theme = ThemeRegistry.get(slug);

    if (!theme || (brandColor && currentBrandColor !== brandColor)) {
        addTheme({ primaryColor: pColor }, slug);
        currentBrandColor = brandColor ?? null;
    }
};
