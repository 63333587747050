import * as React from "react";
import { Endpoint } from "@rest-hooks/endpoint";
import { useController } from "@rest-hooks/react";

import ChatSdk, { SdkConversation } from "@swiggy-private/connect-chat-sdk";
import { useChatDispatch, useChatSdk } from "@swiggy-private/connect-chat-ui";

import { Logger } from "@minis-consumer/includes/logger";

const cache: Record<string, string | null> = {};

interface Params {
    storeId?: string;
    conversationId?: string;
    /**
     * This hook is being consumed by multiple components but
     * for some of them we need fresh blocked information to stop
     * the message from being sent. */
    ignoreCache?: boolean;
}

interface StartConversationParams {
    sdk: ChatSdk;
    storeId: string;
}

const ChatConversation = new Endpoint(
    ({ sdk, storeId }: StartConversationParams) =>
        sdk.createConversation({
            uuid: storeId,
        }),
    {
        key: ({ storeId }) => "chat_conversation_" + storeId,
    },
);

export const useCreateChatConversation = ({
    conversationId: cId,
    storeId,
    ignoreCache,
}: Params): {
    conversationId: string | null;
    blockedInfo?: SdkConversation["blockedInfo"];
} => {
    const sdk = useChatSdk();

    const [conversationId, setConversationId] = React.useState<string | null>(
        cId ? cId : cache[storeId ?? "0"],
    );
    const [blockedInfo, setBlockedInfo] = React.useState<SdkConversation["blockedInfo"]>();

    const chatDispatch = useChatDispatch();
    const controller = React.useRef<AbortController>();
    const { fetch } = useController();

    React.useEffect(() => {
        if ((conversationId && !ignoreCache) || !storeId || !sdk) {
            return;
        }

        controller.current?.abort();
        controller.current = new AbortController();
        const signal = controller.current.signal;

        fetch(ChatConversation, { sdk: sdk as ChatSdk, storeId })
            .then((c) => {
                if (!signal.aborted) {
                    cache[storeId] = c.id;
                    setConversationId(c.id);

                    /** need blockedInfo only if the feature is enabled */
                    const bInfo = c.chatBlockFeatureEnabled ? c.blockedInfo : undefined;
                    setBlockedInfo(bInfo);

                    chatDispatch({
                        type: "MULTI_DISPATCH",
                        payload: [
                            {
                                type: "ADD_CONVERSATION_ACTION",
                                payload: {
                                    conversations: [c],
                                },
                            },
                            {
                                type: "ADD_USER_ACTION",
                                payload: {
                                    users: c.participants,
                                },
                            },
                        ],
                    });

                    sdk.subscribe({ conversationIds: [c.id] });
                }
            })
            .catch((err) => {
                setConversationId("");

                if (!signal.aborted) {
                    Logger.recordError(err);
                }
            });

        return () => {
            controller.current?.abort();
        };
    }, [chatDispatch, conversationId, fetch, sdk, storeId, ignoreCache]);

    return { conversationId, blockedInfo };
};
