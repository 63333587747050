import React from "react";
import {
    Pressable,
    StyleProp,
    TextInput,
    ViewStyle,
    StyleSheet,
    Platform,
    ColorValue,
    PlatformOSType,
    TextStyle,
} from "react-native";

import { Input, SpacingValue, Surface, useTheme } from "@swiggy-private/rn-dls";
import { useMount, useMountedRef } from "@swiggy-private/react-hooks";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";

import { SearchResultsParams } from "@minis-consumer/api/search";
import { isValidQueryForSearch } from "@minis-consumer/routes/search/helpers";

import { AnimatedAccessory } from "./components/animated-accessory";

const ANIMATION_DURATION = 300;
const ANIMATION_DELAY = 50;

export type SearchInputProps = {
    onChangeText: (text: string) => void;
    searchText: string;
    clearSearch: () => void;
    onBackPress: () => void;
    placeholderText: string;

    style?: StyleProp<ViewStyle>;
    textStyle?: StyleProp<TextStyle>;
    inputStyle?: StyleProp<ViewStyle>;
    placeholderTextColor?: ColorValue;
    onSubmit?: (k: string, type?: SearchResultsParams["queryType"]) => void;
};

const SEARCH_ALL = "search_all";
const SearchInputComponent: React.FC<SearchInputProps> = ({
    onChangeText,
    clearSearch,
    searchText,
    style,
    onBackPress,
    textStyle,
    placeholderTextColor,
    placeholderText,
    onSubmit,
    inputStyle,
}) => {
    const mountedRef = useMountedRef();
    const { value: theme } = useTheme();

    const [showElevation, setShowElevation] = React.useState(false);
    const isValidQuery = React.useMemo(() => isValidQueryForSearch(searchText), [searchText]);

    const [textColor, setTextColor] = React.useState<ColorValue>(theme["color-basic-100"]);

    const inputRef = React.useRef<TextInput>(null);

    const elevation = React.useCallback(
        (platform: PlatformOSType) => (showElevation && Platform.OS === platform ? 4 : undefined),
        [showElevation],
    );

    const clearSearchText = React.useCallback(() => {
        if (!mountedRef.current) {
            return;
        }

        inputRef.current?.clear();
        clearSearch();
        inputRef.current?.focus();
    }, [inputRef, clearSearch, mountedRef]);

    const onPressReturn = React.useCallback(() => {
        if (!isValidQuery) {
            return;
        }

        onSubmit?.(searchText, SEARCH_ALL);
    }, [isValidQuery, onSubmit, searchText]);

    const onFocus = React.useCallback(() => {
        setShowElevation(true);
        setTextColor(theme["color-basic-100"]);
    }, [theme]);

    const onBlur = React.useCallback(() => {
        setShowElevation(false);
        setTextColor(theme["color-basic-60"]);
    }, [theme]);

    useMount(() => {
        const timer = setTimeout(() => {
            inputRef.current?.focus(); // handles for android
            setShowElevation(true);
        }, ANIMATION_DURATION);

        return () => clearTimeout(timer);
    });

    return (
        <Surface style={[styles.surface, { elevation: elevation("ios") }, style]}>
            <Input
                testID="minis-search-screen-input"
                ref={inputRef}
                placeholder={placeholderText}
                value={searchText}
                onChangeText={onChangeText}
                autoFocus
                accessoryLeft={<AccessoryLeft onPress={onBackPress} />}
                accessoryRight={
                    <AccessoryRight onPress={clearSearchText} canShow={searchText.length > 0} />
                }
                returnKeyLabel="search"
                returnKeyType="search"
                placeholderTextColor={placeholderTextColor}
                onSubmitEditing={onPressReturn}
                allowFontScaling={false}
                textStyle={[
                    textStyle,
                    {
                        color: textColor,
                    },
                ]}
                style={[
                    {
                        borderColor: showElevation
                            ? theme["color-basic-30"]
                            : theme["color-basic-15"],
                        elevation: elevation("android"),
                        paddingHorizontal: SpacingValue["space-small"],
                    },
                    inputStyle,
                ]}
                onFocus={onFocus}
                onBlur={onBlur}
            />
        </Surface>
    );
};

type AccessoryRightProps = {
    onPress: () => void;
    canShow: boolean;
};

const AccessoryRight: React.FC<AccessoryRightProps> = ({ onPress, canShow }) => {
    if (!canShow) {
        return null;
    }

    return (
        <Pressable
            style={[styles.center, { marginLeft: SpacingValue["space-x-small"] }]}
            accessible
            accessibilityLabel="Clear Search"
            onPress={onPress}
            testID="minis-search-clear-btn"
            hitSlop={{ top: 16, bottom: 16, left: 16, right: 16 }}>
            <SvgIcon icon="Close" color="color-basic-45" height={18} width={18} />
        </Pressable>
    );
};

const AccessoryLeft: React.FC<{ onPress: () => void }> = ({ onPress }) => {
    return (
        <Pressable
            testID="minis-search-back-btn"
            onPress={onPress}
            hitSlop={{ top: 16, bottom: 16, left: 16, right: 16 }}
            style={styles.center}>
            <AnimatedAccessory durationInMs={ANIMATION_DURATION} delayInMs={ANIMATION_DELAY} />
        </Pressable>
    );
};

export const SearchInput = React.memo(SearchInputComponent);

const styles = StyleSheet.create({
    surface: {
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "transparent",
        paddingHorizontal: SpacingValue["space-medium"],
    },
    center: {
        justifyContent: "center",
        alignItems: "center",
    },
});
