import React, { useState } from "react";
import { ChatRenderer, ChatRenderContextProvider } from "@swiggy-private/connect-chat-ui";

import { ChatFooter } from "../components/footer";
import { ConversationCustomMessage } from "../components/custom-message";
import { ChatConversationEmptyComponent } from "../components/empty-chat-conversation";
import { ChatConversationHeader } from "../components/header";
import { ChatRenderMessage } from "../components/render-message";
import { ConversationListItemTitleIcon } from "../components/conversation-list-item-title-icon";
import { ChatConversationNudge } from "../components/conversation-nudge";
import { MessageFooterCta } from "../components/custom-message-footer";

class ChatConversationRenderer implements ChatRenderer {
    renderConversationFooter = ChatFooter;
    renderCustomMessage = ConversationCustomMessage;
    renderConversationEmptyContent = ChatConversationEmptyComponent;
    renderConversationHeader = ChatConversationHeader;
    renderMessage = ChatRenderMessage;
    renderConversationListItemTitleIcon = ConversationListItemTitleIcon;
    renderConversationNudge = ChatConversationNudge;
    /** Add in the end */
    renderFooterCta = MessageFooterCta;
}

export const RenderContextProvider: React.FC<React.PropsWithChildren> = (props) => {
    const [value] = useState(() => new ChatConversationRenderer());
    return <ChatRenderContextProvider value={value} {...props} />;
};
