import React from "react";

import { TextProps, TooltipPlacement } from "@swiggy-private/rn-dls";

import { CartBill } from "@minis-consumer/interfaces/cart";
import { Coupon } from "@minis-consumer/interfaces/coupon";

import { BillItem } from "../bill-item";
import { DiscountTooltip } from "./components/tooltip";

interface OfferDiscountComponentProps {
    finalAmount: CartBill["itemTotal"];
    itemDiscount: CartBill["itemTotal"];
    couponDiscount: CartBill["couponDiscount"];

    appliedCoupon?: Coupon["code"];
    titleColor?: TextProps["color"];
}

const OfferDiscountComponent: React.FC<OfferDiscountComponentProps> = (props) => {
    return (
        <BillItem
            title="Discount"
            finalAmount={-props.finalAmount}
            titleColor={props.titleColor}
            tooltip={<DiscountTooltip {...props} />}
            tooltipPlacement={TooltipPlacement.RIGHT_START}
        />
    );
};

export const OfferDiscount = React.memo(OfferDiscountComponent);
