import React from "react";

export type TrackAsyncTasksContextType = {
    promises: Record<string, Promise<unknown>[]>;
    trackAsyncTasksPromises: (promises: Promise<unknown>[], promiseType: string) => void;
    clearAsyncTasksPromise: (promiseType: string) => void;
};

export const TrackAsyncTasksContext = React.createContext<TrackAsyncTasksContextType>({
    promises: {},
    trackAsyncTasksPromises: () => undefined,
    clearAsyncTasksPromise: () => undefined,
});

/**
 * The `ApiPromiseProvider` component enables tracking of API promises, ensuring they are completed
 * before rendering dependent elements or screens throughout the app. It provides two functions:
 * - `trackApiPromises`: Adds promises and their associated types to the context for tracking
 * - `clearApiPromise`: Removes a tracked promise type that is no longer needed
 * The context allows accessing the currently tracked promises
 */
export const TrackAsyncTasksContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [promises, setPromises] = React.useState<Record<string, Promise<unknown>[]>>({});

    const trackAsyncTasksPromises = React.useCallback(
        (promisesArr: Promise<unknown>[], promiseType: string): void => {
            setPromises((prevStatus) => {
                const updatedStatus: Record<string, Promise<unknown>[]> = {
                    ...prevStatus,
                };

                if (!updatedStatus[promiseType]) {
                    updatedStatus[promiseType] = [...promisesArr];
                } else {
                    updatedStatus[promiseType] = [...updatedStatus[promiseType], ...promisesArr];
                }

                return updatedStatus;
            });
        },
        [],
    );

    const clearAsyncTasksPromise = React.useCallback(
        (promiseType: string): void => {
            if (!promises?.[promiseType]?.length) {
                return;
            }

            setPromises((prevStatus) => {
                const updatedStatus: Record<string, Promise<unknown>[]> = {
                    ...prevStatus,
                };

                delete updatedStatus[promiseType];

                return updatedStatus;
            });
        },
        [promises],
    );

    return (
        <TrackAsyncTasksContext.Provider
            value={{ promises, trackAsyncTasksPromises, clearAsyncTasksPromise }}>
            {children}
        </TrackAsyncTasksContext.Provider>
    );
};
