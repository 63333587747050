import React from "react";
import { Keyboard, Pressable, StyleSheet, ViewStyle } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { Modal, ModalWithComponent, SpacingValue, useTheme } from "@swiggy-private/rn-dls";

import { KeyboardAwareView, KeyboardAwareViewProps } from "../keyboard-aware-view";

export interface KeyboardAwareBottomModalInterface extends ModalWithComponent {
    keyboardAwareProps?: Omit<KeyboardAwareViewProps, "children">;
}

export const KeyboardAwareBottomModalComponent: React.FC<KeyboardAwareBottomModalInterface> = ({
    setShowModal,
    component,
    dismissOnPressingBackdrop,
    keyboardAwareProps,
    modalStyles,
    ...props
}) => {
    // TODO: refactor the modalStyles
    return (
        <Modal
            setShowModal={setShowModal}
            animation={{
                type: "slideFromBottom",
            }}
            {...props}
            modalStyles={{
                backdropStyle: {
                    justifyContent: "flex-end",
                    ...modalStyles?.backdropStyle,
                },
                modalBodyStyle: {
                    backgroundColor: "transparent",
                    borderRadius: 0,
                    padding: 0,
                    maxWidth: "100%",
                    width: "100%",
                },
            }}
            component={
                <ModalBody
                    modalBodyStyles={modalStyles?.modalBodyStyle}
                    dismissOnPressingBackdrop={dismissOnPressingBackdrop}
                    component={component}
                    setShowModal={setShowModal}
                    keyboardAwareProps={keyboardAwareProps}
                />
            }
        />
    );
};

interface ModalBodyProps {
    setShowModal: KeyboardAwareBottomModalInterface["setShowModal"];
    component: KeyboardAwareBottomModalInterface["component"];
    dismissOnPressingBackdrop: KeyboardAwareBottomModalInterface["dismissOnPressingBackdrop"];
    keyboardAwareProps?: KeyboardAwareBottomModalInterface["keyboardAwareProps"];
    modalBodyStyles?: ViewStyle;
}

const ModalBody: React.FC<ModalBodyProps> = ({
    setShowModal,
    component,
    dismissOnPressingBackdrop,
    keyboardAwareProps,
    modalBodyStyles: bodyStyles = {},
}) => {
    const { value: theme } = useTheme();
    const insets = useSafeAreaInsets();

    const onPressBackdrop = React.useCallback(() => {
        Keyboard.dismiss();
        if (dismissOnPressingBackdrop) {
            setShowModal(false);
        }
    }, [setShowModal, dismissOnPressingBackdrop]);

    const modalBodyStyles: ViewStyle = {
        paddingBottom: insets.bottom + SpacingValue["space-large"],
        backgroundColor: theme["color-background-primary"],
        ...bodyStyles,
    };

    return (
        <Pressable onPress={onPressBackdrop} accessible={false}>
            <KeyboardAwareView
                {...keyboardAwareProps}
                style={[styles.keyboardView, keyboardAwareProps?.style ?? null]}>
                <Pressable style={[styles.modalBodyStyles, modalBodyStyles]} accessible={false}>
                    {component}
                </Pressable>
            </KeyboardAwareView>
        </Pressable>
    );
};

export const KeyboardAwareBottomModal = React.memo(KeyboardAwareBottomModalComponent);

const styles = StyleSheet.create({
    keyboardView: {
        flex: 0,
        height: "100%",
        justifyContent: "flex-end",
    },
    modalBodyStyles: {
        paddingHorizontal: SpacingValue["space-medium"],
        paddingTop: SpacingValue["space-x-large"],
        borderRadius: 12,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
    },
});
