import * as React from "react";

import { useReviewAndRatingHandler } from "@minis-consumer/hooks/use-rating-and-review-modal-handler";
import { RatingAndReviewModal } from "@minis-consumer/components/rating-and-review-modal";

export const RatingAndReviewProvider: React.FC = () => {
    const { canOpen, orderId, ratingCount, storeId, orderKey, handler } =
        useReviewAndRatingHandler();

    const onCloseModal = React.useCallback(
        (b: boolean) => {
            handler({ canOpen: b });
        },
        [handler],
    );

    return (
        <RatingAndReviewModal
            setOpen={onCloseModal}
            ratingCount={ratingCount}
            orderId={orderId}
            isOpen={canOpen}
            storeId={storeId}
            orderKey={orderKey}
        />
    );
};
