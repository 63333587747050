import * as React from "react";
import {
    BlockedInput,
    useChatConversationState,
    useChatState,
} from "@swiggy-private/connect-chat-ui";

import { Analytics } from "@minis-consumer/analytics";
import { useChatBlockyModalHandler } from "@minis-consumer/hooks/use-chat-block-modal-handler";

import { ANALYTICS_COPIES } from "./constants";
import { checkIsBlockedByCustomer, getChatBlockedData } from "../../helpers/chat-blocked";

export const BlockedChatFooter: React.FC = () => {
    const { modalViewHandler } = useChatBlockyModalHandler();

    const { conversation } = useChatConversationState();

    /** Temporary change to meet RC cut since chat conversation dispatch isn't working */
    const { conversations } = useChatState();
    const targetConversation = conversations[conversation.id];
    /** */

    const isConversationBlocked = !!targetConversation.blockedInfo?.blockedBy?.length;
    const isBlockedByCustomer = checkIsBlockedByCustomer(targetConversation?.blockedInfo);
    const blockedFooterData = React.useMemo(
        () => getChatBlockedData(targetConversation, isBlockedByCustomer),
        [targetConversation, isBlockedByCustomer],
    );
    const { blockedText, unblockText, chatUnavailableText } = blockedFooterData ?? {};

    const analyticsContext = React.useMemo(
        () => JSON.stringify({ conversationId: conversation.id }),
        [conversation],
    );

    const onUnblockPress = React.useCallback(() => {
        Analytics.clickEvent({
            category: ANALYTICS_COPIES.UNBLOCK_CHAT,
            context: analyticsContext,
        });

        modalViewHandler(true);
    }, [analyticsContext, modalViewHandler]);

    const onBlockedFooterMount = (): void => {
        Analytics.impressionEvent({
            category: ANALYTICS_COPIES.CHAT_UNAVAILABLE,
            context: analyticsContext,
        });
    };

    if (!isConversationBlocked) {
        return null;
    }

    return (
        <BlockedInput
            isCurrentUserBlocker={isBlockedByCustomer}
            onUnblockPress={onUnblockPress}
            blockedText={blockedText}
            unblockText={unblockText}
            chatUnavailableText={chatUnavailableText}
            onMountCallback={isBlockedByCustomer ? undefined : onBlockedFooterMount}
        />
    );
};
