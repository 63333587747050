import * as React from "react";
import { StyleSheet } from "react-native";

import { useTheme } from "@swiggy-private/rn-dls";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import {
    ChatConversationScreen,
    ScreenLoader,
    useChatState,
    useConversationIndicators,
    useGetChatUserDetailsFromConversation,
} from "@swiggy-private/connect-chat-ui";

import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { useCreateChatConversation } from "@minis-consumer/hooks/use-start-chat-conversation";
import { ChatHeader } from "@minis-consumer/components/chat/components/header";
import { RenderContextProvider } from "@minis-consumer/components/chat/contexts/render-context";
import { AppEvent, AppEventTypes } from "@minis-consumer/includes/app-event";

import { ResetErrorBoundary } from "../reset-error-boundary";

import { ChatInfoComponent } from "./components/chat-info.web";

const Chat: React.FC = () => {
    const { value: theme } = useTheme();
    const chatState = useChatState();
    const [showPopup, setShowPopup] = React.useState(false);

    const callIconClickHandler = React.useCallback(() => {
        setShowPopup(!showPopup);
    }, [showPopup]);

    const storeInfo = useStoreInfo();

    const { conversationId } = useCreateChatConversation({
        storeId: storeInfo.storeId,
    });

    const indicators = useConversationIndicators(conversationId ?? "guest");
    const conversation = chatState.conversations[conversationId ?? "guest"];
    const conversationUser = useGetChatUserDetailsFromConversation(conversation);

    const contentStyle = {
        backgroundColor: theme["color-basic-5"],
        borderRightWidth: StyleSheet.hairlineWidth,
        borderLeftWidth: StyleSheet.hairlineWidth,
        borderLeftColor: theme["color-basic-alpha-5"],
        borderRightColor: theme["color-basic-alpha-5"],
    };

    React.useEffect(() => {
        return AppEvent.listen<{ conversationId: string }>(
            AppEventTypes.NEW_CHAT_MESSAGE,
            (event) => {
                if (
                    !event.handled &&
                    event.value &&
                    event.value.conversationId === conversationId
                ) {
                    event.handled = true;
                }
            },
        );
    }, [conversationId]);

    return (
        <Stack flex={1}>
            <ChatHeader
                active={indicators.online || conversationUser?.online}
                typing={indicators.typing}
                lastSeenAt={conversationUser?.lastSeenAt}
                conversation={conversation}
                callIconClickHandler={callIconClickHandler}
            />
            {conversationId ? (
                <ChatConversationScreen
                    showPopup={showPopup}
                    closeInfoPopup={callIconClickHandler}
                    conversationId={conversationId}
                    contentStyle={contentStyle}
                    ChatInfoComponent={ChatInfoComponent}
                />
            ) : (
                <ScreenLoader style={[{ backgroundColor: theme["color-basic-5"] }]} />
            )}
        </Stack>
    );
};

export const SidebarChat: React.FC = () => {
    const storeInfo = useStoreInfo();

    const { value: theme } = useTheme();

    const emptyBoxStyle = {
        borderWidth: 1,
        borderColor: theme["color-basic-15"],
        height: "100%",
    };

    /** When the store is deleted, show empty box */
    if (storeInfo.enabled === false) {
        return (
            <Box
                style={[styles.container, emptyBoxStyle]}
                display={{ default: "none", lg: "flex" }}
            />
        );
    }

    return (
        <Box
            style={[styles.container, styles.containerMaxHeight]}
            display={{ default: "none", lg: "flex" }}>
            <ResetErrorBoundary>
                <RenderContextProvider>
                    <Chat />
                </RenderContextProvider>
            </ResetErrorBoundary>
        </Box>
    );
};

const styles = StyleSheet.create({
    container: {
        borderRadius: 16,
        width: 320,
    },
    containerMaxHeight: {
        maxHeight: 640,
    },
});
