import React from "react";
import { Linking } from "react-native";

import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { getStoreDomain, getStoreUrl } from "@minis-consumer/helpers/store";
import { Logger } from "@minis-consumer/includes/logger";
import { useShareCheckForInApp } from "@minis-consumer/helpers/user-agent-app-name";

export type ShareParams = {
    description?: string;
    product?: {
        name: string;
        id: string;
    };
    social?: "whatsapp";
    order?: {
        id: string;
        key?: string;
    };
};

export const useShare = (storeDetails?: {
    slug: string;
    name: string;
}): ((params?: ShareParams) => void) => {
    const storeData = useStoreInfo(!storeDetails);

    const storeInfo = storeDetails ?? storeData;

    const storeDomain = getStoreDomain(storeData?.storeVersion);
    const storeUrl = getStoreUrl(storeInfo?.slug ?? "", storeDomain);
    const executeShare = useShareCheckForInApp();

    return React.useCallback(
        async (params?: ShareParams) => {
            try {
                if (!storeUrl) {
                    return;
                }

                const productLink = getProductLink(params?.product, storeUrl);
                const orderLink = gerOrderLink(params?.order, storeUrl);

                const link = productLink ? productLink : orderLink ? orderLink : storeUrl;
                const message = params?.description ? `${params?.description} ${link}` : link;

                if (params?.social === "whatsapp") {
                    if (!message) {
                        return;
                    }

                    await openWhatsapp(message);
                    return;
                }

                message &&
                    executeShare(
                        {
                            title: storeInfo?.name,
                            message,
                        },
                        { dialogTitle: storeInfo?.name },
                        message,
                    );
            } catch (error) {
                Logger.recordError(error);
            }
        },
        [storeInfo?.name, storeUrl, executeShare],
    );
};

const gerOrderLink = (order: ShareParams["order"], storeUrl: string): null | string => {
    if (!order) {
        return null;
    }

    return `${storeUrl}/order/${order.id}?orderKey=${order.key}`;
};

const getProductLink = (product: ShareParams["product"], storeUrl: string): null | string => {
    if (!product) {
        return null;
    }

    return `${storeUrl}/products/${product.id}`;
};

const openWhatsapp = async (message: string): Promise<void> => {
    const url = `https://wa.me/?text=${encodeURIComponent(message)}`;
    const canOpen = await Linking.canOpenURL(url);
    return canOpen ? Linking.openURL(url) : Promise.resolve();
};
