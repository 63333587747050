import React, { useCallback } from "react";
import { StyleSheet, Pressable, Platform } from "react-native";

import { SpacingValue } from "@swiggy-private/rn-dls";
import { Stack, Box } from "@swiggy-private/rn-adaptive-layout";
import { IOFlatList } from "@swiggy-private/react-native-ui";

import { Product } from "@minis-consumer/interfaces/catalog";
import { Divider } from "@minis-consumer/components/divider";
import { LocalCartItem } from "@minis-consumer/interfaces/cart";
import { useVariantsList } from "@minis-consumer/hooks/use-variants";

import { CustomisationsListHeader } from "./components/header";
import { CustomisationsListFooter } from "./components/footer";
import { VariantsCustomisationsListItem } from "./components/variant-list-item";

interface CustomisationsSheetProps {
    id: Product["id"];
    title: string;
    storeId: string;
    variants: LocalCartItem[];
    onAdd: (variantId?: string) => void;
    onRemove: (variantId?: string) => void;
    onShowVariantsList: VoidFunction;

    onClose?: VoidFunction;
}

const ItemSeparatorComponent: React.FC = () => (
    <Pressable>
        <Divider style={{ marginVertical: SpacingValue["space-x-large"] }} />
    </Pressable>
);

const CustomisationsList: React.FC<CustomisationsSheetProps> = ({
    id,
    title,
    storeId,
    variants,
    onAdd,
    onRemove,
    onClose,
    onShowVariantsList,
}) => {
    const { variantDetails } = useVariantsList(id);

    const renderItem = useCallback(
        ({ item }: { item: LocalCartItem }) => {
            const _variant = variantDetails.find((v) => v.id === item.variantId);
            if (!_variant) {
                return null;
            }
            const { id: itemId, quantity, variantId, productType } = item;
            const { price = 0, available, discountedPrice, optionValueMap } = _variant;
            const variantValues = Object.values(optionValueMap);
            return (
                <VariantsCustomisationsListItem
                    type={productType}
                    price={price}
                    itemId={itemId}
                    storeId={storeId}
                    quantity={quantity}
                    variantId={variantId}
                    available={available}
                    values={variantValues}
                    discountedPrice={discountedPrice}
                    onAdd={onAdd}
                    onRemove={onRemove}
                />
            );
        },
        [onAdd, onRemove, storeId, variantDetails],
    );

    return (
        <Stack>
            <Box ph={SpacingValue["space-medium"]}>
                <CustomisationsListHeader title={title} onClose={onClose} />
            </Box>
            <Box style={styles.contentContainer}>
                <IOFlatList
                    data={variants}
                    scrollEnabled={true}
                    renderItem={renderItem}
                    contentContainerStyle={styles.content}
                    showsVerticalScrollIndicator={true}
                    keyExtractor={(item: LocalCartItem) => item.variantId || item.id}
                    ItemSeparatorComponent={ItemSeparatorComponent}
                    ListFooterComponent={<CustomisationsListFooter onPress={onShowVariantsList} />}
                />
            </Box>
        </Stack>
    );
};

const styles = StyleSheet.create({
    ph16: {
        paddingHorizontal: SpacingValue["space-medium"],
    },
    closeIcon: {
        borderRadius: 100,
    },
    addBtn: {
        width: 96,
        maxWidth: 96,
    },
    unavailableBtn: {
        width: 125,
        maxWidth: 125,
    },
    headerDivider: {
        marginVertical: SpacingValue["space-large"],
    },
    striked: {
        textDecorationLine: "line-through",
    },
    contentContainer: {
        maxHeight: 400,
    },
    content: {
        paddingTop: SpacingValue["space-large"],
        paddingBottom:
            Platform.OS !== "web" ? SpacingValue["space-xx-large"] : SpacingValue["space-medium"],
        paddingHorizontal: SpacingValue["space-medium"],
    },
});

if (__DEV__) {
    CustomisationsList.displayName = "VariantsCustomisationsSheet";
}

export const VariantsCustomisationsList = React.memo(CustomisationsList);
