import * as React from "react";

export interface IStyle {
    _getCss: () => CSSModule[];
}

export type CSSModule = [id: number, css: string];

export interface IWithStylesContext {
    styles: unknown;
    addStyles: (styles: IStyle) => void;
}

export const WithStylesContext = React.createContext<IWithStylesContext | null>(null);
