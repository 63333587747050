import * as React from "react";
import { StyleSheet } from "react-native";

import { SpacingValue, Surface, SurfaceProps, useTheme } from "@swiggy-private/rn-dls";
import { useSelectScreen } from "@swiggy-private/rn-adaptive-layout";

export const Card: React.FC<React.PropsWithChildren<SurfaceProps>> = ({
    style,
    children,
    ...props
}) => {
    const { value: theme } = useTheme();

    const paddingValue = useSelectScreen({
        lg: SpacingValue["space-x-large"],
        default: SpacingValue["space-medium"],
    });

    const containerStyle = {
        backgroundColor: theme["color-background-primary"],
        padding: paddingValue,
    };

    return (
        <Surface style={[styles.surface, containerStyle, style]} {...props}>
            {children}
        </Surface>
    );
};

const styles = StyleSheet.create({
    surface: {
        elevation: 1,
        borderRadius: 16,
    },
});
