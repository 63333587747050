import * as React from "react";
import { ColorValue, Pressable, StyleProp, StyleSheet, ViewStyle } from "react-native";

import {
    SpacingValue,
    Text,
    TextProps,
    Tooltip,
    TooltipPlacement,
    TooltipProps,
    useTheme,
} from "@swiggy-private/rn-dls";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon, SvgIconType } from "@swiggy-private/connect-svg-icons";
import { CdnImage, CdnImageProps } from "@swiggy-private/react-native-ui";

import { Analytics, Event } from "@minis-consumer/analytics";

type TimeBasedTooltipComponentProps = {
    children: React.ReactElement;
    tooltipContentTitle: string;

    tooltipContentDescription?: string;
    isTooltipVisible?: boolean;
    tooltipContentTitleProps?: TextProps;
    tooltipContentDescriptionProps?: TextProps;
    icon?: SvgIconType["icon"];
    image?: CdnImageProps["id"];
    imageProps?: CdnImageProps;
    placement?: TooltipPlacement;
    tooltipStyle?: StyleProp<ViewStyle>;
    pointerColor?: ColorValue;
    analytics?: Event;
    pointerOffset?: number;
    tooltipContentClickHandler?: VoidFunction;

    withoutPortal?: boolean;
    containerWrapperStyle?: TooltipProps["containerWrapperStyle"];
    pointerCustomStyle?: TooltipProps["pointerCustomStyle"];
};

type TooltipContentComponentProps = {
    tooltipContentTitle: string;

    tooltipContentDescription?: string;
    tooltipContentClick?: VoidFunction;
    icon?: SvgIconType["icon"];
    image?: CdnImageProps["id"];
    imageProps?: CdnImageProps;
    tooltipContentTitleProps?: TextProps;
    tooltipContentDescriptionProps?: TextProps;
};

const TOOLTIP_IMAGE_SIZE = 27;
const DEFAULT_IMAGE_WIDTH = 56;
const DEFAULT_IMAGE_HEIGHT = 68;
const POINTER_OFFSET = 3;

const TooltipContent: React.FC<TooltipContentComponentProps> = ({
    tooltipContentClick,
    tooltipContentTitle,
    tooltipContentDescription,
    icon,
    image,
    imageProps,
    tooltipContentTitleProps,
    tooltipContentDescriptionProps,
}) => {
    const onTooltipContentClick = React.useCallback(() => {
        tooltipContentClick?.();
    }, [tooltipContentClick]);

    return (
        <Pressable onPress={onTooltipContentClick} hitSlop={{ top: 8, bottom: 8 }}>
            <Stack
                style={styles.tooltipContentContainer}
                direction="row"
                spacing={SpacingValue["space-x-small"]}
                alignItems="center"
                justifyContent="center">
                {icon ? (
                    <SvgIcon
                        icon="Chat"
                        color="color-basic-5"
                        width={TOOLTIP_IMAGE_SIZE}
                        height={TOOLTIP_IMAGE_SIZE}
                    />
                ) : image ? (
                    <CdnImage
                        id={image}
                        width={DEFAULT_IMAGE_WIDTH}
                        height={DEFAULT_IMAGE_HEIGHT}
                        {...imageProps}
                    />
                ) : null}

                <Box flex={1}>
                    <Text color="color-basic-5" category="b3" {...tooltipContentTitleProps}>
                        {tooltipContentTitle}
                    </Text>
                    {tooltipContentDescription ? (
                        <Text
                            color="color-basic-5"
                            category="b3"
                            {...tooltipContentDescriptionProps}>
                            {tooltipContentDescription}
                        </Text>
                    ) : null}
                </Box>
            </Stack>
        </Pressable>
    );
};

export const TimeBasedTooltipComponent: React.FC<TimeBasedTooltipComponentProps> = ({
    analytics,
    children,
    tooltipContentTitleProps,
    tooltipContentTitle,
    tooltipContentDescription,
    tooltipContentDescriptionProps,
    icon,
    image,
    imageProps,
    tooltipContentClickHandler,
    tooltipStyle,
    isTooltipVisible = false,
    placement = TooltipPlacement.BOTTOM,
    withoutPortal = false,
    containerWrapperStyle,
    pointerCustomStyle,
    //TODO: To add in DLS
    pointerColor = "#02060cbf",
    pointerOffset = POINTER_OFFSET,
}) => {
    const { value: theme } = useTheme();

    React.useEffect(() => {
        if (isTooltipVisible && analytics) {
            Analytics.impressionEvent(analytics);
        }
    }, [isTooltipVisible, analytics]);

    return (
        <Tooltip
            backgroundColor={theme["color-basic-alpha-75"]}
            style={[{ backgroundColor: pointerColor }, styles.toolTip, tooltipStyle]}
            visible={isTooltipVisible}
            placement={placement}
            animationType="slide"
            translateValue={-50}
            animationDuration={500}
            pointerColor={pointerColor}
            withoutPortal={withoutPortal}
            containerWrapperStyle={containerWrapperStyle}
            pointerCustomStyle={pointerCustomStyle}
            message={
                <TooltipContent
                    tooltipContentTitle={tooltipContentTitle}
                    tooltipContentDescription={tooltipContentDescription}
                    tooltipContentTitleProps={tooltipContentTitleProps}
                    tooltipContentDescriptionProps={tooltipContentDescriptionProps}
                    icon={icon}
                    image={image}
                    imageProps={imageProps}
                    tooltipContentClick={tooltipContentClickHandler}
                />
            }
            additionalPointerOffset={{
                horizontal: pointerOffset,
                vertical: 0,
            }}>
            {children}
        </Tooltip>
    );
};

const styles = StyleSheet.create({
    toolTip: {
        borderRadius: 12,
        marginRight: -1 * SpacingValue["space-xx-small"],
    },
    tooltipContentContainer: {
        paddingLeft: SpacingValue["space-xxx-small"],
    },
});

export const TimeBasedTooltip = React.memo(TimeBasedTooltipComponent);
