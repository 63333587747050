import * as React from "react";

export interface ICartCountContext {
    cartCountText: string;
    setCartCountText: (cartCountText: string) => void;
}

export const CartCountContext = React.createContext<ICartCountContext>({
    cartCountText: "",
    setCartCountText: () => undefined,
});

export const CartCountContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [cartCount, setCartCount] = React.useState("");

    const setCartCountText = React.useCallback((cartCountText: string) => {
        setCartCount(cartCountText);
    }, []);

    const value = React.useMemo(
        () => ({
            cartCountText: cartCount,
            setCartCountText,
        }),
        [cartCount, setCartCountText],
    );

    return <CartCountContext.Provider value={value}>{children}</CartCountContext.Provider>;
};

export const useCartCountTextContext = (): ICartCountContext => React.useContext(CartCountContext);
