import React, { memo } from "react";
import { StyleSheet } from "react-native";

import { Text } from "@swiggy-private/rn-dls";

import { Product } from "@minis-consumer/interfaces/catalog";
import { DURATION_STRING_MAP } from "@minis-consumer/constants/product";

const ProductDurationComponent: React.FC<NonNullable<Product["productDuration"]>> = ({
    unit,
    value,
}) => {
    if (!unit || !value) {
        return null;
    }

    return (
        <Text
            category="b3"
            color="low"
            weight="regular"
            numberOfLines={1}
            ellipsizeMode="tail"
            style={styles.duration}>
            {value}{" "}
            {value > 1
                ? DURATION_STRING_MAP[unit].pluralText
                : DURATION_STRING_MAP[unit].singularText}
        </Text>
    );
};

export const ProductDuration = memo(ProductDurationComponent);

const styles = StyleSheet.create({
    duration: {
        textTransform: "lowercase",
    },
});
