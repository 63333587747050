import { ApiResponse } from "@swiggy-private/connect-api-core";
import { Endpoint } from "@rest-hooks/endpoint";
import { Entity } from "@rest-hooks/rest";

import { UserService } from "../api/user";
import { Session } from "../includes/session";

import type { User as IUser } from "../interfaces/user";

export class UserEntity extends Entity implements IUser {
    readonly id: string = "0";
    readonly mobile: string = "";
    readonly verified: boolean = false;
    readonly token: string = "";
    readonly name: string = "";
    readonly email: string = "";
    readonly emailVerified: boolean = false;
    readonly addresses: IUser["addresses"] = [];

    pk(): string {
        return this.id;
    }
}

const guestUser: Omit<IUser, "isGuest"> = {
    id: "0",
    mobile: "",
    verified: false,
    token: "",
    name: "",
    email: "",
    emailVerified: false,
    addresses: [],
};

const fetchUser = async (): Promise<Omit<IUser, "isGuest">> => {
    if (!Session.getInstance().tid || !Session.getInstance().token) {
        return guestUser;
    }

    return UserService.fetchUser()
        .then((data) => {
            if (!data) {
                return guestUser;
            }

            if (data.token) {
                const session = Session.getInstance();

                session.token = data.token;
                session.userId = data.customer_id;
                session.userName = data.name;
            }

            return {
                ...data,
                id: data.customer_id,
            };
        })
        .catch((err) => {
            // Todo move this logic from here...
            if (err && (err as ApiResponse<unknown>).statusCode === 999) {
                const session = Session.getInstance();
                session.clear();
            }

            return guestUser;
        });
};

export const User = new Endpoint(fetchUser, { schema: UserEntity });
