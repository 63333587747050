import * as React from "react";

import { useClearCartModalHandler } from "@minis-consumer/hooks/use-clear-cart-modal-handler";
import { ClearCartDialog } from "@minis-consumer/components/clear-cart-modal";

export const ClearCartProvider: React.FC = () => {
    const { isOpen, modalViewHandler } = useClearCartModalHandler();

    const onCloseModal = React.useCallback(
        (isCartCleared?: boolean) => {
            modalViewHandler(false, isCartCleared);
        },
        [modalViewHandler],
    );

    return <ClearCartDialog showModal={isOpen} closeModal={onCloseModal} />;
};
