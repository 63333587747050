import * as React from "react";
import { Pressable, StyleSheet } from "react-native";

import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Surface, Text, useTheme } from "@swiggy-private/rn-dls";
import { UploadedMediaFile } from "@swiggy-private/rn-image-picker";

import {
    COPIES,
    DEFAULT_VALUES,
    TEST_IDS,
} from "@minis-consumer/components/rating-and-review-form/constants";

import { UploadedImages } from "../uploaded-images";

interface ImageUploadProps {
    handleUploadImage: VoidFunction;
    uploadedMedia: UploadedMediaFile[];
    setUploadedMedia: (urls: UploadedMediaFile[]) => void;

    maxImageCount?: number;
}

export const ImageUploadSmallScreen: React.FC<ImageUploadProps> = React.memo(
    ({ uploadedMedia, setUploadedMedia, handleUploadImage, maxImageCount = 1 }) => {
        const { value: theme } = useTheme();
        const uploadedMediaLength = uploadedMedia?.length;
        const isUploadedMediaCountValid = uploadedMediaLength < maxImageCount;

        const surfaceStyle = {
            backgroundColor: isUploadedMediaCountValid
                ? theme["color-basic-0"]
                : theme["color-basic-30"],
        };

        const imageUploadContainerStyle = {
            backgroundColor: theme["color-basic-5"],
        };

        const iconColor = isUploadedMediaCountValid ? "color-primary" : "color-basic-60";

        return (
            <Stack
                direction="row"
                style={[imageUploadContainerStyle, styles.container]}
                testID={TEST_IDS.IMAGE_UPLOAD}>
                <Pressable
                    testID={TEST_IDS.SMALL_SCREEN_ADD_ICON}
                    onPress={handleUploadImage}
                    disabled={!isUploadedMediaCountValid}>
                    <Surface style={[surfaceStyle, styles.iconContainer]}>
                        <SvgIcon
                            icon="CameraPlus"
                            width={DEFAULT_VALUES.CAMERA_ICON_SIZE}
                            height={DEFAULT_VALUES.CAMERA_ICON_SIZE}
                            color={iconColor}
                        />
                    </Surface>
                </Pressable>
                <Stack flex={1} justifyContent="center">
                    {!uploadedMediaLength ? (
                        <Text category="b3" weight="regular" color="color-basic-60">
                            <Text category="btn5" weight="bold" color="high">
                                {COPIES.ADD_PHOTO_PREFIX}
                            </Text>
                            {COPIES.ADD_PHOTO_SUFIX}
                        </Text>
                    ) : (
                        <UploadedImages
                            uploadedMedia={uploadedMedia}
                            setUploadedMedia={setUploadedMedia}
                            width={DEFAULT_VALUES.SMALL_SCREEN_UPLOADED_IMAGE_SIZE}
                            height={DEFAULT_VALUES.SMALL_SCREEN_UPLOADED_IMAGE_SIZE}
                        />
                    )}
                </Stack>
            </Stack>
        );
    },
);

if (__DEV__) {
    ImageUploadSmallScreen.displayName = "ImageUploadSmallScreen";
}

const styles = StyleSheet.create({
    container: {
        borderRadius: 12,
        padding: SpacingValue["space-x-small"],
    },
    iconContainer: {
        elevation: 3,
        borderRadius: 8,
        padding: SpacingValue["space-large"],
        marginRight: SpacingValue["space-medium"],
    },
});
