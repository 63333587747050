import React from "react";
import { Platform, Pressable, StyleProp, StyleSheet, TextStyle, ViewStyle } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { Snackbar, SpacingValue, Text, TextProps, useTheme } from "@swiggy-private/rn-dls";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { Box, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";

import { Analytics } from "@minis-consumer/analytics";
import { Cart } from "@minis-consumer/interfaces/cart";

import { ANALYTICS_COPIES, APPOINTMENT_ERROR_SNACKBAR_CONSTANTS } from "./constants";

type AppointmentErrorSnackbarProps = {
    errorText: string;
    canShowSnackbar: boolean;
    dismissSnackbar: VoidFunction;
    ctaText?: string;
    extraStyle?: StyleProp<ViewStyle>;
    cart?: Cart["cartViewData"];
};

const isWeb = Platform.OS === "web";
const isAndroid = Platform.OS === "android";

const AppointmentErrorSnackbarComponent: React.FC<AppointmentErrorSnackbarProps> = ({
    errorText,
    extraStyle,
    ctaText,
    canShowSnackbar,
    dismissSnackbar,
    cart,
}) => {
    const insets = useSafeAreaInsets();
    const { value: theme } = useTheme();

    const { id: productId, productType, price } = cart?.cartItems[0]?.item || {};
    const billTotal = cart?.bill?.billTotal;

    const message = React.useMemo(() => {
        if (errorText.trim().length === 0) {
            return APPOINTMENT_ERROR_SNACKBAR_CONSTANTS.ERROR_FALLBACK_MSSG;
        }

        return errorText;
    }, [errorText]);

    const btnText = React.useMemo(() => {
        if (ctaText && ctaText.trim().length > 0) {
            return ctaText;
        }

        return APPOINTMENT_ERROR_SNACKBAR_CONSTANTS.CTA_TEXT_FALLBACK;
    }, [ctaText]);

    const snackbarCtaStyles: ViewStyle = {
        backgroundColor: theme["color-basic-75"],
        borderColor: theme["color-basic-60"],
    };

    const getBottomMargin = (): number => {
        if (isWeb) {
            return 16 * SpacingValue["space-xx-small"] + SpacingValue["space-x-large"];
        }

        const androidMargin = 6 * SpacingValue["space-large"];
        const iosMargin = insets.bottom + 5 * SpacingValue["space-medium"];
        const defaultMargin = SpacingValue["space-x-large"];

        return Math.max(isAndroid ? androidMargin : iosMargin, defaultMargin);
    };

    const snackbarStyle = useSelectScreen({
        lg: {
            maxWidth: APPOINTMENT_ERROR_SNACKBAR_CONSTANTS.MAX_SNACKBAR_WIDTH,
            backgroundColor: theme["color-basic-100"],
            ...styles.largeSnackbar,
        },
        default: {
            marginBottom: getBottomMargin(),
            backgroundColor: theme["color-basic-100"],
            ...styles.smallSnackbar,
        },
    });

    const ctaCategory: TextProps["category"] = useSelectScreen({
        default: "btn5",
        md: "btn5",
        lg: "btn3",
    });

    // This was asked by design to specifically make the text sentence case
    const textStyles: TextStyle = {
        textTransform: "none",
    };

    const msgStyle: TextStyle = useSelectScreen({
        lg: {
            fontSize: 16,
            lineHeight: 19,
            fontWeight: "600",
        },
        default: {
            fontSize: 13,
            lineHeight: 16,
            fontWeight: "500",
        },
    });

    const commonMsgStyle: TextStyle = {
        color: theme["color-basic-0"],
        letterSpacing: -0.3,
    };

    const backgroundStyles: ViewStyle = {
        backgroundColor: theme["color-basic-100"],
    };

    const childrenStyle = StyleSheet.flatten([msgStyle, commonMsgStyle]);

    const analyticsData = React.useMemo(() => {
        return {
            screen: ANALYTICS_COPIES.SCREEN_NAME,
            category: ANALYTICS_COPIES.CATEGORY,
            context: JSON.stringify({
                productId: productId,
                productType: productType,
                price: price,
                billAmmount: billTotal,
                cartId: cart?.id,
                errorMessage: errorText,
            }),
        };
    }, [billTotal, cart, errorText, price, productId, productType]);

    const onPressCta = React.useCallback(() => {
        Analytics.clickEvent(analyticsData);

        dismissSnackbar();
    }, [analyticsData, dismissSnackbar]);

    React.useEffect(() => {
        if (!canShowSnackbar) {
            return;
        }

        Analytics.impressionEvent(analyticsData);
    }, [analyticsData, canShowSnackbar]);

    if (!canShowSnackbar) {
        return null;
    }

    return (
        <Snackbar
            style={[styles.root, snackbarStyle, backgroundStyles, extraStyle]}
            isAutoDismiss={false}
            isActive={canShowSnackbar}
            childrenStyle={childrenStyle}
            touchableRight={
                <Box>
                    <Pressable
                        style={[snackbarCtaStyles, styles.ctaStyles]}
                        onPress={onPressCta}
                        hitSlop={{
                            top: 6,
                            left: 6,
                            bottom: 6,
                            right: 6,
                        }}>
                        <Text category={ctaCategory} style={textStyles} color="color-basic-0">
                            {btnText}
                        </Text>
                    </Pressable>
                </Box>
            }
            accessoryLeft={
                <SvgIcon icon="InfoFilled" color="color-negative" height={20} width={20} />
            }>
            {message}
        </Snackbar>
    );
};

const styles = StyleSheet.create({
    root: {
        borderRadius: 16,
        paddingVertical: SpacingValue["space-small"],
        paddingHorizontal: SpacingValue["space-small"],
    },
    ctaStyles: {
        borderWidth: 1,
        borderRadius: 20,
        paddingVertical: SpacingValue["space-x-small"],
        paddingHorizontal: SpacingValue["space-small"],
    },
    largeSnackbar: {
        left: 0,
        rigth: 0,
        marginBottom: SpacingValue["space-x-large"],
        marginHorizontal: SpacingValue["space-x-large"],
        paddingVertical: SpacingValue["space-small"],
    },
    smallSnackbar: {
        marginHorizontal: SpacingValue["space-medium"],
    },
});

export const AppointmentErrorSnackbar = React.memo(AppointmentErrorSnackbarComponent);
