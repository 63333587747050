import React from "react";
import { StyleSheet } from "react-native";

import { SdkConversation } from "@swiggy-private/connect-chat-sdk";
import { SpacingValue, Modal } from "@swiggy-private/rn-dls";
import { useBlockConversation } from "@swiggy-private/connect-chat-ui";
import { useGetConversationFromNetwork } from "@swiggy-private/connect-chat-ui/src/hooks/use-get-conversation";

import { useChatBlockyModalHandler } from "@minis-consumer/hooks/use-chat-block-modal-handler";
import { Analytics } from "@minis-consumer/analytics";

import { ModalBody } from "./components/modal-body";
import { getModalStrings } from "./helpers";
import { ANALYTICS_COPIES, IS_WEB, MAX_MODAL_WIDTH_WEB } from "./constants";

interface Props {
    showModal: boolean;
    closeModal: VoidFunction;
    conversationId: SdkConversation["id"];
    isBlocked: boolean;
    personName: string;
}

const ChatBlockDialogComponent: React.FC<Props> = ({
    showModal,
    closeModal,
    conversationId,
    isBlocked,
    personName,
}) => {
    const [showLoader, setShowLoader] = React.useState(false);

    const modalStrings = getModalStrings({ isBlocked, personName });

    const { title, description, ctaText } = modalStrings;

    const blockUnblockAction = useBlockConversation();
    const getConversationFromNetwork = useGetConversationFromNetwork();

    const { dataHandler: modalDataHandler } = useChatBlockyModalHandler();

    const fireArdEvent = React.useCallback(
        (isChatBlocked: boolean) => {
            Analytics.clickEvent({
                category: isChatBlocked ? ANALYTICS_COPIES.UNBLOCK_CTA : ANALYTICS_COPIES.BLOCK_CTA,
                context: JSON.stringify({
                    conversationId,
                }),
            });
        },
        [conversationId],
    );

    const onPressBlockUnblock = React.useCallback(async () => {
        fireArdEvent(isBlocked);

        setShowLoader(true);

        const response = await blockUnblockAction({
            id: conversationId,
            blocked: !isBlocked,
        });

        setShowLoader(false);

        if (response) {
            modalDataHandler(conversationId, !isBlocked);
            getConversationFromNetwork(conversationId, true);
            closeModal();
        }
    }, [
        closeModal,
        blockUnblockAction,
        modalDataHandler,
        conversationId,
        fireArdEvent,
        isBlocked,
        getConversationFromNetwork,
    ]);

    if (!conversationId || !personName) {
        return null;
    }

    return (
        <Modal
            showModal={showModal}
            setShowModal={closeModal}
            modalStyles={{
                modalBodyStyle: styles.modalBodyStyle,
            }}
            component={
                <ModalBody
                    closeModal={closeModal}
                    title={title}
                    description={description}
                    blockCtaText={ctaText}
                    onPressBlockUnblock={onPressBlockUnblock}
                    showLoader={showLoader}
                    conversationId={conversationId}
                />
            }
        />
    );
};

export const ChatBlockDialog = React.memo(ChatBlockDialogComponent);

const styles = StyleSheet.create({
    modalBodyStyle: {
        borderRadius: 20,
        maxWidth: IS_WEB ? MAX_MODAL_WIDTH_WEB : "80%",
        width: IS_WEB ? MAX_MODAL_WIDTH_WEB : "80%",
        paddingTop: SpacingValue["space-x-large"],
        paddingHorizontal: SpacingValue["space-medium"],
        paddingBottom: SpacingValue["space-medium"],
    },
});
