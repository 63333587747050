import React from "react";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { CommonRouteList, MinisRouteList, RouteList } from "@minis-consumer/interfaces/route";
import { Order } from "@minis-consumer/interfaces/order";

import { UpdateItemInCartParams, useUpdateBatchItemsInLocalCart } from "./use-cart";
import { MinisNavigatorId, StoreNavigatorId } from "@minis-consumer/constants/navigation";

type StoreNavigation = NativeStackNavigationProp<RouteList, "Order", string>;
type MinisNavigation = NativeStackNavigationProp<MinisRouteList, "Order", string>;

export const useReorderItems = (
    storeId: string,
    storeSlug: string,
): ((items: Order["items"]) => void) => {
    const navigation = useNavigation<NativeStackNavigationProp<CommonRouteList, "Order", string>>();
    const updateCartItems = useUpdateBatchItemsInLocalCart(storeId);

    return React.useCallback(
        (items: Order["items"]) => {
            const data: UpdateItemInCartParams[] = [];

            items.forEach((product) => {
                data.push({
                    itemId: product.item.id,
                    quantity: product.quantity,
                    custom: product.metadata?.custom,
                    variantId: product.item.variantId,
                });
            });

            updateCartItems(data);

            const storeNavigation: StoreNavigation | null =
                navigation.getId() === StoreNavigatorId
                    ? (navigation as StoreNavigation)
                    : (navigation.getParent(StoreNavigatorId) as StoreNavigation);

            const minisNavigation: MinisNavigation | null =
                navigation.getId() === MinisNavigatorId
                    ? (navigation as MinisNavigation)
                    : (navigation.getParent(MinisNavigatorId) as MinisNavigation);

            if (storeNavigation != null) {
                storeNavigation.replace("Home", {
                    screen: "Cart",
                    params: {},
                });
            } else if (minisNavigation != null) {
                // Replace was not working as expected
                minisNavigation.reset({
                    index: 0,
                    routes: [
                        {
                            name: "MiniStore",
                            params: { slug: storeSlug, initialPath: "/cart" },
                        },
                    ],
                });
            }
        },
        [navigation, updateCartItems, storeSlug],
    );
};
