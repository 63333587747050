import React from "react";

import { StyleSheet, Pressable, Platform } from "react-native";
import { NavigationProp } from "@react-navigation/native";

import { Box } from "@swiggy-private/rn-adaptive-layout";
import { Text, SpacingValue } from "@swiggy-private/rn-dls";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { StoriesAnalytics } from "@swiggy-private/react-native-stories";
import { useMount } from "@swiggy-private/react-hooks";

import { useCopyToClipboard } from "@minis-consumer/hooks/use-copy";

import { RouteList } from "@minis-consumer/interfaces/route";

import { useNavigation } from "../hooks/use-navigation";
import { COVER_COPIES } from "../constants";

interface IStoryCoverProps {
    link: string;
    storeId: string;
    analytics: StoriesAnalytics;
    renderCloseComponent: () => React.ReactNode;
    onClose: VoidFunction;
    goToChat: VoidFunction;
}

const isWeb = Platform.OS === "web";

const StoryCoverComponent: React.FC<IStoryCoverProps> = ({
    link,
    storeId,
    analytics,
    renderCloseComponent,
    onClose,
    goToChat,
}) => {
    const copyLink = useCopyToClipboard();
    const navigation = useNavigation<NavigationProp<RouteList>>();

    const copyToClipboard = React.useCallback(() => {
        // Copy StoreLink
        analytics.sendShareClick();
        copyLink({ message: link });
    }, [analytics, link, copyLink]);

    const onChatPress = React.useCallback((): void => {
        analytics.sendChatWithUsClick();
        if (isWeb) {
            onClose();
            goToChat();
            return;
        }
        navigation.navigate("ChatConversation", { storeId });
        return;
    }, [analytics, goToChat, navigation, onClose, storeId]);

    useMount(() => {
        analytics.sendEndCoverImpression();
    });

    return (
        <Box
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={styles.container}>
            <Box mt={SpacingValue["space-medium"]} style={styles.close}>
                <Pressable hitSlop={{ top: 16, bottom: 16, left: 16, right: 16 }} onPress={onClose}>
                    {renderCloseComponent()}
                </Pressable>
            </Box>

            <Box
                direction="column"
                justifyContent="center"
                alignItems="center"
                style={styles.contentContainer}>
                <Text category="b1" weight="bold" color={"color-basic-0"}>
                    {COVER_COPIES.LOVE}
                </Text>
                <Box
                    direction="row"
                    alignItems="center"
                    mt={SpacingValue["space-x-small"]}
                    style={styles.copyLink}>
                    <Box ph={SpacingValue["space-x-small"]} flex={1}>
                        <Text
                            category="b2"
                            numberOfLines={1}
                            color={"color-basic-0"}
                            style={styles.linkText}>
                            {link}
                        </Text>
                    </Box>
                    <Pressable style={styles.linkCTA} onPress={copyToClipboard}>
                        <Box pv={SpacingValue["space-small"]} ph={SpacingValue["space-small"]}>
                            <Text category="b3" color={"color-basic-0"}>
                                {COVER_COPIES.COPY}
                            </Text>
                        </Box>
                    </Pressable>
                </Box>
                <Box direction="column" mt={80} alignItems="center">
                    <Text category="b2" weight="medium" color={"color-basic-0"}>
                        {COVER_COPIES.QUESTIONS}
                    </Text>
                    <Pressable onPress={onChatPress}>
                        <Box
                            direction="row"
                            mt={SpacingValue["space-x-small"]}
                            pv={SpacingValue["space-x-small"]}
                            ph={SpacingValue["space-medium"]}
                            alignItems="center"
                            style={styles.chatWithUs}>
                            <SvgIcon icon="Chat" color={"color-basic-0"} width={16} height={16} />
                            <Box ph={SpacingValue["space-xx-small"]}>
                                <Text category="b2" weight="bold" color={"color-basic-0"}>
                                    {COVER_COPIES.CHAT}
                                </Text>
                            </Box>
                        </Box>
                    </Pressable>
                </Box>
            </Box>
        </Box>
    );
};

export const StoryCover = React.memo(StoryCoverComponent);

const styles = StyleSheet.create({
    container: { width: "100%", height: "100%" },
    close: {
        position: "absolute",
        paddingHorizontal: SpacingValue["space-medium"],
        top: 0,
        right: 0,
        paddingTop: SpacingValue["space-medium"],
        zIndex: 999,
    },
    contentContainer: {
        position: "absolute",
        left: 32,
        right: 32,
        height: "100%",
    },

    copyLink: {
        borderRadius: 24,
        borderWidth: 1,
        borderColor: "#909197",
        width: "100%",
        overflow: "hidden",
        paddingLeft: SpacingValue["space-small"],
    },
    linkText: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    linkCTA: {
        marginLeft: "auto",
        borderBottomRightRadius: 12,
        borderTopRightRadius: 12,
        backgroundColor: "#36393E",
    },
    chatWithUs: {
        backgroundColor: "#36393E",
        borderRadius: 8,
    },
});
