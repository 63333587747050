import { useContext } from "react";
import { AppStateContext } from "../contexts/app-context";
import { AppState } from "../reducers/app-reducer";

export function useAppState(): AppState {
    const ctx = useContext(AppStateContext);
    if (!ctx) {
        throw new Error("Missing app state context");
    }

    return ctx;
}
