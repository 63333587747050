import { useEffect } from "react";

import { AppEvent, AppEventTypes, IAppEvent } from "../includes/app-event";

export const useAppEvent = <T = unknown>(
    type: AppEventTypes,
    listener: (e: IAppEvent<T>) => void,
): void => {
    useEffect(() => AppEvent.listen(type, listener), [listener, type]);
};
