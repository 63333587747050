import { Platform } from "react-native";

export const getSlugFromPath = (): string => {
    if (Platform.OS !== "web") {
        return "";
    }

    if (window && window.location && window.location.pathname) {
        const domain = window.location.hostname.split(".");
        if (domain[0] === "www") {
            return domain[1];
        } else {
            return domain[0];
        }
    }

    return "";
};
