import * as React from "react";
import { useController } from "@rest-hooks/react";

import { useMount, useMountedRef } from "@swiggy-private/react-hooks";

import { StaticImages } from "@minis-consumer/constants/images";
import { User } from "@minis-consumer/resources/user";
import { isSSR } from "@minis-consumer/helpers/server";
import type { SwiggyHeaders } from "@minis-consumer/interfaces/swiggy";
import { getMediaUrl } from "@minis-consumer/helpers/media";
import { fetchConfigInfoApi } from "@minis-consumer/api/config-info";
import { useGetSession } from "@minis-consumer/hooks/use-get-session";
import { ConfigInfo } from "@minis-consumer/interfaces/config-info";

import { Session } from "./session";
import { LocalCartManager } from "./local-cart-manager";
import { Counter } from "./counter";
import { ImagePreloader } from "./image-preloader";
import { RecentMinisTracker } from "./recent-minis";
import { Logger } from "./logger";
import { Device } from "./device";

/**
 * Loads config info from the server.
 */
export const loadConfigInfoFromServer = async (
    setConfigInfo: (configInfo: ConfigInfo) => void,
    config?: ConfigInfo,
): Promise<void> => {
    const fn = (): Promise<void> =>
        fetchConfigInfoApi().then(setConfigInfo).catch(Logger.recordError);

    if (config != null) {
        fn();
        return;
    }

    return fn();
};

export const bootstrap = async (headers?: SwiggyHeaders | null): Promise<void> => {
    await preloadImages();

    try {
        await Promise.all([
            Device.load(headers),
            Session.load(headers),
            LocalCartManager.load(),
            Counter.load(),
            RecentMinisTracker.load(),
        ]);
    } catch (err) {
        Logger.recordError(err);
    }

    Device.getInstance().save();
};

const isTestEnv = process.env.NODE_ENV === "test";

interface BootStrapperProps {
    fallback?: React.ReactNode;
    headers?: SwiggyHeaders | null;
    fetchUser?: boolean;
    onFetchUser: VoidFunction;
}

export const BootStrapper: React.FC<React.PropsWithChildren<BootStrapperProps>> = ({
    children,
    fallback,
    headers,
    fetchUser = true,
    onFetchUser,
}) => {
    const [initialized, setInitialized] = React.useState(() => isTestEnv || isSSR());
    const mounted = useMountedRef();
    const { fetch } = useController();
    const { setConfigInfo, config } = useGetSession();

    const fn = async (): Promise<void> => {
        try {
            await bootstrap(headers);

            if (!mounted.current) {
                return;
            }

            if (fetchUser) {
                await fetch(User)
                    .then((response) => {
                        if (response.id === "0" || !response.id) {
                            Session.getInstance().clear();
                        }
                        onFetchUser();
                    })
                    .catch((err) => {
                        Logger.recordError(err);
                        Session.getInstance().clear();
                    });
            }

            await loadConfigInfoFromServer(setConfigInfo, config);

            mounted.current && setInitialized(true);
        } catch (err) {
            Logger.recordError(err);
        }
    };

    useMount(() => {
        fn();
    });

    return initialized ? <>{children}</> : fallback ? <>{fallback}</> : null;
};

const preloadImages = async (): Promise<void> => {
    const urls = StaticImages.map((imageParams) => {
        const params = typeof imageParams === "string" ? { id: imageParams } : imageParams;

        return getMediaUrl(params.id, {}, true);
    });

    await ImagePreloader.preload(urls).catch(Logger.recordError);
};
