import * as React from "react";
import { useLinkProps, NavigationAction } from "@react-navigation/native";
import { AccessibilityProps, Platform } from "react-native";

import type { To } from "@react-navigation/native/lib/typescript/src/useLinkTo";
import type { RouteList } from "../../interfaces/route";

interface LinkProps extends AccessibilityProps {
    to: To<RouteList>;
    action?: NavigationAction;
    children: React.ReactElement<{ onPress: () => void } & AccessibilityProps>;
}

export const Link: React.FC<React.PropsWithChildren<LinkProps>> = ({ to, action, children }) => {
    const { onPress, ...props } = useLinkProps<RouteList>({ to, action });

    if (Platform.OS === "web") {
        return React.cloneElement(children, { onPress, ...props });
    }

    return React.cloneElement(children, { onPress });
};
