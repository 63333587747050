import { Endpoint } from "@rest-hooks/endpoint";

import { fetchFAQApi, fetchHelpApi } from "../api/help-faq";

export const Faq = new Endpoint(fetchFAQApi, {
    key: () => "faq",
});

export const Help = new Endpoint(fetchHelpApi, {
    key: () => "help",
});
