import { useCache, useController } from "@rest-hooks/react";
import { useCallback, useMemo } from "react";

import { IAddressList } from "@minis-consumer/interfaces/address";
import { AddressListResource } from "@minis-consumer/resources/address";

import { useSignedIn } from "./use-user";

export const useFetchAddressListFromServer = (storeId: string): VoidFunction => {
    const { fetch } = useController();
    const isSignedIn = useSignedIn();

    const fetchAddressList = useCallback(async () => {
        if (!isSignedIn) {
            return;
        }
        await fetch(AddressListResource, {
            storeId,
        });
    }, [fetch, isSignedIn, storeId]);

    return fetchAddressList;
};

export const useAddressList = (storeId: string): IAddressList | null => {
    const addresses = useCache(AddressListResource, { storeId });

    return useMemo(() => addresses ?? null, [addresses]);
};
