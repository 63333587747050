import * as React from "react";
import { StyleProp, StyleSheet, ViewStyle } from "react-native";

import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { FormInput } from "@swiggy-private/react-native-ui";

import { useIsDesktopWeb } from "@minis-consumer/hooks/use-dweb";

import { COPIES, DEFAULT_VALUES, TEST_IDS } from "../../constants";
import { getTitle } from "../../helper";

interface ReviewInputProps {
    review: string;
    ratingCount: number;
    handleReviewChange: (s: string) => void;

    onBlur?: VoidFunction;
    style?: StyleProp<ViewStyle>;
}

const ReviewInputComponent: React.FC<ReviewInputProps> = ({
    ratingCount,
    handleReviewChange,
    review,
    style,
    onBlur,
}) => {
    const isDWeb = useIsDesktopWeb();
    const { value: theme } = useTheme();
    const [label, setLabel] = React.useState("");

    const inputStyle = {
        fontFamily: theme["text-subheader-2-font-family"],
        letterSpacing: theme["text-subheader-2-letter-spacing"],
        color: theme["color-basic-100"],
    };

    const title = getTitle(ratingCount);

    const onFocus = React.useCallback(() => {
        setLabel(isDWeb ? COPIES.INPUT_LABEL : "");
    }, [isDWeb]);

    const onBlurInput = React.useCallback(() => {
        setLabel(isDWeb && review ? COPIES.INPUT_LABEL : "");
        onBlur?.();
    }, [isDWeb, onBlur, review]);

    const handleInputChange = React.useCallback(
        (text: string) => {
            setLabel(isDWeb ? COPIES.INPUT_LABEL : "");
            handleReviewChange(text);
        },
        [handleReviewChange, isDWeb],
    );

    return (
        <Stack
            direction="column"
            alignItems="center"
            spacing={SpacingValue["space-x-large"]}
            style={style}
            testID={TEST_IDS.REVIEW_INPUT}>
            <Text category="h5" color="color-basic-100" testID={TEST_IDS.FORM_INPUT_TITLE}>
                {title}
            </Text>

            <FormInput
                name="review-form"
                style={styles.largeInputBox}
                onChangeText={handleInputChange}
                placeholder={COPIES.INPUT_PLACEHOLDER}
                placeholderTextColor={theme["color-basic-45"]}
                textStyle={[inputStyle, styles.inputText]}
                testID={TEST_IDS.FORM_INPUT}
                label={label}
                spellCheck={false}
                multiline
                value={review}
                maxLength={DEFAULT_VALUES.INPUT_FEEDBACK_MAX_LENGTH}
                onBlur={onBlurInput}
                onFocus={onFocus}
                showCounter
            />
        </Stack>
    );
};

export const ReviewInput = React.memo(ReviewInputComponent);

const styles = StyleSheet.create({
    largeInputBox: {
        height: 104,
        flexDirection: "column",
    },
    inputText: {
        overflow: "scroll",
        minHeight: "90%",
        paddingTop: SpacingValue["space-small"] / 2,
        width: "100%",
    },
});
