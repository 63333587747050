import React, { FC } from "react";
import { StyleSheet, ScrollView, KeyboardAvoidingView, Platform, ViewStyle } from "react-native";
import { useHeaderHeight } from "@react-navigation/elements";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useSelectScreen } from "@swiggy-private/rn-adaptive-layout";

import { Stack, Box } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Button, useTheme } from "@swiggy-private/rn-dls";
import { useIsKeyboardShown } from "@swiggy-private/react-native-ui";

import { DynamicForm } from "@minis-consumer/components/dynamic-forms";
import { IDynamicForm } from "@minis-consumer/interfaces/dynamic-forms";

interface DynamicMultipleFormsProps {
    forms: IDynamicForm[];
    submitButtonText: string;
    FormSeparator: React.ReactElement;
    onSubmitForms: (formData: any) => void;

    renderHeader?: () => React.ReactElement | null;
    disabled?: boolean;
    readonly?: boolean;
}

const DynamicMultipleFormsComponent: FC<DynamicMultipleFormsProps> = ({
    forms,
    submitButtonText,
    FormSeparator,
    disabled,
    readonly,
    onSubmitForms,
    renderHeader,
}) => {
    const { value: theme } = useTheme();

    const headerHeight = useHeaderHeight();
    const [validForms, setValidForms] = React.useState({});
    const formAnswers = React.useRef({} as Record<number, Record<string, string>>);
    const insets = useSafeAreaInsets();

    const isKeyboardShown = useIsKeyboardShown();

    const validateForm = (formIndex: number, valid: boolean): void => {
        setValidForms((prev) => {
            return { ...prev, [formIndex]: valid };
        });
    };

    const setFormData = (index: number, formData: Record<string, string>): void => {
        formAnswers.current = { ...formAnswers.current, [index]: formData };
    };

    const onSubmit = (): void => {
        onSubmitForms(formAnswers.current);
    };

    const hasFormAnswers = (form: IDynamicForm): boolean => {
        const isAnswered = form.some((field) => !!field.answer);
        return isAnswered;
    };

    const isFormValid = React.useMemo(
        () => Object.values(validForms).filter((valid) => valid).length === forms.length,
        [forms.length, validForms],
    );

    const contStyle: ViewStyle | undefined = useSelectScreen({
        lg: { width: 500, alignSelf: "center" },
        md: { width: 500, alignSelf: "center" },
        default: undefined,
    });

    const bgColor = useSelectScreen({
        lg: theme["color-basic-0"],
        md: theme["color-basic-0"],
        default: undefined,
    });
    if (!forms || forms.length === 0) {
        return null;
    }
    const containerStyle = {
        backgroundColor: theme["background_Secondary"],
    };

    return (
        <Stack
            flex={1}
            style={[
                {
                    backgroundColor: theme["color-background-secondary"],
                },
            ]}>
            <Stack flex={1} style={contStyle}>
                <KeyboardAvoidingView
                    style={[
                        styles.container,
                        containerStyle,
                        { borderTopColor: theme["color-basic-15"] },
                    ]}
                    behavior={Platform.OS === "ios" ? "padding" : "height"}
                    keyboardVerticalOffset={headerHeight}>
                    {renderHeader?.()}
                    <ScrollView
                        style={[styles.scrollViewContent, { backgroundColor: bgColor }]}
                        bounces={false}>
                        <Stack style={styles.scrollViewContentStack}>
                            {forms.map((form, index) => {
                                if (readonly) {
                                    const hasAnswersIfReadOnly = hasFormAnswers(form);
                                    if (!hasAnswersIfReadOnly) {
                                        return null;
                                    }
                                }
                                return (
                                    <>
                                        {index !== 0 ? FormSeparator : null}
                                        <Box
                                            key={index}
                                            style={[
                                                styles.formContainer,
                                                index > 0 ? styles.formRadius : null,
                                            ]}>
                                            <DynamicForm
                                                formData={form as IDynamicForm}
                                                validateForm={validateForm}
                                                formIndex={index}
                                                setFormData={setFormData}
                                                readonly={readonly}
                                            />
                                        </Box>
                                    </>
                                );
                            })}
                        </Stack>
                    </ScrollView>

                    {!readonly ? (
                        <Box
                            style={[
                                styles.submitButtonContainer,
                                {
                                    paddingBottom: isKeyboardShown
                                        ? SpacingValue["space-medium"]
                                        : insets.bottom + SpacingValue["space-medium"],
                                },
                            ]}>
                            <Button
                                disabled={!isFormValid || disabled}
                                elevation={1}
                                onPress={onSubmit}>
                                {submitButtonText}
                            </Button>
                        </Box>
                    ) : null}
                </KeyboardAvoidingView>
            </Stack>
        </Stack>
    );
};

export const DynamicMultipleForms = React.memo(DynamicMultipleFormsComponent);

const styles = StyleSheet.create({
    card: {
        flexShrink: 1,
    },
    svg: {
        marginRight: SpacingValue["space-medium"],
    },
    btn: {
        marginTop: SpacingValue["space-x-small"],
    },
    container: {
        flexGrow: 1,
        height: "100%",
        borderTopWidth: 1,
    },
    scrollViewContent: {
        flexGrow: 1,
        paddingBottom: SpacingValue["space-medium"],
    },
    scrollViewContentStack: {
        paddingBottom: SpacingValue["space-medium"],
    },
    submitButtonContainer: {
        width: "100%",
        paddingHorizontal: SpacingValue["space-medium"],
        paddingTop: SpacingValue["space-small"],
        backgroundColor: "#fff",
    },
    formContainer: {
        paddingTop: SpacingValue["space-x-large"],
        borderBottomLeftRadius: 24,
        borderBottomRightRadius: 24,
        padding: SpacingValue["space-medium"],
        backgroundColor: "#fff",
    },
    formRadius: {
        borderTopLeftRadius: 24,
        borderTopRightRadius: 24,
    },
});
