import * as React from "react";
import { StyleSheet, ViewProps } from "react-native";

import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { CustomProductsEnquireMessage as ICustomProductsEnquireMessage } from "@swiggy-private/connect-chat-commons";

interface CustomProductEnquireMessageProps {
    message: ICustomProductsEnquireMessage;
    isPublisher: boolean;
    style?: ViewProps["style"];
}

export const CustomProductEnquireMessage: React.FC<CustomProductEnquireMessageProps> = ({
    message,
}) => {
    const { value: theme } = useTheme();
    const payload = message.payload;
    const enquiryMessage = payload.text;

    const customProducts = payload.customProductList;

    const backgroundColor = {
        backgroundColor: theme["color-warning-25"],
        borderColor: theme["color-warning-200"],
    };

    return (
        <Box style={styles.container}>
            <Stack
                style={[styles.productContainer, backgroundColor]}
                spacing={SpacingValue["space-x-small"]}>
                {customProducts.map((item, index) => (
                    <Bullet text={item} key={index} />
                ))}
            </Stack>

            <Box mt={SpacingValue["space-x-small"]}>
                <Text category="b2" weight="regular" color="high">
                    {enquiryMessage}
                </Text>
            </Box>
        </Box>
    );
};

const Bullet: React.FC<{ text: string }> = ({ text }) => {
    const { value: theme } = useTheme();

    return (
        <Stack direction="row" spacing={SpacingValue["space-x-small"]} alignItems="center">
            <Box style={[styles.bullet, { backgroundColor: theme["color-basic-75"] }]} />

            <Box flex={1}>
                <Text category="b2" color="high">
                    {text}
                </Text>
            </Box>
        </Stack>
    );
};

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        width: "100%",
        minWidth: 100,
        maxWidth: "100%",
        overflow: "hidden",
    },
    productContainer: {
        borderWidth: StyleSheet.hairlineWidth,
        borderRadius: 12,
        padding: SpacingValue["space-medium"],
    },
    bullet: {
        height: 4,
        width: 4,
        borderRadius: 8,
    },
});
