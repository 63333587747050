import { Platform, Share, ShareContent, ShareOptions } from "react-native";

import { useCopyToClipboard } from "@minis-consumer/hooks/use-copy";

import memoize from "memoize-one";

export type IPlatform = "mobile" | "Instagram" | "Facebook" | "Webview" | "WhatsApp" | "";

export type TshareParam = { cb?: VoidFunction; message?: string };

export const getUserAgentAndApp = memoize((): [string, IPlatform] => {
    if (Platform.OS === "web" && typeof navigator !== "undefined") {
        const userAgent = navigator.userAgent;

        if (/instagram/i.test(userAgent)) {
            return [userAgent, "Instagram"];
        }

        if (/whatsapp/i.test(userAgent)) {
            return [userAgent, "WhatsApp"];
        }

        if (/FBAN|FBAV|FBDV/i.test(userAgent)) {
            return [userAgent, "Facebook"];
        }

        if (userAgent.includes("wv")) {
            return [userAgent, "Webview"];
        }

        if (/mobile/i.test(userAgent)) {
            return [userAgent, "mobile"];
        }

        return [userAgent, "mobile"];
    }
    return ["", ""];
});

// * Shares the content on Apps, meanwhile copies the link on Mweb
export const useShareCheckForInApp = (): ((
    shareContent: ShareContent,
    shareOption?: ShareOptions,
    message?: string,
) => Promise<void>) => {
    const [, platform] = getUserAgentAndApp();
    const copyLink = useCopyToClipboard();
    const executeShare = async (
        shareContent: ShareContent,
        shareOption?: ShareOptions,
        message?: string,
    ): Promise<void> => {
        try {
            // * Webview, share is supported on few browsers
            if (platform) {
                throw "Share might not work here..!";
            }

            await Share.share(shareContent, shareOption);
        } catch (e) {
            copyLink({ message: message ?? "" });
        }
    };

    return executeShare;
};

export const getMobileOperatingSystem = (): string => {
    const userAgent = navigator.userAgent || navigator.vendor;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "WINDOWS";
    }

    if (/android/i.test(userAgent)) {
        return "ANDROID";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent)) {
        return "IOS";
    }

    return "UNKNOWN";
};
