import { AppConfig } from "@minis-consumer/config";
import { Endpoint, HttpClient } from "@swiggy-private/http-client";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const httpClient = new HttpClient<any>();

export interface CashfreeOrderPayParams {
    order_token: string;
    payment_method: {
        netbanking?: {
            channel: "link";
            netbanking_bank_code: number;
        };
        upi?: {
            channel: "collect" | "link";
            upi_id?: string;
            upi_expiry_minutes?: number;
        };
        card?:
            | {
                  channel: "link";
                  card_number: string;
                  card_holder_name: string;
                  card_expiry_mm: string;
                  card_expiry_yy: string;
                  card_cvv: string;
              }
            | {
                  channel: "link";
                  card_alias: string;
                  card_cvv: string;
              };
    };
    save_instrument?: boolean;
    save_payment_method?: boolean;
}

export interface CashfreeOrderPayResponse {
    payment_method: "card" | "upi" | "netbanking";
    cf_payment_id: string;
    data: {
        url: string;
        payload?: Record<string, string>;
    };
}

export interface CashfreeErrorResponse {
    message: string;
    code: string;
    type: string;
}

export const cashfreeOrderPayApi = async (
    params: CashfreeOrderPayParams,
): Promise<CashfreeOrderPayResponse> => {
    const endpoint = Endpoint.from(`${AppConfig.CASHFREE_ENDPOINT}/orders/pay`);

    const response = await httpClient.post({
        endpoint,
        body: {
            ...params,
        },
        headers: {
            "x-api-version": "2021-05-21",
        },
    });

    if ((response as CashfreeErrorResponse).code) {
        throw new Error((response as CashfreeErrorResponse).message);
    }

    return response;
};
