import { AppConfig } from "@minis-consumer/config";
import { Endpoint, HttpClient } from "@swiggy-private/http-client";

const httpClient = new HttpClient<CardInfoResponse | CardInfoErrorResponse>();

interface CardInfoParams {
    cardBin: string;
}

export interface CardInfoResponse {
    id: string;
    object: "cardbin";
    brand: "VISA" | "MASTERCARD" | "AMEX" | "DINERS" | "RUPAY";
    bank: string;
    country: string;
    type: "CREDIT | DEBIT";
    card_sub_type: string;
    juspay_bank_code: string;
}

export interface CardInfoErrorResponse {
    status: string;
    error_code: string;
    error_message: string;
}

export const getCardInfo = async (params: CardInfoParams): Promise<CardInfoResponse> => {
    const endpoint = Endpoint.from(`${AppConfig.JUSPAY_ENDPOINT}/cardbins/{%%cardBin%%}`, {
        cardBin: params.cardBin,
    });

    const response = await httpClient.get({
        endpoint,
    });

    if ((response as CardInfoErrorResponse).error_code) {
        throw new Error((response as CardInfoErrorResponse).error_message);
    }

    return response as CardInfoResponse;
};
