import * as React from "react";

import { IDeliveryInfo } from "@minis-consumer/interfaces/delivery";

export interface IStoreDeliveryInfoSnackContext {
    isOpen: boolean;
    close: VoidFunction;
    open: VoidFunction;
    setDeliveryInfo: (dI?: IDeliveryInfo) => void;

    deliveryInfo?: IDeliveryInfo;
}

export const StoreDeliveryInfoSnackbarContext =
    React.createContext<IStoreDeliveryInfoSnackContext | null>(null);

export const StoreDeliveryInfoSnackbarContextProvider: React.FC<React.PropsWithChildren> = ({
    children,
}) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [deliveryInfo, setDeliveryInfo] = React.useState<IDeliveryInfo>();

    const close = React.useCallback(() => setIsOpen(false), []);
    const open = React.useCallback(() => setIsOpen(true), []);

    const value = React.useMemo(
        () => ({ isOpen, close, open, deliveryInfo, setDeliveryInfo }),
        [isOpen, close, open, deliveryInfo, setDeliveryInfo],
    );

    return (
        <StoreDeliveryInfoSnackbarContext.Provider value={value}>
            {children}
        </StoreDeliveryInfoSnackbarContext.Provider>
    );
};
