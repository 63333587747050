import { Endpoint } from "@rest-hooks/endpoint";
import { Entity } from "@rest-hooks/rest";

import { unique } from "@minis-consumer/helpers/array";
import {
    CancellationDetails,
    Order,
    OrderAddress,
    OrderBill,
    OrderItem,
    OrderMetaData,
    OrderRating,
    OrderRefund,
    OrderStatus,
    OrderStore,
    OrderUser,
    PaymentType,
    ShippingDetails,
} from "@minis-consumer/interfaces/order";
import { ReturnDetails } from "@minis-consumer/interfaces/return-order";
import { PRODUCT_TYPE } from "@minis-consumer/interfaces/catalog";

import {
    cancelOrderApi,
    getOrderDetailsApi,
    getAllOrdersApi,
    AllOrdersResponse,
    rateOrderApi,
} from "../api/order";

export class OrderEntity extends Entity implements Order {
    readonly id: string = "0";
    readonly orderTotal: number = 0;

    readonly bill!: OrderBill;

    readonly txnId?: string;
    readonly shippingId?: string;
    readonly items: OrderItem[] = [];

    readonly userDetails!: OrderUser;
    readonly storeDetails!: OrderStore;
    readonly rating?: OrderRating | undefined;
    readonly pickupAddress!: OrderAddress;
    readonly dropAddress!: OrderAddress;
    readonly metadata!: OrderMetaData;
    readonly status!: OrderStatus;
    readonly cancellationDetails!: CancellationDetails;
    readonly shippingDetails!: ShippingDetails;
    readonly orderTimeout!: number;
    readonly orderType?: PRODUCT_TYPE;
    readonly paymentMethod!: PaymentType;
    readonly statusUpdatedAt!: number;
    readonly refundDetails?: OrderRefund;
    readonly returnDetails?: ReturnDetails;

    readonly createdAt: number = 0;
    readonly updatedAt: number = 0;
    readonly guestOrder: boolean = false;

    get storeId(): string {
        return this.storeDetails.id;
    }

    pk(): string {
        return this.id;
    }

    static override get key(): string {
        return "Order";
    }
}

export const OrderDetail = new Endpoint(getOrderDetailsApi, {
    schema: OrderEntity,
    invalidIfStale: true,
});

export const OrderCancel = new Endpoint(cancelOrderApi, {
    schema: OrderEntity,
    sideEffect: true,
});

export const OrderList = new Endpoint(getAllOrdersApi, {
    schema: { orders: [OrderEntity], cursor: "" },

    update: (newResponse: AllOrdersResponse, params: { storeId?: string; cursor?: string }) => {
        return {
            [OrderList.key({ storeId: params.storeId })]: (
                oldResponse: AllOrdersResponse = { cursor: "", orders: [] },
            ) => {
                return params.cursor
                    ? {
                          ...newResponse,
                          orders: unique([...oldResponse.orders, ...newResponse.orders]),
                      }
                    : newResponse;
            },
        };
    },
});

export const RateOrder = new Endpoint(rateOrderApi, {
    key: ({ orderId }) => `rate-order/${orderId}`,
});
