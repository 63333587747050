import * as React from "react";

export interface ICustomCartContext {
    state: { keys: Record<string, string> };
    addCustomCartKey: (cartKey: string, storeId: string) => void;
    isCustomCartKeyUsedForStore: (storeId: string) => boolean;
}

const CustomCartContext = React.createContext<ICustomCartContext>({
    state: { keys: {} },
    addCustomCartKey: () => undefined,
    isCustomCartKeyUsedForStore: () => false,
});

export const CustomCartContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [customCartState, setKey] = React.useState<{ keys: Record<string, string> }>({
        keys: {},
    });

    const isCustomCartKeyUsedForStore = React.useCallback(
        (storeId: string) => !!customCartState.keys[storeId],
        [customCartState.keys],
    );

    const addCustomCartKey = React.useCallback(
        (cartKey: string, storeId: string) => {
            if (customCartState.keys[storeId] === cartKey) {
                return;
            }

            setKey({
                keys: {
                    ...customCartState.keys,
                    [storeId]: cartKey,
                },
            });
        },
        [customCartState.keys],
    );

    const value = React.useMemo(
        () => ({ addCustomCartKey, isCustomCartKeyUsedForStore, state: customCartState }),
        [addCustomCartKey, customCartState, isCustomCartKeyUsedForStore],
    );

    return <CustomCartContext.Provider value={value}>{children}</CustomCartContext.Provider>;
};

export const useCustomCartContext = (): ICustomCartContext => React.useContext(CustomCartContext);
