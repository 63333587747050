import { Entity, schema } from "@rest-hooks/rest";
import { Endpoint } from "@rest-hooks/endpoint";

import { fetchCatalogView, fetchProductView } from "../api/catalog";
import type { Product, ProductBadge, ProductCategory, PRODUCT_TYPE } from "../interfaces/catalog";

export class ProductEntity extends Entity implements Product {
    readonly id: string = "";
    readonly storeId: string = "";
    readonly name: string = "";
    readonly description?: string;
    readonly badges: string[] = [];
    readonly categories: string[] = [];
    readonly inStock: boolean = false;
    readonly active: boolean = false;
    // old-ways for storing images-id
    readonly images: string[] = [];
    // new ways for storing medias-id
    readonly mediaIds: string[] = [];
    readonly storeName: string = "";
    readonly storeVerified: boolean = false;
    readonly weight?: number;
    readonly price?: number;
    readonly discountedPrice?: number;
    readonly productType?: PRODUCT_TYPE;

    pk(): string {
        return this.id;
    }

    static override get key(): string {
        return "Product";
    }
}

export class ProductCategoryEntity extends Entity implements ProductCategory {
    readonly id: string = "";
    readonly storeId: string = "";
    readonly name: string = "";
    readonly products: string[] = [];

    readonly systemCategory?: boolean;
    readonly displayOrder?: number;

    pk(): string {
        return this.id + "" + this.storeId;
    }

    static override get key(): string {
        return "ProductCategory";
    }
}

export class ProductBadgeEntity extends Entity implements ProductBadge {
    readonly id: string = "";
    readonly name: string = "";
    readonly active: boolean = false;
    readonly products: string[] = [];

    pk(): string {
        return this.id;
    }

    static override get key(): string {
        return "ProductBadge";
    }
}

export const CatalogView = new Endpoint(fetchCatalogView, {
    schema: new schema.Object({
        products: new schema.Array(ProductEntity),
        categories: new schema.Array(ProductCategoryEntity),
        badges: new schema.Array(ProductBadgeEntity),
    }),
});

export const ProductView = new Endpoint(fetchProductView, {
    schema: new schema.Object({
        product: new schema.Object(ProductEntity),
        categories: new schema.Array(ProductCategoryEntity),
        badges: new schema.Array(ProductBadgeEntity),
        recommendedProductIds: new schema.Array(String),
    }),
    key: ({ productId }) => "product_view_" + productId,
});
