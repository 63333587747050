import * as React from "react";

import { ClearCartModalContext } from "@minis-consumer/contexts/clear-cart-modal-context";

interface ModalDataHandlerParams {
    storeId: string;
    descriptionPrefix: string;

    onClearCartCallback?: VoidFunction;
}
export const useClearCartModalHandler = (): {
    isOpen: boolean;
    storeId: string;
    descriptionPrefix: string;
    modalViewHandler: (b: boolean, c?: boolean) => void;
    dataHandler: (props: ModalDataHandlerParams) => void;
} => {
    const ctx = React.useContext(ClearCartModalContext);

    const dataHandler = React.useCallback(
        ({ storeId, descriptionPrefix, onClearCartCallback }: ModalDataHandlerParams) => {
            storeId !== undefined && ctx?.setStoreId(storeId);
            descriptionPrefix !== undefined && ctx?.setDescriptionPrefix(descriptionPrefix);
            onClearCartCallback !== undefined && ctx?.setClearCartCallback?.(onClearCartCallback);
        },
        [ctx],
    );

    const modalViewHandler = React.useCallback(
        (open: boolean, isCartCleared?: boolean) => {
            if (open) {
                ctx?.open();
            } else {
                ctx?.close();
                if (isCartCleared) {
                    ctx?.onClearCartCallback?.();
                    ctx?.setClearCartCallback?.(() => null);
                }
            }
        },
        [ctx],
    );

    return {
        isOpen: ctx?.isOpen ?? false,
        storeId: ctx?.storeId ?? "",
        descriptionPrefix: ctx?.descriptionPrefix ?? "",
        modalViewHandler,
        dataHandler,
    };
};
