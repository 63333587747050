import { getObjectValue, setValue } from "@swiggy-private/common-helpers";

export class RecentMinisTracker {
    private static _storageKey = "@minis_recentMinis";
    private static _instance: RecentMinisTracker | null = null;

    private _timeout = 0;
    private _recentMinis: string[] = [];

    private constructor() {
        //
    }

    static getInstance(): RecentMinisTracker {
        if (RecentMinisTracker._instance == null) {
            RecentMinisTracker._instance = new RecentMinisTracker();
            return RecentMinisTracker._instance;
        }

        return RecentMinisTracker._instance;
    }

    get(): string[] {
        return this._recentMinis;
    }

    add(storeId: string): void {
        this._recentMinis = [storeId, ...this._recentMinis.filter((i) => i !== storeId)];
        this._recentMinis = this._recentMinis.slice(0, 30);

        this.save();
    }

    static async load(): Promise<RecentMinisTracker> {
        const recentMinis = await getObjectValue(RecentMinisTracker._storageKey);
        if (Array.isArray(recentMinis)) {
            RecentMinisTracker.getInstance()._recentMinis = recentMinis;
        }

        return RecentMinisTracker.getInstance();
    }

    save(): void {
        clearTimeout(this._timeout);
        this._timeout = setTimeout(() => {
            setValue(RecentMinisTracker._storageKey, this._recentMinis ?? []);
        }, 0) as unknown as number;
    }
}
