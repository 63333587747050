import * as React from "react";
import { SSRLayoutContext, ScreenSize } from "@swiggy-private/rn-adaptive-layout";
import { isServer } from "@minis-consumer/helpers/server";

export interface ISSRContext {
    isMobileScreen?: boolean;
    children: React.ReactNode;
}

export const SSRContext = (props: ISSRContext): React.ReactElement<ISSRContext> => {
    const { isMobileScreen, children } = props;

    return (
        <SSRLayoutContext.Provider
            value={{
                screenType: isMobileScreen ? ScreenSize.Normal : ScreenSize.Large,
                isServer: isServer(),
            }}>
            {children}
        </SSRLayoutContext.Provider>
    );
};
