import React from "react";
import { StyleSheet, useWindowDimensions, TouchableWithoutFeedback, Keyboard } from "react-native";

import { CdnImage } from "@swiggy-private/react-native-ui";
import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { ChatConversationEmptyProps } from "@swiggy-private/connect-chat-ui";
import { Box } from "@swiggy-private/rn-adaptive-layout";

import { useSignedIn } from "@minis-consumer/hooks/use-user";

const DESCRIPTION =
    "You can use this space to discuss any \nquestions you have about this brand, inquire \nabout your order, or even share any special \ninstructions.";

const GUEST_USER_DESCRIPTION =
    "Use chat to discuss any inquiries, questions, or \nspecial instructions with us hassle-free.";

export const ChatConversationEmptyComponent: React.FC<ChatConversationEmptyProps> = React.memo(
    ({ participantDeleted = false, closeInfoPopup, showPopup, ChatInfoComponent }) => {
        const { value: theme } = useTheme();
        const { width: windowWidth } = useWindowDimensions();
        const isSignedIn = useSignedIn();

        const defaultWidth = participantDeleted ? 193.22 : 162.1;

        const imgWidth = Math.min(defaultWidth, windowWidth * (participantDeleted ? 0.52 : 0.43));
        const imgHeight = imgWidth * (participantDeleted ? 0.63 : 0.9);

        return (
            <TouchableWithoutFeedback onPress={() => Keyboard.dismiss()} accessible={false}>
                <>
                    {ChatInfoComponent ? (
                        <ChatInfoComponent showPopup={showPopup} closeInfoPopup={closeInfoPopup} />
                    ) : null}
                    <Box
                        justifyContent="center"
                        alignItems="center"
                        flex={1}
                        style={{ backgroundColor: theme["color-basic-5"] }}>
                        <CdnImage
                            isImageKitEnabled
                            id={
                                participantDeleted
                                    ? "minis/empty_chat_participant_deleted"
                                    : "minis/empty_chat"
                            }
                            width={imgWidth}
                            height={imgHeight}
                            style={styles.img}
                        />

                        {!participantDeleted ? (
                            <Text category="b2" color="color-basic-45" style={styles.description}>
                                {isSignedIn ? DESCRIPTION : GUEST_USER_DESCRIPTION}
                            </Text>
                        ) : null}
                    </Box>
                </>
            </TouchableWithoutFeedback>
        );
    },
);

if (__DEV__) {
    ChatConversationEmptyComponent.displayName = "ChatConversationEmptyComponent";
}

const styles = StyleSheet.create({
    img: {
        marginTop: "20%",
        marginBottom: SpacingValue["space-large"],
    },
    description: {
        marginTop: SpacingValue["space-large"],
        textAlign: "center",
    },
});
