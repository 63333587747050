import * as React from "react";
import { useCache, useController } from "@rest-hooks/react";
import { useLoading } from "@rest-hooks/hooks";

import { useMountedRef } from "@swiggy-private/react-hooks";
import { ApiError, API_FAILURE_STORE_DELETED_CODE } from "@swiggy-private/connect-api-core";
import { assertIsDefined } from "@swiggy-private/react-native-ui";

import {
    followStore as followStoreApi,
    unfollowStore as unfollowStoreApi,
} from "@minis-consumer/api/store";
import { StoreFollowStatus } from "@minis-consumer/resources/store";
import { Logger } from "@minis-consumer/includes/logger";

import { useStoreInfo } from "./use-store";
import { useToast } from "./use-toast";
import { useSignedIn } from "./use-user";
import { useFollowersCountDispatch } from "./use-followers-count-dispatch";
import { useStoreDeletedErrorSnackbarHandler } from "./use-store-deleted-snack-handler";

const SUCCESS_FOLLOW_MESSAGE = "This mini will now be available for you on Swiggy Minis";

const SUCCESS_UNFOLLOW_MESSAGE = "Successfully unfavorited this mini";

type UseFollowUnfollowStoreParams = {
    id?: string; // storeId
    isFollowStatusRequired?: boolean;
    isStoreFollowed?: boolean;
};

type UseFollowUnfollowStoreReturnValue = {
    canFollow: boolean;
    following: boolean;
    trigger: () => void;
    loading: boolean;
    error?: Error;
};

export const useFollowUnfollowStore = (
    { id, isFollowStatusRequired, isStoreFollowed }: UseFollowUnfollowStoreParams = {
        isFollowStatusRequired: true,
    },
): UseFollowUnfollowStoreReturnValue => {
    const mountedRef = useMountedRef();

    const [, storeDeletedSnackHandler] = useStoreDeletedErrorSnackbarHandler();

    const storeInfo = useStoreInfo(!id);
    const storeId = id ?? storeInfo?.storeId;
    assertIsDefined(storeId);

    const [showToast] = useToast();
    const { receive } = useController();
    const signedIn = useSignedIn();

    const {
        status,
        loading: followStatusLoading,
        fetch: fetchFollowStatus,
    } = useFollowStoreStatus(storeId);

    const followStatus = React.useMemo(() => status ?? isStoreFollowed, [isStoreFollowed, status]);

    const followersCountDispatch = useFollowersCountDispatch();

    const updateFollowersCount = React.useCallback(
        ({ storeFollowersCount }: { storeFollowersCount: number }) => {
            followersCountDispatch({
                payload: { storeId, followersCount: storeFollowersCount },
                type: "UPDATE_FOLLOWERS_COUNT_ACTION",
            });
        },
        [followersCountDispatch, storeId],
    );

    const [followStore, followLoading, followError] = useLoading(async () => {
        try {
            const res = await followStoreApi({ storeId });
            updateFollowersCount(res);
            if (mountedRef.current && res) {
                showToast(SUCCESS_FOLLOW_MESSAGE);
                receive(StoreFollowStatus, { storeId }, { follow: true });
            }
        } catch (err) {
            if (
                err instanceof ApiError &&
                err.errorCode === API_FAILURE_STORE_DELETED_CODE &&
                mountedRef.current
            ) {
                return storeDeletedSnackHandler(true);
            }

            Logger.recordError(err);
            return mountedRef.current && showToast("Something went wrong");
        }
    }, [mountedRef, receive, showToast, storeId, updateFollowersCount, storeDeletedSnackHandler]);

    const [unfollowStore, unfollowLoading, unfollowError] = useLoading(async () => {
        await unfollowStoreApi({ storeId }).then(updateFollowersCount).catch(Logger.recordError);

        if (mountedRef.current) {
            showToast(SUCCESS_UNFOLLOW_MESSAGE);
            receive(StoreFollowStatus, { storeId }, { follow: false });
        }
    }, [mountedRef, receive, showToast, storeId, updateFollowersCount]);

    /** Show Toast on Error */
    React.useEffect(() => {
        const error = followError || unfollowError;
        if (error) {
            showToast(error.message);
        }
    }, [followError, showToast, unfollowError]);

    /** Fetch API on Mount */
    React.useEffect(() => {
        if (signedIn && followStatus == null && isFollowStatusRequired) {
            fetchFollowStatus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signedIn]);

    return {
        following: followStatus ?? false,
        trigger: followStatus ? unfollowStore : followStore,
        loading: followStatusLoading || followLoading || unfollowLoading,
        error: followError || unfollowError,
        canFollow: signedIn,
    };
};

type UseFollowStoreStatusReturnValue = {
    status: boolean | null;
    loading: boolean;
    error?: Error;
    fetch: () => void;
};

export const useFollowStoreStatus = (id?: string): UseFollowStoreStatusReturnValue => {
    const storeInfo = useStoreInfo(!id);
    const signedIn = useSignedIn();

    const storeId = id ?? storeInfo?.storeId;
    assertIsDefined(storeId);

    const cacheStatus = useCache(StoreFollowStatus, { storeId });
    const { fetch } = useController();

    const [followStatus, statusLoading, statusError] = useLoading(async () => {
        if (!signedIn || cacheStatus != null) {
            return;
        }

        await fetch(StoreFollowStatus, { storeId });
    }, [cacheStatus, fetch, signedIn, storeId]);

    return {
        status: cacheStatus?.follow ?? null,
        loading: statusLoading,
        error: statusError,
        fetch: followStatus,
    };
};
