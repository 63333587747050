import * as React from "react";

import { Product, Variant } from "@minis-consumer/interfaces/catalog";

export interface IProductEnquiryModalContext {
    isOpen: boolean;
    close: () => void;
    open: () => void;
    setProductId: (e: string) => void;
    // for instagram image enquiry, use later
    setImageUrl: (e: string) => void;
    setStoreId: (e: string) => void;
    setProductDetails: (e: Product) => void;
    setSelectedVariant: (e?: Variant) => void;

    productId?: string;
    imageUrl?: string;
    storeId?: string;
    selectedVariant?: Variant | null;

    /** This is for routes apart from PDP and store routes */
    productDetails?: Product;
}

export const ProductEnquiryModalContext = React.createContext<IProductEnquiryModalContext | null>(
    null,
);

export const ProductEnquiryModalContextProvider: React.FC<React.PropsWithChildren> = ({
    children,
}) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [productId, setProductId] = React.useState("");
    const [imageUrl, setImageUrl] = React.useState("");
    const [storeId, setStoreId] = React.useState("");
    const [productDetails, setProductDetails] = React.useState<Product>();
    const [selectedVariant, setSelectedVariant] = React.useState<Variant | undefined>();

    const close = React.useCallback(() => setIsOpen(false), []);
    const open = React.useCallback(() => setIsOpen(true), []);

    const value = React.useMemo(
        () => ({
            isOpen,
            close,
            open,
            productId,
            imageUrl,
            storeId,
            productDetails,
            selectedVariant,
            setProductId,
            setImageUrl,
            setStoreId,
            setProductDetails,
            setSelectedVariant,
        }),
        [
            isOpen,
            close,
            open,
            productId,
            imageUrl,
            storeId,
            productDetails,
            selectedVariant,
            setProductId,
            setImageUrl,
            setStoreId,
            setProductDetails,
            setSelectedVariant,
        ],
    );

    return (
        <ProductEnquiryModalContext.Provider value={value}>
            {children}
        </ProductEnquiryModalContext.Provider>
    );
};
