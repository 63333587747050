import * as React from "react";

import { SpacingValue } from "@swiggy-private/rn-dls";

import { Shimmer } from "@minis-consumer/components/shimmer";

import { Wrapper } from "./wrapper";

const FallbackComponent: React.FC = () => {
    return (
        <Wrapper>
            <Shimmer
                width={175}
                height={50}
                style={{ paddingVertical: SpacingValue["space-medium"] }}
            />
        </Wrapper>
    );
};

export const Fallback = React.memo(FallbackComponent);
