import * as React from "react";
import { StyleSheet } from "react-native";
import formatRelative from "date-fns/formatRelative";

import { SpacingValue, useTheme, Text } from "@swiggy-private/rn-dls";
import { Stack, Box } from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";

type SellerStatusIndicatorProps = {
    sellerStatus: "online" | "offline" | "closed";
    lastSeenAt?: number;
};

const SellerStatusIndicatorComponent: React.FC<SellerStatusIndicatorProps> = ({
    sellerStatus,
    lastSeenAt,
}) => {
    const { value: theme } = useTheme();

    const statusMeta = React.useMemo(() => {
        switch (sellerStatus) {
            case "closed":
                return { description: "Permanently closed" };
            case "offline":
                if (lastSeenAt) {
                    return {
                        description: `Last seen ${formatRelative(lastSeenAt, new Date())}`,
                        indicatorColor: theme["color-basic-30"],
                    };
                }

                return { description: "Offline", indicatorColor: theme["color-basic-30"] };
            default:
                return { description: "Online now", indicatorColor: "#1BA672" };
        }
    }, [lastSeenAt, sellerStatus, theme]);

    return (
        <Stack direction="row" spacing={SpacingValue["space-xx-small"]} alignItems="center">
            {sellerStatus === "closed" ? (
                <SvgIcon icon="CrossFilled" color="color-critical-200" height={10} width={10} />
            ) : lastSeenAt && sellerStatus === "offline" ? null : (
                <Box
                    style={[
                        styles.indicator,
                        {
                            backgroundColor: statusMeta.indicatorColor,
                        },
                    ]}
                />
            )}

            <Text category="b3" color="color-basic-60">
                {statusMeta.description}
            </Text>
        </Stack>
    );
};

const styles = StyleSheet.create({
    indicator: {
        height: 8,
        width: 8,
        borderRadius: 4,
    },
});

export const SellerStatusIndicator = React.memo(SellerStatusIndicatorComponent);
