import React, { useCallback } from "react";
import { Platform, StyleProp, StyleSheet, ViewStyle } from "react-native";

import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";

import { Divider } from "@minis-consumer/components/divider";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { useIsDesktopWeb } from "@minis-consumer/hooks/use-dweb";
import { ProductPrice } from "@minis-consumer/components/product-price";
import { useSelectedVariant, useVariantsList } from "@minis-consumer/hooks/use-variants";
import { useGetProductCtaProps } from "@minis-consumer/components/product-cta/hooks/use-get-cta-actions";
import { ProductCta } from "@minis-consumer/components/product-cta";
import { Product } from "@minis-consumer/interfaces/catalog";
import { IOScrollView } from "@swiggy-private/react-native-ui";

import { VariantOptionsList } from "../options-list";

export interface VariantOptionsSelectionProps {
    id: string;
    onAdd: (variantId?: string) => void;
    onRemove: (variantId?: string) => void;

    type: Product["productType"];
    onClose?: VoidFunction;
    containerStyles?: StyleProp<ViewStyle>;
    footerStyles?: StyleProp<ViewStyle>;
}

const OptionsSelection: React.FC<VariantOptionsSelectionProps> = ({
    id,
    type,
    onAdd,
    onRemove,
    onClose,
    containerStyles = {},
    footerStyles = {},
}) => {
    const { value: theme } = useTheme();

    const { storeId } = useStoreInfo();
    const isDesktop = useIsDesktopWeb();
    const { variantOptions, variantDetails } = useVariantsList(id);
    const { selectedVariant, selectedVariantOptions, onVariantPress } = useSelectedVariant(id);

    const { price, discountedPrice, discountPercent, id: variantId } = selectedVariant || {};

    const buttonStyle = {
        borderColor: true ? "transparent" : theme["color-basic-15"],
    };

    const spacingHorizontal: ViewStyle = {
        paddingHorizontal: !isDesktop ? SpacingValue["space-medium"] : 0,
    };

    const onIncrement = useCallback(() => {
        onAdd?.(variantId);
    }, [variantId, onAdd]);

    const onDecrement = useCallback(() => {
        onRemove?.(variantId);
    }, [onRemove, variantId]);

    const productCtaActionsAndStyles = useGetProductCtaProps({
        productId: id,
        storeId,
        buttonProps: {
            style: [styles.actionBtn, buttonStyle],
            elevation: 0,
            color: "primary",
            textStyle: {
                color: theme["color-basic-0"],
            },
        },
        selectedVariant: selectedVariant?.id,
        isVariantList: true,
        productActionCtaName: "variants-product-action-cta",
        onAdd: onIncrement,
        onRemoveOrDecrement: onDecrement,
        onEnquire: onClose,
    });

    return (
        <Stack style={[styles.container, containerStyles]} spacing={SpacingValue["space-x-large"]}>
            <Stack spacing={SpacingValue["space-xx-large"]}>
                <VariantOptionsList
                    options={variantOptions}
                    variantDetails={variantDetails}
                    selectedVariants={selectedVariantOptions}
                    selectedVariantDetails={selectedVariant}
                    onPress={onVariantPress}
                    containerStyles={spacingHorizontal}
                />
                <Box style={spacingHorizontal}>
                    <Divider color="color-basic-5" />
                </Box>
            </Stack>

            {selectedVariant ? (
                <IOScrollView>
                    <Stack
                        style={[spacingHorizontal, footerStyles]}
                        alignItems="center"
                        direction="row"
                        spacing={isDesktop ? SpacingValue["space-xx-large"] : 0}
                        justifyContent="space-between">
                        <ProductPrice
                            price={price}
                            discountPercent={discountPercent}
                            discountedPrice={discountedPrice}
                        />

                        <ProductCta
                            {...productCtaActionsAndStyles}
                            productId={id}
                            price={price}
                            type={type}
                            storeId={storeId}
                            isInVariantComponent
                            selectedVariant={selectedVariant}
                            onCloseDialog={onClose}
                        />
                    </Stack>
                </IOScrollView>
            ) : null}
        </Stack>
    );
};

const styles = StyleSheet.create({
    container: {
        paddingBottom: Platform.OS !== "web" ? SpacingValue["space-xx-large"] : 0,
    },
    sheet: {
        backgroundColor: "transparent",
    },
    actionBtn: {
        flex: 1,
        minHeight: 40,
        height: 40,
        maxWidth: 150,
        minWidth: 140,
        borderRadius: 8,
        paddingHorizontal: SpacingValue["space-medium"],
    },
});

if (__DEV__) {
    OptionsSelection.displayName = "VariantOptionsSelection";
}

export const VariantOptionsSelection = React.memo(OptionsSelection);
