import {
    CONSUMER_SERVICE_HOST,
    MEDIA_BASE_URL,
    APP_ENV,
    CHAT_SERVICE_HOST,
    PUBNUB_PUBLISH_KEY,
    PUBNUB_SUBSCRIBE_KEY,
    CASHFREE_ENDPOINT,
    ANALYTICS_APP_NAME,
    ANALYTICS_ENABLED,
    ANALYTICS_EVENT_NAME,
    ANALYTICS_SCHEMA_VERSION,
    ANALYTICS_URL,
    JUSPAY_ENDPOINT,
    GOOGLE_API_KEY,
    SELLER_SERVICE_HOST,
    NEW_RELIC_INSIGHTS_ENDPOINT,
    NEW_RELIC_API_KEY,
    IMAGEKIT_MEDIA_BASE_URL,
    IMAGEKIT_CLOUDNAME,
} from "@env";
import { isServer } from "@minis-consumer/helpers/server";

export const getConfig = (key: string, defaultValue: string): string => {
    if (isServer()) {
        return process.env[key] ?? defaultValue;
    }

    if (
        typeof window !== "undefined" &&
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        typeof window.__CONFIG__ === "object" &&
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.__CONFIG__[key] != null
    ) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return window.__CONFIG__[key];
    }

    return defaultValue;
};

export const AppConfig = {
    APP_ENV: getConfig("APP_ENV", APP_ENV),
    SELLER_SERVICE_HOST: getConfig("SELLER_SERVICE_HOST", SELLER_SERVICE_HOST),
    CONSUMER_SERVICE_HOST: getConfig("CONSUMER_SERVICE_HOST", CONSUMER_SERVICE_HOST),
    MEDIA_BASE_URL: getConfig("MEDIA_BASE_URL", MEDIA_BASE_URL),
    CHAT_SERVICE_HOST: getConfig("CHAT_SERVICE_HOST", CHAT_SERVICE_HOST),
    PUBNUB_PUBLISH_KEY: getConfig("PUBNUB_PUBLISH_KEY", PUBNUB_PUBLISH_KEY),
    PUBNUB_SUBSCRIBE_KEY: getConfig("PUBNUB_SUBSCRIBE_KEY", PUBNUB_SUBSCRIBE_KEY),
    CASHFREE_ENDPOINT: getConfig("CASHFREE_ENDPOINT", CASHFREE_ENDPOINT),
    JUSPAY_ENDPOINT: getConfig("JUSPAY_ENDPOINT", JUSPAY_ENDPOINT),

    ANALYTICS_APP_NAME: getConfig("ANALYTICS_APP_NAME", ANALYTICS_APP_NAME),
    ANALYTICS_ENABLED: getConfig("ANALYTICS_ENABLED", ANALYTICS_ENABLED) === "true",
    ANALYTICS_EVENT_NAME: getConfig("ANALYTICS_EVENT_NAME", ANALYTICS_EVENT_NAME),
    ANALYTICS_SCHEMA_VERSION: getConfig("ANALYTICS_SCHEMA_VERSION", ANALYTICS_SCHEMA_VERSION),
    ANALYTICS_URL: getConfig("ANALYTICS_URL", ANALYTICS_URL),

    GOOGLE_API_KEY: getConfig("GOOGLE_API_KEY", GOOGLE_API_KEY),

    NEW_RELIC_INSIGHTS_ENDPOINT: getConfig(
        "NEW_RELIC_INSIGHTS_ENDPOINT",
        NEW_RELIC_INSIGHTS_ENDPOINT,
    ),

    NEW_RELIC_API_KEY: getConfig("NEW_RELIC_API_KEY", NEW_RELIC_API_KEY),

    IMAGEKIT_MEDIA_BASE_URL: getConfig("IMAGEKIT_MEDIA_BASE_URL", IMAGEKIT_MEDIA_BASE_URL),
    IMAGEKIT_CLOUDNAME: getConfig("IMAGEKIT_CLOUDNAME", IMAGEKIT_CLOUDNAME),
};
