import { SvgIconType } from "@swiggy-private/connect-svg-icons";

interface Bullets {
    icon: SvgIconType["icon"];
    text: string;
}

export const DISPLAY_COPIES = {
    LOGIN_CTA_HELPER: "Only when you",
    LOGIN_WITH_SWIGGY: "Login with Swiggy",
    BULLETS: [
        {
            icon: "Package",
            text: "Track your orders easily",
        },
        {
            icon: "Chat",
            text: "Chat and get support to resolve your queries",
        },
        {
            icon: "Info",
            text: "Stay tuned with their updates! (Coming soon)",
        },
    ] as Array<Bullets>,
    PLACE_ORDER: "Login with",
    PLACE_ORDER_SUBTEXT: "to",
};

export const ASSETS = {
    MINIS_LOGO: "static-assets/guest-login/logo",
    SWIGGY_LOGO_TEXT: "static-assets/guest-login/swiggy_logo",
    BACKGROUND: "static-assets/guest-login/background",
    STICKER: "static-assets/guest-login/sticker",
};

export const ASSETS_SIZE = {
    MINIS_LOGO: {
        height: 28,
        width: 84,
    },
    SWIGGY_LOGO_TEXT: {
        height: 24,
        width: 76,
    },
    BACKGROUND: {
        height: 204,
        width: 148,
    },
    STICKER: {
        height: 96,
        width: 96,
    },
    SWIGGY_LOGO: {
        height: 24,
        width: 18,
    },
};

/** TODO: make part of DLS/fetch from DLS */
export const GRADIENT_COLORS = ["rgba(255, 82, 0, 1)", "rgba(237, 140, 86, 1)"];
export const GRADIENT_LOCATION = [0, 1];
export const SHADOW_DEFAULT_COLOR = "#FF5200";
export const BULLET_SIZE = 48;
export const CTA_ARD_EVENT = "login-with-swiggy";
