import { Dispatch, Reducer, ReducerAction, ReducerState, useReducer } from "react";

import { AppAction } from "./actions";
import { IAppUser } from "./types";

export interface AppState {
    user: IAppUser | null;
}

function appReducer(state: Readonly<AppState>, action: Readonly<AppAction>): Readonly<AppState> {
    switch (action.type) {
        case "SET_USER_ACTION":
            return {
                ...state,
                user: action.payload,
            };
    }
}

export function useAppReducer(
    initialState: AppState,
): [
    ReducerState<Reducer<AppState, AppAction>>,
    Dispatch<ReducerAction<Reducer<AppState, AppAction>>>,
] {
    return useReducer(appReducer, initialState);
}
