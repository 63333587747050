import { Analytics } from "@minis-consumer/analytics";
import { IHightlight, IStory } from "@minis-consumer/interfaces/store";
import { SwiggyRNModule } from "@minis-consumer/includes/swiggy-rn-module";
import { SF_ANALYTICS_EVENT_NAMES } from "@minis-consumer/routes/shop/constants";

import { StoriesAnalytics } from "@swiggy-private/react-native-stories";

export const storiesAnalytics = (storeId: string, highlights: IHightlight[]): StoriesAnalytics => {
    let highlightIndex = 0;
    let storyIndex = 0;

    let selectedHighlight: IHightlight;

    let selectedStory: IStory;

    const setStoryIndex = (index: number): void => {
        storyIndex = index;
        if (!selectedHighlight) {
            return;
        }
        selectedStory = selectedHighlight.stories[storyIndex];
    };

    const setHighlightIndex = (index: number): void => {
        highlightIndex = index;
        selectedHighlight = highlights[highlightIndex];
    };

    const sendHighlightClick = (): void => {
        if (!selectedHighlight) {
            return;
        }
        Analytics.clickEvent({
            category: SF_ANALYTICS_EVENT_NAMES.STORY_HIGHLIGHTS,
            context: JSON.stringify({
                storiesCount: selectedHighlight.stories.length,
                highlightId: selectedHighlight.id,
                highlightTitle: selectedHighlight.title,
            }),
        });
    };

    const sendHighlightImpression = (id: string): void => {
        const currentHighlight = highlights.find((highlight) => highlight.id === id);
        if (!currentHighlight) {
            return;
        }
        Analytics.impressionEvent({
            category: SF_ANALYTICS_EVENT_NAMES.STORY_HIGHLIGHTS,
            context: JSON.stringify({
                storiesCount: currentHighlight.stories.length,
                highlightId: currentHighlight.id,
                highlightTitle: currentHighlight.title,
            }),
        });
    };

    const sendProgressBarClick = (): void => {
        if (!selectedHighlight) {
            return;
        }
        Analytics.clickEvent({
            category: "highlights-bar",
            context: JSON.stringify({
                storeId,
                storyId: selectedStory?.id,
                highlightId: selectedHighlight.id,
                highlightTitle: selectedHighlight.title,
            }),
        });
    };

    const sendProgressBarImpression = (): void => {
        if (!selectedHighlight) {
            return;
        }
        Analytics.impressionEvent({
            category: "highlights-bar",
            context: JSON.stringify({
                storeId,
                storyId: selectedStory?.id,
                highlightId: selectedHighlight.id,
                highlightTitle: selectedHighlight.title,
            }),
        });
    };

    const sendLoveClickEvent = (): void => {
        if (!selectedHighlight) {
            return;
        }
        Analytics.clickEvent({
            category: "highlights-bar-love-this-btn",
            context: JSON.stringify({
                storeId,
                storyId: selectedStory?.id,
                highlightId: selectedHighlight.id,
                highlightTitle: selectedHighlight.title,
            }),
        });
    };

    const sendCloseClickEvent = (): void => {
        if (!selectedHighlight) {
            return;
        }
        Analytics.clickEvent({
            category: "highlights-close",
            context: JSON.stringify({
                storeId,
                storyId: selectedStory?.id,
                highlightId: selectedHighlight.id,
                highlightTitle: selectedHighlight.title,
            }),
        });
    };

    const sendShareClickEvent = (): void => {
        if (!selectedHighlight) {
            return;
        }
        Analytics.clickEvent({
            category: "highlights-share",
            context: JSON.stringify({
                storeId,
                storyId: selectedStory?.id,
                highlightId: selectedHighlight.id,
                highlightTitle: selectedHighlight.title,
            }),
        });
    };

    const sendProductCardImpression = (productId: string): void => {
        if (!selectedHighlight) {
            return;
        }
        Analytics.impressionEvent({
            category: "highlights-product-card",
            context: JSON.stringify({
                storeId,
                storyId: selectedStory?.id,
                highlightId: selectedHighlight.id,
                highlightTitle: selectedHighlight.title,
                productId,
            }),
        });
    };
    const sendProductCardClick = (productId: string): void => {
        if (!selectedHighlight) {
            return;
        }
        Analytics.clickEvent({
            category: "highlights-see-product",
            context: JSON.stringify({
                storeId,
                storyId: selectedStory?.id,
                highlightId: selectedHighlight.id,
                highlightTitle: selectedHighlight.title,
                productId,
            }),
        });
    };

    const sendBottomBarChatClick = (): void => {
        if (!selectedHighlight) {
            return;
        }
        Analytics.clickEvent({
            category: "highlights-bottom-bar-chat-with-us",
            context: JSON.stringify({
                storeId,
                storyId: selectedStory?.id,
                highlightId: selectedHighlight.id,
                highlightTitle: selectedHighlight.title,
            }),
        });
    };

    const sendOpenChatImpression = (): void => {
        if (!selectedHighlight) {
            return;
        }
        Analytics.impressionEvent({
            category: "highlights-bottom-bar-open-chat",
            context: JSON.stringify({
                storeId,
                storyId: selectedStory?.id,
                highlightId: selectedHighlight.id,
                highlightTitle: selectedHighlight.title,
            }),
        });
    };

    const sendOpenChatClick = (): void => {
        if (!selectedHighlight) {
            return;
        }
        Analytics.clickEvent({
            category: "highlights-bottom-bar-open-chat",
            context: JSON.stringify({
                storeId,
                storyId: selectedStory?.id,
                highlightId: selectedHighlight.id,
                highlightTitle: selectedHighlight.title,
            }),
        });
    };

    const sendEndCoverImpression = (): void => {
        if (!selectedHighlight) {
            return;
        }
        Analytics.impressionEvent({
            category: "highlights-end-cover",
            context: JSON.stringify({
                storeId,
                storyId: selectedStory?.id,
                highlightId: selectedHighlight.id,
                highlightTitle: selectedHighlight.title,
            }),
        });
    };

    const sendShareClick = (): void => {
        if (!selectedHighlight) {
            return;
        }
        Analytics.clickEvent({
            category: "highlights-copy-share-link",
            context: JSON.stringify({
                storeId,
                storyId: selectedStory?.id,
                highlightId: selectedHighlight.id,
                highlightTitle: selectedHighlight.title,
            }),
        });
    };

    const sendChatWithUsClick = (): void => {
        if (!selectedHighlight) {
            return;
        }
        Analytics.clickEvent({
            category: "highlights-share-chat-with-us",
            context: JSON.stringify({
                storeId,
                storyId: selectedStory?.id,
                highlightId: selectedHighlight.id,
                highlightTitle: selectedHighlight.title,
            }),
        });
    };

    const sendStoryLoaderImpression = (): void => {
        SwiggyRNModule.getInstance().logNRCustomEvent(
            "",
            "story-loader-impression",
            "store-stories",
            JSON.stringify({
                storeId,
                storyId: selectedStory?.id,
                highlightId: selectedHighlight.id,
                highlightTitle: selectedHighlight.title,
            }),
        );
    };

    return {
        setStoryIndex,
        setHighlightIndex,
        sendHighlightImpression,
        sendHighlightClick,
        sendProgressBarClick,
        sendProgressBarImpression,
        sendLoveClickEvent,
        sendCloseClickEvent,
        sendShareClickEvent,
        sendProductCardImpression,
        sendProductCardClick,
        sendBottomBarChatClick,
        sendOpenChatImpression,
        sendOpenChatClick,
        sendShareClick,
        sendChatWithUsClick,
        sendEndCoverImpression,
        sendStoryLoaderImpression,
    };
};
