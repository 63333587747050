import React from "react";
import { Animated, InteractionManager, Platform, StyleProp, ViewStyle } from "react-native";

import { useAnimatedValue } from "@swiggy-private/rn-dls";
import { ANIMATION_CONFIGURATION } from "./constants";

type WidgetFlipperProps = {
    frontContent: React.ReactElement<StyleProp<ViewStyle>>;
    backContent: React.ReactElement<StyleProp<ViewStyle>>;
    canFlip: boolean;

    shouldDoReverseFlip?: boolean;
    onCb?: VoidFunction;
};

const WidgetFlipperComponent: React.FC<WidgetFlipperProps> = ({
    frontContent,
    backContent,
    canFlip,
    shouldDoReverseFlip,
    onCb,
}) => {
    const flipAnimation = useAnimatedValue(shouldDoReverseFlip ? 1 : 0);

    const taskRef = React.useRef<ReturnType<typeof InteractionManager.runAfterInteractions> | null>(
        null,
    );

    const [canShowBackContent, setShowBackContent] = React.useState(shouldDoReverseFlip);

    const frontOpacity = flipAnimation.interpolate({
        inputRange: [0, 0.5, 1],
        outputRange: [1, 0.5, 0],
    });

    const backOpacity = flipAnimation.interpolate({
        inputRange: [0, 0.5, 1],
        outputRange: [0, 0.5, 1],
    });

    const startAnimation = React.useCallback(() => {
        taskRef.current?.cancel();

        taskRef.current = InteractionManager.runAfterInteractions(() => {
            Animated.timing(flipAnimation, {
                toValue: shouldDoReverseFlip ? 0 : 1,
                duration: ANIMATION_CONFIGURATION.DURATION,
                useNativeDriver: Platform.OS !== "web",
            }).start((finished) => {
                if (finished) {
                    onCb?.();
                }
            });
        });
    }, [flipAnimation, onCb, shouldDoReverseFlip]);

    React.useEffect(() => {
        let t: NodeJS.Timeout;

        if (canFlip) {
            t = setTimeout(() => {
                setShowBackContent(!shouldDoReverseFlip);
            }, ANIMATION_CONFIGURATION.DURATION / 2);
            startAnimation();
        }

        return () => clearTimeout(t);
    }, [canFlip, shouldDoReverseFlip, startAnimation]);

    const animationStyle = {
        transform: [
            { perspective: ANIMATION_CONFIGURATION.PERSPECTIVE },
            {
                rotateX: flipAnimation.interpolate({
                    inputRange: [0, 0.5, 0.5, 1],
                    outputRange: ["0deg", "90deg", "-90deg", "0deg"],
                }),
            },
        ],
        backgroundColor: "transparent",
    } as ViewStyle;

    return (
        <Animated.View style={animationStyle}>
            {canShowBackContent ? (
                <Animated.View style={{ opacity: backOpacity }}>{backContent}</Animated.View>
            ) : (
                <Animated.View style={{ opacity: frontOpacity }}>{frontContent}</Animated.View>
            )}
        </Animated.View>
    );
};

export const WidgetFlipper = React.memo(WidgetFlipperComponent);
