import * as React from "react";

import { useEnquiryModalHandler } from "@minis-consumer/hooks/use-enquiry-modal-handler";
import { EnquiryDialog } from "@minis-consumer/components/enquiry-modal";
import { Analytics } from "@minis-consumer/analytics";

const CLOSE_HALF_CARD_EVENT = "close-chat-half-card";

export const ProductEnquiryProvider: React.FC = () => {
    const [isOpen, productId, imageUrl, handler, storeId, productDetails, selectedVariant] =
        useEnquiryModalHandler();

    const onCloseModal = React.useCallback(() => {
        Analytics.impressionEvent({
            category: CLOSE_HALF_CARD_EVENT,
            label: productId,
        });

        handler({ open: false });
    }, [productId, handler]);

    return (
        <EnquiryDialog
            showModal={isOpen}
            closeModal={onCloseModal}
            productId={productId}
            imageUrl={imageUrl}
            storeId={storeId}
            productDetails={productDetails}
            selectedVariant={selectedVariant}
        />
    );
};
