import { ApiError } from "@swiggy-private/connect-api-core";
import { Endpoint, HttpClient } from "@swiggy-private/http-client";

import { AppConfig } from "@minis-consumer/config";

export const getMediaHost = (): string => AppConfig.IMAGEKIT_MEDIA_BASE_URL;
export const getMediaCloudname = (): string => AppConfig.IMAGEKIT_CLOUDNAME;
export const folderName = "static-assets";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const httpClient = new HttpClient<any>();
const FAQ_URL = `${getMediaHost()}/${getMediaCloudname()}/v${Date.now()}/${folderName}/consumer_faq.json`;
const HELP_URL = `${getMediaHost()}/${getMediaCloudname()}/v${Date.now()}/${folderName}/consumer_help.json`;

/**
 * The API to fetch FAQ's.
 *
 *
 * @returns {@link Promise} with value as {@link string}
 */
export const fetchFAQApi = async <T>(): Promise<T> => {
    try {
        return await httpClient.get({
            endpoint: Endpoint.from(FAQ_URL),
        });
    } catch (err) {
        return Promise.reject(new ApiError("Could not fetch FAQ"));
    }
};

/**
 * The API to fetch Help's.
 *
 *
 * @returns {@link Promise} with value as {@link string}
 */
export const fetchHelpApi = async <T>(): Promise<T> => {
    try {
        return await httpClient.get({
            endpoint: Endpoint.from(HELP_URL),
        });
    } catch (err) {
        return Promise.reject("Unable to fetch Help");
    }
};
