import { Product } from "@minis-consumer/interfaces/catalog";

const productTypeToAsstTypeMap = {
    PHYSICAL: "physical-fallback",
    PLAN: "plan-fallback",
    DIGITAL: "digital-fallback",
    APPOINTMENT: "appointment-fallback",
};

export function getProductFallbackImages(productType: Product["productType"]): string {
    return `static-assets/${productTypeToAsstTypeMap[productType ?? "PHYSICAL"]}`;
}
