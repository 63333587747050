import React from "react";
import { StyleSheet } from "react-native";

import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";

import type { Product } from "@minis-consumer/interfaces/catalog";

import { PRODUCT_PRICE_TEST_IDS, PRICE_ON_REQUEST_LABEL } from "./constants";

interface ProductPriceProps {
    price?: Product["price"];
    discountPercent?: Product["discountPercent"];
    discountedPrice?: Product["discountedPrice"];
}

export const ProductPrice: React.FC<ProductPriceProps> = ({
    price,
    discountedPrice,
    discountPercent,
}) => {
    const { value: theme } = useTheme();

    if (!price) {
        return (
            <Text category="s1" weight="bold">
                {PRICE_ON_REQUEST_LABEL}
            </Text>
        );
    }

    return (
        <Stack spacing={SpacingValue["space-xxx-small"]}>
            <Stack direction="row" spacing={SpacingValue["space-x-small"]}>
                <Text testID={PRODUCT_PRICE_TEST_IDS.PRODUCT_PRICE} category="b1" weight="bold">
                    ₹{discountedPrice || price}
                </Text>

                {discountedPrice && discountPercent ? (
                    <Box
                        style={[
                            styles.discountPercentLabel,
                            {
                                backgroundColor: theme["color-warning-50"],
                                borderColor: theme["color-warning-600"],
                            },
                        ]}
                        justifyContent="center"
                        alignItems="center">
                        <Text
                            testID={PRODUCT_PRICE_TEST_IDS.DISCOUNT_PERCENT_LABEL}
                            category="b3"
                            weight="regular"
                            color="color-warning-800">
                            ({discountPercent}% Off)
                        </Text>
                    </Box>
                ) : null}
            </Stack>

            {discountedPrice ? (
                <Text
                    category="b3"
                    testID={PRODUCT_PRICE_TEST_IDS.DISCOUNT_PRICE}
                    weight="regular"
                    style={styles.strikeOff}
                    color="color-basic-60">
                    ₹{price}
                </Text>
            ) : null}
        </Stack>
    );
};

const styles = StyleSheet.create({
    strikeOff: {
        textDecorationLine: "line-through",
    },
    discountPercentLabel: {
        paddingHorizontal: SpacingValue["space-xxx-small"] * 2,
        paddingVertical: SpacingValue["space-xxx-small"],
        borderRadius: 4,
        borderBottomWidth: 1,
    },
});
