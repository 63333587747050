import * as React from "react";
import { StyleProp, StyleSheet, TextStyle, ViewStyle } from "react-native";

import { Stack, StackProps } from "@swiggy-private/rn-adaptive-layout";
import { Text, SpacingValue, TextProps } from "@swiggy-private/rn-dls";

interface ProductPriceProps {
    price?: number;
    discountedPrice?: number;
    discountPercent?: number;
    hideOriginalPrice?: boolean;

    direction?: StackProps["direction"];

    stackStyle?: StyleProp<ViewStyle>;
    priceStyle?: TextStyle;
    strikedPriceStyle?: TextStyle;
    discountPercentStyle?: TextStyle;

    finalPriceTextProps?: TextProps;
    strikedPriceTextProps?: TextProps;
    requestPriceTextProps?: TextProps;
    discountPercentTextProps?: TextProps;
}

export const ProductPrice: React.FC<ProductPriceProps> = ({
    price,
    discountedPrice,
    discountPercent,
    direction = "column",
    stackStyle,
    finalPriceTextProps,
    strikedPriceTextProps,
    strikedPriceStyle,
    requestPriceTextProps,
    discountPercentStyle,
    discountPercentTextProps,
    hideOriginalPrice,
}) => {
    if (!price) {
        return (
            <Text category="b2" color="medium" {...requestPriceTextProps}>
                *Price on request
            </Text>
        );
    }

    const showDiscount = price != null && discountedPrice != null && price !== discountedPrice;

    return (
        <Stack spacing={SpacingValue["space-xxx-small"]} direction={direction} style={stackStyle}>
            <Text category="b1" weight="bold" {...finalPriceTextProps}>
                ₹{showDiscount && discountedPrice ? discountedPrice : price}
                {showDiscount && discountPercent ? (
                    <Text
                        category="b2"
                        weight="regular"
                        color="color-warning-700"
                        style={discountPercentStyle}
                        {...discountPercentTextProps}>
                        {` (${discountPercent}% Off)`}
                    </Text>
                ) : (
                    ""
                )}
            </Text>

            {!hideOriginalPrice && showDiscount ? (
                <Text
                    category="b3"
                    weight="regular"
                    style={[styles.strike, strikedPriceStyle]}
                    color="color-basic-60"
                    {...strikedPriceTextProps}>
                    ₹{price}
                </Text>
            ) : null}
        </Stack>
    );
};

const styles = StyleSheet.create({
    strike: {
        textDecorationLine: "line-through",
    },
});
