import * as React from "react";

import { ViewStoryModalContext } from "@minis-consumer/contexts/view-story-chat-context";

import { ViewStoryMessagePayload } from "@swiggy-private/connect-chat-commons";

export const useStoryEnquiryModalHandler = (): [
    boolean,
    Omit<ViewStoryMessagePayload, "text">,

    (b: boolean, data: Omit<ViewStoryMessagePayload, "text">) => void,
    { isMessageSent: boolean; setIsMessageSent?: (b: boolean) => void },
] => {
    const ctx = React.useContext(ViewStoryModalContext);

    const handler = React.useCallback(
        (open: boolean, data: Omit<ViewStoryMessagePayload, "text">) => {
            ctx?.setData(data);

            if (open) {
                ctx?.open();
            } else {
                ctx?.close();
            }
        },
        [ctx],
    );

    return [
        ctx?.isOpen ?? false,
        ctx?.data ?? { imageUrl: "", storeId: "", storyData: "" },
        handler,
        { isMessageSent: ctx?.isMessageSent ?? false, setIsMessageSent: ctx?.setIsMessageSent },
    ];
};
