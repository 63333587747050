import * as React from "react";

import { Box, BoxProps } from "@swiggy-private/rn-adaptive-layout";
import { useTheme, Text, Button } from "@swiggy-private/rn-dls";

type StoreCloseSubtextAndCtaProps = {
    onPressCta: () => void;
    textMarginTop?: BoxProps["mt"];
    ctaMarginTop?: BoxProps["mt"];
};

const StoreCloseSubtextAndCtaComponent: React.FC<StoreCloseSubtextAndCtaProps> = ({
    textMarginTop,
    ctaMarginTop,
    onPressCta,
}) => {
    const { value: theme } = useTheme();

    const ctaStyle = {
        backgroundColor: theme["color-positive-25"],
        borderColor: theme["color-positive-25"],
    };

    const ctaTextStyle = { color: theme["color-positive-500"] };

    return (
        <Box>
            <Box mt={textMarginTop}>
                <Text category="b1" color="medium">
                    Don&#39;t worry, there&#39;s still a lot to explore.
                </Text>
            </Box>
            <Box mt={ctaMarginTop}>
                <Button
                    color="positive"
                    style={ctaStyle}
                    textStyle={ctaTextStyle}
                    onPress={onPressCta}>
                    Go to Minis Home
                </Button>
            </Box>
        </Box>
    );
};

export const StoreCloseSubtextAndCta = React.memo(StoreCloseSubtextAndCtaComponent);
