import { ApiError, API_SUCCESS_STATUS_CODE } from "@swiggy-private/connect-api-core";
import { Endpoint } from "@swiggy-private/http-client";

import { Store } from "@minis-consumer/interfaces/store";
import { StoreWidgetData } from "@minis-consumer/interfaces/widget";
import { getApiClient } from "@minis-consumer/includes/api";

import { StoreEndpoint, StoreEndpointV2 } from "../endpoint";

export interface FetchStoreInfoParams {
    slug: string;
}

export const fetchStoreInfo = async ({ slug }: FetchStoreInfoParams): Promise<Store> => {
    const endpoint = Endpoint.from(`${StoreEndpoint}/{%%storeSlug%%}`, { storeSlug: slug });
    const response = await getApiClient().get<Store>({ endpoint });

    if (response.statusCode === API_SUCCESS_STATUS_CODE && response.data) {
        return response.data;
    }

    return Promise.reject(
        new ApiError(response.statusMessage || "Could not fetch store info", response),
    );
};

export interface FollowedStoreResponseDataItem
    extends StoreWidgetData,
        Pick<
            StoreWidgetData,
            | "categories"
            | "badges"
            | "intraDeliveryConfigType"
            | "interDeliveryConfigType"
            | "bio"
            | "address"
            | "followerCount"
        > {
    storeId: string;
    storeName: string;
    slug: string;
    brandImageId?: string;
}

export interface FetchFollowedStoresResponse {
    nextPageCursor: string;
    stores: FollowedStoreResponseDataItem[];
}

export const fetchFollowedStores = async (): Promise<FetchFollowedStoresResponse> => {
    const endpoint = Endpoint.from(`${StoreEndpoint}/followed-stores`);
    const response = await getApiClient().get<FetchFollowedStoresResponse>({ endpoint });

    if (response.statusCode === API_SUCCESS_STATUS_CODE && response.data) {
        return response.data;
    }

    return Promise.reject(
        new ApiError(response.statusMessage || "Could not fetch followed stores", response),
    );
};

export interface FollowStoreParams {
    storeId: string;
}

export interface FollowStoreResponse {
    storeFollowersCount: number;
    follow: boolean;
}

export const followStore = async ({ storeId }: FollowStoreParams): Promise<FollowStoreResponse> => {
    const endpoint = Endpoint.from(`${StoreEndpointV2}/{%%storeId%%}/follow`, { storeId });
    const response = await getApiClient().post<FollowStoreResponse>({ endpoint });

    if (response.statusCode === API_SUCCESS_STATUS_CODE && response.data) {
        return response.data;
    }

    return Promise.reject(
        new ApiError(response.statusMessage || "Could not follow store", response),
    );
};

export interface UnfollowStoreParams {
    storeId: string;
}

export interface UnfollowStoreResponse {
    storeFollowersCount: number;
    unfollow: boolean;
}

export const unfollowStore = async ({
    storeId,
}: UnfollowStoreParams): Promise<UnfollowStoreResponse> => {
    const endpoint = Endpoint.from(`${StoreEndpointV2}/{%%storeId%%}/follow`, { storeId });
    const response = await getApiClient().delete<UnfollowStoreResponse>({ endpoint });

    if (response.statusCode === API_SUCCESS_STATUS_CODE && response.data) {
        return response.data;
    }

    return Promise.reject(
        new ApiError(response.statusMessage || "Could not unfollow store", response),
    );
};

export interface FollowStatusParams {
    storeId: string;
}

export const followStatus = async ({
    storeId,
}: FollowStatusParams): Promise<{ follow: boolean }> => {
    const endpoint = Endpoint.from(`${StoreEndpoint}/{%%storeId%%}/follow-status`, { storeId });
    const response = await getApiClient().get<{ follow: boolean }>({ endpoint });

    if (response.statusCode === API_SUCCESS_STATUS_CODE && typeof response.data === "boolean") {
        return {
            follow: response.data,
        };
    }

    return Promise.reject(
        new ApiError(response.statusMessage || "Could not fetch store's follow status", response),
    );
};

export interface StoreLink {
    type: string;
    title: string;
    url: string;
    priority: number;
    logo?: string;
}
export interface FetchStoreLinksResponse {
    socialLinks: StoreLink[];
}

export const fetchStoreLinks = async ({
    storeId,
}: FollowStoreParams): Promise<FetchStoreLinksResponse> => {
    const endpoint = Endpoint.from(`${StoreEndpoint}/{%%storeId%%}/social-links`, { storeId });
    const response = await getApiClient().get<FetchStoreLinksResponse>({ endpoint });

    if (response.statusCode === API_SUCCESS_STATUS_CODE && response.data) {
        return response.data;
    }

    return Promise.reject(
        new ApiError(response.statusMessage || "Could not fetch store links", response),
    );
};
