import * as React from "react";

import { Platform } from "react-native";
import { useFetch, useSuspense } from "@rest-hooks/react";

import { useMount } from "@swiggy-private/react-hooks";
import { useIsomorphicEffect } from "@swiggy-private/react-native-ui";
import { Provider as DlsProvider } from "@swiggy-private/rn-dls";

import { useApplyStoreTheme } from "@minis-consumer/hooks/use-apply-theme";
import { Analytics } from "@minis-consumer/analytics";
import { AsyncComponent } from "@minis-consumer/components/async-component";
import { FollowersCountStateProvider } from "@minis-consumer/contexts/followers-count-context";
import { StoreContext } from "@minis-consumer/contexts/store-context";
import { StoreDeletedErrorSnackbarContextProvider } from "@minis-consumer/contexts/store-deleted-error-snackbar-context";
import { useMinisNavigation } from "@minis-consumer/hooks/use-minis-navigation";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { useUserInfo } from "@minis-consumer/hooks/use-user";
import { Device } from "@minis-consumer/includes/device";
import { Logger } from "@minis-consumer/includes/logger";
import { Session } from "@minis-consumer/includes/session";
import { CatalogView } from "@minis-consumer/resources/catalog";
import { StoreDetail } from "@minis-consumer/resources/store";
import { ShopLoader } from "@minis-consumer/routes/shop/components/loader";
import { initMetaPixel } from "@minis-consumer/helpers/meta-pixel-events";
import { StoreNavigationRoutes } from "@minis-consumer/routes/store-navigation";
import { getUserAgentAndApp } from "@minis-consumer/helpers/user-agent-app-name";
import { StoreDeliveryInfoSnackbarContextProvider } from "@minis-consumer/contexts/store-delivery-info-snackbar-context";

import { StoreCartContainer } from "./store-cart-container";

interface StoreContentProps {
    onReady: () => void;
    initialPath?: string;
    independent?: boolean;
}

const StoreContent: React.FC<React.PropsWithChildren<StoreContentProps>> = React.memo(
    ({ onReady, initialPath, independent, children }) => {
        const { settings, slug, storeId } = useStoreInfo();

        useApplyStoreTheme(slug, settings?.brandColor);

        useMount(() => {
            onReady();
        });

        return (
            <DlsProvider theme={slug}>
                <StoreDeletedErrorSnackbarContextProvider>
                    <StoreDeliveryInfoSnackbarContextProvider>
                        <StoreCartContainer storeId={storeId}>
                            <StoreNavigationRoutes
                                onReady={onReady}
                                initialPath={initialPath}
                                independent={independent}>
                                {children}
                            </StoreNavigationRoutes>
                        </StoreCartContainer>
                    </StoreDeliveryInfoSnackbarContextProvider>
                </StoreDeletedErrorSnackbarContextProvider>
            </DlsProvider>
        );
    },
);

StoreContent.displayName = "StoreContent";

interface StoreContainerProps {
    slug: string;
    initialPath?: string;
    independent?: boolean;
}

export const StoreProvider: React.FC<React.PropsWithChildren<StoreContainerProps>> = ({
    slug,
    children,
}) => {
    // prefetch catalog
    useFetch(CatalogView, { storeSlug: slug })?.catch(Logger.recordError);

    // fetch store info
    const store = useSuspense(StoreDetail, { slug });
    const value = React.useMemo(() => ({ store }), [store]);
    const userInfo = useUserInfo();
    const deviceInfo = Device.getInstance();
    const [userAgent] = getUserAgentAndApp();

    const followersCountState = React.useMemo(
        () => ({
            [store.storeId]: { followersCount: store?.followersCount ?? 0 },
        }),
        [store?.followersCount, store.storeId],
    );

    useIsomorphicEffect(() => {
        if (!store?.storeId) {
            return;
        }
        initMetaPixel({
            storeInfo: store,
            platform: Platform.OS,
            userInfo: userInfo ?? undefined,
            deviceInfo,
            userAgent,
        });
    }, [store, userInfo, deviceInfo, userAgent]);

    useIsomorphicEffect(() => {
        Analytics.setStoreId(store?.storeId);
        Session.getInstance().storeId = store?.storeId ?? "";

        return () => {
            Analytics.setStoreId("-");
        };
    }, [store.storeId, store.facebookAdsUserDetails]);

    return (
        <StoreContext.Provider value={value}>
            <StoreCartContainer storeId={store.storeId}>
                <FollowersCountStateProvider initialState={followersCountState}>
                    {children}
                </FollowersCountStateProvider>
            </StoreCartContainer>
        </StoreContext.Provider>
    );
};

export const StoreContainer: React.FC<React.PropsWithChildren<StoreContainerProps>> = ({
    slug,
    initialPath,
    independent,
    children,
}) => {
    const [, setOnReady] = React.useState(false);
    const minisNavigation = useMinisNavigation();

    const onReady = React.useCallback((): void => {
        setOnReady(true);
        minisNavigation?.setOptions({
            headerShown: false,
            headerTitle: "",
            gestureEnabled: minisNavigation.canGoBack(),
        });
    }, [minisNavigation]);

    return (
        <AsyncComponent fallback={ShopLoader}>
            <StoreProvider slug={slug}>
                <StoreContent onReady={onReady} initialPath={initialPath} independent={independent}>
                    {children}
                </StoreContent>
            </StoreProvider>
        </AsyncComponent>
    );
};
