import * as React from "react";
import { Message } from "@swiggy-private/connect-chat-ui";
import { ViewProps } from "react-native";
import {
    CustomMessageTypes,
    ProductShareMessage as IProductShareMessage,
    ProductEnquireMessage as IProductEnquireMessage,
    ImageEnquireMessage as IImageEnquireMessage,
    CustomProductsEnquireMessage as ICustomProductsEnquireMessage,
    OrderUpdateMessage as IOrderUpdateMessage,
    InitiateReviewMessage as IInitiateReviewMessage,
    SubmittedReviewMessage as ISubmittedReviewMessage,
    IViewStoryMessage,
    ICouponWithTextMessage,
    IReturnOrderMessage,
    IEnquireConfirmMessage,
} from "@swiggy-private/connect-chat-commons";
import { SdkConversation } from "@swiggy-private/connect-chat-sdk";

import { ProductShareMessage } from "./product-share-message";
import { ProductEnquireMessage } from "./product-enquiry-message";
import { ImageEnquireMessage } from "./image-enquiry-message";
import { CustomProductEnquireMessage } from "./custom-product-enquiry-message";
import { OrderUpdateMessage } from "./order-update-message";
import { InitiatedReviewMessage } from "./initiated-review-message";
import { SubmittedReviewMsg } from "./submitted-review-message";
import { ViewStoryMessage } from "./view-story-message";
import { CouponWithTextMessage } from "./coupon-with-text-message";
import { ReturnOrderMessage } from "./return-order-message";
import { EnquireConfirmMessage } from "./confirm-enquiry-message";

interface ConversationCustomMessageProps {
    message: Message;
    isPublisher: boolean;
    conversationId: string;
    style?: ViewProps["style"];
    blocked?: SdkConversation["blocked"];
    deleted?: SdkConversation["deleted"];
}

export const ConversationCustomMessage: React.FC<ConversationCustomMessageProps> = ({
    message,
    ...props
}) => {
    switch (message.type) {
        case CustomMessageTypes.PRODUCT_SHARE:
            return (
                <ProductShareMessage
                    {...props}
                    message={message as unknown as IProductShareMessage}
                />
            );

        case CustomMessageTypes.PRODUCT_ENQUIRE:
            return (
                <ProductEnquireMessage
                    {...props}
                    message={message as unknown as IProductEnquireMessage}
                />
            );

        case CustomMessageTypes.IMAGE_ENQUIRE:
            return (
                <ImageEnquireMessage
                    {...props}
                    message={message as unknown as IImageEnquireMessage}
                />
            );

        case CustomMessageTypes.CUSTOM_PRODUCT_ENQUIRE:
            return (
                <CustomProductEnquireMessage
                    {...props}
                    message={message as unknown as ICustomProductsEnquireMessage}
                />
            );

        case CustomMessageTypes.ORDER_UPDATE:
            return (
                <OrderUpdateMessage
                    {...props}
                    message={message as unknown as IOrderUpdateMessage}
                />
            );

        case CustomMessageTypes.INITIATE_REVIEW:
            return (
                <InitiatedReviewMessage
                    {...props}
                    message={message as unknown as IInitiateReviewMessage}
                />
            );

        case CustomMessageTypes.SUBMITTED_REVIEW:
            return (
                <SubmittedReviewMsg
                    {...props}
                    message={message as unknown as ISubmittedReviewMessage}
                />
            );

        case CustomMessageTypes.VIEW_STORY:
            return (
                <ViewStoryMessage {...props} message={message as unknown as IViewStoryMessage} />
            );

        case CustomMessageTypes.COUPON:
            return (
                <CouponWithTextMessage
                    {...props}
                    message={message as unknown as ICouponWithTextMessage}
                />
            );

        case CustomMessageTypes.RETURN_ORDER:
            return (
                <ReturnOrderMessage
                    {...props}
                    message={message as unknown as IReturnOrderMessage}
                />
            );

        case CustomMessageTypes.ENQUIRE_CONFIRM:
            return (
                <EnquireConfirmMessage
                    {...props}
                    message={message as unknown as IEnquireConfirmMessage}
                />
            );

        default:
            return null;
    }
};
