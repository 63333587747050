import { ProductDurationUnits } from "@minis-consumer/interfaces/catalog";
import { SvgIconType } from "@swiggy-private/connect-svg-icons";

export const CHANNEL_ICON_MAP: Record<
    string,
    {
        text: string;
        icon: SvgIconType["icon"];
    }
> = {
    WHATSAPP: {
        text: "Whatsapp Call",
        icon: "Whatsapp",
    },
    "G-MEET": {
        text: "Google Meet",
        icon: "Calendar",
    },
    ZOOM: {
        text: "Zoom Meeting",
        icon: "Video",
    },
    "PHONE CALL": {
        text: "Phone Call",
        icon: "Phone",
    },
    FORM: {
        text: "Form",
        icon: "Book",
    },
};

export const DEFAULT_CHANNEL_ICON: SvgIconType["icon"] = "Tag";

export const DURATION_STRING_MAP: Record<
    ProductDurationUnits,
    { singularText: string; pluralText: string }
> = {
    MINS: {
        singularText: "minute",
        pluralText: "minutes",
    },
    DAYS: {
        singularText: "day",
        pluralText: "days",
    },
    WEEKS: {
        singularText: "week",
        pluralText: "weeks",
    },
    MONTHS: {
        singularText: "month",
        pluralText: "months",
    },
    SESSIONS: {
        singularText: "session",
        pluralText: "sessions",
    },
};
