import React from "react";
import { StyleSheet } from "react-native";
import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { DashBorderLine } from "@swiggy-private/react-native-ui";

import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { useCartStatus, useCartViewData } from "@minis-consumer/hooks/use-cart";
import { OfferDiscount } from "@minis-consumer/components/offer-discount";
import { SubTotal } from "@minis-consumer/routes/cart/components/bill/components/sub-total";
import { AdditionalCharges } from "@minis-consumer/routes/cart/components/bill/components/additional-charges";
import { DeliveryFee } from "@minis-consumer/routes/cart/components/bill/components/delivery-fee";
import { FinalAmount } from "@minis-consumer/routes/cart/components/bill/components/final-amount";
import { CartTotal } from "@minis-consumer/routes/cart/components/bill/components/cart-total";

const BillDetailsComponent: React.FC = () => {
    const store = useStoreInfo();
    const { value: theme } = useTheme();

    const { storeId } = store;
    const cart = useCartViewData(storeId);
    const cartStatus = useCartStatus(storeId);

    const itemTotal = cart?.bill?.itemTotal ?? 0;
    const itemDiscount = cart?.bill?.itemDiscount ?? 0;
    const couponDiscount = cart?.bill?.couponDiscount ?? 0;

    const totalDiscount = React.useMemo(
        () => itemDiscount + couponDiscount,
        [itemDiscount, couponDiscount],
    );

    // sub total
    const discountedItemTotal = cart?.bill?.discountedItemTotal ?? 0;

    const appliedCoupon = cart?.appliedCoupon?.applied ? cart?.appliedCoupon?.code : "";
    const additionalCharges = cart?.bill?.charges;
    const deliveryFee = cart?.bill?.deliveryFee;
    const billTotal = cart?.bill?.billTotal ?? 0;

    return (
        <Box style={styles.container}>
            <Stack spacing={SpacingValue["space-medium"]}>
                <CartTotal finalAmount={itemTotal} />
                <SubTotal finalAmount={discountedItemTotal} />
                {itemTotal > discountedItemTotal ? (
                    <Stack>
                        {totalDiscount ? (
                            <OfferDiscount
                                finalAmount={totalDiscount}
                                itemDiscount={itemDiscount}
                                couponDiscount={couponDiscount}
                                appliedCoupon={appliedCoupon}
                            />
                        ) : null}
                    </Stack>
                ) : null}
                {additionalCharges && additionalCharges.length > 0 ? (
                    <AdditionalCharges charges={additionalCharges} />
                ) : null}
                <DashBorderLine
                    borderColor={theme["color-basic-5"]}
                    style={styles.mt0}
                    type="solid"
                />
                <DeliveryFee deliveryFee={deliveryFee} cartStatus={cartStatus} />
                <DashBorderLine
                    borderColor={theme["color-basic-5"]}
                    style={styles.mt0}
                    type="solid"
                />
                <FinalAmount billTotal={billTotal} />
            </Stack>
        </Box>
    );
};

const styles = StyleSheet.create({
    mt0: {
        marginTop: 0,
    },
    container: {
        paddingHorizontal: 0,
        paddingVertical: 0,
    },
});

export const BillDetails = React.memo(BillDetailsComponent);
