import * as React from "react";
import { SpacingValue, useTheme, Text } from "@swiggy-private/rn-dls";
import { Box, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { Platform, ViewStyle } from "react-native";

import { useLocalCart } from "@minis-consumer/hooks/use-cart";

export const CartItemCountBadge: React.FC<{
    storeId: string;
}> = (props) => {
    const { value: theme } = useTheme();
    const cartView = useLocalCart(props.storeId);
    const cartItemCount = cartView.items.reduce((sum, item) => {
        return sum + item.quantity;
    }, 0);

    const badgeScreenSpecificStyle = useSelectScreen({
        lg: {
            top: 12,
            left: 16,
        },
        default: {
            top: Platform.OS === "web" ? 0 : -8,
            left: "55%",
        },
    });

    const badgeStyle: ViewStyle = React.useMemo(() => {
        return {
            backgroundColor: theme["color-primary"],
            position: "absolute",
            minWidth: 16,
            height: 16,
            maxHeight: 24,
            maxWidth: 36,
            borderRadius: 24,
            alignItems: "center",
            paddingHorizontal: SpacingValue["space-xx-small"],
            ...badgeScreenSpecificStyle,
        };
    }, [badgeScreenSpecificStyle, theme]);

    return cartItemCount ? (
        <Box style={badgeStyle}>
            <Text category="b3" color="color-basic-0">
                {cartItemCount > 99 ? "99+" : cartItemCount}
            </Text>
        </Box>
    ) : null;
};
