import React from "react";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useLoading } from "@rest-hooks/hooks";
import { useCallback } from "react";
import { useController } from "@rest-hooks/react";

import { useMountedRef } from "@swiggy-private/react-hooks";

import { Analytics } from "@minis-consumer/analytics";
import { Order } from "@minis-consumer/interfaces/order";
import { OrderCancel } from "@minis-consumer/resources/order";
import { CommonRouteList } from "@minis-consumer/interfaces/route";
import { useToast } from "@minis-consumer/hooks/use-toast";

const useCancelOrder = (orderId: string): (() => Promise<Order>) => {
    const { fetch } = useController();

    return useCallback(async () => {
        return await fetch(OrderCancel, orderId);
    }, [fetch, orderId]);
};

interface Props {
    showModal: boolean;
    showCancelOrderDialog: () => void;
    onCancelPress: () => void;
    closeDialog: () => void;
    dialogImpressionEvent: () => void;
    loading: boolean;
}

export const useCancelOrderUtil = (order: Order): Props => {
    const [showModal, setShowModal] = React.useState(false);
    const mountedRef = useMountedRef();
    const [showToast] = useToast();

    const navigation = useNavigation<NativeStackNavigationProp<CommonRouteList>>();

    const orderCancel = useCancelOrder(order.id);

    const showCancelOrderDialog = React.useCallback(() => {
        Analytics.clickEvent({
            category: "track-order-card-cancel-order-btn",
            label: order.id,
        });

        setShowModal(true);
    }, [order.id]);

    const closeDialog = React.useCallback(() => {
        Analytics.clickEvent({
            category: "cancel-order-popup-dismiss-btn",
            label: order.id,
        });

        setShowModal(false);
    }, [order.id]);

    const dialogImpressionEvent = React.useCallback(() => {
        Analytics.impressionEvent({
            category: "cancel-order-popup",
            label: order.id,
        });
    }, [order.id]);

    const navigateToOrderListScreen = React.useCallback(() => {
        navigation.navigate("Order", { id: order.id });
    }, [navigation, order.id]);

    const [onCancelPress, loading] = useLoading(async () => {
        if (mountedRef.current) {
            try {
                Analytics.clickEvent({
                    category: "cancel-order-popup-cancel-order-btn",
                    label: order.id,
                });

                await orderCancel();

                setShowModal(false);
                navigateToOrderListScreen();
            } catch (err) {
                setShowModal(false);
                showToast("Failed to cancel the order");
                // navigate the user to order-list page after a delay, instead of keeping it on the same screen
                setTimeout(navigateToOrderListScreen, 800);
            }
        }
    }, [mountedRef, order.id, orderCancel, navigateToOrderListScreen, showToast]);

    return {
        showModal,
        showCancelOrderDialog,
        onCancelPress,
        closeDialog,
        dialogImpressionEvent,
        loading,
    };
};
