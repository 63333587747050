import { useMemo } from "react";
import { useCache } from "@rest-hooks/react";

import { CatalogView } from "@minis-consumer/resources/catalog";
import type { ProductBadge } from "@minis-consumer/interfaces/catalog";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";

export const useGetProductBadges = (): ProductBadge[] => {
    const storeInfo = useStoreInfo();
    const { badges } = useCache(CatalogView, { storeSlug: storeInfo.slug });
    return useMemo(() => (badges ? Object.values(badges).filter((b) => b.active) : []), [badges]);
};
