import { Coupon, CouponCardData, CouponDeliveryType } from "@minis-consumer/interfaces/coupon";

const CITY_NAME_CHARACTER_LIMIT = 12;

type Props = {
    coupon: Coupon;

    addCodePrefix?: boolean;
    isStoreWidget?: boolean;
    city?: string;
};

/** Get card data for coupon list / storefront / PDP / store widget coupon card */
export const getCouponDataForCard = ({
    coupon,
    addCodePrefix,
    isStoreWidget,
    city,
}: Props): CouponCardData => {
    const data = {
        title:
            coupon.type === "DISCOUNT_TYPE_FREE_DELIVERY"
                ? "FREE DELIVERY"
                : coupon.discountPercent
                ? `${coupon.discountPercent}% OFF`
                : `₹${coupon.maxDiscountAmount} OFF`,
        description: "",
        codePrefix: "",
        code: coupon.code,
    };

    /**
     * Since we have less space in the UI for store widget
     * the description text will be reduced
     */
    if (isStoreWidget) {
        data.description =
            coupon.discountPercent && coupon.maxDiscountAmount
                ? `Up to ₹${coupon.maxDiscountAmount}`
                : coupon.minOrderValue
                ? `Above ₹${coupon.minOrderValue}`
                : "On all orders";
    } else if (coupon.type === "DISCOUNT_TYPE_FREE_DELIVERY") {
        const cityName =
            (city && city.length > CITY_NAME_CHARACTER_LIMIT
                ? city.slice(0, CITY_NAME_CHARACTER_LIMIT) + "..."
                : city) ?? "city";

        data.description = `Free delivery ${
            coupon.deliveryType === CouponDeliveryType.INTRA ? `within ${cityName}` : "across India"
        } ${coupon.minOrderValue ? `on orders above ₹${coupon.minOrderValue}` : "on all orders"}`;
    } else {
        data.description = coupon.minOrderValue
            ? `On orders above ₹${coupon.minOrderValue}`
            : "On all orders";

        if (coupon.discountPercent && coupon.maxDiscountAmount) {
            data.description += ` | Up to ₹${coupon.maxDiscountAmount}`;
        }
    }

    if (addCodePrefix) {
        data.codePrefix = "Use code: ";
    }

    return data;
};
