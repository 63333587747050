import React from "react";
import { StyleSheet } from "react-native";

import { StarSellerBadge, useStarSeller } from "@swiggy-private/connect-business-commons";
import type { SdkConversation } from "@swiggy-private/connect-chat-sdk";
import { SpacingValue } from "@swiggy-private/rn-dls";
import { Box } from "@swiggy-private/rn-adaptive-layout";

const ConversationListItemTitleIconComponent: React.FC<{ item: SdkConversation }> = ({ item }) => {
    const isStarSeller = useStarSeller(item?.meta?.storeBadges);

    if (!isStarSeller) {
        return null;
    }

    return (
        <Box ml={SpacingValue["space-xx-small"]}>
            <StarSellerBadge
                iconSize={10}
                outerGradientStyle={styles.badgeGradient}
                rootContainerStyle={styles.rootContainer}
            />
        </Box>
    );
};

const styles = StyleSheet.create({
    badgeGradient: {
        padding: 0,
    },
    rootContainer: {
        paddingRight: SpacingValue["space-xx-small"],
        paddingVertical: SpacingValue["space-xx-small"],
    },
});

export const ConversationListItemTitleIcon = React.memo(ConversationListItemTitleIconComponent);
