import * as React from "react";
import { Pressable, SectionListData, StyleSheet, View } from "react-native";

import { Text, SpacingValue } from "@swiggy-private/rn-dls";
import { Box } from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";

import { getProductCategoryNativeId } from "@minis-consumer/helpers/catalog";
import { ICatalogSectionsPos } from "@minis-consumer/interfaces/catalog";

import type { Product } from "../../../interfaces/catalog";
import type { Section } from "./types";

export interface ProductSectionHeaderProps {
    section: SectionListData<Product, Section>;
    onToggle?: (id: string) => void;
    expanded?: boolean;
    catalogMeasureRef: React.RefObject<ICatalogSectionsPos>;
}

export const ProductSectionHeader: React.FC<ProductSectionHeaderProps> = ({
    section,
    onToggle,
    catalogMeasureRef,
    expanded = true,
}) => {
    const { category } = section;
    const itemCount = category.products.length;

    const sectionHeaderRef = React.useRef<View>(null);

    React.useEffect(() => {
        if (!catalogMeasureRef.current) {
            return;
        }

        catalogMeasureRef.current[category.id] = sectionHeaderRef.current;
    }, [catalogMeasureRef, category.id]);

    const onPress = React.useCallback(() => {
        onToggle?.(category.id);
    }, [category.id, onToggle]);

    return (
        <Pressable
            onPress={onPress}
            accessible
            accessibilityRole="button"
            nativeID={getProductCategoryNativeId(category.id)}>
            <Box
                collapsable={false}
                ref={sectionHeaderRef}
                direction="row"
                mv={SpacingValue["space-medium"]}>
                <Box flex={1}>
                    <Text category="btn1" color="highest" weight="bold" style={styles.categoryName}>
                        {category.name}
                    </Text>
                    {itemCount ? (
                        <Text category="s1" color="medium">
                            {itemCount} {itemCount > 1 ? "items" : "item"}
                        </Text>
                    ) : null}
                </Box>
                {onToggle ? (
                    <SvgIcon
                        icon={expanded ? "ChevronUp" : "ChevronDown"}
                        width={24}
                        height={24}
                        color="color-primary"
                    />
                ) : null}
            </Box>
        </Pressable>
    );
};

const styles = StyleSheet.create({
    categoryName: {
        textTransform: "capitalize",
    },
});
