/** Convert seconds to mm:ss format */
export const convertSecondsToMinutesAndSeconds = (sec: number): string => {
    if (sec < 60) {
        return sec < 10 ? `00:0${sec}` : `00:${sec}`;
    }

    const minutes = Math.floor(sec / 60);
    const seconds = sec % 60;

    return `${minutes < 10 ? "0" : ""}${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
};
