import { ApiError } from "@swiggy-private/connect-api-core";
import { HttpError } from "@swiggy-private/http-client";

import Sentry from "./sentry.web";

enum LogLevelEnum {
    DEBUG = 1,
    INFO = 2,
    ERROR = 3,
    NONE = 999,
}

export type LogLevel = keyof typeof LogLevelEnum;

const isTestEnv = process.env.NODE_ENV === "test";
let logLevel: LogLevelEnum = isTestEnv
    ? LogLevelEnum.NONE
    : __DEV__
    ? LogLevelEnum.DEBUG
    : LogLevelEnum.NONE;

const setLogLevel = (level: LogLevel): void => {
    logLevel = LogLevelEnum[level];
};

const canRecordError = (err: Error): boolean => {
    return !(err instanceof HttpError) && !(err instanceof ApiError);
};

const recordError = (err: unknown): void => {
    if (!isTestEnv && isInfoEnabled()) {
        console.log(err);
    }

    if (__DEV__) {
        return;
    }

    if (err instanceof Error && canRecordError(err)) {
        Sentry.captureException(err);
    } else if (typeof err === "string" && err) {
        Sentry.captureMessage(err);
    }
};

const error = (message?: unknown, ...optionalParams: unknown[]): void => {
    if (message instanceof Error) {
        recordError(message);
    }

    if (logLevel <= LogLevelEnum.ERROR) {
        console.error(message, ...optionalParams);
    }
};

const debug = (message?: unknown, ...optionalParams: unknown[]): void => {
    if (isDebugEnabled()) {
        console.debug(message, ...optionalParams);
    }
};

const info = (message?: unknown, ...optionalParams: unknown[]): void => {
    if (typeof message === "string" && message.length > 0) {
        Sentry.addBreadcrumb({ message });
    }

    if (isInfoEnabled()) {
        console.info(message, ...optionalParams);
    }
};

const isDebugEnabled = (): boolean => {
    return logLevel <= LogLevelEnum.DEBUG;
};

const isErrorEnabled = (): boolean => {
    return logLevel <= LogLevelEnum.ERROR;
};

const isInfoEnabled = (): boolean => {
    return logLevel <= LogLevelEnum.INFO;
};

export const Logger = {
    error,
    info,
    debug,
    setLogLevel,
    isDebugEnabled,
    recordError,
    isErrorEnabled,
    isInfoEnabled,
};
