import * as React from "react";
import { Pressable, StyleSheet } from "react-native";

import { Modal, SpacingValue, useTheme } from "@swiggy-private/rn-dls";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { Stack } from "@swiggy-private/rn-adaptive-layout";

import { Analytics } from "@minis-consumer/analytics";

import { RatingAndReviewForm } from "../rating-and-review-form";
import { ANALYTICS_COPIES } from "./constants";

interface RatingAndReviewModalProps {
    ratingCount: number;
    orderId: string;
    setOpen: (b: boolean) => void;
    storeId: string;

    isOpen?: boolean;
    orderKey?: string;
}

const RatingAndReviewModalComponent: React.FC<RatingAndReviewModalProps> = ({
    ratingCount,
    orderId,
    isOpen = false,
    setOpen,
    storeId,
    orderKey,
}) => {
    React.useEffect(() => {
        if (!isOpen) {
            return;
        }

        Analytics.impressionEvent({
            category: ANALYTICS_COPIES.ORDER_RATING_AND_REVIEW_MODAL,
            context: JSON.stringify({
                storeId,
                orderId,
            }),
        });
    }, [isOpen, orderId, storeId]);

    return (
        <Modal
            showModal={isOpen}
            setShowModal={setOpen}
            modalStyles={{ modalBodyStyle: styles.modalStyles }}
            component={
                <ModalComponent
                    ratingCount={ratingCount}
                    orderId={orderId}
                    setOpen={setOpen}
                    storeId={storeId}
                    orderKey={orderKey}
                />
            }
        />
    );
};

const ModalComponentFC: React.FC<RatingAndReviewModalProps> = ({
    setOpen,
    ratingCount,
    orderId,
    storeId,
    orderKey,
}) => {
    const { value: theme } = useTheme();

    const formSubmitHandler = React.useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    return (
        <>
            <Stack alignItems="flex-end">
                <Pressable
                    onPress={formSubmitHandler}
                    style={[styles.iconContainer, { backgroundColor: theme["color-basic-5"] }]}>
                    <SvgIcon icon="Close" width={14} height={14} color="color-basic-45" />
                </Pressable>
            </Stack>

            <RatingAndReviewForm
                defaultRating={ratingCount}
                orderId={orderId}
                formSubmit={formSubmitHandler}
                storeId={storeId}
                orderKey={orderKey}
            />
        </>
    );
};

const ModalComponent = React.memo(ModalComponentFC);

const styles = StyleSheet.create({
    modalStyles: {
        maxWidth: 600,
        width: 600,
        height: 630,
        padding: SpacingValue["space-xx-large"],
        borderRadius: 24,
    },
    iconContainer: {
        alignItems: "center",
        justifyContent: "center",
        height: 32,
        width: 32,
        borderRadius: 50,
    },
});

export const RatingAndReviewModal = React.memo(RatingAndReviewModalComponent);
