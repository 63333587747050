import * as React from "react";

import { RatingAndReviewModalContext } from "@minis-consumer/contexts/rating-and-review-context";

type handlerType = {
    canOpen: boolean;
    orderId?: string;
    ratingCount?: number;
    storeId?: string;
    orderKey?: string;
};

export const useReviewAndRatingHandler = (): {
    canOpen: boolean;
    orderId: string;
    ratingCount: number;
    storeId: string;
    orderKey: string;
    handler: (o: handlerType) => void;
} => {
    const ctx = React.useContext(RatingAndReviewModalContext);

    const setContextValues = React.useCallback(
        ({ orderId, ratingCount, storeId, orderKey }: handlerType): void => {
            ctx?.setOrderId(orderId ?? "");
            ctx?.setRatingCount(ratingCount ?? 0);
            ctx?.setStoreId(storeId ?? "");
            ctx?.setOrderKey(orderKey ?? "");
        },
        [ctx],
    );

    const handleOpenClose = React.useCallback(
        (open: boolean, orderId?: string): void => {
            if (open) {
                if (!orderId) {
                    return;
                }
                ctx?.open();
            } else {
                ctx?.close();
            }
        },
        [ctx],
    );

    const handler = React.useCallback(
        (params: handlerType) => {
            setContextValues(params);
            handleOpenClose(params.canOpen, params.orderId);
        },
        [handleOpenClose, setContextValues],
    );

    return {
        canOpen: ctx?.isOpen ?? false,
        orderId: ctx?.orderId ?? "",
        ratingCount: ctx?.ratingCount ?? 0,
        storeId: ctx?.storeId ?? "",
        orderKey: ctx?.orderKey ?? "",
        handler,
    };
};
