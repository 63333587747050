import React from "react";

import { SdkConversation } from "@swiggy-private/connect-chat-sdk";

import { useSignedIn } from "@minis-consumer/hooks/use-user";
import { useAuthHandler } from "@minis-consumer/hooks/use-auth-modal-handler";

import { ChatConversationNudgeContent } from "./components/conversation-nudge-content";

const ChatConversationNudgeComponent: React.FC<{ conversation: SdkConversation }> = ({
    conversation,
}) => {
    const { title, meta } = conversation;

    const isSignedIn = useSignedIn();
    const [, setIsAuthOpen] = useAuthHandler();

    const onPressCta = React.useCallback(() => {
        setIsAuthOpen(true);
    }, [setIsAuthOpen]);

    if (!conversation) {
        return null;
    }

    return (
        <ChatConversationNudgeContent
            isGuestUser={!isSignedIn}
            storeName={title}
            storeId={meta?.storeId ?? ""}
            onPressCta={onPressCta}
        />
    );
};

export const ChatConversationNudge = React.memo(ChatConversationNudgeComponent);
