import React from "react";

import { Analytics } from "@minis-consumer/analytics";
import { Event } from "@minis-consumer/analytics";

type ReturnValue = {
    onMountAnalytics: (p: Event) => void;
    onClickAnalytics: (p: Event) => void;
};

// Added only basic events for now
export const useWidgetAnalytics = (): ReturnValue => {
    const onMountAnalytics = React.useCallback((params: Event) => {
        if (!params) {
            return;
        }

        Analytics.impressionEvent(params);
    }, []);

    const onClickAnalytics = React.useCallback((params: Event) => {
        if (!params) {
            return;
        }

        Analytics.clickEvent(params);
    }, []);

    return {
        onMountAnalytics,
        onClickAnalytics,
    };
};
