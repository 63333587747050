import React from "react";
import { SimpleMenu } from "@swiggy-private/react-native-ui";
import { View, StyleSheet } from "react-native";
import { NavigationProp, useNavigation } from "@react-navigation/native";

import { RouteList } from "@minis-consumer/interfaces/route";
import { useLogout } from "@minis-consumer/hooks/use-logout";

import { DROPDOWN_ITEMS } from "../constants";
import { AccountDropDownTileItem } from "./account-dropdown-tile";

interface Props {
    open: boolean;
    anchorEl: React.RefObject<View>;
    onClose: () => void;
}

export const AccountDropDown: React.FC<Props> = ({ anchorEl, onClose, open }) => {
    const navigation = useNavigation<NavigationProp<RouteList>>();

    const logout = useLogout();

    if (!open) {
        return null;
    }

    return anchorEl.current ? (
        <SimpleMenu onClose={onClose} anchorEl={anchorEl.current} style={styles.container}>
            <>
                {DROPDOWN_ITEMS.map(({ name, routeName, key }) => {
                    return (
                        <AccountDropDownTileItem
                            key={key}
                            name={name}
                            onPress={() => {
                                if (key === "account") {
                                    navigation.navigate("Home", { screen: "MyAccount" });
                                } else if (routeName) {
                                    navigation.navigate("Account", { screen: routeName as never });
                                } else if (key === "logout") {
                                    logout();
                                }
                                onClose();
                            }}
                        />
                    );
                })}
            </>
        </SimpleMenu>
    ) : null;
};

const styles = StyleSheet.create({
    container: {
        top: 48,
        paddingTop: 0,
        paddingBottom: 0,
    },
});
