import * as React from "react";
import { Pressable, StyleSheet } from "react-native";

import { StoreMedia } from "@swiggy-private/react-native-ui";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text, TextProps, useTheme } from "@swiggy-private/rn-dls";

import { RecommendedBadge } from "@minis-consumer/components/catalog/product-badge";
import { ProductPrice } from "@minis-consumer/components/catalog/product-price";
import { ProductVariantsSizeLabel } from "@minis-consumer/components/variants";
import { RENDER_ON_SERVER_ERROR_MSG } from "@minis-consumer/constants/error";
import { getProductMainImageUrl } from "@minis-consumer/helpers/catalog";
import { getProductFallbackImages } from "@minis-consumer/helpers/product-fallback-images";

import { useLocalCartItem } from "@minis-consumer/hooks/use-cart";
import { useRenderClientOnly } from "@minis-consumer/hooks/use-render-client-only";
import type { Product } from "@minis-consumer/interfaces/catalog";
import { useGetProductCtaProps } from "@minis-consumer/components/product-cta/hooks/use-get-cta-actions";
import { ProductCta } from "@minis-consumer/components/product-cta";

import { DigitalAssetInfo } from "../../digital-asset.info";
import { CommunicationChannel } from "../../communication-channel";
import { ProductDuration } from "../../product-duration";

interface IProductProps {
    product: Product;
    index: number;
    onProductPress: VoidFunction;

    onItemAddCallback?: VoidFunction;
    showAddToCart?: boolean;
    showRecommendedBadge?: boolean;

    imageSize?: number;
    productNameProps?: TextProps;
    sfWidget?: string;
}

const PRODUCT_ITEM_SIZE = 136;

const ProductSmallCardComponent: React.FC<IProductProps> = ({
    product,
    imageSize,
    onProductPress,
    onItemAddCallback,
    showAddToCart,
    showRecommendedBadge = true,
    productNameProps,
    sfWidget,
}) => {
    useRenderClientOnly(RENDER_ON_SERVER_ERROR_MSG);
    const { value: theme } = useTheme();
    const { id, name, price, badges, storeId, discountPercent, discountedPrice, variantDetails } =
        product;
    const imageBgColor = theme["color-secondary-light"];
    const productItemSize = imageSize ?? PRODUCT_ITEM_SIZE;
    const [, increment, decrement] = useLocalCartItem({
        storeId: storeId,
        itemId: id,
        productType: product.productType ?? "PHYSICAL",
    });

    const productMainImageUrl = getProductMainImageUrl(
        product,
        {
            width: productItemSize + 2,
            height: productItemSize + 2,
        },
        true,
    );

    const imageStyle = {
        height: productItemSize,
        width: productItemSize,
        backgroundColor: productMainImageUrl ? theme["color-basic-0"] : imageBgColor,
    };

    const separatorStyle = {
        ...styles.separator,
        borderColor: theme["color-basic-45"],
    };

    const onItemAdd = React.useCallback((): void => {
        price && increment();
        onItemAddCallback?.();
    }, [increment, price, onItemAddCallback]);

    const productCtaActionsAndStyles = useGetProductCtaProps({
        productId: product.id,
        storeId,
        buttonProps: {
            style: styles.btn,
            elevation: 0,
        },
        sfWidget,
        productActionCtaName: `${sfWidget?.toLowerCase()}-product-action-cta`,
        onAdd: onItemAdd,
        onRemoveOrDecrement: decrement,
    });

    const productImgFallback = getProductFallbackImages(product.productType);

    return (
        <Pressable
            testID="product-small-card"
            onPress={onProductPress}
            accessible
            accessibilityLabel="Open product">
            <Stack direction="column" style={styles.item} spacing={SpacingValue["space-x-small"]}>
                <Box style={[styles.imageContainer, { borderColor: theme["color-basic-15"] }]}>
                    <StoreMedia
                        onPress={onProductPress}
                        isImageKitEnabled
                        disableVideoPlay
                        width={productItemSize + 2}
                        height={productItemSize + 2}
                        mediaId={productMainImageUrl ?? productImgFallback}
                        style={imageStyle}
                        resizeMode="cover"
                        showLoader
                        testID="product-small-card-image"
                    />
                </Box>

                <Stack
                    spacing={SpacingValue["space-xxx-small"]}
                    style={{ maxWidth: productItemSize }}>
                    <Text
                        color="high"
                        category="b1"
                        weight="bold"
                        numberOfLines={2}
                        {...productNameProps}>
                        {name}
                    </Text>
                    <ProductVariantsSizeLabel options={variantDetails?.options || []} />

                    {product.digitalAssetInfo && product.productType === "DIGITAL" ? (
                        <DigitalAssetInfo {...product.digitalAssetInfo} />
                    ) : null}

                    {product.productType === "APPOINTMENT" || product.productType === "PLAN" ? (
                        <Stack direction="row" style={styles.commChannelsAndDuration}>
                            <CommunicationChannel
                                channels={product.serviceDeliveryChannels}
                                showFirst
                            />

                            {product.serviceDeliveryChannels?.length ?? 0 > 0 ? (
                                <Box mh={SpacingValue["space-xx-small"]} style={separatorStyle} />
                            ) : null}
                            {product.productDuration ? (
                                <ProductDuration {...product.productDuration} />
                            ) : null}
                        </Stack>
                    ) : null}
                </Stack>

                <ProductPrice
                    price={price}
                    discountedPrice={discountedPrice}
                    discountPercent={discountPercent}
                />
                {showAddToCart ? (
                    <Box style={styles.btnContainer} testID="add-to-cart-btn">
                        <ProductCta
                            {...productCtaActionsAndStyles}
                            productId={product.id}
                            storeId={storeId}
                            showVariants
                            price={product.price}
                            type={product.productType}
                        />
                    </Box>
                ) : null}
            </Stack>

            {showRecommendedBadge ? <RecommendedBadge badges={badges} /> : null}
        </Pressable>
    );
};

export const ProductSmallCard = React.memo(ProductSmallCardComponent);

const styles = StyleSheet.create({
    item: {
        overflow: "hidden",
        flexGrow: 1,
    },
    imageContainer: {
        borderRadius: 12,
        borderWidth: 1,
        overflow: "hidden",
    },
    btn: {
        marginTop: SpacingValue["space-xx-small"],
        minHeight: 40,
        height: 40,
    },
    btnContainer: {
        flexGrow: 1,
        justifyContent: "flex-end",
        marginBottom: SpacingValue["space-xxx-small"],
    },
    commChannelsAndDuration: {
        marginTop: SpacingValue["space-xxx-small"],
        flexWrap: "wrap",
    },
    separator: {
        borderWidth: 0.5,
    },
});
