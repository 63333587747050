import React, { FC, memo, useCallback } from "react";
import { StyleSheet } from "react-native";

import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { Button, ButtonProps } from "@swiggy-private/rn-dls";
import { InView } from "@swiggy-private/react-native-ui";

import { withVariantFormCalendarSelector } from "./hoc/with-variant-form-calendar";
import { ProductCtaProps } from ".";

const DEFAULT_ICON_SIZE = 24;

const SingleActionProductCtaComponent: FC<ProductCtaProps> = ({
    ctaText,
    ctaIconColor,
    ctaStyle,
    ctaTextStyle,
    ctaElevation,
    ctaColor,
    ctaIcon,
    ctaIconSize,
    isDisabled,
    isEditForm,
    singleActionHandler: onPress,
    impressionEvent,
}) => {
    const onImpression = useCallback(
        (isVisible: boolean) => {
            isVisible && impressionEvent?.();
        },
        [impressionEvent],
    );

    if (!ctaText) {
        return null;
    }

    const ACCESSORY_LEFT = ctaIcon ? (
        <SvgIcon
            icon={ctaIcon}
            height={ctaIconSize ?? DEFAULT_ICON_SIZE}
            width={ctaIconSize ?? DEFAULT_ICON_SIZE}
            color={ctaIconColor?.toString() ?? "color-primary"}
        />
    ) : undefined;

    const ctaProps: ButtonProps = {
        color: ctaColor ?? "primary",
        textStyle: ctaTextStyle,
        style: [styles.button, ctaStyle],
        elevation: ctaElevation,
    };

    return (
        <InView onChange={onImpression}>
            <Button
                color="primary"
                accessoryLeft={ACCESSORY_LEFT}
                accessoryAlignment={isEditForm ? "end" : "center"}
                onPress={onPress}
                disabled={isDisabled}
                {...ctaProps}>
                {ctaText}
            </Button>
        </InView>
    );
};

export const SingleActionProductCta = withVariantFormCalendarSelector(
    memo(SingleActionProductCtaComponent),
);

SingleActionProductCtaComponent.displayName = "SingleActionProductCta";

const styles = StyleSheet.create({
    button: {
        borderRadius: 8,
    },
});
