import { Product, ProductCategory } from "@minis-consumer/interfaces/catalog";

type TCategoryBasedProduct = {
    category: ProductCategory;
    data: Product[];
};

export const organiseProducts = ({
    category,
    data,
}: TCategoryBasedProduct): TCategoryBasedProduct => {
    const { inStock, outOfStock } = data.reduce(
        (acc, curr: Product) => {
            if (curr.inStock) {
                acc.inStock.push(curr);
            } else {
                acc.outOfStock.push(curr);
            }
            return acc;
        },
        { inStock: [] as Product[], outOfStock: [] as Product[] },
    );

    return { category, data: [...inStock, ...outOfStock] };
};
