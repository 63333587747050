import React from "react";
import { StyleSheet } from "react-native";

import { SpacingValue, Modal } from "@swiggy-private/rn-dls";
import { useMount } from "@swiggy-private/react-hooks";

import { Analytics } from "@minis-consumer/analytics";
import { useCartClearWithoutHardDelete, useCartView } from "@minis-consumer/hooks/use-cart";
import { useClearCartModalHandler } from "@minis-consumer/hooks/use-clear-cart-modal-handler";

import { ModalBody } from "./components/modal-body";
import { ANALYTICS_COPIES, IS_WEB, MAX_MODAL_WIDTH_WEB } from "./constants";

interface Props {
    showModal: boolean;
    closeModal: (isCartCleared?: boolean) => void;
}

const ClearCartDialogComponent: React.FC<Props> = ({ showModal, closeModal }) => {
    const [showLoader, setShowLoader] = React.useState(false);

    const {
        dataHandler: modalDataHandler,
        storeId,
        descriptionPrefix,
    } = useClearCartModalHandler();

    const clearCart = useCartClearWithoutHardDelete(storeId, true);

    const fireArdEvent = React.useCallback(() => {
        Analytics.clickEvent({
            category: ANALYTICS_COPIES.CLEAR_CART_CTA,
            context: JSON.stringify({
                storeId,
            }),
        });
    }, [storeId]);

    const onClearCart = React.useCallback(async () => {
        fireArdEvent();

        setShowLoader(true);
        await clearCart();

        setShowLoader(false);
        modalDataHandler({ storeId: "", descriptionPrefix: "" });

        closeModal(true);
    }, [closeModal, modalDataHandler, fireArdEvent, clearCart]);

    const cartView = useCartView(storeId);
    const removeItemsTextData = React.useMemo(() => {
        const cartItems = cartView?.cartViewData?.cartItems ?? [];
        const totalItems = cartItems.length;
        const cartFirstItemName = cartItems?.[0]?.item.name;

        return {
            prefix: "Remove ",
            cartFirstItemName: `${cartFirstItemName} ${
                totalItems > 1 ? `and ${totalItems - 1} more item` : ""
            }`,
            suffix: " from cart to proceed",
        };
    }, [cartView]);

    useMount(() => {
        Analytics.impressionEvent({
            category: ANALYTICS_COPIES.CLEAR_CART_CTA,
            context: JSON.stringify({
                storeId,
            }),
        });
    });

    return (
        <Modal
            showModal={showModal}
            setShowModal={closeModal}
            modalStyles={{
                modalBodyStyle: styles.modalBodyStyle,
            }}
            component={
                <ModalBody
                    closeModal={closeModal}
                    storeId={storeId}
                    descriptionPrefix={descriptionPrefix}
                    onClearCart={onClearCart}
                    showLoader={showLoader}
                    removeItemsTextData={removeItemsTextData}
                />
            }
        />
    );
};

export const ClearCartDialog = React.memo(ClearCartDialogComponent);

const styles = StyleSheet.create({
    modalBodyStyle: {
        borderRadius: 8,
        maxWidth: IS_WEB ? MAX_MODAL_WIDTH_WEB : "80%",
        width: IS_WEB ? MAX_MODAL_WIDTH_WEB : "80%",
        paddingTop: SpacingValue["space-x-large"],
        paddingHorizontal: SpacingValue["space-medium"],
        paddingBottom: SpacingValue["space-medium"],
    },
});
