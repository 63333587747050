import { StyleSheet, StyleProp, ViewStyle, Pressable } from "react-native";
import React, { FC, memo } from "react";

import { Box } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";

import { VariantOption, Variant } from "@minis-consumer/interfaces/catalog";

import { getPillStyles, getTextProps, isVariantAvailable } from "./helpers";
import { VARIANT_TEST_IDS } from "../../constants";

interface OptionsItemProps {
    options: VariantOption[];
    variantDetails: Variant[];
    selectedVariants: Variant["optionValueMap"] | null;
    variant: VariantOption;
    item: string;

    onPress?: (item: string, variant: VariantOption) => void;
}

const OptionsItemComponent: FC<OptionsItemProps> = ({
    selectedVariants,
    variant,
    options,
    variantDetails,
    item,
    onPress,
}) => {
    const { value: theme } = useTheme();

    const isSelected = !!(selectedVariants && selectedVariants[variant.name] === item);
    const isUnavailable = !isVariantAvailable({
        variantName: item,
        optionName: variant.name,
        options,
        variantDetails,
        selectedVariants,
    });
    const pillStyles = getPillStyles({ selected: isSelected, unavailable: isUnavailable, theme });
    const textProps = getTextProps({ selected: isSelected, unavailable: isUnavailable });
    const borderStyles: StyleProp<ViewStyle> = {
        borderWidth: 2,
    };

    return (
        <Pressable
            testID={`${VARIANT_TEST_IDS.ON_OPTION_SELECTION}-${item}`}
            onPress={() => onPress?.(item, variant)}
            key={item}>
            <Box
                direction="row"
                alignItems="center"
                justifyContent="center"
                style={[styles.pill, borderStyles, pillStyles]}>
                <Text {...textProps}>{item}</Text>
            </Box>
        </Pressable>
    );
};

export const OptionsItem = memo(OptionsItemComponent);

const styles = StyleSheet.create({
    pill: {
        height: 42,
        minWidth: 72,
        borderRadius: 100,
        paddingHorizontal: SpacingValue["space-medium"],
    },
});
