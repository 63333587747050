import { API_SUCCESS_STATUS_CODE } from "@swiggy-private/connect-api-core";
import { Endpoint } from "@swiggy-private/http-client";

import { IDeliveryInfo } from "@minis-consumer/interfaces/delivery";
import { getApiClient } from "@minis-consumer/includes/api";

import { DeliveryEtaEndpoint } from "../endpoint";

export const DELIVERY_INFO_API_ERRORS = {
    FETCH_DELIVERY_INFO: "Could not fetch the delivery info",
};

export interface DeliveryInfoParams {
    storeId: string;
    dropPincode: string;
    pickupPincode: string;
    dropLat?: number | null;
    dropLng?: number | null;
    pickupLat?: number;
    pickupLng?: number;
}

const createError = (errorKey: string, storeId: string): IDeliveryInfo => {
    return {
        error: errorKey,
        storeId: storeId,
    };
};

export const fetchDeliveryInfo = async ({
    storeId,
    dropPincode,
    pickupPincode,
    dropLat,
    dropLng,
    pickupLat,
    pickupLng,
}: DeliveryInfoParams): Promise<IDeliveryInfo> => {
    const endpoint = Endpoint.from(DeliveryEtaEndpoint, { storeId });

    try {
        if (!dropPincode || !pickupPincode) {
            return createError(DELIVERY_INFO_API_ERRORS.FETCH_DELIVERY_INFO, storeId);
        }

        const params = {
            dropPincode: dropPincode,
            pickupPincode: pickupPincode,
            lat2: dropLat?.toString() ?? "",
            lng2: dropLng?.toString() ?? "",
            lat1: pickupLat?.toString() ?? "",
            lng1: pickupLng?.toString() ?? "",
        };

        const response = await getApiClient().get<IDeliveryInfo>({
            endpoint,
            queryParams: params,
        });

        if (response.statusCode === API_SUCCESS_STATUS_CODE && response.data) {
            return { ...response.data, storeId: storeId };
        }

        return createError(DELIVERY_INFO_API_ERRORS.FETCH_DELIVERY_INFO, storeId);
    } catch (err) {
        return createError(DELIVERY_INFO_API_ERRORS.FETCH_DELIVERY_INFO, storeId);
    }
};
