import * as React from "react";

export interface IActiveOrderTooltip {
    onDismissNudge: (cb?: VoidFunction) => void;
    setCanShowTooltip: (a: boolean) => void;
    setActiveOrder: (a: boolean) => void;
    setIsTooltipShown: (a: boolean) => void;
    canShowTooltip?: boolean;
    isTooltipShown?: boolean;
    hasActiveOrder?: boolean;
}

const ActiveOrderTooltipContext = React.createContext<IActiveOrderTooltip>({
    canShowTooltip: false,
    isTooltipShown: false,
    hasActiveOrder: false,
    onDismissNudge: () => undefined,
    setCanShowTooltip: () => undefined,
    setActiveOrder: () => undefined,
    setIsTooltipShown: () => undefined,
});

export const ActiveOrderTooltipProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [canShowTooltip, setCanShowTooltip] = React.useState(false);
    const [hasActiveOrder, setActiveOrder] = React.useState(false);
    const [isTooltipShown, setIsTooltipShown] = React.useState(false);

    const onDismissNudge = React.useCallback((cb?: VoidFunction) => {
        setCanShowTooltip(false);
        cb?.();
    }, []);

    const value = React.useMemo(
        () => ({
            canShowTooltip,
            hasActiveOrder,
            isTooltipShown,
            setCanShowTooltip,
            setActiveOrder,
            onDismissNudge,
            setIsTooltipShown,
        }),
        [
            canShowTooltip,
            hasActiveOrder,
            isTooltipShown,
            setCanShowTooltip,
            onDismissNudge,
            setActiveOrder,
            setIsTooltipShown,
        ],
    );

    return (
        <ActiveOrderTooltipContext.Provider value={value}>
            {children}
        </ActiveOrderTooltipContext.Provider>
    );
};

export const useActiveOrderTooltipContext = (): IActiveOrderTooltip =>
    React.useContext(ActiveOrderTooltipContext);
