import { getObjectValue, removeValue, setValue } from "@swiggy-private/common-helpers";
import { ConsumerAddressResponse } from "@swiggy-private/connect-address-ui";
import { Icon } from "@swiggy-private/connect-svg-icons";

import { UserAddress } from "@minis-consumer/interfaces/cart";
import { OrderAddress } from "@minis-consumer/interfaces/order";

export type AddressStorageType = {
    [addressId: string]: {
        postalCode: number;
        city?: string;
        state?: string;
    };
};

export const _addressStorageKey = "@addressSelectedDetails";

export const getAddressStorageMeta = async (): Promise<{
    addresses: AddressStorageType;
} | null> => {
    try {
        const data = await getObjectValue<{ addresses: AddressStorageType } | null>(
            _addressStorageKey,
        );

        return data;
    } catch (err) {}

    return null;
};

export const setAddressStorageMeta = async (value: AddressStorageType): Promise<void> => {
    try {
        const oldData = await getAddressStorageMeta();

        const dump = {
            ...oldData?.addresses,
            ...value,
        };

        await setValue(_addressStorageKey, { addresses: dump });
    } catch (err) {}
};

export const removeAddressStorageMeta = async (): Promise<void> => {
    try {
        await removeValue(_addressStorageKey);
    } catch (err) {}
};

export const getFormattedAddress = (address: ConsumerAddressResponse | UserAddress): string => {
    return `${address.address ? address.address + ", " : ""}${
        address?.addressLine2 ? address.addressLine2 + " " : ""
    }${address.formattedAddress ?? ""}`;
};

const getAddressIcon = (key: string, canUseFilledIcon?: boolean): Icon => {
    switch (key) {
        case "home":
            return canUseFilledIcon ? "NewHomeFilled" : "NewHome";
        case "friends and family":
            return canUseFilledIcon ? "FriendsAndFamilyFilled" : "FriendsAndFamily";
        case "work":
            return canUseFilledIcon ? "SuitcaseFilled" : "Suitcase";
        default:
            return canUseFilledIcon ? "NavigationFilled" : "Navigation";
    }
};

export const displayAddressNameAndIcon = (
    address: UserAddress | OrderAddress,
    isFilled?: boolean,
): { addressTag: string; icon: Icon } => {
    let icon: Icon = "Navigation";
    let addressTag = "Other";

    if (Array.isArray(address?.annotations) && address?.annotations.length) {
        addressTag = address.annotations[0];
        icon = getAddressIcon(address.annotations[0].toLowerCase(), isFilled);
    }

    if (Array.isArray(address?.tags) && address?.tags.length) {
        addressTag = address.tags[0];
        icon = getAddressIcon(address.tags[0].toLowerCase(), isFilled);
    }

    // Takes priority
    if (address?.displayName) {
        addressTag = address.displayName;
    }

    return { addressTag, icon };
};
