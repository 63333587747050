import * as React from "react";
import { Pressable, StyleSheet } from "react-native";
import { NavigationProp, useNavigation } from "@react-navigation/core";

import { Emoji, InView } from "@swiggy-private/react-native-ui";
import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text } from "@swiggy-private/rn-dls";
import { SdkConversation } from "@swiggy-private/connect-chat-sdk";
import { OrderUpdateMessage as IOrderUpdateMessage } from "@swiggy-private/connect-chat-commons";

import { Analytics } from "@minis-consumer/analytics";
import { MinisRouteList, RouteList } from "@minis-consumer/interfaces/route";

import { ANALYTICS_COPIES, COPIES } from "../../constants";
import { getOrderCtaText } from "../../helpers/order-updates";

interface OrderUpdateMessageProps {
    message: IOrderUpdateMessage;
    conversationId: string;
    conversationMeta?: SdkConversation["meta"];
}

const DELIVERED_STATUS = "DELIVERED";

export const OrderUpdateMessage: React.FC<OrderUpdateMessageProps> = ({
    message: { payload: data, type },
    conversationId,
}) => {
    const { orderId, orderStatus, orderType } = data;
    const isDelivered = orderStatus === DELIVERED_STATUS;
    const navigation = useNavigation<NavigationProp<RouteList & MinisRouteList>>();

    const analyticsContextData = React.useMemo(() => {
        return JSON.stringify({
            orderId,
            orderStatus,
            messageType: type,
            conversationId,
        });
    }, [conversationId, orderId, orderStatus, type]);

    const onOrderUpdateMsgImpression = (isVisible: boolean): void => {
        isVisible &&
            Analytics.impressionEvent({
                category: ANALYTICS_COPIES.CHAT_MSGS,
                context: analyticsContextData,
            });
    };

    const onPress = React.useCallback(() => {
        Analytics.clickEvent({
            category: ANALYTICS_COPIES.CHAT_MSGS,
            context: analyticsContextData,
        });

        navigation.navigate("Order", {
            id: data.orderId,
        });
    }, [analyticsContextData, data.orderId, navigation]);

    const trackCtaText = React.useMemo(() => {
        if (!orderType || !orderStatus) {
            return isDelivered ? COPIES.ORDER_DETAILS : COPIES.TRACK_ORDER_STATUS_TEXT;
        }

        return getOrderCtaText({ orderType, orderStatus });
    }, [orderType, orderStatus, isDelivered]);

    return (
        <InView onChange={onOrderUpdateMsgImpression}>
            <Stack spacing={SpacingValue["space-medium"]} style={styles.container}>
                <Stack spacing={SpacingValue["space-x-small"]}>
                    {data.title ? (
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                            spacing={SpacingValue["space-xx-small"]}>
                            <Text
                                category="b2"
                                weight="bold"
                                color="high"
                                style={styles.textAlignCenter}>
                                {data.title}
                            </Text>
                        </Stack>
                    ) : null}

                    <Text
                        category="b2"
                        weight="regular"
                        color="high"
                        style={styles.textAlignCenter}>
                        {data.text}
                    </Text>
                </Stack>

                <Pressable onPress={onPress} hitSlop={{ top: 8, bottom: 8 }}>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={SpacingValue["space-xx-small"]}>
                        <Emoji id="here_emoji" height={16} width={16} />

                        <Text color="primary" weight="bold" style={styles.trackText}>
                            {trackCtaText}
                        </Text>

                        <Emoji
                            id="here_emoji"
                            height={16}
                            width={16}
                            style={{ transform: [{ scaleX: -1 }] }}
                        />
                    </Stack>
                </Pressable>
            </Stack>
        </InView>
    );
};

const styles = StyleSheet.create({
    container: {
        marginBottom: SpacingValue["space-medium"],
    },
    textAlignCenter: {
        textAlign: "center",
    },
    trackText: {
        textDecorationStyle: "solid",
        textDecorationLine: "underline",
    },
});
