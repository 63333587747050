import * as React from "react";
import { NavigationProp, useNavigation } from "@react-navigation/core";

import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { useMountedRef } from "@swiggy-private/react-hooks";
import { ApiError } from "@swiggy-private/connect-api-core";
import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";
import { isWindowSessionStorageAvailable, setSessionValue } from "@swiggy-private/common-helpers";

import { Analytics } from "@minis-consumer/analytics";
import { useCartViewData } from "@minis-consumer/hooks/use-cart";
import { useCartUpdateGuestDetails, useLocalCart } from "@minis-consumer/hooks/use-cart";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { useToast } from "@minis-consumer/hooks/use-toast";
import { RouteList } from "@minis-consumer/interfaces/route";
import { ICartGuestDetails } from "@minis-consumer/interfaces/cart";
import { GENERIC_ERROR_MESSAGE } from "@minis-consumer/constants";
import { isValidEmail } from "@minis-consumer/helpers/email";
import { isValidMobileNumber } from "@minis-consumer/helpers/number";
import { isValidPincode } from "@minis-consumer/helpers/number";
import {
    GUEST_ADDRESS,
    useGuestCheckOutAddress,
} from "@minis-consumer/hooks/use-guest-checkout-address";

import { DeliveryDetails } from "../delivery-details";
import { ContactDetails } from "../contact-details";
import { DeliveryFormFooter } from "./footer";
import { getLatLngFromAddress } from "./utils";
import { AutoCompleteAddressProps } from "../autocomplete-address";

const ERROR_HINTS = {
    EMAIL_HINT: "Please enter a valid email address",
    PHONE_HINT: "Please enter a valid phone number",
    PINCODE_HINT: "Please enter a valid pincode",
    ADDRESS_HINT: "This address is not serviceable",
};

const propertiesToCheckForNonPhysicalCart: Array<keyof ICartGuestDetails> = [
    "fullName",
    "emailAddress",
    "phoneNumber",
];

export interface GuestCheckoutDeliveryFormProps {
    Wrapper?: React.FC<{ footer: React.ReactNode; content: React.ReactNode }>;
    canCollapse?: boolean;
}

export const GuestCheckoutDeliveryForm: React.FC<GuestCheckoutDeliveryFormProps> = ({
    Wrapper,
    canCollapse,
}) => {
    const { value: theme } = useTheme();
    const storeInfo = useStoreInfo();

    const cart = useCartViewData(storeInfo.storeId);
    const isPhysicalCart = cart?.cartType === "PHYSICAL" || !cart?.cartType;

    const guestCartUpdated = React.useRef(false);

    const initialGuestDetails = useLocalCart(storeInfo.storeId).guestDetails;

    const guestAddress = useGuestCheckOutAddress();
    const [guestDetails, setGuestDetails] = React.useState<ICartGuestDetails>(() => ({
        address: "",
        landmark: "",
        pincode: "",
        city: "",
        state: "",
        fullName: "",
        emailAddress: "",
        phoneNumber: "",
        lat: 0,
        lng: 0,
        ...initialGuestDetails,
    }));

    const [loading, updateCart] = useCartUpdateGuestDetails(storeInfo.storeId);
    const [errors, setErrors] = React.useState<Record<string, string | null>>({});
    const [showToast] = useToast();
    const mounted = useMountedRef();
    const navigation = useNavigation<NavigationProp<RouteList, keyof RouteList>>();

    const navigateToPayment = React.useCallback(
        () =>
            navigation.navigate("Payment", {
                screen: "PaymentHome",
                params: { cartId: cart?.id },
            }),
        [navigation, cart?.id],
    );

    React.useEffect(() => {
        //update local cart when there is no guest address
        if (guestCartUpdated.current) {
            return;
        }
        if (!guestDetails.lat && guestAddress) {
            updateCart(guestAddress);
            guestCartUpdated.current = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [guestAddress, updateCart]);

    const setAddress = React.useCallback((address: string) => {
        setGuestDetails((prev) => ({ ...prev, address }));
        setErrors((prev) => ({ ...prev, address: null }));
    }, []);

    const setLandmark = React.useCallback((landmark: string) => {
        setGuestDetails((prev) => ({ ...prev, landmark }));
        setErrors((prev) => ({ ...prev, landmark: null }));
    }, []);

    const setCity = React.useCallback((city: string) => {
        setGuestDetails((prev) => ({ ...prev, city }));
        setErrors((prev) => ({ ...prev, city: null }));
    }, []);

    const setState = React.useCallback((state: string) => {
        setGuestDetails((prev) => ({ ...prev, state }));
        setErrors((prev) => ({ ...prev, state: null }));
    }, []);

    const setPincode = React.useCallback((pincode: string) => {
        setGuestDetails((prev) => ({ ...prev, pincode }));
        setErrors((prev) => ({ ...prev, pincode: null }));
    }, []);

    const setFullName = React.useCallback((fullName: string) => {
        setGuestDetails((prev) => ({ ...prev, fullName }));
        setErrors((prev) => ({ ...prev, fullName: null }));
    }, []);

    const setEmail = React.useCallback((emailAddress: string) => {
        setGuestDetails((prev) => ({ ...prev, emailAddress }));
        setErrors((prev) => ({ ...prev, emailAddress: null }));
    }, []);

    const setPhone = React.useCallback((phoneNumber: string) => {
        setGuestDetails((prev) => ({ ...prev, phoneNumber }));
        setErrors((prev) => ({ ...prev, phoneNumber: null }));
    }, []);

    const handleAutoCompleteAddress: AutoCompleteAddressProps["handleAddress"] = React.useCallback(
        (address) => {
            setGuestDetails((prev) => ({
                ...prev,
                address: address.address,
                city: address.city,
                state: address.state,
                pincode: address.pincode,
                lat: address.lat ?? null,
                lng: address.lng ?? null,
            }));
            setErrors({});
        },
        [],
    );

    const isDetailsFilled = React.useMemo(() => {
        const { landmark, ...otherProperties } = guestDetails;
        const values = Object.values(otherProperties);

        if (!isPhysicalCart) {
            let isFormValid = true;
            propertiesToCheckForNonPhysicalCart.forEach((property) => {
                if (!guestDetails[property]) {
                    isFormValid = false;
                }
            });

            return isFormValid;
        }

        return values.every((value) => value !== "");
    }, [guestDetails, isPhysicalCart]);

    const hintStyles = React.useMemo(() => {
        const hasError = Object.values(errors).some(Boolean);

        return {
            backgroundColor: hasError ? theme["color-critical-light"] : "unset",
            paddingBottom: SpacingValue["space-xx-small"],
        };
    }, [errors, theme]);

    const handleValidInputs = React.useCallback(() => {
        if (!isValidPincode(guestDetails.pincode ?? "") && isPhysicalCart) {
            setErrors((prev) => ({ ...prev, pincode: ERROR_HINTS.PINCODE_HINT }));
            return false;
        }

        if (!isValidEmail(guestDetails.emailAddress)) {
            setErrors((prev) => ({ ...prev, emailAddress: ERROR_HINTS.EMAIL_HINT }));
            return false;
        }

        if (!isValidMobileNumber(guestDetails.phoneNumber)) {
            setErrors((prev) => ({ ...prev, phoneNumber: ERROR_HINTS.PHONE_HINT }));
            return false;
        }

        return true;
    }, [guestDetails.emailAddress, guestDetails.phoneNumber, guestDetails.pincode, isPhysicalCart]);

    const onSubmit = React.useCallback(async (): Promise<void> => {
        Analytics.clickEvent({
            category: "continue-to-payment-btn",
            label: cart?.id || "-",
            context: JSON.stringify({
                cartId: cart?.id,
                cartType: cart?.cartType,
            }),
        });

        try {
            if (!handleValidInputs()) {
                return;
            }

            if (isWindowSessionStorageAvailable()) {
                setSessionValue(GUEST_ADDRESS, JSON.stringify(guestDetails));
            }

            const latLng = isPhysicalCart
                ? !guestDetails.lat || !guestDetails.lng
                    ? await getLatLngFromAddress(guestDetails.address ?? "")
                    : { lat: guestDetails.lat, lng: guestDetails.lng }
                : null;

            const response = await updateCart({
                ...guestDetails,
                lat: latLng?.lat,
                lng: latLng?.lng,
            });

            if (!mounted.current) {
                return;
            }

            if (isPhysicalCart && response?.cartViewData?.serviceabilityType === "UNSERVICEABLE") {
                setErrors((prev) => ({ ...prev, address: ERROR_HINTS.ADDRESS_HINT }));
                return;
            }

            if (response && response.cartStatus === "GUEST_CART") {
                if (response?.payments?.active === false) {
                    showToast(response?.payments?.message || GENERIC_ERROR_MESSAGE);
                    return;
                }

                navigateToPayment();
                return;
            }

            // Future task: Handle unserviceable case
            showToast(GENERIC_ERROR_MESSAGE);
        } catch (err) {
            if (!mounted.current) {
                return;
            }

            if (err instanceof ApiError && err.errors) {
                const apiErrors: Record<string, string> = err.errors;
                const newErrors: Record<string, string> = {};

                Object.keys(err.errors).forEach((key) => {
                    const _key = key.indexOf(".") !== -1 ? key.split(".")[1] : key;
                    newErrors[_key] = apiErrors[key];
                });

                setErrors(newErrors);

                return;
            }

            showToast((err as Error)?.message || GENERIC_ERROR_MESSAGE);
        }
    }, [
        handleValidInputs,
        cart?.id,
        cart?.cartType,
        guestDetails,
        mounted,
        navigateToPayment,
        showToast,
        updateCart,
        isPhysicalCart,
    ]);

    const detailsElement = (
        <Stack spacing={SpacingValue["space-large"]}>
            {isPhysicalCart ? (
                <DeliveryDetails
                    completeAddress={guestDetails.address ?? ""}
                    landmark={guestDetails.landmark || ""}
                    pincode={guestDetails.pincode ?? ""}
                    city={guestDetails.city ?? ""}
                    stateValue={guestDetails.state ?? ""}
                    handleAddress={setAddress}
                    handleLandmark={setLandmark}
                    handleCity={setCity}
                    handleState={setState}
                    handlePincode={setPincode}
                    errors={errors}
                    canCollapse={canCollapse}
                    handleAutoCompleteAddress={handleAutoCompleteAddress}
                    isAddressFilled={!!guestDetails.lat && !!guestDetails.lng}
                    hintStyles={hintStyles}
                />
            ) : null}

            <ContactDetails
                fullName={guestDetails.fullName}
                email={guestDetails.emailAddress}
                phone={guestDetails.phoneNumber}
                handleFullName={setFullName}
                handleEmail={setEmail}
                handlePhone={setPhone}
                errors={errors}
                canCollapse={canCollapse}
                hintStyles={hintStyles}
            />
        </Stack>
    );

    const footerElement = (
        <DeliveryFormFooter
            onSubmit={onSubmit}
            loading={loading}
            isDetailsFilled={isDetailsFilled}
            isPhysicalCart={isPhysicalCart}
        />
    );

    if (Wrapper) {
        return (
            <Wrapper
                content={detailsElement}
                footer={
                    <DeliveryFormFooter
                        onSubmit={onSubmit}
                        loading={loading}
                        isDetailsFilled={isDetailsFilled}
                        isPhysicalCart={isPhysicalCart}
                    />
                }
            />
        );
    }

    return (
        <Stack>
            {detailsElement}
            {footerElement}
        </Stack>
    );
};
