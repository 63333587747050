import React, { useState } from "react";
import { Platform, Pressable, StyleProp, TextStyle, ViewStyle } from "react-native";

import { SpacingValue, Text, TextCategory, TextColor, TextProps } from "@swiggy-private/rn-dls";

export interface ReadMoreTextProps {
    text: string;
    maxLengthCount: number;
    textProps: TextProps;
    expandedTextProps: TextProps;
    collapsedTextProps: TextProps;

    expandedText?: string;
    collapsedText?: string;
    textCategory?: TextCategory;
    textColor?: TextColor;
    textStyle?: StyleProp<TextStyle>;
    canHideCTA?: boolean;
}

const TRIPLE_DOT = "...";

const ReadMoreTextComponent: React.FC<ReadMoreTextProps> = ({
    text,
    textStyle,
    maxLengthCount,
    expandedTextProps,
    collapsedTextProps,
    textProps,
    canHideCTA = false,
    expandedText = "show less",
    collapsedText = "read more",
}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [toggledTextProps, setToggledTextProps] = useState(expandedTextProps);

    const truncatedText = React.useMemo(
        () => text.slice(0, maxLengthCount),
        [text, maxLengthCount],
    );

    const toggleExpanded = React.useCallback(() => {
        setIsExpanded(!isExpanded);

        setToggledTextProps(isExpanded ? expandedTextProps : collapsedTextProps);
    }, [isExpanded, expandedTextProps, collapsedTextProps]);

    const textToRender = React.useMemo(() => {
        return isExpanded ? text : truncatedText;
    }, [isExpanded, text, truncatedText]);

    const textWrapperStyle: ViewStyle = {
        marginBottom: Platform.select({
            web: -SpacingValue["space-xx-small"],
            android: -(SpacingValue["space-xx-small"] + SpacingValue["space-xxx-small"] / 2),
            ios: -SpacingValue["space-xx-small"] / 2,
        }),
    };

    return (
        <Text {...textProps} style={textStyle}>
            <Text {...textProps} style={textStyle}>
                {textToRender}
            </Text>
            {text.length > maxLengthCount && !isExpanded ? (
                <Text {...textProps} style={textStyle}>
                    {TRIPLE_DOT}
                </Text>
            ) : (
                ""
            )}
            {!canHideCTA && text.length > maxLengthCount ? (
                <Pressable onPress={toggleExpanded} hitSlop={{ right: 4, bottom: 8, top: 4 }}>
                    <Text {...toggledTextProps} style={textWrapperStyle}>
                        {isExpanded ? ` ${expandedText}` : ` ${collapsedText}`}
                    </Text>
                </Pressable>
            ) : (
                ""
            )}
        </Text>
    );
};
export const ReadMoreText = React.memo(ReadMoreTextComponent);
