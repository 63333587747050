import * as React from "react";
import { IMinisContext, MinisContext } from "@minis-consumer/contexts/minis-context";
import { useBackListener } from "@swiggy-private/react-native-ui";
import { useNavigation } from "@react-navigation/core";

export const useMinisNavigation = (): IMinisContext["navigation"] | null => {
    const ctx = React.useContext(MinisContext);
    return ctx?.navigation ?? null;
};

export const useMinisNavigationBack = (): [boolean, () => void] => {
    const navigation = useMinisNavigation();

    const fn = React.useCallback(() => {
        const canGoBack = navigation?.canGoBack() ?? false;
        if (canGoBack) {
            navigation?.pop();
        } else {
            navigation?.replace("MinisHome", { screen: "Explore" });
        }
    }, [navigation]);
    return [navigation != null, fn];
};

export const useMinisNavigationGetRoute = (): any => {
    const navigation = useMinisNavigation();

    const route = navigation?.getState().routes[navigation?.getState().index];
    return route;
};

export const useMinisNavigationBackListener = (): void => {
    const navigation = useNavigation();
    const [canGoBack, fn] = useMinisNavigationBack();

    const callback = React.useCallback(() => {
        // let the navigation handle the back press
        if (navigation?.canGoBack()) {
            return false;
        }

        fn();
        return canGoBack;
    }, [canGoBack, fn, navigation]);

    useBackListener(callback);
};
