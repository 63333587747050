/* eslint-disable no-void */
import React, { createContext, useCallback, useMemo, useRef, useState } from "react";
import { useWindowDimensions } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { SpacingValue, Toast, ToastProps } from "@swiggy-private/rn-dls";
import { useMountedRef } from "@swiggy-private/react-hooks";
import { ScreenSize, useScreenSize } from "@swiggy-private/rn-adaptive-layout";

export interface ToastContextValue {
    showToast: (text: string | null, timeout?: number, props?: ToastProps) => void;
    hideToast: () => void;
}

export const ToastContext = createContext<ToastContextValue>({
    showToast: () => void 0,
    hideToast: () => void 0,
});

export const ToastProvider: React.FC<React.PropsWithChildren> = (props) => {
    const [text, setText] = useState<string | null>(null);
    const [active, setActive] = useState(false);
    const [toastProps, setToastProps] = useState<ToastProps | null>(null);

    const timerRef = useRef<number>();
    const inset = useSafeAreaInsets();
    const mountedRef = useMountedRef();
    const screenSize = useScreenSize();
    const { width: windowWidth } = useWindowDimensions();

    const hideToast = useCallback(() => {
        if (mountedRef.current) {
            timerRef.current && clearTimeout(timerRef.current);
            setActive(false);
        }
    }, [mountedRef]);

    const showToast = useCallback(
        (t: string | null, timeout = 2_500, tprops?: ToastProps) => {
            if (mountedRef.current) {
                setActive(true);
                setText(t);
                setToastProps(tprops || null);

                timerRef.current && clearTimeout(timerRef.current);
                timerRef.current = setTimeout(hideToast, timeout) as unknown as number;
            }
        },
        [hideToast, mountedRef],
    );

    const value: ToastContextValue = useMemo(() => {
        return {
            showToast,
            hideToast,
        };
    }, [hideToast, showToast]);

    const style = [
        {
            paddingHorizontal: Math.max(
                Math.max(inset.left, inset.right),
                SpacingValue["space-medium"],
            ),
            paddingBottom: Math.max(inset.bottom, SpacingValue["space-small"]),
            bottom: screenSize === ScreenSize.Large ? SpacingValue["space-x-large"] : 0,
            left: screenSize === ScreenSize.Large ? (windowWidth - 360) / 2 : 0,
            width: screenSize === ScreenSize.Large ? 360 : "100%",
        },
        toastProps?.style,
    ];

    return (
        <ToastContext.Provider value={value}>
            <>
                {props.children}
                {text != null ? (
                    <Toast visible={active} style={style} {...toastProps}>
                        {text}
                    </Toast>
                ) : null}
            </>
        </ToastContext.Provider>
    );
};
