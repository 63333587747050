import React, { useEffect } from "react";
import { ViewStyle } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useNavigation } from "@react-navigation/core";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { SpacingValue, DialogContent } from "@swiggy-private/rn-dls";
import { ScreenSize, useScreenSize, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { BottomSheet } from "@swiggy-private/react-native-ui";

import { Product, Variant } from "@minis-consumer/interfaces/catalog";
import { RouteList } from "@minis-consumer/interfaces/route";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { useGetProduct } from "@minis-consumer/routes/product/hooks/use-get-product";
import { useEnquiryChat } from "@minis-consumer/hooks/use-product-enquiry-chat";
import { Analytics } from "@minis-consumer/analytics";
import { getMediaAssets, getProductMainImageUrl } from "@minis-consumer/helpers/catalog";
import { getSelectedVariants } from "@minis-consumer/helpers/variants";

import { ModalBody } from "./components/modal-body";

interface Props {
    showModal: boolean;
    closeModal: () => void;
    productId: Product["id"];

    setIsMessageSent?: (isMessageSent: boolean) => void;
    // for instagram image enquiry, use once product enquiry is done
    imageUrl?: string;
    storeId?: string;
    productDetails?: Product;
    selectedVariant?: Variant | null;
}

const IMAGE_SIZE = 40;
const EnquiryDialogComponent: React.FC<Props> = ({
    showModal,
    closeModal,
    productId,
    storeId: dialogContextStoreId,
    productDetails,
    selectedVariant,
}) => {
    const navigation = useNavigation<NativeStackNavigationProp<RouteList>>();

    const screenSize = useScreenSize();

    const storeInfo = useStoreInfo(false);
    const storeId = storeInfo?.storeId ?? dialogContextStoreId;

    const insets = useSafeAreaInsets();

    const product = useGetProduct(productId) ?? productDetails;

    const { sendEnquiryMessage } = useEnquiryChat(storeId ?? "");

    const modalMaxWidth = useSelectScreen({
        lg: 380 as ViewStyle["width"],
        default: "100%",
    });

    useEffect(() => {
        if (!showModal || !product) {
            return;
        }

        Analytics.impressionEvent({
            category: "enquire-enter-query-half-card",
            label: product.id,
        });
    }, [product, showModal]);

    const modalStyles = useSelectScreen({
        default: {
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            paddingTop: SpacingValue["space-x-small"],
            paddingBottom: Math.max(insets.bottom, SpacingValue["space-medium"]),
        },
        lg: {
            borderRadius: 8,
            paddingHorizontal: SpacingValue["space-x-small"],
            paddingTop: SpacingValue["space-x-small"],
            paddingBottom: SpacingValue["space-x-small"],
        },
    });

    const variantText = React.useMemo(() => {
        const selectedVariantOptionsMap = selectedVariant?.optionValueMap;
        const variantOptions = product?.variantDetails?.options || [];

        if (!variantOptions.length || !selectedVariantOptionsMap) {
            return;
        }

        const selectedVariantOptions: string[] = [];
        variantOptions?.forEach((option) => {
            const variantName = option.name;
            const variantValue = selectedVariantOptionsMap?.[variantName] ?? "";
            selectedVariantOptions.push(variantValue);
        });

        return getSelectedVariants(selectedVariantOptions);
    }, [product?.variantDetails?.options, selectedVariant?.optionValueMap]);

    const onSendMessage = React.useCallback(
        (enquiryMessage: string) => {
            if (!product || !enquiryMessage) {
                return;
            }
            sendEnquiryMessage({
                text: enquiryMessage,
                productId: product.id,
                productName: product.name,
                productImageId: getMediaAssets(product)?.[0],
                productDescription: product.description,
                productPrice: product.price,
                productDiscountedPrice: product.discountedPrice,
                variantLabel: variantText,
                variantId: selectedVariant?.id,
            });

            if (screenSize !== ScreenSize.Large) {
                navigation.navigate("ChatConversation", {
                    storeId,
                });
            }
        },
        [
            product,
            sendEnquiryMessage,
            variantText,
            selectedVariant?.id,
            screenSize,
            navigation,
            storeId,
        ],
    );

    if (!showModal || !storeId || !product) {
        return null;
    }

    const productImageUrl = getProductMainImageUrl(
        product,
        {
            width: IMAGE_SIZE,
            height: IMAGE_SIZE,
        },
        true,
    );

    return (
        <BottomSheet
            open={showModal}
            onClose={closeModal}
            style={[modalStyles, { width: modalMaxWidth }]}>
            <DialogContent>
                <ModalBody
                    closeModal={closeModal}
                    onSendMessage={onSendMessage}
                    storeId={storeId}
                    imageUrl={productImageUrl ?? undefined}
                    queryText={product.price ? "Add a query for" : "Request price for"}
                    name={product.name}
                    showToastMsg
                    variantText={variantText}
                />
            </DialogContent>
        </BottomSheet>
    );
};

export const EnquiryDialog = React.memo(EnquiryDialogComponent);
