import React from "react";

import { useToast } from "./use-toast";

type ReturnType = {
    onMount: () => void;
    onLocateMePress: () => void;
    onError: (err: string) => void;
};

export const useGoogleMapFns = (): ReturnType => {
    const [showToast] = useToast();

    const onMount = React.useCallback(() => {
        // To Do: Add analytics
    }, []);

    const onError = React.useCallback(
        (err: string | Error) => {
            // @To Do: Add Logger when available
            showToast(err as string);
        },
        [showToast],
    );

    const onLocateMePress = React.useCallback(() => {
        // To Do: Add analytics
    }, []);

    return { onMount, onLocateMePress, onError };
};
