import { CLOUDINARY_BASE_URL } from "@swiggy-private/react-native-ui";

interface ICloudinary {
    cloudName: string;
    baseUrl: string;
    folderName?: string;
}

export const MinisCloudinary: ICloudinary = {
    cloudName: "swiggymini",
    baseUrl: CLOUDINARY_BASE_URL,
    folderName: "mini-assets",
};

export const SwiggyCloudinary: ICloudinary = {
    cloudName: "swiggy",
    baseUrl: CLOUDINARY_BASE_URL,
};

export const SWIGGY_CLOUDINARY_RAW_BASE_URL = `${CLOUDINARY_BASE_URL}/${SwiggyCloudinary.cloudName}/raw/upload`;
export const SWIGGY_CLOUDINARY_IMAGE_BASE_URL = `${CLOUDINARY_BASE_URL}/${SwiggyCloudinary.cloudName}/image/upload`;
export const SWIGGY_CLOUDINARY_VIDEO_BASE_URL = `${CLOUDINARY_BASE_URL}/${SwiggyCloudinary.cloudName}/video/upload`;
