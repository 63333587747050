import * as React from "react";
import { StyleSheet } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { Button, SpacingValue, Surface, Text } from "@swiggy-private/rn-dls";
import { ThreeDotsLoader } from "@swiggy-private/react-native-ui";
import { Box } from "@swiggy-private/rn-adaptive-layout";
import { useMount } from "@swiggy-private/react-hooks";

import { useIsDesktopWeb } from "@minis-consumer/hooks/use-dweb";

import { COPIES, TEST_IDS } from "../../constants";

interface FormFooterProps {
    onSubmit: VoidFunction;

    isSaving?: boolean;
    onMountCallback?: VoidFunction;
}

interface SubmitButtonProps {
    onSubmit: VoidFunction;
    btnText: string;

    isSaving?: boolean;
}

const SubmitButton: React.FC<SubmitButtonProps> = React.memo(({ onSubmit, isSaving, btnText }) => {
    const inset = useSafeAreaInsets();
    const isDWeb = useIsDesktopWeb();

    const buttonStyles = {
        margin: isDWeb ? 0 : SpacingValue["space-medium"],
        marginBottom: inset.bottom + SpacingValue["space-medium"] + SpacingValue["space-small"],
    };

    return (
        <Button size="large" onPress={onSubmit} style={buttonStyles} testID={TEST_IDS.SUBMIT_BTN}>
            {isSaving ? (
                <Box testID={TEST_IDS.BTN_LOADING} flex={1}>
                    <ThreeDotsLoader />
                </Box>
            ) : (
                <Text
                    category="btn2"
                    weight="bold"
                    color="color-basic-0"
                    testID={TEST_IDS.SUBMIT_BTN_TEXT}>
                    {btnText}
                </Text>
            )}
        </Button>
    );
});

if (__DEV__) {
    SubmitButton.displayName = "SubmitButton";
}

const FormFooterComponent: React.FC<FormFooterProps> = ({
    onSubmit,
    isSaving,
    onMountCallback,
}) => {
    const isDWeb = useIsDesktopWeb();
    const btnText = isDWeb ? COPIES.SUBMIT_REVIEW_BTN_TEXT : COPIES.SUBMIT_BTN_TEXT;

    useMount(() => {
        onMountCallback?.();
    });

    return (
        <>
            {isDWeb ? (
                <SubmitButton onSubmit={onSubmit} isSaving={isSaving} btnText={btnText} />
            ) : (
                <Surface style={styles.surface}>
                    <SubmitButton onSubmit={onSubmit} isSaving={isSaving} btnText={btnText} />
                </Surface>
            )}
        </>
    );
};

const styles = StyleSheet.create({
    surface: {
        elevation: 12,
    },
});

export const FormFooter = React.memo(FormFooterComponent);
