import * as React from "react";

import { appNudgeClosedForSession } from "@minis-consumer/helpers/app-nudge";

interface ITimebasedTooltipsCountInSession {
    [key: string]: number;
}

export interface IAppNudgeContext {
    isClosed: boolean;
    timebasedTooltipsCountInSession: ITimebasedTooltipsCountInSession;

    onClose: () => void;
    onCloseChatIconTooltip: (key: string, value: number) => void;
}

export const AppNudgeContext = React.createContext<IAppNudgeContext | null>(null);

export const AppNudgeContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [isClosed, setIsClosed] = React.useState(appNudgeClosedForSession());
    const [timebasedTooltipsCountInSession, setTimebasedTooltipsCountInSession] = React.useState(
        {},
    );
    const onClose = React.useCallback(() => setIsClosed(true), []);
    const onCloseChatIconTooltip = React.useCallback(
        (key: string, value: number) => {
            return setTimebasedTooltipsCountInSession({
                ...timebasedTooltipsCountInSession,
                [key]: value,
            });
        },
        [timebasedTooltipsCountInSession],
    );

    const value = React.useMemo(
        () => ({ isClosed, onClose, timebasedTooltipsCountInSession, onCloseChatIconTooltip }),
        [isClosed, onClose, timebasedTooltipsCountInSession, onCloseChatIconTooltip],
    );

    return <AppNudgeContext.Provider value={value}>{children}</AppNudgeContext.Provider>;
};
