import { Product } from "@minis-consumer/interfaces/catalog";

export const DOT_SEPARATOR = "•";

export const getSelectedVariants = (values?: string[], separator = DOT_SEPARATOR): string => {
    if (!values) {
        return "";
    }
    return values.reduce((acc, curr, index) => {
        const canAddDotSeparator = values.length !== index + 1;
        acc = `${acc}${curr}`;
        return canAddDotSeparator ? `${acc} ${separator} ` : acc;
    }, "");
};

export const doesProductHaveVariants = (product: Product): boolean => {
    const variantOptions = product?.variantDetails?.options || [];
    return variantOptions.length > 0;
};
