import { useCallback } from "react";
import { Platform } from "react-native";
import { useController } from "@rest-hooks/react";

import { useOnReloadWeb } from "@minis-consumer/hooks/use-reload-web";
import { loadConfigInfoFromServer } from "@minis-consumer/includes/bootstrap";
import { useGetSession } from "@minis-consumer/hooks/use-get-session";

import { AuthService } from "../api/auth";
import { Session } from "../includes/session";
import { useAppDispatch } from "./use-app-dispatch";
import type { User as IUser } from "../interfaces/user";
import { useStoreInfo } from "./use-store";

const isWeb = Platform.OS === "web";

export const useLoginViaOtp = (): ((otp: string) => Promise<void>) => {
    const { resetEntireStore } = useController();
    const appDispatch = useAppDispatch();
    const store = useStoreInfo(false);
    const { onReload: resetWebState } = useOnReloadWeb();

    const { setConfigInfo } = useGetSession();
    return useCallback(
        async (otp: string) => {
            const response = await AuthService.verifyOtp(otp);
            const user: IUser = {
                ...response,
                id: response.customer_id,
            };

            if (response.token) {
                const session = Session.getInstance();

                session.userId = response.customer_id;
                session.token = response.token;
                session.userName = response.name;
            }

            if (!isWeb && Session.getInstance().tid) {
                loadConfigInfoFromServer(setConfigInfo);
            }

            appDispatch({
                type: "SET_USER_ACTION",
                payload: user,
            });

            resetEntireStore();

            //wait to set the redirect path, we need to reload the web
            setTimeout(() => {
                resetWebState(store?.slug);
            }, 1000);
        },
        [appDispatch, resetEntireStore, resetWebState, store?.slug],
    );
};
