import * as React from "react";
import { Theme } from "@react-navigation/native";
import { useTheme } from "@swiggy-private/rn-dls";

export const useNavigationTheme = (): Theme => {
    const { value: theme } = useTheme();

    return React.useMemo<Theme>(
        () => ({
            dark: false,
            colors: {
                primary: theme["color-primary"].toString(),
                background: theme["color-background-primary"].toString(),
                card: theme["color-surface-primary"].toString(),
                text: theme["color-basic-100"].toString(),
                border: theme["color-basic-5"].toString(),
                notification: theme["color-critical"].toString(),
            },
        }),
        [theme],
    );
};
