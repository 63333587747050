import React from "react";
import { Platform, StyleProp, StyleSheet, useWindowDimensions, ViewStyle } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { SpacingValue, Snackbar, Text } from "@swiggy-private/rn-dls";
import { Stack, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { SdkConversation } from "@swiggy-private/connect-chat-sdk";

import { Analytics } from "@minis-consumer/analytics";

import { getSnackbarText } from "./helpers";
import { ANALYTICS_EVENTS, SNACKBAR_TIMEOUT, TEST_IDS } from "./constants";

interface Props {
    canShowSnackbar: boolean;
    blockedInfo: SdkConversation["blockedInfo"];
    onPressClose: VoidFunction;

    extraStyle?: StyleProp<ViewStyle>;
}

const MAX_SNACKBAR_WIDTH = 740;
const ICON_SIZE = 20;

const ChatBlockedSnackbarComponent: React.FC<Props> = ({
    canShowSnackbar,
    blockedInfo,
    onPressClose,
    extraStyle,
}) => {
    const insets = useSafeAreaInsets();

    const { width: windowWidth } = useWindowDimensions();

    const snackbarText = React.useMemo(() => getSnackbarText(blockedInfo), [blockedInfo]);

    const snackbarStyle = useSelectScreen({
        lg: {
            marginBottom: SpacingValue["space-x-large"],
            maxWidth: MAX_SNACKBAR_WIDTH,
            left: 0,
            paddingVertical: SpacingValue["space-small"],
            marginLeft: (windowWidth - MAX_SNACKBAR_WIDTH) / 2,
        },
        default: {
            marginBottom:
                Platform.OS !== "web"
                    ? Math.max(insets.bottom, SpacingValue["space-x-large"])
                    : SpacingValue["space-x-large"],
            marginHorizontal: SpacingValue["space-medium"],
        },
    });

    React.useEffect(() => {
        if (!canShowSnackbar) {
            return;
        }

        Analytics.impressionEvent({
            category: ANALYTICS_EVENTS.MOUNT,
        });

        const t: NodeJS.Timer = setTimeout(() => onPressClose(), SNACKBAR_TIMEOUT);

        return () => clearTimeout(t as unknown as number);
    }, [canShowSnackbar, onPressClose]);

    if (!canShowSnackbar) {
        return null;
    }

    return (
        <Snackbar
            isAutoDismiss={false}
            style={[styles.snackbar, snackbarStyle, extraStyle]}
            isActive={canShowSnackbar}
            accessoryLeft={
                <SvgIcon
                    icon="InfoFilled"
                    color="color-positive"
                    height={ICON_SIZE}
                    width={ICON_SIZE}
                />
            }
            touchableRight={
                <SvgIcon
                    testID={TEST_IDS.CLOSE}
                    icon="CrossFilled"
                    color="color-basic-60"
                    height={ICON_SIZE}
                    width={ICON_SIZE}
                    onPress={onPressClose}
                />
            }>
            <Stack direction="row" testID={TEST_IDS.SNACKBAR_TEXT}>
                <Text category="b3" weight="medium" color="color-basic-0">
                    {snackbarText}
                </Text>
            </Stack>
        </Snackbar>
    );
};

export const ChatBlockedSnackbar = React.memo(ChatBlockedSnackbarComponent);

const styles = StyleSheet.create({
    snackbar: {
        borderRadius: 12,
        marginHorizontal: SpacingValue["space-x-large"],
    },
});
