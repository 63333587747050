export const ANALYTICS_COPIES = {
    CHAT_MSGS: "chat-msg",
    READ_MORE: "read-more",
    REVIEW_SUBMITTED_CLICK_IMAGE: "click-image",
    CHAT_CONVO: "chat-convo",
    CHAT_SEARCH: "search-in-chats",
    COPY_COUPON: "chat-copy-coupon",
};

export const COPIES = {
    TRACK_ORDER_STATUS_TEXT: "Track order status",
    VIEW_STORY: "View Story",
    COPY_COUPON: "Coupon code copied!",
    ORDER_DETAILS: "Order details",
};

export const DEFAULT_VALUES = {
    FOOTER_SIZE: 104,
};

export const TEMPLATE_MSG = `Hi, your request has been sent to {0} The seller will get back to the respective quote within 24-48 hours. You can chat with the seller here for additional queries`;

export const TRACK_ORDER_STATUSES: Array<string> = [
    "PENDING",
    "ACCEPTED",
    "DISPATCH_READY",
    "DELIVERED",
];
export const TRACK_REFUND_STATUSES: Array<string> = ["CANCELLED"];

export const TRACK_ORDER_TEXT = "Track order here";
export const TRACK_BOOKING_TEXT = "Track booking here";
export const TRACK_REFUND_TEXT = "Track refund here";
export const DOWNLOAD_NOW_TEXT = "Download Now";

export const PRODUCT_CTA_TEXT: Record<string, Record<string, string>> = {
    PHYSICAL: {
        TRACK_ORDER: TRACK_ORDER_TEXT,
        TRACK_REFUND: TRACK_REFUND_TEXT,
    },
    DIGITAL: {
        TRACK_ORDER: DOWNLOAD_NOW_TEXT,
        TRACK_REFUND: TRACK_REFUND_TEXT,
    },
    APPOINTMENT: {
        TRACK_ORDER: TRACK_BOOKING_TEXT,
        TRACK_REFUND: TRACK_REFUND_TEXT,
    },
    PLAN: {
        TRACK_ORDER: TRACK_ORDER_TEXT,
        TRACK_REFUND: TRACK_REFUND_TEXT,
    },
};
