import { IntroductionWidget } from "@minis-consumer/interfaces/widget";

export const STORE_BRAND_FALLBACK = "minis/brand_image_fallback";
export const SPOTLIGHT_BACKGROUND_FALLBACK = "minis/spotlight_fallback";
export const CATEGORY_BACKGROUND_FALLBACK = "minis/category_big_fallback";
export const STORE_BACKGROUND_FALLBACK = "minis/favourite_fallback";
export const PRODUCT_IMAGE_FALLBACK = "minis/product_img_fallback";

export const INTRODUCTION_WIDGET: IntroductionWidget = {
    id: "INTRODUCTION_WIDGET",
    type: "INTRODUCTION_WIDGET",
};
