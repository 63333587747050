import React, { useCallback } from "react";
import { GestureResponderEvent, Pressable, PressableProps } from "react-native";

import { HapticFeedbackTypes, HapticService } from "@swiggy-private/rn-services";

const options = {
    enableVibrateFallback: true,
    ignoreAndroidSystemSettings: false,
};

interface HapticPressableProps extends PressableProps {
    feedbackType?: HapticFeedbackTypes;
}

export const HapticPressable: React.FC<HapticPressableProps> = ({
    onPress,
    feedbackType = "impactMedium",
    ...props
}) => {
    const onPressWithHaptic = useCallback(
        (e: GestureResponderEvent) => {
            if (onPress) {
                HapticService.trigger(feedbackType, options);
                onPress(e);
            }
        },
        [feedbackType, onPress],
    );

    return <Pressable {...props} onPress={onPressWithHaptic} />;
};
