export type CouponType =
    | "DISCOUNT_TYPE_FLAT_OFF"
    | "DISCOUNT_TYPE_PERCENT_OFF"
    | "DISCOUNT_TYPE_FREE_DELIVERY";

export enum CouponDeliveryType {
    INTER = "INTER",
    INTRA = "INTRA",
}

export interface Coupon {
    type: CouponType;
    code: string;
    maxDiscountAmount: number;

    discountPercent?: number;
    minOrderValue?: number;
    active?: boolean;
    deliveryType?: CouponDeliveryType;
    segmentedOffer?: boolean;
}

export interface CartAppliedCoupon {
    code: string;
    message: string;
    discountAmount: number;

    applied?: boolean;
    segmentedOffer?: boolean;
}

export interface CartCoupon {
    coupon: Coupon;
    discountAmount: number;

    eligible?: boolean;
}

export interface CartCoupons {
    bestCoupons: Array<CartCoupon>;
    eligibleCoupons: Array<CartCoupon>;
    ineligibleCoupons: Array<CartCoupon>;
}

export interface CartCouponSectionCoupon extends Coupon {
    isEligible?: boolean;
}

export interface CartCouponSectionData {
    items: CartCouponSectionCoupon[];
}

export interface CartCouponSection {
    title: string;
    data: CartCouponSectionData[];
}

/** Coupon card data interface for storefront and PDP */
export interface CouponCardData {
    title: string;
    description: string;
    codePrefix: string;
    code: Coupon["code"];
}
