import React from "react";

import { useCartClearWithoutHardDelete } from "./use-cart";
import { useNavigationReset } from "./use-navigation-reset";
import { useStoreInfo, useStoreRefetch } from "./use-store";

export const useStoreReset = (storeId: string): (() => void) => {
    const clearCart = useCartClearWithoutHardDelete(storeId);

    const store = useStoreInfo(false);
    const refetchStore = useStoreRefetch(store?.slug ?? "");
    const navigationReset = useNavigationReset();

    const reset = React.useCallback(() => {
        clearCart();
        store?.slug && refetchStore();
        navigationReset();
    }, [store?.slug, clearCart, refetchStore, navigationReset]);

    return reset;
};
