import { ApiError, API_SUCCESS_STATUS_CODE } from "@swiggy-private/connect-api-core";
import { Endpoint } from "@swiggy-private/http-client";

import { getApiClient } from "../../includes/api";
import { CatalogViewEndpoint, ProductViewEndpoint } from "../endpoint";
import type { Product, ProductBadge, ProductCategory } from "../../interfaces/catalog";

export interface CatalogViewParams {
    storeSlug: string;
}

export interface CatalogViewResponse {
    categories: ProductCategory[];
    products: Product[];
    badges: ProductBadge[];
}

export const fetchCatalogView = async ({
    storeSlug,
}: CatalogViewParams): Promise<CatalogViewResponse> => {
    const endpoint = Endpoint.from(CatalogViewEndpoint, { storeSlug });
    const response = await getApiClient().get<CatalogViewResponse>({ endpoint });

    if (response.statusCode === API_SUCCESS_STATUS_CODE && response.data) {
        return response.data;
    }

    return Promise.reject(
        new ApiError(response.statusMessage || "Could not fetch catalog", response),
    );
};

export interface ProductViewResponse {
    categories: ProductCategory[];
    product: Product;
    badges: ProductBadge[];
}

export interface ProductViewParams {
    storeSlug: string;
    productId: string;
}

export const fetchProductView = async ({
    storeSlug,
    productId,
}: ProductViewParams): Promise<ProductViewResponse> => {
    const endpoint = Endpoint.from(ProductViewEndpoint, { storeSlug, productId });
    const response = await getApiClient().get<ProductViewResponse>({ endpoint });

    if (response.statusCode === API_SUCCESS_STATUS_CODE && response.data) {
        return response.data;
    }

    return Promise.reject(
        new ApiError(response.statusMessage || "Could not fetch product", response),
    );
};
