import React from "react";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useNavigation } from "@react-navigation/native";

import { useMount } from "@swiggy-private/react-hooks";

import { HomeRouteList } from "@minis-consumer/interfaces/route";
import { Cart } from "@minis-consumer/interfaces/cart";

type Props = {
    checkoutErrorMsg?: string;
    cart?: Cart["cartViewData"] | null;
};

type ReturnValue = {
    errorText: string;
    canShowSnackbar: boolean;
    dismissSnackbar: VoidFunction;
    triggerError: (msg: string) => void;
};

export const useCouponInvalidHandler = (props: Props | null): ReturnValue => {
    const navigation = useNavigation<NativeStackNavigationProp<HomeRouteList, "Cart">>();

    const couponRef = React.useRef<undefined | boolean>(false);

    const appliedCoupon = React.useMemo(
        () => props?.cart?.appliedCoupon,
        [props?.cart?.appliedCoupon],
    );

    const [errorText, setErrorText] = React.useState("");
    const [canShowSnackbar, setSnackbar] = React.useState(false);

    const triggerError = React.useCallback((msg: string) => {
        setSnackbar(true);
        setErrorText(msg);
    }, []);

    const dismissSnackbar = React.useCallback(() => {
        setSnackbar(false);
        setErrorText("");
    }, []);

    useMount(() => {
        if (!props?.checkoutErrorMsg?.trim().length) {
            return;
        }

        triggerError(props.checkoutErrorMsg);

        navigation.setParams({
            errorMessage: undefined,
        });
    });

    React.useEffect(() => {
        if (appliedCoupon?.applied === couponRef.current) {
            return;
        }

        if (appliedCoupon?.applied === false) {
            triggerError(appliedCoupon.message);
        }

        couponRef.current = appliedCoupon?.applied;
    }, [appliedCoupon?.applied, appliedCoupon?.message, triggerError]);

    return { errorText, canShowSnackbar, dismissSnackbar, triggerError };
};
