import { ProductCtaProps } from "@minis-consumer/components/product-cta";
import { CanAddOrBookParams } from "@minis-consumer/hooks/use-add-or-book-validation";
import { LocalCartItem } from "@minis-consumer/interfaces/cart";

import { unique } from "./array";

export interface Params extends Omit<CanAddOrBookParams, "storeId"> {
    cartItems: LocalCartItem[];
    ctaState?: ProductCtaProps["ctaState"];
}

export interface ReturnValue {
    canAdd: boolean;
    prefixText?: string;
}

export const getCanAddOrBookProduct = ({
    productId,
    price,
    discountedPrice,
    productType = "PHYSICAL",
    cartItems,
    ctaState,
}: Params): ReturnValue => {
    /** when cart is empty, any product can be added unless price is required
     * OR
     * there is a physical product without default variant price on SF
     */
    if (!cartItems?.length) {
        const productTypeWithPrice = productType === "PHYSICAL" || productType === "PLAN";
        const isShowVariantsCta = ctaState === "SHOW_VARIANTS";

        if (productTypeWithPrice && !price && !discountedPrice) {
            return {
                canAdd: isShowVariantsCta ? true : false,
                prefixText: isShowVariantsCta ? undefined : COPIES.PRICE_ERROR,
            };
        }

        return { canAdd: true };
    }

    /** If custom product(s) is/are present, do not allow more products to be added */
    const customProducts = cartItems?.filter((item) => item.custom);
    if (customProducts.length) {
        return {
            canAdd: false,
            prefixText: COPIES.CUSTOM_CART_PRESENT,
        };
    }

    /** when cart has items */
    const productIds = cartItems?.map((item) => item.id);
    const isProductAdded = productIds.indexOf(productId) > -1;

    /** when same product is already added, only physical product can have quantity > 1 */
    if (isProductAdded) {
        if (productType === "PHYSICAL") {
            return { canAdd: true };
        }

        /** this case probably won't occur, written for precaution */
        return { canAdd: false, prefixText: COPIES.SINGLE_QUANTITY };
    }

    /** when different product is added, only 1 appointment can be present whereas other types of products can be added */
    if (productType === "APPOINTMENT") {
        return { canAdd: false, prefixText: COPIES.SINGLE_SERVICE_ONLY };
    }

    /** check which all product types have been added */
    const productTypes = unique(cartItems.map((item) => item.productType ?? "PHYSICAL"));
    if (productTypes.length > 1) {
        return { canAdd: false, prefixText: COPIES.SINGLE_SERVICE_ONLY };
    }

    /** A cart can have multiple products of same type for some product types */
    const canAddProduct = productTypes[0] === productType;
    return {
        canAdd: canAddProduct,
        prefixText: !canAddProduct ? COPIES.SINGLE_SERVICE_ONLY : "",
    };
};

const COPIES = {
    SINGLE_SERVICE_ONLY:
        "The item cannot be clubbed with items already in your cart. You will need to clear existing cart to add this item.",
    PRICE_ERROR: "You can't add these items without price",
    CUSTOM_CART_PRESENT: "Custom products are present in cart, you can't add more products",
    SINGLE_QUANTITY: "You can add this service only once.",
};
