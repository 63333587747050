import { Endpoint } from "@rest-hooks/endpoint";

import {
    fetchScheduledMeetingSlots,
    fetchVacationDays,
} from "@minis-consumer/api/product/schedule-management";

export const FetchVacationDays = new Endpoint(fetchVacationDays, {
    key: ({ storeId }) => `appointment-vacation-days/${storeId}`,
});

export const FetchScheduledMeetingSlots = new Endpoint(fetchScheduledMeetingSlots, {
    key: ({ storeId }) => `scheduled-meeting-slots/${storeId}`,
});
