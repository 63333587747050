import * as React from "react";

import { useStoryEnquiryModalHandler } from "@minis-consumer/hooks/use-view-story-modal-handler";
import { StoryEnquiryDialog } from "@minis-consumer/components/enquiry-modal/types/story-modal";

import { ViewStoryMessagePayload } from "@swiggy-private/connect-chat-commons";

export const StoryEnquiryProvider: React.FC = () => {
    const [isOpen, data, handler, { setIsMessageSent }] = useStoryEnquiryModalHandler();

    const onCloseModal = React.useCallback(() => {
        handler(false, {} as Omit<ViewStoryMessagePayload, "text">);
    }, [handler]);

    return (
        <StoryEnquiryDialog
            showModal={isOpen}
            closeModal={onCloseModal}
            imageUrl={data.imageUrl}
            storeId={data.storeId}
            storyId={data.storyData}
            highlightName={data.highlightName}
            setIsMessageSent={setIsMessageSent}
        />
    );
};
