import * as React from "react";

import { SubmittedReviewMessage } from "@swiggy-private/connect-business-commons";
import { SubmittedReviewMessage as ISubmittedReviewMessage } from "@swiggy-private/connect-chat-commons";
import { InView } from "@swiggy-private/react-native-ui";

import { Analytics } from "@minis-consumer/analytics";

import { ANALYTICS_COPIES } from "../../constants";

interface SubmittedReviewMsgProps {
    message: ISubmittedReviewMessage;
    conversationId: string;
}

const SubmittedReviewMsgComponent: React.FC<SubmittedReviewMsgProps> = ({
    message: { payload: data, type },
    conversationId,
}) => {
    const { orderId, storeId, description, value, imageIds } = data;

    const analyticsContextData = JSON.stringify({
        orderId,
        storeId,
        messageType: type,
        conversationId,
    });
    const handleReadMoreClick = React.useCallback(
        (expanded: boolean) => {
            if (!expanded) {
                return;
            }

            Analytics.clickEvent({
                category: ANALYTICS_COPIES.READ_MORE,
                context: analyticsContextData,
            });
        },
        [analyticsContextData],
    );

    const onSubmittedReviewMsgImpression = React.useCallback(
        (isVisible: boolean) => {
            isVisible &&
                Analytics.impressionEvent({
                    category: ANALYTICS_COPIES.CHAT_MSGS,
                    context: analyticsContextData,
                });
        },
        [analyticsContextData],
    );

    const onImageFullScreenView = React.useCallback(() => {
        Analytics.clickEvent({
            category: ANALYTICS_COPIES.REVIEW_SUBMITTED_CLICK_IMAGE,
            context: analyticsContextData,
        });
    }, [analyticsContextData]);

    return (
        <InView onChange={onSubmittedReviewMsgImpression}>
            <SubmittedReviewMessage
                text={description}
                rating={value}
                imageId={imageIds?.[0]}
                handleReadMoreClick={handleReadMoreClick}
                onImageFullScreenView={onImageFullScreenView}
            />
        </InView>
    );
};

export const SubmittedReviewMsg = React.memo(SubmittedReviewMsgComponent);
