import * as React from "react";
import { Keyboard, Platform, StyleSheet, ScrollView } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useIsFocused } from "@react-navigation/native";
import { useController } from "@rest-hooks/react";

import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { Emoji } from "@swiggy-private/react-native-ui";
import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { Snackbar, SpacingValue, Text } from "@swiggy-private/rn-dls";
import { UploadedMediaFile } from "@swiggy-private/rn-image-picker";

import { Analytics } from "@minis-consumer/analytics";
import { useOrderRating } from "@minis-consumer/hooks/use-rating-order";
import { Logger } from "@minis-consumer/includes/logger";
import { OrderDetail, OrderList } from "@minis-consumer/resources/order";
import { useIsDesktopWeb } from "@minis-consumer/hooks/use-dweb";

import { KeyboardAwareView } from "../keyboard-aware-view";
import { FormBody } from "./components/form-body";
import { FormFooter } from "./components/form-footer";
import { ANALYTICS_COPIES, COPIES, DEFAULT_VALUES } from "./constants";
import { getImageUrls } from "./helper";

interface FormBodyProps {
    orderId: string;
    formSubmit: VoidFunction;

    defaultRating?: number;
    storeId?: string;

    orderKey?: string;
}

const keyboardAwareViewBehaviour = Platform.OS === "ios" ? "padding" : undefined;

const RatingAndReviewFormComponent: React.FC<FormBodyProps> = ({
    defaultRating = 0,
    orderId,
    orderKey,
    storeId,
    formSubmit,
}) => {
    const isDWeb = useIsDesktopWeb();
    const [ratingCount, setRatingCount] = React.useState(defaultRating);
    const [review, setReview] = React.useState("");
    const [isSaving, setSaving] = React.useState(false);
    const [showSnackbar, setShowSnackbar] = React.useState(false);
    const [uploadedMedia, setUploadedMedia] = React.useState<UploadedMediaFile[]>([]);
    const insets = useSafeAreaInsets();
    const orderRating = useOrderRating();

    const isFocused = useIsFocused();
    const { invalidate, invalidateAll } = useController();

    const analyticsContextData = React.useMemo(() => {
        return {
            orderId,
            storeId,
        };
    }, [orderId, storeId]);

    const reviewSubmitAnalyticsContextData = React.useMemo(() => {
        return JSON.stringify({
            ...analyticsContextData,
            ratingStars: ratingCount,
            feedback: review,
            isImageUploaded: !!uploadedMedia.length,
        });
    }, [analyticsContextData, ratingCount, review, uploadedMedia.length]);

    const snackbarStyle = React.useMemo(() => {
        return {
            bottom: Math.max(
                insets.bottom +
                    DEFAULT_VALUES.PRIMARY_BTN_HEIGHT +
                    2 * SpacingValue["space-medium"],
                SpacingValue["space-x-large"],
            ),
        };
    }, [insets.bottom]);

    const sendReviewSubmitClickEvent = React.useCallback(() => {
        Analytics.clickEvent({
            category: ANALYTICS_COPIES.SUBMIT_REVIW_BTN,
            context: reviewSubmitAnalyticsContextData,
        });
    }, [reviewSubmitAnalyticsContextData]);

    const handleReviewAndRatingSubmit = React.useCallback(async () => {
        Keyboard.dismiss();
        setSaving(true);
        const apiData = {
            orderId,
            value: ratingCount,
            description: review,
            imageIds: getImageUrls(uploadedMedia),
        };

        sendReviewSubmitClickEvent();

        try {
            await orderRating(apiData);
            setSaving(false);

            /** TODO: We shall refactor this in future to try and reduce the number of API calls */
            invalidate(OrderDetail, orderId, orderKey);
            invalidateAll(OrderList);

            formSubmit();
        } catch (err) {
            Logger.recordError(err);
            setShowSnackbar(true);
            setSaving(false);
        }
    }, [
        orderId,
        ratingCount,
        review,
        uploadedMedia,
        sendReviewSubmitClickEvent,
        orderRating,
        invalidate,
        invalidateAll,
        orderKey,
        formSubmit,
    ]);

    const handleStarRatingChange = React.useCallback(
        (rating: number) => {
            setRatingCount(rating);
            Analytics.clickEvent({
                category: ANALYTICS_COPIES.RATING_STAR,
                context: JSON.stringify({
                    orderId,
                    ratingStars: rating,
                    storeId,
                }),
            });
        },
        [orderId, storeId],
    );

    const handleInputBlur = React.useCallback(() => {
        Analytics.clickEvent({
            category: ANALYTICS_COPIES.RATING_FEEDBACK,
            context: JSON.stringify({
                ...analyticsContextData,
            }),
        });
    }, [analyticsContextData]);

    const handleImageUpload = React.useCallback(() => {
        Analytics.clickEvent({
            category: ANALYTICS_COPIES.ADD_PHOTO,
            context: JSON.stringify({
                ...analyticsContextData,
            }),
        });
    }, [analyticsContextData]);

    const onFooterMount = React.useCallback(() => {
        Analytics.impressionEvent({
            category: ANALYTICS_COPIES.SUBMIT_REVIW_BTN,
            context: JSON.stringify({
                ...analyticsContextData,
            }),
        });
    }, [analyticsContextData]);

    return (
        <Stack direction="column" justifyContent="space-between" flex={1}>
            <KeyboardAwareView enabled={isFocused} behavior={keyboardAwareViewBehaviour}>
                {isDWeb ? (
                    <FormBody
                        ratingCount={ratingCount}
                        handleRatingChange={handleStarRatingChange}
                        review={review}
                        handleReviewChange={setReview}
                        uploadedMedia={uploadedMedia}
                        setUploadedMedia={setUploadedMedia}
                        handleInputBlur={handleInputBlur}
                        handleImageUpload={handleImageUpload}
                    />
                ) : (
                    <ScrollView
                        contentContainerStyle={styles.scrollContentContainer}
                        keyboardShouldPersistTaps="handled">
                        <FormBody
                            ratingCount={ratingCount}
                            handleRatingChange={handleStarRatingChange}
                            review={review}
                            handleReviewChange={setReview}
                            uploadedMedia={uploadedMedia}
                            setUploadedMedia={setUploadedMedia}
                            handleInputBlur={handleInputBlur}
                            handleImageUpload={handleImageUpload}
                        />
                    </ScrollView>
                )}
                {showSnackbar ? (
                    <Snackbar
                        style={[styles.snackbar, snackbarStyle]}
                        isActive={showSnackbar}
                        accessoryLeft={
                            <SvgIcon
                                icon="InfoFilled"
                                color="color-critical"
                                height={DEFAULT_VALUES.ERROR_ICON_SIZE}
                                width={DEFAULT_VALUES.ERROR_ICON_SIZE}
                            />
                        }>
                        <Stack direction="row">
                            <Text>
                                <Text category="b3" weight="medium" color="color-basic-0">
                                    {COPIES.SNACKBAR_FAILURE_TEXT_PREFIX}
                                </Text>
                                {/* TODO: Update emoji, match with design */}
                                <Emoji id="sad_emoji" height={16} width={16} style={styles.emoji} />
                                <Text category="b3" weight="medium" color="color-basic-0">
                                    {COPIES.SNACKBAR_FAILURE_TEXT_SUFFIX}
                                </Text>
                            </Text>
                        </Stack>
                    </Snackbar>
                ) : null}
                <FormFooter
                    onSubmit={handleReviewAndRatingSubmit}
                    isSaving={isSaving}
                    onMountCallback={onFooterMount}
                />
            </KeyboardAwareView>
        </Stack>
    );
};

export const RatingAndReviewForm = React.memo(RatingAndReviewFormComponent);

const styles = StyleSheet.create({
    snackbar: {
        borderRadius: 12,
        marginHorizontal: SpacingValue["space-x-large"],
    },
    emoji: {
        marginBottom: -1 * SpacingValue["space-xxx-small"],
    },
    scrollContentContainer: {
        flexGrow: 1,
        padding: SpacingValue["space-medium"],
    },
});
