import { Icon } from "@swiggy-private/connect-svg-icons";

import { OrderItem, OrderStatus } from "@minis-consumer/interfaces/order";

import { getSelectedVariants } from "./variants";

export const getSvgIcon = (trackStatus: OrderStatus | "DORMANT"): Icon => {
    switch (trackStatus) {
        case "CANCELLED_RTO":
        case "CANCELLED_RTO_DELIVERED":
        case "CANCELLED_DISPATCH":
            return "Rotate";

        case "CANCELLED_AUTO":
        case "CANCELLED_CC":
        case "CANCELLED_SELLER":
        case "CANCELLED_USER":
            return "SadSmiley";

        default:
            return "InfoFilled";
    }
};

export const getOrderItemsTitle = (items: OrderItem[], withVariants = false): string => {
    const totalItems = items?.length;
    if (!items?.length) {
        return "";
    }

    let firstItemTitle = items[0].item?.name;

    if (!firstItemTitle) {
        return "";
    }

    if (withVariants) {
        const firstItemVariants = items[0].item?.variantOptionValues;
        if (firstItemVariants) {
            firstItemTitle = `${firstItemTitle} (${getSelectedVariants(firstItemVariants)})`;
        }
    }

    return totalItems === 1 ? firstItemTitle : `${firstItemTitle} and ${totalItems - 1} more`;
};
