import Cookies from "js-cookie";

import type { ICookieStorage } from "./cookie-storage";
import {
    MINIS_DOMAIN_REGEX,
    MINIS_DOMAIN,
    MINIS_SITE_DOMAIN_REGEX,
    MINIS_SITE_DOMAIN,
} from "@minis-consumer/constants";

const getStoreDomain = (hostname: string): string | undefined => {
    if (MINIS_DOMAIN_REGEX.test(hostname)) {
        return MINIS_DOMAIN;
    }

    if (MINIS_SITE_DOMAIN_REGEX.test(hostname)) {
        return MINIS_SITE_DOMAIN;
    }

    return undefined;
};

const cookieDomain =
    typeof window !== "undefined" && window?.location?.hostname
        ? getStoreDomain(window.location.hostname)
        : undefined;

const cookieOptions = { secure: __DEV__ ? false : true, domain: cookieDomain, expires: 31 };

export const CookieStorage: ICookieStorage = {
    getValue: async function (key: string): Promise<string | null> {
        return Cookies.get(key) || null;
    },

    getObjectValue: async function <T>(key: string): Promise<T | null> {
        try {
            const jsonValue = Cookies.get(key);
            return jsonValue != null ? JSON.parse(jsonValue) : null;
        } catch (e) {}

        return null;
    },

    setValue: async function <T>(key: string, value: T): Promise<void> {
        Cookies.set(key, typeof value === "string" ? value : JSON.stringify(value), cookieOptions);
    },
};
