import { Platform } from "react-native";
import { nanoid } from "nanoid/non-secure";

import { ApiClient, ApiResponse, API_UNAUTHORIZED_CODE } from "@swiggy-private/connect-api-core";
import {
    HttpResponseInterceptor,
    HttpRequestInterceptor,
    HttpCallParams,
    HttpClient,
} from "@swiggy-private/http-client";
import { checkIsApiBlacklistedForLogging } from "@swiggy-private/common-helpers";

import { getCurrentLocation } from "@minis-consumer/contexts/location-context";
import { isServer } from "@minis-consumer/helpers/server";
import { Analytics } from "@minis-consumer/analytics";
import { BLACKLISTED_API_ENDPOINTS, MINIS_VERSION_CODE } from "@minis-consumer/constants";
import { AppEvent, AppEventTypes } from "@minis-consumer/includes/app-event";
import { ANALYTICS_URL } from "@env";

import { Device } from "./device";
import { Session } from "./session";
import { Logger } from "./logger";

const metricsRequestTimeMap: Record<string, number> = {};

const metricsRequestInterceptor: HttpRequestInterceptor = (
    params: HttpCallParams,
): HttpCallParams => {
    const { headers = {} } = params;
    const requestId = headers["request-id"];

    if (requestId) {
        metricsRequestTimeMap[requestId] = Date.now();
    }

    return params;
};

const sessionResponseInterceptor: HttpResponseInterceptor<ApiResponse<unknown>> = (response) => {
    if (isServer()) {
        return response;
    }

    if (response.tid) {
        Session.getInstance().tid = response.tid;
    }

    if (response.sid) {
        Session.getInstance().sid = response.sid;
    }

    return response;
};

const unauthorizedResponseInterceptor: HttpResponseInterceptor<ApiResponse<unknown>> = (
    response,
) => {
    if (response.statusCode === API_UNAUTHORIZED_CODE) {
        AppEvent.dispatch(AppEventTypes.LOGOUT);

        if (Platform.OS === "web" && typeof window !== "undefined") {
            window.location.href = "/";
        }
    }
    return response;
};

const headersRequestInterceptor: HttpRequestInterceptor = (params) => {
    if (isServer()) {
        return {
            ...params,
            headers: {
                "request-id": nanoid(),
                ...params.headers,
            },
        };
    }

    const session = Session.getInstance();
    const device = Device.getInstance();
    const location = getCurrentLocation();

    return {
        ...params,

        headers: {
            tid: session.tid || "",
            sid: session.sid || "",
            token: session.token || "",

            latitude: String(location?.lat || ""),
            longitude: String(location?.lng || ""),

            platform: Platform.OS,
            deviceId: device.deviceId || "",
            swuid: device.deviceId || "",

            "app-version": device.appVersion || "",
            "version-code": device.versionCode || "",
            "request-id": nanoid(),

            // manually increment the version when needed
            "x-minis-version": String(MINIS_VERSION_CODE),

            ...params.headers,
        },
    };
};

const logInterceptor: HttpRequestInterceptor = (params) => {
    if (Platform.OS !== "web" && __DEV__) {
        console.debug(
            "[API] " +
                params.endpoint +
                (params.queryParams ? `?${new URLSearchParams(params.queryParams)}` : "") +
                " \n" +
                JSON.stringify(params.headers ?? {}, null, 4),
        );
    }

    return params;
};

const metricsResponseInterceptor: HttpResponseInterceptor<ApiResponse<unknown>> = (
    response,
    request,
    httpResponse,
) => {
    try {
        const { endpoint, headers = {}, queryParams = {} } = request;
        const requestId = headers["request-id"] ?? "-";
        const xMinisVersion = headers["x-minis-version"] ?? "-";

        if (endpoint.getUri().indexOf(ANALYTICS_URL) !== -1) {
            return response;
        }

        const responseTime =
            metricsRequestTimeMap[requestId] != null
                ? Date.now() - metricsRequestTimeMap[requestId]
                : "-";

        const context = JSON.stringify({
            requestId,
            xMinisVersion,
            uriParams: endpoint.getUriParams(),
            endpoint: endpoint.toString(),
            method: request.options?.method || "",
            queryParams,
            responseTime,
            statusCode: httpResponse.status,
            url: httpResponse.url,
        });

        if (checkIsApiBlacklistedForLogging(endpoint.toString(), BLACKLISTED_API_ENDPOINTS)) {
            return response;
        }

        Analytics.customEvent({
            category: "metrics",
            action: "api",
            label: endpoint.getUri() || "-",
            value: response.statusCode ?? 9999,
            context,
        });
    } catch (err) {
        Logger.recordError(err);
    }

    return response;
};

HttpClient.globalRequestInterceptors = [logInterceptor, metricsRequestInterceptor];

HttpClient.globalResponseInterceptors = [
    metricsResponseInterceptor,
    sessionResponseInterceptor,
    unauthorizedResponseInterceptor,
];

let _apiClient: ApiClient | null = null;

// init api client
export const initApiClient = (): ApiClient => {
    if (_apiClient != null) {
        return _apiClient;
    }

    _apiClient = new ApiClient({
        requestInterceptors: [headersRequestInterceptor],
    });

    return _apiClient;
};

export const getApiClient = (): ApiClient => {
    if (_apiClient == null) {
        return initApiClient();
    }

    return _apiClient;
};
