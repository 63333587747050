import React from "react";
import { Animated, StyleSheet, Pressable, InteractionManager } from "react-native";
import LinearGradient from "react-native-linear-gradient";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { Text, SpacingValue, useTheme } from "@swiggy-private/rn-dls";
import { Box } from "@swiggy-private/rn-adaptive-layout";
import { StoreMedia } from "@swiggy-private/react-native-ui";

import { RouteList } from "@minis-consumer/interfaces/route";
import { useChatStoreUnreadCount } from "@minis-consumer/hooks/use-chat-unread-count";
import { Badge } from "@minis-consumer/components/badge";
import { TEST_IDS_COMMON } from "@minis-consumer/constants/test-ids/common";
import { Analytics } from "@minis-consumer/analytics";
import { useLocalCart } from "@minis-consumer/hooks/use-cart";
import { TAB_MIN_HEIGHT } from "@minis-consumer/components/cart-floating-tab";
import { useStoreDeliveryInfoSnackbarHandler } from "@minis-consumer/hooks/use-store-delivery-info-snack-handler";
import { getMediaUrl } from "@minis-consumer/helpers/media";

import { STORE_BACKGROUND_FALLBACK } from "../widgets/constants";

const ANALYTICS_EVENT_NAME = "chat-fba";
const ANIMATION_DURATION = 300;
const GRADIENT_LOCATIONS_START = { x: 0, y: 1 };
const GRADIENT_LOCATIONS_END = { x: 1, y: 1 };
const SNACKBAR_OFFSET = 48;
const PRODUCT_IMAGE_SIZE = 34;

const FloatingChatComponent: React.FC<{
    storeId: string;
    storeImage?: string;
    disableSnackBarOffset?: boolean;
    disableCartBarOffset?: boolean;
}> = ({ storeId, storeImage, disableSnackBarOffset, disableCartBarOffset }) => {
    const { value: theme } = useTheme();
    const navigation = useNavigation<NativeStackNavigationProp<RouteList>>();
    const chatUnreadCount = useChatStoreUnreadCount(storeId);
    const cartView = useLocalCart(storeId);
    const { isOpen: isSnackBarOpen } = useStoreDeliveryInfoSnackbarHandler();

    const cartItemsTotal = cartView.items.reduce((sum, item) => sum + item.quantity, 0) || 0;

    const insets = useSafeAreaInsets();
    const defaultYCordinate = Math.max(insets.bottom, SpacingValue["space-medium"]);
    const GRADIENT_COLORS = [
        theme["color-basic-100"].toString(),
        theme["color-basic-75"].toString(),
    ];

    const animatedValue = React.useRef(
        new Animated.ValueXY({ x: 0, y: 2 * defaultYCordinate }),
    ).current;

    const analyticsContext = React.useMemo(() => {
        return {
            category: ANALYTICS_EVENT_NAME,
            context: `chatUnreadCount: ${chatUnreadCount}`,
        };
    }, [chatUnreadCount]);

    const onChatPress = React.useCallback((): void => {
        Analytics.clickEvent(analyticsContext);

        navigation.navigate("ChatConversation", { storeId: storeId });
    }, [navigation, storeId, analyticsContext]);

    React.useEffect(() => {
        Number(chatUnreadCount) && Analytics.impressionEvent(analyticsContext);
    }, [chatUnreadCount, analyticsContext]);

    React.useEffect(() => {
        if (cartItemsTotal === undefined) {
            return;
        }

        const task = InteractionManager.runAfterInteractions(() => {
            let yCordinate =
                !disableCartBarOffset && cartItemsTotal
                    ? TAB_MIN_HEIGHT + SpacingValue["space-x-small"] + defaultYCordinate
                    : defaultYCordinate;

            yCordinate =
                !disableSnackBarOffset && isSnackBarOpen
                    ? yCordinate + SNACKBAR_OFFSET
                    : yCordinate;

            Animated.timing(animatedValue, {
                toValue: { x: 0, y: -yCordinate },
                duration: ANIMATION_DURATION,
                useNativeDriver: false,
            }).start();
        });

        return () => {
            task.cancel();
        };
    }, [cartItemsTotal, animatedValue, defaultYCordinate, isSnackBarOpen, disableSnackBarOffset]);

    const imageUrl = getMediaUrl(
        storeImage ?? STORE_BACKGROUND_FALLBACK,
        { width: PRODUCT_IMAGE_SIZE, height: PRODUCT_IMAGE_SIZE },
        true,
    );

    return (
        <Animated.View style={animatedValue.getLayout()}>
            <Box style={styles.container}>
                <Badge count={chatUnreadCount} style={styles.badgeCount} />
                <LinearGradient
                    colors={GRADIENT_COLORS}
                    start={GRADIENT_LOCATIONS_START}
                    end={GRADIENT_LOCATIONS_END}
                    locations={[0, 1]}
                    angle={105}
                    style={styles.wrapper}>
                    <Pressable onPress={onChatPress} testID={TEST_IDS_COMMON.CHAT_FLOATING_CTA}>
                        <Box
                            style={styles.content}
                            direction="row"
                            alignItems="center"
                            gap={6}
                            ph={SpacingValue["space-xx-small"]}>
                            <Box style={styles.image}>
                                <StoreMedia
                                    isImageKitEnabled
                                    mediaId={imageUrl}
                                    style={styles.image}
                                    resizeMode="cover"
                                />
                                {chatUnreadCount === 0 ? <Box style={styles.online} /> : null}
                            </Box>
                            <Box direction="column">
                                <Text color="color-basic-0" weight="bold" category="b4">
                                    Contact Us
                                </Text>
                            </Box>
                        </Box>
                    </Pressable>
                </LinearGradient>
            </Box>
        </Animated.View>
    );
};

export const FloatingChat = React.memo(FloatingChatComponent);

const styles = StyleSheet.create({
    container: {
        paddingVertical: SpacingValue["space-small"] / 2,
        position: "absolute",
        right: SpacingValue["space-medium"],
        bottom: 0,
        zIndex: 0,
    },
    wrapper: {
        width: 120,
        height: 44,
        borderRadius: 100,
        borderStyle: "solid",
        display: "flex",
        justifyContent: "center",
    },
    content: {
        height: "100%",
    },
    badgeCount: {
        position: "absolute",
        top: SpacingValue["space-xx-small"],
        backgroundColor: "#C84168",
        left: -SpacingValue["space-xx-small"],
        zIndex: 1,
        right: "auto",
    },
    image: {
        height: 34,
        width: 34,
        backgroundColor: "#ccc",
        borderRadius: 36,
    },
    online: {
        height: 13,
        width: 13,
        borderRadius: 13,
        backgroundColor: "#4AA66A",
        right: -4,
        position: "absolute",
        zIndex: 1,
        borderWidth: 2,
        borderColor: "#3E225A",
    },
});
