import { useCallback, useRef } from "react";

import { Counter } from "@minis-consumer/includes/counter";

export const useShowCounter = (key: string, count = 1): [boolean, () => void] => {
    const counter = Counter.getInstance();
    const show = useRef(counter.get(key) < count).current;

    const incrementCounter = useCallback(() => {
        counter.set(key);
    }, [counter, key]);

    return [show, incrementCounter];
};
