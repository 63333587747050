import React from "react";
import { DeactivateContextOnBlur } from "@swiggy-private/react-native-ui";

import { AppStateContext } from "../contexts/app-context";
import { CartStateContext } from "../contexts/cart-context";

export const StateContextContainer: React.FC<React.PropsWithChildren> = ({ children }) => {
    return (
        <DeactivateContextOnBlur Context={AppStateContext}>
            <DeactivateContextOnBlur Context={CartStateContext}>{children}</DeactivateContextOnBlur>
        </DeactivateContextOnBlur>
    );
};
