import { MENU_ITEM_IDS, MenuItem } from "@swiggy-private/connect-chat-ui";

import { ANALYTICS_COPIES, MENU_COPIES } from "../components/header/constants";

export const getMenuItems = (isBlocked?: boolean): MenuItem[] => {
    return [
        {
            id: MENU_ITEM_IDS.BLOCK,
            text: isBlocked ? MENU_COPIES.unblock : MENU_COPIES.block,
            icon: "Block",
            textProps: {
                color: "color-critical-400",
            },
            iconProps: {
                color: "color-critical-400",
            },
            analyticsEventName: isBlocked
                ? ANALYTICS_COPIES.UNBLOCK_CHAT_CTA
                : ANALYTICS_COPIES.BLOCK_CHAT_CTA,
        },
    ];
};
