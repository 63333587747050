import { Dispatch, useContext } from "react";

import { AppDispatchContext } from "../contexts/app-context";
import { AppAction } from "../reducers/app-reducer/actions";

export function useAppDispatch(): Dispatch<AppAction> {
    const ctx = useContext(AppDispatchContext);
    if (!ctx) {
        throw new Error("Missing app dispatch context");
    }

    return ctx;
}
