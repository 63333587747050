import React, { useCallback, useMemo } from "react";
import { StyleSheet, Pressable } from "react-native";

import { Stack, Box, useScreenSize, ScreenSize } from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { Text, SpacingValue, useTheme, ElevationValue, shadow } from "@swiggy-private/rn-dls";
import { DashBorderLine } from "@swiggy-private/react-native-ui";
import { useMount } from "@swiggy-private/react-hooks";

import { useAuthHandler } from "@minis-consumer/hooks/use-auth-modal-handler";
import { useCartViewData } from "@minis-consumer/hooks/use-cart";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { Analytics } from "@minis-consumer/analytics";

const DISPLAY_COPIES = {
    TURBO_CHECKOUT: "Turbo Checkout",
    TWO_TIMES_FASTER: "2 times faster",
    LOGIN_WITH_SWIGGY: "Login with Swiggy",
};

export const LoginWithSwiggy: React.FC = () => {
    const storeInfo = useStoreInfo();
    const cart = useCartViewData(storeInfo.storeId);

    const { value: theme } = useTheme();
    const screenSize = useScreenSize();

    const shadowDefault = shadow(1, 0.1, "#FF5200");
    const shadowStyles = {
        ...shadowDefault,
        shadowRadius: 28,
        shadowOffset: { height: SpacingValue["space-x-large"], width: SpacingValue["space-small"] },
    };

    const isLargeScreen = React.useMemo(() => screenSize === ScreenSize.Large, [screenSize]);

    const containerStyles = {
        backgroundColor: theme["color-basic-0"],
        padding: isLargeScreen ? SpacingValue["space-x-large"] : SpacingValue["space-medium"],
        borderRadius: SpacingValue["space-medium"],
        borderWidth: 1,
        borderColor: theme["color-basic-15"],
    };

    const loginButtonTheme = useMemo(
        () => ({
            color: theme["color-basic-0"],
            backgroundColor: "#FF5200",
        }),
        [theme],
    );

    const loginButtonStyles = isLargeScreen
        ? [styles.loginButton, { minWidth: 310, ...ElevationValue[3] }]
        : [styles.loginButton, { marginTop: SpacingValue["space-medium"] }];

    const analyticsData = useMemo(() => {
        return {
            category: "login-with-swiggy",
            context: JSON.stringify({
                cartId: cart?.id,
            }),
        };
    }, [cart?.id]);

    const [, setIsAuthOpen] = useAuthHandler();

    const handleLoginCtaPress = useCallback(() => {
        Analytics.clickEvent(analyticsData);

        setIsAuthOpen(true, { redirectPath: "cart" });
    }, [setIsAuthOpen, analyticsData]);

    useMount(() => {
        Analytics.impressionEvent(analyticsData);
    });

    return (
        <Box
            direction={isLargeScreen ? "row" : "column"}
            style={containerStyles}
            justifyContent={isLargeScreen ? "space-between" : "center"}>
            <Stack
                direction={isLargeScreen ? "column" : "row"}
                justifyContent={isLargeScreen ? "center" : "space-between"}
                alignItems="center"
                spacing={isLargeScreen ? SpacingValue["space-xx-small"] : 0}>
                <Text category="b2" weight="bold" color="color-secondary">
                    {DISPLAY_COPIES.TURBO_CHECKOUT}
                </Text>

                {!isLargeScreen ? (
                    <DashBorderLine
                        borderColor={theme["color-secondary-400"]}
                        type="solid"
                        style={styles.separator}
                    />
                ) : null}

                <Stack direction="row" alignItems="center" spacing={SpacingValue["space-xx-small"]}>
                    <SvgIcon icon="BoltFilled" width={16} height={16} color="#FF5200" />
                    <Text category="b3" weight="medium" color="low">
                        {DISPLAY_COPIES.TWO_TIMES_FASTER}
                    </Text>
                </Stack>
            </Stack>

            <Pressable
                style={[loginButtonTheme, loginButtonStyles, shadowStyles]}
                onPress={handleLoginCtaPress}>
                <SvgIcon icon="Swiggy" width={18} height={24} color="color-basic-0" />
                <Text category="btn2" weight="bold" color="color-basic-0" style={styles.loginText}>
                    {DISPLAY_COPIES.LOGIN_WITH_SWIGGY}
                </Text>
            </Pressable>
        </Box>
    );
};

const styles = StyleSheet.create({
    loginButton: {
        borderRadius: SpacingValue["space-small"],
        padding: SpacingValue["space-medium"],
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        height: 52,
    },
    separator: {
        width: "30%",
        marginTop: SpacingValue["space-x-small"],
        opacity: 0.1,
    },
    loginText: {
        paddingLeft: SpacingValue["space-x-small"],
    },
});
