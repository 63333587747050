import * as React from "react";
import type { SwiggyHeaders } from "@minis-consumer/interfaces/swiggy";

export interface ILocationContext {
    lat: string | number;
    lng: string | number;
    name?: string;
}

export const LocationContext = React.createContext<ILocationContext | null>(null);

interface LocationContextProviderProps {
    ctx?: ILocationContext;
    swiggyHeaders?: SwiggyHeaders | null;
}

let currentLocation: ILocationContext | null = null;

export const LocationContextProvider: React.FC<
    React.PropsWithChildren<LocationContextProviderProps>
> = ({ children, ctx, swiggyHeaders }) => {
    const [value] = React.useState(() => ctx ?? getLocationFromSwiggyHeaders(swiggyHeaders));
    currentLocation = value;

    return <LocationContext.Provider value={value}>{children}</LocationContext.Provider>;
};

export const getCurrentLocation = (): ILocationContext | null => currentLocation;

export const getLocationFromSwiggyHeaders = (
    headers?: SwiggyHeaders | null,
): ILocationContext | null => {
    if (headers?.lat && headers?.lng) {
        return {
            lat: headers.lat,
            lng: headers.lng,
        };
    }

    return null;
};
