import * as React from "react";
import { Linking, Platform } from "react-native";
import { NavigationProp, useNavigation } from "@react-navigation/core";

import { InView } from "@swiggy-private/react-native-ui";
import { SdkConversation } from "@swiggy-private/connect-chat-sdk";
import { IViewStoryMessage } from "@swiggy-private/connect-chat-commons";
import { ViewStoryMessage as ViewStoryMessageComponent } from "@swiggy-private/connect-business-commons";

import { useMinisNavigation } from "@minis-consumer/hooks/use-minis-navigation";
import { useLinking } from "@minis-consumer/hooks/use-linking";
import { MinisRouteList, RouteList } from "@minis-consumer/interfaces/route";
import { MINIS_DOMAIN } from "@minis-consumer/constants";
import { Analytics } from "@minis-consumer/analytics";

import { ANALYTICS_COPIES } from "../../constants";

interface ViewStoryMessageProps {
    message: IViewStoryMessage;
    conversationId: string;
    conversationMeta?: SdkConversation["meta"];
}

export const ViewStoryMsg: React.FC<ViewStoryMessageProps> = ({ message, conversationId }) => {
    const { payload: data, type } = message;
    const navigation = useNavigation<NavigationProp<RouteList & MinisRouteList>>();
    const minisNavigation = useMinisNavigation();
    const openUrl = useLinking();

    const analyticsContextData = JSON.stringify({
        storeId: data.storeId,
        messageType: type,
        conversationId,
    });

    const onPress = React.useCallback(async () => {
        const { slug, storyId } = JSON.parse(data.storyData);

        Analytics.clickEvent({
            category: ANALYTICS_COPIES.CHAT_MSGS,
            context: analyticsContextData,
        });

        if (Platform.OS === "web") {
            const initialUrl = await Linking.getInitialURL();

            const storeDomain = initialUrl?.split("?")?.[0] ?? `https://${slug}.${MINIS_DOMAIN}`;

            const url = `${storeDomain}?storyId=${storyId}`;
            openUrl(url);
            return;
        }
        if (minisNavigation) {
            minisNavigation.goBack();
            minisNavigation.navigate("MiniStore", {
                slug,
                storyId,
            });
        } else {
            navigation?.navigate("MiniStore", {
                slug,
                storyId,
            });
        }
    }, [data.storyData, analyticsContextData, minisNavigation, openUrl, navigation]);

    const onMsgImpression = React.useCallback(
        (isVisible: boolean) => {
            isVisible &&
                Analytics.impressionEvent({
                    category: ANALYTICS_COPIES.CHAT_MSGS,
                    context: analyticsContextData,
                });
        },
        [analyticsContextData],
    );

    return (
        <InView onChange={onMsgImpression}>
            <ViewStoryMessageComponent onPress={onPress} message={message} />
        </InView>
    );
};

export const ViewStoryMessage = React.memo(ViewStoryMsg);
