import { Logger } from "./logger";

export type IAppEvent<T = unknown> = {
    readonly type: AppEventTypes;
    readonly value?: T;
    handled: boolean;
};

type Listener<T> = (e: IAppEvent<T>) => void;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let listeners: Array<readonly [AppEventTypes, Listener<any>]> = [];

export const AppEvent = {
    dispatch: (eventType: AppEventTypes, value?: unknown): IAppEvent => {
        const event: IAppEvent = {
            type: eventType,
            value,
            handled: false,
        };

        listeners.forEach(([type, fn]) => {
            if (type !== eventType) {
                return;
            }

            try {
                fn(event);
            } catch (err) {
                Logger.recordError(err);
            }
        });

        return event;
    },
    listen: <T>(eventType: AppEventTypes, listener: Listener<T>): (() => void) => {
        const tuple = [eventType, listener] as const;
        listeners.push(tuple);

        return () => {
            listeners = listeners.filter((t) => t !== tuple);
        };
    },
};

export enum AppEventTypes {
    NEW_CHAT_MESSAGE,
    SESSION_EXPIRED,
    LOGOUT,
}
