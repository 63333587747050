import { Platform } from "react-native";

export const DISPLAY_STRINGS = {
    BLOCK: "Block",
    UNBLOCK: "Unblock",
    CANCEL: "Cancel",
};

export const ANALYTICS_COPIES = {
    MODAL_MOUNT: "block-unblock-pop-up",
    CANCEL_CTA: "block-unblock-pop-up-cancel-btn",
    BLOCK_CTA: "block-unblock-pop-up-block-btn",
    UNBLOCK_CTA: "block-unblock-pop-up-unblock-btn",
};

export const IS_WEB = Platform.OS === "web";
export const MAX_MODAL_WIDTH_WEB = 300;
