import { Image } from "react-native";
import { Logger } from "./logger";

export class ImagePreloader {
    static async preload(urls: string | string[]): Promise<void> {
        if (!Array.isArray(urls)) {
            urls = [urls];
        }

        const urlsToPrefetch = await ImagePreloader.checkCache(urls);
        if (!urlsToPrefetch.length) {
            return;
        }

        try {
            for await (const url of urlsToPrefetch) {
                await Image.prefetch(url);
            }
        } catch (err) {
            Logger.recordError(err);
        }
    }

    static async checkCache(urls: string[]): Promise<string[]> {
        if (typeof Image.queryCache !== "function") {
            return urls;
        }

        try {
            const cached = await Image.queryCache(urls);
            return urls.filter((url) => cached[url] != null);
        } catch (e) {
            Logger.recordError(e);
            return urls;
        }
    }
}
