import * as React from "react";

import { useChatBlockyModalHandler } from "@minis-consumer/hooks/use-chat-block-modal-handler";
import { ChatBlockDialog } from "@minis-consumer/components/chat-block-modal";

export const ChatBlockProvider: React.FC = () => {
    const { isOpen, conversationId, isBlocked, personName, modalViewHandler } =
        useChatBlockyModalHandler();

    const onCloseModal = React.useCallback(() => {
        modalViewHandler(false);
    }, [modalViewHandler]);

    return (
        <ChatBlockDialog
            showModal={isOpen}
            closeModal={onCloseModal}
            conversationId={conversationId}
            isBlocked={isBlocked}
            personName={personName}
        />
    );
};
