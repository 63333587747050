import * as React from "react";
import { Pressable, StyleSheet } from "react-native";

import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { InView, StoreMedia } from "@swiggy-private/react-native-ui";
import { IK_QUALITY_FORMAT } from "@swiggy-private/react-native-ui/";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Surface, Text, useTheme } from "@swiggy-private/rn-dls";

import { Analytics } from "@minis-consumer/analytics";
import { ProductVariantsSizeLabel } from "@minis-consumer/components/variants";
import {
    META_PIXEL_CONTENT_CATEGORY,
    META_PIXEL_EVENT_NAMES,
} from "@minis-consumer/constants/meta-pixel-analytics";
import { getProductMainImageUrl } from "@minis-consumer/helpers/catalog";
import { fireMetaPixelEvents } from "@minis-consumer/helpers/meta-pixel-events";

import { getProductFallbackImages } from "@minis-consumer/helpers/product-fallback-images";

import { useLocalCartItem } from "@minis-consumer/hooks/use-cart";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { Product } from "@minis-consumer/interfaces/catalog";
import { RouteList } from "@minis-consumer/interfaces/route";
import { SF_ANALYTICS_EVENT_NAMES } from "@minis-consumer/routes/shop/constants";
import { ProductCta } from "@minis-consumer/components/product-cta";
import { useGetProductCtaProps } from "@minis-consumer/components/product-cta/hooks/use-get-cta-actions";
import { useGetPdpCtaNavigation } from "@minis-consumer/hooks/use-pdp-cta-navigation";

import { ProductPrice } from "../product-price";
import { DigitalAssetInfo } from "../digital-asset.info";
import { CommunicationChannel } from "../communication-channel";
import { ProductDuration } from "../product-duration";

export const ProductCarouselItem: React.FC<{
    product: Product;
    index: number;
    sfWidget: string;
}> = ({ product, index, sfWidget }) => {
    const navigation = useNavigation<NativeStackNavigationProp<RouteList>>();
    const { value: theme } = useTheme();
    const imageBgColor = theme["color-secondary-light"];

    const [, increment, decrement] = useLocalCartItem({
        storeId: product.storeId,
        itemId: product.id,
        productType: product.productType ?? "PHYSICAL",
    });

    const productMainImageUrl = getProductMainImageUrl(
        product,
        {
            width: PRODUCT_ITEM_SIZE,
            height: PRODUCT_ITEM_SIZE,
            qualityAuto: IK_QUALITY_FORMAT.GOOD,
        },
        true,
    );

    const imageStyle = {
        backgroundColor: productMainImageUrl ? theme["color-basic-0"] : imageBgColor,
    };

    const storeInfo = useStoreInfo();

    const productCardAnalyticsData = React.useMemo(() => {
        return {
            category: SF_ANALYTICS_EVENT_NAMES.PRODUCT_CARD,
            context: JSON.stringify({
                itemId: product.id,
                sfWidget: sfWidget,
                hasRecommendedTag: true,
                index,
                productType: product.productType,
            }),
        };
    }, [product, index, sfWidget]);

    const showProduct = React.useCallback(() => {
        Analytics.clickEvent(productCardAnalyticsData);

        navigation.navigate("Product", {
            id: product.id,
            slug: storeInfo?.slug,
        });
    }, [product.id, navigation, storeInfo?.slug, productCardAnalyticsData]);

    const { action: pdpNavAction, resetProductSelectedData } =
        useGetPdpCtaNavigation({ productId: product.id, minisNavigation: navigation }) ?? {};
    const onItemAdd = React.useCallback((): void => {
        fireMetaPixelEvents(META_PIXEL_EVENT_NAMES.ADD_TO_CART, {
            content_name: product.name,
            value: String(product?.discountedPrice ?? product?.price),
            content_category: META_PIXEL_CONTENT_CATEGORY.SF_RECOMMENDED,
        });

        resetProductSelectedData?.();

        pdpNavAction?.({
            originalCallback: () => {
                increment();
                resetProductSelectedData?.();
            },
        });
    }, [increment, product, pdpNavAction, resetProductSelectedData]);

    const onViewImpression = React.useCallback(
        (isVisible: boolean) => {
            isVisible && Analytics.impressionEvent(productCardAnalyticsData);
        },
        [productCardAnalyticsData],
    );

    const separatorStyle = {
        ...styles.commChannelAndDurationSeparator,
        borderColor: theme["color-basic-45"],
    };

    const productCtaActionsAndStyles = useGetProductCtaProps({
        productId: product.id,
        storeId: storeInfo.storeId,
        buttonProps: {
            style: styles.btn,
            elevation: 0,
        },
        sfWidget,
        productActionCtaName: `${sfWidget.toLowerCase()}-product-action-cta`,
        onAdd: onItemAdd,
        onRemoveOrDecrement: decrement,
    });

    const productImgFallback = getProductFallbackImages(product.productType);

    return (
        <InView onChange={onViewImpression}>
            <Pressable
                onPress={showProduct}
                accessible
                accessibilityLabel="Open product"
                style={styles.pressable}>
                <Surface style={styles.surface}>
                    <Stack
                        direction="column"
                        style={styles.item}
                        spacing={SpacingValue["space-x-small"]}>
                        <Box
                            style={[
                                styles.imageContainer,
                                { borderColor: theme["color-basic-15"] },
                            ]}>
                            <StoreMedia
                                disableVideoPlay
                                isImageKitEnabled
                                onPress={showProduct}
                                width={PRODUCT_ITEM_SIZE}
                                height={PRODUCT_ITEM_SIZE}
                                mediaId={productMainImageUrl ?? productImgFallback}
                                style={[styles.image, imageStyle]}
                                resizeMode="cover"
                                showLoader
                            />
                        </Box>

                        <Stack spacing={SpacingValue["space-xxx-small"]} style={styles.text}>
                            <Text color="high" category="b1" weight="bold" numberOfLines={2}>
                                {product.name}
                            </Text>
                            <ProductVariantsSizeLabel
                                options={product.variantDetails?.options || []}
                            />

                            {product.digitalAssetInfo && product.productType === "DIGITAL" ? (
                                <DigitalAssetInfo {...product.digitalAssetInfo} />
                            ) : null}
                        </Stack>

                        {product.productType === "APPOINTMENT" || product.productType === "PLAN" ? (
                            <Stack
                                direction="row"
                                divider={
                                    <Box
                                        mh={SpacingValue["space-xx-small"]}
                                        style={separatorStyle}
                                    />
                                }
                                style={styles.commChannelsAndDuration}>
                                {product.serviceDeliveryChannels?.length ? (
                                    <CommunicationChannel
                                        channels={product.serviceDeliveryChannels}
                                        showFirst
                                    />
                                ) : null}

                                {product.productDuration ? (
                                    <ProductDuration {...product.productDuration} />
                                ) : null}
                            </Stack>
                        ) : null}

                        <Box mh={SpacingValue["space-medium"]}>
                            <ProductPrice
                                price={product.price}
                                discountedPrice={product.discountedPrice}
                                discountPercent={product.discountPercent}
                            />
                        </Box>

                        <Box style={styles.btnContainer} justifyContent="flex-end">
                            <Box
                                style={[
                                    styles.buttonSeparator,
                                    {
                                        backgroundColor: theme["color-basic-100"],
                                    },
                                ]}
                            />

                            <Box ph={SpacingValue["space-small"] - 2}>
                                <ProductCta
                                    {...productCtaActionsAndStyles}
                                    productId={product.id}
                                    storeId={storeInfo.storeId}
                                    showVariants
                                    price={product.price}
                                    type={product.productType}
                                />
                            </Box>
                        </Box>
                    </Stack>
                </Surface>
            </Pressable>
        </InView>
    );
};

const PRODUCT_ITEM_SIZE = 210;

const styles = StyleSheet.create({
    pressable: {
        flexGrow: 1,
    },
    surface: {
        elevation: 5,
        borderRadius: 16,
        shadowOpacity: 0.08,
        justifyContent: "space-between",
        flex: 1,
        marginBottom: SpacingValue["space-small"],
        flexGrow: 1,
    },
    item: {
        width: PRODUCT_ITEM_SIZE,
        overflow: "hidden",
        flexGrow: 1,
    },
    image: {
        width: PRODUCT_ITEM_SIZE,
        height: PRODUCT_ITEM_SIZE,
    },
    imageContainer: {
        borderTopLeftRadius: 12,
        borderTopRightRadius: 12,
        borderWidth: 0,
        overflow: "hidden",
    },
    btn: {
        borderWidth: 0,
        borderRadius: 0,
        elevation: 0,
        height: 48,
    },
    buttonSeparator: {
        height: 1,
        width: "80%",
        marginTop: SpacingValue["space-x-small"],
        alignSelf: "center",
        opacity: 0.1,
    },
    btnContainer: {
        flexGrow: 1,
    },
    text: {
        paddingHorizontal: SpacingValue["space-medium"],
    },
    commChannelsAndDuration: {
        paddingHorizontal: SpacingValue["space-medium"],
        marginTop: -SpacingValue["space-xxx-small"],
    },
    commChannelAndDurationSeparator: {
        borderWidth: 0.5,
    },
});
