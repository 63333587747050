import React, { useMemo } from "react";

import { Text, TextProps } from "@swiggy-private/rn-dls";
import { VariantOption } from "@minis-consumer/interfaces/catalog";

import { DOT_SEPERATOR, VARIANT_TEST_IDS } from "../../constants";

interface VariantValuesLabelProps {
    values?: VariantOption["values"];
    textProps?: TextProps;
    seperator?: string;
}

const VariantValuesLabel: React.FC<VariantValuesLabelProps> = ({
    values = [],
    textProps = {},
    seperator = DOT_SEPERATOR,
}) => {
    const variantValues = useMemo(() => {
        const label = values.reduce((acc, curr, index) => {
            const addDotSeperator = values.length !== index + 1;
            acc = `${acc}${curr}`;
            return addDotSeperator ? `${acc} ${seperator} ` : acc;
        }, "");

        return label;
    }, [values, seperator]);

    if (!values.length) {
        return null;
    }

    return (
        <Text
            color="medium"
            category="b3"
            weight="medium"
            {...textProps}
            testID={VARIANT_TEST_IDS.VALUE_LABEL}>
            {variantValues}
        </Text>
    );
};

if (__DEV__) {
    VariantValuesLabel.displayName = "variantValuesLabel";
}

export const ProductVariantValuesLabel = React.memo(VariantValuesLabel);
