import * as React from "react";
import {
    CustomMessageTypes,
    CustomProductsEnquireMessagePayload,
} from "@swiggy-private/connect-chat-commons";
import { useChatSdk, useSendMessage } from "@swiggy-private/connect-chat-ui";
import { SdkConversation } from "@swiggy-private/connect-chat-sdk";

import { Analytics } from "@minis-consumer/analytics";
import { ENQUIRE_HALF_CARD_EVENT_CATEGORY } from "@minis-consumer/constants/analytics";

import { useCreateChatConversation } from "./use-start-chat-conversation";

type SendEnquiryMessageProps = Omit<CustomProductsEnquireMessagePayload, "storeId">;

export const useCustomProductEnquiryChat = (
    storeId: string,
): {
    sendEnquiryMessage: (props: SendEnquiryMessageProps) => void;
    blockedInfo: SdkConversation["blockedInfo"];
} => {
    const [messagePayload, setMessagePayload] =
        React.useState<CustomProductsEnquireMessagePayload>();
    const { conversationId, blockedInfo } = useCreateChatConversation({
        storeId,
        ignoreCache: true,
    });
    const sendMessage = useSendMessage(conversationId ?? "");
    const sdk = useChatSdk();

    const sendEnquiryMessage = React.useCallback(
        ({ text, customProductList }: SendEnquiryMessageProps) => {
            setMessagePayload({
                text,
                customProductList,
                storeId,
            });
        },
        [storeId],
    );

    React.useEffect(() => {
        if (!conversationId || !sdk || !messagePayload) {
            return;
        }

        const message = sdk.prepareMessage<CustomProductsEnquireMessagePayload>({
            type: CustomMessageTypes.CUSTOM_PRODUCT_ENQUIRE,
            payload: messagePayload,
        });

        const messageRes = sendMessage(message);

        if (messageRes) {
            Analytics.clickEvent({
                category: ENQUIRE_HALF_CARD_EVENT_CATEGORY,
                label: `customProductList: ${messagePayload.customProductList}`,
                context: `messageId: ${messageRes.message.id}, initiatedBy: customer`,
            });
        }
    }, [messagePayload, conversationId, sdk, sendMessage]);

    return { sendEnquiryMessage, blockedInfo };
};
