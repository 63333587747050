import { ApiError, API_SUCCESS_STATUS_CODE } from "@swiggy-private/connect-api-core";
import { Endpoint } from "@swiggy-private/http-client";

import { getApiClient } from "../../includes/api";
import {
    CartClearEndpoint,
    CartEndpoint,
    CartListEndpoint,
    ClearCartFeedbackOptionsEndpoint,
} from "../endpoint";
import type {
    Cart,
    ICartGuestDetails,
    ICartsList,
    IClearCartFeedbackOptions,
    LocalCartItem,
} from "../../interfaces/cart";

export const CART_API_ERRORS = {
    FETCH_CART: "Could not fetch the cart",
    UPDATE_CART: "Could not update the cart",
    DELETE_CART: "Could not delete the cart",
    BULK_DELETE_CARTS: "Could not perform bulk deletion",
    FEEDBACK_OPTIONS: "Could not fetch the feedback options",
    FETCH_LIST: "Could not fetch the cart list",
};

const DEFAULT_PAGE_SIZE = 10;

export interface FetchCartParams {
    storeId: string;
    addressId?: string;
    cartKey?: string;
}

export const fetchCart = async ({
    storeId,
    addressId,
    cartKey,
}: FetchCartParams): Promise<Cart> => {
    const endpoint = Endpoint.from(CartEndpoint, { storeId });
    const response = await getApiClient().get<Cart>({
        endpoint,
        queryParams: {
            userAddressId: addressId || "",
            cartKey: cartKey || "",
        },
    });

    if (response.statusCode === API_SUCCESS_STATUS_CODE && response.data) {
        return response.data;
    }

    return Promise.reject(
        new ApiError(response.statusMessage || CART_API_ERRORS.FETCH_CART, response),
    );
};

export interface UpdateCartParams {
    storeId: string;
    cart: {
        cartItems: Array<LocalCartItem>;
        appliedCoupon?: string;
        guestUserDetails?: ICartGuestDetails;
    };
    addressId?: string;
    cartKey?: string;
}

export const updateCart = async ({
    storeId,
    addressId,
    cartKey,
    cart = { cartItems: [], appliedCoupon: "" },
}: UpdateCartParams): Promise<Cart> => {
    const endpoint = Endpoint.from(CartEndpoint, { storeId });
    const response = await getApiClient().put<Cart>({
        endpoint,
        queryParams: {
            userAddressId: addressId || "",
            customCartKey: cartKey || "",
        },
        body: cart,
    });

    if (response.statusCode === API_SUCCESS_STATUS_CODE && response.data) {
        return response.data;
    }

    return Promise.reject(
        new ApiError(response.statusMessage || CART_API_ERRORS.UPDATE_CART, response),
    );
};

export interface DeleteCartParams {
    storeId: string;
}

export const deleteCart = async ({ storeId }: DeleteCartParams): Promise<void> => {
    const endpoint = Endpoint.from(CartEndpoint, { storeId });
    const response = await getApiClient().delete<Cart>({ endpoint });

    if (response.statusCode === API_SUCCESS_STATUS_CODE) {
        return;
    }

    return Promise.reject(
        new ApiError(response.statusMessage || CART_API_ERRORS.DELETE_CART, response),
    );
};

export const fetchClearCartFeedbackOptionsApi = async (): Promise<IClearCartFeedbackOptions> => {
    const endpoint = Endpoint.from(ClearCartFeedbackOptionsEndpoint);

    const response = await getApiClient().get<IClearCartFeedbackOptions>({
        endpoint,
    });

    if (response.statusCode === API_SUCCESS_STATUS_CODE && response.data) {
        return response.data;
    }

    return Promise.reject(
        new ApiError(response.statusMessage || CART_API_ERRORS.FEEDBACK_OPTIONS, response),
    );
};

export interface CartListingParams {
    cursor?: string;
    pageSize?: number;
    type?: string;
}

export const fetchAllCartsApi = async (params: CartListingParams): Promise<ICartsList> => {
    const { cursor, pageSize } = params;

    const endpoint = Endpoint.from(CartListEndpoint);

    const response = await getApiClient().get<ICartsList>({
        endpoint,
        queryParams: {
            lastPageCursor: cursor ?? "",
            pageSize: String(pageSize ?? DEFAULT_PAGE_SIZE),
        },
    });

    if (response.statusCode === API_SUCCESS_STATUS_CODE && response.data) {
        return response.data;
    }

    return Promise.reject(
        new ApiError(response.statusMessage || CART_API_ERRORS.FETCH_LIST, response),
    );
};

export interface ClearCartParams {
    storeId?: string;
    bulk?: boolean;
}

export const clearCartApi = async (params: ClearCartParams): Promise<void> => {
    const { storeId, bulk = false } = params;

    const endpoint = Endpoint.from(CartClearEndpoint);

    const response = await getApiClient().post<void>({
        endpoint,
        queryParams: {
            storeId: storeId ?? "",
            bulk: String(bulk),
        },
    });

    if (response.statusCode === API_SUCCESS_STATUS_CODE) {
        return;
    }

    return Promise.reject(
        new ApiError(
            response.statusMessage || bulk
                ? CART_API_ERRORS.BULK_DELETE_CARTS
                : CART_API_ERRORS.DELETE_CART,
            response,
        ),
    );
};
