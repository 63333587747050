import * as React from "react";

import { ViewStoryMessagePayload } from "@swiggy-private/connect-chat-commons";

type IViewStoryModalContext = {
    isOpen: boolean;
    data: Omit<ViewStoryMessagePayload, "text">;
    isMessageSent?: boolean;

    open: VoidFunction;
    close: VoidFunction;
    setData: (data: Omit<ViewStoryMessagePayload, "text">) => void;
    setIsMessageSent: (e: boolean) => void;
};

export const ViewStoryModalContext = React.createContext<IViewStoryModalContext | null>(null);

export const ViewStoryModalContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [data, setData] = React.useState({} as Omit<ViewStoryMessagePayload, "text">);
    const [isMessageSent, setIsMessageSent] = React.useState(false);

    const close = React.useCallback(() => setIsOpen(false), []);
    const open = React.useCallback(() => setIsOpen(true), []);

    const value = React.useMemo(
        () => ({
            isOpen,
            data,
            close,
            open,
            isMessageSent,
            setIsMessageSent,
            setData,
        }),
        [isOpen, data, close, open, isMessageSent],
    );

    return (
        <ViewStoryModalContext.Provider value={value}>{children}</ViewStoryModalContext.Provider>
    );
};
