import "./includes/polyfill";

import * as React from "react";
import { AppRegistry } from "react-native";

import {
    AppConfig,
    initializePushNotification,
    initializeBlaze,
    getConfig,
    Logger,
    recordAppLoadTime,
    BLAZE_EVENTS,
} from "@swiggy-private/mini-consumer-native";

import { awaitInitialData } from "@rest-hooks/ssr";
import { CacheProvider } from "@rest-hooks/react";

import { App } from "./server/app/app";
import { DEV_STORE_SLUG, ROOT_ELEMENT_ID, RENDER_ON_SERVER_ERROR_MSG } from "./constants";

const getSlugFromHost = (): string => {
    const domain = window.location.hostname.split(".");
    if (domain[0] === "www") {
        return domain[1];
    } else {
        return domain[0];
    }
};

export const getStoreSlug = (): string => {
    if (!__DEV__ && AppConfig.APP_ENV === "production") {
        return getSlugFromHost();
    }

    const searchParams = new URLSearchParams(window.location.search);
    const storeSlug =
        searchParams.get("storeSlug") || localStorage.getItem("storeSlug") || getSlugFromHost();

    if (__DEV__ && storeSlug === "localhost") {
        return DEV_STORE_SLUG;
    }

    if (storeSlug) {
        localStorage.setItem("storeSlug", storeSlug);
    }

    return storeSlug;
};

//Initialize BlazeJS
initializeBlaze();

const WebApp: React.FC<React.PropsWithChildren<{ initialState: State<unknown> }>> = ({
    initialState,
    ...props
}) => {
    const initialPath = window.location.pathname + window.location.search;

    return (
        <CacheProvider initialState={initialState}>
            <App {...props} storeSlug={getStoreSlug()} url={initialPath} />
        </CacheProvider>
    );
};

const runAppClient = (): void => {
    AppRegistry.registerComponent("App", () => WebApp);
    AppRegistry.runApplication("App", {
        rootTag: document.getElementById(ROOT_ELEMENT_ID),
    });
};

const runAppSSR = async (): Promise<void> => {
    try {
        const initialData = await awaitInitialData();

        recordAppLoadTime(BLAZE_EVENTS.LOAD_TIME.STORE_FRONT_SSR);

        const SsrWebApp: React.FC = () => <WebApp initialState={initialData} />;
        AppRegistry.registerComponent("App", () => SsrWebApp);

        AppRegistry.runApplication("App", {
            rootTag: document.getElementById(ROOT_ELEMENT_ID),
            hydrate: true,
            callback: {
                onRecoverableError: (err: Error) => {
                    if (err.message === RENDER_ON_SERVER_ERROR_MSG) {
                        return;
                    }
                    Logger.recordError(err);
                },
            },
        });
    } catch (e) {
        runAppClient();
    }
};

if (__DEV__ || getConfig("isSSR", "false") !== "true") {
    runAppClient();
} else {
    runAppSSR();
}

if (!__DEV__ && "serviceWorker" in navigator) {
    window.addEventListener("load", () => {
        navigator.serviceWorker
            .register("/service-worker.js")
            .then((registration) => {
                console.log("SW registered: ", registration);
            })
            .catch((registrationError) => {
                console.log("SW registration failed: ", registrationError);
            });
    });

    window.addEventListener("beforeinstallprompt", (event) => {
        event.preventDefault();
    });
}

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { State } from "@rest-hooks/react";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDcOVu-ramgLRWtolNYbe_CagyCRBpbQlc",
    authDomain: "swiggy-mini-merchant-app.firebaseapp.com",
    projectId: "swiggy-mini-merchant-app",
    storageBucket: "swiggy-mini-merchant-app.appspot.com",
    messagingSenderId: "838653606986",
    appId: "1:838653606986:web:df139311294006905ebab3",
};

if (__DEV__) {
    const consoleError = console.error;
    const consoleWarn = console.warn;

    const SUPPRESSED_ERRORS = [
        "Warning: Using UNSAFE_componentWillMount",
        "Warning: Using UNSAFE_componentWillReceiveProps",
    ];

    const SUPPRESSED_WARNINGS = ["Animated: `useNativeDriver` is not supported"];

    console.error = function filterErrors(msg, ...args) {
        if (!SUPPRESSED_ERRORS.some((entry) => msg.includes(entry))) {
            consoleError(msg, ...args);
        }
    };

    console.warn = function filterWarnings(msg, ...args) {
        if (!SUPPRESSED_WARNINGS.some((entry) => msg.includes(entry))) {
            consoleWarn(msg, ...args);
        }
    };
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize PN
initializePushNotification(app);
