import React, { useCallback, useMemo, memo } from "react";
import { NavigationProp, useNavigation } from "@react-navigation/core";

import { InView } from "@swiggy-private/react-native-ui";
import { SdkConversation } from "@swiggy-private/connect-chat-sdk";
import { IReturnOrderMessage } from "@swiggy-private/connect-chat-commons";
import { ConsumerReturnOrderMessage as ConsumerReturnOrderMessageComp } from "@swiggy-private/connect-business-commons";

import { Analytics } from "@minis-consumer/analytics";
import { RouteList } from "@minis-consumer/interfaces/route";

import { ANALYTICS_COPIES } from "../../constants";

interface Props {
    isPublisher: boolean;
    message: IReturnOrderMessage;
    conversationId: SdkConversation["id"];
}

const ReturnOrderMsg: React.FC<Props> = ({ message, isPublisher, conversationId }) => {
    const navigation = useNavigation<NavigationProp<RouteList>>();

    const { payload: data, type } = message;
    const { storeId, orderId } = data || {};

    const analyticsContextData = useMemo(
        () =>
            JSON.stringify({
                storeId: storeId,
                orderId: orderId,
                messageType: type,
                conversationId,
            }),
        [conversationId, storeId, type, orderId],
    );

    const onMsgImpression = useCallback(
        (isVisible: boolean) => {
            isVisible &&
                Analytics.impressionEvent({
                    category: ANALYTICS_COPIES.CHAT_MSGS,
                    context: analyticsContextData,
                });
        },
        [analyticsContextData],
    );

    const onPress = useCallback(() => {
        navigation.navigate("Order", {
            id: orderId,
        });
    }, [navigation, orderId]);

    return (
        <InView onChange={onMsgImpression}>
            <ConsumerReturnOrderMessageComp
                onPress={onPress}
                isPublisher={isPublisher}
                message={message}
            />
        </InView>
    );
};

export const ReturnOrderMessage = memo(ReturnOrderMsg);
