import React, { useEffect } from "react";
import { Pressable, StyleSheet, Platform, ScrollView, StyleProp, ViewStyle } from "react-native";
import LinearGradient from "react-native-linear-gradient";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { Analytics } from "@minis-consumer/analytics";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { CdnImage } from "@swiggy-private/react-native-ui";
import {
    Box,
    ScreenSize,
    Stack,
    useScreenSize,
    useSelectScreen,
} from "@swiggy-private/rn-adaptive-layout";
import { Portal, SpacingValue, Text, useTheme, Button, Modal } from "@swiggy-private/rn-dls";
import { useShareCheckForInApp } from "@minis-consumer/helpers/user-agent-app-name";
import { Logger } from "@minis-consumer/includes/logger";
import { useLinking } from "@minis-consumer/hooks/use-linking";
import { getMobileOperatingSystem } from "@minis-consumer/helpers/user-agent-app-name";
import { MINIS_SHARE_TEXT, MINIS_SITE } from "@minis-consumer/constants/share";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";

import * as CONSTANTS from "./constants";

const {
    HEADER,
    CONTENT,
    CTA,
    GRADIENT_COLORS,
    GRADIENT_LOCATIONS_START,
    GRADIENT_LOCATIONS_END,
    SELLER_APP_LINKS,
} = CONSTANTS;

interface Props {
    showModal: boolean;
    closeModal: () => void;
}

const MinisDownloadComponent: React.FC<Props> = ({ showModal, closeModal }) => {
    const { value: theme } = useTheme();
    const executeShare = useShareCheckForInApp();
    const openUrl = useLinking();
    const storeInfo = useStoreInfo(false);

    const insets = useSafeAreaInsets();

    const onCloseDialog = React.useCallback((): void => {
        closeModal();
    }, [closeModal]);

    useEffect(() => {
        if (!showModal) {
            return;
        }
        Analytics.impressionEvent({
            category: "download-app-btn",
            label: storeInfo?.storeId,
        });
        Analytics.impressionEvent({
            category: "refer-minis",
            label: storeInfo?.storeId,
        });
    }, [showModal, storeInfo?.storeId]);

    const onPressReferralCta = async (): Promise<void> => {
        try {
            executeShare(
                {
                    message: MINIS_SHARE_TEXT,
                },
                undefined,
                MINIS_SITE,
            );
            Analytics.clickEvent({
                category: "refer-minis",
                label: storeInfo?.storeId,
            });
        } catch (error) {
            Logger.recordError(error);
        }
    };

    const onPressStartSellCta = React.useCallback((): void => {
        let url = Platform.select({
            ios: SELLER_APP_LINKS.ios,
            android: SELLER_APP_LINKS.android,
            default: "",
        });

        Analytics.clickEvent({
            category: "download-app-btn",
            label: storeInfo?.storeId,
        });

        if (Platform.OS === "web") {
            const agent = getMobileOperatingSystem();
            url = agent === "iOS" ? SELLER_APP_LINKS.ios : SELLER_APP_LINKS.android;
        }
        openUrl(url);
    }, [openUrl, storeInfo?.storeId]);

    const gradientCommonStyles: StyleProp<ViewStyle> = {
        ...styles.gradient,
        paddingTop: SpacingValue["space-small"] + insets.top,
        paddingBottom: SpacingValue["space-xx-large"],
    };

    const gradientScreenStyle: StyleProp<ViewStyle> = useSelectScreen({
        lg: {
            ...gradientCommonStyles,
            borderRadius: 16,
            height: 540,
            overflow: "scroll",
        },
        default: { ...gradientCommonStyles },
    });

    if (!showModal) {
        return null;
    }

    return (
        <LinearGradient
            colors={GRADIENT_COLORS}
            start={GRADIENT_LOCATIONS_START}
            end={GRADIENT_LOCATIONS_END}
            locations={[0, 0.5, 0, 1]}
            angle={105}
            style={gradientScreenStyle}>
            <Pressable onPress={onCloseDialog}>
                <Box
                    ml={SpacingValue["space-medium"]}
                    justifyContent="center"
                    alignItems="center"
                    style={[styles.back, { backgroundColor: theme["background_Secondary_Alpha"] }]}>
                    <SvgIcon icon="ArrowLeft" color="color-basic-75" width={24} height={24} />
                </Box>
            </Pressable>
            <Stack
                justifyContent="center"
                alignItems="center"
                spacing={SpacingValue["space-small"]}>
                <CdnImage
                    id={"static-assets/swiggy_minis_orange"}
                    height={50}
                    width={200}
                    style={styles.logo}
                    resizeMode="contain"
                />
                <Text
                    category="header_H4_Black"
                    color={"text_High_Emphasis"}
                    style={[styles.text, { maxWidth: "60%" }]}>
                    {HEADER.TITLE}
                </Text>
                <Text
                    category="b2"
                    weight="medium"
                    color={"text_High_Emphasis"}
                    style={styles.text}>
                    {HEADER.SUBTITLE_PREFIX}{" "}
                    <SvgIcon
                        icon="HeartFilled"
                        width={16}
                        height={16}
                        color={theme["primary"]}
                        style={{ paddingTop: SpacingValue["space-xxx-small"] }}
                    />{" "}
                    {HEADER.SUBTITLE_SUFFIX}
                </Text>
            </Stack>
            <Box justifyContent="center" alignItems="center">
                <CdnImage
                    isImageKitEnabled
                    id="static-assets/arrow"
                    width={50}
                    height={100}
                    resizeMode="contain"
                />
            </Box>
            <Box justifyContent="center" alignItems="center" mb={SpacingValue["space-xx-large"]}>
                <Stack
                    justifyContent="center"
                    alignItems="center"
                    spacing={SpacingValue["space-xx-small"]}>
                    <Text category="header_H3_Black" color={"text_High_Emphasis"}>
                        {CONTENT.TITLE}
                    </Text>
                    <Text category="b1" weight="bold" style={{ color: theme["primary"] }}>
                        {CONTENT.SUBTITLE}
                    </Text>
                    <CdnImage
                        isImageKitEnabled
                        id="https://minis-media-assets.swiggy.com/swiggymini/image/upload/static-assets/activity-page/underline.png"
                        width={150}
                        height={4}
                    />
                </Stack>
            </Box>
            <Stack
                justifyContent="center"
                alignItems="center"
                spacing={SpacingValue["space-medium"]}>
                {CONTENT.LIST.map((item) => (
                    <Stack
                        key={item.title}
                        justifyContent="center"
                        alignItems="center"
                        spacing={SpacingValue["space-xx-small"]}>
                        <SvgIcon
                            icon="TickFilled"
                            width={24}
                            height={24}
                            color={theme["positive"]}
                        />
                        <Text
                            category="b1"
                            weight="bold"
                            color={"text_High_Emphasis"}
                            style={styles.text}>
                            {item.title}
                        </Text>
                        <Text
                            category="b2"
                            weight="medium"
                            color={"text_Med_Emphasis"}
                            style={styles.text}>
                            {item.subtitle}
                        </Text>
                    </Stack>
                ))}
            </Stack>
            <Stack
                justifyContent="center"
                alignItems="center"
                style={{
                    paddingHorizontal: SpacingValue["space-medium"],
                    marginTop: SpacingValue["space-large"],
                }}
                spacing={SpacingValue["space-medium"]}>
                <Button color="rn_dls_primary" onPress={onPressStartSellCta} style={styles.cta}>
                    <>
                        <Text category="b1" weight="bold" color="color-basic-0">
                            {CTA.title}
                        </Text>
                        <Text category="b3" weight="medium" color="primary_Sub">
                            {CTA.subtitle}
                        </Text>
                    </>
                </Button>
                <Box>
                    <Pressable style={styles.button} onPress={onPressReferralCta}>
                        <Box direction="row" justifyContent="center" alignItems="center">
                            <Text category="b1" color="text_High_Emphasis" weight="bold">
                                Refer{" "}
                            </Text>
                            <Text category="b1" weight="bold" style={{ color: theme["primary"] }}>
                                Minis{" "}
                            </Text>
                            <Text category="b1" color="text_High_Emphasis" weight="bold">
                                to someone you know
                            </Text>
                        </Box>
                    </Pressable>
                </Box>
            </Stack>
        </LinearGradient>
    );
};

const DownloadModalWrapper: React.FC<Props> = ({ showModal, closeModal }) => {
    const screenSize = useScreenSize();
    const isLargeScreen = React.useMemo(() => screenSize === ScreenSize.Large, [screenSize]);

    if (!showModal) {
        return null;
    }

    if (isLargeScreen) {
        return (
            <Modal
                showModal={showModal}
                setShowModal={closeModal}
                modalStyles={{
                    modalBodyStyle: styles.modalBodyStyle,
                }}
                component={<MinisDownloadComponent showModal={showModal} closeModal={closeModal} />}
            />
        );
    }

    return (
        <Portal>
            <ScrollView style={styles.container} bounces={false}>
                <MinisDownloadComponent showModal={showModal} closeModal={closeModal} />
            </ScrollView>
        </Portal>
    );
};

export const MinisDownloadModal = React.memo(DownloadModalWrapper);

const styles = StyleSheet.create({
    cta: {
        marginTop: SpacingValue["space-large"],
        justifyContent: "center",
        borderRadius: 100,
        width: "100%",
        height: 56,
        paddingVertical: SpacingValue["space-small"],
    },
    overlay: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    container: {
        flex: 1,
        backgroundColor: "#fff",
        width: "100%",
        flexGrow: 1,
        height: "100%",
    },
    button: {
        alignItems: "center",
        justifyContent: "center",
    },
    logo: {
        height: 50,
        width: 200,
        marginTop: SpacingValue["space-xx-small"],
    },
    text: {
        textAlign: "center",
        marginTop: SpacingValue["space-xx-small"],
    },
    gradient: {
        flex: 1,
    },
    back: {
        height: 40,
        width: 40,
        borderRadius: 40,
    },
    modalBodyStyle: {
        borderRadius: 8,
        maxHeight: "80%",
        maxWidth: "40%",
        width: "40%",
        paddingTop: SpacingValue["space-x-large"],
        paddingHorizontal: SpacingValue["space-medium"],
        paddingBottom: SpacingValue["space-medium"],
    },
});
