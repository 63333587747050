import React, { useEffect } from "react";
import { ViewStyle } from "react-native";

import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { Button, SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { ThreeDotsLoader } from "@swiggy-private/react-native-ui";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";

import { useFollowUnfollowStore } from "@minis-consumer/hooks/use-store-follow";

import { CONSTANT_STRINGS } from "./constants";

/** TODO: Reuse the same CTA in the entire app */
const FollowUnfollowCTAComponent: React.FC<{
    storeId: string;
    logoSize: number;

    hideIcon?: boolean;
    style?: ViewStyle;
    updateFollowersText?: (b: boolean) => void;
    isStoreFollowed?: boolean;
    onPressFollow?: VoidFunction;
    renderCTA?: () => React.ReactNode;
}> = ({
    storeId,
    logoSize,
    isStoreFollowed,
    hideIcon,
    style,
    renderCTA,
    updateFollowersText,
    onPressFollow,
}) => {
    const { value: theme } = useTheme();

    const {
        following: followingStore,
        trigger,
        loading,
        canFollow,
    } = useFollowUnfollowStore({ id: storeId, isFollowStatusRequired: false, isStoreFollowed });

    const actionBtn = React.useMemo(() => {
        return {
            backgroundColor: followingStore ? theme["color-basic-0"] : theme["color-primary-25"],
            height: logoSize,
            minHeight: logoSize,
            borderColor: followingStore ? theme["color-primary-25"] : "transparent",
            borderRadius: SpacingValue["space-x-small"],
            maxWidth: followingStore && !loading ? logoSize : undefined,
            ...style,
        };
    }, [loading, followingStore, logoSize, theme, style]);

    const onPressFollowCta = React.useCallback(() => {
        onPressFollow?.();

        trigger();
    }, [trigger, onPressFollow]);

    useEffect(() => {
        !loading && updateFollowersText?.(followingStore);
    }, [loading, followingStore, updateFollowersText]);

    if (!canFollow) {
        return null;
    }

    return (
        <Button
            elevation={2}
            color="tertiary"
            style={actionBtn}
            disableElevation
            disabled={loading}
            onPress={onPressFollowCta}>
            {loading ? (
                <ThreeDotsLoader color="color-primary" />
            ) : followingStore ? (
                <SvgIcon icon="HeartFilled" height={16} width={16} color="color-primary-400" />
            ) : (
                <Stack direction="row" spacing={SpacingValue["space-xx-small"]} alignItems="center">
                    {!hideIcon ? (
                        <SvgIcon icon="Heart" height={14} width={14} color="color-primary-400" />
                    ) : null}
                    {renderCTA ? (
                        renderCTA()
                    ) : (
                        <Text category="b2" color="color-primary-400" weight="medium">
                            {CONSTANT_STRINGS.LOVE_THIS}
                        </Text>
                    )}
                </Stack>
            )}
        </Button>
    );
};

export const FollowUnfollowCTA = React.memo(FollowUnfollowCTAComponent);
