import * as React from "react";
import { NavbarRouteList } from "@minis-consumer/interfaces/route";

export interface IBottomBarScrollContext {
    pageScroll: number;
    isBottomBarVisible: boolean;
    setScrollPos: (scroll: number, context: string) => void;
    toggleBottomBarVisibility: (isNavBarShown: boolean) => void;
    resetPageScroll: VoidFunction;
    setScreenContext: (screen: keyof NavbarRouteList) => void;
}

export const BottomBarScrollContext = React.createContext<IBottomBarScrollContext>({
    pageScroll: 0,
    isBottomBarVisible: true,
    resetPageScroll: () => undefined,
    setScrollPos: () => undefined,
    toggleBottomBarVisibility: () => undefined,
    setScreenContext: () => undefined,
});

export const INITIAL_SCREEN = "Explore";

export const BottomBarScrollContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [pageScroll, setPageScroll] = React.useState(0);
    const [screenContext, setScreenContext] = React.useState(INITIAL_SCREEN);
    const [isBottomBarVisible, setBottomBarVisibility] = React.useState(true);

    const setScrollPos = React.useCallback(
        (pos: number, context: string) => {
            if (screenContext === context) {
                setPageScroll(pos);
            }
        },
        [screenContext],
    );

    const resetPageScroll = React.useCallback(() => {
        setPageScroll(0);
    }, []);

    const toggleBottomBarVisibility = React.useCallback((isNavBarShown: boolean) => {
        setBottomBarVisibility(isNavBarShown);
    }, []);

    const value = React.useMemo(
        () => ({
            pageScroll,
            isBottomBarVisible,
            toggleBottomBarVisibility,
            setScrollPos,
            resetPageScroll,
            setScreenContext,
        }),
        [
            pageScroll,
            isBottomBarVisible,
            setScreenContext,
            toggleBottomBarVisibility,
            setScrollPos,
            resetPageScroll,
        ],
    );

    return (
        <BottomBarScrollContext.Provider value={value}>{children}</BottomBarScrollContext.Provider>
    );
};

export const useBottomBarScrollContext = (): IBottomBarScrollContext =>
    React.useContext(BottomBarScrollContext);
