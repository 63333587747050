import React from "react";
import {
    ViewStyle,
    StyleSheet,
    useWindowDimensions,
    Animated,
    Platform,
    InteractionManager,
} from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { BottomSheet, CdnImage } from "@swiggy-private/react-native-ui";
import {
    DialogContent,
    SpacingValue,
    Text,
    useAnimatedValue,
    useTheme,
} from "@swiggy-private/rn-dls";
import { Box, Stack, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { useMount } from "@swiggy-private/react-hooks";

import { Analytics } from "@minis-consumer/analytics";
import { useIsDesktop } from "@minis-consumer/hooks/use-desktop";

import { CONSTANTS, COPIES } from "./constants";

const isWeb = Platform.OS === "web";

type StarSellerInfoProps = {
    showDialog: boolean;
    closeDialog: VoidFunction;
    storeId: string;
};

const StarSellerInfoComponent: React.FC<StarSellerInfoProps> = ({
    showDialog,
    closeDialog,
    storeId,
}) => {
    const insets = useSafeAreaInsets();

    const modalMaxWidth = useSelectScreen({
        lg: CONSTANTS.MAX_DESKTOP_WIDTH as ViewStyle["width"],
        default: "100%",
    });

    const modalStyles = useSelectScreen({
        default: {
            borderTopLeftRadius: 22,
            borderTopRightRadius: 22,
            paddingBottom: Math.max(
                insets.bottom + SpacingValue["space-xx-small"],
                SpacingValue["space-large"],
            ),
        },

        lg: {
            borderRadius: 22,
        },
    });

    const contentStyle = useSelectScreen({
        lg: {
            paddingBottom: 2 * SpacingValue["space-large"],
            paddingHorizontal: CONSTANTS.PADDING_HORIZONTAL, // Default value doesn't roll up
        },
        default: {
            paddingHorizontal: CONSTANTS.PADDING_HORIZONTAL,
        },
    });

    if (!showDialog) {
        return null;
    }

    return (
        <BottomSheet
            disableBackdropClose={false}
            open={showDialog}
            onClose={closeDialog}
            style={[modalStyles, { width: modalMaxWidth }]}>
            <DialogContent style={contentStyle}>
                <StarSellerInfoContent storeId={storeId} />
            </DialogContent>
        </BottomSheet>
    );
};

const StarSellerInfoContent: React.FC<{ storeId: string }> = React.memo(({ storeId }) => {
    const { value: theme } = useTheme();
    const animatedValue = useAnimatedValue(0);
    const { width: screenWidth } = useWindowDimensions();
    const isDesktop = useIsDesktop();

    const { BOX_SIZE, ANIMATION_DELAY, ANIMATION_DURATION, MAX_DESKTOP_WIDTH, PADDING_HORIZONTAL } =
        CONSTANTS;

    const leftPosition = React.useMemo(
        () => ((isDesktop ? MAX_DESKTOP_WIDTH : screenWidth) - BOX_SIZE) / 2 - PADDING_HORIZONTAL,
        [BOX_SIZE, MAX_DESKTOP_WIDTH, PADDING_HORIZONTAL, isDesktop, screenWidth],
    );

    const imageWidth = React.useMemo(() => Math.min(screenWidth * 0.41, 153), [screenWidth]);
    const imageHeight = React.useMemo(() => imageWidth * 0.32, [imageWidth]);

    const patternWidth = React.useMemo(
        () => (isDesktop ? MAX_DESKTOP_WIDTH : screenWidth),
        [MAX_DESKTOP_WIDTH, isDesktop, screenWidth],
    );
    const patternHeight = React.useMemo(() => patternWidth * 0.6, [patternWidth]);

    const boxAdditionalStyles = {
        backgroundColor: theme["color-positive-500"],
        width: BOX_SIZE,
        height: BOX_SIZE,
        left: leftPosition,
        top: -(BOX_SIZE / 2) - SpacingValue["space-large"], // Minus paddingTop
        borderRadius: BOX_SIZE / 2,
    };

    const animatedStyle = {
        opacity: animatedValue.interpolate({
            inputRange: [0, 1],
            outputRange: [0.5, 1],
        }),
        transform: [
            {
                translateY: animatedValue.interpolate({
                    inputRange: [0, 1],
                    outputRange: [32, 16],
                }),
            },
        ],
    };

    const imgAnimatedStyle = {
        opacity: animatedValue.interpolate({
            inputRange: [0, 1],
            outputRange: [0, 1],
        }),
    };

    useMount(() => {
        Analytics.impressionEvent({
            category: COPIES.IMPRESSION_CATEGORY,
            label: storeId,
        });

        if (isWeb) {
            return;
        }

        const task = InteractionManager.runAfterInteractions(() => {
            Animated.timing(animatedValue, {
                toValue: 1,
                duration: ANIMATION_DURATION,
                useNativeDriver: !isWeb,
                delay: ANIMATION_DELAY,
            }).start();
        });

        return () => {
            task?.cancel();
        };
    });

    return (
        <Stack alignItems="center">
            <Box style={[styles.box, boxAdditionalStyles]}>
                <SvgIcon icon="StarBadge" color="color-basic-0" height={40} width={40} />
            </Box>
            <CdnImage
                isImageKitEnabled
                id="minis/dotted_radial_pattern"
                style={styles.pattern}
                width={patternWidth}
                height={patternHeight}
            />
            <Animated.View style={!isWeb ? imgAnimatedStyle : undefined}>
                <CdnImage
                    isImageKitEnabled
                    id="minis/star_seller"
                    width={imageWidth}
                    height={imageHeight}
                    style={styles.img}
                />
            </Animated.View>

            <Animated.View style={!isWeb ? animatedStyle : undefined}>
                <Text category="b2" color="high" style={styles.txt}>
                    {COPIES.DESCRIPTION}
                </Text>
            </Animated.View>
        </Stack>
    );
});

if (__DEV__) {
    StarSellerInfoContent.displayName = "StarSellerInfoContent";
}

const styles = StyleSheet.create({
    pattern: {
        position: "absolute",
        top: -20,
        borderRadius: 22,
        zIndex: -1,
        opacity: 0.5,
    },
    box: {
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
    },
    txt: {
        textAlign: "center",
        marginTop: !isWeb ? -SpacingValue["space-large"] : undefined,
    },
    img: {
        marginVertical: SpacingValue["space-x-large"],
    },
});

export const StarSellerInfo = React.memo(StarSellerInfoComponent);
