import { Session } from "@minis-consumer/includes/session";

import type { IAppUser } from "@minis-consumer/reducers/app-reducer/types";

export const getUserFromSession = (): IAppUser | null => {
    const session = Session.getInstance();

    return session.isGuest()
        ? null
        : {
              id: session.userId,
              token: session.token ?? "",
              name: session.userName ?? "",
          };
};
