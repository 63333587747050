import { useContext } from "react";
import { Platform } from "react-native";

import { SessionContext } from "@minis-consumer/contexts/session-context";
import { ConfigInfo } from "@minis-consumer/interfaces/config-info";

export const useGetSession = (): {
    doesSessionExist: boolean;
    config?: ConfigInfo;
    setConfigInfo: (config: ConfigInfo) => void;
} => {
    const { doesSessionExist, config, setConfigInfo } = useContext(SessionContext);
    if (Platform.OS !== "web") {
        return { doesSessionExist: false, config, setConfigInfo };
    }
    return { doesSessionExist, config, setConfigInfo };
};
