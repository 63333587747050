import { useSuspense } from "@rest-hooks/react";

import { User } from "@minis-consumer/resources/user";
import { IAppUser } from "@minis-consumer/reducers/app-reducer/types";
import { User as IUser } from "@minis-consumer/interfaces/user";

import { useAppState } from "./use-app-state";

export const useUserInfo = (): IAppUser | null => {
    const user = useAppState().user;
    return String(user?.id || "0") === "0" ? null : user;
};

export const useSignedIn = (): boolean => {
    return useUserInfo() != null;
};

export const useSuspenseUser = (): IUser | null => {
    const user = useSuspense(User);

    return String(user.id || "0") === "0" ? null : user;
};
