/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import {
    Animated,
    NativeSyntheticEvent,
    NativeScrollEvent,
    ViewStyle,
    StyleProp,
    StyleSheet,
    Easing,
    Platform,
} from "react-native";

import { useHeaderHeight } from "@react-navigation/elements";

export type IProps = {
    height: number;
    initialThreshold?: number;
    Component: ({
        headerStyles,
        animationDone,
        scrolledUp,
        headerShown,
    }: {
        headerStyles: ViewStyle;
        animationDone: boolean;
        scrolledUp: boolean;
        headerShown: boolean;
    }) => React.ReactElement;
};

export type CollapsibleHeader = {
    onScroll: (event: NativeSyntheticEvent<NativeScrollEvent>, options: ViewStyle) => void;
    HeaderAnimated: React.ReactElement;
};

const ANIMATION_THRESHOLD = 10;
const INITIAL_ANIMATION_THRESHOLD = 50;

const useCollapsibleHeader = ({
    Component,
    height,
    initialThreshold = 5,
}: IProps): CollapsibleHeader => {
    const [headerStyles, setHeaderStyles] =
        React.useState<Animated.WithAnimatedValue<StyleProp<ViewStyle>>>();
    const [scrolledUp, setScrolledUp] = React.useState<boolean>(true);

    const scrolling = React.useRef(new Animated.Value(0)).current;
    const preHeaderHeight = useHeaderHeight();
    const preOffset = React.useRef(0);
    const isPreScrollDown = React.useRef(true);
    const [animationDone, setAnimationDone] = React.useState(false);

    const headerHeight = height ?? preHeaderHeight;

    React.useEffect(() => {
        if (headerHeight === 0) {
            return;
        }
        scrolling.addListener(({ value }) => {
            if (value > headerHeight + ANIMATION_THRESHOLD && !animationDone) {
                setAnimationDone(true);
            }
        });
    }, [headerHeight]);

    const initialCollapsAninationStyles = (): Animated.WithAnimatedValue<StyleProp<ViewStyle>> => {
        const progress = scrolling.interpolate({
            inputRange: [0, headerHeight],
            outputRange: [0, 1],
            extrapolate: "clamp",
        });

        const translateY = Animated.multiply(progress, -headerHeight);
        return { transform: [{ translateY }] };
    };

    const getCollapsedAnimationStyles = (): Animated.WithAnimatedValue<StyleProp<ViewStyle>> => {
        const animValue = new Animated.Value(0);

        Animated.timing(animValue, {
            toValue: -headerHeight,
            duration: 400,
            easing: Easing.bezier(0.25, 0.1, 0.25, 1),
            useNativeDriver: Platform.OS !== "web",
        }).start();

        return { transform: [{ translateY: animValue }] };
    };

    const getUnCollpasedAnimationstyles = (): Animated.WithAnimatedValue<StyleProp<ViewStyle>> => {
        const animValue = new Animated.Value(-headerHeight);
        Animated.timing(animValue, {
            duration: 200,
            toValue: 0,
            delay: 0,
            useNativeDriver: Platform.OS !== "web",
        }).start();
        return { transform: [{ translateY: animValue }], opacity: 1 };
    };

    const onScroll = React.useCallback(
        (event: NativeSyntheticEvent<NativeScrollEvent>, customHeaderStyles: ViewStyle) => {
            const offset = event.nativeEvent.contentOffset;

            const isMoving =
                Math.abs(Math.abs(offset.y) - Math.abs(preOffset.current)) > ANIMATION_THRESHOLD;
            const isScrollUp = offset.y > preOffset.current;

            const shouldAnimateOnScrollDown = !isScrollUp && !isPreScrollDown.current;
            const shouldAnimateOnScrollUp = isScrollUp && isPreScrollDown.current;

            setScrolledUp(isScrollUp || offset.y < initialThreshold);
            let animationStyles;
            if (offset.y < headerHeight + INITIAL_ANIMATION_THRESHOLD) {
                animationStyles = initialCollapsAninationStyles();
            } else {
                animationStyles = shouldAnimateOnScrollUp
                    ? getCollapsedAnimationStyles()
                    : shouldAnimateOnScrollDown
                    ? getUnCollpasedAnimationstyles()
                    : null;
            }

            const updatedHeaderStyles = {
                ...StyleSheet.flatten(customHeaderStyles),
                ...StyleSheet.flatten(animationStyles),
            };

            if (shouldAnimateOnScrollUp) {
                setHeaderStyles(updatedHeaderStyles);
                isPreScrollDown.current = false;
            }

            if (isMoving && shouldAnimateOnScrollDown) {
                setHeaderStyles(updatedHeaderStyles);
                isPreScrollDown.current = true;
            }

            isMoving ? (preOffset.current = offset.y) : null;
            scrolling.setValue(offset.y);
        },
        [scrolling, headerHeight, setHeaderStyles, isPreScrollDown.current, preOffset.current],
    );

    return {
        onScroll,
        HeaderAnimated: Component({
            headerStyles: headerStyles as ViewStyle,
            animationDone,
            scrolledUp,
            headerShown: isPreScrollDown.current,
        }),
    };
};

export { useCollapsibleHeader };
