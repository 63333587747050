import { ThemeRegistry, generateTheme, GenerateThemeParams } from "@swiggy-private/rn-dls";

const PRIMARY_COLOR = "#B52A6B";
const SECONDARY_COLOR = "#02060C";
const TERTIARY_COLOR = "#683896";
const CRITICAL_COLOR = "#C93220";
const HIGHLIGHT_COLOR = "#0A2EBD";
const WARNING_COLOR = "#FCCD16";
const POSITIVE_COLOR = "#4AA66A";

export const addTheme = (
    {
        primaryColor = PRIMARY_COLOR,
        secondaryColor = SECONDARY_COLOR,
        tertiaryColor = TERTIARY_COLOR,
        criticalColor = CRITICAL_COLOR,
        highlightColor = HIGHLIGHT_COLOR,
        warningColor = WARNING_COLOR,
        positiveColor = POSITIVE_COLOR,
    }: Partial<GenerateThemeParams> = {},
    key?: string,
): void => {
    // init theme registry
    ThemeRegistry.add(
        key ?? "light",
        generateTheme(
            {
                primaryColor: primaryColor ?? PRIMARY_COLOR,
                secondaryColor: secondaryColor ?? SECONDARY_COLOR,
                tertiaryColor: tertiaryColor ?? TERTIARY_COLOR,
                criticalColor: criticalColor ?? CRITICAL_COLOR,
                highlightColor: highlightColor ?? HIGHLIGHT_COLOR,
                warningColor: warningColor ?? WARNING_COLOR,
                positiveColor: positiveColor ?? POSITIVE_COLOR,
            },
            "light",
        ),
    );
};
