import { useContext } from "react";
import { NavigationContext } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { RouteList } from "@minis-consumer/interfaces/route";
import { useNavigationContext } from "@minis-consumer/components/product-cta/hoc/with-variant-form-calendar/context";

export const useCustomNavigation = (): NativeStackNavigationProp<RouteList> | null => {
    const navigation = useContext(NavigationContext);

    const contextNavigation = useNavigationContext();

    if (navigation) {
        return navigation as unknown as NativeStackNavigationProp<RouteList>;
    }

    return contextNavigation;
};
