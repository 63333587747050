import { Platform } from "react-native";

import { useIsDesktop } from "./use-desktop";

export const useIsDesktopWeb = (): boolean => {
    const isDesktop = useIsDesktop();

    const isDWeb = Platform.OS === "web" && isDesktop;

    return isDWeb;
};
