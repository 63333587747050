import * as React from "react";
import { StyleSheet } from "react-native";

import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue } from "@swiggy-private/rn-dls";
import { UploadedMediaFile } from "@swiggy-private/rn-image-picker";
import { StarRating } from "@swiggy-private/connect-business-commons";

import { useIsDesktopWeb } from "@minis-consumer/hooks/use-dweb";

import { Banner } from "../banner";
import { ImageUpload } from "../image-upload";
import { ReviewInput } from "../review-input";
import { DEFAULT_VALUES } from "../../constants";

interface FormBodyProps {
    ratingCount: number;
    review: string;
    uploadedMedia: UploadedMediaFile[];

    setUploadedMedia: (urls: UploadedMediaFile[]) => void;
    handleReviewChange: (s: string) => void;
    handleRatingChange: (index: number) => void;
    handleInputBlur: VoidFunction;
    handleImageUpload: VoidFunction;
}

const FormBodyComponent: React.FC<FormBodyProps> = ({
    ratingCount,
    review,
    uploadedMedia,
    handleReviewChange,
    handleRatingChange,
    setUploadedMedia,
    handleInputBlur,
    handleImageUpload,
}) => {
    const isDWeb = useIsDesktopWeb();
    const starIconSize = isDWeb
        ? DEFAULT_VALUES.LARGE_SCREEN_STAR_ICON_SIZE
        : DEFAULT_VALUES.SMALL_SCREEN_STAR_ICON_SIZE;

    const bannerStyle = {
        marginBottom: isDWeb ? SpacingValue["space-xx-large"] : SpacingValue["space-medium"],
    };

    const reviewInputStyle = {
        marginBottom: isDWeb ? SpacingValue["space-x-large"] : SpacingValue["space-large"],
    };

    return (
        <Stack direction="column">
            <Banner ratingCount={ratingCount} styles={bannerStyle} />
            <StarRating
                ratingCount={ratingCount}
                style={styles.ratings}
                showFilled
                onPress={handleRatingChange}
                starColor="color-basic-30"
                iconSize={starIconSize}
            />
            <ReviewInput
                ratingCount={ratingCount}
                style={reviewInputStyle}
                review={review}
                handleReviewChange={handleReviewChange}
                onBlur={handleInputBlur}
            />
            <ImageUpload
                uploadedMedia={uploadedMedia}
                setUploadedMedia={setUploadedMedia}
                handleImageUpload={handleImageUpload}
            />
        </Stack>
    );
};

export const FormBody = React.memo(FormBodyComponent);

const styles = StyleSheet.create({
    ratings: {
        marginBottom: 2 * SpacingValue["space-medium"],
    },
});
