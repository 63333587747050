import * as React from "react";
import { StyleSheet } from "react-native";

import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { StoreMedia } from "@swiggy-private/react-native-ui";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";
import { UploadedMediaFile } from "@swiggy-private/rn-image-picker";

import {
    DEFAULT_VALUES,
    TEST_IDS,
} from "@minis-consumer/components/rating-and-review-form/constants";
import { getMediaUrl } from "@minis-consumer/helpers/media";

interface UploadedImagesProps {
    uploadedMedia: UploadedMediaFile[];
    setUploadedMedia: (urls: UploadedMediaFile[]) => void;

    height?: number;
    width?: number;
}

export const UploadedImages: React.FC<UploadedImagesProps> = React.memo(
    ({
        uploadedMedia,
        setUploadedMedia,
        height = DEFAULT_VALUES.UPLOADED_IMAGE_SIZE,
        width = DEFAULT_VALUES.UPLOADED_IMAGE_SIZE,
    }) => {
        const { value: theme } = useTheme();

        const cancelImage = React.useCallback(
            (index: number) => {
                return () => {
                    const newArray = [...uploadedMedia];
                    newArray.splice(index, 1);
                    setUploadedMedia(newArray);
                };
            },
            [setUploadedMedia, uploadedMedia],
        );

        if (!uploadedMedia.length) {
            return null;
        }

        const shiftVal = DEFAULT_VALUES.CROSS_ICON_SIZE / 2;

        const cancelBtnStyle = {
            bottom: height - shiftVal,
            left: width - shiftVal,
        };

        const imageStyle = {
            borderColor: theme["color-basic-15"],
        };

        return (
            <Stack direction="row">
                {uploadedMedia.map((media, index) => (
                    <Box key={media.mediaId}>
                        <StoreMedia
                            mediaId={getMediaUrl(media.mediaId, { width, height }, true)}
                            width={width}
                            disableVideoPlay
                            height={height}
                            resizeMode="cover"
                            style={[styles.imageWrapper, imageStyle]}
                            testID={`media-${media}-${index + 1}`}
                        />
                        <SvgIcon
                            testID={TEST_IDS.CANCEL_IMAGE}
                            icon="CrossFilled"
                            height={DEFAULT_VALUES.CROSS_ICON_SIZE}
                            width={DEFAULT_VALUES.CROSS_ICON_SIZE}
                            onPress={cancelImage?.(index)}
                            color="color-secondary-400"
                            style={[cancelBtnStyle, styles.cancelBtn]}
                        />
                    </Box>
                ))}
            </Stack>
        );
    },
);

if (__DEV__) {
    UploadedImages.displayName = "UploadedImages";
}

const styles = StyleSheet.create({
    imageWrapper: {
        borderRadius: 8,
        marginRight: SpacingValue["space-medium"],
        borderWidth: 1,
    },
    cancelBtn: {
        position: "absolute",
    },
});
