import * as React from "react";
import { State } from "@rest-hooks/react";
import { LogBox } from "react-native";

import { assert } from "@swiggy-private/react-native-ui";
import { useMount } from "@swiggy-private/react-hooks";

import { SwiggyHeaders } from "@minis-consumer/interfaces/swiggy";

import { AppEventTypes } from "@minis-consumer/includes/app-event";
import { useLogout } from "@minis-consumer/hooks/use-logout";
import { useAppEvent } from "@minis-consumer/hooks/use-appevent";

import { initApiClient } from "./includes/api";
import { AppContainer } from "./containers/app-container";
import { StoreContainer } from "./containers/store-container";
import { getUrlSearchParams } from "./helpers/uri";
import { Analytics } from "./analytics";

__DEV__ && LogBox.ignoreLogs(["Setting a timer"]);

initApiClient();

interface AppProps {
    storeSlug: string;
    initialState?: State<unknown>;
    swiggyHeaders?: SwiggyHeaders;
    url?: string;
}

export const App: React.FC<AppProps> = ({ storeSlug, swiggyHeaders, url }) => {
    assert(storeSlug != null, "storeSlug is required");

    useAppEvent(AppEventTypes.LOGOUT, useLogout());

    useMount(() => {
        const params = getUrlSearchParams(location.href);
        Analytics.launchEvent({
            category: "app-launch",
            context: JSON.stringify(params),
        });
    });

    return (
        <React.StrictMode>
            <AppContainer headers={swiggyHeaders}>
                <StoreContainer slug={storeSlug} initialPath={url} />
            </AppContainer>
        </React.StrictMode>
    );
};

export { AppConfig, getConfig } from "./config";
export { initializePushNotification } from "./includes/push-notification";
export { initializeBlaze, recordAppLoadTime, BLAZE_EVENTS } from "./includes/blaze";
export { Logger } from "./includes/logger";
export { StoreDetail, StoreEntity } from "./resources/store";
export { CatalogView } from "./resources/catalog";
export { SSRContext } from "./contexts/ssr-context";
export { USER_DATA_KEYS, USER_DATA_COOKIE_KEY } from "./constants/cookie";
