import * as React from "react";
import {
    LayoutChangeEvent,
    Pressable,
    SafeAreaView,
    StyleSheet,
    TextInput,
    View,
} from "react-native";

import {
    getFocusedRouteNameFromRoute,
    NavigationState,
    StackActions,
    useNavigation,
    useNavigationState,
} from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { useDebounceFn, useMountedRef } from "@swiggy-private/react-hooks";
import { Box, Container, Stack } from "@swiggy-private/rn-adaptive-layout";
import { AppBar, Spacer, SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";

import { useAuthHandler } from "@minis-consumer/hooks/use-auth-modal-handler";
import { useLocalCart } from "@minis-consumer/hooks/use-cart";
import { useCartIcon } from "@minis-consumer/hooks/use-cart-icon";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { useUserInfo } from "@minis-consumer/hooks/use-user";
import type { HomeRouteList, RouteList } from "@minis-consumer/interfaces/route";
import { fireMetaPixelEvents } from "@minis-consumer/helpers/meta-pixel-events";
import { META_PIXEL_EVENT_NAMES } from "@minis-consumer/constants/meta-pixel-analytics";

import { Link } from "../link";
import { AccountDropDown } from "./components/account-dropdown";
import { CartItemCountBadge } from "./components/cart-item-count-badge";
import { SearchResult } from "./components/search-result";
import { NavbarProps } from "./types";

export const Navbar: React.FC<NavbarProps> = ({ style: propStyle, navRef, ...props }) => {
    const navigation = useNavigation<NativeStackNavigationProp<RouteList>>();

    const mountedRef = useMountedRef();
    const [searchText, setSearchText] = React.useState("");
    const inputRef = React.useRef<TextInput>(null);

    const { value: theme } = useTheme();
    const style = {
        backgroundColor: theme["color-background-primary"],
    };

    const storeInfo = useStoreInfo();
    const userInfo = useUserInfo();
    const signedIn = userInfo != null;

    const focusedRouteName = useNavigationState<
        RouteList,
        ReturnType<typeof getFocusedRouteNameFromState>
    >(getFocusedRouteNameFromState);

    const [, setIsAuthOpen] = useAuthHandler();
    const [isDropDownOpen, setIsDropDownOpen] = React.useState(false);
    const accountViewRef = React.useRef<View>(null);

    const [searchBarStart, setSearchBarStart] = React.useState(0);
    const [navbarHeight, setNavbarHeight] = React.useState(0);

    const onSearchBarLayout = React.useCallback((e: LayoutChangeEvent): void => {
        /** In web we get the position in e.nativeEvent.layout.left but left isn't a
        part of the LayoutRectangle interface, so it throws ts error. */

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setSearchBarStart(e.nativeEvent.layout.left);
        setNavbarHeight(e.nativeEvent.layout.height - SpacingValue["space-x-small"]);
    }, []);

    const deboucedSearch = useDebounceFn(
        React.useCallback((text: string) => {
            const trimmedText = (text || "").trim();
            if (trimmedText) {
                fireMetaPixelEvents(META_PIXEL_EVENT_NAMES.SEARCH, { content_name: trimmedText });
            }

            setSearchText(text);
        }, []),
        { wait: 300 },
    );

    const onAccountPress = React.useCallback(() => {
        if (!signedIn) {
            setIsAuthOpen(true);
        } else if (!isDropDownOpen) {
            setIsDropDownOpen(true);
        }
    }, [isDropDownOpen, setIsAuthOpen, signedIn]);

    const clearSearchText = React.useCallback(() => {
        if (mountedRef.current) {
            inputRef.current?.clear();
            setSearchText("");
        }
    }, [mountedRef]);

    const onProductPress = React.useCallback(
        (productId: string) => {
            clearSearchText();
            navigation.navigate("Product", {
                id: productId,
                slug: storeInfo?.slug,
            });
        },
        [clearSearchText, navigation, storeInfo?.slug],
    );

    const cartView = useLocalCart(storeInfo.storeId);
    const cartItemCount = cartView.items.reduce((sum, item) => {
        return sum + item.quantity;
    }, 0);

    const getCartIcon = useCartIcon(cartItemCount);

    return (
        <SafeAreaView style={style}>
            <AppBar {...props} style={StyleSheet.compose(style, propStyle)}>
                <Container fluid={false} style={styles.container}>
                    <Stack
                        flexGrow={1}
                        flexShrink={1}
                        direction="row"
                        spacing={SpacingValue["space-xx-large"]}>
                        <Link
                            to="/"
                            action={StackActions.push("Home", { screen: "Shop" })}
                            accessibilityRole="menuitem"
                            accessible
                            accessibilityLabel="Shop">
                            <AppBar.Action
                                title="Home"
                                active={focusedRouteName === "Shop"}
                                icon={
                                    focusedRouteName === "Shop"
                                        ? "ConsumerStoreFilled"
                                        : "ConsumerStoreOutline"
                                }
                            />
                        </Link>
                        <Link
                            to="/about"
                            action={StackActions.push("Home", { screen: "About" })}
                            accessibilityRole="menuitem"
                            accessible
                            accessibilityLabel="About">
                            <AppBar.Action
                                title="Gallery"
                                active={focusedRouteName === "About"}
                                icon={focusedRouteName === "About" ? "InfoFilled" : "Info"}
                            />
                        </Link>
                        <Link
                            to="/cart"
                            action={StackActions.push("Home", { screen: "Cart" })}
                            accessibilityRole="menuitem"
                            accessible
                            accessibilityLabel="Cart">
                            <AppBar.Action
                                title={(p) => (
                                    <>
                                        <Text {...p} />
                                        <CartItemCountBadge storeId={storeInfo.storeId} />
                                    </>
                                )}
                                active={focusedRouteName === "Cart"}
                                icon={getCartIcon(focusedRouteName === "Cart")}
                            />
                        </Link>

                        {storeInfo.enabled !== false ? (
                            <Box onLayout={onSearchBarLayout}>
                                <AppBar.Search
                                    placeholder={`Search in ${storeInfo.name}...`}
                                    onChangeText={deboucedSearch}
                                    accessoryRight={
                                        searchText ? (
                                            <Box justifyContent="center" alignItems="center">
                                                <Pressable
                                                    accessible
                                                    accessibilityLabel="Clear Search"
                                                    onPress={clearSearchText}>
                                                    <SvgIcon
                                                        icon="Close"
                                                        color="color-basic-60"
                                                        height={12}
                                                        width={12}
                                                    />
                                                </Pressable>
                                            </Box>
                                        ) : undefined
                                    }
                                    ref={inputRef}
                                />
                            </Box>
                        ) : null}
                        <Spacer />
                    </Stack>

                    <Stack
                        flexGrow={1}
                        direction="row"
                        spacing={SpacingValue["space-xx-large"]}
                        justifyContent="flex-end">
                        <Box collapsable={false} ref={accountViewRef}>
                            <AppBar.Action
                                title={signedIn ? userInfo?.name ?? "Account" : "Login"}
                                icon="Account"
                                onPress={onAccountPress}
                            />
                        </Box>
                    </Stack>
                </Container>
            </AppBar>

            <AccountDropDown
                anchorEl={accountViewRef}
                onClose={() => setIsDropDownOpen(false)}
                open={isDropDownOpen}
            />

            {searchText.trim().length && inputRef ? (
                <SearchResult
                    searchText={searchText.trim()}
                    left={searchBarStart}
                    top={navbarHeight}
                    onProductPress={onProductPress}
                    onOutsideClick={clearSearchText}
                    ref={inputRef}
                />
            ) : null}
        </SafeAreaView>
    );
};

const getFocusedRouteNameFromState = (
    state: NavigationState<RouteList>,
): keyof HomeRouteList | void => {
    // this state can happen for the initial route
    if (state.index == null && Array.isArray(state.routes) && state.routes.length === 1) {
        return getFocusedRouteNameFromRoute(state.routes[0]) as keyof HomeRouteList;
    }

    if (state.index != null && Array.isArray(state.routes)) {
        return getFocusedRouteNameFromRoute(state.routes[state.index]) as keyof HomeRouteList;
    }
};

const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
    },
    separator: {
        width: SpacingValue["space-xx-large"],
    },
});
