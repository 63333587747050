export const TOOLTIP_DEFAULT_VALUE = {
    KEY: "call-icon-tooltip",
    VALUE: false,
    START_TIME: 5000,
    DISMISS_AFTER: 6000,
    LIFETIME_DISPLAY_COUNT: 1,
    SESSION_DISPLAY_COUNT: 1,
    TOOLTIP_TEXT: "For urgent queries, consider reaching us directly over a call.",
};

export const ANALYTICS_COPIES = {
    CALL_SELLER_BTN_CATEGORY: "call-seller-btn",
    CALL_SELLER_POPUP_CATEGORY: "call-seller-popup",
    BLOCK_CHAT_CTA: "block-chat",
    UNBLOCK_CHAT_CTA: "unblock-chat",
};

export const COPIES = {
    TYPING: "Typing...",
    SHOP_DEFAULT_NAME: "Store",
};

export const MENU_COPIES = {
    block: "Block chat",
    unblock: "Unblock chat",
};
