import * as React from "react";
import { LayoutChangeEvent, PressableProps } from "react-native";
import { View, StyleSheet, TextStyle, ViewStyle } from "react-native";
import { NavigationState, useNavigationState } from "@react-navigation/native";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import type { HomeRouteList, RouteList } from "@minis-consumer/interfaces/route";
import { useIsNavbarVisible } from "@minis-consumer/hooks/use-is-navbar-visible";
import { useAuthHandler } from "@minis-consumer/hooks/use-auth-modal-handler";
import { Analytics } from "@minis-consumer/analytics";
import { useSignedIn } from "@minis-consumer/hooks/use-user";

import { Icon, SvgIcon } from "@swiggy-private/connect-svg-icons";
import { Box } from "@swiggy-private/rn-adaptive-layout";
import {
    SpacingValue,
    Surface,
    TouchableComponent,
    FalsyFC,
    FalsyText,
    useTheme,
} from "@swiggy-private/rn-dls";

interface INavbarItem {
    icon: Icon;
    showSeperator: boolean;
    title: string;
    isSelected: boolean;
    index: number;
    textStyle?: TextStyle;
    itemStyle?: ViewStyle;
    selectedStyle?: TextStyle;
    hideIcon?: boolean;
}

//TODO: Move to theme
const NAV_TEXT_COLOR = "#3E573A";
const NAV_BG_COLOR = "#F8F8FA";

const NavBarItem: React.FC<
    INavbarItem & Pick<PressableProps, "onPress" | "onPressOut" | "accessibilityLabel">
> = (props) => {
    const {
        icon,
        showSeperator,
        title,
        textStyle,
        isSelected,
        selectedStyle,
        itemStyle,
        hideIcon,
        ...otherProps
    } = props;

    const { value: theme } = useTheme();

    const textFinalStyle = StyleSheet.flatten<ViewStyle>([
        textStyle,
        { color: theme["color-basic-45"] },
        isSelected ? selectedStyle : undefined,
    ]);

    const svgColor = !isSelected ? theme["color-basic-45"] : NAV_TEXT_COLOR;
    const tabIndicatorStyle = {
        backgroundColor: NAV_TEXT_COLOR,
    };

    return (
        <>
            <TouchableComponent
                accessibilityRole="menuitem"
                accessible
                useDefaultHitSlop
                {...otherProps}>
                <Box direction="column" alignItems="center" pt={16}>
                    {icon && !hideIcon ? (
                        <FalsyFC
                            component={SvgIcon}
                            icon={icon}
                            height={16}
                            width={16}
                            color={svgColor}
                        />
                    ) : null}
                    <FalsyText component={title} style={textFinalStyle} />
                </Box>

                {isSelected ? <View style={[styles.tabIndicator, tabIndicatorStyle]} /> : null}
            </TouchableComponent>
            {showSeperator ? (
                <View style={[styles.separator, { backgroundColor: theme["color-basic-15"] }]} />
            ) : null}
        </>
    );
};

interface IProps {
    onLayout?: (e: LayoutChangeEvent) => void;
    headerStyles?: ViewStyle;
    customStyles?: ViewStyle;
    scrolledUp?: boolean;
    shownAsHeader?: boolean;
}
const StorefrontNavBarComponent: React.FC<IProps> = ({
    onLayout,
    headerStyles,
    scrolledUp,
    customStyles,
    shownAsHeader,
}) => {
    const showNavbar = useIsNavbarVisible();

    const isSignedIn = useSignedIn();
    const { value: theme } = useTheme();
    const [, authHandler] = useAuthHandler();
    const navigation = useNavigation<NativeStackNavigationProp<RouteList>>();

    const focusedRouteName = useNavigationState<
        RouteList,
        ReturnType<typeof getFocusedRouteNameFromState>
    >(getFocusedRouteNameFromState);

    const updatedHeaderStyles = {
        ...StyleSheet.flatten(customStyles),
        ...StyleSheet.flatten(headerStyles),
    };

    const hideHeaderStyles = scrolledUp ? { opacity: 0 } : { opacity: 1 };
    const backgroundStyle = {
        backgroundColor: shownAsHeader ? theme["color-basic-0"] : NAV_BG_COLOR,
    };

    const shadowStyle = shownAsHeader
        ? { shadowOpacity: 0.08, elevation: 5 }
        : { shadowOpacity: 0, elevation: 0 };

    const onAccountPress = React.useCallback((): void => {
        if (!isSignedIn && !showNavbar) {
            authHandler(true);
            return;
        }
        navigation.navigate("Home", { screen: "MyAccount" });
    }, [authHandler, isSignedIn, showNavbar, navigation]);

    const onAboutPress = React.useCallback((): void => {
        navigation.navigate("Home", { screen: "About", params: { defaultTab: "overview" } });
    }, [navigation]);

    const menuClick = React.useCallback((tabName: string): void => {
        Analytics.clickEvent({ category: `bottom-bar-${tabName}-icon` });
    }, []);

    return (
        <Surface style={[updatedHeaderStyles, hideHeaderStyles, shadowStyle]}>
            <Box direction="row" style={[styles.navbar, backgroundStyle]} onLayout={onLayout}>
                <NavBarItem
                    title="Mini Store"
                    showSeperator
                    accessibilityLabel="Shop"
                    textStyle={styles.textStyle}
                    selectedStyle={styles.selectedTextStyle}
                    itemStyle={styles.itemStyle}
                    onPressOut={() => menuClick("shop")}
                    index={0}
                    isSelected={focusedRouteName === "Shop"}
                    hideIcon={shownAsHeader}
                    icon={
                        focusedRouteName === "Shop" ? "ConsumerStoreFilled" : "ConsumerStoreOutline"
                    }
                />

                <NavBarItem
                    title="About"
                    showSeperator
                    onPress={onAboutPress}
                    onPressOut={() => menuClick("about")}
                    accessibilityLabel="About us"
                    index={1}
                    textStyle={styles.textStyle}
                    selectedStyle={styles.selectedTextStyle}
                    itemStyle={styles.itemStyle}
                    isSelected={focusedRouteName === "About"}
                    hideIcon={shownAsHeader}
                    icon={focusedRouteName === "About" ? "InfoFilled" : "Info"}
                />

                <NavBarItem
                    title="Your orders"
                    showSeperator={false}
                    onPress={onAccountPress}
                    onPressOut={() => menuClick("account")}
                    accessibilityLabel="Your orders"
                    index={2}
                    selectedStyle={styles.selectedTextStyle}
                    textStyle={styles.textStyle}
                    itemStyle={styles.itemStyle}
                    isSelected={focusedRouteName === "MyAccount"}
                    hideIcon={shownAsHeader}
                    icon={focusedRouteName === "MyAccount" ? "AccountFilled" : "Account"}
                />
            </Box>
        </Surface>
    );
};

export const StorefrontNavBar = React.memo(StorefrontNavBarComponent);

const styles = StyleSheet.create({
    navbar: {
        justifyContent: "space-evenly",
        alignItems: "center",
        overflow: "hidden",
    },
    itemStyle: {
        alignItems: "center",
    },
    textStyle: {
        fontSize: 14,
        paddingBottom: SpacingValue["space-medium"],
        paddingTop: 0,
        paddingHorizontal: SpacingValue["space-small"],
    },
    selectedTextStyle: {
        color: NAV_TEXT_COLOR,
        fontWeight: "500",
    },
    container: {
        alignItems: "center",
    },
    separator: {
        height: SpacingValue["space-xx-large"],
        width: 1,
    },
    tabIndicator: {
        height: 3,
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "100%",
        borderRadius: 4,
    },
});

const getFocusedRouteNameFromState = (
    state: NavigationState<RouteList>,
): keyof HomeRouteList | void => {
    // this state can happen for the initial route
    if (state.index == null && Array.isArray(state.routes) && state.routes.length === 1) {
        return state.routes[0].name as keyof HomeRouteList;
    }

    if (state.index != null && Array.isArray(state.routes)) {
        return state.routes[state.index].name as keyof HomeRouteList;
    }
};
