import React from "react";

import { SpacingValue, Text } from "@swiggy-private/rn-dls";
import { Box } from "@swiggy-private/rn-adaptive-layout";

const NoResultsFoundComponent: React.FC<{ searchText: string }> = ({ searchText }) => {
    return (
        <Box flex={1} alignItems="center" justifyContent="center">
            <Text category="b2">
                No match found for
                <Text category="b2" weight="bold">
                    {` ${searchText}`}
                </Text>
                .
            </Text>
            <Box mt={SpacingValue["space-x-large"]} alignItems="center">
                <Text category="b2">Still looking for this item?</Text>
                <Text category="b2">Chat with the seller</Text>
            </Box>
        </Box>
    );
};

export const SearchNoResultsFound = React.memo(NoResultsFoundComponent);
