import { ScrollView } from "react-native";

export const isDOM = typeof document !== "undefined";

interface FindAndScrollToElementParams {
    id: string;
    scrollView?: ScrollView | null;
    headerOffset?: number;
}

export const findAndScrollToElement = ({
    id,
    scrollView,
    headerOffset = 0,
}: FindAndScrollToElementParams): void => {
    if (!isDOM) {
        return;
    }

    const element = document.getElementById(id);
    if (element == null) {
        return;
    }

    if (scrollView == null) {
        element.scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
        return;
    }

    const parent = scrollView as unknown as HTMLDivElement;

    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + parent.scrollTop - headerOffset;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    parent.scrollTo({ x: 0, y: offsetPosition, animated: true });
};
