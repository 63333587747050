import * as Storage from "@swiggy-private/common-helpers";

import { CartState } from "../reducers/cart-reducer";

export class LocalCartManager {
    private _timeout = 0;
    private _localCartState: CartState = { carts: {} };

    private static _storageKey = "@minis_localCart";
    private static _instance: LocalCartManager | null = null;

    private constructor() {
        //
    }

    static getInstance(): LocalCartManager {
        if (LocalCartManager._instance == null) {
            LocalCartManager._instance = new LocalCartManager();
            return LocalCartManager._instance;
        }

        return LocalCartManager._instance;
    }

    save(state?: CartState): void {
        clearTimeout(this._timeout);

        this._timeout = setTimeout(() => {
            if (state == null) {
                Storage.removeValue(LocalCartManager._storageKey);
            } else {
                Storage.setValue(LocalCartManager._storageKey, state);
            }
        }, 0) as unknown as number;
    }

    clear(): void {
        clearTimeout(this._timeout);
    }

    static async load(): Promise<LocalCartManager> {
        const cartState = await Storage.getObjectValue<CartState>(LocalCartManager._storageKey);
        if (cartState) {
            LocalCartManager.getInstance()._localCartState = cartState;
        }

        return LocalCartManager.getInstance();
    }

    get cartState(): CartState {
        return this._localCartState;
    }
}
