import React from "react";
import { SafeAreaView, StyleSheet } from "react-native";
import { NavigationProp, useNavigation } from "@react-navigation/core";

import { useMount } from "@swiggy-private/react-hooks";
import { Stack, Box, useScreenSize, ScreenSize } from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { SpacingValue, useTheme, Button, Text } from "@swiggy-private/rn-dls";
import { ThreeDotsLoader } from "@swiggy-private/react-native-ui";

import { Analytics } from "@minis-consumer/analytics";
import { RouteList } from "@minis-consumer/interfaces/route";

import { OverlayLoader } from "@minis-consumer/components/overlay-loader";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { useCartViewData } from "@minis-consumer/hooks/use-cart";

interface DeliveryFormFooterProps {
    onSubmit: () => void;
    loading?: boolean;
    isDetailsFilled: boolean;
    isPhysicalCart?: boolean;
}

const DISPLAY_COPIES = {
    CONTINUE_PAYMENT_TITLE: "Continue to Payment",
    RETURN: "Return To Delivery",
};

export const DeliveryFormFooter: React.FC<DeliveryFormFooterProps> = ({
    onSubmit,
    loading,
    isDetailsFilled,
    isPhysicalCart,
}) => {
    const { value: theme } = useTheme();
    const storeInfo = useStoreInfo();

    const cart = useCartViewData(storeInfo.storeId);

    const screenSize = useScreenSize();
    const isLargeScreen = React.useMemo(() => screenSize === ScreenSize.Large, [screenSize]);
    const navigation = useNavigation<NavigationProp<RouteList, "GuestCheckout">>();

    useMount(() => {
        Analytics.impressionEvent({
            category: "continue-to-payment-btn",
            label: cart?.id || "-",
            context: JSON.stringify({
                cartId: cart?.id,
                cartType: cart?.cartType,
            }),
        });
    });

    const mainAreaStyles = {
        backgroundColor: theme["color-basic-5"],
    };

    const returnBtnStyles = {
        paddingLeft: SpacingValue["space-small"],
        color: theme["color-positive-500"],
    };

    const handleReturnClick = (): void => {
        if (navigation.canGoBack()) {
            navigation.goBack();
        }
    };

    const activePaymentButtonStyles = {
        backgroundColor: theme["color-positive-500"],
        borderColor: theme["color-positive-500"],
    };

    const isProceedDisabled = !cart?.bill?.items?.length;
    const containerJustifyContent = isLargeScreen
        ? !isPhysicalCart
            ? "flex-end"
            : "space-between"
        : "center";

    return (
        <SafeAreaView style={isLargeScreen ? mainAreaStyles : {}}>
            <Stack
                style={isLargeScreen ? undefined : styles.container}
                direction={isLargeScreen ? "row" : "column"}
                justifyContent={containerJustifyContent}>
                {isLargeScreen && isPhysicalCart ? (
                    <Stack direction="row" justifyContent="center" alignItems="center">
                        <SvgIcon
                            icon="ChevronLeft"
                            color={theme["color-primary-400"]}
                            width={12}
                            height={12}
                            onPress={handleReturnClick}
                        />
                        <Text style={returnBtnStyles} category="b1" weight="bold">
                            {DISPLAY_COPIES.RETURN}
                        </Text>
                    </Stack>
                ) : null}
                <Box pv={SpacingValue["space-small"]} justifyContent="flex-end">
                    <Button
                        onPress={onSubmit}
                        disabled={loading || !isDetailsFilled || isProceedDisabled}
                        style={isDetailsFilled ? activePaymentButtonStyles : {}}>
                        {loading ? <ThreeDotsLoader /> : DISPLAY_COPIES.CONTINUE_PAYMENT_TITLE}
                    </Button>
                </Box>
            </Stack>
            <OverlayLoader visible={!!loading} />
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: SpacingValue["space-medium"],
    },
});
