import * as React from "react";
import { ImageErrorEventData, NativeSyntheticEvent, StyleSheet, ViewProps } from "react-native";

import { InitiatedReviewMessage as InitiatedReviewMessageComponent } from "@swiggy-private/connect-business-commons";
import { InitiateReviewMessage as IInitiateReviewMessage } from "@swiggy-private/connect-chat-commons";
import { InView } from "@swiggy-private/react-native-ui";

import { Analytics } from "@minis-consumer/analytics";
import { Logger } from "@minis-consumer/includes/logger";

import { ANALYTICS_COPIES } from "../../constants";

const IMAGE_SIZE = 56;

interface InitiatedReviewMessageProps {
    message: IInitiateReviewMessage;
    conversationId: string;

    style?: ViewProps["style"];
}

const InitiatedReviewMessageComp: React.FC<InitiatedReviewMessageProps> = ({
    message,
    conversationId,
}) => {
    const { payload, type } = message;
    const { orderId, storeId } = payload;

    const analyticsContextData = JSON.stringify({
        orderId,
        storeId,
        messageType: type,
        conversationId,
    });

    const onError = React.useCallback(
        ({ nativeEvent: { error: err } }: NativeSyntheticEvent<ImageErrorEventData>) => {
            Logger.recordError(err);
        },
        [],
    );

    const onInitiatedReviewMsgImpression = React.useCallback(
        (isVisible: boolean) => {
            isVisible &&
                Analytics.impressionEvent({
                    category: ANALYTICS_COPIES.CHAT_MSGS,
                    context: analyticsContextData,
                });
        },
        [analyticsContextData],
    );

    return (
        <InView onChange={onInitiatedReviewMsgImpression} style={styles.msgWrapper}>
            <InitiatedReviewMessageComponent
                message={message}
                imageIds={payload.imageIds}
                onError={onError}
                imageSize={IMAGE_SIZE}
            />
        </InView>
    );
};

const styles = StyleSheet.create({
    msgWrapper: { width: "100%" },
});

export const InitiatedReviewMessage = React.memo(InitiatedReviewMessageComp);
