import React from "react";

import { UserAddress } from "@minis-consumer/interfaces/cart";
import { getFormattedAddress } from "@minis-consumer/helpers/address";

export const useCompleteAddress = (userAddress?: UserAddress | null): string => {
    const completeAddress = React.useMemo(
        () => (userAddress ? getFormattedAddress(userAddress) : ""),
        [userAddress],
    );

    return completeAddress;
};
