export const VARIANT_TEST_IDS = {
    VALUE_LABEL: "variants-value-label",
    OPTION_NAME: "variant-option--name",
    ON_OPTION_SELECTION: "variant-option-press",
    VALUE_LABEL_WITH_SIZE: "variants-value-label-with-size",
};

export const DOT_SEPERATOR = "•";

export const LABELS = {
    TITLE: "Your customisations",
    BUTTON_TITLE: "Add new customisation",
};
