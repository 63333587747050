export const SELLER_APP_LINKS = {
    ios: "https://apps.apple.com/in/app/sell-with-minis/id1617011298",
    android: "https://play.google.com/store/apps/details?id=store.mini.mobile&hl=en_IN&gl=US",
};

export const HEADER = {
    TITLE: "The platform of choice for Businesses & Creators",
    SUBTITLE_PREFIX: "Loved",
    SUBTITLE_SUFFIX: "by 65k+ entrepreneurs",
};

export const GRADIENT_COLORS = ["#F6E1E1", "#FFF5EF", "#FFF"];
export const GRADIENT_LOCATIONS_START = { x: 0, y: 1 };
export const GRADIENT_LOCATIONS_END = { x: 0, y: 0 };

export const CONTENT = {
    TITLE: "Get your mini.site today",
    SUBTITLE: "@ 0% commissions",
    LIST: [
        {
            title: "Enhanced Link-in-Bio",
            subtitle: "Instagram, whatsapp, youtube all in one",
        },
        {
            title: "Sell anything",
            subtitle: "Cakes, Consultations, T-Shirts, PDFs",
        },
        {
            title: "Increase Repeats",
            subtitle: "Engage using Swiggy App",
        },
    ],
};

export const CTA = {
    title: "Download app",
    subtitle: "& get started in minutes !",
};
