import React from "react";
import { StyleSheet } from "react-native";

import LinearGradient from "react-native-linear-gradient";

import { Text, useTheme } from "@swiggy-private/rn-dls";

import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";

import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { useCartViewData } from "@minis-consumer/hooks/use-cart";

const GuestCheckoutDeliveryFeeComponent: React.FC = () => {
    const { value: theme } = useTheme();
    const store = useStoreInfo();

    const cart = useCartViewData(store.storeId);
    const deliveryFee = cart?.bill?.deliveryFee;
    const finalAmount = deliveryFee?.finalValue ?? 0;

    const deliveryFeeStyles = {
        backgroundColor: theme["color-warning-light"],
        borderBottomColor: theme["color-warning-50"],
        borderBottomWidth: 1,
    };

    return (
        <>
            {finalAmount ? (
                <Box style={[styles.container, deliveryFeeStyles]}>
                    <Text category="b3">
                        Delivery charge of{" "}
                        <Text category="b3" weight="bold" color="color-warning-800">
                            ₹{finalAmount}
                        </Text>{" "}
                        added to total
                    </Text>
                </Box>
            ) : deliveryFee ? (
                <LinearGradient
                    colors={["#F9FBDD", "#BCECD9"]}
                    useAngle={true}
                    angle={96.12}
                    angleCenter={{ x: 0.5, y: 0.5 }}
                    locations={[-0.2889, 0.3784]}
                    style={styles.container}>
                    <Stack direction="row">
                        <Text category="b3" weight="bold" color="color-positive-800">
                            Yay!{" "}
                        </Text>
                        <Text category="b3">Enjoy </Text>
                        <Text category="b3" weight="bold" color="color-positive-800">
                            FREE{" "}
                        </Text>
                        <Text category="b3">delivery on this order!</Text>
                    </Stack>
                </LinearGradient>
            ) : null}
        </>
    );
};

export const GuestCheckoutDeliveryFee = React.memo(GuestCheckoutDeliveryFeeComponent);

const styles = StyleSheet.create({
    container: {
        height: 38,
        justifyContent: "center",
        alignItems: "center",
    },
});
