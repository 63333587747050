import type {
    ConsumerAddressResponse,
    CreateConsumerAddressApiParams,
} from "@swiggy-private/connect-address-ui";
import { UserAddress } from "@swiggy-private/connect-address-ui";
import { API_SUCCESS_STATUS_CODE, ApiError } from "@swiggy-private/connect-api-core";
import { Endpoint } from "@swiggy-private/http-client";

import { IAddressList } from "@minis-consumer/interfaces/address";

import { getApiClient } from "../../includes/api";
import { AddressEndpoint, AddressListEndpoint } from "../endpoint";

export const ADDRESS_API_ERRORS = {
    FETCH_ADDRESS_LIST: "Could not fetch the addresses",
};

export interface AddressListParams {
    storeId: string;
}

export const fetchUserAddresses = async ({ storeId }: AddressListParams): Promise<IAddressList> => {
    const endpoint = Endpoint.from(AddressListEndpoint, { storeId });
    const response = await getApiClient().get<UserAddress[]>({
        endpoint,
    });

    if (response.statusCode === API_SUCCESS_STATUS_CODE && response?.data) {
        return {
            addresses: response.data,
        };
    }

    return Promise.reject(
        new ApiError(response.statusMessage || ADDRESS_API_ERRORS.FETCH_ADDRESS_LIST, response),
    );
};

/**
 * The API to create a consumer address.
 *
 * @param params the api params
 * @returns {@link Promise} with value as {@link ConsumerAddressResponse}
 */
export const createAddressApi = async (
    params: CreateConsumerAddressApiParams,
): Promise<ConsumerAddressResponse> => {
    const endpoint = Endpoint.from(AddressEndpoint);
    const response = await getApiClient().post<ConsumerAddressResponse>({
        endpoint,
        body: { ...params.payload },
    });

    if (response.statusCode === API_SUCCESS_STATUS_CODE && response.data) {
        return response.data;
    }

    return Promise.reject(
        new ApiError(
            response.statusMessage ||
                "Sorry, unable to save the details. Please try again in a few minutes",
            response,
        ),
    );
};

/**
 * The API to create a consumer address.
 *
 * @returns {@link Promise} with value as {@link ConsumerAddressResponse[]}
 */
export const getAddressesApi = async (): Promise<ConsumerAddressResponse[]> => {
    const endpoint = Endpoint.from(AddressEndpoint);
    const response = await getApiClient().get<ConsumerAddressResponse[]>({ endpoint });

    if (response.statusCode === API_SUCCESS_STATUS_CODE && response.data) {
        return response.data;
    }

    return Promise.reject(
        new ApiError(
            response.statusMessage ||
                "Sorry, unable to fetch the details. Please try again in a few minutes",
            response,
        ),
    );
};
