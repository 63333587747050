import { useNavigation } from "@react-navigation/core";
import { useCallback } from "react";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { MinisNavigatorId, StoreNavigatorId } from "@minis-consumer/constants/navigation";

import { useMinisNavigation } from "./use-minis-navigation";
import { CommonRouteList, MinisRouteList, RouteList } from "@minis-consumer/interfaces/route";

type StoreNavigation = NativeStackNavigationProp<RouteList, keyof RouteList>;
type MinisNavigation = NativeStackNavigationProp<MinisRouteList, keyof MinisRouteList>;

export const useNavigationBack = (): (() => void) => {
    const navigation =
        useNavigation<NativeStackNavigationProp<CommonRouteList, keyof CommonRouteList, string>>();
    const minisNavigationCtx = useMinisNavigation();

    return useCallback(() => {
        if (navigation.canGoBack()) {
            navigation.goBack();
            return;
        }

        const storeNavigation: StoreNavigation | null =
            navigation.getId() === StoreNavigatorId
                ? (navigation as StoreNavigation)
                : (navigation.getParent(StoreNavigatorId) as StoreNavigation);

        if (storeNavigation != null) {
            if (storeNavigation.canGoBack()) {
                storeNavigation.goBack();
            } else {
                storeNavigation.reset({
                    index: 0,
                    routes: [{ name: "Home", key: "home" }],
                });
            }

            return;
        }

        const minisNavigation: MinisNavigation | null =
            navigation.getId() === MinisNavigatorId
                ? (navigation as MinisNavigation)
                : (navigation.getParent(MinisNavigatorId) as MinisNavigation);

        if (minisNavigation != null) {
            if (minisNavigation.canGoBack()) {
                minisNavigation.goBack();
            } else {
                minisNavigation.reset({
                    index: 0,
                    routes: [{ name: "MinisHome" }],
                });
            }

            return;
        }

        if (minisNavigationCtx != null) {
            if (minisNavigationCtx.canGoBack()) {
                minisNavigationCtx.goBack();
            } else {
                minisNavigationCtx.reset({
                    index: 0,
                    routes: [
                        {
                            name: "MinisHome",
                        },
                    ],
                });
            }
        }
    }, [minisNavigationCtx, navigation]);
};
