/* eslint-disable react-native/no-inline-styles */
import * as React from "react";
import {
    NativeStackHeaderProps,
    NativeStackNavigationOptions,
    NativeStackNavigationProp,
} from "@react-navigation/native-stack";
import {
    Animated,
    Platform,
    Pressable,
    PressableProps,
    StyleProp,
    StyleSheet,
    ViewStyle,
} from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { getDefaultHeaderHeight, getHeaderTitle, Header } from "@react-navigation/elements";
import memoizeOne from "memoize-one";
import type { NavigationProp, ParamListBase, RouteProp } from "@react-navigation/native";
import type {
    StackNavigationOptions,
    StackHeaderProps,
    StackHeaderStyleInterpolator,
    StackHeaderInterpolatedStyle,
} from "@react-navigation/stack";

import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { getScreenSize, ScreenSize } from "@swiggy-private/rn-adaptive-layout";

import { MinisRouteList, RouteList } from "@minis-consumer/interfaces/route";
import { MinisNavigatorId } from "@minis-consumer/constants/navigation";

type Callback<Routes extends ParamListBase> = (props: {
    route: RouteProp<Routes>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    navigation: any;
}) => NativeStackNavigationOptions;

const MinHeaderChar = 20;

const calcWidth = (screenSize: ScreenSize, headerTitle: string | React.ReactNode): string => {
    if (["sm", "xs"].includes(screenSize)) {
        if (typeof headerTitle === "string") {
            return headerTitle.length > MinHeaderChar ? "90%" : "100%";
        }
    }
    return "100%";
};

type Layout = {
    height: number;
    width: number;
};

const getInterpolatedStyle = memoizeOne(
    (
        styleInterpolator: StackHeaderStyleInterpolator,
        layout: Layout,
        current: Animated.AnimatedInterpolation<number>,
        next: Animated.AnimatedInterpolation<number> | undefined,
        titleLayout: Layout | undefined,
        leftLabelLayout: Layout | undefined,
        headerHeight: number,
        // eslint-disable-next-line max-params
    ) =>
        styleInterpolator({
            current: { progress: current },
            next: next && { progress: next },
            layouts: {
                header: {
                    height: headerHeight,
                    width: layout.width,
                },
                screen: layout,
                title: titleLayout,
                leftLabel: leftLabelLayout,
            },
        }),
);

export const useScreenOptions = <Route extends ParamListBase = RouteList>(
    defaultOptions: NativeStackNavigationOptions | StackNavigationOptions = {},
): Callback<Route> => {
    const { value: theme } = useTheme();
    const insets = useSafeAreaInsets();
    const screenSize = getScreenSize();

    const headerComponent = React.useCallback(
        (props: NativeStackHeaderProps) => {
            const { options, route, back } = props;

            const {
                headerTintColor,
                headerTitleAlign,
                headerTitleStyle,
                headerLeft,
                headerRight,
                headerTitle,
                headerStyle,
                headerShadowVisible,
                headerTransparent,
                headerBackground,
                headerBackVisible,
            } = options;

            const headerStatusBarHeight = headerTransparent ? 0 : insets.top;

            // this is available for web only
            const { styleInterpolator, progress, layout } = props as StackHeaderProps;
            let interpolatedStyles: StackHeaderInterpolatedStyle | null = null;

            if (styleInterpolator != null && progress && layout) {
                const headerHeight = getDefaultHeaderHeight(layout, false, headerStatusBarHeight);
                interpolatedStyles = getInterpolatedStyle(
                    styleInterpolator,
                    layout,
                    progress.current,
                    progress.next,
                    undefined,
                    undefined,
                    headerHeight,
                );
            }

            return (
                <Header
                    layout={layout}
                    title={getHeaderTitle(options, route.name)}
                    headerTintColor={headerTintColor}
                    headerTitleAlign={headerTitleAlign}
                    headerTitleStyle={[
                        headerTitleStyle,
                        { width: calcWidth(screenSize, headerTitle as string) },
                    ]}
                    headerLeft={({ tintColor }) =>
                        headerLeft?.({
                            tintColor,
                            canGoBack: back != null || headerBackVisible === true,
                        })
                    }
                    headerRight={({ tintColor }) =>
                        headerRight?.({
                            tintColor,
                            canGoBack: back != null || headerBackVisible === true,
                        })
                    }
                    headerTitle={
                        typeof headerTitle === "function"
                            ? ({ children, tintColor }) => headerTitle({ children, tintColor })
                            : headerTitle
                    }
                    headerStyle={[
                        headerTransparent
                            ? {
                                  backgroundColor: "transparent",
                              }
                            : null,
                        headerStyle,
                    ]}
                    headerStatusBarHeight={headerStatusBarHeight}
                    headerTransparent={headerTransparent}
                    headerShadowVisible={headerShadowVisible}
                    headerBackground={headerBackground}
                    headerTitleContainerStyle={interpolatedStyles?.titleStyle}
                    headerBackgroundContainerStyle={interpolatedStyles?.backgroundStyle}
                    headerRightContainerStyle={interpolatedStyles?.rightButtonStyle}
                    headerLeftContainerStyle={interpolatedStyles?.leftButtonStyle}
                />
            );
        },
        [insets.top, screenSize],
    );

    return React.useCallback(
        ({ navigation }) => {
            const headerBackgroundContainerStyle = {
                backgroundColor: theme["color-background-primary"].toString(),
                borderBottomWidth: StyleSheet.hairlineWidth,
                borderBottomColor: theme["color-basic-5"],
            };

            return {
                header: headerComponent,
                headerShown: false,
                headerTitleAllowFontScaling: false,
                headerLeft: (p) => (
                    <HeaderLeft
                        navigation={navigation}
                        color={p.tintColor}
                        canGoBack={p.canGoBack}
                    />
                ),
                headerTitleAlign: "left",

                headerTitleStyle: {
                    fontFamily: theme["text-body-1-bold-font-family"],
                    color: theme["color-basic-100"].toString(),
                    fontSize: theme["text-body-1-bold-font-size"],
                    lineHeight: theme["text-body-1-bold-line-height"],
                    letterSpacing: theme["text-body-1-bold-letter-spacing"],
                    marginTop: SpacingValue["space-xxx-small"],
                    fontWeight: "bold",
                },

                headerLeftContainerStyle: {
                    flexGrow: 0,
                },

                headerBackgroundContainerStyle,
                headerTintColor: theme["color-basic-100"].toString(),
                headerMode: "float",

                animation: Platform.OS === "web" ? "none" : "slide_from_right",
                orientation: "portrait",
                animationTypeForReplace: "push",
                animationEnabled: Platform.OS !== "web",
                detachPreviousScreen: Platform.OS === "web",

                ...defaultOptions,
            } as NativeStackNavigationOptions;
        },
        [defaultOptions, headerComponent, theme],
    );
};

const ICON_SIZE = 20;

type IconContainerProps = Omit<PressableProps, "style"> & {
    style?: Animated.WithAnimatedValue<StyleProp<ViewStyle>>;
};

const IconContainer: React.FC<React.PropsWithChildren<IconContainerProps>> = (props) => {
    const {
        accessible = true,
        accessibilityRole = "button",
        style,
        children,
        ...restProps
    } = props;

    return (
        <Pressable accessible={accessible} accessibilityRole={accessibilityRole} {...restProps}>
            <Animated.View style={[styles.iconContainer, style]}>{children}</Animated.View>
        </Pressable>
    );
};

interface HeaderLeftProps {
    navigation: NavigationProp<RouteList, keyof RouteList, string>;
    color?: string;
    containerStyle?: IconContainerProps["style"];
    canGoBack?: boolean;
}

type MinisNavigation = NativeStackNavigationProp<MinisRouteList, keyof MinisRouteList>;

const HeaderLeft: React.FC<HeaderLeftProps> = ({
    navigation,
    color,
    containerStyle,
    canGoBack,
}) => {
    const onBack = React.useCallback(() => {
        if (navigation.canGoBack()) {
            navigation.goBack();
            return;
        }

        const minisNavigation: MinisNavigation | null =
            navigation.getId() === MinisNavigatorId
                ? (navigation as MinisNavigation)
                : (navigation.getParent(MinisNavigatorId) as MinisNavigation);

        if (minisNavigation != null) {
            minisNavigation.reset({
                index: 0,
                routes: [{ name: "MinisHome" }],
            });
        }
    }, [navigation]);

    if (canGoBack || navigation.canGoBack()) {
        return (
            <IconContainer accessibilityLabel="Back" onPress={onBack} style={containerStyle}>
                <SvgIcon icon="ArrowLeft" color={color} width={ICON_SIZE} height={ICON_SIZE} />
            </IconContainer>
        );
    }

    return null;
};

const styles = StyleSheet.create({
    iconContainer: {
        paddingLeft: SpacingValue["space-x-small"],
        marginLeft: SpacingValue["space-x-small"],
        justifyContent: "center",
        alignItems: "center",
    },
});
