import * as React from "react";

import { StoreDeliveryInfoSnackbarContext } from "@minis-consumer/contexts/store-delivery-info-snackbar-context";
import { IDeliveryInfo } from "@minis-consumer/interfaces/delivery";

interface HandlerData {
    isOpen?: boolean;
    handler: (b: boolean, p?: string, i?: string) => void;
    deliveryInfo?: IDeliveryInfo;
    setDeliveryInfo?: (dI?: IDeliveryInfo) => void;
}

export const useStoreDeliveryInfoSnackbarHandler = (): HandlerData => {
    const ctx = React.useContext(StoreDeliveryInfoSnackbarContext);

    const handler = React.useCallback(
        (open: boolean) => (open ? ctx?.open() : ctx?.close()),
        [ctx],
    );

    return {
        isOpen: ctx?.isOpen ?? false,
        handler,
        deliveryInfo: ctx?.deliveryInfo,
        setDeliveryInfo: ctx?.setDeliveryInfo,
    };
};
