import React from "react";
import { Platform, useWindowDimensions } from "react-native";
import RenderHtml, { MixedStyleDeclaration } from "react-native-render-html";

import { SpacingValue, useTheme, FONT_REGULAR } from "@swiggy-private/rn-dls";

import { useLinking } from "@minis-consumer/hooks/use-linking";

interface RenderHTMLComponentProps {
    html: string;

    contentWidth?: number;
    h3TagStyle?: MixedStyleDeclaration;
    pTagStyle?: MixedStyleDeclaration;
    bodyStyle?: MixedStyleDeclaration;
    listParentTagStyle?: MixedStyleDeclaration;
    listItemTagStyle?: MixedStyleDeclaration;
    classesStyles?: Record<string, MixedStyleDeclaration>;
}

const isWeb = Platform.OS === "web";

const RenderHTMLComponent: React.FC<RenderHTMLComponentProps> = ({
    html,
    contentWidth,
    classesStyles,
    listItemTagStyle,
    bodyStyle,
}) => {
    const { width } = useWindowDimensions();

    const { value: theme } = useTheme();
    const openUrl = useLinking();

    if (!html) {
        return null;
    }

    const h3TagStyles: MixedStyleDeclaration = {
        marginTop: 0,
        marginBottom: SpacingValue["space-xx-small"],
        fontWeight: "700",
        lineHeight: theme["text-body-2-bold-line-height"],
        letterSpacing: theme["text-body-2-bold-letter-spacing"],
        fontSize: theme["text-body-2-bold-font-size"],
    };

    const pTagStyles: MixedStyleDeclaration = {
        marginVertical: 0,
    };

    const bodyStyles: MixedStyleDeclaration = {
        fontFamily: FONT_REGULAR,
        fontStyle: "normal",
        color: theme["color-basic-75"].toString(),
        fontWeight: "400",
        letterSpacing: theme["text-body-2-medium-letter-spacing"],
        lineHeight: theme["text-body-2-medium-line-height"],
        fontSize: theme["text-body-2-medium-font-size"],
        ...bodyStyle,
    };

    const listParentTagStyles: MixedStyleDeclaration = {
        marginVertical: 0,
        marginLeft: -14,
    };

    const listItemTagStyles: MixedStyleDeclaration = {
        flexWrap: isWeb ? "wrap" : undefined,
        ...listItemTagStyle,
    };

    const aItemTagStyles: MixedStyleDeclaration = {
        textDecorationLine: "underline",
        color: theme["text_Button_Secondary"],
        textDecorationColor: theme["text_Button_Secondary"],
    };

    return (
        <RenderHtml
            contentWidth={contentWidth ?? width}
            source={{ html }}
            tagsStyles={{
                h3: h3TagStyles,
                p: pTagStyles,
                ul: listParentTagStyles,
                ol: listParentTagStyles,
                li: listItemTagStyles,
                body: bodyStyles,
                a: aItemTagStyles,
            }}
            classesStyles={classesStyles}
            renderersProps={{
                a: {
                    onPress: (_, href) => {
                        openUrl(href);
                    },
                },
            }}
        />
    );
};

export const RenderHTMLCommon = React.memo(RenderHTMLComponent);
