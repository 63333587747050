import React from "react";
import { StyleSheet } from "react-native";
import { GestureResponderEvent, Pressable } from "react-native";

import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";

import { useLinking } from "@minis-consumer/hooks/use-linking";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";

const COPIES = {
    SUPPORT_TIMING: "Support Timings: ",
    FALLBACK_SUPPORT_TIME: "Monday to Saturday from 11am - 8pm.",
    CLOSE: "Close",
};

const DEFAULT_VALUE = {
    PHONE_ICON_SIZE: 18,
    CROSS_ICON_SIZE: 12,
};

export const ChatInfoComponent: React.FC<{
    showPopup?: boolean;
    closeInfoPopup?: VoidFunction;
}> = ({ showPopup = false, closeInfoPopup }) => {
    const { value: theme } = useTheme();
    const openUrl = useLinking();
    const storeInfo = useStoreInfo(false);

    const connectWithSeller = React.useCallback(() => {
        openUrl(`tel:${storeInfo?.settings.mobile}`);
    }, [openUrl, storeInfo?.settings.mobile]);

    const handleBoxPress = (event: GestureResponderEvent): void => {
        event.stopPropagation();
    };

    const popupStyles = {
        backgroundColor: theme["color-basic-0"],
    };

    if (!showPopup || !storeInfo?.settings?.mobile) {
        return null;
    }

    return (
        <Pressable onPress={closeInfoPopup} style={styles.popupWrapper}>
            <Pressable onPress={handleBoxPress}>
                <Box>
                    <Stack direction="column" style={[styles.popup, popupStyles]}>
                        <Text category="b1" color="color-basic-60">
                            {COPIES.SUPPORT_TIMING}
                            {storeInfo?.settings?.workingHours ?? COPIES.FALLBACK_SUPPORT_TIME}
                        </Text>
                        <Stack
                            direction="row"
                            style={styles.call}
                            spacing={SpacingValue["space-small"]}>
                            <SvgIcon
                                icon="Phone"
                                color="color-basic-75"
                                width={DEFAULT_VALUE.PHONE_ICON_SIZE}
                                height={DEFAULT_VALUE.PHONE_ICON_SIZE}
                            />
                            <Pressable onPress={connectWithSeller}>
                                <Text weight="medium" category="b1" color="color-basic-75">
                                    {storeInfo?.settings?.mobile}
                                </Text>
                            </Pressable>
                        </Stack>
                        <Box style={[styles.line, { borderColor: theme["color-basic-5"] }]} />
                        <Pressable onPress={closeInfoPopup}>
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="center"
                                spacing={SpacingValue["space-x-small"]}>
                                <SvgIcon
                                    icon="Close"
                                    color="color-basic-60"
                                    height={DEFAULT_VALUE.CROSS_ICON_SIZE}
                                    width={DEFAULT_VALUE.CROSS_ICON_SIZE}
                                />
                                <Text category="b1">{COPIES.CLOSE}</Text>
                            </Stack>
                        </Pressable>
                    </Stack>
                </Box>
            </Pressable>
        </Pressable>
    );
};

const styles = StyleSheet.create({
    popupWrapper: {
        position: "absolute",
        zIndex: 2,
        height: "100%",
        backgroundColor: "#00000066",
        display: "flex",
        flex: 1,
    },
    popup: {
        marginTop: SpacingValue["space-medium"],
        marginHorizontal: SpacingValue["space-medium"],
        padding: SpacingValue["space-medium"],
        borderRadius: 8,
        opacity: 1,
    },
    call: {
        paddingVertical: SpacingValue["space-medium"],
    },
    line: {
        borderWidth: StyleSheet.hairlineWidth,
        marginBottom: SpacingValue["space-small"],
    },
});
