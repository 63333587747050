/* eslint-disable no-bitwise */
// Function to calculate the relative luminance of a color
export const getLuminance = (color: string): number => {
    let r, g, b;
    if (color[0] === "#") {
        color = color.slice(1);
    }
    r = parseInt(color.substring(0, 2), 16) / 255;
    g = parseInt(color.substring(2, 4), 16) / 255;
    b = parseInt(color.substring(4, 6), 16) / 255;

    //WACG guidelines formula
    r = r <= 0.03928 ? r / 12.92 : Math.pow((r + 0.055) / 1.055, 2.4);
    g = g <= 0.03928 ? g / 12.92 : Math.pow((g + 0.055) / 1.055, 2.4);
    b = b <= 0.03928 ? b / 12.92 : Math.pow((b + 0.055) / 1.055, 2.4);

    return 0.2126 * r + 0.7152 * g + 0.0722 * b;
};

export const darkenColor = (color: string, amount: number): string => {
    let usePound = false;
    if (color[0] === "#") {
        color = color.slice(1);
        usePound = true;
    }

    const num = parseInt(color, 16);
    let r = (num >> 16) - amount;
    let b = ((num >> 8) & 0x00ff) - amount;
    let g = (num & 0x0000ff) - amount;

    if (r < 0) {
        r = 0;
    }
    if (b < 0) {
        b = 0;
    }
    if (g < 0) {
        g = 0;
    }

    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16).padStart(6, "0");
};

const getContrastRatio = (color: string, whiteLuminance = 1): number => {
    const luminance1 = getLuminance(color);
    return (
        (Math.max(luminance1, whiteLuminance) + 0.05) /
        (Math.min(luminance1, whiteLuminance) + 0.05)
    );
};

export const checkColor = (color: string): string => {
    const whiteLuminance = 1; // Luminance of white
    const contrastThreshold = 3; // WCAG recommended contrast ratio for text 14 and above
    let newColor = color;
    let constratRatio = getContrastRatio(newColor, whiteLuminance);

    while (constratRatio < contrastThreshold) {
        newColor = darkenColor(newColor, 10); // Adjust the amount to darken
        constratRatio = getContrastRatio(newColor, whiteLuminance);
    }

    return newColor;
};
