import * as React from "react";
import { Platform } from "react-native";

import { Provider as DlsProvider } from "@swiggy-private/rn-dls";

import { ChatProvider } from "@minis-consumer/contexts/chat-context";
import { CartCountContextProvider } from "@minis-consumer/contexts/abandoned-cart-context";
import { CartStateProvider } from "@minis-consumer/contexts/cart-context";
import { ToastProvider } from "@minis-consumer/contexts/toast-context";
import { getUserFromSession } from "@minis-consumer/helpers/user";
import { AuthModalContextProvider } from "@minis-consumer/contexts/auth-modal-context";
import { AppNudgeContextProvider } from "@minis-consumer/contexts/app-nudge-context";
import { useAppDispatch } from "@minis-consumer/hooks/use-app-dispatch";
import { SwiggyHeaders } from "@minis-consumer/interfaces/swiggy";
import { SelectedAddressInfoProvider } from "@minis-consumer/contexts/selected-address-context";
import { BottomBarScrollContextProvider } from "@minis-consumer/contexts/bottom-bar-scroll-context";
import { OnboardingStoriesContextProvider } from "@minis-consumer/contexts/onboarding-stories-context";
import { ClearCartModalContextProvider } from "@minis-consumer/contexts/clear-cart-modal-context";

import { AppStateContainer } from "./app-state-container";

const UpdatedUser: React.FC<React.PropsWithChildren<{ userFetched: boolean }>> = ({
    userFetched,
    children,
}) => {
    const appDispatch = useAppDispatch();
    React.useEffect(() => {
        if (!userFetched || Platform.OS !== "web") {
            return;
        }

        const updatedUser = getUserFromSession();
        appDispatch({
            type: "SET_USER_ACTION",
            payload: updatedUser,
        });
    }, [userFetched, appDispatch]);
    return <>{children}</>;
};

const AppContent: React.FC<
    React.PropsWithChildren<{ userFetched: boolean; headers?: SwiggyHeaders | null }>
> = ({ userFetched, headers, children }) => {
    const user = getUserFromSession();

    return (
        <AppStateContainer initialUser={user}>
            <UpdatedUser userFetched={userFetched}>
                <DlsProvider>
                    <ToastProvider>
                        <SelectedAddressInfoProvider headers={headers}>
                            <CartStateProvider>
                                <ChatProvider isUserFetched={userFetched}>
                                    <CartCountContextProvider>
                                        <ClearCartModalContextProvider>
                                            <BottomBarScrollContextProvider>
                                                <OnboardingStoriesContextProvider>
                                                    <AuthModalContextProvider>
                                                        <AppNudgeContextProvider>
                                                            {children}
                                                        </AppNudgeContextProvider>
                                                    </AuthModalContextProvider>
                                                </OnboardingStoriesContextProvider>
                                            </BottomBarScrollContextProvider>
                                        </ClearCartModalContextProvider>
                                    </CartCountContextProvider>
                                </ChatProvider>
                            </CartStateProvider>
                        </SelectedAddressInfoProvider>
                    </ToastProvider>
                </DlsProvider>
            </UpdatedUser>
        </AppStateContainer>
    );
};

export default AppContent;
