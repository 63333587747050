import { MinisCloudinary } from "@minis-consumer/constants/cloudinary";
import { CloudinaryContext, ICloudinaryContext } from "@swiggy-private/react-native-ui";
import * as React from "react";

const DEFAULT_CONTEXT_VALUE: ICloudinaryContext = {
    cloudName: MinisCloudinary.cloudName,
    baseUrl: MinisCloudinary.baseUrl,
};

export const MinisCloudinaryContext: React.FC<
    React.PropsWithChildren<Partial<ICloudinaryContext>>
> = ({ children, ...props }) => {
    const value = React.useMemo(
        () => ({
            ...DEFAULT_CONTEXT_VALUE,
            ...props,
        }),
        [props],
    );

    return <CloudinaryContext.Provider value={value}>{children}</CloudinaryContext.Provider>;
};
