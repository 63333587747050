/* eslint-disable react-native/no-inline-styles */
import * as React from "react";
import { Platform, Pressable, StyleSheet, TextStyle, ViewProps } from "react-native";
import { NavigationProp, useNavigation } from "@react-navigation/core";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { ProductShareMessage as IProductShareMessage } from "@swiggy-private/connect-chat-commons";
import { SdkConversation } from "@swiggy-private/connect-chat-sdk";
import { useMount } from "@swiggy-private/react-hooks";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { Button, SpacingValue, Text } from "@swiggy-private/rn-dls";
import { ConditionalJSX, IK_QUALITY_FORMAT, StoreMedia } from "@swiggy-private/react-native-ui";
// import { useChatSdk, useSendMessage } from "@swiggy-private/connect-chat-ui";
// import {
//     CustomMessageTypes,
//     AddedToCartMessagePayload,
// } from "@swiggy-private/connect-chat-commons";

import { Analytics } from "@minis-consumer/analytics";
import {
    META_PIXEL_CONTENT_CATEGORY,
    META_PIXEL_EVENT_NAMES,
} from "@minis-consumer/constants/meta-pixel-analytics";
import { getMediaUrl } from "@minis-consumer/helpers/media";
import { fireMetaPixelEvents } from "@minis-consumer/helpers/meta-pixel-events";
import { formatNumberWithIndianSystem } from "@minis-consumer/helpers/number";
import { useLocalCartItem, useLocalCart } from "@minis-consumer/hooks/use-cart";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { MinisRouteList, RouteList } from "@minis-consumer/interfaces/route";
import { getProductPath } from "@minis-consumer/helpers/path";
import { useClearCartModalHandler } from "@minis-consumer/hooks/use-clear-cart-modal-handler";
import { getCanAddOrBookProduct } from "@minis-consumer/helpers/can-add-or-book-product";
import { useGetPdpCtaNavigation } from "@minis-consumer/hooks/use-pdp-cta-navigation";
import { convertToReadableDateAndTime } from "@minis-consumer/helpers/date";

import { getCTAText } from "../../helpers/product-share";

const CUSTOM_ITEM_TAG = "Custom Products";
const IMAGE_OPTS = { width: 70, height: 84, qualityAuto: IK_QUALITY_FORMAT.GOOD, quality: 100 };

interface ProductShareMessageProps {
    message: IProductShareMessage;
    isPublisher: boolean;

    style?: ViewProps["style"];
    blocked?: SdkConversation["blocked"];
    deleted?: SdkConversation["deleted"];
    conversationMeta?: SdkConversation["meta"];
    conversationId?: string;
}

export const ProductShareMessage: React.FC<ProductShareMessageProps> = (props) => {
    const { conversationMeta, message } = props;
    const { payload } = message;

    const {
        id,
        name: title,
        storeId,
        discountPrice,
        discountPercentage,
        price,
        custom: isCustom,
        imageId,
        imageUrl: imgUrl,
        variantId,
        variantLabel,
        productType,
        suggestedTime,
        duration,
        digitalAssetType,
    } = payload;

    const storeInfo = useStoreInfo(false);
    const slug = storeInfo ? storeInfo.slug : conversationMeta?.storeSlug;

    const { dataHandler, modalViewHandler } = useClearCartModalHandler();
    const cart = useLocalCart(storeId);
    // const sdk = useChatSdk();
    // const sendMessage = useSendMessage(conversationId ?? "");

    const navigation = useNavigation<NavigationProp<RouteList & MinisRouteList>>();

    const { productSelectedData, resetProductSelectedData } =
        useGetPdpCtaNavigation({
            productId: id,
            storeId,
            storeSlug: slug,
            minisNavigation: navigation as NativeStackNavigationProp<RouteList>,
        }) || {};

    const [, addItemToCart, , isItemInCart] = useLocalCartItem({
        storeId: storeId ?? "0",
        itemId: id,
        custom: isCustom ?? false,
        /** Default type added: Update with chat changes */
        productType: productType ?? "PHYSICAL",
    });

    const imageUrl = imageId ? getMediaUrl(imageId, IMAGE_OPTS, true) : imgUrl;

    const itemsAddedInCart = isItemInCart(variantId);

    const analyticsContext = React.useMemo(() => {
        return {
            category: "custom-item-add-to-cart-btn",
            label: id,
            context: JSON.stringify({
                productId: id,
                productType,
                price,
                discountPrice,
                variantId,
            }),
        };
    }, [id, productType, price, discountPrice, variantId]);

    useMount(() => {
        if (price && !itemsAddedInCart) {
            Analytics.impressionEvent(analyticsContext);
        }
    });

    const getDurationText = (): string => {
        if (!duration) {
            return "";
        }
        return `${duration.value} ${duration.unit}`;
    };

    const addItem = React.useCallback(() => {
        const slot = productSelectedData?.slot
            ? productSelectedData.slot
            : suggestedTime
            ? { start: suggestedTime }
            : undefined;
        addItemToCart(variantId, { slot, itemForm: productSelectedData?.formData }, true);

        fireMetaPixelEvents(META_PIXEL_EVENT_NAMES.ADD_TO_CART, {
            content_name: title,
            value: String(discountPrice ?? price),
            content_category: META_PIXEL_CONTENT_CATEGORY.CHAT,
        });

        if (productType === "APPOINTMENT" && !suggestedTime) {
            navigation?.navigate("SlotSelection", {
                id,
                slug: slug ?? "",
                storeId,
                isCustom,
                isEditMode: true,
            });
            return;
        }

        goToCart();

        resetProductSelectedData?.();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, addItemToCart, variantId, title, discountPrice, price, analyticsContext]);

    const goToCart = React.useCallback(() => {
        if (storeInfo) {
            navigation?.navigate("Home", { screen: "Cart", params: {} });
            return;
        }

        if (slug) {
            navigation?.navigate("MiniStore", { slug, initialPath: "/cart" });
        }
    }, [navigation, slug, storeInfo]);

    const addItemCallback = (): void => {
        addItem();

        // if (!sdk) {
        //     return;
        // }
        // const confirmMessage = sdk.prepareMessage<AddedToCartMessagePayload>({
        //     type: CustomMessageTypes.ADDED_TO_CART,
        //     payload: { storeId: storeId ?? "0", productId: id, productName: title },
        // });

        // // sendMessage(confirmMessage);
    };

    const onCtaPress = (): void => {
        const { canAdd, prefixText } = getCanAddOrBookProduct({
            productId: id,
            price: price,
            productType: productType ?? "PHYSICAL",
            cartItems: cart.items ?? [],
        });

        Analytics.clickEvent(analyticsContext);

        if (!canAdd) {
            dataHandler({
                storeId,
                descriptionPrefix: prefixText ?? "",
                onClearCartCallback: addItemCallback,
            });

            modalViewHandler(true);
            return;
        }
        addItemCallback();
    };

    const goToPdp = React.useCallback(() => {
        if (storeInfo) {
            navigation.navigate("Product", {
                id,
                slug: storeInfo?.slug,
                variantId,
            });
            return;
        }

        if (slug) {
            const productPath = getProductPath(id, slug, variantId);

            navigation?.navigate("MiniStore", {
                slug,
                initialPath: productPath,
            });
        }
    }, [navigation, id, variantId, slug, storeInfo]);

    const strikedOffCta: TextStyle = {
        textDecorationLine: props.blocked || props.deleted ? "line-through" : "none",
    };

    const getProductInfo = (): React.ReactElement => {
        return (
            <>
                {suggestedTime ? (
                    <Text category="b3" color="color-basic-60">
                        {convertToReadableDateAndTime(suggestedTime)}

                        {duration ? (
                            <Text category="b2" color="color-basic-60">
                                {" (" + getDurationText() + ")"}
                            </Text>
                        ) : (
                            ""
                        )}
                    </Text>
                ) : null}
                {!suggestedTime && duration ? (
                    <Text category="b2" color="color-basic-60">
                        {getDurationText()}
                    </Text>
                ) : null}
                {digitalAssetType ? (
                    <Text category="b2" color="color-basic-60">
                        {digitalAssetType ?? "PDF"}
                    </Text>
                ) : null}
            </>
        );
    };

    if (!id || !title) {
        return null;
    }

    return (
        <Box style={styles.container}>
            <Stack style={styles.content}>
                <Pressable onPress={goToPdp} disabled={isCustom} style={styles.pressable}>
                    <Stack flexShrink={1}>
                        {isCustom ? (
                            <Text color="color-basic-60" style={styles.customItemTag}>
                                {CUSTOM_ITEM_TAG}
                            </Text>
                        ) : null}
                        <Text
                            category="b1"
                            color={isCustom ? "high" : "color-primary"}
                            weight="medium"
                            numberOfLines={2}>
                            {title}
                        </Text>
                        {variantLabel ? (
                            <Text category="b3" color="color-basic-60">
                                {variantLabel}
                            </Text>
                        ) : null}

                        <Stack>
                            {discountPrice && discountPercentage ? (
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    style={styles.discountPriceBox}>
                                    <Text category="b1" color="high" weight="bold">
                                        {formatNumberWithIndianSystem(discountPrice, {
                                            showRupeeSymbol: true,
                                        })}
                                    </Text>
                                    <Text category="b2" color="color-warning-800">
                                        {` (${discountPercentage}% Off)`}
                                    </Text>
                                </Stack>
                            ) : null}
                        </Stack>
                    </Stack>

                    <ConditionalJSX condition={!!imageUrl}>
                        <StoreMedia
                            onPress={goToPdp}
                            disableVideoPlay
                            mediaId={imageUrl || ""}
                            style={styles.image}
                        />
                    </ConditionalJSX>
                </Pressable>
                {getProductInfo()}
                {price && !itemsAddedInCart ? (
                    <Button
                        color="primary"
                        elevation={1}
                        style={[styles.addToCartBtn]}
                        textStyle={strikedOffCta}
                        onPress={!props.blocked && !props.deleted ? onCtaPress : undefined}>
                        {({ style, ...restProps }) => (
                            <Text weight="bold" style={[style, { fontSize: 18 }]} {...restProps}>
                                {getCTAText(productType ?? "PHYSICAL")}

                                {formatNumberWithIndianSystem(
                                    discountPrice ? discountPrice : price,
                                    {
                                        showRupeeSymbol: true,
                                    },
                                )}
                            </Text>
                        )}
                    </Button>
                ) : null}
                {itemsAddedInCart ? (
                    slug ? (
                        <Button
                            color="tertiary"
                            elevation={1}
                            style={styles.addToCartBtn}
                            onPress={goToCart}>
                            <>
                                <Text category="h5" weight="bold" color="color-primary-400">
                                    View Cart
                                </Text>
                            </>
                        </Button>
                    ) : (
                        <Text
                            category="b3"
                            weight="medium"
                            color="color-primary-700"
                            numberOfLines={1}
                            ellipsizeMode="tail">
                            Item added to cart
                        </Text>
                    )
                ) : null}
            </Stack>
        </Box>
    );
};

const styles = StyleSheet.create({
    container: {
        padding: SpacingValue["space-xxx-small"],
        flexDirection: "row",
        borderRadius: 12,
        borderWidth: 0,
        width: Platform.OS === "web" ? "100%" : "80%",
        minWidth: "70%",
        overflow: "hidden",
    },
    content: {
        flexShrink: 1,
        flexGrow: 1,
    },
    image: {
        width: 70,
        height: 84,
        borderRadius: 8,
        resizeMode: "cover",
    },
    addToCartBtn: {
        marginTop: SpacingValue["space-small"],
        borderRadius: 12,
    },
    discountPriceBox: {
        marginTop: SpacingValue["space-x-small"],
    },
    strikeThrough: {
        textDecorationLine: "line-through",
    },
    customItemTag: {
        fontSize: 13,
        lineHeight: 16,
        letterSpacing: -0.2,
        fontWeight: "400",
        marginBottom: SpacingValue["space-x-small"],
    },
    pressable: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
});
