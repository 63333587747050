import React, { useCallback, useState } from "react";
import { Pressable, StyleSheet, ViewProps } from "react-native";

import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { SpacingValue, Text } from "@swiggy-private/rn-dls";

interface ExpansionPanelProps extends ViewProps {
    collapsed?: boolean;
    title: string;
    subtitle?: string;
    style?: ViewProps["style"];
    onToggleExpand?: VoidFunction;
    canCollapse?: boolean;
}

export const ExpansionPanel: React.FC<ExpansionPanelProps> = ({
    collapsed = true,
    title,
    subtitle,
    children,
    testID,
    onToggleExpand,
    canCollapse = true,
    ...props
}) => {
    const [expanded, setExpanded] = useState(!collapsed);

    const toggleExpand = useCallback(() => {
        onToggleExpand?.();
        setExpanded((state) => !state);
    }, [onToggleExpand]);

    return (
        <Stack {...props}>
            <Pressable
                style={styles.header}
                onPress={canCollapse ? toggleExpand : undefined}
                accessibilityRole="button"
                testID={testID}>
                <Stack>
                    <Text category="s1" weight="bold" color="high">
                        {title}
                    </Text>
                    {subtitle ? (
                        <Text category="s3" color="color-basic-60">
                            {subtitle}
                        </Text>
                    ) : null}
                </Stack>
                {canCollapse ? (
                    <Stack style={styles.headerIcon}>
                        <SvgIcon
                            width={SpacingValue["space-x-large"]}
                            height={SpacingValue["space-x-large"]}
                            color="color-secondary"
                            icon={expanded ? "ChevronUp" : "ChevronDown"}
                        />
                    </Stack>
                ) : null}
            </Pressable>
            {expanded ? children : null}
        </Stack>
    );
};

const styles = StyleSheet.create({
    header: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    headerIcon: {
        width: SpacingValue["space-x-large"],
        height: SpacingValue["space-x-large"],
    },
});
