import { Dispatch, Reducer, ReducerAction, ReducerState, useReducer } from "react";

import { FollowersCountAction } from "./actions";

export type FollowersCountState = Record<string, { followersCount: number }>;

function followersCountReducer(
    state: Readonly<FollowersCountState>,
    action: Readonly<FollowersCountAction>,
): Readonly<FollowersCountState> {
    switch (action.type) {
        case "UPDATE_FOLLOWERS_COUNT_ACTION":
            const { storeId, followersCount } = action.payload;

            return {
                ...state,
                ...{
                    [storeId]: {
                        followersCount: followersCount,
                    },
                },
            };
    }
}

export function useFollowersCountReducer(
    initialState: FollowersCountState,
): [
    ReducerState<Reducer<FollowersCountState, FollowersCountAction>>,
    Dispatch<ReducerAction<Reducer<FollowersCountState, FollowersCountAction>>>,
] {
    return useReducer(followersCountReducer, initialState);
}
