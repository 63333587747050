import React from "react";
import { Pressable, StyleSheet } from "react-native";

import { useTheme } from "@swiggy-private/rn-dls";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";

interface IndicatorProps {
    totalImages: number;
    currentSlideIndex: number;
    scrollToNextSlide?: () => void;
    scrollToPreviousSlide?: () => void;
    imageSize: number;
}

const SlideArrows: React.FC<IndicatorProps> = ({
    totalImages,
    currentSlideIndex,
    scrollToNextSlide,
    scrollToPreviousSlide,
    imageSize,
}) => {
    const { value: theme } = useTheme();

    const containerBg = {
        backgroundColor: theme["color-basic-15"],
    };

    const arrowContainerTop = {
        top: imageSize / 2 - 16,
    };

    return (
        <>
            {currentSlideIndex !== 0 ? (
                <Pressable
                    style={[styles.leftContainer, styles.container, containerBg, arrowContainerTop]}
                    onPress={scrollToPreviousSlide}>
                    <SvgIcon icon="ArrowLeft" height={12} width={12} />
                </Pressable>
            ) : null}

            {currentSlideIndex !== totalImages - 1 ? (
                <Pressable
                    style={[
                        styles.rightContainer,
                        styles.container,
                        containerBg,
                        arrowContainerTop,
                    ]}
                    onPress={scrollToNextSlide}>
                    <SvgIcon icon="ArrowRight" height={12} width={12} />
                </Pressable>
            ) : null}
        </>
    );
};

export default SlideArrows;

const styles = StyleSheet.create({
    container: {
        zIndex: 2,
        position: "absolute",
        height: 32,
        width: 32,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        opacity: 0.9,
    },
    leftContainer: {
        left: 0,
        borderTopRightRadius: 16,
        borderBottomRightRadius: 16,
    },
    rightContainer: {
        right: 0,
        borderTopLeftRadius: 16,
        borderBottomLeftRadius: 16,
    },
});
