import React, { useMemo } from "react";
import { StyleSheet, Pressable } from "react-native";
import LinearGradient from "react-native-linear-gradient";

import { Stack, Box, useScreenSize, ScreenSize } from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { Text, SpacingValue, useTheme, ElevationValue, shadow } from "@swiggy-private/rn-dls";
import { CdnImage } from "@swiggy-private/react-native-ui";

import { useAuthHandler } from "@minis-consumer/hooks/use-auth-modal-handler";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { useCartViewData } from "@minis-consumer/hooks/use-cart";
import { useMount } from "@swiggy-private/react-hooks";
import { Analytics } from "@minis-consumer/analytics";

import {
    ASSETS,
    ASSETS_SIZE,
    BULLET_SIZE,
    DISPLAY_COPIES,
    GRADIENT_COLORS,
    GRADIENT_LOCATION,
    SHADOW_DEFAULT_COLOR,
    CTA_ARD_EVENT,
} from "./constants";

export const LoginWithSwiggy: React.FC = () => {
    const { value: theme } = useTheme();
    const screenSize = useScreenSize();
    const isLargeScreen = React.useMemo(() => screenSize === ScreenSize.Large, [screenSize]);

    const storeInfo = useStoreInfo();
    const cart = useCartViewData(storeInfo.storeId);

    const shadowDefault = shadow(1, 0.1, SHADOW_DEFAULT_COLOR);
    const shadowStyles = {
        ...shadowDefault,
        shadowRadius: 28,
        shadowOffset: {
            height: SpacingValue["space-x-large"],
            width: SpacingValue["space-small"],
        },
    };

    const smallScreenContainerStyle = {
        marginHorizontal: -SpacingValue["space-medium"],
        backgroundColor: theme["color-background-secondary"],
        borderRadius: 0,
    };

    const containerStyles = {
        backgroundColor: theme["color-basic-0"],
        paddingHorizontal: isLargeScreen
            ? SpacingValue["space-x-large"]
            : SpacingValue["space-medium"],
        paddingBottom: SpacingValue["space-x-large"],
        paddingTop: 0,
        borderRadius: 16,
    };

    const loginButtonTheme = useMemo(
        () => ({
            color: theme["color-basic-0"],
            backgroundColor: SHADOW_DEFAULT_COLOR,
        }),
        [theme],
    );

    const loginButtonStyles = isLargeScreen
        ? [styles.loginButton, { minWidth: 310, ...ElevationValue[3] }]
        : [styles.loginButton, { marginTop: SpacingValue["space-medium"] }];

    const bulletStyles = {
        width: isLargeScreen ? "65%" : "60%",
    };

    const analyticsData = useMemo(() => {
        return {
            category: CTA_ARD_EVENT,
            context: JSON.stringify({
                cartId: cart?.id,
                cartType: cart?.cartType,
            }),
        };
    }, [cart?.id, cart?.cartType]);

    const [, setIsAuthOpen] = useAuthHandler();

    const onPress = React.useCallback(() => {
        Analytics.clickEvent(analyticsData);

        setIsAuthOpen(true, { redirectPath: "cart" });
    }, [setIsAuthOpen, analyticsData]);

    useMount(() => {
        Analytics.impressionEvent(analyticsData);
    });

    const pressableStyles = [loginButtonTheme, loginButtonStyles, shadowStyles];

    return (
        <Stack
            direction={"column"}
            style={[containerStyles, !isLargeScreen ? smallScreenContainerStyle : undefined]}
            justifyContent={isLargeScreen ? "space-between" : "center"}
            spacing={SpacingValue["space-x-large"]}>
            <CdnImage id={ASSETS.STICKER} style={styles.sticker} />

            <Box style={styles.box}>
                <Stack
                    direction={"column"}
                    justifyContent={isLargeScreen ? "center" : "space-between"}
                    spacing={SpacingValue["space-x-large"]}>
                    <CdnImage id={ASSETS.MINIS_LOGO} style={styles.minisLogo} />

                    <Stack spacing={SpacingValue["space-xx-small"]} direction="row">
                        <Text category="b1" weight="bold" color="high">
                            {DISPLAY_COPIES.PLACE_ORDER}
                        </Text>

                        <CdnImage id={ASSETS.SWIGGY_LOGO_TEXT} style={styles.swiggyLogo} />

                        <Text category="b1" weight="bold" color="high">
                            {DISPLAY_COPIES.PLACE_ORDER_SUBTEXT}
                        </Text>
                    </Stack>

                    <Stack spacing={SpacingValue["space-x-large"]}>
                        {DISPLAY_COPIES.BULLETS.map((bullet) => (
                            <Stack
                                direction="row"
                                spacing={SpacingValue["space-medium"]}
                                alignItems="center"
                                key={bullet.text}>
                                <LinearGradient
                                    colors={GRADIENT_COLORS}
                                    locations={GRADIENT_LOCATION}
                                    style={styles.iconGradient}>
                                    <SvgIcon
                                        icon={bullet.icon}
                                        color="color-basic-0"
                                        height={BULLET_SIZE / 2}
                                        width={BULLET_SIZE / 2}
                                    />
                                </LinearGradient>

                                <Text category="b1" weight="bold" color="high" style={bulletStyles}>
                                    {bullet.text}
                                </Text>
                            </Stack>
                        ))}
                    </Stack>

                    <Pressable style={pressableStyles} onPress={onPress}>
                        <SvgIcon icon="Swiggy" {...ASSETS_SIZE.SWIGGY_LOGO} color="color-basic-0" />

                        <Text
                            category="btn2"
                            weight="bold"
                            color="color-basic-0"
                            style={styles.loginText}>
                            {DISPLAY_COPIES.LOGIN_WITH_SWIGGY}
                        </Text>
                    </Pressable>
                </Stack>
            </Box>

            <CdnImage id={ASSETS.BACKGROUND} style={styles.backgroundImage} />
        </Stack>
    );
};

const styles = StyleSheet.create({
    loginButton: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
        height: 52,
        width: "100%",
        borderRadius: SpacingValue["space-small"],
        padding: SpacingValue["space-medium"],
    },
    separator: {
        width: "30%",
        opacity: 0.1,
        marginTop: SpacingValue["space-x-small"],
    },
    loginText: {
        paddingLeft: SpacingValue["space-x-small"],
    },
    iconGradient: {
        alignItems: "center",
        justifyContent: "center",
        height: BULLET_SIZE,
        width: BULLET_SIZE,
        borderRadius: BULLET_SIZE / 2,
    },
    sticker: {
        position: "absolute",
        right: 0,
        top: 0,
        ...ASSETS_SIZE.STICKER,
    },
    box: {
        zIndex: 2,
    },
    minisLogo: {
        ...ASSETS_SIZE.MINIS_LOGO,
    },
    swiggyLogo: {
        ...ASSETS_SIZE.SWIGGY_LOGO_TEXT,
    },
    backgroundImage: {
        position: "absolute",
        right: 0,
        zIndex: 1,
        bottom: SpacingValue["space-x-large"],
        ...ASSETS_SIZE.BACKGROUND,
    },
});
