import { useCallback } from "react";
import { useController } from "@rest-hooks/react";

import { AppEvent, AppEventTypes } from "@minis-consumer/includes/app-event";
import { useOnReloadWeb } from "@minis-consumer/hooks/use-reload-web";
import { removeConfigInfo } from "@minis-consumer/helpers/config-info";

import { Session } from "../includes/session";
import { useAppDispatch } from "./use-app-dispatch";
import { useStoreInfo } from "./use-store";

export const useLogout = (): (() => void) => {
    const { resetEntireStore } = useController();
    const appDispatch = useAppDispatch();
    const store = useStoreInfo(false);
    const { onReload: resetWebState } = useOnReloadWeb();

    return useCallback(() => {
        const session = Session.getInstance();
        session.clear();

        appDispatch({
            type: "SET_USER_ACTION",
            payload: null,
        });

        resetEntireStore();
        resetWebState(store?.slug);

        AppEvent.dispatch(AppEventTypes.SESSION_EXPIRED);

        removeConfigInfo();
    }, [appDispatch, resetEntireStore, resetWebState, store?.slug]);
};
