import { REGEX } from "@minis-consumer/constants/regex";

export const VALID_MOBILE_NUMBER_LENGTH = 10;
export const VALID_PINCODE_LENGTH = 6;

/**
 * `isNumber` determines if the variable is a number.
 * For reference - {@link [link](https://github.com/jonschlinkert/is-number/blob/master/index.js)}
 *
 * @param num any variable to be tested for number
 * @returns boolean
 */
export const isNumber = (num: unknown): boolean => {
    if (typeof num === "number") {
        return num - num === 0;
    }
    if (typeof num === "string" && num.trim() !== "") {
        return Number.isFinite ? Number.isFinite(+num) : isFinite(+num);
    }
    return false;
};

/**
 * `getNumber` returns the number from the variable.
 * Uses {@link isNumber} to determine if the variable is a valid number
 *
 * @param text any variable to be converted to number
 * @returns number | false
 */
export const getNumber = (num: unknown): number | false => (isNumber(num) ? Number(num) : false);

type FormatNumberWithIndianSystemOptions = {
    showRupeeSymbol?: boolean;
};

/**
 * `formatNumberWithIndianSystem` formats a given number with indian number system.
 * Additionally, it takes the following options -
 *
 *  - `showRupeeSymbol` - Weather to prepend the rupee symbol
 */
export const formatNumberWithIndianSystem = (
    num: number,
    options?: FormatNumberWithIndianSystemOptions,
): string => {
    const [integer, decimal] = Math.abs(num).toString().split(".");

    const formattedInteger = integer.replace(/(\d)(?=(\d\d)+\d$)/g, "$1,");
    const formattedDecimal = decimal ? "." + (decimal + "00").slice(0, 2) : "";
    const symbol = options?.showRupeeSymbol ? "₹" : "";
    const sign = num >= 0 ? "" : "-";

    return `${sign}${symbol}${formattedInteger}${formattedDecimal}`;
};

/**
 * `getNumberInShorthandFormat` formats a given number into shorthand.
 *
 * @param num number to be formatted
 * @returns string
 */
export const getNumberInShorthandFormat = (n: unknown): string => {
    const num = getNumber(n);

    if (!num) {
        return "0";
    }

    if (num < 0) {
        return `-${getNumberInShorthandFormat(-1 * num)}`;
    }

    if (num < 1000) {
        return num.toString();
    }

    if (num < 1000000) {
        return `${(num / 1000).toFixed(1)}K`;
    }

    if (num < 1000000000) {
        return `${(num / 1000000).toFixed(1)}M`;
    }

    return `${(num / 1000000000).toFixed(1)}B`;
};

/**
 * Checks if the mobile number is valid or not.
 *
 * @param number the mobile number
 * @returns true if mobile number is valid otherwise false
 */
export const isValidMobileNumber = (number: string): boolean => {
    return number.length === VALID_MOBILE_NUMBER_LENGTH && REGEX.PHONE_NUMBER.test(number);
};

/**
 * Checks if the pincode is valid or not.
 *
 * @param number the pincode
 * @returns true if pincode is valid otherwise false
 */
export const isValidPincode = (number: string): boolean => {
    return number.length === VALID_PINCODE_LENGTH && REGEX.PINCODE.test(number);
};
