import { Platform } from "react-native";
import { getScreenSize } from "@swiggy-private/rn-adaptive-layout";

// Mini Character to Limit-upto
const MinHeaderChar: Record<string, number> = { xs: 20, sm: 25, md: 30 };

// Making the Title short as per the Platform
export const compactTitle = (title: string): string => {
    const getSize = getScreenSize();
    if (Platform.OS !== "web") {
        if (title.length > MinHeaderChar[getSize]) {
            return title.slice(0, MinHeaderChar[getSize]) + "...";
        }
    }
    return title;
};
