import React, { useState } from "react";
import {
    Pressable,
    Modal,
    View,
    SafeAreaView,
    StyleSheet,
    Image,
    Platform,
    ViewStyle,
    StyleProp,
} from "react-native";

import { Header } from "@react-navigation/elements";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { unique } from "@minis-consumer/helpers/array";
import { MediaUploadModal } from "@minis-consumer/components/media-upload-modal";
import { useLinking } from "@minis-consumer/hooks/use-linking";
import { downloadAssetApi } from "@minis-consumer/api/media";
import { Analytics } from "@minis-consumer/analytics";

import { SpacingValue, Text, Button, useTheme } from "@swiggy-private/rn-dls";
import { UploadedMediaFile, MEDIA_SOURCE_DATA, MediaFile } from "@swiggy-private/rn-image-picker";
import { Box } from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";

interface IUploadFileComponentProps {
    onUpload: (media: string[]) => void;
    mediaId?: string;
    disabled?: boolean;
    customStyles?: StyleProp<ViewStyle>;
}

const CHIP_DEFAULT_COLOR = "#FF5200";
const MEDIA_TEXT_COLOR = "#3B58CA";
const DEFAULT_COLOR = "rgb(2, 6, 12)";
const DELETE_FILE_EVENT = "delete-file";
const UPLOAD_FILE_EVENT = "upload-file";

//check if file is image
export const isImage = (mediaId: string): boolean => {
    return (mediaId || "").startsWith("IMAGE/");
};

const UploadFileComponent: React.FC<IUploadFileComponentProps> = ({
    onUpload,
    mediaId,
    disabled,
    customStyles,
}: IUploadFileComponentProps) => {
    const [showFileUpload, setShowFileUpload] = React.useState(false);
    const [selectedMedia, setSelectedMedia] = useState<string[]>(mediaId ? [mediaId] : []);
    const [showMediaPreviewScreen, setShowMediaPreviewScreen] = useState(false);
    const [previewMediaUrl, setPreviewMediaUrl] = React.useState("");
    const [webUploadedFiles, setWebUploadedFiles] = React.useState<MediaFile[]>([]);
    const { value: theme } = useTheme();

    const insets = useSafeAreaInsets();
    const openUrl = useLinking();

    const fileInputButton = React.useRef<HTMLInputElement>(null);

    const closeMediaUpload = React.useCallback((media?: UploadedMediaFile[]): void => {
        if (!Array.isArray(media) || !media.length) {
            return;
        }
        let mediasToConsider = media;
        if (mediasToConsider.length > 1) {
            mediasToConsider = mediasToConsider.slice(0, 1);
        }
        const mediaIds = unique(mediasToConsider.map((m) => m.mediaId));
        setSelectedMedia(mediaIds);
        onUpload(mediaIds);
    }, []);

    const deleteFile = (): void => {
        Analytics.clickEvent({ category: DELETE_FILE_EVENT });

        setSelectedMedia([]);
        onUpload([]);
    };

    const showPreviewScreen = async (): Promise<void> => {
        if (selectedMedia.length === 0) {
            return;
        }
        const downloadFileData = await downloadAssetApi({
            mediaId: selectedMedia[0],
            source: "PRIVATE_ASSET",
        });
        if (!downloadFileData.url) {
            return;
        }
        setPreviewMediaUrl(downloadFileData.url);
        if (isImage(selectedMedia[0])) {
            setShowMediaPreviewScreen(true);
        } else {
            if (downloadFileData.url) {
                openUrl(downloadFileData.url);
            }
        }
    };

    const hidPreviewScreen = (): void => {
        setShowMediaPreviewScreen(false);
    };

    const onUploadPressHandler = (): void => {
        Analytics.clickEvent({ category: UPLOAD_FILE_EVENT });

        //to reset state
        setShowFileUpload(false);
        setTimeout(() => {
            setShowFileUpload(true);
        });
    };

    const handleFileChange = (event: any): void => {
        const file = event.target.files[0];
        if (file) {
            file.path = URL.createObjectURL(file);
            file.contentType = file.type;
            file.fileSize = file.size;
            setWebUploadedFiles([file]);
            setShowFileUpload(true);
        }
    };

    const renderButton = (): React.ReactElement => {
        return Platform.OS === "web" ? (
            <Box>
                <Button
                    style={styles.button}
                    elevation={1}
                    onPress={() => fileInputButton.current?.click()}>
                    Upload File
                </Button>
                <input
                    id="fileInput"
                    ref={fileInputButton}
                    type="file"
                    accept="image/*, application/pdf"
                    style={styles.fileInput}
                    onChange={handleFileChange}
                />
            </Box>
        ) : (
            <Button style={styles.button} elevation={1} onPress={onUploadPressHandler}>
                Upload File
            </Button>
        );
    };

    const mediaSourceData =
        Platform.OS === "android"
            ? MEDIA_SOURCE_DATA.slice(1)
            : Platform.OS === "ios"
            ? MEDIA_SOURCE_DATA
            : undefined;

    return (
        <Box style={customStyles}>
            {selectedMedia.length > 0 ? (
                <Box direction="row">
                    <Text category="b2" weight="medium" style={styles.image}>
                        {selectedMedia[0].slice(0, 5)}
                    </Text>

                    <Pressable onPress={showPreviewScreen}>
                        <Text category="b2" weight="medium" style={styles.chip}>
                            View
                        </Text>
                    </Pressable>
                    {!disabled ? (
                        <Pressable onPress={deleteFile}>
                            <Text category="b2" weight="medium" style={styles.chip}>
                                Delete
                            </Text>
                        </Pressable>
                    ) : null}
                </Box>
            ) : !disabled ? (
                renderButton()
            ) : null}

            {showFileUpload ? (
                <MediaUploadModal
                    onUpload={closeMediaUpload}
                    limit={1}
                    mediaSource="PRIVATE_ASSET"
                    sourceData={mediaSourceData}
                    webUploadedFiles={webUploadedFiles}
                />
            ) : null}
            {showMediaPreviewScreen ? (
                <Modal
                    onRequestClose={hidPreviewScreen}
                    onDismiss={hidPreviewScreen}
                    animationType="slide"
                    visible
                    statusBarTranslucent={false}
                    presentationStyle="overFullScreen"
                    style={[styles.container]}>
                    <View style={styles.container}>
                        <Header
                            headerStatusBarHeight={insets.top}
                            headerTitleStyle={{ color: theme["color-basic-0"] }}
                            headerStyle={{ backgroundColor: DEFAULT_COLOR }}
                            title="View Media"
                            headerLeft={() => {
                                return (
                                    <SvgIcon
                                        icon="ArrowLeft"
                                        onPress={hidPreviewScreen}
                                        style={{
                                            marginHorizontal: SpacingValue["space-medium"],
                                            marginLeft: SpacingValue["space-medium"],
                                        }}
                                        color="color-basic-0"
                                    />
                                );
                            }}
                            modal
                        />
                        <SafeAreaView style={[styles.container]}>
                            <View style={[styles.mediaContainter]}>
                                <Image
                                    source={{ uri: previewMediaUrl }}
                                    style={styles.media}
                                    resizeMode="contain"
                                />
                            </View>
                        </SafeAreaView>
                    </View>
                </Modal>
            ) : null}
        </Box>
    );
};

export const UploadFile = React.memo(UploadFileComponent);

const styles = StyleSheet.create({
    input: {
        marginTop: SpacingValue["space-small"],
        marginBottom: SpacingValue["space-x-large"],
    },
    button: {
        border: 0,
        alignSelf: "flex-start",
        minHeight: "auto",
        height: 38,
    },
    chip: {
        color: CHIP_DEFAULT_COLOR,
        marginLeft: SpacingValue["space-small"],
    },
    image: {
        color: MEDIA_TEXT_COLOR,
    },
    container: {
        flex: 1,
        backgroundColor: DEFAULT_COLOR,
    },
    mediaContainter: {
        flexGrow: 1,
        flexShrink: 1,
        paddingVertical: SpacingValue["space-large"] * 3,
    },
    media: {
        width: "100%",
        height: "100%",
    },
    fileInput: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        opacity: 0,
        zIndex: -1,
    },
});
