import * as React from "react";

import { MinisRouteList } from "@minis-consumer/interfaces/route";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

export interface IMinisContext {
    navigation: NativeStackNavigationProp<MinisRouteList, keyof MinisRouteList>;
}

export const MinisContext = React.createContext<IMinisContext | null>(null);
