import * as React from "react";
import { StyleProp, ViewStyle } from "react-native";

import { SdkConversation } from "@swiggy-private/connect-chat-sdk";

export interface IChatBlockedErrorSnackContext {
    isOpen: boolean;
    close: VoidFunction;
    open: VoidFunction;
    blockedInfo: SdkConversation["blockedInfo"];
    setBlockedInfo: (b: SdkConversation["blockedInfo"]) => void;
    extraStyle: StyleProp<ViewStyle>;
    setExtraStyle: (s: StyleProp<ViewStyle>) => void;
}

export const ChatBlockedErrorSnackbarContext =
    React.createContext<IChatBlockedErrorSnackContext | null>(null);

export const ChatBlockedSnackbarContextProvider: React.FC<React.PropsWithChildren> = ({
    children,
}) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [blockedInfo, setBlockedInfo] = React.useState<SdkConversation["blockedInfo"]>();
    const [extraStyle, setExtraStyle] = React.useState<StyleProp<ViewStyle>>();

    const close = React.useCallback(() => setIsOpen(false), []);
    const open = React.useCallback(() => setIsOpen(true), []);

    const value = React.useMemo(
        () => ({ isOpen, close, open, blockedInfo, setBlockedInfo, extraStyle, setExtraStyle }),
        [isOpen, close, open, blockedInfo, setBlockedInfo, extraStyle, setExtraStyle],
    );

    return (
        <ChatBlockedErrorSnackbarContext.Provider value={value}>
            {children}
        </ChatBlockedErrorSnackbarContext.Provider>
    );
};
