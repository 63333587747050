import * as React from "react";
import { Pressable, StyleSheet } from "react-native";
import { NavigationProp, useNavigation } from "@react-navigation/core";
import { useHeaderHeight } from "@react-navigation/elements";

import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { useIsomorphicEffect } from "@swiggy-private/react-native-ui";

import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { RouteList } from "@minis-consumer/interfaces/route";
import { ShopLogo } from "@minis-consumer/components/shop-logo";
import { getStoreBrandImageUrl } from "@minis-consumer/helpers/store";
import { Store } from "@minis-consumer/interfaces/store";
import { useCartView } from "@minis-consumer/hooks/use-cart";

import { BillSummaryModal } from "../bill-summary-dialog";

const DISPLAY_COPIES = {
    HIDE_BILL_SUMMARY: "Hide bill summary",
    BILL_SUMMARY: "bill summary",
};

interface HeaderLeftProps {
    storeInfo: Store;
    hasFullStoreName?: boolean;
}

interface HeaderRightProps {
    storeInfo: Store;
    headerHeight: number;
}

export const HeaderLeft: React.FC<HeaderLeftProps> = ({ storeInfo, hasFullStoreName = false }) => {
    const { value: theme } = useTheme();
    const logoSize = 40;
    const logoUrl = React.useMemo(
        () => getStoreBrandImageUrl(storeInfo, { height: logoSize }, true),
        [storeInfo],
    );

    const logoStyles = {
        borderWidth: 1,
        borderColor: theme["color-basic-15"],
    };

    const storeNameStyle = { maxWidth: hasFullStoreName ? "100%" : "65%" };

    return (
        <Stack direction="row" spacing={SpacingValue["space-small"]} alignItems="center">
            <ShopLogo
                isImageKitEnabled
                url={logoUrl}
                size={logoSize}
                borderRadius={8}
                style={logoStyles}
            />
            <Text
                color="high"
                category="s1"
                numberOfLines={1}
                weight="bold"
                style={[styles.store, storeNameStyle]}>
                {storeInfo.name}
            </Text>
        </Stack>
    );
};

export const HeaderRight: React.FC<HeaderRightProps> = ({ storeInfo, headerHeight }) => {
    const [showBillSummary, setShowBillSummary] = React.useState(false);
    const cart = useCartView(storeInfo.storeId);

    const handlePress = (): void => {
        setShowBillSummary(!showBillSummary);
    };

    return (
        <>
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="flex-end"
                style={{ marginRight: SpacingValue["space-medium"] }}>
                <Text color="high" category="s1" weight="bold">
                    ₹{cart?.cartViewData?.bill.finalBillTotal ?? "-"}
                </Text>
                <Pressable onPress={handlePress}>
                    <Text color="color-positive" category="btn4" style={styles.summaryButton}>
                        {showBillSummary
                            ? DISPLAY_COPIES.HIDE_BILL_SUMMARY
                            : DISPLAY_COPIES.BILL_SUMMARY}
                    </Text>
                </Pressable>
            </Stack>

            <BillSummaryModal
                showModal={showBillSummary}
                setShowModal={setShowBillSummary}
                headerHeight={headerHeight}
            />
        </>
    );
};

export const GuestCheckoutHeader: React.FC = () => {
    const storeInfo = useStoreInfo();
    const navigation = useNavigation<NavigationProp<RouteList, "GuestCheckout">>();
    const headerHeight = useHeaderHeight();

    useIsomorphicEffect(() => {
        navigation.setOptions({
            headerTitle: () => <HeaderLeft storeInfo={storeInfo} />,
            headerRight: () => <HeaderRight storeInfo={storeInfo} headerHeight={headerHeight} />,
        });
    }, [headerHeight, navigation, storeInfo]);

    return null;
};

const styles = StyleSheet.create({
    store: {
        paddingRight: 20,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    summaryButton: {
        whiteSpace: "nowrap",
        marginTop: 0,
    },
});
