import React, { FC } from "react";

import { SvgIconType } from "@swiggy-private/connect-svg-icons";
import { ButtonProps, TextProps } from "@swiggy-private/rn-dls";

import { Product, Variant } from "@minis-consumer/interfaces/catalog";
import { CartStatuses } from "@minis-consumer/interfaces/cart";

import { SingleActionProductCta } from "./single-action";
import { MultipleQuantityProductCta } from "./multiple-quantity";

export interface ProductCtaProps {
    productId: string;
    type: Product["productType"];
    storeId: string;
    ctaState:
        | "ADD"
        | "REMOVE"
        | "ENQUIRE"
        | "UNAVAIALBLE"
        | "REDIRECT"
        | "COUNTER"
        | "EDIT"
        | "SHOW_VARIANTS"; // to be used for physical product without default variant price on SF ONLY
    quantity: number;

    price?: number;

    ctaStyle?: ButtonProps["style"];
    ctaTextStyle?: ButtonProps["textStyle"];
    ctaColor?: ButtonProps["color"];
    ctaIconSize?: number;
    ctaText?: string;
    ctaTextColor?: TextProps["color"];
    ctaElevation?: ButtonProps["elevation"];
    isDisabled?: boolean;
    ctaIcon?: SvgIconType["icon"];
    ctaIconColor?: SvgIconType["color"];

    showVariants?: boolean;
    selectedVariant?: Variant | null;
    isInVariantComponent?: boolean;

    sfWidget?: string;
    itemStatus?: CartStatuses;
    productActionCtaName?: string;
    isEditForm?: boolean;

    singleActionHandler?: VoidFunction;
    incrementActionHandler?: VoidFunction;
    decrementActionHandler?: VoidFunction;
    onCloseDialog?: VoidFunction;
    impressionEvent?: VoidFunction;
}

const ProductCtaComponent: FC<ProductCtaProps> = ({
    productId,
    type,
    storeId,
    ctaState,
    ctaStyle,
    ctaTextStyle,
    ctaColor,
    ctaText,
    ctaIcon,
    ctaIconColor,
    ctaIconSize,
    ctaTextColor,
    ctaElevation,
    quantity,
    isDisabled,
    showVariants,
    isInVariantComponent,
    selectedVariant,
    sfWidget,
    itemStatus,
    productActionCtaName,
    isEditForm,
    singleActionHandler,
    incrementActionHandler,
    decrementActionHandler,
    onCloseDialog,
    impressionEvent,
}) => {
    if (ctaState === "COUNTER") {
        if (incrementActionHandler && decrementActionHandler) {
            /** load multiple quantity cta */
            return (
                <MultipleQuantityProductCta
                    ctaStyle={ctaStyle}
                    ctaTextStyle={ctaTextStyle}
                    ctaColor={ctaColor}
                    ctaState={ctaState}
                    productId={productId}
                    storeId={storeId}
                    ctaIconColor={ctaIconColor}
                    ctaTextColor={ctaTextColor}
                    quantity={quantity}
                    incrementActionHandler={incrementActionHandler}
                    decrementActionHandler={decrementActionHandler}
                    showVariants={showVariants}
                    isInVariantComponent={isInVariantComponent}
                    selectedVariant={selectedVariant}
                    onCloseDialog={onCloseDialog}
                    type={type}
                    impressionEvent={impressionEvent}
                    sfWidget={sfWidget}
                    itemStatus={itemStatus}
                    productActionCtaName={productActionCtaName}
                    isEditForm={isEditForm}
                />
            );
        }
    }

    /** load single action cta */
    return (
        <SingleActionProductCta
            ctaStyle={ctaStyle}
            ctaTextStyle={ctaTextStyle}
            ctaColor={ctaColor}
            ctaElevation={ctaElevation}
            ctaState={ctaState}
            productId={productId}
            storeId={storeId}
            ctaText={ctaText}
            ctaIcon={ctaIcon}
            ctaIconColor={ctaIconColor}
            isDisabled={isDisabled}
            singleActionHandler={singleActionHandler}
            quantity={quantity}
            showVariants={showVariants}
            isInVariantComponent={isInVariantComponent}
            selectedVariant={selectedVariant}
            onCloseDialog={onCloseDialog}
            type={type}
            impressionEvent={impressionEvent}
            sfWidget={sfWidget}
            itemStatus={itemStatus}
            productActionCtaName={productActionCtaName}
            ctaIconSize={ctaIconSize}
            isEditForm={isEditForm}
        />
    );
};

export const ProductCta = React.memo(ProductCtaComponent);
