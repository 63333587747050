import React, { useCallback, useMemo, useState } from "react";
import { StyleSheet, ViewStyle } from "react-native";
// import { useNavigation } from "@react-navigation/core";
// import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { BottomSheet } from "@swiggy-private/react-native-ui";
import { DialogContent, useTheme, SpacingValue } from "@swiggy-private/rn-dls";
import { useMount } from "@swiggy-private/react-hooks";

import { Product } from "@minis-consumer/interfaces/catalog";
import { useIsDesktopWeb } from "@minis-consumer/hooks/use-dweb";
import {
    useLocalCart,
    useLocalCartItem,
    useLocalCartVariantForm,
} from "@minis-consumer/hooks/use-cart";
import { useGetPdpCtaNavigation } from "@minis-consumer/hooks/use-pdp-cta-navigation";
import { NavigationProvider } from "@minis-consumer/components/product-cta/hoc/with-variant-form-calendar/context";
// import { RouteList } from "@minis-consumer/interfaces/route";
import { Analytics } from "@minis-consumer/analytics";
import { useCustomNavigation } from "@minis-consumer/components/product-cta/hooks/use-get-minis-navigation";

import { VariantsCustomisationsList } from "../customisations-list";
import { VariantOptions } from "../variants-options";

type AddVariantsComponentProps = {
    storeId: string;
    product: Product;

    open?: boolean;
    sfWidget?: string;
    setOpen?: (b: boolean) => void;
};

const ARD_EVENTS = {
    HALF_CARD: "variants-half-card",
    ADD_CUSTOMISATION: "add-new-customization",
};

const VariantsBottomSheetComponent: React.FC<AddVariantsComponentProps> = ({
    open = false,
    storeId,
    setOpen,
    product,
    sfWidget,
}) => {
    const { value: theme } = useTheme();
    const { id, name, productType } = product;
    const cartData = useLocalCart(storeId);

    const isDesktop = useIsDesktopWeb();
    const minisNavigation = useCustomNavigation();

    const [, increment, decrement] = useLocalCartItem({
        storeId,
        itemId: id,
        productType: productType ?? "PHYSICAL",
    });

    const { action: pdpNavAction, resetProductSelectedData } =
        useGetPdpCtaNavigation({ productId: id, minisNavigation }) || {};

    const [showAddVariantsSheet, setShowAddVariantsSheet] = useState(false);

    const closeDialog = useCallback((): void => setOpen?.(false), [setOpen]);

    const analyticsContext = useMemo(() => {
        return JSON.stringify({
            productId: product.id,
            productType: product.productType,
            isRecommended: product.badges.includes("Recommended"),
            sfWidget,
        });
    }, [product, sfWidget]);

    const showVariantsAddSheet = useCallback(() => {
        Analytics.clickEvent({
            category: ARD_EVENTS.ADD_CUSTOMISATION,
            context: analyticsContext,
        });

        setShowAddVariantsSheet(true);
    }, [analyticsContext]);

    const { getVariantItemFormInCart } = useLocalCartVariantForm({ storeId });

    const onAdd = useCallback(
        (variantId?: string) => {
            resetProductSelectedData?.();

            const itemForm = getVariantItemFormInCart(variantId ?? "");

            pdpNavAction?.({
                variantId,
                originalCallback: () => {
                    increment(variantId, { itemForm });
                    resetProductSelectedData?.();
                },
            });

            closeDialog();
        },
        [increment, closeDialog, pdpNavAction, resetProductSelectedData, getVariantItemFormInCart],
    );

    const onRemove = useCallback(
        (variantId?: string) => {
            decrement(variantId);
            closeDialog();
        },
        [decrement, closeDialog],
    );

    useMount(() => {
        Analytics.impressionEvent({
            category: ARD_EVENTS.HALF_CARD,
            context: analyticsContext,
        });
    });

    if (!open) {
        return null;
    }

    // Logic, check if a product variant already exists in cart.
    const variantsInCart = cartData.items.filter((cartItem) => cartItem.id === id);

    const dialogStyles: ViewStyle = isDesktop ? styles.dialogContentDesktop : styles.dialogContent;

    return (
        <BottomSheet open={open} onClose={closeDialog} style={styles.sheet}>
            <NavigationProvider navigation={minisNavigation}>
                <DialogContent style={[dialogStyles, { backgroundColor: theme["color-basic-0"] }]}>
                    {showAddVariantsSheet || !variantsInCart.length ? (
                        <VariantOptions
                            onAdd={onAdd}
                            product={product}
                            onRemove={onRemove}
                            onClose={closeDialog}
                        />
                    ) : (
                        <VariantsCustomisationsList
                            id={id}
                            title={name}
                            storeId={storeId}
                            onClose={closeDialog}
                            variants={variantsInCart}
                            onAdd={onAdd}
                            onRemove={onRemove}
                            onShowVariantsList={showVariantsAddSheet}
                        />
                    )}
                </DialogContent>
            </NavigationProvider>
        </BottomSheet>
    );
};

const styles = StyleSheet.create({
    sheet: {
        backgroundColor: "transparent",
    },
    dialogContent: {
        maxHeight: 500,
        paddingBottom: 0,
        paddingHorizontal: 0,
        borderTopLeftRadius: 24,
        borderTopRightRadius: 24,
        paddingTop: SpacingValue["space-x-large"],
    },
    dialogContentDesktop: {
        minWidth: 350,
        borderRadius: 24,
        paddingBottom: 0,
    },
    callout: {
        borderRadius: 16,
        marginHorizontal: SpacingValue["space-medium"],
        marginBottom: SpacingValue["space-medium"],
    },
});

if (__DEV__) {
    VariantsBottomSheetComponent.displayName = "VariantsBottomSheet";
}

export const VariantsBottomSheet = React.memo(VariantsBottomSheetComponent);
