import { SpacingValue } from "@swiggy-private/rn-dls";

export const COPIES = {
    DESCRIPTION:
        "This store provides excellent customer service. With prompt chat responses, timely order fulfilment, and excellent customer ratings.",
    IMPRESSION_CATEGORY: "star-seller-info-popup",
};

export const CONSTANTS = {
    BOX_SIZE: 58,
    ANIMATION_DELAY: 100,
    ANIMATION_DURATION: 450,
    LOTTIE_DURATION: 2500,
    MAX_DESKTOP_WIDTH: 375,
    PADDING_HORIZONTAL: SpacingValue["space-xx-large"],
};
