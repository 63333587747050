import { Dispatch, useContext } from "react";

import { FollowersCountDispatchContext } from "../contexts/followers-count-context";
import { FollowersCountAction } from "../reducers/followers-count-reducer/actions";

export function useFollowersCountDispatch(): Dispatch<FollowersCountAction> {
    const ctx = useContext(FollowersCountDispatchContext);
    if (!ctx) {
        throw new Error("Missing followers count dispatch context");
    }

    return ctx;
}
