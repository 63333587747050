export const DEFAULT_VALUES = {
    SMALL_SCREEN_BANNER_WIDTH: 200,
    SMALL_SCREEN_BANNER_HEIGHT: 80,
    LARGE_SCREEN_BANNER_WIDTH: 220,
    LARGE_SCREEN_BANNER_HEIGHT: 88,
    UPLOAD_BUTTON_HEIGHT: 52,
    CAMERA_ICON_SIZE: 24,
    PLUS_ICON_SIZE: 20,
    CROSS_ICON_SIZE: 24,
    UPLOADED_IMAGE_SIZE: 64,
    SMALL_SCREEN_UPLOADED_IMAGE_SIZE: 64,
    LARGE_SCREEN_UPLOADED_IMAGE_SIZE: 104,
    PRIMARY_BTN_HEIGHT: 68,
    ERROR_ICON_SIZE: 18,
    RATING_COUNT: 0,
    TOTAL_STARS: 5,
    ICON_SIZE: 24,
    MAX_IMAGE_COUNT: 1,
    INPUT_FEEDBACK_MAX_LENGTH: 300,
    SMALL_SCREEN_STAR_ICON_SIZE: 24,
    LARGE_SCREEN_STAR_ICON_SIZE: 32,
};

export const COPIES = {
    ADD_PHOTO_PREFIX: "Add a photo ",
    ADD_PHOTO_SUFIX: "to help us better understand your concerns.",
    SNACKBAR_FAILURE_TEXT_PREFIX: "Oops! ",
    SNACKBAR_FAILURE_TEXT_SUFFIX:
        " It seems we're having trouble submitting your review, Please try again",
    INPUT_PLACEHOLDER: "Please share your feedback here.",
    DEFAULT_TITLE: "Please provide your feedback",
    SUBMIT_BTN_TEXT: "Submit",
    SUBMIT_REVIEW_BTN_TEXT: "Submit Review",
    INPUT_LABEL: "Your Review",
};

export const TEST_IDS = {
    FORM_INPUT: "review-form",
    FORM_INPUT_TITLE: "review-title",
    BANNER: "banner",
    BANNER_IMAGE: "cdn-image",
    LARGE_SCREEN_ADD_ICON: "large-screen-add-icon",
    LARGE_SCREEN_ADD_TEXT: "large-screen-add-text",
    SMALL_SCREEN_ADD_ICON: "small-screen-add-icon",
    CANCEL_IMAGE: "cancel-image",
    BTN_LOADING: "button-loading",
    SUBMIT_BTN_TEXT: "submit-btn-text",
    SUBMIT_BTN: "submit-btn",
    STAR_RATING: "rating",
    REVIEW_INPUT: "review-input",
    IMAGE_UPLOAD: "image-upload",
};

export const TITLE: Record<string, string> = {
    1: "Oh no! Tell us what went wrong",
    2: "Oh no! Tell us what went wrong",
    3: "Uh-Oh! Tell us what we can improve",
    4: "Great! Tell us what impressed you!",
    5: "Great! Tell us what impressed you!",
};

export const ANALYTICS_COPIES = {
    CHAT_UPLOAD_IMAGE_HALF_CARD: "chat-upload-image-half-card",
    GALLERY: "Gallery",
    GALLERY_AND_CAMERA: "Gallery,Camera",
    SUBMIT_REVIW_BTN: "submit-review-btn",
    RATING_STAR: "rating-star",
    RATING_FEEDBACK: "rating-feedback",
    ADD_PHOTO: "add-photo",
};
