import React from "react";
import {
    ChatConversationMessageView,
    ChatConversationMessageViewProps,
    IMessageViewContent,
} from "@swiggy-private/connect-chat-ui";

import { Analytics } from "@minis-consumer/analytics";

const ChatRenderMessageWrapper: React.FC<ChatConversationMessageViewProps> = ({
    onMessageRead,
    ...props
}) => {
    const onMessageReadOverride = React.useCallback(
        (data: IMessageViewContent) => {
            onMessageRead?.(data);

            if (!data.isPublisher) {
                const { conversationId, message } = data;
                Analytics.impressionEvent({
                    screen: "seller-chat-window",
                    category: "chat-message-view",
                    label: `conversationId: ${conversationId}, messageId: ${message.id}, initiatedBy: seller`,
                    context: `timestamp: ${message.timestamp}, messageType: ${message.type}`,
                });
            }
        },
        [onMessageRead],
    );

    return <ChatConversationMessageView {...props} onMessageRead={onMessageReadOverride} />;
};

export const ChatRenderMessage = React.memo(ChatRenderMessageWrapper);
