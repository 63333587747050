import * as React from "react";

import { ProductEnquiryModalContext } from "@minis-consumer/contexts/enquiry-dialog-context";
import { Product, Variant } from "@minis-consumer/interfaces/catalog";

interface CallbackParams {
    open?: boolean;
    id?: string;
    imageLink?: string;
    storeId?: string;
    productDetails?: Product;
    selectedVariant?: Variant | null;
}

export const useEnquiryModalHandler = (): [
    boolean,
    string,
    string,
    ({ open, id, imageLink, storeId, productDetails, selectedVariant }: CallbackParams) => void,
    string,
    Product | undefined,
    Variant | null | undefined,
] => {
    const ctx = React.useContext(ProductEnquiryModalContext);

    const handler = React.useCallback(
        ({ open, id, imageLink, storeId, productDetails, selectedVariant }: CallbackParams) => {
            ctx?.setProductId(id ?? "");
            ctx?.setImageUrl(imageLink ?? "");
            ctx?.setStoreId(storeId ?? "");
            productDetails && ctx?.setProductDetails(productDetails);
            selectedVariant
                ? ctx?.setSelectedVariant(selectedVariant)
                : ctx?.setSelectedVariant(undefined);

            if (open) {
                ctx?.open();
            } else {
                ctx?.close();
            }
        },
        [ctx],
    );

    return [
        ctx?.isOpen ?? false,
        ctx?.productId ?? "",
        ctx?.imageUrl ?? "",
        handler,
        /** Added in the end to keep file changes minimum */
        ctx?.storeId ?? "",
        ctx?.productDetails,
        ctx?.selectedVariant,
    ];
};
