import React, { useCallback } from "react";
import { FlatList, StyleProp, StyleSheet, useWindowDimensions, ViewStyle } from "react-native";

import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";

import { useIsDesktopWeb } from "@minis-consumer/hooks/use-dweb";
import { VariantOption, Variant } from "@minis-consumer/interfaces/catalog";

import { OptionsItem } from "./options-item";

export interface VariantsListProps {
    options: VariantOption[];
    variantDetails: Variant[];
    selectedVariants: Variant["optionValueMap"] | null;
    selectedVariantDetails: Variant | null;
    onPress: (item: string, variant: VariantOption) => void;

    containerStyles?: StyleProp<ViewStyle>;
}

// text props for pills

const VariantsList: React.FC<VariantsListProps> = ({
    options,
    onPress,
    variantDetails,
    selectedVariants,
    containerStyles = {},
}) => {
    const { value: theme } = useTheme();
    const isDesktop = useIsDesktopWeb();

    const { height: windowHeight } = useWindowDimensions();
    const modalHeight = windowHeight * 0.5;

    const renderItem = useCallback(
        ({ item, variant }: { item: string; variant: VariantOption }) => (
            <OptionsItem
                item={item}
                variant={variant}
                variantDetails={variantDetails}
                selectedVariants={selectedVariants}
                options={options}
                onPress={onPress}
            />
        ),

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [onPress, selectedVariants, theme],
    );

    const getOptionsList = (values: string[], variant: VariantOption): React.ReactElement => {
        if (isDesktop) {
            return (
                <Stack spacing={SpacingValue["space-x-small"]} style={styles.wrap} direction="row">
                    {values.map((item) => (
                        <Stack key={item} style={styles.option}>
                            <OptionsItem
                                item={item}
                                variant={variant}
                                variantDetails={variantDetails}
                                selectedVariants={selectedVariants}
                                options={options}
                                onPress={onPress}
                            />
                        </Stack>
                    ))}
                </Stack>
            );
        }
        return (
            <FlatList
                horizontal
                showsHorizontalScrollIndicator={false}
                data={values}
                contentContainerStyle={containerStyles}
                renderItem={({ item }) => renderItem({ item, variant })}
                ItemSeparatorComponent={() => <Box mr={SpacingValue["space-x-small"]} />}
            />
        );
    };

    if (!options.length) {
        return null;
    }

    const desktopStyles = StyleSheet.compose(styles.overflow, { maxHeight: modalHeight });

    return (
        <Stack style={isDesktop ? desktopStyles : null} spacing={SpacingValue["space-x-large"]}>
            {options.map((variant) => {
                const { name, displayName, values } = variant;
                return (
                    <Stack key={name} spacing={SpacingValue["space-small"]}>
                        <Box style={containerStyles}>
                            <Text
                                style={styles.textTransform}
                                category="b2"
                                weight="medium"
                                color="low">
                                {`${displayName} :`}
                            </Text>
                        </Box>
                        {getOptionsList(values, variant)}
                    </Stack>
                );
            })}
        </Stack>
    );
};

const styles = StyleSheet.create({
    textTransform: {
        textTransform: "capitalize",
    },
    wrap: {
        flexWrap: "wrap",
    },
    option: {
        marginBottom: SpacingValue["space-small"],
    },
    overflow: {
        overflow: "scroll",
    },
});

if (__DEV__) {
    VariantsList.displayName = "VariantsList";
}

export const VariantOptionsList = React.memo(VariantsList);
