import React from "react";
import { Icon } from "@swiggy-private/connect-svg-icons";

export const useCartIcon = (cartItemCount: number): ((focused: boolean) => Icon) => {
    const getIcon = React.useCallback(
        (focused: boolean): Icon =>
            cartItemCount > 0
                ? focused
                    ? "BagFullFilled"
                    : "BagFull"
                : cartItemCount === 0
                ? focused
                    ? "BagEmptyFilled"
                    : "BagEmpty"
                : "BagEmpty",
        [cartItemCount],
    );
    return getIcon;
};
