import { SwiggyHeaders } from "@minis-consumer/interfaces/swiggy";

import { CookieStorage } from "../helpers/cookie-storage";
import { USER_DATA_KEYS, USER_DATA_COOKIE_KEY } from "../constants/cookie";

export class Session {
    private _tid: string | null = null;
    private _token: string | null = null;
    private _sid: string | null = null;

    private _userId: string | null = null;
    private _userName: string | null = null;
    private _storeId: string | null = null;

    private _timeout = 0;

    private static _storageKey = USER_DATA_COOKIE_KEY;
    private static _instance: Session | null = null;

    private constructor() {
        //
    }

    static async load(headers?: SwiggyHeaders | null): Promise<Session> {
        if (headers != null && Object.keys(headers).length > 0) {
            return Session.loadFromHeaders(headers);
        }

        const session = Session.getInstance();
        const sessionDetails = await CookieStorage.getObjectValue<Session>(Session._storageKey);

        if (sessionDetails) {
            session._tid = sessionDetails.tid;
            session._token = sessionDetails.token;
            session._sid = sessionDetails.sid;
            session._userId = sessionDetails.userId;
            session._userName = sessionDetails.userName;
        }

        return session;
    }

    static async loadFromHeaders(headers: SwiggyHeaders): Promise<Session> {
        const session = Session.getInstance();

        session._tid = headers.tid || null;
        session._sid = headers.sid || null;
        session._token = headers.token || null;
        session._userId = String(headers.userId || "0");
        session._userName = headers.userName || null;

        return session;
    }

    static getInstance(): Session {
        if (Session._instance == null) {
            Session._instance = new Session();

            return Session._instance;
        }

        return Session._instance;
    }

    get tid(): string | null {
        return this._tid;
    }

    set tid(tid: string | null) {
        this._tid = tid;
        this.save();
    }

    get sid(): string | null {
        return this._sid;
    }

    set sid(sid: string | null) {
        this._sid = sid;
        this.save();
    }

    get token(): string | null {
        return this._token;
    }

    set token(token: string | null) {
        this._token = token;
        this.save();
    }

    get userId(): string {
        return String(this._userId || "0");
    }

    set userId(userId: string | number | null) {
        this._userId = userId == null ? null : String(userId);
        this.save();
    }

    get userName(): string | null {
        return this._userName;
    }

    set userName(userName: string | null) {
        this._userName = userName;
        this.save();
    }

    get storeId(): string | null {
        return this._storeId;
    }

    set storeId(storeId: string | null) {
        this._storeId = storeId;
    }

    clear(): void {
        this._tid = null;
        this._token = null;
        this._sid = null;
        this._userId = "0";
        this._userName = null;
        this._storeId = null;

        this.save();
    }

    isGuest(): boolean {
        return this.userId === "0" || !this.token;
    }

    save(): void {
        clearTimeout(this._timeout);

        this._timeout = setTimeout(async () => {
            const sessionDetails = {
                [USER_DATA_KEYS.tid]: this._tid,
                [USER_DATA_KEYS.sid]: this._sid,
                [USER_DATA_KEYS.token]: this._token,
                [USER_DATA_KEYS.userId]: this._userId,
                [USER_DATA_KEYS.userName]: this._userName,
            };

            await CookieStorage.setValue(Session._storageKey, sessionDetails);
        }, 0) as unknown as number;
    }
}
