import { useMemo } from "react";
import { useController } from "@rest-hooks/react";
import { ReadEndpoint } from "@rest-hooks/endpoint";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function usePaginator<E extends ReadEndpoint<(params: any) => Promise<any>, any>>(
    endpoint: E,
    params: Omit<Parameters<E>[0], "cursor"> | null,
): null | ((cursor: string) => Promise<E>) {
    const { fetch } = useController();

    return useMemo(
        () => {
            if (!params) {
                return null;
            }

            return (cursor: string) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const p: Parameters<E> = [{ ...params, cursor }] as any;
                return fetch(endpoint, ...p);
            };
        },
        // "params && endpoint.key(params)" is a method to serialize params
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [fetch, params && endpoint.key(params)],
    );
}
