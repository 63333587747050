import React, { memo } from "react";
import { StyleSheet } from "react-native";

import { Text } from "@swiggy-private/rn-dls";

import { Product } from "@minis-consumer/interfaces/catalog";
import { CHANNEL_ICON_MAP } from "@minis-consumer/constants/product";

interface Props {
    channels: Product["serviceDeliveryChannels"];

    showFirst?: boolean;
}

const CommunicationChannelComponent: React.FC<Props> = ({ channels, showFirst }) => {
    if (!channels?.length) {
        return null;
    }

    const CHANNEL_DISPLAY_NAMES = channels.map(
        (channel) => CHANNEL_ICON_MAP[channel]?.text ?? channel,
    );

    return (
        <Text
            category="b3"
            color="low"
            weight="regular"
            numberOfLines={1}
            ellipsizeMode="tail"
            style={styles.channelName}>
            {showFirst ? CHANNEL_DISPLAY_NAMES[0] : CHANNEL_DISPLAY_NAMES.join(", ")}
        </Text>
    );
};

export const CommunicationChannel = memo(CommunicationChannelComponent);

const styles = StyleSheet.create({
    channelName: {
        textTransform: "capitalize",
    },
});
