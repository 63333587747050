import React from "react";
import { StyleSheet } from "react-native";

import { SpacingValue, Input } from "@swiggy-private/rn-dls";
import { Stack } from "@swiggy-private/rn-adaptive-layout";

const ModalBodyContentComponent: React.FC<{ onChange: (text: string) => void }> = ({
    onChange: onChangeProp,
}) => {
    const onChangeText = React.useCallback(
        (text: string) => {
            onChangeProp(text);
        },
        [onChangeProp],
    );

    return (
        <Stack spacing={SpacingValue["space-medium"]} style={styles.textContainer}>
            <Input maxLength={250} multiline showCounter autoFocus onChangeText={onChangeText} />
        </Stack>
    );
};

const styles = StyleSheet.create({
    textContainer: {
        marginTop: SpacingValue["space-medium"],
        height: 140,
    },
});

export const ModalBodyContent = React.memo(ModalBodyContentComponent);
