import { useState, useCallback } from "react";
import { useErrorHandler } from "react-error-boundary";
import { useController } from "@rest-hooks/react";

import { useMountedRef } from "@swiggy-private/react-hooks";

import { FetchScheduledMeetingSlots } from "@minis-consumer/resources/schedule-management";
import { FetchScheduledMeetingSlotsResponse } from "@minis-consumer/api/product/schedule-management";

interface useGetProductSlotsParams {
    storeId: string;
    productId: string;
    date: number;
    custom?: boolean;
}

/**
 * Hook to load the coupons view for a cart.
 */
export const useGetProductSlots = ({
    storeId,
    productId,
    date,
    custom,
}: useGetProductSlotsParams): {
    loading: boolean;
    slotData?: FetchScheduledMeetingSlotsResponse;
    fetchProductSlotData: (d?: number) => Promise<void>;
} => {
    const [loading, setLoading] = useState(false);
    const mounted = useMountedRef();
    const errorHandler = useErrorHandler();

    const [slotData, setSlotData] = useState<FetchScheduledMeetingSlotsResponse>();

    const { fetch } = useController();

    const fetchProductSlotData = useCallback(
        async (dateNew?: number) => {
            try {
                setLoading(true);

                const slotDataResponse = await fetch(FetchScheduledMeetingSlots, {
                    storeId,
                    productId,
                    custom,
                    date: dateNew ? dateNew : date,
                });

                if (mounted.current) {
                    setTimeout(() => {
                        setLoading(false);

                        mounted.current && setSlotData(slotDataResponse);
                    }, 1_000);
                }
            } catch (err) {
                if (mounted.current) {
                    setLoading(false);
                    errorHandler(err);
                }
            }
        },
        [fetch, storeId, productId, mounted, errorHandler, custom, date],
    );

    return { loading, slotData, fetchProductSlotData };
};
