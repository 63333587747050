import * as React from "react";
import { StatusBar, StyleSheet } from "react-native";
import { initialWindowMetrics, Metrics, SafeAreaProvider } from "react-native-safe-area-context";

import { useMount } from "@swiggy-private/react-hooks";
import { ActivityIndicator } from "@swiggy-private/rn-dls";

import { Analytics } from "@minis-consumer/analytics";
import { ResetErrorBoundary } from "@minis-consumer/components/reset-error-boundary";
import { ProductEnquiryModalContextProvider } from "@minis-consumer/contexts/enquiry-dialog-context";
import { LocationContextProvider } from "@minis-consumer/contexts/location-context";
import { RatingAndReviewModalContextProvider } from "@minis-consumer/contexts/rating-and-review-context";
import { SessionContextProvider } from "@minis-consumer/contexts/session-context";
import { BootStrapper } from "@minis-consumer/includes/bootstrap";
import { SwiggyHeaders } from "@minis-consumer/interfaces/swiggy";
import { ChatBlockModalContextProvider } from "@minis-consumer/contexts/chat-block-modal-context";
import { ChatBlockedSnackbarContextProvider } from "@minis-consumer/contexts/chat-blocked-snackbar-context";
import { ViewStoryModalContextProvider } from "@minis-consumer/contexts/view-story-chat-context";

import AppContent from "./app-content";

interface AppContainerProps {
    headers?: SwiggyHeaders | null;
    fetchUserOnInit?: boolean;
}

const Loader: React.FC = () => <ActivityIndicator size="large" style={styles.loader} />;

const resetMetrics = {
    insets: {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
    },
    frame: {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
    },
};

export const AppContainer: React.FC<React.PropsWithChildren<AppContainerProps>> = ({
    children,
    headers,
    fetchUserOnInit,
}) => {
    const [userFetched, setUserFetched] = React.useState(false);

    useMount(() => {
        Analytics.init();
        return () => Analytics.destroy();
    });

    const metrics: Metrics = React.useMemo(
        () => (initialWindowMetrics ? initialWindowMetrics : resetMetrics),
        [],
    );

    const onFetchUser = (): void => {
        setUserFetched(true);
    };

    return (
        <SafeAreaProvider initialMetrics={metrics}>
            <ResetErrorBoundary>
                <LocationContextProvider swiggyHeaders={headers}>
                    <SessionContextProvider headers={headers}>
                        <BootStrapper
                            fallback={<Loader />}
                            headers={headers}
                            fetchUser={fetchUserOnInit}
                            onFetchUser={onFetchUser}>
                            <ProductEnquiryModalContextProvider>
                                <ChatBlockModalContextProvider>
                                    <RatingAndReviewModalContextProvider>
                                        <ChatBlockedSnackbarContextProvider>
                                            <ViewStoryModalContextProvider>
                                                <ResetErrorBoundary>
                                                    <AppContent
                                                        userFetched={userFetched}
                                                        headers={headers}>
                                                        {children}
                                                    </AppContent>
                                                </ResetErrorBoundary>
                                            </ViewStoryModalContextProvider>
                                        </ChatBlockedSnackbarContextProvider>
                                    </RatingAndReviewModalContextProvider>
                                </ChatBlockModalContextProvider>
                            </ProductEnquiryModalContextProvider>
                        </BootStrapper>
                    </SessionContextProvider>
                    <StatusBar
                        backgroundColor={"#fff"}
                        translucent={true}
                        barStyle="dark-content"
                    />
                </LocationContextProvider>
            </ResetErrorBoundary>
        </SafeAreaProvider>
    );
};

const styles = StyleSheet.create({
    loader: {
        flex: 1,
    },
});
