import { useCallback } from "react";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { useGetProduct } from "@minis-consumer/routes/product/hooks/use-get-product";
import { CartItem, ICartItemForm, ServiceDeliverySlot } from "@minis-consumer/interfaces/cart";
import { RouteList } from "@minis-consumer/interfaces/route";
import { Product } from "@minis-consumer/interfaces/catalog";

import { useGetCartItem, useLocalCartVariantQuantity } from "./use-cart";

interface Params {
    productId: string;
    storeId?: string;
    storeSlug?: string;
    minisNavigation: NativeStackNavigationProp<RouteList> | null;
    isCustom?: boolean;
}

interface ActionParams {
    originalCallback?: VoidFunction;
    variantId?: string;
    slot?: ServiceDeliverySlot;
    productNavState?: number;
    isEditMode?: boolean;
    formData?: ICartItemForm[];
    isEnquireMode?: boolean;
    callbackBeforeNavigation?: VoidFunction;
}

const STATES = {
    CALENDAR: 0,
    FORM: 1,
};

type ReturnData = Omit<ActionParams, "originalCallback">;

let productSelectedData = {} as ReturnData;

export const useGetPdpCtaNavigation = ({
    productId,
    storeId,
    storeSlug = "",
    minisNavigation,
    isCustom,
}: Params): {
    productSelectedData: ReturnData;
    action: (p: ActionParams) => void;
    setProductSelectedData: (p: ActionParams) => void;
    resetProductSelectedData: VoidFunction;
} | null => {
    const storeInfo = useStoreInfo(false);

    const storeData = storeInfo ?? {
        storeId,
        slug: storeSlug,
    };

    const productData = useGetProduct(productId);

    const cartItem = useGetCartItem(storeData.storeId ?? "", productId);

    const product = isCustom ? cartItem : productData;

    const setProductSelectedData = useCallback(
        ({ variantId, slot, formData, productNavState = 0 }: ActionParams) => {
            productSelectedData = {
                ...productSelectedData,
                productNavState,
            };

            if (variantId) {
                productSelectedData.variantId = variantId;
            }

            if (slot) {
                productSelectedData.slot = slot;
            }

            if (formData) {
                productSelectedData.formData = formData;
            }
        },
        [],
    );

    const resetProductSelectedData = useCallback(() => {
        productSelectedData = {
            variantId: undefined,
            slot: undefined,
            productNavState: 0,
        };
    }, []);

    const { getVariantQuantityInCart } = useLocalCartVariantQuantity({
        storeId: storeData.storeId ?? "",
    });

    const action = useCallback(
        ({
            variantId,
            slot,
            originalCallback,
            productNavState = 0,
            isEditMode,
            isEnquireMode,
            callbackBeforeNavigation,
        }: ActionParams) => {
            if (!product) {
                return;
            }

            const productType =
                (product as CartItem).item?.productType ?? (product as Product)?.productType;
            const productForm = (product as CartItem).itemForm ?? (product as Product)?.form;
            const productEnquiryForm = (product as Product)?.enquiryForm;
            const id = (product as CartItem).item?.id ?? (product as Product)?.id;

            setProductSelectedData({ variantId, slot, productNavState });

            if (productType === "PHYSICAL" || productType === "DIGITAL") {
                /** if item is present in cart, just increment */
                const isItemInCart = Boolean(getVariantQuantityInCart(variantId ?? ""));

                if (
                    (productForm?.length || (isEnquireMode && productEnquiryForm)) &&
                    !isItemInCart
                ) {
                    callbackBeforeNavigation?.();

                    minisNavigation?.navigate("ProductForms", {
                        id,
                        slug: storeData.slug,
                        storeId: storeData.storeId ?? "",
                        variantId,
                        isEnquireMode,
                    });
                } else {
                    originalCallback?.();
                }
            } else if (productType === "APPOINTMENT") {
                const currentState = productSelectedData.productNavState;

                if (currentState === STATES.CALENDAR || !currentState) {
                    callbackBeforeNavigation?.();

                    minisNavigation?.navigate("SlotSelection", {
                        id,
                        slug: storeData.slug,
                        isEnquireMode,
                    });

                    productSelectedData.productNavState =
                        productSelectedData.productNavState ?? 0 + 1;
                    productSelectedData.slot = slot;
                } else if (currentState === STATES.FORM) {
                    callbackBeforeNavigation?.();

                    if (productForm?.length || (isEnquireMode && productEnquiryForm)) {
                        minisNavigation?.navigate("ProductForms", {
                            id,
                            slug: storeData.slug,
                            storeId: storeData.storeId ?? "",
                            isEditMode,
                            isEnquireMode,
                        });
                    } else {
                        originalCallback?.();
                    }
                }
            } else if (productType === "PLAN") {
                if (productForm?.length || (isEnquireMode && productEnquiryForm)) {
                    callbackBeforeNavigation?.();

                    minisNavigation?.navigate("ProductForms", {
                        id,
                        slug: storeData.slug,
                        storeId: storeData.storeId ?? "",
                        isEnquireMode,
                    });
                } else {
                    originalCallback?.();
                }
            }
        },
        [
            product,
            setProductSelectedData,
            getVariantQuantityInCart,
            minisNavigation,
            storeData.slug,
            storeData.storeId,
        ],
    );

    return { productSelectedData, action, setProductSelectedData, resetProductSelectedData };
};
