import * as React from "react";
import { Platform, Pressable, TextInput, ViewStyle, ScrollView } from "react-native";

import { Prediction } from "@swiggy-private/connect-address-ui";
import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { Box } from "@swiggy-private/rn-adaptive-layout";

import { getAddressFromPlaceId, getAddressPredictionsFromInput } from "../delivery-form/utils";

type Address = {
    address: string;
    city: string;
    state: string;
    pincode: string;
    lat: number;
    lng: number;
};

export interface AutoCompleteAddressProps {
    address?: string | null;
    handleAddress: (value: Address) => void;
    canShowPredictions?: boolean;
    addressInputRef: React.RefObject<TextInput>;
}

const isWeb = Platform.OS === "web";

export const AutoCompleteAddress: React.FC<AutoCompleteAddressProps> = ({
    address,
    canShowPredictions = true,
    addressInputRef,
    handleAddress,
}) => {
    const { value: theme } = useTheme();
    const [predictions, setPredictions] = React.useState<Prediction[]>([]);
    const selectedAddressRef = React.useRef(address);

    const showPredictions =
        canShowPredictions &&
        address &&
        address.length >= 3 &&
        selectedAddressRef.current !== address;

    const onPredictionPress = React.useCallback(
        async (prediction: Prediction) => {
            const geocodeAddress = await getAddressFromPlaceId(prediction.place_id).catch(
                () => null,
            );

            const state = geocodeAddress?.address_components?.find(
                (a) => Array.isArray(a.types) && a.types[0] === "state",
            )?.long_name;

            const city = geocodeAddress?.address_components?.find(
                (a) => Array.isArray(a.types) && a.types[0] === "city",
            )?.long_name;

            const pincode = geocodeAddress?.address_components?.find(
                (a) => Array.isArray(a.types) && a.types[0] === "postal_code",
            )?.long_name;

            const addressObj = {
                address: prediction.description || "",
                city: city || "",
                state: state || "",
                pincode: pincode || "",
                lat: geocodeAddress?.geometry.location.lat || 0,
                lng: geocodeAddress?.geometry.location.lng || 0,
            };

            selectedAddressRef.current = addressObj.address;
            setPredictions([]);
            handleAddress(addressObj);
        },
        [handleAddress],
    );

    React.useEffect(() => {
        if (!showPredictions) {
            setPredictions([]);
            return;
        }

        // for web, we need to check if the input is autofilled. If yes, then don't show predictions
        if (
            isWeb &&
            (addressInputRef.current as unknown as HTMLInputElement)?.matches(":-webkit-autofill")
        ) {
            setPredictions([]);
            return;
        }

        const controller = new AbortController();
        const fetchPredictions = async (): Promise<void> => {
            const _predictions = await getAddressPredictionsFromInput(address).catch(() => []);
            if (_predictions && controller.signal.aborted === false) {
                setPredictions(_predictions);
            }
        };

        const timer = setTimeout(fetchPredictions, 250);

        return () => {
            controller.abort();
            clearTimeout(timer);
        };
    }, [address, addressInputRef, showPredictions]);

    if (!showPredictions) {
        return null;
    }

    const containerStyle: ViewStyle = {
        padding: 0,
        top: 88,
        position: "absolute",
        left: 0,
        right: 0,
    };

    const contentStyle = {
        backgroundColor: theme["color-basic-100"],
        borderRadius: 16,
        paddingHorizontal: SpacingValue["space-medium"],
        paddingVertical: 0,
    };

    const itemStyle = {
        paddingVertical: SpacingValue["space-medium"],
        borderBottomWidth: 1,
        borderBottomColor: theme["color-basic-75"],
    };

    return (
        <Box style={containerStyle}>
            <ScrollView style={contentStyle} role="list">
                {predictions.map((prediction) => {
                    const texts = prediction.description.split(address);
                    return (
                        <Pressable
                            key={prediction.place_id}
                            onPress={() => onPredictionPress(prediction)}
                            role="listitem">
                            <Box style={itemStyle}>
                                <Text numberOfLines={1}>
                                    {texts.map((text, index) => (
                                        <Text
                                            color={
                                                text === address
                                                    ? "color-basic-30"
                                                    : "color-basic-15"
                                            }
                                            category="b3"
                                            key={index}>
                                            {prediction.description}
                                        </Text>
                                    ))}
                                </Text>
                            </Box>
                        </Pressable>
                    );
                })}
            </ScrollView>
        </Box>
    );
};
