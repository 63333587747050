import * as React from "react";

export interface IAuthModalContext {
    isOpen: boolean;
    close: () => void;
    open: () => void;
}

export const AuthModalContext = React.createContext<IAuthModalContext | null>(null);

export const AuthModalContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const close = React.useCallback(() => setIsOpen(false), []);
    const open = React.useCallback(() => setIsOpen(true), []);

    const value = React.useMemo(() => ({ isOpen, close, open }), [isOpen, close, open]);

    return <AuthModalContext.Provider value={value}>{children}</AuthModalContext.Provider>;
};
