import React from "react";

import { Product } from "@minis-consumer/interfaces/catalog";
import { useGetProducts } from "@minis-consumer/hooks/use-get-products";

type FilteredListFn = (text: string) => void;

export const isFilteredItem = ({ name, description }: Product, searchString: string): boolean => {
    if (!searchString.trim().length) {
        return false;
    }

    const trimmedSearchString = searchString.trim().toLowerCase();

    return (
        (name?.toLowerCase().includes(trimmedSearchString) ||
            description?.toLowerCase().includes(trimmedSearchString)) ??
        false
    );
};

export const useFilteredProducts = (): [FilteredListFn, Product[]] => {
    const products = useGetProducts();
    const [filteredList, setFilteredList] = React.useState<Product[]>([]);

    const filterListsFn = React.useCallback(
        (text: string): void => {
            if (text.trim().length < 3) {
                setFilteredList([]);
                return;
            }

            const filteredItems = products.filter((item: Product) => isFilteredItem(item, text));

            // always show recommended items at the top of the filtered list
            filteredItems.sort((item) => {
                return item.badges.indexOf("Recommended") > -1 ? -1 : 1;
            });

            setFilteredList(filteredItems);
        },
        [products],
    );

    return [filterListsFn, filteredList];
};
