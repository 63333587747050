import React from "react";
import { Pressable, StyleSheet } from "react-native";

import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";

import { Divider } from "@minis-consumer/components/divider";
import { LABELS } from "@minis-consumer/components/variants/constants";

const CustomisationsHeader: React.FC<{ title: string; onClose?: VoidFunction }> = ({
    title,
    onClose,
}) => {
    const { value: theme } = useTheme();
    return (
        <>
            <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                <Stack spacing={SpacingValue["space-xx-small"]}>
                    <Text category="h5" weight="bold">
                        {LABELS.TITLE}
                    </Text>
                    <Text category="b3" weight="regular" color="low">
                        {title}
                    </Text>
                </Stack>
                <Pressable onPress={onClose}>
                    <Box
                        ph={SpacingValue["space-x-small"]}
                        pv={SpacingValue["space-x-small"]}
                        direction="row"
                        alignItems="center"
                        style={[
                            styles.closeIcon,
                            {
                                backgroundColor: theme["color-basic-5"],
                            },
                        ]}>
                        <SvgIcon icon="Close" width={16} height={16} />
                    </Box>
                </Pressable>
            </Stack>
            <Divider color="color-basic-5" style={styles.headerDivider} />
        </>
    );
};

const styles = StyleSheet.create({
    closeIcon: {
        borderRadius: 100,
    },
    headerDivider: {
        marginTop: SpacingValue["space-large"],
    },
});

if (__DEV__) {
    CustomisationsHeader.displayName = "CustomisationsSheetHeader";
}

export const CustomisationsListHeader = React.memo(CustomisationsHeader);
