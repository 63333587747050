import { useEffect, useRef, useState, useCallback } from "react";
import { useController } from "@rest-hooks/react";
import { useErrorHandler } from "react-error-boundary";
import { useFocusEffect } from "@react-navigation/core";

import { useMountedRef } from "@swiggy-private/react-hooks";
import { useIsomorphicEffect } from "@swiggy-private/react-native-ui";

import { CartCoupons } from "@minis-consumer/interfaces/coupon";
import { FetchCoupons } from "@minis-consumer/resources/coupon";

import { useUserInfo } from "./use-user";

/**
 * Hook to load the coupons view for a cart.
 */
export const useLoadCoupons = (
    storeId: string,
    addressId?: string,
): {
    loading: boolean;
    coupons?: CartCoupons;
} => {
    const [loading, setLoading] = useState(true);
    const mounted = useMountedRef();
    const errorHandler = useErrorHandler();

    const [coupons, setCoupons] = useState<CartCoupons>();

    const user = useUserInfo();
    const userRef = useRef(user);

    const { fetch } = useController();

    const fetchCoupons = useCallback(async () => {
        try {
            const couponsList = await fetch(FetchCoupons, {
                storeId,
                addressId,
            });

            if (mounted.current) {
                setTimeout(() => {
                    setLoading(false);

                    mounted.current && setCoupons(couponsList);
                }, 1_000);
            }
        } catch (err) {
            if (mounted.current) {
                setLoading(false);
                errorHandler(err);
            }
        }
    }, [fetch, storeId, addressId, mounted, errorHandler]);

    useIsomorphicEffect(() => {
        if (userRef.current !== user) {
            userRef.current = user;
            setLoading(true);
        }
    }, [user]);

    useFocusEffect(
        useCallback(() => {
            setLoading(true);
            return () => {
                setLoading(false);
            };
        }, []),
    );

    useEffect(() => {
        if (loading) {
            fetchCoupons();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return { loading, coupons };
};
