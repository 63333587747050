import { ApiError, API_SUCCESS_STATUS_CODE } from "@swiggy-private/connect-api-core";
import { UserAPIService } from "@swiggy-private/connect-api-services";
import { Endpoint } from "@swiggy-private/http-client";

import { getApiClient } from "../../includes/api";
import { FeedbackSubmitEndpoint, getUserHost } from "../endpoint";

export const UserService = UserAPIService.createV1(getUserHost(), getApiClient());

export const SAVE_FEEDBACK_ERROR_MSG = "Could not save the feedback";

export const saveMinisUserEmail = async (params: { email: string }): Promise<string> => {
    const endpoint = Endpoint.from(`${getUserHost()}/api/v1/user/profile`);

    const response = await getApiClient().post<string>({
        endpoint,
        body: {
            userEmail: params.email,
        },
    });

    if (response.statusCode === API_SUCCESS_STATUS_CODE && response.data) {
        return response.data;
    }

    return Promise.reject(
        new ApiError(response.statusMessage || "Failed to add user email", response),
    );
};

export interface FeedbackSaveParams {
    body: {
        feedbacks: string[];
        bulk: boolean;

        cartId?: string;
        storeId?: string;
    };
}

export const feedbackSubmitApi = async (params: FeedbackSaveParams): Promise<void> => {
    const { feedbacks, bulk = false, cartId = "", storeId = "" } = params.body;

    const endpoint = Endpoint.from(FeedbackSubmitEndpoint);

    const response = await getApiClient().post<void>({
        endpoint,
        body: {
            feedbacks,
            bulk,
            cartId,
            storeId,
        },
    });

    if (response.statusCode === API_SUCCESS_STATUS_CODE) {
        return;
    }

    return Promise.reject(
        new ApiError(response.statusMessage || SAVE_FEEDBACK_ERROR_MSG, response),
    );
};
