import * as React from "react";
import {
    Image,
    ImageErrorEventData,
    NativeSyntheticEvent,
    StyleSheet,
    useWindowDimensions,
    ViewProps,
} from "react-native";

import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { Box } from "@swiggy-private/rn-adaptive-layout";
import { ImageEnquireMessage as IImageEnquireMessage } from "@swiggy-private/connect-chat-commons";
import { Logger } from "@minis-consumer/includes/logger";

interface ImageEnquireMessageProps {
    message: IImageEnquireMessage;
    isPublisher: boolean;
    style?: ViewProps["style"];
}

export const ImageEnquireMessage: React.FC<ImageEnquireMessageProps> = ({ message }) => {
    const { value: theme } = useTheme();

    const payload = message.payload;
    const enquiryMessage = payload.text;

    const { width } = useWindowDimensions();
    const imageSize = Math.min(width / 2, 240);
    const [hasError, setHasError] = React.useState(false);

    const imageUrl = payload.imageUrl;

    const imageBackground = {
        backgroundColor: theme["color-basic-5"],
    };

    const onError = React.useCallback(
        ({ nativeEvent: { error: err } }: NativeSyntheticEvent<ImageErrorEventData>) => {
            Logger.recordError(err);
            setHasError(true);
        },
        [],
    );

    return (
        <Box style={styles.container}>
            {hasError || !imageUrl ? null : (
                <Box style={[styles.imageContainer, imageBackground]} alignItems="center">
                    <Image
                        source={{ uri: imageUrl }}
                        style={[styles.image, { width: imageSize, height: imageSize }]}
                        resizeMode="cover"
                        onError={onError}
                    />
                </Box>
            )}

            <Box mt={SpacingValue["space-x-small"]}>
                <Text category="b2" weight="regular" color="high">
                    {enquiryMessage}
                </Text>
            </Box>
        </Box>
    );
};

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        width: "100%",
        minWidth: 100,
        maxWidth: "100%",
        overflow: "hidden",
    },
    imageContainer: {
        borderRadius: 12,
        padding: SpacingValue["space-x-small"],
    },
    image: {
        borderRadius: 8,
    },
});
