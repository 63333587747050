import * as React from "react";
import {
    FlatList,
    NativeScrollEvent,
    NativeSyntheticEvent,
    Platform,
    Pressable,
    StyleProp,
    StyleSheet,
    ViewStyle,
    InteractionManager,
} from "react-native";

import { CdnImage, ConditionalJSX, IOFlatList, StoreMedia } from "@swiggy-private/react-native-ui";
import { Box, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";

import { useIsDesktop } from "@minis-consumer/hooks/use-desktop";

import { RecommendedBadge } from "./components/recommended-badge";
import SlideArrows from "./components/slide-arrows";
import SliderIndicator from "./components/slide-indicator";

import type { Product } from "@minis-consumer/interfaces/catalog";
import { getProductFallbackImages } from "@minis-consumer/helpers/product-fallback-images";

interface PhotosCarouselProps {
    imageList: Product["images"];
    size: number;

    badges?: Product["badges"];
    style?: StyleProp<ViewStyle>;
    indicatorStyle?: StyleProp<ViewStyle>;
    indicatorSize?: number;
    optimizedDpr?: boolean;
    onItemPress?: VoidFunction;
    onImageScroll?: VoidFunction;
    productType?: Product["productType"];
}

const isWeb = Platform.OS === "web";
interface IRenderItemProps {
    onPress: VoidFunction;
    imageSize: number;
    item: string;
    optimizedDpr?: boolean;
    isInViewport?: boolean;
    productType?: Product["productType"];
}

const RenderItem: React.FC<IRenderItemProps> = ({
    onPress,
    imageSize,
    item,
    optimizedDpr,
    isInViewport,
    productType,
}) => {
    const carouselItemStyle = { width: imageSize, height: imageSize, borderRadius: 0 };

    const productImgFallback = getProductFallbackImages(productType);

    return (
        <Pressable onPress={onPress}>
            <StoreMedia
                resizeMode="contain"
                style={carouselItemStyle}
                autoPlayVideo
                disableFullScreenPlay
                mediaId={item ?? productImgFallback}
                showLoader
                optimizedDpr={optimizedDpr}
                isInViewport={isInViewport}
            />
        </Pressable>
    );
};

const RenderItemComponent = React.memo(RenderItem);

const PhotosCarouselComponent: React.FC<PhotosCarouselProps> = ({
    imageList,
    size,
    style,
    indicatorStyle,
    indicatorSize,
    badges,
    optimizedDpr,
    onItemPress,
    onImageScroll,
    productType,
}) => {
    const { value: theme } = useTheme();
    const flatlistRef = React.useRef<FlatList>(null);
    const isDesktop = useIsDesktop();

    const [currentSlideIndex, setCurrentSlideIndex] = React.useState(0);

    const borderRadius = useSelectScreen({
        lg: 16,
        default: 0,
    });

    const containerStyle: ViewStyle = {
        height: size,
        borderRadius: borderRadius,
        borderWidth: StyleSheet.hairlineWidth,
        borderColor: theme["color-basic-15"],
        overflow: "hidden",
    };

    const listStyle = {
        backgroundColor: theme["color-basic-0"],
    };

    const imageSize = React.useMemo(() => size - 2 * StyleSheet.hairlineWidth, [size]);

    const onPress = React.useCallback(() => {
        onItemPress?.();
    }, [onItemPress]);

    const renderItem = React.useCallback(
        ({ item, index }: { item: typeof imageList[number]; index: number }): JSX.Element => (
            <RenderItemComponent
                item={item}
                imageSize={imageSize}
                onPress={onPress}
                optimizedDpr={optimizedDpr}
                isInViewport={currentSlideIndex === index}
                productType={productType}
            />
        ),
        [imageSize, onPress, optimizedDpr, currentSlideIndex],
    );

    React.useEffect(() => {
        const promise = InteractionManager.runAfterInteractions(() => {
            slideFlatList(currentSlideIndex);
        });

        return promise.cancel;
    }, [currentSlideIndex]);

    const updateCurrentSlideIndex = React.useCallback(
        (e: NativeSyntheticEvent<NativeScrollEvent>): void => {
            const contentOffsetX = e.nativeEvent.contentOffset.x;
            const currentIndex = Math.round(contentOffsetX / size);
            setCurrentSlideIndex(currentIndex);

            currentIndex !== currentSlideIndex && onImageScroll?.();
        },
        [size, onImageScroll, currentSlideIndex],
    );

    const slideFlatList = React.useCallback(
        (index: number) => {
            if (index > -1 && index < imageList.length) {
                flatlistRef?.current?.scrollToOffset({
                    animated: true,
                    offset: index * size,
                });
            }
        },
        [imageList.length, size],
    );

    const slideToIndex = React.useCallback(
        (index: number) => {
            if (index > -1 && index < imageList.length) {
                setCurrentSlideIndex(index);
            }
        },
        [imageList.length, size],
    );

    const scrollToNextSlide = React.useCallback(() => {
        if (currentSlideIndex < imageList.length - 1) {
            slideToIndex(currentSlideIndex + 1);
        }
    }, [currentSlideIndex, imageList.length, slideToIndex]);

    const scrollToPreviousSlide = React.useCallback(() => {
        if (currentSlideIndex > 0) {
            slideToIndex(currentSlideIndex - 1);
        }
    }, [currentSlideIndex, slideToIndex]);

    const handleOnLayout = React.useCallback(() => {
        slideFlatList(currentSlideIndex);
    }, [slideFlatList, currentSlideIndex]);

    const scrollEnabled = Platform.OS === "android" || Platform.OS === "ios";

    return (
        <Box style={styles.webCarouselStyle}>
            <Box style={[containerStyle, style]}>
                {badges && isDesktop ? <RecommendedBadge badges={badges} /> : null}

                <IOFlatList
                    style={listStyle}
                    ref={flatlistRef}
                    data={imageList}
                    scrollEnabled={scrollEnabled}
                    onLayout={handleOnLayout}
                    disableScrollViewPanResponder={scrollEnabled !== true}
                    horizontal
                    pagingEnabled={imageList.length > 1}
                    showsHorizontalScrollIndicator={false}
                    onMomentumScrollEnd={updateCurrentSlideIndex}
                    renderItem={renderItem}
                    keyExtractor={(i: string, index: number) => i + index.toString()}
                    ListEmptyComponent={<EmptyList imgSize={imageSize} productType={productType} />}
                    contentContainerStyle={!imageList.length && styles.contentContainer}
                    initialNumToRender={2}
                    extraData={currentSlideIndex}
                />
                <ConditionalJSX condition={imageList.length > 1}>
                    <ConditionalJSX
                        condition={!isWeb}
                        fallback={
                            <SlideArrows
                                totalImages={imageList.length}
                                currentSlideIndex={currentSlideIndex}
                                scrollToNextSlide={scrollToNextSlide}
                                scrollToPreviousSlide={scrollToPreviousSlide}
                                imageSize={size}
                            />
                        }>
                        <SliderIndicator
                            images={imageList}
                            currentSlideIndex={currentSlideIndex}
                            style={indicatorStyle}
                            indicatorSize={indicatorSize}
                        />
                    </ConditionalJSX>
                </ConditionalJSX>
            </Box>

            <ConditionalJSX condition={imageList.length > 1 && isWeb}>
                <SliderIndicator
                    images={imageList}
                    currentSlideIndex={currentSlideIndex}
                    slideToIndex={slideToIndex}
                    style={indicatorStyle}
                    indicatorSize={indicatorSize}
                />
            </ConditionalJSX>
        </Box>
    );
};

interface EmptyListProps {
    imgSize: number;
    productType?: Product["productType"];
}

const EmptyList: React.FC<EmptyListProps> = React.memo(({ imgSize, productType }): JSX.Element => {
    const productImgFallback = getProductFallbackImages(productType);

    return (
        <Box justifyContent="center" alignItems="center" flex={1}>
            <CdnImage
                isImageKitEnabled
                resizeMode="contain"
                width={imgSize}
                height={imgSize}
                id={productImgFallback}
            />
        </Box>
    );
});

if (__DEV__) {
    EmptyList.displayName = "EmptyList";
}

const styles = StyleSheet.create({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    webCarouselStyle: {
        ...Platform.select({
            web: {
                position: "sticky",
                top: 0,
            },
        }),
    },
    contentContainer: {
        flex: 1,
        alignItems: "center",
    },
    badgeStyle: {
        paddingVertical: SpacingValue["space-x-small"],
        paddingHorizontal: SpacingValue["space-large"],
        borderBottomLeftRadius: 2,
        borderTopRightRadius: 2,
        borderBottomRightRadius: 24,
        borderTopLeftRadius: 24,
        position: "absolute",
        top: 0,
        left: 0,
        // @Todo add #16A47F color to Design System
        backgroundColor: "#16A47F",
    },
    badgeContainer: {
        zIndex: 1,
    },
});

export const PhotosCarousel = React.memo(PhotosCarouselComponent);
