import React, { useMemo } from "react";

import { Text } from "@swiggy-private/rn-dls";
import { truncateStringWithEllipsis } from "@swiggy-private/common-helpers";

import { VariantOption } from "@minis-consumer/interfaces/catalog";

import { DOT_SEPERATOR, VARIANT_TEST_IDS } from "../../constants";

const MAX_CHAR_LENGTH = 15;
const MAX_LENGTH_SINGLE_VARIANT = 2 * MAX_CHAR_LENGTH + 2;
/**
 *
 * @param param0 [ { name: size, displayName: sizes, values :[ S, M ] } ]
 * @returns a label with 2 Sizes
 */
export const VariantsLabelWithSize: React.FC<{ options: VariantOption[]; seperator?: string }> = ({
    options = [],
    seperator = DOT_SEPERATOR,
}) => {
    const variantsLabel = useMemo(() => {
        const { length } = options;
        const maxChars = length === 1 ? MAX_LENGTH_SINGLE_VARIANT : MAX_CHAR_LENGTH;

        const label = options.reduce((acc, curr, index) => {
            const addDotSeperator = length !== index + 1;
            const formattedName = truncateStringWithEllipsis(
                `${curr.values.length} ${curr.displayName}`,
                maxChars,
            );
            acc = `${acc}${formattedName}`;
            return addDotSeperator ? `${acc} ${seperator} ` : acc;
        }, "");

        return label;
    }, [options, seperator]);

    if (!options.length) {
        return null;
    }

    return (
        <Text
            color="low"
            category="b3"
            weight="regular"
            numberOfLines={1}
            ellipsizeMode="tail"
            testID={VARIANT_TEST_IDS.VALUE_LABEL_WITH_SIZE}>
            {variantsLabel}
        </Text>
    );
};

if (__DEV__) {
    VariantsLabelWithSize.displayName = "AllVariantsLabel";
}

export const ProductVariantsSizeLabel = React.memo(VariantsLabelWithSize);
