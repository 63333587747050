import * as React from "react";
import { StyleProp, StyleSheet, ViewStyle } from "react-native";

import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";
import { VerticalTextSlider } from "@swiggy-private/react-native-ui";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { useMount } from "@swiggy-private/react-hooks";

import { Analytics } from "@minis-consumer/analytics";

export interface SearchWidgetProps {
    searchTexts: string[];
    shouldStopMarqueeAnimation?: boolean;
    rootStyle?: StyleProp<ViewStyle>;
    inputStyle?: StyleProp<ViewStyle>;
}

const SEARCH_WIDGET_HEIGHT = 48;

const SearchWidgetComponent: React.FC<SearchWidgetProps> = React.memo(
    ({ searchTexts, shouldStopMarqueeAnimation, rootStyle, inputStyle }) => {
        const { value: theme } = useTheme();

        useMount(() => {
            Analytics.impressionEvent({
                category: "minis-search-bar",
            });
        });

        return (
            <Stack style={rootStyle}>
                <Box
                    style={[
                        styles.searchContainerstyle,
                        {
                            backgroundColor: theme["color-background-primary"],
                            borderColor: theme["color-basic-30"],
                        },
                        inputStyle,
                    ]}
                    mh={SpacingValue["space-medium"]}
                    mv={SpacingValue["space-small"]}
                    ph={SpacingValue["space-small"]}
                    direction="row"
                    alignItems="center">
                    <SvgIcon icon="Magnifier" color="color-basic-60" height={18} width={18} />
                    <Box style={styles.searchStyles} ml={SpacingValue["space-x-small"]}>
                        <VerticalTextSlider
                            texts={searchTexts}
                            height={SEARCH_WIDGET_HEIGHT}
                            slideDelay={2000}
                            stopAnimation={shouldStopMarqueeAnimation}
                        />
                    </Box>
                </Box>
            </Stack>
        );
    },
);

if (__DEV__) {
    SearchWidgetComponent.displayName = "SearchWidgetComponent";
}

const styles = StyleSheet.create({
    searchContainerstyle: {
        height: SEARCH_WIDGET_HEIGHT,
        borderWidth: 1,
        borderStyle: "solid",
        borderRadius: 12,
        overflow: "hidden",
        flex: 1,
    },
    searchStyles: {
        height: "100%",
    },
});

export const SearchWidget = React.memo(SearchWidgetComponent);
