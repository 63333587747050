import * as React from "react";

import { CustomMessageTypes, ViewStoryMessagePayload } from "@swiggy-private/connect-chat-commons";
import { useChatSdk, useSendMessage } from "@swiggy-private/connect-chat-ui";
import { SdkConversation } from "@swiggy-private/connect-chat-sdk";

import { Analytics } from "@minis-consumer/analytics";
import { ENQUIRE_HALF_CARD_EVENT_CATEGORY } from "@minis-consumer/constants/analytics";

import { useCreateChatConversation } from "./use-start-chat-conversation";

type SendEnquiryMessageProps = Omit<ViewStoryMessagePayload, "storeId">;

export const useEnquireStoryChat = (
    storeId: string,
): {
    sendEnquiryMessage: (props: SendEnquiryMessageProps) => void;
    blockedInfo: SdkConversation["blockedInfo"];
} => {
    const [messagePayload, setMessagePayload] = React.useState<ViewStoryMessagePayload>();

    const { conversationId, blockedInfo } = useCreateChatConversation({
        storeId,
        ignoreCache: true,
    });
    const sendMessage = useSendMessage(conversationId ?? "");
    const sdk = useChatSdk();

    const sendEnquiryMessage = React.useCallback(
        ({ text, imageUrl, storyData, highlightName }: SendEnquiryMessageProps) => {
            setMessagePayload({
                text,
                imageUrl,
                storyData,
                storeId,
                highlightName,
            });
        },
        [storeId],
    );

    React.useEffect(() => {
        if (!conversationId || !sdk || !messagePayload) {
            return;
        }

        const message = sdk.prepareMessage<ViewStoryMessagePayload>({
            type: CustomMessageTypes.VIEW_STORY,
            payload: messagePayload,
        });

        const messageRes = sendMessage(message);

        if (messageRes) {
            Analytics.clickEvent({
                category: ENQUIRE_HALF_CARD_EVENT_CATEGORY,
                context: `messageId: ${messageRes.message.id}, initiatedBy: customer`,
            });
        }

        setMessagePayload(undefined);
    }, [messagePayload, conversationId, sdk, sendMessage]);

    return { sendEnquiryMessage, blockedInfo };
};
