import React from "react";
import { StyleSheet, ViewStyle, ColorValue, ViewProps } from "react-native";

import { Stack, Box } from "@swiggy-private/rn-adaptive-layout";
import { Text, SpacingValue, useTheme } from "@swiggy-private/rn-dls";

interface DashBorderLineProps {
    borderColor: ColorValue;
    text: string;
    style?: ViewStyle;
    type?: "dashed" | "dotted" | "solid";
}

export const DashBorderLineWithText: React.FC<ViewProps & DashBorderLineProps> = ({
    text,
    borderColor,
    style,
    type = "dashed",
    ...props
}) => {
    const borderType = { borderStyle: type };
    const { value: theme } = useTheme();

    const textFontStyles = {
        letterSpacing: 2,
        fontFamily: theme["text-body-3-regular-font-family"],
        fontSize: theme["text-body-3-regular-font-size"],
        lineHeight: theme["text-body-3-regular-line-height"],
    };

    return (
        <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={[style, styles.container]}
            {...props}>
            <Box
                style={[
                    styles.dashedBorder,
                    {
                        borderColor,
                    },
                    borderType,
                ]}
            />
            <Text category="b1" color="color-basic-45" style={[styles.textStyles, textFontStyles]}>
                {text}
            </Text>
            <Box
                style={[
                    styles.dashedBorder,
                    {
                        borderColor,
                    },
                    borderType,
                ]}
            />
        </Stack>
    );
};

const styles = StyleSheet.create({
    dashedBorder: {
        borderWidth: 1,
        width: "10%",
        opacity: 0.1,
    },
    textStyles: {
        paddingHorizontal: SpacingValue["space-x-small"],
    },
    container: {
        width: "100%",
    },
});
