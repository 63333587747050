import React from "react";

import { Analytics } from "@minis-consumer/analytics";

type ReturnType = {
    showStarSellerInfo: boolean;
    onCloseStarSellerInfo: VoidFunction;
    onStarSellerBadgeMount: VoidFunction;
    onStarSellerBadgePress: VoidFunction;
};

const ANALYTICS_CATEGORY = "star-seller-badge";

/**
 * This hook provides a way to reuse the logic for showing the star seller info modal.
 */
export const useStarSellerInfo = (storeId: string): ReturnType => {
    const [showStarSellerInfo, setShowStarSellerInfo] = React.useState(false);

    const starSellerAnalyticsPayload = React.useMemo(() => {
        return { category: ANALYTICS_CATEGORY, label: storeId };
    }, [storeId]);

    const onCloseStarSellerInfo = React.useCallback(() => {
        setShowStarSellerInfo(false);
    }, []);

    const onStarSellerBadgeMount = React.useCallback(() => {
        Analytics.impressionEvent(starSellerAnalyticsPayload);
    }, [starSellerAnalyticsPayload]);

    const onStarSellerBadgePress = React.useCallback(() => {
        Analytics.clickEvent(starSellerAnalyticsPayload);

        setShowStarSellerInfo(true);
    }, [starSellerAnalyticsPayload]);

    return {
        showStarSellerInfo,
        onCloseStarSellerInfo,
        onStarSellerBadgeMount,
        onStarSellerBadgePress,
    };
};
