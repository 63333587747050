import React from "react";
import { StyleSheet, ViewStyle, StyleProp } from "react-native";

import { FormFieldProps, FieldType, IDynamicForm } from "@minis-consumer/interfaces/dynamic-forms";

import { useMount } from "@swiggy-private/react-hooks";
import { Input, SpacingValue, Text } from "@swiggy-private/rn-dls";
import { Box } from "@swiggy-private/rn-adaptive-layout";

import { UploadFile } from "./file-upload";

const getField = (
    field: FormFieldProps,
    handleInputChange: (fieldName: string, value: string) => void,
    disabled?: boolean,
    styles?: StyleProp<ViewStyle>,
    // eslint-disable-next-line max-params
): React.ReactElement => {
    const { id, answerType, ansPlaceHolder, answer } = field;
    const onInputChange = (text: string): void => {
        handleInputChange(id, text);
    };

    const onFileUplooad = (media?: string[]): void => {
        if (!media) {
            return;
        }

        handleInputChange(id, media?.[0] ?? undefined);
    };

    switch (answerType) {
        case FieldType.TEXT:
            return (
                <Input
                    placeholder={ansPlaceHolder}
                    style={styles}
                    onChangeText={onInputChange}
                    defaultValue={answer}
                    disabled={disabled}
                />
            );
        case FieldType.NUMBER:
            return (
                <Input
                    placeholder={ansPlaceHolder}
                    style={styles}
                    // For App
                    keyboardType="numeric"
                    // For Web
                    isNumeric
                    onChangeText={onInputChange}
                    defaultValue={answer}
                    disabled={disabled}
                />
            );

        case FieldType.FILE:
            return (
                <UploadFile
                    onUpload={onFileUplooad}
                    mediaId={answer}
                    disabled={disabled}
                    customStyles={styles}
                />
            );
    }
};

const DynamicFormComponent: React.FC<{
    formData: IDynamicForm;
    validateForm: (formIndex: number, valid: boolean) => void;
    formIndex: number;
    setFormData: (index: number, formData: Record<string, string>) => void;
    styles?: StyleProp<ViewStyle>;
    readonly?: boolean;
}> = ({ formData, formIndex, validateForm, styles: customStyle, setFormData, readonly }) => {
    const formValues = React.useRef<Record<string, string>>({});

    useMount(() => {
        const existingValues = formData.reduce((prev, curr) => {
            return { ...prev, [curr.id]: curr.answer };
        }, {});
        formValues.current = existingValues;
        onValidateForm(existingValues);
    });

    const onValidateForm = (updatedFormValues: Record<string, string>): void => {
        const requiredFieldsFilled = formData
            .filter((field) => field.required)
            .every((field) => {
                return (
                    (field.id in updatedFormValues &&
                        updatedFormValues[field.id] &&
                        updatedFormValues[field.id].trim() !== "") ||
                    (Array.isArray(updatedFormValues) && updatedFormValues.length > 0)
                );
            });

        validateForm(formIndex, requiredFieldsFilled);
        if (requiredFieldsFilled) {
            setFormData(formIndex, updatedFormValues);
        }
    };

    const handleInputChange = (fieldName: string, value: string): void => {
        const updatedFormValues = { ...formValues.current, [fieldName]: value };

        formValues.current = updatedFormValues;

        onValidateForm(updatedFormValues);
    };

    const renderLabelComponent = (label: string, required?: boolean): React.ReactElement => {
        return (
            <Text category="b2" weight="medium" color="color-basic-alpha-60" numberOfLines={4}>
                {label} {!required ? " (Optional)" : ""}
            </Text>
        );
    };

    return (
        <Box style={customStyle}>
            {formData.map((field, index) => {
                if (!field.answer && readonly) {
                    return null;
                }

                const marginBottom =
                    index !== formData.length - 1 ? SpacingValue["space-large"] : 0;
                return (
                    <Box key={index}>
                        {renderLabelComponent(field.question, field.required)}
                        <Box>
                            {getField(field as FormFieldProps, handleInputChange, readonly, [
                                styles.input,
                                { marginBottom },
                            ])}
                        </Box>
                    </Box>
                );
            })}
        </Box>
    );
};

export const DynamicForm = React.memo(DynamicFormComponent);

const styles = StyleSheet.create({
    input: {
        marginTop: SpacingValue["space-small"],
    },
});
