import type { FirebaseApp } from "firebase/app";
import { getMessaging, getToken, Messaging, isSupported } from "firebase/messaging";
import { Logger } from "./logger";

let messaging: Messaging | null = null;

export async function getPushNotificationToken(userId: string): Promise<string | null> {
    const fallback = `customer_${userId}`;
    return messaging == null
        ? fallback
        : doGetPushNotificationToken(messaging).then((token) => {
              return token || fallback;
          });
}

const VAPID_KEY =
    "BIFR4SCdkHCTuEW20MnhLTtj2f_4mDr8eCzCuFRQC0brVwF2Rxal5dvK06z8CGgqSeEwS1DzWjVUW6A04eAVD-U";

export async function initializePushNotification(app: FirebaseApp): Promise<void> {
    // Initialize Firebase Cloud Messaging and get a reference to the service
    const isWindowSupported = await isSupported();

    if (isWindowSupported) {
        messaging = getMessaging(app);
    } else {
        messaging = null;
        Logger.info("Window not supported for FCM");
    }
}

async function doGetPushNotificationToken(messagingInstance: Messaging): Promise<string | null> {
    return requestNotificationPermission()
        .then(() => getToken(messagingInstance, { vapidKey: VAPID_KEY }))
        .then((currentToken) => {
            if (currentToken) {
                return currentToken;
            } else {
                __DEV__ &&
                    console.log(
                        "No registration token available. Request permission to generate one.",
                    );
                return null;
            }
        })
        .catch((err) => {
            __DEV__ && console.log(err);
            return null;
        });
}

async function requestNotificationPermission(): Promise<void> {
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
        __DEV__ && console.log("Notification permission granted.");
        return Promise.resolve();
    } else {
        return Promise.reject(new Error(permission));
    }
}
