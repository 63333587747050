import { Platform } from "react-native";
import { AppConfig } from "@minis-consumer/config";

// TODO: remove when refactoring
export const NAVBAR_HEIGHT = 64;

const isLocalhost = typeof window !== "undefined" && window?.location?.hostname === "localhost";

export const GOOGLE_API_KEY =
    Platform.OS === "web" && !isLocalhost
        ? "AIzaSyD6b95EVxnrx5Vcdh_1AqcsI8YEeX7Kw60"
        : AppConfig.GOOGLE_API_KEY;

export const GOOGLE_STATIC_MAP_API_URL = "https://maps.googleapis.com/maps/api/staticmap";

export const CANCELLATION_POLICIES = [
    "Full refund if you cancel it before the order is accepted by us. For any queries on cancellations reach out to us via chat",
];

export const BOOKING_CANCELLATION_POLICIES = [
    "Full refund if you cancel it before the order is accepted by us",
    "For any queries on cancellations reach out to us via chat",
];

export const DIGITAL_CANCELLATION_POLICIES = [
    "Order once placed cannot be canceled",
    "If facing download issues post successful payment reach out to us via chat",
];

export const PLAN_CANCELLATION_POLICIES = [
    "Full refund if you cancel it before the order is accepted by us",
    "For any queries on cancellations reach to us via chat",
];

export const MINIS_DOMAIN = "mini.store";
export const MINIS_SITE_DOMAIN = "mini.site";
export const MINIS_DOMAIN_REGEX = /.+\.mini\.store$/;
export const MINIS_SITE_DOMAIN_REGEX = /.+\.mini\.site$/;
export const MINIS_VERSION_CODE = 10001;

export const TERMS_AND_CONDITIONS_URL = `https://${MINIS_DOMAIN}/terms-of-use`;
export const ADDRESS_LAT_LNG_ID = "latLng";
export const GENERIC_ERROR_MESSAGE = "Oops! something went wrong";
export const ADD_TO_CART = "Add to Cart";
export const BLACKLISTED_API_ENDPOINTS = [/message-set/];

export const COMMON_COPIES = {
    CHAT: "Chat",
};

export const RUPEE_SYMBOL = "₹";

export const DEFAULT_STORE_VERSION = "v1-store";
