export const HOME_INTRODUCTION_IMAGE_ID = "minis/introducing_widget";
export const CART_GENERIC_ERROR_BACKGROUND_ID = "minis/cart_generic_error_background";
export const STOREFRONT_OFFER_LOGO = "minis/storefront_offer_logo";
export const SEGMENTED_COUPON_LOGO = "minis/for_you_offer";
export const MASTHEAD_LOTTIE = "static-assets/masthead_lotttie.json";
export const MASTHEAD_LAST_FRAME = "static-assets/masthead_lottie_last_frame";

/** This image is required on the HP for tooltip */
export const TOOLTIP_NOTIFICATION_BELL = "minis/notification_bell";

export const HOME_AVATAR_ICON = {
    id: "minis/avatar",
    width: 28,
    height: 28,
};

export const MINIS_LOGO = "minis/minis_logo";
export const MINIS_LOGO_ORANGE = "static-assets/swiggy_minis_orange";
export const INSTAGRAM_EMPTY = "minis/instagram_empty";

type StaticImage =
    | string
    | {
          id: string;
          width?: number;
          height?: number;
      };

export const StaticImages: StaticImage[] = [
    HOME_INTRODUCTION_IMAGE_ID,
    CART_GENERIC_ERROR_BACKGROUND_ID,
    HOME_AVATAR_ICON,
    MINIS_LOGO,
    INSTAGRAM_EMPTY,
    TOOLTIP_NOTIFICATION_BELL,
    MASTHEAD_LOTTIE,
    MASTHEAD_LAST_FRAME,
];

export const PRODUCT_IMG_FALLBACK = "minis/product_img_fallback";

export const SEGMENTED_COUPON_BANNER = {
    IMG_ID: "minis/segmented_offer",
    HEIGHT: 16,
    WIDTH: 52,
};
