import * as React from "react";
import { useSuspense } from "@rest-hooks/react";

import { StoreInstagram } from "@minis-consumer/resources/store";
import { InstagramFetchAllMediaResponse } from "@minis-consumer/api/instagram";
import { InstagramMedia } from "@minis-consumer/interfaces/store";

import { useStoreInfo } from "./use-store";

type StoreInstagramResponseType = {
    instagramData?: InstagramFetchAllMediaResponse;
};

export const useStoreInstagram = (): StoreInstagramResponseType => {
    const { storeId } = useStoreInfo();
    const instagramData = useSuspense(StoreInstagram, { storeId });

    const data = React.useMemo(
        () => (instagramData?.user ? prepareInstagramMedia(instagramData) : undefined),
        [instagramData],
    );

    return React.useMemo(
        () => ({
            instagramData: data,
        }),
        [data],
    );
};

const prepareInstagramMedia = (
    instagramData: InstagramFetchAllMediaResponse,
): InstagramFetchAllMediaResponse => {
    const { user, media } = instagramData;

    if (!media?.data) {
        return { user, media: { data: [] } };
    }

    const photos = media.data.reduce((acc, cur) => {
        if (cur.media_type === "CAROUSEL_ALBUM" && Array.isArray(cur.children?.data)) {
            acc.push(...(cur.children?.data ?? []).filter((i) => i.media_type === "IMAGE"));
        } else if (cur.media_type === "IMAGE") {
            acc.push(cur);
        }

        return acc;
    }, [] as InstagramMedia[]);

    return { user, media: { data: photos } };
};
