import { ApiError, API_SUCCESS_STATUS_CODE } from "@swiggy-private/connect-api-core";
import { Endpoint } from "@swiggy-private/http-client";

import { Order, ReturnOrderParams } from "@minis-consumer/interfaces/order";
import { getApiClient } from "@minis-consumer/includes/api";
import { ICartGuestDetails } from "@minis-consumer/interfaces/cart";
import { OrderEntity } from "@minis-consumer/resources/order";

import { OrderEndpoint, OrderV2Endpoint } from "../endpoint";
import { CheckoutStatus } from "./types";
import { DEFAULT_PAGE_SIZE } from "../constants";

export interface CreateOrderParams {
    storeId: string;
    addressId?: string;
    totalBillAmount: number;
    instructions?: string;
    addressMeta?: {
        city?: string;
        state?: string;
        postalCode?: string | number;
    };
    guestUserDetails?: ICartGuestDetails;
}

export interface CreateOrderResponse {
    checkoutStatus: CheckoutStatus;
    finalBillValue?: number;
    orderDetails?: Order;
    message?: string;
}

export const createOrderApi = async (params: CreateOrderParams): Promise<CreateOrderResponse> => {
    const endpoint = Endpoint.from(`${OrderEndpoint}/stores/{%%storeId%%}`, {
        storeId: params.storeId,
    });

    const response = await getApiClient().post<CreateOrderResponse>({
        endpoint,
        body: {
            dropAddressId: params.addressId,
            totalBillAmount: params.totalBillAmount,
            instructions: params.instructions,
            dropAddressMetadata: params.addressMeta,
            guestUserDetails: params.guestUserDetails,
        },
    });

    if (response.statusCode === API_SUCCESS_STATUS_CODE && response.data) {
        return response.data;
    }

    return Promise.reject(
        new ApiError(response.statusMessage || "Failed to create an order", response),
    );
};

export const confirmOrderApi = async (orderId: string, paymentId?: string): Promise<Order> => {
    const endpoint = Endpoint.from(`${OrderEndpoint}/{%%orderId%%}/confirm`, { orderId });
    const response = await getApiClient().post<Order>({
        endpoint,
        queryParams: {
            paymentId: paymentId ?? "",
        },
        body: {},
    });

    if (response.statusCode === API_SUCCESS_STATUS_CODE && response.data) {
        return response.data;
    }

    return Promise.reject(
        new ApiError(response.statusMessage || "Failed to confirm the order", response),
    );
};

export const getOrderDetailsApi = async (orderId: string, orderKey?: string): Promise<Order> => {
    const endpoint = Endpoint.from(`${OrderEndpoint}/{%%orderId%%}/details`, { orderId });
    const response = await getApiClient().get<Order>({
        endpoint,
        queryParams: orderKey ? { orderKey } : undefined,
    });

    if (response.statusCode === API_SUCCESS_STATUS_CODE && response.data) {
        return response.data;
    }

    return Promise.reject(
        new ApiError(response.statusMessage || "Failed to confirm the order", response),
    );
};

export const cancelOrderApi = async (orderId: string): Promise<Order> => {
    const endpoint = Endpoint.from(`${OrderEndpoint}/{%%orderId%%}/cancel`, { orderId });
    const response = await getApiClient().post<Order>({ endpoint, body: {} });

    if (response.statusCode === API_SUCCESS_STATUS_CODE && response.data) {
        return response.data;
    }

    return Promise.reject(
        new ApiError(response.statusMessage || "Failed to cancel the order", response),
    );
};

export interface AllOrdersParams {
    storeId?: string;
    cursor?: string;
    pageSize?: number;
}

export interface AllOrdersResponse {
    cursor: string;
    orders: Order[];
}

export const getAllOrdersApi = async (params: AllOrdersParams): Promise<AllOrdersResponse> => {
    const { cursor, pageSize, storeId } = params;

    const endpoint = Endpoint.from(OrderV2Endpoint);

    const response = await getApiClient().get<AllOrdersResponse>({
        endpoint,
        queryParams: {
            cursor: cursor ?? "",
            pageSize: String(pageSize || DEFAULT_PAGE_SIZE),
            storeId: storeId ?? "",
        },
    });

    if (response.statusCode === API_SUCCESS_STATUS_CODE && response.data) {
        return response.data;
    }

    return Promise.reject(
        new ApiError(response.statusMessage || "Failed to fetch the order list", response),
    );
};

export type RateOrderParams = {
    orderId: string;
    value: number;
    description?: string;
    imageIds?: string[];
};

export const rateOrderApi = async (params: RateOrderParams): Promise<Order> => {
    const { orderId, value, description, imageIds } = params;

    const endpoint = Endpoint.from(`${OrderEndpoint}/{%%orderId%%}/rating`, { orderId });
    const response = await getApiClient().patch<Order>({
        endpoint,
        body: {
            value,
            description,
            imageIds,
        },
    });

    if (response.statusCode === API_SUCCESS_STATUS_CODE && response.data) {
        return response.data;
    }

    return Promise.reject(
        new ApiError(response.statusMessage || "Failed to rate the order", response),
    );
};

type ReturnOrderApiType = (orderId: string, params: ReturnOrderParams) => Promise<OrderEntity>;

export const returnOrderApi: ReturnOrderApiType = async (orderId, params) => {
    const endpoint = Endpoint.from(`${OrderEndpoint}/{%%orderId%%}/return`, { orderId });
    const response = await getApiClient().post<OrderEntity>({ endpoint, body: { ...params } });

    if (response.statusCode === API_SUCCESS_STATUS_CODE && response.data) {
        return response.data;
    }

    return Promise.reject(
        new ApiError(response.statusMessage || "Failed to return the order", response),
    );
};
