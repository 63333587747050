/* eslint-disable @typescript-eslint/no-var-requires */
if (typeof Object.entries !== "function") {
    require("object.entries").shim();
}

if (typeof Object.fromEntries !== "function") {
    require("object.fromentries").shim();
}

export {};
