import { SdkConversation } from "@swiggy-private/connect-chat-sdk";

export const checkIsBlockedByCustomer = (blockedInfo: SdkConversation["blockedInfo"]): boolean => {
    if (!blockedInfo?.blockedBy?.length) {
        return false;
    }

    return blockedInfo?.blockedBy?.indexOf("CUSTOMER") > -1 ?? false;
};

export const getChatBlockedData = (
    conversation: SdkConversation,
    isBlockedByCustomer: boolean,
): {
    blockedText: string;
    unblockText: string;
    chatUnavailableText: string;
} | null => {
    if (!conversation.blockedInfo?.blockedBy?.length) {
        return null;
    }

    return {
        blockedText: isBlockedByCustomer ? "You blocked this store" : "",
        unblockText: "Unblock to chat",
        chatUnavailableText: isBlockedByCustomer ? "" : "Chat is unavailable with this seller",
    };
};
