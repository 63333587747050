import { ConfigInfo } from "@minis-consumer/interfaces/config-info";
import { getObjectValue, removeValue, setValue } from "@swiggy-private/common-helpers";

const STORAGE_KEY = "@configInfo";

let configInfoCache: ConfigInfo | null = null;

/**
 * Saves the given config info in storage.
 *
 */
export const setConfigInfo = async (configInfo: ConfigInfo): Promise<void> => {
    configInfoCache = configInfo;
    return setValue(STORAGE_KEY, configInfo);
};

/**
 * Removes the config info from storage and cache.
 */
export const removeConfigInfo = (): Promise<void> => {
    configInfoCache = null;
    return removeValue(STORAGE_KEY);
};

/**
 * Returns the config info from cache.
 * @returns The {@link ConfigInfo}
 */
export const getConfigInfo = (): ConfigInfo | null => configInfoCache;

/**
 * Loads config info into cache from the storage.
 * @returns The {@link Config}
 */
export const loadConfigInfo = async (): Promise<ConfigInfo | null> => {
    configInfoCache = await getObjectValue<ConfigInfo>(STORAGE_KEY);
    return configInfoCache;
};
