import React from "react";
import { Platform, Pressable, StyleProp, StyleSheet, TextStyle, ViewStyle } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { Snackbar, SpacingValue, Text, TextProps, useTheme } from "@swiggy-private/rn-dls";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { useSelectScreen } from "@swiggy-private/rn-adaptive-layout";

import { Analytics } from "@minis-consumer/analytics";

import { ANALYTICS_COPIES, COUPON_ERROR_SNACKBAR_CONSTANTS } from "./constants";

type CouponErrorSnackbarProps = {
    errorText: string;
    canShowSnackbar: boolean;
    dismissSnackbar: VoidFunction;

    isLgScreenModal?: boolean;
    ctaText?: string;
    extraStyle?: StyleProp<ViewStyle>;
};

const CouponErrorSnackbarComponent: React.FC<CouponErrorSnackbarProps> = ({
    errorText,
    extraStyle,
    ctaText,
    canShowSnackbar,
    dismissSnackbar,
    isLgScreenModal = false,
}) => {
    const insets = useSafeAreaInsets();
    const { value: theme } = useTheme();

    const message = React.useMemo(() => {
        if (errorText.trim().length === 0) {
            return COUPON_ERROR_SNACKBAR_CONSTANTS.ERROR_FALLBACK_MSSG;
        }

        return errorText;
    }, [errorText]);

    const btnText = React.useMemo(() => {
        if (ctaText && ctaText.trim().length > 0) {
            return ctaText;
        }

        return COUPON_ERROR_SNACKBAR_CONSTANTS.CTA_TEXT_FALLBACK;
    }, [ctaText]);

    const snackbarStyle = useSelectScreen({
        lg: {
            marginBottom: SpacingValue["space-x-large"],
            maxWidth: COUPON_ERROR_SNACKBAR_CONSTANTS.MAX_SNACKBAR_WIDTH,
            left: 0,
            rigth: 0,
            marginHorizontal: isLgScreenModal ? SpacingValue["space-x-large"] : undefined,
            paddingVertical: SpacingValue["space-small"],
        },
        default: {
            marginBottom:
                Platform.OS !== "web"
                    ? Math.max(insets.bottom, SpacingValue["space-x-large"])
                    : SpacingValue["space-x-large"],
            marginHorizontal: SpacingValue["space-medium"],
        },
    });

    const ctaCategory: TextProps["category"] = useSelectScreen({
        default: "btn5",
        md: "btn5",
        lg: "btn3",
    });

    const msgStyle: TextStyle = useSelectScreen({
        lg: {
            fontSize: 16,
            lineHeight: 19,
            fontWeight: "600",
        },
        default: {
            fontSize: 13,
            lineHeight: 16,
            fontWeight: "500",
        },
    });

    const commonMsgStyle: TextStyle = {
        color: theme["color-basic-0"],
        letterSpacing: -0.3,
    };

    const childrenStyle = StyleSheet.flatten([msgStyle, commonMsgStyle]);

    const hitSlop = {
        top: 6,
        left: 6,
        bottom: 6,
        right: 6,
    };

    const onPressCta = React.useCallback(() => {
        Analytics.clickEvent({
            category: ANALYTICS_COPIES.TOAST_CTA_CATEGORY,
            context: errorText,
        });

        dismissSnackbar();
    }, [dismissSnackbar, errorText]);

    React.useEffect(() => {
        if (!canShowSnackbar) {
            return;
        }

        for (const key in ANALYTICS_COPIES) {
            Analytics.impressionEvent({
                category: ANALYTICS_COPIES[key],
                context: errorText,
            });
        }
    }, [canShowSnackbar, errorText]);

    if (!canShowSnackbar) {
        return null;
    }

    return (
        <Snackbar
            style={[styles.root, snackbarStyle, extraStyle]}
            isAutoDismiss={false}
            isActive={canShowSnackbar}
            childrenStyle={childrenStyle}
            touchableRight={
                <Pressable onPress={onPressCta} hitSlop={hitSlop}>
                    <Text category={ctaCategory} color="color-positive-200">
                        {btnText}
                    </Text>
                </Pressable>
            }
            accessoryLeft={
                <SvgIcon icon="AlertCircle" color="color-critical-300" height={20} width={20} />
            }>
            {message}
        </Snackbar>
    );
};

const styles = StyleSheet.create({
    root: {
        borderRadius: 12,
        paddingVertical: SpacingValue["space-x-small"],
        paddingHorizontal: SpacingValue["space-medium"],
    },
});

export const CouponErrorSnackbar = React.memo(CouponErrorSnackbarComponent);
