import React, { memo } from "react";
import { StyleSheet } from "react-native";

import { Stack, Box, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text } from "@swiggy-private/rn-dls";

import { Product } from "@minis-consumer/interfaces/catalog";
import { useIsDesktopWeb } from "@minis-consumer/hooks/use-dweb";
import { getProductImages } from "@minis-consumer/helpers/catalog";
import { PhotosCarousel } from "@minis-consumer/components/photos-carousel";

import { VariantOptionsSelection } from "../options-selection";

interface IVariantOptions {
    product: Product;
    onAdd: (variantId?: string) => void;
    onRemove: (variantId?: string) => void;

    onClose?: VoidFunction;
}

// only used for desktop.
const IMAGE_SIZE = 420;
const MODAL_WIDTH = 784;

const VariantOptionsComponent: React.FC<IVariantOptions> = ({
    product,
    onAdd,
    onRemove,
    onClose,
}) => {
    const { id, name, productType } = product;

    const isDesktop = useIsDesktopWeb();

    const imageContainer = useSelectScreen({
        lg: {
            width: IMAGE_SIZE,
        },
        md: {
            width: "60%",
        },
        default: {
            width: "100%",
        },
    });

    const images = React.useMemo(
        () =>
            getProductImages(
                product,
                {
                    height: IMAGE_SIZE,
                    width: IMAGE_SIZE,
                    qualityAuto: "eco",
                },
                true,
            ),
        [product],
    );

    if (isDesktop) {
        return (
            <Stack
                direction="row"
                style={[styles.desktopContainer]}
                spacing={SpacingValue["space-x-large"]}>
                <Stack direction="row" spacing={SpacingValue["space-medium"]}>
                    <Box style={imageContainer}>
                        <PhotosCarousel
                            imageList={images}
                            size={IMAGE_SIZE}
                            badges={product.badges}
                            productType={product.productType}
                        />
                    </Box>
                </Stack>
                <Stack flex={1} spacing={SpacingValue["space-large"]}>
                    <Text category="h3" color="highest" numberOfLines={2}>
                        {name}
                    </Text>
                    <VariantOptionsSelection
                        id={id}
                        onAdd={onAdd}
                        onRemove={onRemove}
                        onClose={onClose}
                        type={productType}
                    />
                </Stack>
            </Stack>
        );
    }

    return (
        <Stack spacing={SpacingValue["space-large"]}>
            <Box ph={SpacingValue["space-medium"]}>
                <Text category="h3" color="highest" numberOfLines={2}>
                    {name}
                </Text>
            </Box>
            <VariantOptionsSelection
                id={id}
                onAdd={onAdd}
                onRemove={onRemove}
                onClose={onClose}
                type={productType}
            />
        </Stack>
    );
};

export const VariantOptions = memo(VariantOptionsComponent);

const styles = StyleSheet.create({
    desktopContainer: {
        width: MODAL_WIDTH,
        maxWidth: MODAL_WIDTH,
    },
});
