import React from "react";
import { ViewStyle, ColorValue } from "react-native";

import { useTheme } from "@swiggy-private/rn-dls";
import { Box } from "@swiggy-private/rn-adaptive-layout";

interface SlateProps extends ViewStyle {
    height: ViewStyle["height"];
    width?: ViewStyle["width"];
    bgColor?: ColorValue;
    borderRadius?: number;
    marginTop?: number;
}

const SlateComponent: React.FC<SlateProps> = ({
    height,
    width = "100%",
    bgColor,
    borderRadius = 4,
    marginTop = 0,
}) => {
    const { value: theme } = useTheme();

    const slateStyle = {
        height,
        width,
        backgroundColor: bgColor ?? theme["color-basic-5"],
        borderRadius,
        marginTop,
    };
    return <Box style={slateStyle} />;
};

export const Slate = React.memo(SlateComponent);
