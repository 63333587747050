import React, { useCallback, useEffect } from "react";
import {
    ListRenderItem,
    StyleSheet,
    Dimensions,
    Animated,
    Platform,
    View,
    LayoutChangeEvent,
    Pressable,
    FlatListProps,
    ViewabilityConfig,
    FlatList,
    ColorValue,
} from "react-native";
import { NavigationProp } from "@react-navigation/native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { Box } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text, Button, TouchableComponent } from "@swiggy-private/rn-dls";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { IUserStoryItem } from "@swiggy-private/react-native-stories";
import { StoriesAnalytics } from "@swiggy-private/react-native-stories";

import { RouteList } from "@minis-consumer/interfaces/route";
import { ProductLargeCard } from "@minis-consumer/components/catalog/product-variants/large-card";
import { useStoryEnquiryModalHandler } from "@minis-consumer/hooks/use-view-story-modal-handler";
import { Product } from "@minis-consumer/interfaces/catalog";

import { useNavigation } from "../hooks/use-navigation";
import { CHAT_MSG, CHAT_TEXT } from "../constants";

interface IHeaderProps {
    storeId: string;
    highlightName: string;
    listData: Product[];
    story: IUserStoryItem;
    slug: string;
    brandColor?: ColorValue;
    analytics: StoriesAnalytics;

    goToChat: VoidFunction;
    onClose: VoidFunction;
    goToPDP: (id: string) => void;
    toggleAnimation: (isOpen: boolean) => void;
}

const isWeb = Platform.OS === "web";

const width = Dimensions.get("window").width;
const imageSize = width <= 360 ? 100 : 120;

const viewabilityConfig: ViewabilityConfig = {
    waitForInteraction: false,
    itemVisiblePercentThreshold: 50,
    minimumViewTime: 300,
};

const FooterComponent: React.FC<IHeaderProps> = ({
    storeId,
    story,
    highlightName,
    slug,
    listData,
    brandColor,
    analytics,
    goToChat,
    goToPDP,
    onClose,
    toggleAnimation,
}) => {
    const footerRef = React.useRef<View>(null);
    const [actionsPos, setActionsPos] = React.useState(0);
    const animValue = React.useMemo(() => new Animated.Value(actionsPos), [actionsPos]);
    const isPrevious = React.useRef(false);
    const insets = useSafeAreaInsets();

    const navigation = useNavigation<NavigationProp<RouteList>>();

    const [isOpen, , enquiryModalHandler, { isMessageSent, setIsMessageSent }] =
        useStoryEnquiryModalHandler();

    const cardWidth = Math.ceil((width - SpacingValue["space-medium"] * 2) * 0.82);

    const ItemSeparatorComponent: React.FC = () => <Box ml={SpacingValue["space-medium"]} />;

    useEffect(() => {
        if (!footerRef.current || isPrevious.current) {
            return;
        }

        requestAnimationFrame(() => {
            Animated.timing(animValue, {
                toValue: 0,
                delay: 2000,
                duration: 500,
                useNativeDriver: !isWeb,
            }).start(({ finished }) => {
                if (finished) {
                    isPrevious.current = true;
                }
            });
        });
    }, [animValue]);

    useEffect(() => {
        toggleAnimation(isOpen);
    }, [isOpen, toggleAnimation]);

    useEffect(() => {
        if (isMessageSent) {
            analytics.sendOpenChatImpression();
            setTimeout(() => {
                setIsMessageSent?.(false);
            }, 2000);
        }
    }, [analytics, isMessageSent, setIsMessageSent]);

    const onLayout = (e: LayoutChangeEvent): void => {
        if (!footerRef.current) {
            return;
        }
        setActionsPos?.(e.nativeEvent.layout.height ?? 0);
    };

    const transformStyle = React.useMemo(
        () => ({
            transform: [{ translateY: !isPrevious.current ? animValue : 0 }],
        }),
        [animValue],
    );

    const onChatPress = (): void => {
        if (isMessageSent) {
            analytics.sendOpenChatClick();
            if (isWeb) {
                onClose();
                goToChat();
                return;
            }
            navigation.navigate("ChatConversation", { storeId });
            return;
        }
        analytics.sendBottomBarChatClick();
        enquiryModalHandler(true, {
            storeId: storeId,
            storyData: JSON.stringify({ slug, storyId: story.story_id }),
            highlightName: highlightName,
            imageUrl: story.story_image ?? "",
        });
    };

    const onProductPress = React.useCallback(
        (productId: string) => {
            analytics.sendProductCardClick(productId);
            if (isWeb) {
                onClose();
                goToPDP(productId);
                return;
            }
            navigation.navigate("Product", {
                id: productId,
                slug: slug,
            });
        },
        [analytics, navigation, slug, onClose, goToPDP],
    );

    const renderCustomCTA = useCallback(
        (id: string): React.ReactNode => {
            return (
                <Button
                    style={[styles.button, { backgroundColor: brandColor }]}
                    textStyle={styles.text}
                    color="primary"
                    size="tiny"
                    elevation={0}
                    onPress={() => onProductPress(id)}
                    accessoryRight={
                        <SvgIcon
                            icon="ChevronRight"
                            color={"color-basic-0"}
                            width={16}
                            height={16}
                        />
                    }>
                    <Text category="btn5" weight="bold" color="color-basic-0">
                        See Product
                    </Text>
                </Button>
            );
        },
        [brandColor, onProductPress],
    );

    const renderChatToast = (): React.ReactNode => {
        const icon = isMessageSent ? (
            <SvgIcon icon="TickFilled" width={20} height={20} color="#4AA66A" />
        ) : (
            <SvgIcon icon="Chat" color={"color-basic-0"} width={16} height={16} />
        );

        const text = isMessageSent ? CHAT_TEXT : CHAT_MSG;

        return (
            <Box
                direction="row"
                alignItems="center"
                ph={SpacingValue["space-small"]}
                pv={SpacingValue["space-small"]}
                style={styles.toast}>
                {icon}
                <Box flex={1} ph={SpacingValue["space-x-small"]}>
                    <Text
                        category={isMessageSent ? "b3" : "b2"}
                        weight={isMessageSent ? "medium" : "bold"}
                        color={"color-basic-0"}>
                        {text}
                    </Text>
                </Box>
                {isMessageSent ? (
                    <Text color={"color-basic-0"} style={styles.toastCTA}>
                        OPEN CHAT
                    </Text>
                ) : (
                    <SvgIcon icon="ChevronRight" color={"color-basic-0"} width={16} height={16} />
                )}
            </Box>
        );
    };

    const renderItem: ListRenderItem<Product> = React.useCallback(
        ({ item, index }) => {
            if (!item) {
                return null;
            }
            const marginLeft = isWeb && index === 0 ? SpacingValue["space-medium"] : 0;

            return (
                <Pressable onPress={() => onProductPress(item.id)} style={{ marginLeft }}>
                    <Box style={[{ width: cardWidth }, styles.footerCart]}>
                        <ProductLargeCard
                            item={item}
                            onItemAddCallback={() => null}
                            index={index}
                            imageSize={imageSize}
                            renderCustomCTA={() => renderCustomCTA(item.id)}
                            onProductPress={() => onProductPress(item.id)}
                            hideOriginalPrice
                            hideBadge
                            hideVariants
                        />
                    </Box>
                </Pressable>
            );
        },
        [cardWidth, onProductPress, renderCustomCTA],
    );

    const onViewableItemsChanged: NonNullable<FlatListProps<Product[]>["onViewableItemsChanged"]> =
        React.useCallback(
            ({ changed }) => {
                changed
                    .filter((i) => i.isViewable)
                    .map((item) => {
                        analytics.sendProductCardImpression(item.item.id);
                    });
            },
            [analytics],
        );

    return (
        <Animated.View style={transformStyle}>
            <Box
                direction="column"
                style={{ width }}
                onTouchStart={() => {
                    toggleAnimation(true);
                }}
                onResponderRelease={() => {
                    toggleAnimation(false);
                }}>
                <FlatList
                    horizontal
                    showsHorizontalScrollIndicator={false}
                    bounces={false}
                    data={listData}
                    keyExtractor={(item: any) => item.id}
                    renderItem={renderItem}
                    contentContainerStyle={styles.contentContainer}
                    snapToInterval={cardWidth + SpacingValue["space-medium"]}
                    initialNumToRender={3}
                    pagingEnabled
                    ItemSeparatorComponent={ItemSeparatorComponent}
                    decelerationRate={"fast"}
                    disableIntervalMomentum
                    viewabilityConfig={viewabilityConfig}
                    onViewableItemsChanged={onViewableItemsChanged}
                    style={{ marginBottom: SpacingValue["space-xx-large"] }}
                />
                <Box
                    style={{ backgroundColor: "#000", width }}
                    ref={footerRef}
                    onLayout={onLayout}
                    ph={SpacingValue["space-medium"]}
                    pv={SpacingValue["space-x-large"]}
                    pb={
                        Platform.OS === "ios"
                            ? SpacingValue["space-x-large"] + insets.bottom
                            : SpacingValue["space-x-large"]
                    }>
                    <TouchableComponent onPress={onChatPress}>
                        {renderChatToast()}
                    </TouchableComponent>
                </Box>
            </Box>
        </Animated.View>
    );
};

export const Footer = React.memo(FooterComponent);

const styles = StyleSheet.create({
    contentContainer: {
        flexGrow: 1,
        paddingHorizontal: SpacingValue["space-medium"],
        marginTop: SpacingValue["space-medium"],
    },
    footerCart: {
        backgroundColor: "#fff",
        paddingHorizontal: SpacingValue["space-medium"],
        borderRadius: 16,
        flex: 1,
    },
    button: {
        borderRadius: 8,
        borderColor: "transparent",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "auto",
        border: 0,
        paddingHorizontal: SpacingValue["space-x-small"],
        paddingRight: 0,
        alignSelf: "flex-start",
    },
    text: {
        color: "#fff",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    toast: {
        backgroundColor: "#36393E",
        borderRadius: 12,
    },
    toastCTA: {
        fontSize: 11,
        color: "#4AA66A",
        fontWeight: "700",
    },
});
