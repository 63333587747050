import * as React from "react";
import { Platform, StyleProp, ViewStyle } from "react-native";
import { MediaType } from "react-native-image-picker";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import {
    MediaFile,
    MediaPreviewScreenModal,
    MediaSourceItem,
    MediaSourceModal,
    MEDIA_SOURCE_DATA,
    MEDIA_SOURCE_IDS,
    UploadedMediaFile,
    uploadMedia,
    MediaSource,
} from "@swiggy-private/rn-image-picker";
import { useMount, useMountedRef } from "@swiggy-private/react-hooks";
import { Header } from "@react-navigation/elements";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { SpacingValue } from "@swiggy-private/rn-dls";

import { formatFileSizeMetric } from "@minis-consumer/helpers/file";
import { Logger } from "@minis-consumer/includes/logger";
import { generateMediaUploadUrlApi } from "@minis-consumer/api/media";

import { useSelectMediaFromDevice } from "./use-select-media-device";

interface MediaUploadModalProps {
    supportedMediaTypes?: MediaType[];
    videoDurationLimit?: number;
    onUpload: (media?: UploadedMediaFile[]) => void;
    limit?: number;
    title?: string;
    uploadBtnText?: string;
    sourceModalStyle?: StyleProp<ViewStyle>;
    sourceModalContentStyle?: StyleProp<ViewStyle>;
    onError?: (error: Error) => void;
    maxVideoSize?: number;
    maxImageSize?: number;
    handleUploadOptionSelect?: (s: string) => void;
    sourceData?: MediaSourceItem[];
    mediaSource?: MediaSource;
    webUploadedFiles?: MediaFile[];
}

const getDefaultSourceData = (): MediaSourceItem[] => {
    if (Platform.OS === "android" || Platform.OS === "web") {
        return [MEDIA_SOURCE_DATA[1]];
    } else {
        return MEDIA_SOURCE_DATA.slice(0, 2);
    }
};

export const MediaUploadModal: React.FC<MediaUploadModalProps> = (props) => {
    const {
        onUpload,
        supportedMediaTypes = ["photo"],
        limit = 1,
        title,
        uploadBtnText,
        sourceModalStyle,
        sourceModalContentStyle,
        videoDurationLimit,
        maxImageSize,
        maxVideoSize,
        webUploadedFiles,
        onError,
        handleUploadOptionSelect,

        /**
         * TEMP CODE
         * Since Camera upload isn't working on android in chat
         * adding condition for now. This shall be fixed / dynamically
         * handled for chat feature
         * */
        sourceData = getDefaultSourceData(),
        mediaSource = "PUBLIC_ASSET",
    } = props;

    const insets = useSafeAreaInsets();
    const mediaType = supportedMediaTypes.length > 1 ? "mixed" : supportedMediaTypes[0];
    const [showSourceSelectionModal, setShowSourceSelectionModal] = React.useState(
        !webUploadedFiles || webUploadedFiles?.length === 0,
    );
    const [showMediaPreviewScreen, setShowMediaPreviewScreen] = React.useState(false);
    const [media, setMedia] = React.useState<MediaFile[]>([]);
    const cancelRef = React.useRef<() => void>();
    const mounted = useMountedRef();

    const validateSize = React.useCallback(
        (files: MediaFile[]): void | Error => {
            if (maxImageSize) {
                for (const file of files) {
                    if (file.fileSize > maxImageSize && file.type === "image") {
                        return new Error(
                            `Image exceeds ${formatFileSizeMetric(maxImageSize)
                                .toString()
                                .toLowerCase()} and can't be uploaded. Please try a smaller sized image`,
                        );
                    }
                }
            }

            if (maxVideoSize) {
                for (const file of files) {
                    if (file.fileSize > maxVideoSize && file.type === "video") {
                        return new Error(
                            `Video exceeds ${formatFileSizeMetric(maxVideoSize)
                                .toString()
                                .toLowerCase()} and can't be uploaded. Please try a smaller sized video`,
                        );
                    }
                }
            }
        },
        [maxImageSize, maxVideoSize],
    );

    const onDeviceMediaSelect = React.useCallback(
        (files: MediaFile[] = []) => {
            const err = validateSize(files);
            if (err && onError) {
                onError?.(err);
                return;
            }

            if (files.length > 0) {
                setMedia(files);

                if (!files[0].type.includes("image")) {
                    OnPreviewClose(files);
                    return;
                }
                setTimeout(() => setShowMediaPreviewScreen(true), 1_000);
            } else {
                onUpload();
            }
        },
        [onError, onUpload, validateSize],
    );

    const [uploadFromGallery, uploadFromCamera, uploadFromFiles] = useSelectMediaFromDevice({
        onSelect: onDeviceMediaSelect,
        mediaType,
        limit,
        videoDurationLimit,
    });

    const OnPreviewClose = React.useCallback(
        async (mediaArr?: MediaFile[]): Promise<void> => {
            const onSuccess = (): void => {
                setShowSourceSelectionModal(false);
                setShowMediaPreviewScreen(false);
                setMedia([]);
            };

            if (Array.isArray(mediaArr) && mediaArr.length) {
                const { cancel, result } = uploadMedia({
                    files: mediaArr,
                    generateMediaUrl: generateMediaUploadUrlApi,
                    onError: Logger.recordError,
                    source: mediaSource,
                });

                cancelRef.current = cancel;

                await result
                    .then((files) => {
                        cancelRef.current = undefined;
                        if (mounted.current && files.length) {
                            onSuccess();
                            onUpload(files);
                        }
                    })
                    .catch(Logger.recordError);

                cancelRef.current = undefined;

                return;
            }

            onSuccess();
            onUpload([]);
        },
        [mounted, onUpload],
    );

    useMount(() => {
        return () => {
            if (typeof cancelRef.current === "function") {
                cancelRef.current();
            }
        };
    });

    React.useEffect(() => {
        if (webUploadedFiles && webUploadedFiles.length > 0) {
            onDeviceMediaSelect(webUploadedFiles);
        }
    }, [onDeviceMediaSelect, webUploadedFiles]);

    const sourceAction = React.useMemo(
        () => ({
            [MEDIA_SOURCE_IDS.camera]: () => {
                setShowSourceSelectionModal(false);
                requestAnimationFrame(uploadFromCamera);
            },
            [MEDIA_SOURCE_IDS.gallery]: () => {
                setShowSourceSelectionModal(false);
                requestAnimationFrame(uploadFromGallery);
            },
            [MEDIA_SOURCE_IDS.file]: () => {
                setShowSourceSelectionModal(false);
                requestAnimationFrame(uploadFromFiles);
            },
        }),
        [uploadFromCamera, uploadFromGallery, uploadFromFiles],
    );

    if (showSourceSelectionModal) {
        return (
            <MediaSourceModal
                visible={true}
                onDismiss={() => setShowSourceSelectionModal(false)}
                onRequestClose={() => {
                    setShowSourceSelectionModal(false);
                    onUpload();
                }}
                title={title}
                style={sourceModalStyle}
                contentStyle={sourceModalContentStyle}
                sourceAction={sourceAction}
                sourceData={sourceData}
                handleUploadOptionSelect={handleUploadOptionSelect}
            />
        );
    }

    if (showMediaPreviewScreen && media.length > 0) {
        return (
            <MediaPreviewScreenModal
                media={media}
                onUpload={OnPreviewClose}
                btnText={uploadBtnText}
                onError={Logger.recordError}
                HeaderComponent={(headerProps) => {
                    return (
                        <Header
                            headerStyle={headerProps.style}
                            modal={true}
                            headerTitle={headerProps.title}
                            headerStatusBarHeight={insets.top}
                            title="Preview Media"
                            headerLeft={() => {
                                return (
                                    <SvgIcon
                                        icon="ArrowLeft"
                                        color={headerProps.backIconColor}
                                        onPress={headerProps.onBack}
                                        style={{
                                            marginHorizontal: SpacingValue["space-medium"],
                                            marginLeft: SpacingValue["space-medium"],
                                        }}
                                    />
                                );
                            }}
                        />
                    );
                }}
            />
        );
    }

    return null;
};
