import * as React from "react";
import { StyleProp, StyleSheet, TextStyle, ViewProps } from "react-native";
import { Text, useTheme } from "@swiggy-private/rn-dls";
import { Box } from "@swiggy-private/rn-adaptive-layout";

interface BadgeProps extends ViewProps {
    count?: number | string;
    size?: number;
    textStyle?: StyleProp<TextStyle>;
}

export const Badge: React.FC<BadgeProps> = ({
    count = 0,
    size = 20,
    style,
    textStyle,
    ...props
}) => {
    const { value: theme } = useTheme();

    const containerStyle = {
        borderColor: theme["color-basic-0"],
        backgroundColor: theme["color-critical-400"],
        borderRadius: size,
        height: size,
        minWidth: size,
    };

    const showBadgeCount = count && Number(count) !== 0;

    return showBadgeCount ? (
        <Box style={[styles.container, containerStyle, style]} pointerEvents="none" {...props}>
            <Text
                category="b3"
                color="color-basic-0"
                weight="medium"
                style={StyleSheet.compose(styles.text, textStyle)}
                numberOfLines={1}>
                {count}
            </Text>
        </Box>
    ) : null;
};

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 4,
        paddingVertical: 2,
        borderRadius: 20,
        borderWidth: 2,
        position: "absolute",
        top: 0,
        right: 0,
        minWidth: 20,
        height: 20,
        overflow: "hidden",
        justifyContent: "center",
        alignItems: "center",
    },
    text: {
        fontSize: 10,
        height: 12,
        lineHeight: 12,
    },
});
