import React from "react";
import { StyleSheet, ViewStyle } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { Button, DialogContent, SpacingValue, Text } from "@swiggy-private/rn-dls";
import { BottomSheet } from "@swiggy-private/react-native-ui";
import { Box, Stack, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { useMount } from "@swiggy-private/react-hooks";

import { Order } from "@minis-consumer/interfaces/order";
import { CommonRouteList } from "@minis-consumer/interfaces/route";
import { Analytics } from "@minis-consumer/analytics";

interface PaymentFailedDialogProps {
    show?: boolean;
    order?: Order;
    onDismiss?: () => void;
}

export const PaymentFailedDialog: React.FC<PaymentFailedDialogProps> = ({
    show,
    order,
    onDismiss: onDismissProp,
}) => {
    const insets = useSafeAreaInsets();
    const navigation = useNavigation<NavigationProp<CommonRouteList>>();

    const borderRadiusStyle = useSelectScreen<ViewStyle>({
        default: styles.topBorderRadius,
        lg: styles.fullBorderRadius,
    });

    useMount(() => {
        Analytics.impressionEvent({
            category: "payment-failed-retry-another-method-half-card",
            label: order?.id,
            context: JSON.stringify({ transactionId: order?.txnId || "-" }),
        });
    });

    const onDismiss = React.useCallback(() => {
        Analytics.clickEvent({
            category: "payment-failed-retry-another-method-half-card-dismiss-btn",
            label: order?.id,
            context: JSON.stringify({ transactionId: order?.txnId || "-" }),
        });

        onDismissProp?.();
    }, [onDismissProp, order?.id, order?.txnId]);

    const onRetry = (): void => {
        Analytics.clickEvent({
            category: "payment-failed-retry-another-method-half-card-retry-btn",
            label: order?.id,
            context: JSON.stringify({ transactionId: order?.txnId || "-" }),
        });

        navigation.navigate("Payment", {
            screen: "PaymentHome",
            params: { orderId: order?.id },
        });

        onDismiss?.();
    };

    if (!show) {
        return null;
    }

    return (
        <BottomSheet open={show} onClose={onDismiss} style={borderRadiusStyle}>
            <DialogContent
                style={[
                    borderRadiusStyle,
                    { paddingBottom: insets.bottom + SpacingValue["space-medium"] },
                ]}>
                <Box>
                    <Stack spacing={SpacingValue["space-small"]} direction="row">
                        <Box>
                            <SvgIcon icon="Warning" color="color-critical" />
                        </Box>
                        <Stack spacing={SpacingValue["space-x-small"]} flexShrink={1}>
                            <Text category="h4" color="highest">
                                Payment failed
                            </Text>
                            <Text category="b2" color="medium">
                                Any amount deducted will be refunded back to your account within 2-4
                                days.
                            </Text>
                        </Stack>
                    </Stack>
                    <Box mt={SpacingValue["space-x-large"]}>
                        <Button onPress={onRetry}>Retry another payment method</Button>
                    </Box>
                    <Box alignItems="center" mt={SpacingValue["space-xx-large"]}>
                        <Text
                            category="btn2"
                            color="color-critical-300"
                            suppressHighlighting
                            onPress={onDismiss}>
                            Dismiss
                        </Text>
                    </Box>
                </Box>
            </DialogContent>
        </BottomSheet>
    );
};

const styles = StyleSheet.create({
    topBorderRadius: {
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
    },
    fullBorderRadius: {
        borderRadius: 16,
    },
});
