import { useContext } from "react";
import { FollowersCountStateContext } from "../contexts/followers-count-context";
import { FollowersCountState } from "../reducers/followers-count-reducer";

export function useFollowersCountState(): FollowersCountState {
    const ctx = useContext(FollowersCountStateContext);
    if (!ctx) {
        throw new Error("Missing followers count state context");
    }

    return ctx;
}
