import META_SDK, { TEventName, ICustomData, TInitialiseSDK } from "@swiggy-private/meta-sdk";

import { Logger } from "@minis-consumer/includes/logger";

// wrapper to trigger meta pixel events.
export const fireMetaPixelEvents = (event_name: TEventName, event_data: ICustomData): void => {
    try {
        META_SDK.fireEvents(event_name, event_data);
    } catch (err) {
        Logger.recordError(err);
    }
};

// initialize meta pixel.
export const initMetaPixel = (data: TInitialiseSDK): void => {
    try {
        META_SDK.initialize(data);
    } catch (err) {
        Logger.recordError(err);
    }
};
