import { useMemo } from "react";

import { ButtonProps, useTheme } from "@swiggy-private/rn-dls";

import { Product } from "@minis-consumer/interfaces/catalog";

import { COPIES, CTA_TEXT_PRODUCT_TYPE_MAP } from "../constants";
import { ProductCtaProps } from "..";

interface Params {
    productType: Product["productType"];

    price?: number;
    inStock?: boolean;

    isPdp?: boolean;
    isItemInCart?: boolean;
    isVariantList?: boolean;
    isCustomCartItem?: boolean;
}

interface CtaProps extends Partial<ProductCtaProps> {
    buttonProps: ButtonProps;
}
export const useGetCtaProps = ({
    productType = "PHYSICAL",
    price,
    inStock,
    isItemInCart,
    isPdp,
    isVariantList,
    isCustomCartItem,
}: Params): CtaProps => {
    const { value: theme } = useTheme();

    const CTA_TYPES_PROPS_MAPPING: Record<string, CtaProps> = useMemo(() => {
        return {
            UNAVAILABLE: {
                buttonProps: {
                    color: "tertiary",
                    style: {
                        backgroundColor: isPdp ? theme["color-basic-30"] : theme["color-basic-0"],
                    },
                    textStyle: {
                        color: isPdp ? theme["color-basic-0"] : theme["color-basic-30"],
                    },
                    elevation: isPdp ? 1 : 0,
                },
                isDisabled: true,
                ctaText: COPIES.UNAVAILABLE,
            },
            ENQUIRE: {
                buttonProps: {
                    color: isPdp ? "primary" : "tertiary",
                    textStyle: {
                        color: isPdp ? theme["color-basic-0"] : theme["color-primary"],
                    },
                },

                ctaText: COPIES.ENQUIRE,
                ctaIcon: isPdp ? "AddToCart" : undefined,
                ctaIconColor: isPdp ? "color-basic-0" : "color-primary",
            },
            REMOVE: {
                buttonProps: {
                    color: isPdp ? "primary" : "tertiary",
                    textStyle: {
                        color: isPdp ? theme["color-basic-0"] : theme["color-primary"],
                    },
                },
                ctaText: COPIES.REMOVE,
                ctaIconColor: isPdp ? theme["color-basic-0"] : theme["color-primary"],
            },
            ADD: {
                buttonProps: {
                    color: isPdp ? "primary" : "tertiary",
                    textStyle: {
                        color: isPdp ? theme["color-basic-0"] : theme["color-primary"],
                    },
                },
                ctaText: isPdp
                    ? CTA_TEXT_PRODUCT_TYPE_MAP.ADD_PDP[productType || "PHYSICAL"]
                    : CTA_TEXT_PRODUCT_TYPE_MAP.ADD[productType || "PHYSICAL"],
                ctaIcon: isPdp ? "AddToCart" : undefined,
                ctaIconColor: isPdp ? theme["color-basic-0"] : theme["color-primary"],
            },
            COUNTER: {
                buttonProps: {
                    color: isPdp || isVariantList ? "primary" : "tertiary",
                    textStyle: {
                        color:
                            isPdp || isVariantList
                                ? theme["color-basic-0"]
                                : theme["color-primary"],
                    },
                    elevation: isPdp ? 1 : 0,
                },
                ctaTextColor: isPdp || isVariantList ? "color-basic-0" : "color-primary",
                ctaIconColor:
                    isPdp || isVariantList ? theme["color-basic-0"] : theme["color-primary"],
            },
        };
    }, [isPdp, isVariantList, productType, theme]);

    if (isCustomCartItem) {
        return CTA_TYPES_PROPS_MAPPING.REMOVE;
    }

    /** if item is not available, return unavailable props */
    if (!inStock) {
        return CTA_TYPES_PROPS_MAPPING.UNAVAILABLE;
    }

    if (!price) {
        return CTA_TYPES_PROPS_MAPPING.ENQUIRE;
    }

    /** if item not present in cart, return book or add cta */
    if (!isItemInCart) {
        return CTA_TYPES_PROPS_MAPPING.ADD;
    }

    /** if item present in cart */
    if (productType === "PHYSICAL") {
        return CTA_TYPES_PROPS_MAPPING.COUNTER;
    }

    return CTA_TYPES_PROPS_MAPPING.REMOVE;
};
