import React from "react";
import { StyleSheet } from "react-native";

import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { StoreMedia } from "@swiggy-private/react-native-ui";
import { Box, Stack, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text, TextProps, useTheme } from "@swiggy-private/rn-dls";

interface Props {
    closeModal: () => void;

    imageUrl?: string;
    queryText?: string;
    name?: string;
    variantText?: string;
}

const IMAGE_SIZE = 40;

const ModalBodyHeaderComponent: React.FC<Props> = ({
    closeModal,
    queryText,
    name,
    imageUrl,
    variantText,
}) => {
    const { value: theme } = useTheme();

    const titleText = useSelectScreen<TextProps>({
        default: { category: "h5" },
        lg: { category: "h4" },
    });

    const imageStyle = {
        borderRadius: 8,
        backgroundColor: theme["color-basic-15"],
    };

    return (
        <Stack direction="row" spacing={SpacingValue["space-x-large"]}>
            <Stack
                direction="row"
                spacing={SpacingValue["space-x-small"]}
                flex={1}
                alignItems="center">
                {imageUrl ? (
                    <StoreMedia
                        disableVideoPlay
                        isImageKitEnabled
                        mediaId={imageUrl}
                        width={IMAGE_SIZE}
                        height={IMAGE_SIZE}
                        style={imageStyle}
                    />
                ) : null}

                <Box flex={1}>
                    <Text category={titleText.category} weight="regular">
                        {queryText}
                    </Text>

                    <Text
                        style={styles.productName}
                        category="b2"
                        weight="medium"
                        numberOfLines={2}
                        color="color-basic-75">
                        {name}
                    </Text>

                    {variantText ? (
                        <Text
                            category="b3"
                            weight="medium"
                            numberOfLines={2}
                            color="color-basic-60">
                            {variantText}
                        </Text>
                    ) : null}
                </Box>
            </Stack>
            <SvgIcon icon="Close" height={16} width={16} onPress={closeModal} />
        </Stack>
    );
};

export const ModalBodyHeader = React.memo(ModalBodyHeaderComponent);

const styles = StyleSheet.create({
    productName: {
        marginTop: SpacingValue["space-x-small"],
        marginBottom: SpacingValue["space-xx-small"],
    },
});
