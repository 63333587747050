import React, { useCallback, useState } from "react";
import { StyleProp, StyleSheet, ViewStyle } from "react-native";

import { FormInput } from "@swiggy-private/react-native-ui";
import { useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { Button, SpacingValue, useTheme } from "@swiggy-private/rn-dls";

import { Analytics } from "@minis-consumer/analytics";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";

import { ANALYTICS_COPIES, PINCODE_FORM_CONSTS, VALID_PINCODE_LENGTH } from "../constants";

type FormBodyProps = {
    handlePincodeSubmit: (s: string) => void;
    buttonStyle?: StyleProp<ViewStyle>;
};

export const FormBody: React.FC<FormBodyProps> = React.memo(({ handlePincodeSubmit }) => {
    const { value: theme } = useTheme();
    const { storeId } = useStoreInfo();

    const [btnDisabled, setIsBtnDisabled] = React.useState<boolean>(true);
    const [pincode, setPincode] = useState("");
    const [error, setError] = useState<string>();

    const buttonStyle = {
        marginTop: useSelectScreen({
            lg: SpacingValue["space-large"] + SpacingValue["space-x-small"],
            default: !error
                ? SpacingValue["space-xx-large"]
                : SpacingValue["space-xxx-small"] + SpacingValue["space-xx-small"],
        }),
    };

    const handlePincode = React.useCallback((val: string) => {
        if (val.length > VALID_PINCODE_LENGTH) {
            return;
        }

        const isValidPincode = val.length === VALID_PINCODE_LENGTH && val[0] !== "0";

        setIsBtnDisabled(!isValidPincode);
        setError(isValidPincode ? "" : PINCODE_FORM_CONSTS.INVALID_PINCODE_ERROR);
        setPincode(val);
    }, []);

    const handleApply = useCallback(() => {
        if (!btnDisabled) {
            Analytics.clickEvent({
                category: ANALYTICS_COPIES.PINCODE_APPLY,
                context: JSON.stringify({
                    store_id: storeId,
                    pincode: pincode,
                }),
            });

            handlePincodeSubmit(pincode);
        }
    }, [btnDisabled, handlePincodeSubmit, pincode, storeId]);

    const hintStyles = {
        backgroundColor: theme["color-critical-light"],
        borderColor: theme["color-critical-light"],
        borderWidth: 1,
        color: theme["color-critical"],
    };

    const primaryButtonStyles = React.useMemo(() => {
        return {
            backgroundColor: !btnDisabled ? theme["color-positive"] : theme["color-basic-45"],
            color: theme["color-basic-0"],
            borderColor: "transparent",
        };
    }, [btnDisabled, theme]);

    const hintTextStyle = React.useMemo(() => {
        return {
            color: error ? theme["color-critical"] : theme["color-basic-100"],
        };
    }, [error, theme]);

    const inputLabelStyle = {
        color: error ? theme["color-critical-200"] : theme["color-positive"],
    };

    return (
        <>
            <FormInput
                style={[
                    styles.input,
                    {
                        borderColor: error ? theme["color-critical-200"] : theme["color-positive"],
                    },
                ]}
                isNumeric
                name="pincode"
                label={PINCODE_FORM_CONSTS.ADDRESS_INPUT_LABEL}
                onChangeText={handlePincode}
                placeholderTextColor={theme["color-basic-60"]}
                value={pincode?.toString()}
                keyboardType="number-pad"
                error={!error}
                hint={error}
                hintContainerStyle={hintStyles}
                inputLabelStyle={inputLabelStyle}
                hintTextStyle={hintTextStyle}
                textStyle={{ color: theme["color-basic-100"] }}
            />

            <Button
                onPress={handleApply}
                elevation={0}
                disabled={btnDisabled}
                textStyle={{ color: theme["color-basic-0"] }}
                style={[primaryButtonStyles, buttonStyle]}>
                {PINCODE_FORM_CONSTS.APPLY_BTN}
            </Button>
        </>
    );
});

if (__DEV__) {
    FormBody.displayName = "FormBody";
}

const styles = StyleSheet.create({
    input: {
        marginTop: SpacingValue["space-small"],
    },
});
