import React, { useEffect, useMemo } from "react";
import {
    ChatProvider as ChatUIProvider,
    useInitializeChatSdk,
} from "@swiggy-private/connect-chat-ui";
import { Platform } from "react-native";
import { ChatSdkConfig } from "@swiggy-private/connect-chat-sdk";

import { useUserInfo } from "@minis-consumer/hooks/use-user";
import { Session } from "@minis-consumer/includes/session";
import { AppConfig } from "@minis-consumer/config";
import { Logger } from "@minis-consumer/includes/logger";
import { getPushNotificationToken } from "@minis-consumer/includes/push-notification";
import { LocalChatService } from "@minis-consumer/includes/local-chat-service";
import { Device } from "@minis-consumer/includes/device";

const sdkConfig: ChatSdkConfig = {
    publishKey: AppConfig.PUBNUB_PUBLISH_KEY,
    subscribeKey: AppConfig.PUBNUB_SUBSCRIBE_KEY,
    serverUrl: AppConfig.CHAT_SERVICE_HOST,
    logVerbosity: false,
    userMode: "customer",
};

interface IChatProvider {
    isUserFetched: boolean;
    children: React.ReactNode;
}

export const ChatProvider: React.FC<IChatProvider> = ({ children }) => {
    const session = Session.getInstance();
    const userInfo = useUserInfo();
    const deviceId = Device.getInstance().deviceId;

    const authCreds = useMemo(() => {
        const obj: { [key: string]: string } = {};

        const addIfNotEmpty = (key: string, value: string | null): void => {
            if (value && value.trim().length) {
                obj[key] = value;
            }
        };

        addIfNotEmpty("tid", session.tid);
        addIfNotEmpty("sid", session.sid);
        addIfNotEmpty("token", session.token);
        addIfNotEmpty("deviceId", deviceId);

        return Object.keys(obj).length ? obj : null;
    }, [deviceId, session.sid, session.tid, session.token]);

    const sdk = useInitializeChatSdk(sdkConfig, authCreds);
    const chatService = useMemo(
        () => (userInfo?.id != null ? new LocalChatService(userInfo?.id) : null),
        [userInfo?.id],
    );

    useEffect(() => {
        if (!sdk || session.isGuest()) {
            return;
        }

        let token: string | null = null;

        const fn = async (): Promise<void> => {
            token = await getPushNotificationToken(session.userId);
            if (!token) {
                return;
            }

            sdk.registerDeviceForPushNotifications({
                token,
                userType: "customer",
                os: Platform.OS,
                storeId: session.storeId ?? "",
            }).catch((err) => {
                Logger.recordError(err);
            });
        };

        fn().catch(Logger.recordError);

        return () => {
            if (token && session.isGuest()) {
                sdk.unregisterDeviceForPushNotifications({
                    token,
                }).catch(Logger.recordError);
            }
        };
    }, [sdk, session]);

    return (
        <ChatUIProvider sdk={sdk} service={chatService}>
            {children}
        </ChatUIProvider>
    );
};
