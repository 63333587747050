import { UploadedMediaFile } from "@swiggy-private/rn-image-picker";

import { COPIES, TITLE } from "./constants";

export const getTitle = (rating: number): string => {
    return TITLE[rating] || COPIES.DEFAULT_TITLE;
};

export const getBannerImageId = (rating: number): string => `static-assets/${rating}-star-rating`;

export const getImageUrls = (uploadedMedia: UploadedMediaFile[]): string[] => {
    if (!uploadedMedia.length) {
        return [];
    }

    return uploadedMedia.map((media) => media.mediaId);
};
