import React, { FC, memo } from "react";
import { StyleSheet } from "react-native";

import { ActivityIndicator, Button, SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { useMount } from "@swiggy-private/react-hooks";

import { Analytics } from "@minis-consumer/analytics";

import { ANALYTICS_COPIES, DISPLAY_STRINGS } from "../constants";

interface ModalBodyProps {
    closeModal: VoidFunction;
    storeId: string;
    descriptionPrefix: string;
    removeItemsTextData: {
        prefix: string;
        cartFirstItemName: string;
        suffix: string;
    };

    showLoader?: boolean;
    cancelCtaText?: string;
    onClearCart?: VoidFunction;
}

const ModalBodyComponent: FC<ModalBodyProps> = ({
    closeModal,
    storeId,
    descriptionPrefix,
    onClearCart,
    cancelCtaText,
    showLoader,
}) => {
    const { value: theme } = useTheme();

    const analyticsContext = React.useMemo(() => JSON.stringify({ storeId }), [storeId]);

    const onPressCancel = React.useCallback(() => {
        Analytics.clickEvent({
            category: ANALYTICS_COPIES.CANCEL_CTA,
            context: analyticsContext,
        });

        closeModal();
    }, [closeModal, analyticsContext]);

    useMount(() => {
        Analytics.impressionEvent({
            category: ANALYTICS_COPIES.MODAL_MOUNT,
            context: analyticsContext,
        });
    });

    return (
        <Stack>
            <Box mb={SpacingValue["space-x-small"]}>
                <Text category="b1" weight="bold" color="highest">
                    {DISPLAY_STRINGS.TITLE}
                </Text>
            </Box>

            <Box mb={SpacingValue["space-medium"]}>
                <Text category="b2" color="medium">
                    {descriptionPrefix}
                </Text>
            </Box>

            <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={SpacingValue["space-small"]}>
                <Button
                    onPress={onPressCancel}
                    color="tertiary"
                    style={styles.modalButtons}
                    textStyle={{
                        color: theme["color-basic-60"],
                    }}
                    disableElevation>
                    <Text>{cancelCtaText ?? DISPLAY_STRINGS.CANCEL}</Text>
                </Button>

                <Button
                    onPress={onClearCart}
                    color="tertiary"
                    style={styles.modalButtons}
                    textStyle={{
                        color: theme["color-critical-400"],
                    }}
                    disableElevation>
                    {showLoader ? (
                        <ActivityIndicator color="color-critical-400" />
                    ) : (
                        <Text>{DISPLAY_STRINGS.CLEAR_CART}</Text>
                    )}
                </Button>
            </Stack>
        </Stack>
    );
};

export const ModalBody = memo(ModalBodyComponent);

const styles = StyleSheet.create({
    modalButtons: {
        minHeight: 40,
        borderWidth: 0,
    },
});
