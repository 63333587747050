import React from "react";
import { Pressable, StyleProp, StyleSheet, View, ViewStyle } from "react-native";

import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { SvgIcon, SvgIconType } from "@swiggy-private/connect-svg-icons";
import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { SimpleMenu } from "@swiggy-private/react-native-ui";

import { Analytics, Event } from "@minis-consumer/analytics";

import { DEFAULTS } from "./constants";
import { WidgetPresser } from "../widget-presser";

interface Props {
    dropdownText: string;
    onDropdownItemPress: VoidFunction;

    style?: StyleProp<ViewStyle>;
    iconHeight?: number;
    iconWidth?: number;
    dropdownIconHeight?: number;
    dropdownIconWidth?: number;
    dropdownStyle?: StyleProp<ViewStyle>;
    dropdownIcon?: SvgIconType["icon"];
    analytics?: Event;
}

const KebabComponent: React.FC<Props> = ({
    style,
    iconWidth = DEFAULTS.KEBAB_ICON_HEIGHT,
    iconHeight = DEFAULTS.KEBAB_ICON_WIDTH,
    dropdownText,
    dropdownIcon,
    dropdownIconHeight = DEFAULTS.DROPDOWN_ICON_HEIGHT,
    dropdownIconWidth = DEFAULTS.DROPDOWN_ICON_WIDTH,
    analytics,
    onDropdownItemPress,
    dropdownStyle,
}) => {
    const { value: theme } = useTheme();

    const anchorEl = React.useRef<View>(null);

    const [showDropdown, setShowDropdown] = React.useState(false);
    const [canAnimate, setAnimate] = React.useState(false);

    const openDropdown = React.useCallback(() => {
        if (analytics) {
            Analytics.impressionEvent(analytics);
        }

        setShowDropdown(true);
    }, [analytics]);
    const closeDropdown = React.useCallback(() => setShowDropdown(false), []);

    const onItemPress = React.useCallback(() => {
        onDropdownItemPress();
        closeDropdown();
        setAnimate(false);
    }, [closeDropdown, onDropdownItemPress]);

    const onPressDropdown = React.useCallback(() => {
        setAnimate(true);
    }, []);

    const hitSlop = {
        left: 16,
        bottom: 16,
        right: 16,
        top: 16,
    };

    const dropDownFlattenedStyle = StyleSheet.flatten([
        styles.dropdownContainer,
        {
            backgroundColor: canAnimate ? theme["color-critical-25"] : theme["color-basic-0"],
            borderColor: theme["color-basic-15"],
        },
        dropdownStyle,
    ]);

    return (
        <>
            <Pressable
                ref={anchorEl}
                style={[styles.container, style]}
                onPress={openDropdown}
                hitSlop={hitSlop}>
                <SvgIcon icon="More" height={iconHeight} width={iconWidth} color="color-basic-45" />
            </Pressable>

            {showDropdown && anchorEl.current ? (
                <SimpleMenu
                    xOrigin="right"
                    onClose={closeDropdown}
                    anchorEl={anchorEl.current}
                    style={dropDownFlattenedStyle}>
                    <WidgetPresser
                        animationDuration={DEFAULTS.ANIMATION_DURATION}
                        callback={onItemPress}
                        shouldTriggerAnimation={canAnimate}>
                        <Pressable onPress={onPressDropdown} hitSlop={hitSlop}>
                            <Stack direction="row" spacing={SpacingValue["space-x-small"]}>
                                {dropdownIcon ? (
                                    <SvgIcon
                                        icon={dropdownIcon}
                                        height={dropdownIconHeight}
                                        width={dropdownIconWidth}
                                        color="color-critical-300"
                                    />
                                ) : null}

                                {dropdownText ? (
                                    <Text category="b3" weight="bold" color="color-critical-300">
                                        {dropdownText}
                                    </Text>
                                ) : null}
                            </Stack>
                        </Pressable>
                    </WidgetPresser>
                </SimpleMenu>
            ) : null}
        </>
    );
};

export const Kebab = React.memo(KebabComponent);

const styles = StyleSheet.create({
    container: {
        marginRight: SpacingValue["space-medium"],
    },
    dropdownContainer: {
        minWidth: 20,
        borderRadius: 12,
        paddingVertical: SpacingValue["space-small"],
        paddingHorizontal: SpacingValue["space-medium"],
        backgroundColor: "transparent",
    },
});
