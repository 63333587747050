import React, { FC, memo } from "react";
import { StyleSheet } from "react-native";

import { ActivityIndicator, Button, SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { useMount } from "@swiggy-private/react-hooks";
import { SdkConversation } from "@swiggy-private/connect-chat-sdk";

import { Analytics } from "@minis-consumer/analytics";

import { ANALYTICS_COPIES, DISPLAY_STRINGS } from "../constants";

interface ModalBodyProps {
    closeModal: VoidFunction;
    title: string;
    description: string;
    onPressBlockUnblock: VoidFunction;
    blockCtaText: string;
    conversationId: SdkConversation["id"];

    showLoader?: boolean;
    cancelCtaText?: string;
}

const ModalBodyComponent: FC<ModalBodyProps> = ({
    closeModal,
    title,
    description,
    onPressBlockUnblock,
    blockCtaText,
    cancelCtaText,
    showLoader,
    conversationId,
}) => {
    const { value: theme } = useTheme();

    const analyticsContext = React.useMemo(
        () =>
            JSON.stringify({
                conversationId,
            }),
        [conversationId],
    );

    const onPressCancel = React.useCallback(() => {
        Analytics.clickEvent({
            category: ANALYTICS_COPIES.CANCEL_CTA,
            context: analyticsContext,
        });

        closeModal();
    }, [closeModal, analyticsContext]);

    useMount(() => {
        Analytics.impressionEvent({
            category: ANALYTICS_COPIES.MODAL_MOUNT,
            context: analyticsContext,
        });
    });

    return (
        <Stack>
            <Box mb={SpacingValue["space-x-small"]}>
                <Text category="h4" weight="bold" color="highest">
                    {title}
                </Text>
            </Box>

            <Box mb={SpacingValue["space-medium"]}>
                <Text category="b2" color="low">
                    {description}
                </Text>
            </Box>

            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={SpacingValue["space-small"]}>
                <Button
                    onPress={onPressCancel}
                    color="tertiary"
                    style={styles.modalButtons}
                    textStyle={{
                        color: theme["color-basic-60"],
                    }}
                    disableElevation>
                    <Text>{cancelCtaText ?? DISPLAY_STRINGS.CANCEL}</Text>
                </Button>

                <Button
                    onPress={onPressBlockUnblock}
                    color="critical"
                    style={styles.modalButtons}
                    disableElevation>
                    {showLoader ? (
                        <ActivityIndicator color="color-basic-0" />
                    ) : (
                        <Text>{blockCtaText}</Text>
                    )}
                </Button>
            </Stack>
        </Stack>
    );
};

export const ModalBody = memo(ModalBodyComponent);

const styles = StyleSheet.create({
    modalButtons: {
        flex: 1,
        minHeight: 44,
    },
});
