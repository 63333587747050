import React, { useEffect, useRef } from "react";
import { Pressable, ScrollView, StyleSheet, ViewStyle } from "react-native";

import { StoreMedia } from "@swiggy-private/react-native-ui";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";

interface IndicatorProps {
    images: string[];
    currentSlideIndex: number;
    slideToIndex: (i: number) => void;
}

const SIZE = 64;

const SliderIndicator: React.FC<IndicatorProps> = ({ images, currentSlideIndex, slideToIndex }) => {
    const { value: theme } = useTheme();
    const scrollRef = useRef<ScrollView>(null);

    const activeIndicator: ViewStyle = {
        borderColor: theme["color-primary"],
        borderWidth: StyleSheet.hairlineWidth,
        overflow: "hidden",
    };

    const indicatorBackground = {
        backgroundColor: theme["color-basic-15"],
    };

    const productMediaStyle = StyleSheet.flatten([styles.image, { width: SIZE, height: SIZE }]);

    useEffect(() => {
        if (currentSlideIndex > -1 && currentSlideIndex < images.length) {
            scrollRef?.current?.scrollTo({
                animated: true,
                x: currentSlideIndex * SIZE,
            });
        }
    }, [currentSlideIndex, images.length]);

    return (
        <Box alignItems="center" ph={SpacingValue["space-small"]}>
            <ScrollView
                ref={scrollRef}
                style={styles.container}
                horizontal
                showsHorizontalScrollIndicator={false}>
                <Stack
                    style={styles.indicatorContainer}
                    direction="row"
                    spacing={{
                        default: SpacingValue["space-medium"],
                    }}>
                    {images.map((item, index) => (
                        <Pressable
                            accessible
                            accessibilityLabel={`Product Image ${index + 1}`}
                            onPress={() => slideToIndex(index)}
                            key={index}
                            style={[
                                styles.indicator,
                                indicatorBackground,
                                currentSlideIndex === index ? activeIndicator : null,
                            ]}>
                            <StoreMedia
                                isImageKitEnabled
                                mediaId={item}
                                disableVideoPlay
                                onPress={() => slideToIndex(index)}
                                height={SIZE}
                                width={SIZE}
                                style={productMediaStyle}
                            />
                        </Pressable>
                    ))}
                </Stack>
            </ScrollView>
        </Box>
    );
};

export default SliderIndicator;

const styles = StyleSheet.create({
    container: {
        marginTop: SpacingValue["space-medium"],
        width: "100%",
    },
    indicatorContainer: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        borderBottomLeftRadius: 16,
        borderBottomRightRadius: 16,
    },
    indicator: {
        borderRadius: 8,
    },
    image: {
        borderRadius: 6,
    },
});
