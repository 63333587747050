import React from "react";

import { Box } from "@swiggy-private/rn-adaptive-layout";
import { Button, SpacingValue, useTheme } from "@swiggy-private/rn-dls";

import { LABELS } from "@minis-consumer/components/variants/constants";

const CustomisationsFooter: React.FC<{ onPress: VoidFunction }> = ({ onPress }) => {
    const { value: theme } = useTheme();
    return (
        <Box mt={SpacingValue["space-large"]}>
            <Button
                onPress={onPress}
                elevation={1}
                color="positive"
                textStyle={{
                    color: theme["color-primary"],
                }}>
                {LABELS.BUTTON_TITLE}
            </Button>
        </Box>
    );
};

if (__DEV__) {
    CustomisationsFooter.displayName = "CustomisationsFooter";
}

export const CustomisationsListFooter = React.memo(CustomisationsFooter);
