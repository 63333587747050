import * as React from "react";
import { FlatList, FlatListProps, ListRenderItem, StyleSheet } from "react-native";

import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Surface, useTheme } from "@swiggy-private/rn-dls";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { IOFlatList } from "@swiggy-private/react-native-ui";

import type { Product } from "@minis-consumer/interfaces/catalog";

import { DraggableScrollView } from "../../draggable-carousel";
import { ProductCarouselItem } from "../product-carousel-item";
import { SVG_ICON_HIT_SLOP } from "./constants";

interface ProductCarouselProps extends Omit<FlatListProps<Product>, "renderItem"> {
    data: Product[];
    sfWidget: string;

    imageSize?: number;
    isDraggable?: boolean;
    renderItem?: ListRenderItem<Product>;
}

enum SLIDE_DIRECTION {
    LEFT = "LEFT",
    RIGHT = "RIGHT",
}

const DEFAULT_CARD_SIZE = 140;

const ItemSeparator: React.FC = () => <Box style={styles.separator} />;
const KeyExtractor = (p: Product): string => p.id;

export const ProductCarousel: React.FC<ProductCarouselProps> = ({
    isDraggable = true,
    ...props
}) => {
    if (isDraggable) {
        return <DraggableScrollViewList {...props} />;
    }

    return <ListWithSlider {...props} />;
};

const DraggableScrollViewList: React.FC<ProductCarouselProps> = ({
    renderItem,
    ItemSeparatorComponent = ItemSeparator,
    horizontal = true,
    keyExtractor = KeyExtractor,
    showsHorizontalScrollIndicator = false,
    sfWidget,
    ...props
}) => {
    const RenderItem: ListRenderItem<Product> = ({ item, index }) => (
        <ProductCarouselItem sfWidget={sfWidget} product={item} key={item.id} index={index} />
    );

    return (
        <DraggableScrollView>
            <IOFlatList
                renderItem={renderItem ?? RenderItem}
                horizontal={horizontal}
                keyExtractor={keyExtractor}
                ItemSeparatorComponent={ItemSeparatorComponent}
                showsHorizontalScrollIndicator={showsHorizontalScrollIndicator}
                {...props}
            />
        </DraggableScrollView>
    );
};

const ListWithSlider: React.FC<ProductCarouselProps> = ({
    renderItem,
    ItemSeparatorComponent = ItemSeparator,
    horizontal = true,
    keyExtractor = KeyExtractor,
    showsHorizontalScrollIndicator = false,
    sfWidget,
    ...props
}) => {
    const RenderItem: ListRenderItem<Product> = ({ item, index }) => (
        <ProductCarouselItem sfWidget={sfWidget} product={item} key={item.id} index={index} />
    );

    const flatlistRef = React.useRef<FlatList>(null);

    const [currentScrollIndex, setCurrentScrollIndex] = React.useState(0);

    const { value: theme } = useTheme();

    const sliderContainerStyle = {
        ...styles.sliderConatiner,
        borderColor: theme["color-basic-15"],
    };

    React.useEffect(() => {
        flatlistRef.current?.scrollToOffset({
            offset: currentScrollIndex
                ? ((props.imageSize ?? DEFAULT_CARD_SIZE) + SpacingValue["space-medium"]) *
                  currentScrollIndex
                : 0,
            animated: true,
        });
    }, [currentScrollIndex, props.imageSize]);

    const onPressSlider = React.useCallback(
        (direction: SLIDE_DIRECTION) => {
            let nextSlideIndex = -1;

            if (direction === "RIGHT") {
                nextSlideIndex = currentScrollIndex + 3;
            } else {
                nextSlideIndex = currentScrollIndex - 3;
            }

            setCurrentScrollIndex(nextSlideIndex);
        },
        [currentScrollIndex],
    );

    const onPressLeft = React.useCallback(() => {
        onPressSlider(SLIDE_DIRECTION.LEFT);
    }, [onPressSlider]);

    const onPressRight = React.useCallback(() => {
        onPressSlider(SLIDE_DIRECTION.RIGHT);
    }, [onPressSlider]);

    const isAtLastIndex = React.useMemo(
        () => currentScrollIndex === props.data.length - 2,
        [currentScrollIndex, props.data.length],
    );

    return (
        <Stack
            spacing={SpacingValue["space-small"]}
            direction="row"
            alignItems="center"
            justifyContent="center"
            flex={1}
            style={styles.container}>
            <Surface style={sliderContainerStyle}>
                <SvgIcon
                    icon="ArrowLeft"
                    color={currentScrollIndex === 0 ? "color-primary-light" : "color-primary"}
                    style={styles.sliderIcon}
                    hitSlop={SVG_ICON_HIT_SLOP}
                    disabled={currentScrollIndex === 0}
                    onPress={onPressLeft}
                />
            </Surface>

            <Box flex={1} style={styles.listContainer}>
                <IOFlatList
                    ref={flatlistRef}
                    renderItem={renderItem ?? RenderItem}
                    horizontal={horizontal}
                    keyExtractor={keyExtractor}
                    ItemSeparatorComponent={ItemSeparatorComponent}
                    showsHorizontalScrollIndicator={showsHorizontalScrollIndicator}
                    disableScrollViewPanResponder
                    disableIntervalMomentum
                    removeClippedSubviews
                    pagingEnabled={false}
                    scrollEnabled={false}
                    initialScrollIndex={0}
                    {...props}
                />
            </Box>

            <Surface style={sliderContainerStyle}>
                <SvgIcon
                    icon="ArrowRight"
                    style={styles.sliderIcon}
                    hitSlop={SVG_ICON_HIT_SLOP}
                    color={isAtLastIndex ? "color-primary-light" : "color-primary"}
                    disabled={isAtLastIndex}
                    onPress={onPressRight}
                />
            </Surface>
        </Stack>
    );
};

const styles = StyleSheet.create({
    container: {
        flexGrow: 1,
    },
    listContainer: {
        overflow: "hidden",
    },
    separator: {
        width: SpacingValue["space-medium"],
    },
    sliderConatiner: {
        height: 48,
        width: 48,
        borderRadius: 60,
        alignItems: "center",
        justifyContent: "center",
        elevation: 2,
        borderWidth: 1,
    },
    sliderIcon: {
        margin: SpacingValue["space-small"],
    },
});
