import React, { useEffect, useRef, useState } from "react";
import { useCallback, useContext, useMemo } from "react";
import { useController, useCache } from "@rest-hooks/react";
import { useMountedRef } from "@swiggy-private/react-hooks";
import { useErrorHandler } from "react-error-boundary";
import { useFocusEffect } from "@react-navigation/core";
import { useIsomorphicEffect } from "@swiggy-private/react-native-ui";

import { UpdateCartParams, DeleteCartParams, deleteCart } from "@minis-consumer/api/cart";
import { Logger } from "@minis-consumer/includes/logger";
import { LocalCartItem } from "@minis-consumer/interfaces/cart";
import { getCanAddOrBookProduct } from "@minis-consumer/helpers/can-add-or-book-product";

import { CartDispatchContext, CartStateContext } from "../contexts/cart-context";
import { CartEntity, FetchCart, SoftClearCart, UpdateCart } from "../resources/cart";
import { useToast } from "./use-toast";

import type {
    Cart as ICart,
    CartItem,
    ICartGuestDetails,
    LocalCart,
    UserAddress,
} from "../interfaces/cart";
import { useSignedIn, useUserInfo } from "./use-user";
import { PRODUCT_TYPE } from "@minis-consumer/interfaces/catalog";
import { Analytics } from "@minis-consumer/analytics";

/**
 * Hook to get the current cart local state.
 */
export const useLocalCart = (storeId: string): LocalCart => {
    const { carts } = useContext(CartStateContext);

    return useMemo(
        () => carts[storeId] ?? { items: [], syncing: false },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [carts[storeId], storeId],
    );
};

/**
 * Hook to call delete cart api for a store.
 */
export const useDeleteCart = (storeId: string): (() => Promise<void>) => {
    return useCallback(() => deleteCart({ storeId } as DeleteCartParams), [storeId]);
};

/**
 * Hook to clear the cart for a store.
 */
export const useClearCart = (storeId: string, hardClear = true): (() => void) => {
    const dispatch = useContext(CartDispatchContext);
    const deleteCartApiCall = useDeleteCart(storeId);

    return useCallback(() => {
        if (hardClear) {
            deleteCartApiCall().catch(Logger.recordError);
        }

        dispatch({ type: "CLEAR_CART_ACTION", payload: { storeId } });
    }, [hardClear, deleteCartApiCall, dispatch, storeId]);
};

type LocalCartItemServiceDeliverySlot = LocalCartItem["slot"];
type LocalCartItemForm = LocalCartItem["itemForm"];

export interface UpdateItemInCartParams {
    itemId: string;

    productType?: PRODUCT_TYPE;
    variantId?: string;
    increment?: boolean;
    custom?: boolean;
    quantity?: number;
    slot?: LocalCartItemServiceDeliverySlot;
    itemForm?: LocalCartItemForm;
    update?: boolean;
}

/**
 * Hook to update an item in the cart.
 */
export const useUpdateItemInLocalCart = (
    storeId: string,
): ((params: UpdateItemInCartParams) => void) => {
    const dispatch = useContext(CartDispatchContext);

    const cb = useCallback(
        (params: UpdateItemInCartParams) => {
            dispatch({
                type: "UPDATE_CART_ITEM_ACTION",
                payload: {
                    storeId,
                    item: params,
                },
            });
        },
        [dispatch, storeId],
    );

    return cb;
};

/**
 * Hook to get item quantity from cart state.
 */
export const useLocalCartItem = ({
    storeId,
    itemId,
    custom = false,
    productType,
}: {
    storeId: string;
    productType: PRODUCT_TYPE;
    itemId: string;
    custom?: boolean;
}): [
    number,
    (
        id?: string,
        productMetaData?: {
            productData?: CartItem["item"];
            slot?: LocalCartItemServiceDeliverySlot;
            itemForm?: LocalCartItemForm;
        },
        update?: boolean,
    ) => void,
    (
        id?: string,
        productMetaData?: {
            itemForm?: LocalCartItemForm;
        },
    ) => void,
    (id?: string) => boolean,
] => {
    const cart = useLocalCart(storeId);
    const updateItemInCart = useUpdateItemInLocalCart(storeId);
    // const quantity = cart.items.find((item) => item.id === itemId)?.quantity ?? 0;
    const quantity = cart.items.reduce((acc, curr) => {
        if (curr.id === itemId) {
            acc += curr.quantity;
        }
        return acc;
    }, 0);

    // this is needed to pass unique id's to selected variants.
    const incrementQuantity = useCallback(
        (
            variantId?: string,
            productMetaData?: {
                productData?: CartItem["item"];
                slot?: LocalCartItemServiceDeliverySlot;
                itemForm?: LocalCartItemForm;
            },
            update?: boolean,
        ) => {
            /** @TODO: add more context details if required */
            /** increment quantity */

            Analytics.clickEvent({
                category: "item-added-to-cart",
                context: JSON.stringify({
                    productId: itemId,
                    productType,
                    custom,
                    variantId,
                    slot: productMetaData?.slot,
                    update,
                    itemQuantity: quantity,
                    cartExists: !!cart.items?.length,
                }),
            });

            const matchedItem = cart.items.find(
                (itemData) =>
                    itemData.id === itemId && (variantId ? variantId === itemData.variantId : true),
            );

            let canAddItem = true;
            if (matchedItem && productMetaData && productMetaData.productData) {
                const { canAdd } = getCanAddOrBookProduct({
                    productId: itemId,
                    cartItems: cart.items,
                    productType,
                    price: productMetaData.productData.price,
                    discountedPrice: productMetaData.productData.discountedPrice,
                });
                canAddItem = canAdd;
            }

            const updateItem = update !== undefined ? update : !canAddItem;

            updateItemInCart({
                itemId: itemId,
                increment: true,
                productType,
                custom,
                variantId,
                slot: productMetaData?.slot,
                itemForm: productMetaData?.itemForm,
                update: updateItem,
            });
        },
        [cart.items, custom, itemId, productType, quantity, updateItemInCart],
    );

    const decrementQuantity = useCallback(
        (
            variantId?: string,
            productMetaData?: {
                itemForm?: LocalCartItemForm;
            },
        ) => {
            updateItemInCart({
                itemId: itemId,
                increment: false,
                productType,
                custom,
                variantId,
                itemForm: productMetaData?.itemForm,
            });
        },
        [custom, itemId, productType, updateItemInCart],
    );

    const isItemInCart = useCallback(
        (variantId?: string) => {
            if (!variantId) {
                return quantity > 0;
            }

            return cart.items.some((item) => item.id === itemId && item.variantId === variantId);
        },
        [cart.items, itemId, quantity],
    );

    return [quantity, incrementQuantity, decrementQuantity, isItemInCart];
};

/**
 * Hook to get variant item quantity from cart state.
 * We provide the id value in cb function to dynamically
 * fetch the count.
 */
export const useLocalCartVariantQuantity = ({
    storeId,
}: {
    storeId: string;
}): { getVariantQuantityInCart: (variantId: string) => number } => {
    const cart = useLocalCart(storeId);

    const getVariantQuantityInCart = (variantId: string): number => {
        // item.id === variantId
        // this code is for backward compatibility.
        return (
            cart.items.find(
                (item) =>
                    item.variantId === variantId || (!item.variantId && item.id === variantId),
            )?.quantity ?? 0
        );
    };

    return { getVariantQuantityInCart };
};

/**
 * Hook to get variant item quantity from cart state.
 * We provide the id value in cb function to dynamically
 * fetch the count.
 */
export const useLocalCartVariantForm = ({
    storeId,
}: {
    storeId: string;
}): { getVariantItemFormInCart: (variantId: string) => CartItem["itemForm"] } => {
    const cart = useCartViewData(storeId);

    const getVariantItemFormInCart = (variantId: string): CartItem["itemForm"] => {
        // item.id === variantId
        // this code is for backward compatibility.
        return (
            cart?.cartItems.find(
                (item) =>
                    item.item.variantId === variantId ||
                    (!item.item.variantId && item.item.id === variantId),
            )?.itemForm ?? undefined
        );
    };

    return { getVariantItemFormInCart };
};

/**
 * Hook to delete item from cart state.
 */
export const useLocalCartItemDelete = ({
    storeId,
    itemId,
    custom,
}: {
    storeId: string;
    itemId: string;
    custom?: boolean;
}): ((variantId?: string) => void) => {
    const updateItemInCart = useUpdateItemInLocalCart(storeId);

    const removeItem = useCallback(
        (variantId?: string) => {
            updateItemInCart({
                itemId,
                quantity: 0,
                custom,
                variantId,
            });
        },
        [itemId, updateItemInCart, custom],
    );

    return removeItem;
};

type LoadCartViewReturnType = {
    isLoading: boolean;
    canShowCustomCartHandlerModal: boolean;
    customCartChoiceHandler: ({ canMergeItems }: { canMergeItems?: boolean }) => void;
    setShowCustomCartHandlerModal: (canShowModal: boolean) => void;
};

/**
 * Hook to load the cart view for a store.
 */
export const useLoadCartView = (
    storeId: string,
    cartKey?: string | null,
): LoadCartViewReturnType => {
    const dispatch = useContext(CartDispatchContext);
    const { syncing, ...localCart } = useLocalCart(storeId);
    const fetchCart = useFetchCartFromServer(storeId, cartKey ?? undefined);

    const areThereItemsInLocalCart = React.useMemo(
        () => localCart.items.length > 0,
        [localCart.items.length],
    );
    const isValidCartKey = React.useMemo(() => Boolean(cartKey?.trim().length), [cartKey]);

    const [loading, setLoading] = useState(true);
    const [canShowCustomCartHandlerModal, setShowCustomCartHandlerModal] = useState(
        areThereItemsInLocalCart && isValidCartKey,
    );

    const mounted = useMountedRef();
    const errorHandler = useErrorHandler();

    const user = useUserInfo();
    const userRef = useRef(user);

    const updateParams = getUpdateCartParams(storeId, localCart);

    const { fetch } = useController();

    const updateCart = useCallback(
        async (canMergeItems = true, isThereCartKeyCheck = false) => {
            try {
                if (!cartKey?.length) {
                    const isSyncReq = await fetchCart(true);
                    if (!isSyncReq) {
                        setLoading(false);
                        return;
                    }
                }

                const cartEntity = await fetch(UpdateCart, {
                    ...updateParams,
                    cart: {
                        ...updateParams.cart,
                        cartItems: canMergeItems ? updateParams.cart.cartItems : [],
                    },
                    cartKey: (!isThereCartKeyCheck ? cartKey : undefined) ?? undefined,
                });

                if (mounted.current && cartEntity) {
                    setLoading(false);
                    dispatch({
                        type: "UPDATE_CART_ACTION",
                        payload: {
                            storeId: cartEntity.storeId,
                            cart: buildLocalCartFromCartView(
                                cartEntity,
                                updateParams.addressId,
                                updateParams.cart.guestUserDetails,
                            ),
                            isSyncedWithServer: true,
                        },
                    });
                }
            } catch (err) {
                if (mounted.current) {
                    setLoading(false);
                    errorHandler(err);
                }
            }
        },
        [cartKey, dispatch, errorHandler, fetch, fetchCart, mounted, updateParams],
    );

    const customCartChoiceHandler = useCallback(
        ({ canMergeItems = true }) => {
            setShowCustomCartHandlerModal(false);
            updateCart(canMergeItems, false);
        },
        [updateCart],
    );

    useIsomorphicEffect(() => {
        if (userRef.current !== user) {
            userRef.current = user;
            setLoading(true);
        }
    }, [user]);

    useFocusEffect(
        useCallback(() => {
            setLoading(true);
            return () => {
                setLoading(false);
            };
        }, []),
    );

    useEffect(() => {
        if (loading) {
            updateCart(true, canShowCustomCartHandlerModal);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return {
        isLoading: loading,
        canShowCustomCartHandlerModal,
        customCartChoiceHandler,
        setShowCustomCartHandlerModal,
    };
};

/**
 * Hook to get the cart view for a store.
 */
export const useCartView = (storeId: string): ICart | null => {
    const cart = useCache(FetchCart, { storeId });
    return useMemo(() => cart ?? null, [cart]);
};

/**
 * Hook to get the cart view data for a store.
 */
export const useCartViewData = (storeId: string): ICart["cartViewData"] | null => {
    const cartView = useCartView(storeId);
    return cartView?.cartViewData || null;
};

/**
 * Hook to get the cart status for a store.
 */
export const useCartStatus = (storeId: string): ICart["cartStatus"] => {
    const cartView = useCartView(storeId);
    return cartView?.cartStatus ?? "EMPTY_CART";
};

/**
 * Hook to get the cart items for a store.
 */
export const useGetItemsInCart = (storeId: string): CartItem[] => {
    const data = useCartViewData(storeId);
    return data?.cartItems ?? [];
};

/**
 * Hook to get the item for a store cart.
 */
export const useGetCartItem = (
    storeId: string,
    productId: string,
    variantId?: string,
): CartItem | undefined => {
    const data = useCartViewData(storeId);
    const items = data?.cartItems ?? [];
    const matchedItem = items.find(
        (itemData) =>
            itemData.item.id === productId &&
            (variantId ? variantId === itemData.item.variantId : true),
    );
    return matchedItem;
};

export const useUpdateCartAddress = (storeId: string): ((addressId: string) => void) => {
    const cart = useLocalCart(storeId);
    const dispatch = useContext(CartDispatchContext);

    return useCallback(
        (addressId: string) => {
            if (!cart) {
                return;
            }

            dispatch({
                type: "UPDATE_CART_ACTION",
                payload: {
                    storeId,
                    cart: {
                        ...cart,
                        addressId,
                    },
                },
            });
        },
        [cart, dispatch, storeId],
    );
};

export const useSelectedCartAddress = (storeId: string): UserAddress | null => {
    const localCartData = useLocalCart(storeId);
    const cartData = useCartViewData(storeId);

    if (localCartData.addressId && cartData) {
        const address = cartData.addresses.filter(
            (location) => location.id === localCartData.addressId,
        )[0];

        return address || null;
    }

    return null;
};

export const useSelectedCartLocation = (storeId: string): { lat: string; lng: string } | null => {
    const cartData = useCartViewData(storeId);
    return cartData?.userLocation || null;
};

/**
 * The hook to keep the local cart in sync with the server.
 */
export const useSyncCartWithServer = (storeId: string): boolean => {
    const dispatch = useContext(CartDispatchContext);
    const localCart = useLocalCart(storeId);
    const { fetch } = useController();
    const onClearCart = useCartClearWithoutHardDelete(storeId);

    const ref = React.useRef(false);
    const mountedRef = useMountedRef();
    const [showToast] = useToast();

    const localCartRef = useRef(localCart);
    const { activateOrDeactivateCelebrationModal } = useCelebrationModal(storeId);

    const handleCartUpdate = useCallback(
        async (newCart: LocalCart, prevCart?: LocalCart | null) => {
            if (ref.current) {
                return;
            }

            ref.current = true;

            dispatch({ type: "SYNC_CART_ACTION", payload: { storeId, syncing: true } });

            try {
                const isCartEmpty = newCart.items.length === 0;

                if (isCartEmpty && !newCart.isSyncedWithServer) {
                    await onClearCart();
                }

                const cartUpdateParams = getUpdateCartParams(storeId, newCart);
                const cartEntity = await fetch(UpdateCart, cartUpdateParams);

                if (mountedRef.current) {
                    const updatedLocalCart = buildLocalCartFromCartView(
                        cartEntity,
                        cartUpdateParams.addressId,
                        cartUpdateParams.cart.guestUserDetails,
                    );

                    localCartRef.current = { ...updatedLocalCart, isSyncedWithServer: true };

                    dispatch({
                        type: "UPDATE_CART_ACTION",
                        payload: {
                            storeId: cartEntity.storeId,
                            cart: updatedLocalCart,
                            isSyncedWithServer: true,
                        },
                    });
                }
            } catch (err) {
                if (mountedRef.current) {
                    Logger.recordError(err);

                    showToast("Failed to update the cart!");

                    // deactivateCelebrationModal
                    activateOrDeactivateCelebrationModal(false);

                    // reset the cart
                    if (prevCart) {
                        localCartRef.current = prevCart;
                        dispatch({
                            type: "UPDATE_CART_ACTION",
                            payload: {
                                storeId,
                                cart: prevCart,
                            },
                        });
                    }
                }
            }

            ref.current = false;
        },
        [
            activateOrDeactivateCelebrationModal,
            dispatch,
            fetch,
            mountedRef,
            onClearCart,
            showToast,
            storeId,
        ],
    );

    useEffect(() => {
        if (localCartRef.current !== localCart) {
            if (!compareLocalCart(localCart, localCartRef.current)) {
                handleCartUpdate(localCart, localCartRef.current);
            }
        }
    }, [handleCartUpdate, localCart]);

    return ref.current;
};

/**
 * Hook to update an guest details in the cart.
 */
export const useCartUpdateGuestDetails = (
    storeId: string,
): [boolean, (guestDetails: ICartGuestDetails) => Promise<CartEntity | null>] => {
    const dispatch = useContext(CartDispatchContext);
    const { fetch } = useController();
    const localCart = useLocalCart(storeId);
    const mounted = useMountedRef();
    const [loading, setLoading] = useState(false);

    const cb = useCallback(
        async (guestDetails: ICartGuestDetails) => {
            setLoading(true);

            const cartUpdateParams = getUpdateCartParams(storeId, {
                ...localCart,
                guestDetails,
            });

            const cartEntity = await fetch(UpdateCart, cartUpdateParams).catch((err) => {
                mounted.current && setLoading(false);
                throw err;
            });

            if (mounted.current) {
                setLoading(false);
            }

            if (mounted.current && cartEntity) {
                dispatch({
                    type: "UPDATE_CART_ACTION",
                    payload: {
                        storeId: cartEntity.storeId,
                        cart: buildLocalCartFromCartView(
                            cartEntity,
                            cartUpdateParams.addressId,
                            guestDetails,
                        ),
                        isSyncedWithServer: true,
                    },
                });

                return cartEntity as CartEntity;
            }

            return null;
        },
        [dispatch, fetch, localCart, mounted, storeId],
    );

    return useMemo(() => [loading, cb], [loading, cb]);
};

/**
 * Hook to update batch items in the cart.
 */
export const useUpdateBatchItemsInLocalCart = (
    storeId: string,
): ((batchItems: UpdateItemInCartParams[]) => void) => {
    const dispatch = useContext(CartDispatchContext);

    const cb = useCallback(
        (batchItems: UpdateItemInCartParams[]) => {
            const newCartItems: LocalCart["items"] = [];

            batchItems.forEach((item) => {
                newCartItems.push({
                    id: item.itemId,
                    variantId: item.variantId,
                    quantity: item.quantity || 0,
                    custom: item.custom,
                    productType: item.productType ?? "PHYSICAL",
                });
            });

            dispatch({
                type: "UPDATE_CART_ACTION",
                payload: {
                    storeId,
                    cart: {
                        items: newCartItems,
                    },
                },
            });
        },
        [dispatch, storeId],
    );

    return cb;
};

export const getUpdateCartParams = (
    storeId: string,
    cart: Omit<LocalCart, "syncing">,
): UpdateCartParams => {
    return {
        storeId,
        addressId: cart.addressId,
        cart: {
            cartItems: cart.items
                .filter((i) => i.quantity > 0)
                .map((i) => ({
                    id: i.id,
                    quantity: i.quantity,
                    variantId: i.variantId,
                    custom: i.custom ?? false,
                    slot: i.slot,
                    itemForm: i.itemForm,
                    productType: i.productType,
                })),
            appliedCoupon: cart.appliedCoupon,
            guestUserDetails: cart.guestDetails,
        },
    };
};

export const buildLocalCartFromCartView = (
    cart: ICart,
    addressId?: string,
    guestDetails?: ICartGuestDetails,
): LocalCart => {
    return {
        addressId: cart.cartViewData?.serviceableAddressId === addressId ? addressId : undefined,
        items:
            cart.cartViewData?.cartItems.map((i) => ({
                id: i.item.id,
                quantity: i.quantity,
                variantId: i.item.variantId,
                custom: i.metadata.custom ?? false,
                productType: i.item.productType,
                slot: i.slot,
                itemForm: i.itemForm,
            })) ?? [],
        appliedCoupon: cart.cartViewData?.appliedCoupon?.code,
        updatedAt: cart.cartViewData?.updatedAt,
        guestDetails,
    };
};

export const compareLocalCart = (cart1: LocalCart, cart2: LocalCart): boolean => {
    if (cart1.items.length !== cart2.items.length) {
        return false;
    }

    if (cart1.addressId !== cart2.addressId) {
        return false;
    }

    if (cart1.appliedCoupon !== cart2.appliedCoupon) {
        return false;
    }

    return cart1.items.every((i) => {
        const item = cart2.items.find((l) => l.id === i.id && l.variantId === i.variantId);
        return item != null && item.quantity === i.quantity;
    });
};

/**
 * Gets and sets cart instructions in cart state context
 */
export const useCartInstructions = (storeId: string): [string, (i: string) => void] => {
    const dispatch = useContext(CartDispatchContext);
    const { instructions = {} } = useContext(CartStateContext);

    const setInstruction = React.useCallback(
        (instruction: string) => {
            dispatch({
                type: "UPDATE_CART_INSTRUCTION",
                payload: {
                    storeId,
                    instruction,
                },
            });
        },
        [dispatch, storeId],
    );

    const instruction = instructions[storeId] || "";

    return [instruction, setInstruction];
};

/**
 * Updates applied coupon code in the cart (apply or remove both)
 */
export const useUpdateCartAppliedCoupon = (
    storeId: string,
): ((appliedCoupon?: string) => Promise<void>) => {
    const cart = useLocalCart(storeId);
    const dispatch = useContext(CartDispatchContext);

    return useCallback(
        async (appliedCoupon?: string) => {
            if (!cart) {
                return;
            }

            dispatch({
                type: "UPDATE_CART_ACTION",
                payload: {
                    storeId,
                    cart: {
                        ...cart,
                        appliedCoupon,
                    },
                },
            });
        },
        [cart, dispatch, storeId],
    );
};

/**
 * Update and Get coupon celebration modal flag to hide or show it
 */
export const useCelebrationModal = (
    storeId: string,
): {
    isCelebrationModalActive: Record<string, boolean> | undefined;
    activateOrDeactivateCelebrationModal: (active: boolean) => void;
} => {
    const dispatch = useContext(CartDispatchContext);
    const { isCelebrationModalActive } = useContext(CartStateContext);

    const activateOrDeactivateCelebrationModal = useCallback(
        (active: boolean) => {
            dispatch({
                type: "UPDATE_IS_CELEBRATION_MODAL_ACTIVE",
                payload: {
                    storeId,
                    isCelebrationModalActive: active,
                },
            });
        },
        [dispatch, storeId],
    );

    return { isCelebrationModalActive, activateOrDeactivateCelebrationModal };
};

/**
 * When a coupon was previously applied but was invalid,
 * it gets saved in the appliedCoupon object in the cart,
 * but with applied flag as false, which prevents from
 * triggering the update API call again for cart, this hook
 * helps trigger the update API call to retry applying the
 * coupon as the user should be abe to retry
 */
// @TODO: refactor to avoid repeating handleCartUpdate code, suggestions are welcome
export const useApplyCouponIfAlreadyInvalid = (
    storeId: string,
): {
    reapplyingInvalidCoupon: boolean;
    handleCartUpdate: () => Promise<void>;
} => {
    const dispatch = useContext(CartDispatchContext);
    const localCart = useLocalCart(storeId);
    const { fetch } = useController();
    const onClearCart = useCartClearWithoutHardDelete(storeId);

    const ref = React.useRef(false);
    const mountedRef = useMountedRef();
    const [showToast] = useToast();

    const localCartRef = useRef(localCart);
    const { activateOrDeactivateCelebrationModal } = useCelebrationModal(storeId);

    const handleCartUpdate = useCallback(async () => {
        if (ref.current) {
            return;
        }

        ref.current = true;

        dispatch({ type: "SYNC_CART_ACTION", payload: { storeId, syncing: true } });

        try {
            const isCartEmpty = localCart.items.length === 0;

            if (isCartEmpty && !localCart.isSyncedWithServer) {
                await onClearCart();
            }

            const cartUpdateParams = getUpdateCartParams(storeId, localCart);
            const cartEntity = await fetch(UpdateCart, cartUpdateParams);

            if (mountedRef.current) {
                const updatedLocalCart = buildLocalCartFromCartView(
                    cartEntity,
                    cartUpdateParams.addressId,
                );

                localCartRef.current = { ...updatedLocalCart, isSyncedWithServer: true };

                dispatch({
                    type: "UPDATE_CART_ACTION",
                    payload: {
                        storeId: cartEntity.storeId,
                        cart: updatedLocalCart,
                        isSyncedWithServer: true,
                    },
                });
            }
        } catch (err) {
            if (mountedRef.current) {
                Logger.recordError(err);

                showToast("Failed to update the cart!");

                // deactivateCelebrationModal
                activateOrDeactivateCelebrationModal(false);
            }
        }

        ref.current = false;
    }, [
        activateOrDeactivateCelebrationModal,
        dispatch,
        fetch,
        localCart,
        mountedRef,
        onClearCart,
        showToast,
        storeId,
    ]);

    return {
        reapplyingInvalidCoupon: ref.current,
        handleCartUpdate,
    };
};

/**
 * Clear cart by soft deleting it on server
 */
export const useCartClearWithoutHardDelete = (
    storeId: string,
    canDoSoftDelete = true,
): (() => Promise<void>) => {
    const dispatch = useContext(CartDispatchContext);
    const { fetch } = useController();
    const isGuestUser = useSignedIn() === false; // guest clear cart is not supported at BE

    const onClearCart = React.useCallback(async () => {
        try {
            if (canDoSoftDelete && !isGuestUser) {
                await fetch(SoftClearCart, { storeId });
            }

            dispatch({ type: "CLEAR_CART_ACTION", payload: { storeId } });
        } catch (err) {
            Logger.recordError(err);
        }
    }, [canDoSoftDelete, dispatch, fetch, isGuestUser, storeId]);

    return onClearCart;
};

/**
 * @param storeId
 * @param cartKey
 * @returns a function that fetches cart from server and updates the local cart
 */
export const useFetchCartFromServer = (
    storeId: string,
    cartKey?: string,
): ((b: boolean) => Promise<boolean>) => {
    const mountedRef = useMountedRef();
    const { fetch } = useController();
    const localCart = useLocalCart(storeId);

    const dispatch = React.useContext(CartDispatchContext);
    const localCartRef = React.useRef(localCart);

    const fetchCartFromServer = React.useCallback(
        async (shouldDoUpdationCheck = false) => {
            try {
                const cartEntity = await fetch(FetchCart, {
                    storeId,
                    cartKey,
                    addressId: localCart.addressId,
                });

                if (!mountedRef.current || !cartEntity) {
                    return false;
                }

                // for guest cart, always return `true` to trigger the update
                if (localCartRef.current.guestDetails) {
                    return true;
                }

                if (shouldDoUpdationCheck) {
                    const serverUpdatedAt = cartEntity.cartViewData?.updatedAt ?? 0;
                    const localUpdatedAt = localCart.updatedAt ?? 0;

                    if (serverUpdatedAt < localUpdatedAt) {
                        return true;
                    }
                }

                const updatedLocalCart = buildLocalCartFromCartView(
                    cartEntity,
                    localCart.addressId,
                );

                localCartRef.current = { ...updatedLocalCart, isSyncedWithServer: true };

                dispatch({
                    type: "UPDATE_CART_ACTION",
                    payload: {
                        storeId,
                        cart: updatedLocalCart,
                        isSyncedWithServer: true,
                    },
                });

                return false;
            } catch (err) {
                if (mountedRef.current) {
                    Logger.recordError(err);
                }

                return false;
            }
        },
        [cartKey, dispatch, fetch, localCart.addressId, localCart.updatedAt, mountedRef, storeId],
    );

    return fetchCartFromServer;
};
