import React, { useCallback, useState } from "react";

import { useGetProducts } from "@minis-consumer/hooks/use-get-products";
import { VariantsBottomSheet } from "@minis-consumer/components/variants";
import { useClearCartModalHandler } from "@minis-consumer/hooks/use-clear-cart-modal-handler";
import { useCartViewData, useLocalCart } from "@minis-consumer/hooks/use-cart";
import { getCanAddOrBookProduct } from "@minis-consumer/helpers/can-add-or-book-product";
import { useGetPdpCtaNavigation } from "@minis-consumer/hooks/use-pdp-cta-navigation";
import { Analytics } from "@minis-consumer/analytics";

import { useCustomNavigation } from "../../hooks/use-get-minis-navigation";
import { ProductCtaProps } from "../..";
import { CounterActionType } from "../../hooks/use-get-cta-actions";
import { ARD_EVENT_NAME } from "../../constants";

/* eslint-disable react/display-name */
export const withVariantFormCalendarSelector =
    (Component: React.FC<ProductCtaProps & { canAdd?: boolean }>) =>
    (props: ProductCtaProps & { canAdd?: boolean }) => {
        const {
            productId,
            ctaText,
            incrementActionHandler,
            decrementActionHandler,
            singleActionHandler,
            storeId,
            ctaState,
            showVariants: canShowVariantSelector,
            selectedVariant,
            onCloseDialog,
            sfWidget,
            itemStatus,
            productActionCtaName,
            quantity,
        } = props;

        const products = useGetProducts();
        const product = products?.find((p) => p.id === productId);

        const navigation = useCustomNavigation();

        const [showVariantsSheet, setShowVariantsSheet] = useState<boolean>(false);

        const showVariants = React.useMemo(() => {
            return canShowVariantSelector && !!product?.variantDetails;
        }, [canShowVariantSelector, product?.variantDetails]);

        const { dataHandler, modalViewHandler } = useClearCartModalHandler();

        const { productSelectedData, action: pdpNavAction } =
            useGetPdpCtaNavigation({ productId, minisNavigation: navigation }) || {};

        const cart = useLocalCart(storeId);
        const cartViewData = useCartViewData(storeId);

        const fireAnalyticsEvent = useCallback(
            (canAdd?: boolean, counterActionType?: CounterActionType) => {
                Analytics.clickEvent({
                    category: productActionCtaName ?? ARD_EVENT_NAME,
                    context: JSON.stringify({
                        quantity,
                        canAdd,
                        ctaText,
                        productId,
                        selectedVariant: selectedVariant?.id,
                        ctaState,
                        counterActionType: ctaState === "COUNTER" ? counterActionType : undefined,
                        productType: product?.productType,
                        isRecommended: product?.badges.includes("Recommended"),
                        price: product?.price,
                        discountedPrice: product?.discountedPrice,
                        isVariantsPresent: !!product?.variantDetails?.variants?.length,
                        isFormPresent: !!product?.form?.length,
                        isEnquiryFormPresent: !!product?.enquiryForm?.length,
                        sfWidget,
                        cart: cartViewData?.id,
                        billAmount: cartViewData?.bill?.billTotal,
                        cartItemStatus: itemStatus,
                    }),
                });
            },
            [
                productId,
                selectedVariant,
                ctaState,
                product,
                sfWidget,
                cartViewData,
                itemStatus,
                productActionCtaName,
                ctaText,
                quantity,
            ],
        );

        const variantDetails = product?.variantDetails?.variants.filter(
            (item) => item.id === selectedVariant?.id,
        );

        const singleAction = useCallback(() => {
            const { canAdd, prefixText } = getCanAddOrBookProduct({
                productId,
                price: selectedVariant ? variantDetails?.[0]?.price : product?.price,
                productType: product?.productType,
                cartItems: cart.items ?? [],
                ctaState,
            });

            if (ctaState === "SHOW_VARIANTS" && canAdd) {
                fireAnalyticsEvent(canAdd);
                setShowVariantsSheet(true);
                return;
            }

            if ((ctaState === "ADD" || ctaState === "SHOW_VARIANTS") && !canAdd) {
                dataHandler({
                    storeId,
                    descriptionPrefix: prefixText ?? "",
                });

                fireAnalyticsEvent(canAdd);
                modalViewHandler(true);
                return;
            } else {
                if (showVariants && ctaState === "ADD") {
                    fireAnalyticsEvent(canAdd, "DECREMENT");
                    setShowVariantsSheet(true);
                    return;
                }

                if (ctaState === "ADD" && canAdd) {
                    fireAnalyticsEvent(canAdd);
                    onCloseDialog?.();

                    pdpNavAction?.({
                        ...productSelectedData,
                        variantId: selectedVariant?.id,
                        originalCallback: singleActionHandler,
                    });

                    return;
                }

                fireAnalyticsEvent(canAdd);
                singleActionHandler?.();
            }
        }, [
            showVariants,
            singleActionHandler,
            ctaState,
            productId,
            product,
            cart,
            modalViewHandler,
            dataHandler,
            storeId,
            pdpNavAction,
            productSelectedData,
            selectedVariant,
            onCloseDialog,
            fireAnalyticsEvent,
        ]);

        const onProductAdd = useCallback(() => {
            const { canAdd, prefixText } = getCanAddOrBookProduct({
                productId,
                price: product?.price,
                productType: product?.productType,
                cartItems: cart.items ?? [],
            });

            if (!canAdd) {
                dataHandler({
                    storeId,
                    descriptionPrefix: prefixText ?? "",
                });

                fireAnalyticsEvent(canAdd, "INCREMENT");
                modalViewHandler(true);
                return;
            }

            if (showVariants) {
                fireAnalyticsEvent(canAdd, "INCREMENT");
                setShowVariantsSheet(true);
                return;
            }

            fireAnalyticsEvent(canAdd, ctaState === "COUNTER" ? "INCREMENT" : undefined);
            incrementActionHandler?.();
        }, [
            showVariants,
            incrementActionHandler,
            productId,
            product,
            cart,
            modalViewHandler,
            dataHandler,
            storeId,
            ctaState,
            fireAnalyticsEvent,
        ]);

        const onProductRemove = useCallback(() => {
            if (showVariants) {
                fireAnalyticsEvent(undefined, "DECREMENT");
                setShowVariantsSheet(true);
                return;
            }

            fireAnalyticsEvent(undefined, ctaState === "COUNTER" ? "DECREMENT" : undefined);
            decrementActionHandler?.();
        }, [decrementActionHandler, fireAnalyticsEvent, showVariants, ctaState]);

        return (
            <>
                <Component
                    {...props}
                    singleActionHandler={singleAction}
                    incrementActionHandler={onProductAdd}
                    decrementActionHandler={onProductRemove}
                />
                {showVariantsSheet && product && storeId ? (
                    <VariantsBottomSheet
                        product={product}
                        storeId={storeId}
                        open={showVariantsSheet}
                        setOpen={setShowVariantsSheet}
                        sfWidget={sfWidget}
                    />
                ) : null}
            </>
        );
    };

if (__DEV__) {
    withVariantFormCalendarSelector.displayName = "withVariantSelector";
}
