import { useGetSession } from "@minis-consumer/hooks/use-get-session";
import { getConfigInfo } from "@minis-consumer/helpers/config-info";
import { ConfigInfo } from "@minis-consumer/interfaces/config-info";

export const useGetConfigInfo = (): ConfigInfo | null => {
    const { config } = useGetSession();
    const configInfo = getConfigInfo();

    return config || configInfo;
};
