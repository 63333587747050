import React from "react";
import { StyleSheet } from "react-native";

import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { SpacingValue, Surface, useTheme } from "@swiggy-private/rn-dls";

import { WidgetPresser } from "@minis-consumer/components/widget-presser";
import { HapticPressable } from "@minis-consumer/components/haptic-pressable";

import { DEFAULTS } from "../../constants";

interface FeedbackCtaProps {
    isCtaActive: boolean;
    iconSize: number;

    onPress?: () => Promise<void>;
}

const FeedbackCtaComponent: React.FC<FeedbackCtaProps> = ({ isCtaActive, onPress, iconSize }) => {
    const { value: theme } = useTheme();

    const [shouldAnimate, setAnimate] = React.useState(false);
    const [isBtnPressed, setBtnPressed] = React.useState(false);

    const ctaAnimationCb = React.useCallback(() => {
        setAnimate(false);
        setBtnPressed(!isBtnPressed);
        onPress?.();
    }, [isBtnPressed, onPress]);

    const onPressCta = React.useCallback(() => {
        setAnimate(true);
    }, []);

    const extraCtaContainerStyle = {
        backgroundColor: isCtaActive ? theme["color-primary-400"] : theme["color-basic-5"],
        borderRadius: isCtaActive ? 30 : 12,
        elevation: isCtaActive ? 2 : 0,
    };

    return (
        <WidgetPresser
            animationDuration={DEFAULTS.CTA_ANIMATION_DURATION}
            callback={ctaAnimationCb}
            shouldReverseAnimation={isBtnPressed}
            shouldTriggerAnimation={shouldAnimate}>
            <Surface style={extraCtaContainerStyle}>
                <HapticPressable
                    feedbackType="notificationSuccess"
                    onPress={onPressCta}
                    disabled={!isCtaActive}
                    style={[styles.ctaContainer, extraCtaContainerStyle]}>
                    <SvgIcon
                        icon="SendFilled"
                        height={iconSize}
                        width={iconSize}
                        color={isCtaActive ? theme["color-basic-0"] : theme["color-basic-60"]}
                    />
                </HapticPressable>
            </Surface>
        </WidgetPresser>
    );
};

const styles = StyleSheet.create({
    ctaContainer: {
        padding: SpacingValue["space-medium"],
    },
});

export const FeedbackCta = React.memo(FeedbackCtaComponent);
