import * as React from "react";
import { Keyboard, Platform, StyleSheet, TextInput, useWindowDimensions, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useMeasure } from "@swiggy-private/react-native-ui";
import {
    ChatConversationFooterProps,
    ChatMessageInputView,
    ChatMessageInputViewProps,
    ISentMessageResponse,
    useChatConversationState,
    useChatState,
    useSendImageMessage,
    useSendVideoMessage,
} from "@swiggy-private/connect-chat-ui";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { SpacingValue, Surface, useTheme } from "@swiggy-private/rn-dls";
import { UploadedMediaFile } from "@swiggy-private/rn-image-picker";
import { Stack } from "@swiggy-private/rn-adaptive-layout";

import { Analytics } from "@minis-consumer/analytics";
import { MediaUploadWrapperModal } from "@minis-consumer/components/media-upload-wrapper-modal";
import { useSignedIn } from "@minis-consumer/hooks/use-user";

import { ANALYTICS_COPIES } from "./constants";
import { checkIsBlockedByCustomer } from "../../helpers/chat-blocked";
import { BlockedChatFooter } from "./blocked-footer";

const isPlatformAndroid = Platform.OS === "android";

const useGetBottomOffsetForModal = (y: number): number => {
    const { height: windowHeight } = useWindowDimensions();
    return windowHeight - y + SpacingValue["space-x-small"];
};

export const ChatFooter: React.FC<ChatConversationFooterProps> = ({
    style,
    testID,
    onSend,
    ...props
}) => {
    const { value: theme } = useTheme();
    const isSignedIn = useSignedIn();

    const [showMediaUploadModal, setShowMediaUploadModal] = React.useState(false);

    const inset = useSafeAreaInsets();

    const footerRef = React.useRef<View>(null);
    const inputRef = React.useRef<TextInput>(null);
    const layout = useMeasure(footerRef.current, true);

    const onMessageSent = React.useCallback((sentMessageRes: ISentMessageResponse) => {
        Analytics.clickEvent({
            screen: "seller-chat-window",
            category: "chat-message-send-btn",
            label: `conversationId: ${sentMessageRes?.conversationId}, messageId: ${sentMessageRes?.message.id}, initiatedBy: customer`,
            context: `timestamp: ${sentMessageRes?.message.timestamp}, messageType: ${sentMessageRes?.message.type}`,
        });
    }, []);

    const { conversation } = useChatConversationState();
    const sendImageMessage = useSendImageMessage(conversation.id);
    const sendVideoMessage = useSendVideoMessage(conversation.id);

    /** Temporary change to meet RC cut since chat conversation dispatch isn't working */
    const { conversations } = useChatState();
    const targetConversation = conversations[conversation.id];
    /** */

    const isConversationBlocked = !!targetConversation.blockedInfo?.blockedBy?.length;
    const isBlockedByCustomer = checkIsBlockedByCustomer(targetConversation?.blockedInfo);
    const isBlockedBySeller = isConversationBlocked && !isBlockedByCustomer;
    const canShowBlockedFooter =
        isSignedIn && conversation.chatBlockFeatureEnabled && isConversationBlocked;

    const onAttach = React.useCallback(() => {
        Analytics.clickEvent({ category: "add-attachment" });
        Keyboard.dismiss();
        requestAnimationFrame(() => setShowMediaUploadModal(true));
    }, []);

    const accessoryRight: NonNullable<ChatMessageInputViewProps["accessoryRight"]> =
        React.useCallback(
            ({ send }) => <InputAccessory onSend={send} onAttach={onAttach} />,
            [onAttach],
        );

    const onShareMedia = React.useCallback(
        (media: UploadedMediaFile[]) => {
            setShowMediaUploadModal(false);
            if (!media.length) {
                return;
            }

            const firstMedia = media[0];
            const sentMessageRes =
                firstMedia.type === "image"
                    ? sendImageMessage({
                          full: firstMedia.mediaId,
                          thumbnail: firstMedia.mediaId,
                          multiple: media.length > 1 ? media.map((m) => m.mediaId) : [],
                      })
                    : sendVideoMessage({
                          video: firstMedia.mediaId,
                      });

            sentMessageRes && onMessageSent(sentMessageRes);
        },
        [onMessageSent, sendImageMessage, sendVideoMessage],
    );

    const bottomOffsetForModal = useGetBottomOffsetForModal(layout?.pageY || 0);
    const paddingBottom = inset.bottom + SpacingValue["space-medium"];

    const backgroundStyle = { backgroundColor: theme["color-basic-15"] };
    const analyticsData = {
        category: ANALYTICS_COPIES.CHAT_UPLOAD_IMAGE_HALF_CARD,
        /**
         * TEMP CODE
         * Since Camera upload isn't working on android in chat
         * adding condition for now. This shall be fixed / dynamically
         * handled for chat feature
         * */
        context: isPlatformAndroid ? ANALYTICS_COPIES.GALLERY : ANALYTICS_COPIES.GALLERY_AND_CAMERA,
    };

    return (
        <Surface
            style={[
                styles.container,
                { paddingBottom },
                style,
                isBlockedBySeller && backgroundStyle,
            ]}
            ref={footerRef}>
            {canShowBlockedFooter ? (
                <BlockedChatFooter />
            ) : (
                <ChatMessageInputView
                    {...props}
                    onSend={onSend}
                    accessoryRight={accessoryRight}
                    testID={testID}
                    onMessageSent={onMessageSent}
                    inputRef={inputRef}
                />
            )}

            {showMediaUploadModal ? (
                <MediaUploadWrapperModal
                    onUpload={onShareMedia}
                    bottomOffset={bottomOffsetForModal}
                    analytics={analyticsData}
                />
            ) : null}
        </Surface>
    );
};

type InputAccessoryProps = {
    onSend: () => void;
    onAttach?: () => void;
};

const InputAccessory: React.FC<InputAccessoryProps> = React.memo((props) => {
    const { onSend, onAttach } = props;

    const hitSlop = {
        top: SpacingValue["space-small"],
        bottom: SpacingValue["space-small"],
        left: SpacingValue["space-x-small"],
        right: SpacingValue["space-x-small"],
    };

    const iconSize = 20;

    return (
        <Stack style={styles.inputAccessory} spacing={SpacingValue["space-medium"]} direction="row">
            {onAttach ? (
                <>
                    <SvgIcon
                        icon="Gallery"
                        width={iconSize}
                        height={iconSize}
                        color="color-basic-45"
                        accessible
                        accessibilityRole="button"
                        accessibilityLabel="Attach"
                        onPress={onAttach}
                        hitSlop={hitSlop}
                    />
                </>
            ) : null}

            <SvgIcon
                icon="SendFilled"
                width={iconSize}
                height={iconSize}
                color="color-primary"
                accessible
                accessibilityRole="button"
                accessibilityLabel="Send"
                onPress={onSend}
                hitSlop={hitSlop}
            />
        </Stack>
    );
});

if (__DEV__) {
    InputAccessory.displayName = "InputAccessory";
}

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: SpacingValue["space-small"],
        paddingVertical: SpacingValue["space-medium"],
        flexDirection: "row",
        alignItems: "center",
        elevation: 4,
    },
    inputAccessory: {
        alignSelf: "flex-end",
        marginLeft: SpacingValue["space-x-small"],
    },
});
