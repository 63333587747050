import * as React from "react";

import { ChatBlockModalContext } from "@minis-consumer/contexts/chat-block-modal-context";

export const useChatBlockyModalHandler = (): {
    isOpen: boolean;
    conversationId: string;
    isBlocked: boolean;
    personName: string;
    modalViewHandler: (b: boolean) => void;
    dataHandler: (p?: string, i?: boolean, s?: string) => void;
} => {
    const ctx = React.useContext(ChatBlockModalContext);

    const dataHandler = React.useCallback(
        (conversationId?: string, isBlocked?: boolean, personName?: string) => {
            isBlocked !== undefined && ctx?.setIsBlocked(isBlocked);
            conversationId !== undefined && ctx?.setConversationId(conversationId ?? "");
            personName !== undefined && ctx?.setPersonName(personName ?? "");
        },
        [ctx],
    );

    const modalViewHandler = React.useCallback(
        (open: boolean) => {
            if (open) {
                ctx?.open();
            } else {
                ctx?.close();
            }
        },
        [ctx],
    );

    return {
        isOpen: ctx?.isOpen ?? false,
        conversationId: ctx?.conversationId ?? "",
        isBlocked: ctx?.isBlocked ?? false,
        personName: ctx?.personName ?? "",
        modalViewHandler,
        dataHandler,
    };
};
