import React, { useContext, useEffect, useRef, useState } from "react";
import { useFocusEffect } from "@react-navigation/core";

import { AppNudgeContext } from "@minis-consumer/contexts/app-nudge-context";

import { useShowCounter } from "./use-show-counter";

type ReturnValue = {
    isTooltipVisible: boolean;
    onDismissTooltip: VoidFunction;
    onMountTooltip: VoidFunction;
};

type Props = {
    key: string;
    value: boolean;
    startTime: number;
    dismissAfter: number;
    lifetimeDisplayCount?: number;
    sessionDisplayCount?: number;
};

export const useShowTimeBasedTooltip = ({
    key,
    value,
    startTime,
    dismissAfter,
    lifetimeDisplayCount = 3,
    sessionDisplayCount = 3,
}: Props): ReturnValue => {
    const showTooltipRef = useRef(value);

    const [isTooltipViewCountLegal, incrementCounter] = useShowCounter(key, lifetimeDisplayCount);
    const ctx = useContext(AppNudgeContext);

    const tooltipCountShowedInSession = ctx?.timebasedTooltipsCountInSession[key];

    const [show, setShow] = useState(false);
    const startTimeoutId = useRef<NodeJS.Timeout | undefined>();
    const dismissTimeoutId = useRef<NodeJS.Timeout | undefined>();

    useEffect(() => {
        showTooltipRef.current = value;
    }, [value]);

    const onDismissTooltip = React.useCallback(() => {
        setShow(false);
        if (startTimeoutId.current) {
            clearTimeout(startTimeoutId.current);
        }
        if (dismissTimeoutId.current) {
            clearTimeout(dismissTimeoutId.current);
        }
    }, [dismissTimeoutId, startTimeoutId]);

    const updateShowTooltip = React.useCallback(() => {
        if (!tooltipCountShowedInSession || tooltipCountShowedInSession < sessionDisplayCount) {
            const isValidStatus = isTooltipViewCountLegal && showTooltipRef.current;

            if (isValidStatus) {
                ctx?.onCloseChatIconTooltip(
                    key,
                    tooltipCountShowedInSession === undefined ? 1 : tooltipCountShowedInSession + 1,
                );
                incrementCounter();
            }

            setShow(isValidStatus);
        }
    }, [
        ctx,
        incrementCounter,
        isTooltipViewCountLegal,
        key,
        sessionDisplayCount,
        tooltipCountShowedInSession,
    ]);

    const onMount = React.useCallback(() => {
        if (!startTime) {
            updateShowTooltip();
        } else {
            startTimeoutId.current = setTimeout(() => {
                updateShowTooltip();
                dismissTimeoutId.current = setTimeout(() => {
                    onDismissTooltip();
                }, dismissAfter);
            }, startTime);
        }

        return () => {
            onDismissTooltip();
        };
    }, [startTime, dismissAfter, updateShowTooltip, onDismissTooltip]);

    useFocusEffect(
        React.useCallback(() => {
            return () => {
                onDismissTooltip();
            };
        }, [onDismissTooltip]),
    );

    return {
        isTooltipVisible: show,
        onDismissTooltip,
        onMountTooltip: onMount,
    };
};
