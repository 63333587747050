import { useContext } from "react";
import { ToastContext, ToastContextValue } from "@minis-consumer/contexts/toast-context";

export const useToast = (): [ToastContextValue["showToast"], ToastContextValue["hideToast"]] => {
    const ctx = useContext(ToastContext);
    if (!ctx) {
        throw new Error("Missing toast context");
    }

    return [ctx.showToast, ctx.hideToast];
};
