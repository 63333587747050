import React from "react";

import { WidgetProps } from "@minis-consumer/interfaces/widget";

import { SearchInput, SearchInputProps } from "./components/search-input";
import {
    SearchWidget as HomeScreenSearchWidget,
    SearchWidgetProps,
} from "./components/search-widget";

type SearchBarWidgetProps = Partial<WidgetProps["SEARCH_BAR_WIDGET"]> & {
    searchInputProps?: SearchInputProps;
    homeScreenSearchWidgetProps?: SearchWidgetProps;
};

/**
 * Please note: This widget conditionally returns widgets depending on
 * if the input is enabled or not. The children components will undergo more
 * revamp during HP 2.0 so refactoring them is out of scope as of now
 */
const SearchBarWidgetComponent: React.FC<SearchBarWidgetProps> = ({
    widgetDetails,
    searchInputProps,
    homeScreenSearchWidgetProps,
}) => {
    const widgetData = widgetDetails?.data;

    if (searchInputProps) {
        return <SearchInput {...searchInputProps} />;
    }

    if (!homeScreenSearchWidgetProps || (widgetData?.isInputEnabled && !searchInputProps)) {
        return null;
    }

    return <HomeScreenSearchWidget {...homeScreenSearchWidgetProps} />;
};

export const SearchBarWidget = React.memo(SearchBarWidgetComponent);
