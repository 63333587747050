import React from "react";
import {
    ActivityIndicator,
    FlatList,
    ListRenderItem,
    StyleSheet,
    TextInput,
    View,
} from "react-native";
import { useNavigation } from "@react-navigation/core";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { Box } from "@swiggy-private/rn-adaptive-layout";
import { useDebounce, useMountedRef } from "@swiggy-private/react-hooks";
import { Portal, SpacingValue, Surface } from "@swiggy-private/rn-dls";

import { useFilteredProducts } from "@minis-consumer/hooks/use-filtered-products";
import { SearchNoResultsFound } from "@minis-consumer/components/catalog/search/no-results-found";
import { Product } from "@minis-consumer/interfaces/catalog";
import { ProductSectionItem } from "@minis-consumer/components/catalog/product-section-list/section-item";
import { useGetProductBadges } from "@minis-consumer/hooks/use-get-badges";
import { Divider } from "@minis-consumer/components/divider";
import { NavigationProvider } from "@minis-consumer/components/product-cta/hoc/with-variant-form-calendar/context";
import { RouteList } from "@minis-consumer/interfaces/route";

type Props = {
    searchText: string;
    left: number;
    top: number;
    onProductPress: (productId: string) => void;
    onOutsideClick: () => void;
};

export const SearchResultComponent = React.forwardRef<TextInput, Props>((props, ref) => {
    const [isSearching, setIsSearching] = React.useState(true);
    const resultsRef = React.useRef<View>(null);
    const minisNavigation = useNavigation<NativeStackNavigationProp<RouteList>>();

    const { searchText, left, top, onProductPress, onOutsideClick } = props;

    const surfaceStyle = React.useMemo(() => {
        return {
            left,
            top,
        };
    }, [left, top]);

    const debouncedSearchText = useDebounce(searchText, { wait: 300 });

    const mountedRef = useMountedRef();
    const badges = useGetProductBadges();

    const [filterListsFn, filteredList] = useFilteredProducts();

    React.useEffect(() => {
        if (debouncedSearchText.trim().length < 3) {
            filterListsFn("");
            return;
        }

        setIsSearching(true);

        filterListsFn(debouncedSearchText);

        if (!mountedRef.current) {
            return;
        }

        const timer = setTimeout(() => setIsSearching(false), 500);

        return () => {
            clearTimeout(timer);
        };
    }, [debouncedSearchText, mountedRef, filterListsFn]);

    const renderItem: ListRenderItem<Product> = ({ item, index }) => {
        return (
            <ProductSectionItem
                item={item}
                index={index}
                badges={badges}
                onProductPress={() => onProductPress(item.id)}
                callBeforeCtaNavigation={onOutsideClick}
            />
        );
    };

    React.useEffect(() => {
        const handleClickOutside = (event: MouseEvent): void => {
            if (
                resultsRef.current &&
                // @Todo: Fix use of any
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                !(resultsRef.current as any).contains(event.target) &&
                typeof ref !== "function" &&
                event.target !== ref?.current
            ) {
                onOutsideClick?.();
            }
        };
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, [onOutsideClick, ref, resultsRef]);

    if (!searchText.trim() || searchText.trim().length < 3) {
        return null;
    }

    return (
        <Portal>
            <NavigationProvider navigation={minisNavigation}>
                <Surface style={[styles.container, surfaceStyle]} ref={resultsRef}>
                    {!isSearching &&
                    !filteredList.length &&
                    searchText.trim() &&
                    searchText.trim().length >= 3 ? (
                        <SearchNoResultsFound searchText={searchText} />
                    ) : null}

                    {isSearching && searchText.trim() && searchText.trim().length >= 3 ? (
                        <Box style={styles.containerFlex} mt={SpacingValue["space-medium"]}>
                            <ActivityIndicator />
                        </Box>
                    ) : null}

                    {!isSearching &&
                    filteredList.length &&
                    searchText.trim() &&
                    searchText.trim().length >= 3 ? (
                        <FlatList
                            bounces={false}
                            showsVerticalScrollIndicator={true}
                            data={filteredList}
                            renderItem={renderItem}
                            keyExtractor={(item: Product) => item.id}
                            ItemSeparatorComponent={() => <Divider />}
                            contentContainerStyle={styles.contentConatiner}
                        />
                    ) : null}
                </Surface>
            </NavigationProvider>
        </Portal>
    );
});

if (__DEV__) {
    SearchResultComponent.displayName = "SearchResultComponent";
}

export const SearchResult = React.memo(SearchResultComponent);

const styles = StyleSheet.create({
    container: {
        flexGrow: 1,
        backgroundColor: "white",
        width: 500,
        minHeight: 300,
        maxHeight: 500,
        borderRadius: 24,
        position: "absolute",
        elevation: 2,
    },
    containerFlex: {
        flex: 1,
    },
    contentConatiner: {
        paddingHorizontal: SpacingValue["space-medium"],
    },
});
