export type IDynamicForm = FormFieldProps[];

export interface FormFieldProps {
    id: string;
    question: string;
    answerType: FieldType;

    answer?: string;
    required?: boolean;
    ansPlaceHolder?: string;
}

export enum FieldType {
    TEXT = "TEXT",
    NUMBER = "NUMBER",
    FILE = "FILE",
}
