import { SwiggyHeaders } from "@minis-consumer/interfaces/swiggy";
import { NativeModules, Platform } from "react-native";

export interface ILocation {
    areaName?: string;
    latitude?: number;
    longitude?: number;
}

export interface IUPIAppsList {
    label?: string;
    packageName?: string;
    icon?: string;
}

/**
 * TODO: Update once native code is updated
 * Today, we have perf methods written differently for android and ios.
 * This is because Android does not use screen name in the perf metrics
 * and ios does.
 * We shall update the native code to have same method for both platforms
 * and then we shall make the changes here acordingly.
 */
type ISwiggyRNModulePerfMonitor = (eventName: string, screenName?: string) => void;

interface ISwiggyRNModule {
    exit: () => void;
    fetchCurrentLocation: (fn: (location?: ILocation | null) => void) => void;
    openLogin: (fn: (headers?: SwiggyHeaders) => void) => void;
    launchDeeplink: (uri: string) => void;
    launchIntent: (deeplinkUri: string, packageName: string) => Promise<string>;
    hideSplashScreen: () => void;
    forceRefreshHomeScreen: () => void;
    // available on ios only
    forceRefreshTabs: () => void;
    stopInitialLoadTrace: ISwiggyRNModulePerfMonitor;
    startPerfMonitor: ISwiggyRNModulePerfMonitor;
    stopPerfMonitor: ISwiggyRNModulePerfMonitor;
    getAllUpiIntentAppsInstalledOnDevice: () => Promise<IUPIAppsList[]>;
    logNRCustomEvent: (
        tableName: string,
        eventName: string,
        screenName: string,
        value: string,
    ) => void;
}

const module: ISwiggyRNModule | null = NativeModules?.SwiggyRNModule ?? null;

type EventNullProps = string | null | undefined;

export class SwiggyRNModule implements ISwiggyRNModule {
    currentScreen = "-";

    private static _instance: SwiggyRNModule | null = null;
    private refreshHomeScreenOnExit = false;

    private constructor() {
        //
    }

    static getInstance(): SwiggyRNModule {
        if (SwiggyRNModule._instance == null) {
            SwiggyRNModule._instance = new SwiggyRNModule();
            return SwiggyRNModule._instance;
        }

        return SwiggyRNModule._instance;
    }

    static isModuleAvailable(): boolean {
        return module != null;
    }

    setScreenNameForPerf = (screen: EventNullProps): void => {
        this.currentScreen = screen ?? "-";
    };

    exit = (): void => {
        if (this.refreshHomeScreenOnExit && Platform.OS === "ios") {
            this.forceRefreshTabs();
            return;
        }

        module?.exit();
    };

    fetchCurrentLocation = (fn: (location?: ILocation | null) => void): void => {
        if (!module) {
            fn(null);
            return;
        }

        module.fetchCurrentLocation(fn);
    };

    openLogin = (fn: (headers?: SwiggyHeaders) => void): void => {
        module?.openLogin(fn);
    };

    launchDeeplink = (uri: string): void => {
        module?.launchDeeplink(uri);
    };

    hideSplashScreen = (): void => {
        module?.hideSplashScreen();
    };

    forceRefreshHomeScreen = (): void => {
        this.refreshHomeScreenOnExit = true;

        if (Platform.OS === "android") {
            module?.forceRefreshHomeScreen();
        }
    };

    forceRefreshTabs = (): void => {
        if (Platform.OS === "ios") {
            module?.forceRefreshTabs();
        }
    };

    stopInitialLoadTrace = (eventName: string): void => {
        //Triggers native method to record app load time
        if (Platform.OS === "android") {
            module?.stopInitialLoadTrace(eventName.toLowerCase());
        }

        /**
         * Uncomment this and other newly added calls for perf in
         * this file one the native issue for metrics not getting
         * recorded for release builds is fixed
         *
         * Also, make sure perf calls aren't hampering the UX
         * */
        if (Platform.OS === "ios") {
            module?.stopInitialLoadTrace(eventName, "-");
        }
    };

    startPerfMonitor = (eventName: string): void => {
        if (Platform.OS === "android") {
            module?.startPerfMonitor(eventName);
        }
        if (Platform.OS === "ios") {
            module?.startPerfMonitor(eventName, "-");
        }
    };

    stopPerfMonitor = (eventName: string): void => {
        if (Platform.OS === "android") {
            module?.stopPerfMonitor(eventName);
        }
        if (Platform.OS === "ios") {
            module?.stopPerfMonitor(eventName, "-");
        }
    };

    getAllUpiIntentAppsInstalledOnDevice = (): Promise<Array<Partial<IUPIAppsList>>> => {
        if (Platform.OS === "android" && module?.getAllUpiIntentAppsInstalledOnDevice) {
            return module.getAllUpiIntentAppsInstalledOnDevice();
        }

        return Promise.reject("Not able to get UPI apps from native");
    };

    launchIntent = (deeplinkUri: string, packageName: string): Promise<string> => {
        if (Platform.OS === "android" && module?.launchIntent) {
            return module.launchIntent(deeplinkUri, packageName);
        }

        return Promise.reject("Not able to open native intent module");
    };

    // eslint-disable-next-line max-params
    logNRCustomEvent = (
        tableName: string,
        eventName: string,
        screenName: string,
        value: string,
    ): void => {
        if (Platform.OS === "android" && module?.logNRCustomEvent) {
            return module.logNRCustomEvent("minis_generic_event", eventName, screenName, value);
        }
    };
}
