import React, { createContext, useEffect } from "react";

import { LocalCartManager } from "../includes/local-cart-manager";
import { useCartReducer } from "../reducers/cart-reducer";

type CartReducerType = ReturnType<typeof useCartReducer>;
type State = CartReducerType[0];
type Dispatch = CartReducerType[1];

export const CartStateContext = createContext<State>({ carts: {}, instructions: {} });

// eslint-disable-next-line no-void
export const CartDispatchContext = createContext<Dispatch>(() => void 0);

interface CartStateProviderProps {
    initialState?: State;
}

export const CartStateProvider: React.FC<React.PropsWithChildren<CartStateProviderProps>> = ({
    initialState,
    children,
}) => {
    const localCartInstance = LocalCartManager.getInstance();
    const [state, dispatch] = useCartReducer(() => {
        const localcart = initialState ?? localCartInstance.cartState;

        // reset the saved addresses in the local cart
        if (localcart.carts) {
            localcart.carts = Object.keys(localcart.carts).reduce((acc, key) => {
                const { addressId, ...cart } = localcart.carts[key];
                acc[key] = cart;
                return acc;
            }, {} as State["carts"]);
        }

        return localcart;
    });

    useEffect(() => {
        const cartState = { ...state };

        /** Instructions are not persisted, only available for session */
        delete cartState.instructions;

        localCartInstance.save(cartState);
        return () => localCartInstance.clear();
    }, [localCartInstance, state]);

    return (
        <CartStateContext.Provider value={state}>
            <CartDispatchContext.Provider value={dispatch}>{children}</CartDispatchContext.Provider>
        </CartStateContext.Provider>
    );
};
