import {
    AccountRouteList,
    HelpRouteList,
    HomeRouteList,
    MinisRouteList,
    PaymentProceedRouteList,
    PaymentRouteList,
    StoreRouteList,
    AddressRouteList,
    NavbarRouteList,
} from "@minis-consumer/interfaces/route";

export type RouteNames =
    | keyof AccountRouteList
    | keyof HelpRouteList
    | keyof MinisRouteList
    | keyof PaymentRouteList
    | keyof PaymentProceedRouteList
    | keyof StoreRouteList
    | keyof HomeRouteList
    | keyof AddressRouteList
    | keyof NavbarRouteList;

export const RouteToAnalyticsScreenMap: Record<RouteNames, string> = {
    // TODO: Update screen names or add screens based on your ARD events screen name
    // Common routes
    MinisMyAccount: "account-home",
    Account: "Account",
    Address: "Address",
    Auth: "Auth",
    ChatConversation: "seller-chat-window",
    RatingAndReview: "order-rating-review",
    Order: "order-details",
    SelectReturnItems: "return-order-select-items",
    SelectReturnReason: "return-order-select-reason",
    Tracking: "track-screen",
    Payment: "payment-page",
    NotFound: "not-found",
    MinisHome: "minis-home",
    RecentMinis: "minis-explored-page",
    Collection: "collection-page",
    ChatHome: "chat-home",
    MiniStore: "storefront-home",
    BulkFollowedStores: "BulkFollowedStores",
    Coupons: "all-coupons",
    Search: "minis-search",
    SlotSelection: "slot-selection",
    ProductForms: "form-screen",

    // Help routes
    HelpHome: "account-help-and-support",
    HelpDetailsLevelOne: "HelpDetailsLevelOne",
    HelpDetailsLevelTwo: "HelpDetailsLevelTwo",

    // Account routes
    ProfileSettings: "account-edit-profile",
    FAQ: "account-help-and-support-faqs",
    TermsAndConditions: "account-terms-and-conditions",
    FollowedStores: "minis-followed-page",
    Help: "Help",
    SavedAddresses: "account-saved-address",
    AbandonedCart: "saved-carts",

    // Payment routes
    PaymentHome: "payment-home",
    PaymentAddNewUpi: "payment-via-upi",
    PaymentAddNewCard: "payment-via-card",
    PaymentNetBanking: "payment-via-net-banking",
    PaymentProceed: "payment-proceed",
    PaymentTimer: "payment-via-upi-timer",
    PaymentVerify: "payment-verify",
    PaymentSuccess: "payment-successful-order-placed",

    // Store routes
    Home: "storefront-home",
    Product: "storefront-product-details",
    StoreCatalogSearch: "store-search",
    UpdateAddress: "update-address",

    // Home routes
    Shop: "storefront-home",
    About: "storefront-overview",
    Cart: "cart-home",
    MyAccount: "account-home",

    //Address routes
    SearchLocation: "enter-address-location",
    Map: "address-map-view",
    AddressForm: "enter-address-details",

    // guest checkout
    GuestCheckout: "guest-checkout",

    //NavBar routes
    Carts: "saved-carts",
    Explore: "minis-home",
    Chat: "chat-home",
    Swiggy: "swiggy",
    Activity: "activity-page",
};
