import { DISPLAY_STRINGS } from "./constants";

interface Params {
    isBlocked: boolean;
    personName: string;
}

interface ModalStrings {
    title: string;
    description: string;
    ctaText: string;
}

export const getModalStrings = ({ isBlocked, personName }: Params): ModalStrings => {
    return {
        title: `${isBlocked ? DISPLAY_STRINGS.UNBLOCK : DISPLAY_STRINGS.BLOCK} ${personName}?`,
        description: isBlocked
            ? `This will give ${personName} access to sending you further messages on chat`
            : `This will stop ${personName} from sending you further messages on chat`,
        ctaText: isBlocked ? DISPLAY_STRINGS.UNBLOCK : DISPLAY_STRINGS.BLOCK,
    };
};
