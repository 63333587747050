import * as React from "react";
import { useSuspense } from "@rest-hooks/react";

import { StoreLinks } from "@minis-consumer/resources/store";
import { FetchStoreLinksResponse } from "@minis-consumer/api/store";

import { useStoreInfo } from "./use-store";

type StoreLinksResponseType = {
    data?: FetchStoreLinksResponse;
};

export const useFetchStoreLinks = (): StoreLinksResponseType => {
    const { storeId } = useStoreInfo();
    const storeLinksData = useSuspense(StoreLinks, { storeId });

    return React.useMemo(
        () => ({
            data: storeLinksData,
        }),
        [storeLinksData],
    );
};
