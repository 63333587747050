import React, { FC, memo, useCallback, useMemo } from "react";
import { StyleSheet } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { InView } from "@swiggy-private/react-native-ui";
import { SdkConversation } from "@swiggy-private/connect-chat-sdk";
import { ICouponWithTextMessage } from "@swiggy-private/connect-chat-commons";
import { CouponWithTextMessage as CouponWithTextMessageComponent } from "@swiggy-private/connect-business-commons";
import { SpacingValue } from "@swiggy-private/rn-dls";

import { Analytics } from "@minis-consumer/analytics";
import { useCopyToClipboard } from "@minis-consumer/hooks/use-copy";
import { useIsDesktopWeb } from "@minis-consumer/hooks/use-dweb";

import { ANALYTICS_COPIES, COPIES, DEFAULT_VALUES } from "../../constants";

interface ViewStoryMessageProps {
    message: ICouponWithTextMessage;
    conversationId: SdkConversation["id"];

    conversationMeta?: SdkConversation["meta"];
}

export const CouponWithTextMsg: FC<ViewStoryMessageProps> = ({ message, conversationId }) => {
    const { payload: data, type } = message;
    const copyLink = useCopyToClipboard();
    const inset = useSafeAreaInsets();
    const isDWeb = useIsDesktopWeb();

    const analyticsContextData = useMemo(
        () =>
            JSON.stringify({
                storeId: data.storeId,
                messageType: type,
                conversationId,
                couponData: data.couponData,
            }),
        [conversationId, data.couponData, data.storeId, type],
    );

    const toastStyle = useMemo(
        () =>
            !isDWeb
                ? [
                      styles.toast,
                      {
                          bottom: inset.bottom + DEFAULT_VALUES.FOOTER_SIZE,
                      },
                  ]
                : undefined,
        [inset.bottom, isDWeb],
    );

    const onPress = useCallback(() => {
        copyLink({
            message: data.couponData.code,
            toastProps: {
                style: toastStyle,
            },
            toastDuration: 2_500,
            toastText: COPIES.COPY_COUPON,
        });

        Analytics.clickEvent({
            category: ANALYTICS_COPIES.COPY_COUPON,
            context: analyticsContextData,
        });
    }, [analyticsContextData, copyLink, data.couponData.code, toastStyle]);

    const onMsgImpression = useCallback(
        (isVisible: boolean) => {
            isVisible &&
                Analytics.impressionEvent({
                    category: ANALYTICS_COPIES.CHAT_MSGS,
                    context: analyticsContextData,
                });
        },
        [analyticsContextData],
    );

    return (
        <InView onChange={onMsgImpression}>
            <CouponWithTextMessageComponent onPress={onPress} message={message} />
        </InView>
    );
};

export const CouponWithTextMessage = memo(CouponWithTextMsg);

const styles = StyleSheet.create({
    toast: {
        width: "90%",
        height: 44,
        borderRadius: 16,
        left: "5%",
        right: "5%",
        paddingHorizontal: SpacingValue["space-medium"],
        paddingBottom: SpacingValue["space-small"],
    },
});
