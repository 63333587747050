import * as React from "react";

export interface IOnboardingStoriesContext {
    isOpen: boolean;
    close: () => void;
    open: () => void;
}

const OnboardingStoriesContext = React.createContext<IOnboardingStoriesContext>({
    isOpen: false,
    close: () => undefined,
    open: () => undefined,
});

export const OnboardingStoriesContextProvider: React.FC<React.PropsWithChildren> = ({
    children,
}) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const close = React.useCallback(() => setIsOpen(false), []);
    const open = React.useCallback(() => setIsOpen(true), []);

    const value = React.useMemo(() => ({ isOpen, close, open }), [isOpen, close, open]);

    return (
        <OnboardingStoriesContext.Provider value={value}>
            {children}
        </OnboardingStoriesContext.Provider>
    );
};

export const useOnboardingStoriesContext = (): IOnboardingStoriesContext =>
    React.useContext(OnboardingStoriesContext);
