import React from "react";
import { Animated, InteractionManager, Platform, StyleSheet } from "react-native";

import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { useAnimatedValue } from "@swiggy-private/react-native-ui";
import { useMount } from "@swiggy-private/react-hooks";
import { SpacingValue } from "@swiggy-private/rn-dls";

type AnimatedAccessoryProps = {
    durationInMs: number;

    delayInMs?: number;
};

const AnimatedAccessoryComponent: React.FC<AnimatedAccessoryProps> = ({
    durationInMs,
    delayInMs = 0,
}) => {
    const animatedValueMagnifier = useAnimatedValue(0);
    const animatedValueBack = useAnimatedValue(0);
    const isNative = Platform.OS !== "web";

    const magnifierStyle = React.useMemo(
        () => ({
            opacity: animatedValueMagnifier.interpolate({
                inputRange: [0, 0.25, 0.5, 1],
                outputRange: [1, 0.1, 0, 0],
            }),
            transform: [
                {
                    translateX: animatedValueMagnifier.interpolate({
                        inputRange: [0, 0.5, 1],
                        outputRange: [0, -9, -9],
                    }),
                },
            ],
        }),
        [animatedValueMagnifier],
    );

    const backBtnStyle = React.useMemo(
        () => ({
            opacity: animatedValueBack.interpolate({
                inputRange: [0, 0.5, 1],
                outputRange: [0, 0.5, 1],
            }),
            transform: [
                {
                    translateX: animatedValueBack.interpolate({
                        inputRange: [0, 0.5, 1],
                        outputRange: [0, -10, -20],
                    }),
                },
            ],
        }),
        [animatedValueBack],
    );

    const animation = React.useCallback(() => {
        return Animated.parallel([
            Animated.timing(animatedValueMagnifier, {
                toValue: 1,
                duration: durationInMs,
                delay: delayInMs,
                useNativeDriver: isNative,
            }),
            Animated.timing(animatedValueBack, {
                toValue: 1,
                duration: durationInMs,
                delay: delayInMs,
                useNativeDriver: isNative,
            }),
        ]);
    }, [animatedValueBack, animatedValueMagnifier, delayInMs, durationInMs, isNative]);

    useMount(() => {
        const task = InteractionManager.runAfterInteractions(() => {
            animation().start();
        });

        return () => {
            task.cancel();
        };
    });

    return (
        <Animated.View style={styles.rootContainer}>
            <Animated.View style={magnifierStyle}>
                <SvgIcon icon="Magnifier" color="color-basic-60" height={18} width={18} />
            </Animated.View>
            <Animated.View style={backBtnStyle}>
                <SvgIcon icon="ChevronLeft" color="color-basic-60" height={22} width={22} />
            </Animated.View>
        </Animated.View>
    );
};

export const AnimatedAccessory = React.memo(AnimatedAccessoryComponent);

const styles = StyleSheet.create({
    rootContainer: {
        flexDirection: "row",
        alignItems: "center",
        width: 18,
        marginRight: SpacingValue["space-x-small"],
    },
});
