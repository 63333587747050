import { Entity } from "@rest-hooks/rest";
import { Endpoint } from "@rest-hooks/endpoint";

import { getStoreInstagramMediaApi } from "@minis-consumer/api/instagram";
import {
    Store,
    StoreAddress,
    StoreDeliveryConfig,
    StoreSettings,
    IHightlight,
} from "@minis-consumer/interfaces/store";

import { fetchStoreInfo, fetchFollowedStores, followStatus, fetchStoreLinks } from "../api/store";

export class StoreEntity extends Entity implements Store {
    storeId = "0";
    name = "";
    slug = "";
    enabled = false;
    settings: StoreSettings = {};
    verified = false;
    followersCount?: number;
    categories?: string[];
    interDeliveryConfig?: StoreDeliveryConfig;
    intraDeliveryConfig?: StoreDeliveryConfig;
    address?: StoreAddress;
    facebookAdsUserDetails?:
        | {
              accessToken: string;
              adsAccountId: string;
              metaPixelId: string;
              enableEventCapture: boolean;
          }
        | undefined;
    highlights: IHightlight[] = [];
    storeVersion?: string;

    pk(): string {
        return this.storeId;
    }

    static override get key(): string {
        return "Store";
    }

    static override indexes = ["slug" as const];
}

export const StoreDetail = new Endpoint(fetchStoreInfo, {
    schema: StoreEntity,
});

export const FollowedStores = new Endpoint(fetchFollowedStores, {
    key: () => "followed-stores",
});

export const StoreFollowStatus = new Endpoint(followStatus, {
    key: ({ storeId }) => "store-follow-status/" + storeId,
});

export const StoreInstagram = new Endpoint(getStoreInstagramMediaApi, {
    key: ({ storeId }) => "store-instagram/" + storeId,
});

export const StoreLinks = new Endpoint(fetchStoreLinks, {
    key: ({ storeId }) => "store-links/" + storeId,
});
