export const COUPON_ERROR_SNACKBAR_CONSTANTS = {
    ERROR_FALLBACK_MSSG: "Apologies. This coupon is no longer valid.",
    CTA_TEXT_FALLBACK: "OKAY",
    MAX_SNACKBAR_WIDTH: 740,
};

export const ANALYTICS_COPIES: { [key: string]: string } = {
    TOAST_CATEGORY: "checkout-error-toast",
    TOAST_CTA_CATEGORY: "checkout-error-toast-close",
};
