import * as React from "react";

import { SwiggyHeaders } from "@minis-consumer/interfaces/swiggy";
import { ConfigInfo } from "@minis-consumer/interfaces/config-info";
import { getConfig } from "@minis-consumer/config";

interface IContext {
    doesSessionExist: boolean;
    setConfigInfo: (configInfo: ConfigInfo) => void;

    config?: ConfigInfo;
}

const initialState = {
    doesSessionExist: false,
    setConfigInfo: () => 0,
};
export const SessionContext = React.createContext<IContext>(initialState);

export interface ISessionContextProps {
    headers?: (SwiggyHeaders & { config?: ConfigInfo }) | null;
}

export const SessionContextProvider: React.FC<React.PropsWithChildren<ISessionContextProps>> = (
    props,
) => {
    const { headers, children } = props;
    let initalConfigState = headers?.config ?? null;
    const isSSR = getConfig("isSSR", "false") === "true";
    if (isSSR) {
        try {
            initalConfigState = getConfig("config", "{}") as ConfigInfo;
        } catch (e) {}
    }

    const [config, setConfig] = React.useState<ConfigInfo | null>(initalConfigState);

    //for SSR
    let doesSessionExist = !!headers?.userId && headers?.userId !== "0";

    //for client hydration
    if (typeof window !== "undefined" && !headers) {
        const clientHeaders = getConfig("headers", "") as SwiggyHeaders;
        doesSessionExist = !!clientHeaders?.userId && clientHeaders?.userId !== "0";
    }

    const setConfigInfo = (configInfo: ConfigInfo): void => {
        setConfig(configInfo);
    };

    return (
        <SessionContext.Provider
            value={{
                doesSessionExist: doesSessionExist,
                config: config ?? undefined,
                setConfigInfo,
            }}>
            {children}
        </SessionContext.Provider>
    );
};
