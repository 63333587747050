import * as React from "react";
import { StyleSheet, ViewStyle } from "react-native";

import { DialogContent, Dialog, SpacingValue } from "@swiggy-private/rn-dls";
import { useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { useMountedRef } from "@swiggy-private/react-hooks";

import { DialogBody } from "./components/dialog-body";

type CelebrationModalDialog = {
    showDialog: boolean;
    closeDialog: () => void;
    storeId: string;
};

const CelebrationModalDialogComponent: React.FC<CelebrationModalDialog> = ({
    showDialog,
    closeDialog,
    storeId,
}) => {
    const mountedRef = useMountedRef();

    const modalMaxWidth = useSelectScreen({
        lg: 310,
        default: 270,
    }) as ViewStyle["width"];

    React.useEffect(() => {
        if (!showDialog) {
            return;
        }

        if (!mountedRef.current) {
            return;
        }

        const timer = setTimeout(() => closeDialog(), 6_000);

        return () => {
            clearTimeout(timer);
        };
    }, [showDialog, mountedRef, closeDialog]);

    if (!showDialog) {
        return null;
    }

    const modalStyles: ViewStyle = {
        borderRadius: 16,
        width: modalMaxWidth,
    };

    return (
        <Dialog
            disableBackdropClose={false}
            open={showDialog}
            onClose={closeDialog}
            style={modalStyles}>
            <DialogContent style={styles.content}>
                <DialogBody storeId={storeId} closeDialog={closeDialog} />
            </DialogContent>
        </Dialog>
    );
};

const styles = StyleSheet.create({
    content: {
        paddingHorizontal: SpacingValue["space-x-large"],
        paddingVertical: SpacingValue["space-x-large"],
    },
});

export const CelebrationModalDialog = React.memo(CelebrationModalDialogComponent);
