import { REGEX } from "@minis-consumer/constants/regex";

/**
 * Checks if the email id is valid or not.
 *
 * @param text email id string
 * @returns true is email id is valid otherwise false
 */
export const isValidEmail = (text: string): boolean => {
    return new RegExp(REGEX.EMAILID).test(text);
};
