export const SHARE_TEXT =
    "Check out this amazing highlight, {0}, from {1}! You won't want to miss it: {2} 🎉";

export const CHAT_TEXT = "Message received! We'll respond soon";
export const CHAT_MSG = "Have questions? Chat with us";

export const COVER_COPIES = {
    LOVE: "Love it? Share the joy!",
    COPY: "Copy link",
    CHAT: "Chat with us",
    QUESTIONS: "Have questions?",
};
