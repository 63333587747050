import { SwiggyHeaders } from "@minis-consumer/interfaces/swiggy";
import { nanoid } from "nanoid/non-secure";
import { CookieStorage } from "../helpers/cookie-storage";

export class Device {
    private static _instance: Device | null = null;

    private _deviceId: string;
    private _appVersion: string | null = null;
    private _osVersion: string | null = null;
    private _versionCode: string | null = null;

    private static _storageKey = "@minis_deviceInfo";
    private _timeout = 0;

    private constructor() {
        this._deviceId = nanoid();
    }

    get deviceId(): string {
        return this._deviceId;
    }

    set deviceId(deviceId: string) {
        this._deviceId = deviceId;
    }

    get appVersion(): string | null {
        return this._appVersion;
    }

    set appVersion(appVersion: string | null) {
        this._appVersion = appVersion;
    }

    get osVersion(): string | null {
        return this._osVersion;
    }

    set osVersion(osVersion: string | null) {
        this._osVersion = osVersion;
    }

    get versionCode(): string | null {
        return this._versionCode;
    }

    set versionCode(versionCode: string | null) {
        this._versionCode = versionCode;
    }

    static async load(headers?: SwiggyHeaders | null): Promise<Device> {
        if (headers != null && Object.keys(headers).length > 0) {
            return Device.loadFromHeaders(headers);
        }

        return Device.loadFromStorage();
    }

    public static async loadFromStorage(): Promise<Device> {
        const device = Device.getInstance();
        const deviceDetails = await CookieStorage.getObjectValue<Device>(Device._storageKey);

        if (deviceDetails) {
            device._deviceId = deviceDetails.deviceId;
        }

        return device;
    }

    public static loadFromHeaders(headers?: SwiggyHeaders | null): Device {
        const device = Device.getInstance();

        if (headers) {
            device._deviceId = headers.deviceId ?? headers.swuid ?? device._deviceId;
            device._appVersion = headers.appVersion ?? null;
            device._versionCode = headers.versionCode ?? headers["version-code"] ?? null;
            device._osVersion = headers.osVersion ?? null;
        }

        return device;
    }

    static getInstance(): Device {
        if (Device._instance == null) {
            Device._instance = new Device();
            return Device._instance;
        }

        return Device._instance;
    }

    public save(): void {
        clearTimeout(this._timeout);
        this._timeout = setTimeout(async () => {
            const deviceDetails = {
                deviceId: this._deviceId,
            };

            await CookieStorage.setValue(Device._storageKey, deviceDetails);
        }, 0) as unknown as number;
    }
}
