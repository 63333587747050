export const TEST_IDS_SF = {
    COVER_IMAGE: "cover-image",
    SEARCH_CTA: "search-cta",
    MY_ACCOUNT: "my-account-cta",
    CHAT_CTA: "chat-cta",
    SHARE_CTA: "share-cta",
    FOLLOWED: "followed",
    FOLLOW_CTA: "follow-cta",
    BACK: "sf-back",
    SHOP_OFFLINE: "shop-offline",
    DELIVERY_INFO_VIEW: "delivery-info-view",
    DELIVERY_DETAILS_VIEW: "delivery-details-view",
    ORDER_AND_DELIVERY: "order-and-delivery",
    ORDER_AND_DELIVERY_ICON: "order-and-delivery-icon",
    RETURN_POLICY: "return-policy",
    RETURN_POLICY_ICON: "return-policy-icon",
    CANCELLATION_POLICY: "cancellation-policy",
    CANCELLATION_POLICY_ICON: "cancellation-policy-icon",
    STORE_INFO: "store-info",
    STORE_INFO_ICON: "store-info-icon",
    DELIVERY_INFO_SNACKBAR: "delivery-info-toast",
    INSTAGRAM_WIDGET: "instagram-widget",
    BADGE_PRODUCT: "badge-products", // recommended
    CATALOG_FULL_VIEW: "catalog-full-view",
    MY_STORE_TAB: "my-store-tab",
    MY_LINKS_TAB: "my-links-tab",
    MY_LINK_PREFIX: "my-link",
    SOCIAL_METRIC: "social-metric",
    PERF_METRICS: {
        RATINGS: "perf-metric-ratings",
        ORDERS: "perf-metric-orders",
        FOLLOWERS: "perf-metric-followers",
    },
};
