import { getMultiple, getObjectValue, setValue } from "@swiggy-private/common-helpers";
import {
    ChatServiceFindConversationsParams,
    ChatServiceFindMessagesParams,
    ChatServiceMessage,
    ChatServiceSaveMessageParams,
    ChatServiceUpdateMessageActionParams,
    IChatService,
    SdkConversation,
} from "@swiggy-private/connect-chat-sdk";

const KEY_STORAGE_CONVERSATIONS = "@@minis/chat_conversations";

export class LocalChatService implements IChatService {
    private userId: string;
    private conversationCache: SdkConversation[] | null = null;

    public constructor(userId: string) {
        this.userId = userId;
    }

    private getStorageKey(): string {
        return `${KEY_STORAGE_CONVERSATIONS}_${this.userId}`;
    }

    async findConversationById(id: string): Promise<SdkConversation | null> {
        const conversations = await this.getAllConversations();
        return conversations.find((c) => c.id === id) || null;
    }

    async findConversations(
        _params?: ChatServiceFindConversationsParams | undefined,
    ): Promise<SdkConversation[]> {
        return this.getAllConversations();
    }

    async updateConversation(conversation: SdkConversation): Promise<void> {
        let conversations = await this.getAllConversations();

        const existingConversation = conversations.find((c) => c.id === conversation.id);
        if (existingConversation) {
            conversations = conversations.filter((c) => c.id !== conversation.id);
        }

        this.conversationCache = [conversation, ...conversations].sort(
            (a, b) => (b.lastMessageTimestamp || 0) - (a.lastMessageTimestamp || 0),
        );

        return setValue(this.getStorageKey(), this.conversationCache.slice(0, 10));
    }

    async putConversation(conversation: SdkConversation): Promise<void> {
        const conversations = (await this.getAllConversations()).filter(
            (c) => c.id !== conversation.id,
        );

        this.conversationCache = [conversation, ...conversations].sort(
            (a, b) => (b.lastMessageTimestamp || 0) - (a.lastMessageTimestamp || 0),
        );

        return setValue(this.getStorageKey(), this.conversationCache.slice(0, 10));
    }

    async updateMessage(_params: ChatServiceSaveMessageParams): Promise<void> {
        // not implemented
    }

    async putMessage(_params: ChatServiceSaveMessageParams): Promise<void> {
        // not implemented
    }

    async findMessages(_params: ChatServiceFindMessagesParams): Promise<ChatServiceMessage[]> {
        return [];
    }

    async findMessageById(_id: string): Promise<ChatServiceMessage | null> {
        return null;
    }

    async findMessageByConversationIdAndTimetoken(
        _conversationId: string,
        _timetoken: string,
    ): Promise<ChatServiceMessage | null> {
        return null;
    }

    async updateMessageReadState(_id: string, _unread: boolean): Promise<void> {
        // not implemented
    }

    async updateMessageAction(_params: ChatServiceUpdateMessageActionParams): Promise<void> {
        // not implemented
    }

    async getUnreadMessageCount(_conversationId?: string | undefined): Promise<number> {
        return 0;
    }

    async getUnreadConversationCount(): Promise<number> {
        return 0;
    }

    async saveConversationLastReadTime(
        conversationId: string,
        time?: number | undefined,
    ): Promise<void> {
        return setValue("cx_last_read_" + conversationId, time || Date.now());
    }

    async getConversationLastReadTime(
        conversationId: string | string[],
    ): Promise<Record<string, number | null>> {
        const conversationIds = Array.isArray(conversationId) ? conversationId : [conversationId];
        const result = await getMultiple(conversationIds.map((c) => "cx_last_read_" + c));

        return conversationIds.reduce((acc, cur) => {
            acc[cur] = Number(result["cx_last_read_" + cur]) || null;
            return acc;
        }, {} as Record<string, number | null>);
    }

    private async getAllConversations(): Promise<SdkConversation[]> {
        if (this.conversationCache != null) {
            return this.conversationCache;
        }

        this.conversationCache = (await getObjectValue(this.getStorageKey())) || [];

        return this.conversationCache;
    }
}
