import { ApiError, API_SUCCESS_STATUS_CODE } from "@swiggy-private/connect-api-core";
import { Endpoint } from "@swiggy-private/http-client";
import { GenerateMediaUrlParams } from "@swiggy-private/rn-image-picker";

import { AppConfig } from "@minis-consumer/config";
import { getApiClient } from "@minis-consumer/includes/api";

const MEDIA_BASE_PATH = "/api/v1/media";

interface GenerateMediaUploadUrlResponse {
    url: string;
    headers: Record<string, string[]>;
    mediaId: string;
}

interface DownloadAssetData {
    mediaId: string;
    source: "PRIVATE_ASSET" | "PUBLIC_ASSET";
}

export const generateMediaUploadUrlApi = async (
    data: GenerateMediaUrlParams,
): Promise<GenerateMediaUploadUrlResponse> => {
    const endpoint = Endpoint.from(
        `${AppConfig.SELLER_SERVICE_HOST}${MEDIA_BASE_PATH}/generate-signed-url`,
    );

    const response = await getApiClient().post<GenerateMediaUploadUrlResponse>({
        endpoint,
        body: { ...data },
    });

    if (response.statusCode === API_SUCCESS_STATUS_CODE && response.data) {
        return response.data;
    }

    return Promise.reject(
        new ApiError(response.statusMessage || "Could not generate url for upload", response),
    );
};

export const downloadAssetApi = async (
    data: DownloadAssetData,
): Promise<GenerateMediaUploadUrlResponse> => {
    const endpoint = Endpoint.from(`${AppConfig.SELLER_SERVICE_HOST}${MEDIA_BASE_PATH}/signed-url`);

    const response = await getApiClient().get<GenerateMediaUploadUrlResponse>({
        endpoint,
        queryParams: { ...data },
    });

    if (response.statusCode === API_SUCCESS_STATUS_CODE && response.data) {
        return response.data;
    }

    return Promise.reject(
        new ApiError(response.statusMessage || "Could not generate url for download", response),
    );
};
