import { Dimensions } from "react-native";

import { IHightlight } from "@minis-consumer/interfaces/store";
import { getMediaUrl } from "@minis-consumer/helpers/media";

import { IUserStory } from "@swiggy-private/react-native-stories";

const { width, height } = Dimensions.get("window");

const STORY_IMG_SIZE = 72;

export const transformStories = (highlights: IHightlight[]): IUserStory[] => {
    const stories = highlights.map((highlight) => {
        return {
            user_id: highlight.id,
            user_name: highlight.title,
            user_image: getMediaUrl(
                highlight.coverImage,
                { width: STORY_IMG_SIZE, height: STORY_IMG_SIZE },
                true,
            ),
            stories: highlight.stories
                .filter((story) => story.media.type === "IMAGE")
                .map((story) => {
                    const link = getMediaUrl(story.media.mediaId, { width, height }, true);
                    return {
                        story_id: story.id,
                        story_image: link,
                    };
                }),
        };
    });

    return stories;
};

export const getSelectedStoryIndex = (
    stories: IUserStory[],
    storyId?: string,
): { highlightIndex: number; storyIndex: number } => {
    let highlightIndex = 0;
    let storyIndex = 0;
    if (!storyId) {
        return { highlightIndex, storyIndex };
    }
    stories.forEach((story, index) => {
        const matchedStoryIndex = story.stories.findIndex(
            (storyItem) => storyItem.story_id === storyId,
        );
        if (matchedStoryIndex >= 0) {
            highlightIndex = index;
            storyIndex = matchedStoryIndex;
        }
    });
    return { highlightIndex, storyIndex };
};

export const timeAgo = (epochTimestamp: number): string => {
    const now = new Date().getTime();
    const secondsAgo = Math.floor((now - epochTimestamp) / 1000);

    const minutes = Math.floor(secondsAgo / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);

    if (secondsAgo < 60) {
        return `${secondsAgo} sec${secondsAgo === 1 ? "" : "s"} ago`;
    } else if (minutes < 60) {
        return `${minutes} min${minutes === 1 ? "" : "s"} ago`;
    } else if (hours < 24) {
        return `${hours} hour${hours === 1 ? "" : "s"} ago`;
    } else if (days < 7) {
        return `${days} day${days === 1 ? "" : "s"} ago`;
    } else if (months < 1) {
        return `${weeks} week${weeks === 1 ? "" : "s"} ago`;
    } else {
        return `${months} month${months === 1 ? "" : "s"} ago`;
    }
};
