import React, { useMemo } from "react";
import {
    KeyboardAvoidingView,
    KeyboardAvoidingViewProps,
    Platform,
    StyleSheet,
} from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

export interface KeyboardAwareViewProps extends KeyboardAvoidingViewProps {
    iosOffset?: number;
    androidOffset?: number;
    applyHeaderOffset?: boolean;
    behavior?: KeyboardAvoidingViewProps["behavior"];
}

const iOS = Platform.OS === "ios";

export const KeyboardAwareView: React.FC<KeyboardAwareViewProps> = ({
    children,
    style,
    iosOffset = 0,
    androidOffset = 0,
    applyHeaderOffset = true,
    ...props
}) => {
    const insets = useSafeAreaInsets();
    const headerHeight = insets.top;
    const offset = useMemo(
        () => (iOS ? iosOffset : androidOffset) + (applyHeaderOffset ? headerHeight : 0),
        [androidOffset, applyHeaderOffset, headerHeight, iosOffset],
    );

    return (
        <KeyboardAvoidingView
            keyboardVerticalOffset={offset}
            behavior={iOS ? "padding" : "height"}
            style={StyleSheet.compose(styles.keyboardView, style)}
            {...props}>
            {children}
        </KeyboardAvoidingView>
    );
};

const styles = StyleSheet.create({
    keyboardView: {
        flex: 1,
    },
});
