import { APP_ENV } from "@env";

import Blaze from "@swiggy-private/blaze.js";

import { AppConfig } from "@minis-consumer/config";
import { Session } from "@minis-consumer/includes/session";
import { Logger } from "@minis-consumer/includes/logger";
import { getUserAgentAndApp } from "@minis-consumer/helpers/user-agent-app-name";

const sessionInfo = Session.getInstance();

export const BLAZE_EVENTS = {
    LOAD_TIME: {
        APP: "minis_loadtime_app",
        STORE_FRONT: "minis_loadtime_storefront",
        STORE_CARD: "minis_store_card_load_time",
        STORE_FRONT_SSR: "minis_storefront_ssr_loadtime",
    },
};

const blazeJsData = {
    isInitialized: false,
    headerData: {
        sid: sessionInfo.sid ?? "",
        tid: sessionInfo.tid ?? "",
        userAgent: String(getUserAgentAndApp()),
        userid: String(sessionInfo.userId),
        environment: APP_ENV,
    },
};

export const initializeBlaze = (recordAppLoadStartTime?: boolean): void => {
    try {
        if (__DEV__) {
            return;
        }
        const newrelicAPIEndpoint: string = AppConfig.NEW_RELIC_INSIGHTS_ENDPOINT;
        const newrelicAPIkey: string = AppConfig.NEW_RELIC_API_KEY;

        if (!newrelicAPIkey || !newrelicAPIEndpoint) {
            return;
        }

        Blaze.init({
            sendToAnalytics: (): void => {
                void 0;
            },
            enableElementTiming: false,
            enableResourceTiming: true,
            nrConfig: {
                enable: true,
                config: {
                    NR_ENDPOINT: newrelicAPIEndpoint,
                    NR_KEY: newrelicAPIkey,
                },
                meta: {
                    appName: "minis-consumer-web",
                    ...blazeJsData.headerData,
                },
            },
            resourceTimingConfig: {
                allowedUrls: [
                    new RegExp(/.*\.mini\.store$|\?storeSlug=.*/), // allow resource time metrcis for IM home page only
                ],
            },
        });

        blazeJsData.isInitialized = true;
        if (recordAppLoadStartTime) {
            startCustomEvent(BLAZE_EVENTS.LOAD_TIME.APP);
        }
    } catch (e) {
        Logger.recordError(e);
    }
};

export const recordAppLoadTime = (event: string): void => {
    try {
        if (!blazeJsData.isInitialized) {
            return;
        }
        Blaze.recordTimeFromAppStart(event);
    } catch (e) {
        Logger.recordError(e);
    }
};

export const startCustomEvent = (event: string): void => {
    try {
        if (!blazeJsData.isInitialized) {
            return;
        }
        Blaze.recordStart(event);
    } catch (e) {
        Logger.recordError(e);
    }
};

export const endCustomEvent = (event: string): void => {
    try {
        if (!blazeJsData.isInitialized) {
            return;
        }
        Blaze.recordEnd(event);
    } catch (e) {
        Logger.recordError(e);
    }
};
