import format from "date-fns/format";
import getDate from "date-fns/getDate";
import getMonth from "date-fns/getMonth";
import getYear from "date-fns/getYear";
import getHours from "date-fns/getHours";
import getMinutes from "date-fns/getMinutes";
import getSeconds from "date-fns/getSeconds";
import addSeconds from "date-fns/addSeconds";

// To convert the date to MMM DD, hh:mm a format
export const convertToReadableDateAndTime = (
    dateInEpoch: number,
    dateFormat = "MMM d, hh:mm a",
): string => {
    try {
        const localDate = new Date(dateInEpoch * 1000);
        const readableDate = format(localDate, dateFormat);
        return readableDate;
    } catch (err) {
        return "";
    }
};

// TODO: Remove/Refactor this function if dates are always in IST as a FF
export const convertToReadableDateAndTimeIST = (
    dateInEpoch: number,
    dateFormat = "MMM d, hh:mm a",
): string => {
    try {
        const timezoneOffset = new Date().getTimezoneOffset() * 60;
        const istOffsetSeconds = -5.5 * 3600;
        const utcDate = new Date((dateInEpoch + timezoneOffset) * 1000);
        const istDate = addSeconds(utcDate, -istOffsetSeconds);
        const readableDate = format(istDate, dateFormat);

        return readableDate;
    } catch (err) {
        return "";
    }
};

type TimeUnit = "year" | "month" | "week" | "day" | "hour" | "minute";

const timeUnits: Record<TimeUnit, number> = {
    year: 31536000,
    month: 2592000,
    week: 604800,
    day: 86400,
    hour: 3600,
    minute: 60,
};

export const getTimeElapsed = (epochTime: number): string => {
    if (!epochTime || typeof epochTime !== "number") {
        return "";
    }

    const seconds = Math.floor(Date.now() / 1000 - epochTime);

    for (const unit in timeUnits) {
        const value = Math.floor(seconds / timeUnits[unit as TimeUnit]);

        if (value >= 1) {
            return value + ` ${unit}${value > 1 ? "s" : ""} ago`;
        }
    }

    return "just now";
};

export const getSlotFromDateAndTime = (epochDate: number, epochTime: number): number => {
    if (
        !epochDate ||
        typeof epochDate !== "number" ||
        !epochTime ||
        typeof epochTime !== "number"
    ) {
        return 0;
    }

    const day = getDate(epochDate);
    const month = getMonth(epochDate);
    const year = getYear(epochDate);

    const hours = getHours(epochTime);
    const minutes = getMinutes(epochTime);
    const seconds = getSeconds(epochTime);

    const finalDate = new Date(year, month, day, hours, minutes, seconds).getTime() / 1000;

    return finalDate;
};
