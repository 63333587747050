import React from "react";
import { Platform, Pressable, StyleSheet, ViewStyle } from "react-native";

import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { Box, Display, ScreenSize, Stack } from "@swiggy-private/rn-adaptive-layout";
import { Modal, SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { useMount } from "@swiggy-private/react-hooks";

import { Analytics } from "@minis-consumer/analytics";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";

import { KeyboardAwareBottomModal } from "../keyboard-aware-bottom-modal";
import { ANALYTICS_COPIES, PINCODE_FORM_CONSTS } from "./constants";
import { FormBody } from "./components/form-body";

type PincodeFormComponentProps = {
    canOpen: boolean;
    setOpen: (b: boolean) => void;
    handlePincodeSubmit: (s: string) => void;
};

const LargeScreenHeader: React.FC = () => {
    const { value: theme } = useTheme();

    const iconContainerStyles = React.useMemo<ViewStyle>(() => {
        return {
            backgroundColor: theme["color-basic-5"],
        };
    }, [theme]);

    return (
        <Stack
            spacing={SpacingValue["space-medium"]}
            direction="row"
            alignItems="center"
            style={styles.iconWrapper}>
            <Box style={[styles.iconContainer, iconContainerStyles]}>
                <SvgIcon icon="Location" color="color-basic-60" />
            </Box>

            <Stack>
                <Text category="h4" weight="bold" color="color-basic-100">
                    {PINCODE_FORM_CONSTS.HEADER_TTTLE}
                </Text>

                <Text category="b2" color="color-basic-60">
                    {PINCODE_FORM_CONSTS.HEADER_DESCRIPTION}
                </Text>
            </Stack>
        </Stack>
    );
};

const PincodeFormComponent: React.FC<PincodeFormComponentProps> = ({
    canOpen,
    setOpen,
    handlePincodeSubmit,
}) => {
    const { storeId } = useStoreInfo();
    const { value: theme } = useTheme();
    const isWeb = Platform.OS === "web";

    const titleContainerStyle = {
        backgroundColor: theme["color-basic-5"],
    };

    useMount(() => {
        Analytics.impressionEvent({
            category: ANALYTICS_COPIES.ENTER_PINCODE,
            context: JSON.stringify({
                store_id: storeId,
            }),
        });
    });

    return (
        <>
            <Display gt={ScreenSize.Large}>
                <Modal
                    showModal={canOpen}
                    setShowModal={setOpen}
                    dismissOnPressingBackdrop
                    modalStyles={{
                        modalBodyStyle: styles.centralModalStyles,
                    }}
                    component={
                        <>
                            <LargeScreenHeader />
                            <FormBody handlePincodeSubmit={handlePincodeSubmit} />
                        </>
                    }
                />
            </Display>
            {/* TODO: Add logic to compute the offset value */}
            <Display lt={ScreenSize.Medium}>
                <KeyboardAwareBottomModal
                    keyboardAwareProps={{
                        iosOffset: -100,
                        androidOffset: -100,
                    }}
                    component={
                        <Stack>
                            <Stack direction="column" style={styles.container}>
                                <Stack direction="row" style={[titleContainerStyle, styles.title]}>
                                    <Text category="h5" weight="bold">
                                        {PINCODE_FORM_CONSTS.SMALL_SCREEN_TITLE}
                                    </Text>
                                    {isWeb ? (
                                        <Pressable onPress={() => setOpen(false)}>
                                            <SvgIcon
                                                icon="Close"
                                                width={16}
                                                height={16}
                                                color="color-basic-100"
                                            />
                                        </Pressable>
                                    ) : null}
                                </Stack>
                            </Stack>

                            <Stack direction="column" style={styles.formContainer}>
                                <FormBody handlePincodeSubmit={handlePincodeSubmit} />
                            </Stack>
                        </Stack>
                    }
                    showModal={canOpen}
                    setShowModal={setOpen}
                    dismissOnPressingBackdrop={true}
                    modalStyles={{
                        modalBodyStyle: styles.modalStyles,
                    }}
                />
            </Display>
        </>
    );
};

export const PincodeForm = React.memo(PincodeFormComponent);

const styles = StyleSheet.create({
    iconContainer: {
        height: 48,
        width: 48,
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 12,
        padding: SpacingValue["space-small"],
    },
    container: {
        minHeight: 56,
    },
    modalStyles: {
        paddingTop: 0,
        paddingHorizontal: 0,
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
    },
    title: {
        paddingTop: SpacingValue["space-x-large"],
        paddingBottom: SpacingValue["space-large"],
        paddingHorizontal: SpacingValue["space-medium"],
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
    },
    iconWrapper: {
        marginBottom: SpacingValue["space-small"],
    },
    formContainer: {
        paddingHorizontal: SpacingValue["space-medium"],
        paddingVertical: SpacingValue["space-large"],
    },
    centralModalStyles: {
        maxWidth: 300,
        width: 300,
        paddingBottom: SpacingValue["space-x-large"],
    },
});
