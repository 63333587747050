import * as React from "react";
import { Platform, StyleSheet, StyleProp, ViewStyle } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { SpacingValue, Text } from "@swiggy-private/rn-dls";
import { Stack, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";

import { RouteList } from "@minis-consumer/interfaces/route";
import { useMinisNavigation } from "@minis-consumer/hooks/use-minis-navigation";
import { useUserInfo } from "@minis-consumer/hooks/use-user";
import { useAuthHandler } from "@minis-consumer/hooks/use-auth-modal-handler";

type AccountNavigation = NativeStackNavigationProp<RouteList, "Home", string> | null;

interface Props {
    style?: StyleProp<ViewStyle>;
    flex?: ViewStyle["flex"];
}

export const StoreClosedContent: React.FC<Props> = ({ style, flex }) => {
    const navigation = useNavigation() as AccountNavigation;
    const minisNavigation = useMinisNavigation();

    const [, setIsAuthOpen] = useAuthHandler();

    const paddingVertical = useSelectScreen({
        lg: 80,
        md: 80,
        default: Platform.OS === "web" ? 0 : 80,
    });

    const userInfo = useUserInfo();
    const signedIn = userInfo != null;

    const onPressFaq = React.useCallback(() => {
        if (!signedIn) {
            setIsAuthOpen(true);
            return;
        }

        if (navigation != null) {
            navigation.navigate("Account", {
                screen: "FAQ",
            });
        } else {
            minisNavigation?.navigate("Account", {
                screen: "FAQ",
            });
        }
    }, [signedIn, navigation, minisNavigation, setIsAuthOpen]);

    return (
        <Stack
            justifyContent="center"
            alignItems="center"
            spacing={SpacingValue["space-x-large"]}
            flex={flex}
            style={[{ paddingVertical }, style]}>
            <Text category="b1" color="medium" weight="medium" style={styles.textCenter}>
                {"This Ministore has been \npermanently closed by the seller"}
            </Text>

            <Text category="b1" color="medium">
                Have questions? Read Minis{" "}
                <Text category="b1" weight="bold" color="color-primary" onPress={onPressFaq}>
                    FAQ
                </Text>
            </Text>
        </Stack>
    );
};

const styles = StyleSheet.create({
    textCenter: {
        textAlign: "center",
    },
});
