import * as React from "react";

import { useFollowersCountReducer } from "../reducers/followers-count-reducer";

export type IFollowersCountContext = Record<string, unknown>;

export const FollowersCountContext = React.createContext<IFollowersCountContext>({});

type FollowersCountReducerType = ReturnType<typeof useFollowersCountReducer>;
type State = FollowersCountReducerType[0];
type Dispatch = FollowersCountReducerType[1];

export const FollowersCountStateContext = React.createContext<State | null>(null);

// eslint-disable-next-line no-void
export const FollowersCountDispatchContext = React.createContext<Dispatch>(() => void 0);

interface FollowersCountStateProviderProps {
    initialState: State;
}

export const FollowersCountStateProvider: React.FC<
    React.PropsWithChildren<FollowersCountStateProviderProps>
> = ({ initialState, children }) => {
    const [state, dispatch] = useFollowersCountReducer(initialState);

    return (
        <FollowersCountStateContext.Provider value={state}>
            <FollowersCountDispatchContext.Provider value={dispatch}>
                {children}
            </FollowersCountDispatchContext.Provider>
        </FollowersCountStateContext.Provider>
    );
};
