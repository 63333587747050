import React from "react";
import {
    Animated,
    ViewStyle,
    InteractionManager,
    Platform,
    StyleProp,
    ViewProps,
    RegisteredStyle,
} from "react-native";
import { useAnimatedValue } from "@swiggy-private/rn-dls";

type WidgetShakerProps = {
    children: React.ReactNode;
    shake: boolean;
    setShake: (b: boolean) => void;
    durationInMs?: number;
    style?: StyleProp<ViewProps>;
};

const DEFAULT_ANIMATION_DURATION = 600;

const WidgetShakerComponent: React.FC<WidgetShakerProps> = (props) => {
    const animatedValue = useAnimatedValue(0);

    const taskRef = React.useRef<ReturnType<typeof InteractionManager.runAfterInteractions> | null>(
        null,
    );

    const tranlateXAnimated = animatedValue.interpolate({
        inputRange: [0, 0.125, 0.25, 0.375, 0.5, 0.625, 0.75, 0.875, 1],
        outputRange: [0, 5, -5, 5, 0, 5, -5, 5, 0],
    });

    const onShakeNudge = React.useCallback(() => {
        taskRef.current?.cancel();

        props.setShake(true);

        taskRef.current = InteractionManager.runAfterInteractions(() => {
            Animated.timing(animatedValue, {
                toValue: 1,
                useNativeDriver: Platform.OS !== "web",
                duration: props.durationInMs || DEFAULT_ANIMATION_DURATION,
            }).start((finished) => {
                if (finished) {
                    animatedValue.setValue(0);
                    props.setShake(false);
                }
            });
        });
    }, [animatedValue, props]);

    React.useEffect(() => {
        if (props.shake) {
            onShakeNudge();
        }
    }, [onShakeNudge, props.shake]);

    return (
        <Animated.View
            style={[
                {
                    transform: [
                        {
                            translateX: tranlateXAnimated,
                        },
                    ],
                },
                props.style as RegisteredStyle<ViewStyle>,
            ]}>
            {props.children}
        </Animated.View>
    );
};

export const WidgetShaker = React.memo(WidgetShakerComponent);
