import * as React from "react";
import { ViewStyle } from "react-native";

import { useRecentMinisMigrationTooltipCounter } from "@minis-consumer/routes/minis-home/hooks/use-recent-minis-view-count";
import { RecentMinisTracker } from "@minis-consumer/includes/recent-minis";

import { getInputStyles, IGetStyle } from "../helpers";

export interface IRecentMinisMigrationNudge {
    canShowNudge: boolean;
    onDismissNudge: (cb?: VoidFunction) => void;
    getInputStyles: (i: IGetStyle) => ViewStyle | undefined;
}

const RecentMinisMigrationNudgeContext = React.createContext<IRecentMinisMigrationNudge>({
    canShowNudge: false,
    onDismissNudge: () => undefined,
    getInputStyles: () => undefined,
});

export const RecentMinisMigrationNudgeProvider: React.FC<React.PropsWithChildren> = ({
    children,
}) => {
    const { isNudgeViewCountLegal } = useRecentMinisMigrationTooltipCounter();

    const visitedStores = RecentMinisTracker.getInstance().get();

    const hasVisitedStores = visitedStores.length > 0;

    const canShow = React.useMemo(
        () => hasVisitedStores && isNudgeViewCountLegal,
        [hasVisitedStores, isNudgeViewCountLegal],
    );

    const [show, setShow] = React.useState(canShow);

    const onDismissNudge = React.useCallback((cb?: VoidFunction) => {
        setShow(false);
        cb?.();
    }, []);

    const value = React.useMemo(
        () => ({
            canShowNudge: show,
            onDismissNudge,
            getInputStyles,
        }),
        [onDismissNudge, show],
    );

    return (
        <RecentMinisMigrationNudgeContext.Provider value={value}>
            {children}
        </RecentMinisMigrationNudgeContext.Provider>
    );
};

export const useRecentMinisMigrationNudgeContext = (): IRecentMinisMigrationNudge =>
    React.useContext(RecentMinisMigrationNudgeContext);
