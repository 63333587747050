import * as React from "react";
import { StyleSheet, ViewProps, Pressable, Platform } from "react-native";
import { useNavigation } from "@react-navigation/core";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { ConditionalJSX, IK_QUALITY_FORMAT, StoreMedia } from "@swiggy-private/react-native-ui";
import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { ProductEnquireMessage as IProductEnquireMessage } from "@swiggy-private/connect-chat-commons";
import { getTextualDescription } from "@swiggy-private/connect-business-commons";
import { SdkConversation } from "@swiggy-private/connect-chat-sdk";

import { RouteList } from "@minis-consumer/interfaces/route";
import { PRODUCT_TYPE } from "@minis-consumer/interfaces/catalog";
import { getMediaUrl } from "@minis-consumer/helpers/media";
import { getProductFallbackImages } from "@minis-consumer/helpers/product-fallback-images";

import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { RUPEE_SYMBOL } from "@minis-consumer/constants";

const IMAGE_OPTS = { width: 64, height: 64, qualityAuto: IK_QUALITY_FORMAT.GOOD, quality: 100 };

interface ProductEnquireMessageProps {
    message: IProductEnquireMessage;
    isPublisher: boolean;
    style?: ViewProps["style"];
    conversationMeta?: SdkConversation["meta"];
    conversationId?: string;
}

export const ProductEnquireMessage: React.FC<ProductEnquireMessageProps> = ({
    message,
    conversationMeta,
}) => {
    const { value: theme } = useTheme();
    const payload = message.payload;

    const storeInfo = useStoreInfo(false);
    const slug = storeInfo ? storeInfo.slug : conversationMeta?.storeSlug ?? "";
    const storeId = storeInfo ? storeInfo.storeId : conversationMeta?.storeId ?? "";

    const {
        productName: title,
        productDescription,
        text: enquiryMessage,
        variantLabel,
        productId,
        productImageId,
        productPrice,
        productDiscountedPrice,
        productType,
        productDuration,
        productSlot,
        formData,
        contactInfo,
        assetType,
    } = payload;

    const description = getTextualDescription(productDescription ?? "");

    const productImgFallback = getProductFallbackImages(productType);

    const imageUrl = getMediaUrl(productImageId ?? productImgFallback, IMAGE_OPTS, true);

    const navigation = useNavigation<NativeStackNavigationProp<RouteList>>();

    const productBackground = {
        backgroundColor: theme.background_Secondary,
    };

    if (!productId || !title) {
        return null;
    }

    const renderProductInfo = (): React.ReactElement | null => {
        const productDurationElement = (
            <Box mt={SpacingValue["space-x-small"]}>
                <Text category="b3" weight="medium" numberOfLines={1} color="color-basic-60">
                    {productDuration}
                </Text>
            </Box>
        );

        const productSlotElement = productSlot ? (
            <Box mt={SpacingValue["space-x-small"]}>
                <Text category="b3" weight="medium" numberOfLines={2} color="color-basic-75">
                    {productSlot}
                </Text>
            </Box>
        ) : null;

        const digitalInfoElement = (
            <Box mt={SpacingValue["space-x-small"]}>
                <Text category="b3" weight="medium" numberOfLines={1} color="color-basic-60">
                    {assetType}
                </Text>
            </Box>
        );

        switch (productType as PRODUCT_TYPE) {
            case "APPOINTMENT":
                return productDurationElement || productSlotElement ? (
                    <Box direction="column">
                        {productDurationElement}
                        {productSlotElement}
                    </Box>
                ) : null;
            case "PLAN":
                return productDurationElement ? (
                    <Box direction="column">{productDurationElement}</Box>
                ) : null;
            case "DIGITAL":
                return assetType ? <Box direction="column">{digitalInfoElement}</Box> : null;
            case "PHYSICAL":
            default:
                return null;
        }
    };

    const renderContactInfo = (): React.ReactElement => {
        return (
            <Box
                mv={SpacingValue["space-small"]}
                pv={SpacingValue["space-xx-small"]}
                style={styles.contactInfoContainer}>
                <Text category="b2" weight="bold" color="color-positive">
                    {`Whatsapp Number ${contactInfo}`}
                </Text>
            </Box>
        );
    };

    const viewFormPressHandler = (): void => {
        try {
            navigation.navigate("ProductForms", {
                id: productId,
                slug,
                storeId,
                formData,
                isEditMode: false,
                isEnquireMode: true,
            });
        } catch (e) {}
    };

    return (
        <Box style={[styles.container]}>
            <Stack
                style={[styles.content, productBackground]}
                spacing={SpacingValue["space-small"]}
                direction="row">
                <Stack flex={1}>
                    <Box direction="row">
                        <Stack spacing={SpacingValue["space-xx-small"]} flex={1}>
                            <Text category="b1" color="high" weight="bold" numberOfLines={2}>
                                {title}
                            </Text>

                            {description ? (
                                <Text
                                    category="b2"
                                    weight="regular"
                                    color="high"
                                    numberOfLines={2}
                                    ellipsizeMode="tail">
                                    {description}
                                </Text>
                            ) : null}

                            {variantLabel ? (
                                <Text
                                    category="b3"
                                    weight="medium"
                                    numberOfLines={2}
                                    color="color-basic-60">
                                    {variantLabel}
                                </Text>
                            ) : null}
                        </Stack>

                        <ConditionalJSX condition={!!imageUrl}>
                            <StoreMedia disableVideoPlay mediaId={imageUrl} style={styles.image} />
                        </ConditionalJSX>
                    </Box>

                    {renderProductInfo()}

                    {productPrice ? (
                        <Box mt={SpacingValue["space-x-small"]}>
                            <Stack
                                spacing={SpacingValue["space-xxx-small"]}
                                direction="row"
                                alignItems="center">
                                <Text
                                    category="b3"
                                    weight={productDiscountedPrice ? "regular" : "medium"}
                                    color="high"
                                    ellipsizeMode="tail"
                                    style={
                                        payload.productDiscountedPrice
                                            ? styles.strikeOff
                                            : undefined
                                    }>
                                    ₹{productPrice}
                                </Text>
                                {productDiscountedPrice ? (
                                    <Text
                                        category="b3"
                                        weight="medium"
                                        color="high"
                                        ellipsizeMode="tail">
                                        {`${RUPEE_SYMBOL}${productDiscountedPrice}`}
                                    </Text>
                                ) : null}
                            </Stack>
                        </Box>
                    ) : null}

                    {formData ? (
                        <Pressable onPress={viewFormPressHandler}>
                            <Text
                                category="b2"
                                weight="bold"
                                numberOfLines={1}
                                color="color-positive"
                                style={styles.viewForm}>
                                View Form
                            </Text>
                        </Pressable>
                    ) : null}
                </Stack>
            </Stack>
            {contactInfo ? renderContactInfo() : null}
            <Box mt={SpacingValue["space-x-small"]}>
                <Text category="b2" weight="regular" color="high">
                    {enquiryMessage}
                </Text>
            </Box>
        </Box>
    );
};

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        minWidth: 200,
        maxWidth: Platform.OS === "web" ? "100%" : "80%",
    },
    content: {
        flex: Platform.OS === "web" ? 1 : 0,
        width: "100%",
        padding: SpacingValue["space-small"],
        borderRadius: 12,
    },
    image: {
        width: 64,
        height: 64,
        borderRadius: 8,
        resizeMode: "cover",
    },
    strikeOff: {
        textDecorationLine: "line-through",
    },
    viewForm: {
        textDecorationLine: "underline",
        marginTop: SpacingValue["space-x-small"],
    },
    contactInfoContainer: {
        borderRadius: 4,
        backgroundColor: "rgba(74, 166, 106, 0.1)",
        paddingLeft: SpacingValue["space-small"],
    },
});
