import {
    DefaultMapEndpoint,
    fetchGeocodeApi,
    fetchLocationsApi,
    GeocodeAddress,
    Prediction,
} from "@swiggy-private/connect-address-ui";

export const getLatLngFromAddress = async (
    address: string,
): Promise<{ lat: number; lng: number }> => {
    const predictions = await getAddressPredictionsFromInput(address);
    const placeId = predictions[0].place_id;
    const geocodeResponse = await getAddressFromPlaceId(placeId);

    return geocodeResponse.geometry.location;
};

export const getAddressPredictionsFromInput = async (address: string): Promise<Prediction[]> => {
    const places = await fetchLocationsApi(address, { baseUrl: DefaultMapEndpoint });
    if (!Array.isArray(places?.data) || !places.data.length) {
        throw new Error("invalid address");
    }

    return places.data;
};

export const getAddressFromPlaceId = async (placeId: string): Promise<GeocodeAddress> => {
    const geocodeResponse = await fetchGeocodeApi(placeId, { baseUrl: DefaultMapEndpoint });
    if (!Array.isArray(geocodeResponse?.data) || !geocodeResponse.data.length) {
        throw new Error("invalid address");
    }

    return geocodeResponse.data[0];
};
