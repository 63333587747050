import React, { useEffect } from "react";
import { ViewStyle } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { SpacingValue, DialogContent } from "@swiggy-private/rn-dls";
import { useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { BottomSheet } from "@swiggy-private/react-native-ui";

import { useEnquireStoryChat } from "@minis-consumer/hooks/use-enquire-story-chat";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { getMediaUrl } from "@minis-consumer/helpers/media";
import { Analytics } from "@minis-consumer/analytics";

import { ModalBody } from "../components/modal-body";

interface Props {
    showModal: boolean;
    closeModal: () => void;
    imageUrl: string;
    storyId: string;

    storeId?: string;
    highlightName?: string;
    setIsMessageSent?: (isMessageSent: boolean) => void;
}

const IMAGE_SIZE = 100;
const StoryEnquiryDialogComponent: React.FC<Props> = ({
    showModal,
    closeModal,
    storeId: dialogContextStoreId,
    imageUrl,
    storyId,
    highlightName,
    setIsMessageSent,
}) => {
    const storeInfo = useStoreInfo(false);
    const storeId = storeInfo?.storeId ?? dialogContextStoreId;

    const insets = useSafeAreaInsets();
    const { sendEnquiryMessage } = useEnquireStoryChat(storeId ?? "");

    const modalMaxWidth = useSelectScreen({
        lg: 380 as ViewStyle["width"],
        default: "100%",
    });

    const analyticsContextData = React.useMemo(
        () =>
            JSON.stringify({
                storeId,
                storyId,
                highlightTitle: highlightName,
            }),
        [storeId, storyId, highlightName],
    );

    useEffect(() => {
        if (!showModal) {
            return;
        }
        Analytics.impressionEvent({
            category: "highlights-enquire-enter-query-half-card",
            context: analyticsContextData,
        });
    }, [showModal, analyticsContextData]);

    const modalStyles = useSelectScreen({
        default: {
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            paddingTop: SpacingValue["space-x-small"],
            paddingBottom: Math.max(insets.bottom, SpacingValue["space-medium"]),
        },
        lg: {
            borderRadius: 8,
            paddingHorizontal: SpacingValue["space-x-small"],
            paddingTop: SpacingValue["space-x-small"],
            paddingBottom: SpacingValue["space-x-small"],
        },
    });

    const productImageUrl = getMediaUrl(
        imageUrl,
        {
            width: IMAGE_SIZE,
            height: IMAGE_SIZE,
        },
        true,
    );

    const onCloseModal = React.useCallback(() => {
        closeModal();
        Analytics.clickEvent({
            category: "highlights-enter-query-half-card-close-btn",
            context: analyticsContextData,
        });
    }, [closeModal, analyticsContextData]);

    const onSendMessage = React.useCallback(
        (enquiryMessage: string) => {
            if (!enquiryMessage || !storeId) {
                return;
            }
            sendEnquiryMessage({
                text: enquiryMessage,
                imageUrl: productImageUrl,
                storyData: storyId,
                highlightName,
            });

            Analytics.clickEvent({
                category: "highlights-enter-query-half-card-send-btn",
                context: analyticsContextData,
            });

            setIsMessageSent?.(true);
        },
        [
            storeId,
            sendEnquiryMessage,
            productImageUrl,
            storyId,
            highlightName,
            analyticsContextData,
            setIsMessageSent,
        ],
    );

    if (!showModal || !storeId) {
        return null;
    }

    return (
        <BottomSheet
            open={showModal}
            onClose={closeModal}
            style={[modalStyles, { width: modalMaxWidth }]}>
            <DialogContent>
                <ModalBody
                    closeModal={onCloseModal}
                    onSendMessage={onSendMessage}
                    storeId={storeId}
                    imageUrl={productImageUrl ?? undefined}
                    queryText={"Add a query for"}
                    name={highlightName ?? undefined}
                />
            </DialogContent>
        </BottomSheet>
    );
};

export const StoryEnquiryDialog = React.memo(StoryEnquiryDialogComponent);
