import * as React from "react";
import { ViewStyle } from "react-native";

import { Stack, StackProps, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { Text, SpacingValue } from "@swiggy-private/rn-dls";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { useMount } from "@swiggy-private/react-hooks";

import { useRatingAndReviewHook } from "@minis-consumer/hooks/use-rating-and-review";
import { Analytics } from "@minis-consumer/analytics";

import { ORDER_RATING } from "./constants";

type OrderRatingCardProps = {
    rated: boolean;
    value: number;
    orderId: string;
    isStoreEnabled: boolean;
    storeId: string;

    style?: ViewStyle;
    lgDirection?: ViewStyle["flexDirection"];
    defaultDirection?: ViewStyle["flexDirection"];
};

const OrderRatingCardComponent: React.FC<OrderRatingCardProps> = ({
    rated: ratingAdded,
    value: ratingValue,
    isStoreEnabled,
    style,
    lgDirection,
    defaultDirection,
    orderId,
    storeId,
}) => {
    const { ratingAndReviewNavigtaionHandler } = useRatingAndReviewHook({
        orderId,
        storeId,
    });

    const analyticsData = React.useMemo(() => {
        return {
            category: "order-rating",
            context: `orderId: ${orderId}, ratingAdded: ${ratingAdded}, ratingValue: ${ratingValue}`,
        };
    }, [orderId]);

    const svgSize = useSelectScreen({
        lg: SpacingValue["space-x-large"],
        default: SpacingValue["space-x-large"],
    });

    const direction: StackProps["direction"] = useSelectScreen({
        lg: lgDirection || "row",
        default: defaultDirection || "column",
    });

    const onAddRating = React.useCallback(
        (ratingGiven: number) => {
            Analytics.clickEvent(analyticsData);

            ratingAndReviewNavigtaionHandler(ratingGiven);
        },
        [ratingAndReviewNavigtaionHandler],
    );

    useMount(() => {
        Analytics.impressionEvent(analyticsData);
    });

    if (!isStoreEnabled && !ratingAdded) {
        return null;
    }

    return (
        <Stack
            style={style}
            alignItems="center"
            direction={direction}
            spacing={SpacingValue["space-medium"]}>
            <Text category="b2" color="color-warning-800">
                {ratingAdded ? ORDER_RATING.RATED : ORDER_RATING.NOT_RATED}
            </Text>
            <Stack
                direction="row"
                spacing={{
                    lg: SpacingValue["space-x-small"],
                    default: SpacingValue["space-medium"],
                }}>
                {Array(5)
                    .fill(1)
                    .map((_, index) => (
                        <SvgIcon
                            key={index}
                            icon={ratingAdded && ratingValue > index ? "StarFilled" : "Star"}
                            width={svgSize}
                            height={svgSize}
                            color={
                                ratingAdded
                                    ? ratingValue > index
                                        ? "color-warning"
                                        : "color-basic-30"
                                    : "color-warning-500"
                            }
                            disabled={ratingAdded}
                            onPress={() => onAddRating(index + 1)}
                        />
                    ))}
            </Stack>
        </Stack>
    );
};

export const OrderRatingCard = React.memo(OrderRatingCardComponent);
