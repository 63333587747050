import * as React from "react";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { ScreenSize, useScreenSize } from "@swiggy-private/rn-adaptive-layout";
import { useController } from "@rest-hooks/react";

import { RouteList } from "@minis-consumer/interfaces/route";
import { AuthModalContext } from "@minis-consumer/contexts/auth-modal-context";
import { SwiggyRNModule } from "@minis-consumer/includes/swiggy-rn-module";
import { Session } from "@minis-consumer/includes/session";
import { useAppDispatch } from "./use-app-dispatch";

type TOptionProps = { redirectPath?: string };
type AuthHandlerProps = [boolean, (open: boolean, options?: TOptionProps) => void];

export const useAuthHandler = (): AuthHandlerProps => {
    const ctx = React.useContext(AuthModalContext);

    const screenSize = useScreenSize();
    const navigation = useNavigation<NativeStackNavigationProp<RouteList>>();

    const { resetEntireStore } = useController();
    const appDispatch = useAppDispatch();

    const handler = React.useCallback(
        (open: boolean, options?: TOptionProps) => {
            if (SwiggyRNModule.isModuleAvailable()) {
                SwiggyRNModule.getInstance().openLogin((headers) => {
                    if (headers?.userId && headers?.token && headers.tid) {
                        Session.loadFromHeaders(headers);

                        appDispatch({
                            type: "SET_USER_ACTION",
                            payload: {
                                id: headers.userId,
                                name: headers.userName ?? "",
                                token: headers.token,
                            },
                        });

                        resetEntireStore();
                    }
                });

                return;
            }

            if (ScreenSize.Large !== screenSize) {
                if (options?.redirectPath && options.redirectPath !== "/") {
                    navigation.setParams({ redirectPath: "/" + options?.redirectPath });
                }

                // push won't work here
                navigation.navigate("Auth", { screen: "Login" });
                return;
            }

            if (open) {
                ctx?.open();
            } else {
                ctx?.close();
            }
        },
        [appDispatch, ctx, navigation, resetEntireStore, screenSize],
    );

    return [ctx?.isOpen ?? false, handler];
};
