import * as React from "react";
import {
    Platform,
    Pressable,
    PressableProps,
    StyleProp,
    StyleSheet,
    ViewStyle,
    useWindowDimensions,
} from "react-native";
import { useSuspense } from "@rest-hooks/react";

import { Box, Stack, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text, TooltipPlacement } from "@swiggy-private/rn-dls";
import { ChatConversationHeaderProps } from "@swiggy-private/connect-chat-ui";
import { StarSellerBadge, useStarSeller } from "@swiggy-private/connect-business-commons";
import { useMount } from "@swiggy-private/react-hooks";

import { ShopLogo } from "@minis-consumer/components/shop-logo";
import { useShopNavigation } from "@minis-consumer/hooks/use-shop-navigation";
import { AsyncComponent } from "@minis-consumer/components/async-component";
import { StoreDetail, StoreEntity } from "@minis-consumer/resources/store";
import { getStoreBrandImageUrl } from "@minis-consumer/helpers/store";
import { compactTitle } from "@minis-consumer/helpers/title";
import { useStoreInfo } from "@minis-consumer/hooks/use-store";
import { useShowTimeBasedTooltip } from "@minis-consumer/hooks/use-time-based-tooltip";
import { Analytics } from "@minis-consumer/analytics";
import { useChatBlockyModalHandler } from "@minis-consumer/hooks/use-chat-block-modal-handler";
import { useSignedIn } from "@minis-consumer/hooks/use-user";

import { SellerStatusIndicator } from "./components/seller-status-indicator";
import { Wrapper } from "./components/wrapper";
import { Fallback } from "./components/fallback";
import { Menu } from "./components/menu";
import { ANALYTICS_COPIES, COPIES, TOOLTIP_DEFAULT_VALUE } from "./constants";
import { checkIsBlockedByCustomer } from "../../helpers/chat-blocked";

import { TimeBasedTooltipComponent } from "@minis-consumer/components/time-based-tooltip";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { useIsMobileWeb } from "@minis-consumer/hooks/use-mweb";
import { useLinking } from "@minis-consumer/hooks/use-linking";

export type IconContainerProps = Omit<PressableProps, "style"> & {
    style?: StyleProp<ViewStyle>;
};

export const IconContainer: React.FC<React.PropsWithChildren<IconContainerProps>> = (props) => {
    const {
        accessible = true,
        accessibilityRole = "button",
        style,
        children,
        ...restProps
    } = props;

    return (
        <Pressable
            accessible={accessible}
            accessibilityRole={accessibilityRole}
            style={styles.icon}
            {...restProps}>
            <Box style={[styles.iconContainer, style]}>{children}</Box>
        </Pressable>
    );
};

export const ChatHeader: React.FC<ChatConversationHeaderProps> = (props) => {
    const { width: windowWidth } = useWindowDimensions();
    const isSignedIn = useSignedIn();
    const isMWeb = useIsMobileWeb();

    const { dataHandler: chatBlockModalDataHandler } = useChatBlockyModalHandler();

    const { isTooltipVisible, onMountTooltip, onDismissTooltip } = useShowTimeBasedTooltip({
        key: TOOLTIP_DEFAULT_VALUE.KEY,
        value: true,
        startTime: TOOLTIP_DEFAULT_VALUE.START_TIME,
        dismissAfter: TOOLTIP_DEFAULT_VALUE.DISMISS_AFTER,
        lifetimeDisplayCount: TOOLTIP_DEFAULT_VALUE.LIFETIME_DISPLAY_COUNT,
        sessionDisplayCount: TOOLTIP_DEFAULT_VALUE.SESSION_DISPLAY_COUNT,
    });

    const { conversation, active, typing, lastSeenAt, callIconClickHandler } = props;
    const canShowBlockMenu = conversation?.chatBlockFeatureEnabled && isSignedIn;

    const storeInfo = useStoreInfo(false);
    const openUrl = useLinking();

    const slug = storeInfo ? storeInfo.slug : conversation?.meta?.storeSlug;

    const store = useSuspense(
        StoreDetail,
        slug && !storeInfo ? { slug: slug as string } : null,
    ) as StoreEntity | null;

    const chatIconAnalyticsData = React.useMemo(() => {
        return {
            category: ANALYTICS_COPIES.CALL_SELLER_BTN_CATEGORY,
            context: JSON.stringify({ store_id: storeInfo?.storeId }),
        };
    }, [storeInfo?.storeId]);

    const tooltipAnalyticsData = React.useMemo(() => {
        return {
            category: ANALYTICS_COPIES.CALL_SELLER_POPUP_CATEGORY,
            context: JSON.stringify({ store_id: storeInfo?.storeId }),
        };
    }, [storeInfo?.storeId]);

    const shop = storeInfo || store;

    const connectWithSeller = React.useCallback(() => {
        Analytics.clickEvent(chatIconAnalyticsData);

        if (Platform.OS === "web" && !isMWeb) {
            onDismissTooltip();
            callIconClickHandler?.();
            return;
        }

        shop && openUrl(`tel:${shop.settings.mobile}`);
    }, [chatIconAnalyticsData, isMWeb, shop, openUrl, onDismissTooltip, callIconClickHandler]);

    const goToStore = useShopNavigation();

    const logoSize = useSelectScreen({
        lg: 56,
        default: 40,
    });

    const borderRadius = useSelectScreen({
        lg: 16,
        default: 12,
    });

    const headerStyle = useSelectScreen({
        lg: { width: "100%" },
        default: { width: windowWidth - logoSize - 2 * SpacingValue["space-medium"] },
    });

    const imgUrl = React.useMemo(
        () =>
            shop ? getStoreBrandImageUrl(shop, { height: logoSize, width: logoSize }, true) : null,
        [shop, logoSize],
    );

    const sellerStatus = React.useMemo(
        () => (!conversation?.deleted ? (active ? "online" : "offline") : "closed"),
        [active, conversation?.deleted],
    );

    const isStarSeller = useStarSeller(
        storeInfo ? storeInfo.badges : conversation?.meta?.storeBadges,
    );

    const isBlockedByCustomer = React.useMemo(
        () => checkIsBlockedByCustomer(conversation?.blockedInfo),
        [conversation],
    );

    useMount(() => {
        onMountTooltip();

        if (storeInfo?.settings?.mobile) {
            Analytics.impressionEvent(chatIconAnalyticsData);
        }
    });

    React.useEffect(() => {
        if (!conversation || isBlockedByCustomer === undefined) {
            return;
        }

        chatBlockModalDataHandler(
            conversation.id,
            isBlockedByCustomer,
            shop?.name ?? COPIES.SHOP_DEFAULT_NAME,
        );
    }, [conversation, isBlockedByCustomer, shop?.name, chatBlockModalDataHandler]);

    const canShowBussinessNumber = React.useMemo(
        () => shop?.settings?.showBusinessNumber && shop?.settings?.mobile,
        [shop?.settings],
    );

    return (
        <Wrapper onPress={() => goToStore(shop?.slug)} isDisabled={conversation?.deleted}>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                style={headerStyle}
                spacing={SpacingValue["space-small"]}>
                <Stack direction="row" flex={1}>
                    {imgUrl ? (
                        <ShopLogo
                            isImageKitEnabled
                            size={logoSize}
                            url={imgUrl}
                            borderRadius={borderRadius}
                            style={styles.shopLogo}
                        />
                    ) : null}

                    <Box justifyContent="center" flex={1}>
                        <Box direction="row" alignItems="center">
                            <Text
                                category="b1"
                                weight="bold"
                                color="color-basic-100"
                                numberOfLines={1}
                                style={styles.shopName}>
                                {compactTitle(shop?.name ?? "Store")}
                            </Text>

                            {isStarSeller ? (
                                <StarSellerBadge
                                    outerGradientStyle={styles.badgeGradient}
                                    iconSize={10}
                                />
                            ) : null}
                        </Box>

                        {typing ? (
                            <Text category="b3" color="color-basic-60">
                                {COPIES.TYPING}
                            </Text>
                        ) : (
                            <SellerStatusIndicator
                                sellerStatus={sellerStatus}
                                lastSeenAt={lastSeenAt}
                            />
                        )}
                    </Box>
                </Stack>

                <Stack direction="row" alignItems="center">
                    {canShowBussinessNumber ? (
                        <TimeBasedTooltipComponent
                            analytics={tooltipAnalyticsData}
                            isTooltipVisible={isTooltipVisible}
                            tooltipContentTitle={TOOLTIP_DEFAULT_VALUE.TOOLTIP_TEXT}
                            tooltipStyle={styles.toolTip}
                            placement={TooltipPlacement.BOTTOM_END}
                            tooltipContentClickHandler={connectWithSeller}>
                            <IconContainer accessibilityLabel="Chat" onPress={connectWithSeller}>
                                <SvgIcon
                                    icon="Phone"
                                    color="color-basic-60"
                                    width={18}
                                    height={18}
                                    onPress={connectWithSeller}
                                />
                            </IconContainer>
                        </TimeBasedTooltipComponent>
                    ) : null}

                    {canShowBlockMenu ? (
                        <Menu isBlocked={isBlockedByCustomer} conversationId={conversation?.id} />
                    ) : null}
                </Stack>
            </Stack>
        </Wrapper>
    );
};

export const ChatConversationHeaderComponent: React.FC<ChatConversationHeaderProps> = (props) => {
    React.useEffect(() => {
        props.navigation?.setOptions({
            headerTitle: () => (
                <AsyncComponent fallback={Fallback}>
                    <ChatHeader {...props} />
                </AsyncComponent>
            ),
        });
    }, [props]);

    return null;
};

const styles = StyleSheet.create({
    badgeGradient: {
        padding: 0,
        marginLeft: SpacingValue["space-xxx-small"],
    },
    toolTip: {
        width: 278,
    },
    iconContainer: {
        width: 36,
        height: 36,
        justifyContent: "center",
        alignItems: "center",
    },
    shopName: {
        marginBottom: SpacingValue["space-xxx-small"],
    },
    icon: {
        marginLeft: SpacingValue["space-x-small"],
    },
    shopLogo: {
        marginRight: SpacingValue["space-small"],
    },
});

export const ChatConversationHeader = React.memo(ChatConversationHeaderComponent);
